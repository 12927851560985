import React, { FC, useMemo, useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Select, { components } from "react-select";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";

import { typeOption } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

const cnContentPagination = cn("ContentPagination");

type Props = {
  id: string;
  itemPagesCount: number;
  currentPage: number;
  customize: SettingsCustomizeState;
  onChange: (value: number) => void;
};

const Pagination: FC<Props> = ({ id, itemPagesCount, currentPage, onChange, customize }) => {
  const [stateCurrentPage, setStateCurrentPage] = useState<number>(currentPage);
  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    if (currentPage !== stateCurrentPage) {
      setStateCurrentPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  const pagesOption: typeOption[] = useMemo(
    () =>
      new Array(itemPagesCount).fill(null).map((e, i) => ({
        label: (i + 1).toString(),
        value: i + 1,
      })),
    [itemPagesCount],
  );

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon name={props.selectProps.menuIsOpen ? "icon-arrow-up" : "icon-arrow-down"} width={15} />
        </components.DropdownIndicator>
      )
    );
  };

  const handleChangePageValue = (value: number) => {
    setStateCurrentPage(value);
    onChange(value);
    const layoutElement = document.querySelector(".Layout-Container");
    if (layoutElement) {
      layoutElement.scrollTo({ top: 0, left: 0 });
    }
  };

  return (
    <div className={cnContentPagination()} id={id}>
      <ReactPaginate
        previousLabel={<Icon name="icon-arrow-left" height={18} width={12} />}
        nextLabel={<Icon name="icon-arrow-right" height={18} width={12} />}
        nextClassName="pagination-next"
        previousClassName="pagination-prev"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={itemPagesCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={({ selected }) => handleChangePageValue(selected + 1)}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={stateCurrentPage - 1}
      />
      <div className={cnContentPagination("Select")}>
        <Select
          className={cnContentPagination("Select")}
          classNamePrefix={cnContentPagination("Select-Prefix")}
          value={pagesOption.find(({ value }) => value === stateCurrentPage)}
          components={{ DropdownIndicator }}
          isClearable={false}
          isSearchable={true}
          options={pagesOption}
          menuPlacement="top"
          onChange={option => handleChangePageValue((option?.value as number) || 1)}
          theme={theme => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#EAFBFD",
              primary75: mainColor,
              primary50: `rgba(${mainColor}, .5)`,
              primary: mainColor,
            },
          })}
          styles={{
            indicatorSeparator: (provided: any) => ({
              ...provided,
              backgroundColor: "transparent",
            }),
            option: (provided: any) => ({
              ...provided,
              cursor: "pointer",
              padding: "6px 10px",
            }),
            menuList: (provided: any) => ({
              ...provided,
              borderRadius: 6,
              border: `1px solid ${mainColor}`,
              padding: 0,
              maxHeight: 200,
            }),
            menu: (provided: any) => ({
              ...provided,
              boxShadow: "none",
            }),
            container: (provided: any) => ({
              ...provided,
              width: 80,
            }),
            control: (provided: any, state) => ({
              ...provided,
              borderColor: mainColor,
              boxShadow: state.isFocused ? `0 0 2px ${mainColor}` : "none",
              minHeight: 28,
              "&:hover": {
                borderColor: mainColor,
              },
            }),
            valueContainer: (provided: any) => ({
              ...provided,
              padding: "0 8px",
            }),
            dropdownIndicator: (provided: any) => ({
              ...provided,
              padding: "0 6px",
            }),
            singleValue: (provided: any) => ({
              ...provided,
              color: mainColor,
            }),
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
