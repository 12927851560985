import { all, fork } from "redux-saga/effects";

import { saga as checkConnection } from "store/routines/settings/checkConnection";
import { saga as getConfig } from "store/routines/settings/getConfig";
import { saga as getGlobalSettings } from "store/routines/settings/getGlobalSettings";
import { saga as getOAuthLifeTime } from "store/routines/settings/getOAuthLifeTime";
import { saga as getProtectedConfig } from "store/routines/settings/getProtectedConfig";
import { saga as getSettings } from "store/routines/settings/getSettings";
import { saga as setAutoBackupStatus } from "store/routines/settings/setAutoBackupStatus";
import { saga as setBackUpClear } from "store/routines/settings/backUpClear";
import { saga as setBackUpParams } from "store/routines/settings/setBackUpParams";
import { saga as setBackUpNotify } from "store/routines/settings/setBackUpNotify";
import { saga as setFolderNotify } from "store/routines/settings/setFolderNotify";
import { saga as setBestIcon } from "store/routines/settings/setBestIcon";
import { saga as setBestIconCheck } from "store/routines/settings/setBestIconCheck";
import { saga as setClickedLink } from "store/routines/settings/setClickedLink";
import { saga as setCustomize } from "store/routines/settings/setCustomize";
import { saga as setGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { saga as setHeaderIcon } from "store/routines/settings/setHeaderIcon";
import { saga as setHeaderTitle } from "store/routines/settings/setHeaderTitle";
import { saga as setOAuthLifeTime } from "store/routines/settings/setOAuthLifeTime";
import { saga as setOpenedFolder } from "store/routines/settings/setOpenedFolder";
import { saga as setOpenedNavigationFolder } from "store/routines/settings/setOpenedNavigationFolder";
import { saga as setResetPasswordSettings } from "store/routines/settings/setResetPasswordSettings";
import { saga as setSearch } from "store/routines/settings/setSearch";
import { saga as settingsClear } from "store/routines/settings/clear";
import { saga as setCustomizeMailer } from "store/routines/settings/setCustomizeMailer";
import { saga as setCustomizeMailerCheck } from "store/routines/settings/setCustomizeMailerCheck";
import { saga as setCustomizeMailerSwitch } from "store/routines/settings/setCustomizeMailerSwitch";
import { saga as setInitialize } from "store/routines/settings/setInitialize";

export default function* settings() {
  return yield all([
    fork(checkConnection),
    fork(getConfig),
    fork(getGlobalSettings),
    fork(getOAuthLifeTime),
    fork(getProtectedConfig),
    fork(getSettings),
    fork(setAutoBackupStatus),
    fork(setBackUpClear),
    fork(setBackUpParams),
    fork(setBestIcon),
    fork(setBestIconCheck),
    fork(setBackUpNotify),
    fork(setFolderNotify),
    fork(setClickedLink),
    fork(setCustomize),
    fork(setGlobalSettings),
    fork(setHeaderIcon),
    fork(setHeaderTitle),
    fork(setOAuthLifeTime),
    fork(setOpenedFolder),
    fork(setOpenedNavigationFolder),
    fork(setResetPasswordSettings),
    fork(setSearch),
    fork(settingsClear),
    fork(setCustomizeMailer),
    fork(setCustomizeMailerCheck),
    fork(setCustomizeMailerSwitch),
    fork(setInitialize)
  ]);
}
