import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { connect } from "react-redux";

import { PRODUCT_TYPE, ROLE_TYPE, productsAll, rolesAccessAll, Routes } from "core";

import { RootState } from "store/reducers";

interface IProps extends RouteProps {
  component: any;
  productType: PRODUCT_TYPE;
  productTypeAccess?: PRODUCT_TYPE[];
  role: ROLE_TYPE;
  roleAccess?: ROLE_TYPE[];
}

const UnprotectedRoute: FC<IProps> = ({
  component: Component,
  productType,
  productTypeAccess = productsAll,
  role = ROLE_TYPE.USER,
  roleAccess = rolesAccessAll,
  ...rest
}) => {
  return (
    <Route {...rest}>
      {props => {
        if (!roleAccess.includes(role) || !productTypeAccess.includes(productType)) {
          return <Redirect to={Routes.Login} />;
        }
        return <Component {...props} />;
      }}
    </Route>
  );
};

const mapStateToProps = ({ user: { role }, settings: { productType } }: RootState) => ({
  productType,
  role,
});

export default connect(mapStateToProps)(UnprotectedRoute);
