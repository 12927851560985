/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { getKey } from "core";
import { Icon } from "ss-ui";

export const cnTabs = cn("Tabs");

export interface ITabs {
  id: number;
  label: string;
  isActive?: boolean;
  icon?: string;
  count?: number;
}

interface Props {
  tabs: ITabs[];
  onClick?: (id: number) => void;
  moreClass?: string;
}

const Tabs: FC<Props> = ({ moreClass = "", tabs = [], onClick = () => ({}) }) => {
  return (
    <div className={cnTabs(`${moreClass}`)}>
      {tabs.map(tab => {
        return (
          <button
            id={getKey("btn_tab", tab.id)}
            onClick={() => onClick(tab.id)}
            type="button"
            className={cnTabs("Tab", { isActive: tab.isActive })}
            key={tab.id}
          >
            {tab.icon &&
              <Icon name={`icon-${tab.icon}`} width={20} height={20} fill="#A7A7A8"/>
            }
            <span>{tab.label} <sup>{tab.count}</sup></span>
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
