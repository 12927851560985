import { all, fork } from "redux-saga/effects";

import { saga as groupsChose } from "store/routines/groups/chose";
import { saga as groupsClear } from "store/routines/groups/clear";
import { saga as groupsDelete } from "store/routines/groups/delete";
import { saga as groupsGet } from "store/routines/groups/get";
import { saga as groupsGetAll } from "store/routines/groups/getAll";
import { saga as groupsManagement } from "store/routines/groups/manage";
import { saga as syncGroup } from "store/routines/groups/syncGroups";
import { saga as deferredGroups } from "store/routines/groups/deferredGroups";

export default function* groups() {
  return yield all([
    fork(groupsChose),
    fork(groupsClear),
    fork(groupsDelete),
    fork(groupsGet),
    fork(groupsGetAll),
    fork(groupsManagement),
    fork(syncGroup),
    fork(deferredGroups),
  ]);
}
