/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Radio } from "ss-ui";
import { WrappedFieldProps } from "redux-form";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

type Props = {
  className?: string;
  disabled?: boolean;
  label: string;
  value?: string;
  checked?: boolean;
} & WrappedFieldProps;

const FormRadio: React.FC<Props> = ({ className = "", input, checked, label, meta: { touched, error }, ...rest }) => {
  return (
    <div className={`${cnField()} ${cnField("Radio")} ${className}`} id={`input_radio_${input.name}_container`}>
      {label && (
        <label id={`input_radio_${input.name}`}>
          <Radio {...input} checked={checked} /> <span className="Label">{label}</span>
        </label>
      )}
      {!label && <Radio {...input} checked={checked} />}
      {touched && Boolean(error) && (
        <span id={`input_radio_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default FormRadio;
