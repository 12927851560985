import React, { FC, useEffect } from "react";

type Props = {
  seconds: number;
  setSeconds: (number: number) => void;
};

const Timer: FC<Props> = ({ seconds, setSeconds }) => {
  useEffect(() => {
    const interval = setInterval(() => setSeconds(seconds - 1), 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return <span>{seconds}</span>;
};

export default Timer;