/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo } from "react";
import { Checkbox, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { ButtonDiv } from "components/Generic";
import { cnTable, typeTableHeaderElement } from "components/Table";

import { getKey, mapRolesName, PayloadChoseFunction, typeUser, DIRECTION } from "core";

import { PropsWithChosen, PropsWithLoading, WithChosen, withLoading } from "hoc";

import { Payload as PayloadSetClickedLink } from "store/routines/settings/setClickedLink";
import { RootState } from "store/reducers";
import { setClickedLink, usersChose } from "store/actions";
import { tableHeader } from "./TableConfig";

const cnTableUsers = cn("TableUsers");

type Props = PropsWithLoading &
  PropsWithChosen & {
    chosen: string[];
    isLoading: boolean;
    list: typeUser[];
    setClickedLink: (payload: PayloadSetClickedLink) => void;
    usersChose: (payload: PayloadChoseFunction) => void;
  };

const TableUsers: FC<Props> = ({
  chosen,
  handleChosenAllItems,
  handleUpdateChosenItems,
  isLoading,
  list,
  setClickedLink,
  setIsLoading,
  usersChose,
}) => {
  const { t } = useTranslation();

  const themeCheckAll = useMemo(() => (chosen.length < (list ? list.length : 0) && chosen.length > 0 ? "squre" : ""), [chosen, list]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const handleChosen = (id: string, tab: number) => {
    handleUpdateChosenItems(usersChose, !(chosen.indexOf(id) >= 0), id, true);
    setClickedLink({ clickedLink: tab });
  };

  return (
    <div className={cnTableUsers()}>
      <div className={cnTable("Head Table-Head-Small")}>
        <div className={cnTable("Head-Th")}>
          <Checkbox
            checked={chosen.length > 0 && chosen[0] !== "new"}
            id="checkbox_check_all"
            onChange={(status: boolean) => {
              handleChosenAllItems(
                usersChose,
                status,
                list.map(({ id }: typeUser) => id),
              );
            }}
            theme={themeCheckAll}
          />
        </div>
        {tableHeader.map(({ id, title, sortInit }: typeTableHeaderElement, index: number) => (
          <ButtonDiv className={cnTable("Head-Th", { active: false })} onClick={() => ({})} id={getKey(id, index)} key={id}>
            <span className={cnTable("ThTitle")}>{t(`table_title-${title}`)}</span>
            {false && <Icon className={cnTable("SortIcon", [DIRECTION.ASC])} name="colored/icon-sort-down" />}
          </ButtonDiv>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {list.length > 0 &&
            list.map(({ id, username, roles, groups, email, firstName, lastName, avatar }: typeUser, index) => {
              const lastNameValue = isEmpty(lastName) ? "" : lastName;
              const firstNameValue = firstName === "Name" ? username : firstName;
              return (
                <div key={id} className={cnTable("Row", { chosen: chosen.indexOf(id) >= 0 })} id={getKey("table_row_user", index)}>
                  <div className={cnTableUsers("Checkbox Table-Th")}>
                    <Checkbox
                      checked={chosen.indexOf(id) >= 0}
                      onChange={(status: boolean) => handleUpdateChosenItems(usersChose, status, id)}
                    />
                  </div>
                  <div className={cnTableUsers("Icon Table-Th")}>
                    <Icon name="icon-user-circle" width={28} height={28} />
                  </div>
                  <div
                    className={cnTableUsers("Title Table-Th")}
                    onClick={() => handleChosen(id, 1)}
                    id={getKey("btn_dev_chosen_user_1", index)}
                  >
                    <div className={cnTableUsers("Title-Column")}>
                      <p>{`${firstNameValue} ${lastNameValue}`}</p>
                      <span>{email}</span>
                    </div>
                  </div>

                  <div className={cnTableUsers("Role Table-Th")}>{mapRolesName[roles]}</div>

                  <div className={cnTableUsers("Groups Table-Th")} onClick={() => handleChosen(id, 3)}>
                    <div className={cnTableUsers("Groups-Item", { isEmpty: groups.length === 0 })}>
                      <Icon name="icon-group" />
                      <span>{groups.length}</span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ users: { list, chosen, isLoading } }: RootState) => ({
  chosen,
  isLoading,
  list,
});

const mapDispatchToProps = { usersChose, setClickedLink };

export default compose<Props, Partial<Props>>(WithChosen, withLoading, connect(mapStateToProps, mapDispatchToProps))(TableUsers);
