import { all, fork } from "redux-saga/effects";

import { saga as setGlobalSearch } from "store/routines/search/setGlobalSearch";
import { saga as getKeysSearch } from "store/routines/search/getKeysSearch";
import { saga as getFoldersSearch } from "store/routines/search/getFoldersSearch";
import { saga as removeKeyInSearch } from "store/routines/search/removeKeyInSearch";
import { saga as removeFolderInSearch } from "store/routines/search/removeFolderInSearch";

export default function* keys() {
  return yield all([
    fork(setGlobalSearch),
    fork(getKeysSearch),
    fork(getFoldersSearch),
    fork(removeKeyInSearch),
    fork(removeFolderInSearch),
  ]);
}