import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "ss-ui";

import { FormSwitch } from "components/Form";
import { Modal, cnModal } from "components/Generic";

import { cnSettings } from "pages/Settings";

import { Payload as PayloadSetBestIcon } from "store/routines/settings/setBestIcon";
import { Payload as PayloadSetBestIconCheck } from "store/routines/settings/setBestIconCheck";
import { RootState } from "store/reducers";
import { setBestIcon, setBestIconCheck } from "store/actions";

type Props = {
  bestIconEnabled?: boolean;
  hasEmptyLogos?: boolean;
  setBestIcon: (payload: PayloadSetBestIcon) => void;
  setBestIconCheck: (payload: PayloadSetBestIconCheck) => void;
};

const BestIcon: FC<Props> = ({ bestIconEnabled = false, hasEmptyLogos, setBestIcon, setBestIconCheck }) => {
  const { t } = useTranslation();

  const handleUpdate = (value: boolean) => {
    setBestIcon({
      bestIcon: value,
    });
  };

  const handleUpdateIcon = (isCancel: boolean) => {
    setBestIconCheck({ isCancel });
  };

  return (
    <>
      <div className={cnSettings("Container")}>
        <div className={cnSettings("SwitchItem")} id="container_best_icon_services">
          <div className={cnSettings("Header")}>
            {t("settings_header_bestIcon")}
            <div className={cnSettings("Switch")}>
              <FormSwitch isOn={bestIconEnabled} handleToggle={handleUpdate} fieldName="iconSwitch" />
              {bestIconEnabled ? (
                <p>{t("settings_switch_on")}</p>
              ) : (
                <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
              )}
            </div>
          </div>
          <div className={cnSettings("Hint")}>{t("settings_hunt_bestIcon")}</div>
        </div>
      </div>
      {hasEmptyLogos && (
        <Modal handleClose={() => handleUpdateIcon(true)} className={cnModal("Confirmation Modal-Confirmation-Small")}>
          <div className={cnModal("Header")}>{t("button-Confirm")}</div>
          <div className={cnModal("Scroll")}>
            <p className={cnModal("ConfirmationText")}>{t("settings_bestIcon_text")}</p>
          </div>
          <div className={cnModal("Actions")}>
            <Button id="button_update_icon_confirm" onClick={() => handleUpdateIcon(false)}>
              {t("button-Confirm")}
            </Button>
            <Button id="button_update_icon_cancel" theme="primary-outline" onClick={() => handleUpdateIcon(true)}>
              {t("button-Cancel")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({ settings: { bestIconEnabled, hasEmptyLogos } }: RootState) => ({
  bestIconEnabled,
  hasEmptyLogos,
});

const mapDispatchToProps = {
  setBestIcon,
  setBestIconCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(BestIcon);
