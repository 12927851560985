/* eslint-disable no-nested-ternary, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { cn } from "@bem-react/classname";

import { ButtonDiv } from "components/Generic";

import { emptyFolder, findFolderInTree, getKey, replaceUrlParam, ROLE_TYPE, Routes, typeFolder } from "core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FolderIcon, Icon } from "ss-ui";

import { RootState } from "store/reducers";
import { get } from "lodash";

export const cnSingle = cn("Single");

type Props = {
  folders: typeFolder[];
  role: ROLE_TYPE;
};

const Single: FC<Props> = ({ folders, role,  }) => {
  const route = useRouteMatch(Routes.Keys);
  const folderID = get(route, "params.folderID", undefined);

  const { push } = useHistory();
  const { t } = useTranslation();

  const isManagersUser = useMemo(() => [ROLE_TYPE.MANAGER, ROLE_TYPE.ADMIN].includes(role), [role]);
  const isPersonalRootActive = useMemo(() => !folderID && !isManagersUser, [folderID, isManagersUser]);

  const folder: typeFolder = useMemo(
    () =>
      findFolderInTree(folders, folderID) ||
      (isPersonalRootActive && folders[0]) || {
        ...emptyFolder,
        children: [...folders],
      },
    [folders, folderID, isPersonalRootActive],
  );

  const { root, title, countKeys, children, type, access, level_security } = folder;

  const handleClickFolder = ({ root, id }: typeFolder, back: boolean) => {
    const targetID = back ? root : id;
    push(targetID ? replaceUrlParam(Routes.Keys, "folderID", targetID) : Routes.FolderManagement);
    const layoutElement = document.querySelector(".Layout-Container");
    if (layoutElement) {
      layoutElement.scrollTo({ top: 0, left: 0 });
    }
  };

  return (
    <div className={cnSingle()} id={getKey(`menu_list_folder_item_${title}_wrapper`)}>
      {folder.title && (
        <div className={cnSingle("Folder", { isOpen: true })} id={getKey(`menu_list_folder_item_${title}`)}>
          {!isManagersUser && root === null ? (
            <div className={cnSingle("Space")}>&nbsp;</div>
          ) : (
            <ButtonDiv
              className={cnSingle("Back")}
              onClick={() => handleClickFolder(folder, true)}
              id={getKey(`menu_list_folder_item_${title}_btn_open`)}
            >
              <Icon fill="#727272" name="icon-pointer-left" width={14} height={15} />
            </ButtonDiv>
          )}
          <div className={cnSingle({ No_access: type === "group" && access === "no_access" })}>
            <FolderIcon isGroup={type === "group"} isRoot={root === null && type !== "group"} level={level_security} size={30} />
          </div>
          <div className={cnSingle("Title")}>
            {folder.title}
            <div className={cnSingle("Count")}>
              {countKeys} {t("navigation_keys")}
            </div>
          </div>
        </div>
      )}
      <div className={cnSingle("Children")} id={getKey(`menu_list_folder_item_${folder.title}_children`)}>
        {children.map((folder: typeFolder) => {
          const { id, title, countKeys, type, level_security, access } = folder;
          return (
            <ButtonDiv
              className={cnSingle("Folder")}
              onClick={() => handleClickFolder(folder, false)}
              id={getKey(`menu_list_folder_item_${folder.title}_children_title_btn_open`)}
              key={id}
            >
              <div className={cnSingle("Space")}>&nbsp;</div>
              <div className={cnSingle({ No_access: type === "group" && access === "no_access" })}>
                <FolderIcon isGroup={type === "group"} isRoot={root === null && type !== "group"} level={level_security} size={30} />
              </div>
              <div className={cnSingle("Title")} id={getKey(`menu_list_folder_item_${folder.title}_children_title`)}>
                {title}
                <div className={cnSingle("Count")}>
                  {countKeys} {t("navigation_keys")}
                </div>
              </div>
            </ButtonDiv>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ folders: { list }, user: { role } }: RootState) => ({
  folders: list,
  role,
});

export default connect(mapStateToProps)(Single);
