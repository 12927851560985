import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Button } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { cnField } from "components/Form";
import { RootState } from "store/reducers";
import { Payload as payloadSetCustomizeMailerCheck } from "store/routines/settings/setCustomizeMailerCheck";
import { setCustomizeMailerCheck } from "store/actions";
import { LoadingContainer, Modal, cnModal } from "components/Generic";
import { isValidEmail } from "core";

type Props = {
  mailerData: any;
  isLoading: boolean;
  handleCloseModal: (value: boolean) => void;
  setCustomizeMailerCheck: (payload: payloadSetCustomizeMailerCheck) => void;
}

const CustomizeMailerModal: FC<Props> = ({
  mailerData,
  isLoading,
  handleCloseModal,
  setCustomizeMailerCheck,
}: Props) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFocus();
  }, []);

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  /**
   * Send check mails status
   */
  const handleStatusCheck = () => {
    setCustomizeMailerCheck({
      dataValue: mailerData,
      emailTo: inputValue,
    });
    handleCloseModal(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);

    setErrorEmail(!isValidEmail(value));
  };

  return (
    <Modal
      className={ cnModal("ModalFolderSelect") }
      handleClose={ () => handleCloseModal(false) }
    >
      <LoadingContainer isLoading={ isLoading }>
        <div className={ cnModal("Header") }>
          {t("customize_mail_modal_title")}
        </div>
        <div className={ cnModal("Scroll") }>
          <div className={ `${cnField()} ${cnField("Input", { hasError: errorEmail })} Required` } >
            <span
              className="Label"
              id="input_text_mailerUser_label"
            >
              {t("customize_mail_modal_field_email")}
            </span>
            <div className={ `${cnField("InputWrapper")} "text"` }>
              <input
                ref={ inputRef }
                id="emailTestSend"
                placeholder={ t("form_input-email") }
                type="input"
                onChange={ handleInputChange }
              />
            </div>
          </div>
        </div>
        <div className={ cnModal("Actions") }>
          <Button
            disabled={ errorEmail || !inputValue }
            id="button_action_save"
            onClick={ handleStatusCheck }
          >
            {t("customize_mail_modal_button")}
          </Button>
          <Button
            id="button_action_cancel"
            theme="secondary-outline"
            onClick={ () => handleCloseModal(false) }
          >
            {t("button-Cancel")}
          </Button>
        </div>
      </LoadingContainer>
    </Modal>
  );
};

const mapStateToProps = ({ settingsTest: { mailerSettings } }: RootState) => ({ mailerSettings });

const mapDispatchToProps = { setCustomizeMailerCheck };

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeMailerModal);

