import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnInfoBox } from "components/InfoBox";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const InfoBoxActions: FC<IProps> = ({ children, className = "", ...props }) => {
  return (
    <div className={`${cnInfoBox("Actions")} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default InfoBoxActions;