import { generatePassword, ifStringInclude } from "core";
import { cnSettings } from "pages/Settings";
import React, { ChangeEvent, FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FolderIcon } from "ss-ui";

type Props = {
  setFile: (file: any) => void;
  file?: File;
  label?: string;
  allowedFormat?: string[];
};

const ConveyanceFile: FC<Props> = ({ setFile, file, label, allowedFormat }) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string>("");
  const uniqID: string = useMemo(() => generatePassword(10), []);

  const handleChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (files && files[0] && allowedFormat && ifStringInclude(files[0].type, allowedFormat)) {
      setFile(files[0]);
      setError("");
    } else if (files === null || files.length === 0) {
      setFile("");
      setError("");
    } else {
      setFile("");
      setError(t("settings_file_format_error"));
    }
  };

  return (
    <div className={cnSettings("ConveyanceFile")}>
      <label>{label || t("settings_key_select_file")}</label>
      <div className={cnSettings("UploadFieldWrapper")}>
        <div className={cnSettings("UploadField")}>
          <FolderIcon size={16} />
          {file && <span className={cnSettings("UploadFieldName")}>{file.name}</span>}
          <input
            type="file"
            name={uniqID}
            id={uniqID}
            onChange={handleChange}
            accept={allowedFormat ? allowedFormat.map(item => `.${item}`).join(",") : "*"}
          />
        </div>
        <label htmlFor={uniqID}>{t("settings_key_select")}</label>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default ConveyanceFile;
