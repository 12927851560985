import useLocalStorage from "core/hook/useLocalStorage";
import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";

import { FOLDER_STRUCTURE, getUserEnvKey, LOCAL_STORAGE, typeFolder } from "core";
import { LoadingContainer } from "components/Generic";

import { RootState } from "store/reducers";

import { Single } from "./Single";
import { Tree } from "./Tree";

export const cnFolders = cn("Folders");

type Props = {
  folders: typeFolder[];
  username?: string;
  isLoading: boolean;
};

const Folders: FC<Props> = ({ folders = [], username, isLoading }) => {
  const [folderStructure] = useLocalStorage<FOLDER_STRUCTURE>(
    getUserEnvKey(LOCAL_STORAGE.FOLDER_STRUCTURE, username),
    FOLDER_STRUCTURE.TREE,
  );

  return (
    <div className={cnFolders()} id="menu_folders_wrapper">
      <LoadingContainer isLoading={isLoading}>
        {folderStructure === FOLDER_STRUCTURE.TREE ? (
          folders.map((folder: typeFolder) => <Tree folder={folder} key={folder.id} />)
        ) : (
          <Single />
        )}
      </LoadingContainer>
    </div>
  );
};

const mapStateToProps = ({ folders: { list: folders, isLoading }, user: { username } }: RootState) => ({
  username,
  folders,
  isLoading,
});

export default connect(mapStateToProps, {})(Folders);
