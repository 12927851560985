/* eslint-disable react-hooks/exhaustive-deps, no-return-assign, no-param-reassign */

import moment from "moment";
import { get } from "lodash";

import {
  FIELD_TYPE,
  SECURE_FIELDS_LIST,
  SecurityServices,
  apiCallSecurityPassword,
  parseDate,
  typeFolder,
  typeKey,
  typeSearchKey,
  typeTemplate,
} from "core";

/**
 * Get last key by updated at date time
 * @param keys - keys flat list typeKey
 */
export function getLastKeyFromFlatKeyList(keys: typeKey[]): typeKey | undefined {
  if (keys && keys.length === 0) {
    return undefined;
  }
  const listKeys: typeKey[] = [...keys];
  return listKeys.sort((a, b) => moment(parseDate(b.updatedAt)).diff(moment(parseDate(a.updatedAt))))[0];
}

/**
 * Decrypt key in folders tree with folder child
 * @param tree - array folders tree typeFolder[]
 */
export function decryptKeysInFoldersList(tree: typeFolder[]): typeFolder[] {
  return tree.map((folder: typeFolder) => {
    const decryptFolder: typeFolder = { ...folder };

    if (folder.children && folder.children.length > 0) {
      decryptFolder.children = [...decryptKeysInFoldersList(folder.children)];
    }

    if (folder.key && folder.key.length > 0) {
      decryptFolder.key = decryptFolder.key.map((key: typeKey) => SecurityServices.decryptKey(key));
    }

    return decryptFolder;
  });
}

/**
 * Get key from array by key ID, search by find function
 * @param keys - array of keys
 * @param id - id searchable key
 */
export function getKeyFromArrayByID(keys: typeKey[], id: string): typeKey | undefined {
  const key = keys.filter((key: typeKey) => key.id === id);
  return key.length === 0 ? undefined : key[0];
}

/**
 * Unpack keys array, parse key data from string to array
 * @param {typeKey[]} keys
 * @return typeKey[]
 */
export function unpackKeys(keys: typeKey[]): typeKey[] {
  return keys.map(unpackKey);
}

/**
 * Parse key data from string to array
 * @param {typeKey} key
 * @return typeKey
 */
export function unpackKey(key: typeKey): typeKey {
  return {
    ...key,
    ...JSON.parse(key.data || "{}"),
    ...key.keyFiles.reduce((acc, file) => ({ ...acc, [file.propertyName]: file.fileName }), {}),
  };
}

/**
 * Unpack keys array, parse key data from string to array
 * @param {typeSearchKey[]} keys
 * @return typeSearchKey[]
 */
export function unpackSearchKeys(keys: typeSearchKey[]): typeSearchKey[] {
  return keys.map(unpackSearchKey);
}

/**
 * Parse key data from string to array
 * @param {typeSearchKey} key
 * @return typeSearchKey
 */
export function unpackSearchKey(key: typeSearchKey): typeSearchKey {
  return {
    ...key,
    ...JSON.parse(key.data || "{}"),
  };
}

/**
 * Get template icon by template name
 *
 * @param {string} template
 * @return string
 */
export function getTemplateIcon(template: string): string {
  switch (template) {
    case "login_template":
      return "icon-key-small";
    case "credit_card_template":
      return "icon-credit-card";
    case "bank_account_template":
      return "icon-bank";
    case "customer_card_template":
      return "icon-qr-code";
    case "id_card_template":
      return "icon-id-card-2";
    case "license_template":
      return "icon-licence";
    case "server_access_template":
      return "icon-server";
    case "aws_access_template":
      return "icon-aws";
    case "email_template":
      return "icon-envelop";
    case "sap_login_template":
      return "icon-key-small";
    case "sap_rfc_template":
      return "icon-key-small";
    default:
      return "icon-key-small";
  }
}

/**
 * Get field value by key, and decrypt it if it in encryption list
 *
 * @param {FIELD_TYPE} field
 * @param {?typeKey} key
 * @return {Promise<string>}
 */
export function getFieldValue(field: FIELD_TYPE, key?: typeKey): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    if (!key) {
      return resolve(undefined);
    }

    if (!key || !key?.[field]) {
      return resolve(undefined);
    }

    if (!SECURE_FIELDS_LIST.includes(field)) {
      return resolve(get(key, `${field}`, undefined));
    }

    return apiCallSecurityPassword({
      password: key[field],
    })
      .then(resolve)
      .catch(reject);
  });
}

/**
 * Get field value by key, and filtering by templates
 *
 * @param {any} data
 * @param {typeTemplate | undefined} templates
 * @return {any}
 */
export function getFilterDataOnTemplate(data: any, templates: typeTemplate | undefined) {
  const dataNew = {};
  for (const field in data) {
    if (templates?.fields.includes(field as FIELD_TYPE) || field === "template") {
      dataNew[field] = data[field];
    }
  }
  return dataNew;
}