/* eslint-disable no-unneeded-ternary */
import React, { FC, useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { InfoBox, InfoBoxHeader, InfoBoxTabs, InfoBoxContent, InfoBoxActions, cnInfoBox } from "components/InfoBox";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useParams } from "react-router";
import { keyChose, keyMove, keyDelete, keyMultipleDelete } from "store/actions";
import {
  PayloadChoseFunction,
  getKeyFromArrayByID,
  typeKey,
  emptyFolder,
  // rolesAccessPaid,
  typeInfoBoxTab,
  getFolderFromTreeById,
  typeFolder,
  FOLDER_TYPE,
  ROLE_TYPE,
  replaceUrlParam,
  Routes,
  FOLDER_ACCESS,
  FORM_KEY_MOVE,
  getTemplateIcon,
  getKeyAnnotation,
} from "core";
import { RootState } from "store/reducers";
import { Multiply, Settings, Sharing } from "components/InfoBox/Key";
import { Button, Icon } from "ss-ui";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { ModalFolderSelect, cnModal, Modal, LoadingContainer } from "components/Generic";
import { toast } from "react-toastify";
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { compose } from "recompose";
import { Payload as PayloadKeyMove } from "store/routines/keys/move";
import { Payload as PayloadKeyDelete } from "store/routines/keys/delete";
import { Payload as PayloadKeyMultipleDelete } from "store/routines/keys/multipleDelete";

interface iParams {
  folderID: string;
}

type Props = {
  chosen: string[];
  isLoadingFolders: boolean;
  isLoadingKeys: boolean;
  keyList: typeKey[];
  folderList: typeFolder[];
  role: ROLE_TYPE;
  keyChose: (payload: PayloadChoseFunction) => void;
  keyMove: (payload: PayloadKeyMove) => void;
  keyDelete: (payload: PayloadKeyDelete) => void;
  keyMultipleDelete: (payload: PayloadKeyMultipleDelete) => void;
} & InjectedFormProps
  & WrappedFieldArrayProps<any>
  & RouteComponentProps<{ id: string }>;

const InfoBoxKey: FC<Props> = ({
  chosen,
  isLoadingKeys,
  isLoadingFolders,
  keyList,
  folderList,
  role,
  keyChose,
  keyMove,
  keyDelete,
  keyMultipleDelete,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { folderID } = useParams<iParams>();

  const [activeTab, setActiveTab] = useState<number>(1);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showFolderSelect, setShowFolderSelect] = useState<boolean>(false);
  const [isLoadingKey, setIsLoadingKey] = useState<boolean>(true);

  const key = getKeyFromArrayByID(keyList, chosen[0]);
  const folder = useMemo(() => getFolderFromTreeById(folderList, key ? key.mainFolderId : ""), [folderList, key]);
  const mainKeyFolder: typeFolder = useMemo(
    () => getFolderFromTreeById(folderList, key?.mainFolderId) || emptyFolder,
    [folderList, key],
  );
  const isSharingKeySearch: boolean = useMemo(() => !isEmpty(mainKeyFolder), [mainKeyFolder]);
  const isMulti = useMemo(() => chosen.length > 1, [chosen]);
  const foldersLength = useMemo(() => key && key?.folders.length > 0, [key, key?.folders]);
  const isFolderWritable = useMemo(() => folder ? FOLDER_ACCESS.WRITE === folder?.access : FOLDER_ACCESS.WRITE === mainKeyFolder.access, [folder]);
  const isSharingKey = useMemo(() => folderID && folderID !== key?.mainFolderId, [folderID, key]);
  const isSealing = useMemo(() => key && key?.sealing, [key]);
  const isSapLoginKey = useMemo(() => key && key?.template === "sap_login_template", [key]);

  const configTabs: typeInfoBoxTab[] = [
    { index: 1, title: "Settings", component: <Settings /> },
    // { index: 2, title: "History", component: <History />, tabAccess: rolesAccessPaid.includes(role) },
    { index: 2, title: "Sharing", component: <Sharing />, tabAccess: folder && folder.type === FOLDER_TYPE.PERSONAL ? false : true },
  ];

  useEffect(() => {
    if (folder && folder.type === FOLDER_TYPE.PERSONAL) {
      setActiveTab(1);
    }
  }, [folder]);

  useEffect(() => {
    setIsLoadingKey(true);
    setTimeout(() => setIsLoadingKey(false), 2000);
  }, [key]);

  const handleDelete = () => {
    if (!isMulti && folder.id) {
      keyDelete({
        folderID: folder.id,
        id: chosen[0],
      });
    }
    if (isMulti && folder.id) {
      keyMultipleDelete({
        folderID: folder.id,
        keys: chosen,
      });
    }
  };

  const handleMove = (value: string | null) => {
    if (folder.id && value && folder.id !== value) {
      keyMove({
        folderID: value,
        keys: chosen,
      });
    }
    if (folder.id && value && folder.id === value) {
      toast.warning(t("toast_warning_move_some_folder"));
    }
    if (!folder.id && value && folder.id === value) {
      toast.warning(t("toast_warning_move_some_folder"));
    }
  };

  const handleClose = () => {
    keyChose({ status: false, choseAll: true });
  };

  const handleOpenEditKey = () => {
    history.push(folder.id &&
      replaceUrlParam(replaceUrlParam(Routes.ManageKey, "id", chosen[0]), "folderID", folder.id)
    );
  }

  return (
    <>
    <InfoBox isLoading={isLoadingKeys || isLoadingFolders || isLoadingKey}>
      <InfoBoxHeader
        title={chosen.length > 1 ? t("infobox_key_selected_count_keys", { count: chosen.length }) : key?.title}
        iconName={`${getTemplateIcon(key?.template as string)}`}
        handleClose={handleClose}
        isChildren
      >
        {key && (
          <div className={cnInfoBox("Logo")}>
            {key.logo ? (
              <img alt="" className={cnInfoBox("Logo-Image")} src={key.logo} />
            ) : (
              <p className={cnInfoBox("Logo-Text")}>{getKeyAnnotation(key.title)}</p>
            )}
          </div>
        )}
      </InfoBoxHeader>
      {!isMulti && <InfoBoxTabs activeTab={activeTab} setActiveTab={setActiveTab} configTabs={configTabs} />}
      <InfoBoxContent>
        {isMulti ? <Multiply /> : configTabs[activeTab - 1].component }
      </InfoBoxContent>
      <InfoBoxActions className={cnInfoBox("Actions-Column")}>
        {isFolderWritable && (!isSharingKey || !isSharingKeySearch) && (
          <>
            {folder.id && !isMulti && (
              <Button id="button_key_action_edit" icon="icon-pencil" onClick={handleOpenEditKey} width="100%">
                {t("button-Edit")}
              </Button>
            )}
              <div className={cnInfoBox("Actions-Row", { isMargin: !isMulti })}>
                  <Button
                    id="button_key_action_move"
                    icon="icon-move-out"
                    theme="secondary-outline"
                    onClick={() => setShowFolderSelect(true)}
                    width="calc(100% - 40px)"
                    disabled={isSapLoginKey}
                  >
                    {t("button-Move")}
                  </Button>
              <Button
                id="button_key_action_trash"
                onClick={() => setShowConfirm(true)}
                icon="icon-trash"
                theme="danger-link"
              />
            </div>
          </>
        )}
      </InfoBoxActions>
    </InfoBox>
    {showFolderSelect && (
      <ModalFolderSelect
        modalTitle={t("modal-move")}
        activeFolder={folder.id}
        handleClose={setShowFolderSelect}
        type={FOLDER_TYPE.UNDEFINED}
        checkboxRootFolder={false}
        onChanges={handleMove}
        folderID={chosen[0]}
        isSharing={!!foldersLength || isSealing}
        accessArr={[FOLDER_ACCESS.WRITE]}
      />
    )}
    {showConfirm && (
      <Modal handleClose={() => setShowConfirm(false)} className={cnModal("Confirmation")}>
        <LoadingContainer isLoading={isLoadingKeys}>
          <div className={cnModal("Header")}>{t("button-Delete")}</div>
          <div className={cnModal("Scroll")}>
            <p className={cnModal("ConfirmationText")}>{t("modal-faq-key", { count: chosen.length })}</p>
            <div className={cnModal("ConfirmationList")}>
              {chosen &&
                chosen.map((id: string) => {
                  const key = getKeyFromArrayByID(keyList, id);

                  return key && (
                    <div className={cnModal("ConfirmationItem")} key={id}>
                      <Icon name={getTemplateIcon(key.template)} width={20} />
                      <div className={cnModal("ConfirmationItemName")}>{key.title}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={cnModal("Actions")}>
            <Button id="button_key_action_modal_delete" onClick={() => handleDelete()}>
              {t("button-Delete")}
            </Button>
            <Button id="button_key_action_modal_cancel" theme="secondary-outline" onClick={() => setShowConfirm(false)}>
              {t("button-Cancel")}
            </Button>
          </div>
        </LoadingContainer>
      </Modal>
    )}
    </>
  );
};

const mapStateToProps = ({
  folders: { isLoading: isLoadingFolders, list: folderList },
  keys: { chosen, isLoading: isLoadingKeys, list: keyList },
  user: { role },
}: RootState) => ({
  chosen,
  keyList,
  folderList,
  isLoadingFolders,
  isLoadingKeys,
  role,
});

const mapDispatchToProps = { keyChose, keyMove, keyDelete, keyMultipleDelete };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<{ folderID: string }>({
    form: FORM_KEY_MOVE,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(InfoBoxKey);
