import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

export const action: Routine = createRoutine("USER_GET_REGISTER_YUBIKEY");

const getRegisterYubikey = () => {
  return http({
    route: "user/yubikeys",
  });
};

function* handler() {
  try {
    const response = yield call(getRegisterYubikey);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(null));
  } finally {
    yield put(action.fulfill())
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
