/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps, no-unneeded-ternary */

import React, { FC, useEffect, useMemo, useState } from "react";
import { Icon, } from "ss-ui";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import { FormLazySelect, FormSelect, FormInput } from "components/Form";
import { cnMessages } from "components/Messages";

import { 
  ACTION, 
  FORM_USER_MANAGEMENT, 
  getUserFromListByID, 
  http, 
  ILanguages, 
  optionsRole, 
  optionsRoleWithAdmin, 
  ROLE_TYPE, 
  translation, 
  typeGroup, 
  typeUser, 
  Validation,
  typeAuthorizationSupports,
} from "core";

import { FormValues, Payload as PayloadUsersManagement } from "store/routines/users/manage";
import { RootState } from "store/reducers";
import { usersManagement } from "store/actions";

import { cnSettings } from "./Settings";

type Props = {
  chosen: string[];
  currentUserRole?: ROLE_TYPE;
  list: typeUser[];
  username?: string;
  authorizationSupports: typeAuthorizationSupports;
  usersManagement: (payload: PayloadUsersManagement) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<FormValues>;

const UserLDAP: FC<Props> = ({
  change,
  chosen,
  currentUserRole,
  error,
  handleSubmit,
  list,
  username,
  usersManagement,
  authorizationSupports,
}) => {
  const { t } = useTranslation();

  const [skip, setSkip] = useState<string>();
  const [limit, setLimit] = useState<number>();
  const [offset, setOffset] = useState<number>(0);

  const currentLang = localStorage.getItem("i18nextLng");
  const user: typeUser = useMemo(() => getUserFromListByID(list, chosen[0]), [list, chosen]);

  const isNew = useMemo(() => chosen[0] === "new", [chosen]);
  const isCurrentManager = useMemo(() => currentUserRole === ROLE_TYPE.MANAGER, [currentUserRole]);
  const isAdmin = useMemo(() => user && user.roles === ROLE_TYPE.ADMIN, [user]);
  const isCurrent = useMemo(() => user && user.username === username, [user, username]);

  useEffect(() => {
    change("roles", isNew ? optionsRole[0] : isAdmin ? optionsRoleWithAdmin[2] : user.roles);
    change("locale", isNew ? currentLang : user.locale);

    if (!isNew) {
      change("email", user.email);
    }

    if (!isNew) {
      change("username", { label: user.username, value: user.username });
    }
  }, [chosen]);

  const handleForm = (data: any) => {
    const payload: PayloadUsersManagement = {
      id: isNew ? undefined : user.id,
      action: isNew ? ACTION.CREATE : ACTION.UPDATE,
      payload: {
        groups: isNew ? [] : user.groups.map(({ id }: typeGroup) => id),
        locale: get(data, "locale.value", data.locale) || currentLang,
        roles: get(data, "roles.value", data.roles),
        username: get(data, "username.value", get(data, "username")),
        email: get(data, "email.value", get(data, "email")),
        azureId: isNew && authorizationSupports.microsoft ? get(data, "azureId.value", get(data, "azureId")) : undefined,
      },
    };

    usersManagement(payload);
  };

  const handleChange = (value: any) => {
    if (isNew && value.azureID) {
      change("azureId", value?.azureID);
    }
  };

  return (
    <form id="submit-users-form" onSubmit={handleSubmit(handleForm)} className={cnSettings()}>
      <Field
        component={FormLazySelect}
        label={t("form_input-username")}
        name="username"
        className="Required"
        disabled={!isNew}
        onChange={handleChange}
        placeholder={t("form_input_select-UserName")}
        loadOptions={async query => {
          let params: { q: string; skip_token?: string; limit?: number; offset?: number } = { q: query, offset };

          if (skip) params = { ...params, skip_token: skip };
          if (limit) params = { ...params, limit };
          if (query !== "") params = { ...params, offset: 0 };

          const response: any = await http({ route: "manager/users-ldap", method: "GET", payload: { params } });

          setSkip(response.skip_token);
          setLimit(response.limit);
          setOffset(response.offset + response.limit);

          return {
            hasMore: !!response.skip_token,
            options: response.users.map(user => ({
              label: `${user.firstName}${user.lastName === null ? "" : user.lastName}`,
              value: user.userName,
              azureID: user.azureId
            })),
          };
        }}
        template={(label: string): JSX.Element => (
          <div className="Users-Select">
            <Icon name="icon-user-circle" width={20} height={20} />
            <span title={label}>{label}</span>
          </div>
        )}
      />

      {!isNew && (
        <Field 
          component={FormInput} 
          label={t("form_input-Email-Notification")} 
          name="email" 
          disabled={authorizationSupports.microsoft ? false : true}
          validate={[Validation.email, Validation.required]}
        />
      )}

      <Field
        component={FormSelect}
        label={t("form_input-Role")}
        name="roles"
        disabled={isCurrent || (isAdmin && isCurrentManager)}
        placeholder={t("form_input_select-Role")}
        options={isCurrentManager ? optionsRole : optionsRoleWithAdmin}
        validate={[Validation.required]}
      />

      <Field
        component={FormSelect}
        label={t("form_input-Language")}
        name="locale"
        menuPlacement="top"
        placeholder={t("form_input_select-Language")}
        options={translation.languages.map(({ code, title }: ILanguages) => ({ value: code, label: title }))}
        validate={[Validation.required]}
        template={(label: string, value: string): JSX.Element => (
          <div className="ManageKey-Option">
            <Icon name={`colored/icon-lang-${value || "en"}`} width={16} />
            &nbsp;
            {label}
          </div>
        )}
      />

      {error && <span className={`${cnMessages()} ${cnMessages("Error")}`}>{error}</span>}

    </form>
  );
};

const mapStateToProps = ({
  users: { list, chosen },
  user: { role: currentUserRole, username },
  settings: { authorizationSupports }
}: RootState) => ({
  chosen,
  currentUserRole,
  list,
  username,
  authorizationSupports,
});

const mapDispatchToProps = { usersManagement };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_USER_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(UserLDAP);
