/* eslint-disable @typescript-eslint/no-use-before-define, no-nested-ternary */
import React, { FC, useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { InfoBox, InfoBoxHeader, InfoBoxTabs, InfoBoxContent, InfoBoxActions } from "components/InfoBox";
import { typeInfoBoxTab, USER_STORAGE, PayloadChoseFunction, typeUser, getUserFromListByID, GROUP_STORAGE } from "core";
import { Button, Icon } from "ss-ui";
import { cnModal, LoadingContainer, Modal } from "components/Generic";
import { cnMessages } from "components/Messages";
import { Payload as PayloadUsersDelete } from "store/routines/users/delete";
import { Payload as PayloadUsersDeleteMulti } from "store/routines/users/deleteMulti";
import { useTranslation } from "react-i18next";
import { usersChose, usersDelete, usersDeleteMulti } from "store/actions";
import { RootState } from "store/reducers";
import { Groups, Settings, Security } from "./Single";
import { Multiply } from "./Multiply";

const configTabs: typeInfoBoxTab[] = [
  { index: 1, title: "Settings", component: <></> },
  { index: 2, title: "Security", component: <></> },
  { index: 3, title: "Groups", component: <></> },
];

type Props = {
  chosen: string[];
  clickedLink: string | number;
  isLoading: boolean;
  error?: string | string[];
  userStorage;
  groupStorage: GROUP_STORAGE;
  usersList: typeUser[];
  usersChose: (payload: PayloadChoseFunction) => void;
  usersDelete: (payload: PayloadUsersDelete) => void;
  usersDeleteMulti: (payload: PayloadUsersDeleteMulti) => void;
};

const InfoBoxUser: FC<Props> = ({
  clickedLink,
  chosen,
  isLoading,
  userStorage,
  groupStorage,
  usersList,
  error,
  usersChose,
  usersDelete,
  usersDeleteMulti,
}) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>((clickedLink as number) || configTabs[0].index);
  const [openModalAddGroups, setOpenModalAddGroups] = useState<boolean>(false);

  const user: typeUser = getUserFromListByID(usersList, chosen[0]);
  const isNew = useMemo(() => chosen[0] === "new", [chosen]);
  const isMulti = useMemo(() => chosen.length > 1, [chosen]);
  let tabs: typeInfoBoxTab[] = [];

  useEffect(() => {
    if (isNew) {
      setActiveTab(1);
    }
  }, [isNew]);

  if (isNew) {
    tabs = userStorage === USER_STORAGE.LOCAL ? [configTabs[0], configTabs[1]] : [configTabs[0]];
  } else {
    tabs = userStorage === USER_STORAGE.LOCAL ? configTabs : [configTabs[0], configTabs[2]];
  }

  const handleOpenModalAddGroups = (value: boolean) => {
    setOpenModalAddGroups(value);
  };

  const handleClose = () => {
    usersChose({ status: false, choseAll: true });
  };

  const handleDelete = () => {
    if (chosen.length === 1) {
      usersDelete({ id: chosen[0] });
    } else if (chosen.length > 1) {
      usersDeleteMulti({ ids: chosen });
    }
  };

  return (
    <>
      <InfoBox isLoading={isLoading}>
        <InfoBoxHeader
          title={
            isNew
              ? t("users-NewUser")
              : isMulti
              ? `${t("multiply-name")} ${chosen.length} ${t("nav-Users")}:`
              : `${user.firstName || ""} ${user.lastName || ""}`
          }
          iconName="icon-user-circle"
          handleClose={handleClose}
        />
        {!isMulti && <InfoBoxTabs activeTab={activeTab} setActiveTab={setActiveTab} configTabs={tabs} />}
        <InfoBoxContent>
          {isMulti && <Multiply />}
          {!isMulti && activeTab === 1 && <Settings setActiveTab={setActiveTab} />}
          {!isMulti && activeTab === 2 && <Security setActiveTab={setActiveTab} />}
          {!isMulti && activeTab === 3 && <Groups handleModalGroups={handleOpenModalAddGroups} modalGroupsValue={openModalAddGroups} />}
        </InfoBoxContent>
        <InfoBoxActions>
          {activeTab === 1 && (
            <Button id="button_settings_action_save" form="submit-users-form" type="submit">
              {t("button-Save")}
            </Button>
          )}
          {activeTab === 2 && (
            <Button id="button_security_action_save" type="submit" form="submit-security-form" disabled={isLoading}>
              {t("button-Save")}
            </Button>
          )}
          {activeTab === 3 && ["local", "ldap"].includes(groupStorage) ? (
            <Button id="button_group_action_edit" onClick={() => handleOpenModalAddGroups(!openModalAddGroups)}>
              {t("button-Edit")}
            </Button>
          ) : (
            <span>&nbsp;</span>
          )}
          {!isNew && <Button id="button_user_show_confirm" theme="danger-link" icon="icon-trash" onClick={() => setShowConfirm(true)} />}
        </InfoBoxActions>
      </InfoBox>
      {showConfirm && (
        <Modal handleClose={() => setShowConfirm(false)} className={cnModal("Confirmation")}>
          <LoadingContainer isLoading={isLoading}>
            <div className={cnModal("Header")}>{t("button-Delete")}</div>
            <div className={cnModal("Scroll")}>
              <p className={cnModal("ConfirmationText")}>{t("modal-faq-user", { count: chosen.length })}</p>
              <div className={cnModal("ConfirmationList")}>
                {chosen &&
                  chosen.map((id: string) => {
                    const user = getUserFromListByID(usersList, id);
                    return (
                      <div className={cnModal("ConfirmationItem")} key={id}>
                        <Icon name="icon-user-circle" width={30} height={30} />
                        {user && `${user.firstName} ${user.lastName ? user.lastName : ""}`}
                      </div>
                    );
                  })}
              </div>
              <p className={cnModal("ConfirmationText", ["red"])}>{t("modal-error-user")}</p>
              {error && <span className={`${cnMessages()} ${cnMessages("Error")}`}>{error}</span>}
            </div>
            <div className={cnModal("Actions")}>
              <Button id="button_key_action_delete" onClick={() => handleDelete()}>
                {t("button-Delete")}
              </Button>
              <Button id="button_key_action_cancel" theme="secondary-outline" onClick={() => setShowConfirm(false)}>
                {t("button-Cancel")}
              </Button>
            </div>
          </LoadingContainer>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({
  users: { chosen, isLoading, list: usersList, error },
  settings: { clickedLink, userStorage, groupStorage },
}: RootState) => ({
  chosen,
  error,
  usersList,
  clickedLink,
  isLoading,
  userStorage,
  groupStorage,
});

const mapDispatchToProps = { usersChose, usersDelete, usersDeleteMulti };

export default connect(mapStateToProps, mapDispatchToProps)(InfoBoxUser);
