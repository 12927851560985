import { Action } from "redux-actions";

import { IManageSessionsRequest } from "core";

import { closeUsersSession, closeUsersSessions, getUsersSessions } from "store/actions/manager";

export interface State {
  activeSessions: IManageSessionsRequest;
  isLoading: boolean;
  error?: string;
}

const initialState: State = {
  activeSessions: {
    count: 0,
    limit: 0,
    offset: 0,
    tokens: [],
    user: null,
  },
  isLoading: false,
  error: undefined,
};

export default function user(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case closeUsersSession.TRIGGER:
    case closeUsersSessions.TRIGGER:
    case getUsersSessions.TRIGGER:
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };

    case closeUsersSession.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case closeUsersSessions.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case getUsersSessions.SUCCESS:
      return {
        ...state,
        activeSessions: payload,
        isLoading: false,
      };

    case closeUsersSession.FAILURE:
    case closeUsersSessions.FAILURE:
    case getUsersSessions.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
