import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = {
  search: string;
};

export const action: Routine = createRoutine("SET_GLOBAL_SEARCH");

function* handler({ payload }: Action<Payload>) {
  try {
    yield put(action.success(payload.search));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}