import React, { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Routes, saveDataToLS, LOCAL_STORAGE } from "core";

import { Payload as PayloadSetGlobalSearch } from "store/routines/search/setGlobalSearch";
import { setGlobalSearch } from "store/actions";

const cnHeaderSearch = cn("HeaderSearch");

type Props = {
  setGlobalSearch: (payload: PayloadSetGlobalSearch) => void;
};

const clearValue = value => value.replace(/^.*?:\/\//, "").replace(/\\|\//g, "");

const HeaderSearch: FC<Props> = ({ setGlobalSearch }) => {
  const { t } = useTranslation();

  const { push } = useHistory();

  const searchRef = useRef<HTMLInputElement>(null);

  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
  const [valueSearch, setValueSearch] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    handleCloseSearch();
  }, [window.location.pathname]);

  const handleSearch = () => {
    if (!currentSearchValue || clearValue(currentSearchValue).length < 2) {
      toast.error(t("validate_min_length", { min: 2 }), { toastId: "min-3-length" });
      return;
    }
    if (clearValue(currentSearchValue).length > 50) {
      toast.error(t("validate_max_length", { max: 50 }), { toastId: "min-3-length" });
      return;
    }
    if (valueSearch !== currentSearchValue) {
      setGlobalSearch({ search: clearValue(currentSearchValue) });
      saveDataToLS(LOCAL_STORAGE.LAST_SEARCH_VALUE, { search: currentSearchValue });
      push(Routes.Search);
    }
    setValueSearch(currentSearchValue);
  };

  const handleOpenSearch = () => {
    setIsOpen(!isOpen)
    searchRef.current?.focus();
  }

  const handleCleanValue = () => {
    if (!location.pathname.includes("search")) {
      setCurrentSearchValue("");
      setIsOpen(false);
    } else {
      setCurrentSearchValue("");
    }
  }

  const handleCloseSearch = () => {
    if (!window.location.pathname.includes("search")) {
      setCurrentSearchValue("");
      setValueSearch("");
      setIsOpen(false);
    }
  }

  return (
    <ClickAwayListener onClickAway={handleCloseSearch}>
      <div className={cnHeaderSearch({ isOpen })} id="page_header_search">
        <input
          onChange={e => setCurrentSearchValue(e.target.value)}
          onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => event.key === "Enter" && handleSearch()}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => event.key === "Escape" && handleCloseSearch()}
          placeholder={t("search_placeholder")}
          ref={searchRef}
          type="text"
          value={currentSearchValue}
          id="page_header_search_input"
        />
        <button type="button" className={cnHeaderSearch("Button")} onClick={() => handleOpenSearch()} id="page_header_search_btn_submit">
          <Icon name="icon-search" width={25} height={25} />
        </button>
        {!!currentSearchValue && (
          <button type="button" className={cnHeaderSearch("Clear")} onClick={handleCleanValue} id="page_header_search_btn_clear">
            <Icon name="icon-close" fill="#A7A7A8" width={16} height={16} />
          </button>
        )}
      </div>
    </ClickAwayListener>
  );
};

const mapDispatchToProps = { setGlobalSearch };

export default connect(null, mapDispatchToProps)(HeaderSearch);
