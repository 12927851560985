export const FORM_LOGIN = "FORM_LOGIN";
export const FORM_GET_QR_CODE = "FORM_GET_QR_CODE";
export const FORM_INSTALL_PASSWORD = "FORM_INSTALL_PASSWORD";
export const FORM_RESET_PASSWORD = "FORM_RESET_PASSWORD";
export const FORM_RESET_ADMIN_PASSWORD = "FORM_RESET_ADMIN_PASSWORD";
export const FORM_KEY_MANAGEMENT = "FORM_KEY_MANAGEMENT";
export const FORM_GROUP_MANAGEMENT = "FORM_GROUP_MANAGEMENT";
export const FORM_FOLDER_MANAGEMENT = "FORM_FOLDER_MANAGEMENT";
export const FORM_FOLDER_FILTER = "FORM_FOLDER_FILTER";
export const FORM_KEY_MOVE = "FORM_KEY_MOVE";
export const FORM_USER_MANAGEMENT = "FORM_USER_MANAGEMENT";
export const FORM_LOCAL_USER_MANAGEMENT = "FORM_LOCAL_USER_MANAGEMENT";
export const FORM_BACKUP = "FORM_BACKUP";
export const FORM_CUSTOMERS = "FORM_CUSTOMERS";
export const FORM_CUSTOMERS_FOOTER = "FORM_CUSTOMERS_FOOTER";
export const FORM_CUSTOMERS_MAILER = "FORM_CUSTOMERS_FOOTER_MAILER";
export const FORM_OTP_ADMIN = "FORM_OTP_ADMIN";
export const FORM_GLOBAL_OTP = "FORM_GLOBAL_OTP";
