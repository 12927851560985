/* eslint-disable @typescript-eslint/no-unused-vars */
import Color from "color";
import { cn } from "@bem-react/classname";
import DatePicker from "react-datepicker";
import { de, enUS } from "date-fns/locale";
import React, { FC, useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { Icon } from "ss-ui";
import { cnField } from "components/Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Filter, cnFilter } from "components/Layout";
import { customSelectStyles } from "components/Form/Select";

import { IHistoryFilter, operationOptions, PRODUCT_TYPE, productsEnterprise, typeHistoryRoute, sealingOptions, ROLE_TYPE } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

const cnHistory = cn("History");

type HistoryFilterProps = {
  customize?: SettingsCustomizeState;
  filters: IHistoryFilter;
  handleFilter: () => void;
  handleFilterCancel: () => void;
  productType?: PRODUCT_TYPE;
  setFilters: (filters: IHistoryFilter) => void;
  setIsShow: (status: boolean) => void;
  isManager: boolean;
};

const HistoryFilter: FC<HistoryFilterProps> = ({
  customize,
  filters,
  handleFilter,
  handleFilterCancel,
  productType = PRODUCT_TYPE.STANDARD,
  setFilters,
  setIsShow,
  isManager,
}) => {
  const { t } = useTranslation();

  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  const templateTypeOptions = ({ value, label }) => {
    const iconCreate = getIconOptions(value);
    return (
      <div className="ManageKey-Option">
        <div className={`${cnHistory(`Circle Circle-${iconCreate.circleClass}`)} ${cnHistory("Circle-Small")}`}>
          <Icon width={10} height={10} name={iconCreate.actionIcon} fill="#ffffff" />
        </div>
        &nbsp;
        <p title={label}>{label}</p>
      </div>
    )
  };

  const getIconOptions = (nameOptions: string) => {
    switch (nameOptions) {
      case "update":
        return {
          circleClass: "Yellow",
          actionIcon: "icon-pencil",
        }
      case "create":
        return {
          circleClass: "Green",
          actionIcon: "icon-plus"
        }
      case "delete":
        return {
          circleClass: "Red",
          actionIcon: "icon-trash"
        }
      
      case "restore":
        return {
          circleClass: "Blue", 
          actionIcon: "icon-update"
        }
      default:
        return {
          circleClass: "Yellow",
          actionIcon: "icon-pencil",
        }
    }
  }

  const currentLang = localStorage.getItem("i18nextLng");

  const handleRemoveOperation = (name: string) => {
    setFilters({ ...filters, operations: filters.operations.filter((item: any) => item !== name) });
  }

  const isProductsEnterprise = useMemo<boolean>(() => productsEnterprise.includes(productType), [productType]);

  return (
    <>
      <Filter handleFilterCancel={handleFilterCancel} filterClose={(value: boolean) => setIsShow(value)} handleFilter={handleFilter}>
        <div className={cnFilter("Field")}>
          <label>{t("history_filter_period_label")}</label>
          <div className={cnFilter("Field-Date")}>
            <DatePicker
              onChange={date => setFilters({ ...filters, period: { ...filters.period, start: date } })}
              startDate={filters.period.start}
              selected={filters.period.start}
              endDate={filters.period.end}
              maxDate={filters.period.end}
              dateFormat="dd.MM.yyyy"
              id="history_filter_date_picker_period_start"
              selectsStart
              locale={currentLang === "de" ? de : enUS}
            />
            &nbsp;—&nbsp;
            <DatePicker
              onChange={date => setFilters({ ...filters, period: { ...filters.period, end: date } })}
              startDate={filters.period.start}
              minDate={filters.period.start}
              selected={filters.period.end}
              endDate={filters.period.end}
              dateFormat="dd.MM.yyyy"
              id="history_filter_date_picker_period_end"
              selectsEnd
              locale={currentLang === "de" ? de : enUS}
            />
          </div>
        </div>
        <div className={cnFilter("Field")}>
          <label>{t("history_filter_operations_label")}</label>
          <Select
            className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
            classNamePrefix={cnFilter("Select")}
            isSearchable={false}
            isMulti
            id="history_filter_select_filter_operation"
            styles={customSelectStyles({ isMultiple: false }, mainColor)}
            options={operationOptions}
            controlShouldRenderValue={false}
            menuPlacement="top"
            value={operationOptions.filter(option => filters.operations.indexOf(option.value as typeHistoryRoute) >= 0)}
            placeholder={`${t("form_input-Select")}...`}
            onChange={options => setFilters({ ...filters, operations: options.map(({ value }) => value as typeHistoryRoute) || [] })}
            formatOptionLabel={templateTypeOptions}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#EAFBFD",
                primary75: mainColor,
                primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
              },
            })}
          />
          {filters.operations.map((item) => {
            const iconCreate = getIconOptions(item);
            return (
              <div key={item} className={cnFilter("SelectItem")}>
                <div className={cnFilter("SelectItem-Name")}>
                  <div className={`${cnHistory(`Circle Circle-${iconCreate.circleClass}`)} ${cnHistory("Circle-Small")}`}>
                    <Icon width={10} height={10} name={iconCreate.actionIcon} fill="#ffffff" />
                  </div>
                  <span>{t(`history_filter_operations_option_${item}`)}</span>
                </div>
                <button type="button" onClick={() => handleRemoveOperation(item)} id="btn_handle_remove_role">
                  <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
                </button>
              </div>
            )
          })}
        </div>
        {isProductsEnterprise && isManager && (
          <div className={cnFilter("Field")}>
            <label>
              <Icon name="icon-sealing" width={16} height={16} /> {t("history_filter_sealing_label")}
            </label>
            <Select
              isSearchable={false}
              styles={customSelectStyles({ isMultiple: false }, mainColor)}
              options={sealingOptions}
              value={sealingOptions.filter(option => option.value === filters.sealing)}
              defaultValue={sealingOptions[0]}
              menuPlacement="top"
              onChange={option => setFilters({ ...filters, sealing: (option?.value as string) || "" })}
              theme={theme => ({
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#EAFBFD",
                  primary75: mainColor,
                  primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                  primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                },
              })}
            />
          </div>
        )}
      </Filter>
    </>
  );
};

const mapStateToProps = ({ settings: { productType }, settingsTest: { customize } }: RootState) => ({
  productType,
  customize,
});

export default connect(mapStateToProps, null)(HistoryFilter);
