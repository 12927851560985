import { ACTION, FOLDER_TYPE, FORM_FOLDER_MANAGEMENT, http, getDataFromLS, LOCAL_STORAGE } from "core";

import i18n from "locales/i18n";
import { get as lodashGet, has, mapValues, snakeCase } from "lodash";
import { toast } from "react-toastify";
import { Action } from "redux-actions";
import { stopSubmit } from "redux-form";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { folderGetAll, folderChose, favoritesGetFolders, getProtectedConfig, setGlobalSearch } from "store/actions";

export type FormValuesFolder = {
  description?: string;
  webAccess: boolean;
  levelSecurity: number;
  parent: string | null;
  title: string;
  folderGroups?: string[];
};

export type Payload = {
  action: ACTION;
  id?: string;
  payload: FormValuesFolder;
  type: FOLDER_TYPE;
};

export const action: Routine = createRoutine("FOLDER_MANAGE");

const management = ({ id, action, payload, type }: Payload) => {
  return http({
    method: action === ACTION.UPDATE ? "PUT" : "POST",
    payload,
    route: `folder${action === ACTION.UPDATE ? `/${id}` : ""}/${type}`,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(management, payload);

    toast.success(i18n.t(`toast_folder_success_${payload.action}`));

    yield put(action.success({ response, action: payload.action }));
    yield put(folderChose({ status: false, choseAll: true }));
    yield put(folderGetAll.trigger());
    const lastSearch = getDataFromLS(LOCAL_STORAGE.LAST_SEARCH_VALUE);
    if (window.location.pathname.includes("search") && lastSearch) {
      yield put(setGlobalSearch.trigger(lastSearch));
    }
    yield put(favoritesGetFolders.trigger({ page: 1 }));
    yield put(getProtectedConfig.trigger());
  } catch (error) {
    if (has(error, "response.data.detail")) {
      yield put(
        stopSubmit(
          FORM_FOLDER_MANAGEMENT,
          mapValues(lodashGet(error, "response.data.detail"), e => i18n.t(`toast_folder_error_${e[0].replace(/ /g, "_")}`)),
        ),
      );
      if(lodashGet(error, "response.data.detail.title")){
        toast.error(i18n.t(`error_${snakeCase(lodashGet(error, "response.data.detail.title[0]"))}`));
        yield put(action.failure(lodashGet(error, "response.data.detail.title[0]")));
      } else {
        yield put(action.failure(lodashGet(error, "response.data.detail")));
      }
    } else {
      yield put(action.failure(lodashGet(error, "message", undefined)));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
