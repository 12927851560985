import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import { connect } from "react-redux";
import { cnSettings } from "pages/Settings";
import { Checkbox } from "ss-ui";
import { typeAccessDevice } from "core";
import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { setGlobalSettings } from "store/actions";

type Props = {
  settings?: typeAccessDevice;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const AccessDeviceManagement: FC<Props> = ({ 
  settings,
  setGlobalSettings,
}: Props) => {

  const { t } = useTranslation();

  const handleChangeValue = (valueSettings: string, value: boolean) => {
    setGlobalSettings({
      name: "ACCESS_DEVICES",
      value: valueSettings,
      accessDeviceValue: value,
    });
  }

  return (
    <>
      {settings ? (
      <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_best_icon_services">
        <div className={cnSettings("Header")}>{t("settings_access_devices_header")}</div>
        <div className={cnSettings("Hint")}>{t("settings_access_devices_hint")}</div>
      </div>
      <div className={cnSettings("Table")}>
        <div className={cnSettings("Table-Body")}>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={`${cnSettings("Row-Name")} ${cnSettings("Row-Name-Big")}`}>{t("settings_access_devices_ext")}</div>
            <div className={cnSettings("Row-User")}>
              <Checkbox
                checked={settings.browser_extension}
                onChange={() => handleChangeValue(
                  "browser_extension",
                  !settings.browser_extension
                )}
              />
            </div>
          </div>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={`${cnSettings("Row-Name")} ${cnSettings("Row-Name-Big")}`}>{t("settings_access_devices_mobile")}</div>
            <div className={cnSettings("Row-User")}>
              <Checkbox
                checked={settings.mobile_app}
                onChange={() => handleChangeValue(
                  "mobile_app",
                  !settings.mobile_app
                )}
              />
            </div>
          </div>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={`${cnSettings("Row-Name")} ${cnSettings("Row-Name-Big")}`}>{t("settings_access_devices_web")}</div>
            <div className={cnSettings("Row-User")}>
              <Checkbox
                checked={settings.web_browser}
                onChange={() => handleChangeValue(
                  "web_browser",
                  !settings.web_browser
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
      ) : null}
    </>
  )
}

const mapStateToProps = ({ settingsTest: { accessDevice: { settings } } }: RootState) => ({
  settings,
});

const mapDispatchToProps = { setGlobalSettings };

export default connect(mapStateToProps, mapDispatchToProps)(AccessDeviceManagement);