import { SORT_BY } from "core";
import { typeTableHeaderElement } from "components/Table";

export const tableHeader: typeTableHeaderElement[] = [
  {
    id: "keys_type",
    title: "Type",
  },
  {
    id: "keys_title",
    title: "Title",
    sortInit: SORT_BY.TITLE,
  },
  {
    id: "keys_modified",
    title: "Modified",
    sortInit: SORT_BY.UPDATE_DATE,
  },
  {
    id: "keys_actions",
    title: "Actions",
  },
];

export const tableHeaderFolder: typeTableHeaderElement[] = [
  {
    id: "keys_type",
    title: "Type",
  },
  {
    id: "keys_title",
    title: "Title",
    sortInit: SORT_BY.TITLE,
  },
  {
    id: "keys_modified",
    title: "Modified",
    sortInit: SORT_BY.UPDATE_DATE,
  },
  {
    id: "keys_actions",
    title: "Keys",
  },
  {
    id: "keys_actions",
    title: "Actions",
  },
];
