import { Action } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { toast } from "react-toastify";

import { http } from "core";

import i18n from "locales/i18n";

import { getUsersSessions, logout } from "store/actions";

export const action: Routine = createRoutine("MANAGER_CLOSE_SESSION");

export interface Payload {
  id: number;
  current?: boolean;
}

const closeUsersSession = ({ id }: Payload) => {
  return http({ route: `manager/close-sessions/${id}` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(closeUsersSession, payload);

    yield put(action.success(response));

    yield put(payload.current ? logout.trigger() : getUsersSessions.trigger({}));

    toast.success(i18n.t("toast_session_closed_success"), { toastId: "SESSION_CLOSED_SUCCESS" });
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
