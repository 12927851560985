/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { groupsChose, groupsDelete } from "store/actions";
import { Button, Icon } from "ss-ui";
import { InfoBox, InfoBoxHeader, InfoBoxTabs, InfoBoxContent, InfoBoxActions } from "components/InfoBox";

import { 
  typeInfoBoxTab, 
  PayloadChoseFunction, 
  typeGroup, 
  getGroupFromListByID, 
  ROLE_TYPE, 
  GROUP_STORAGE,
  typeFolderGroups,
  isSapGroup
} from "core";

import { Payload as PayloadGroupsDelete } from "store/routines/groups/delete";
import { cnModal, Modal } from "components/Generic";
import { RootState } from "store/reducers";
import { Multiply } from "./Multiply";
import { Folders, Members, Settings } from "./Single";

type Props = {
  chosen: string[];
  clickedLink: string | number;
  isFoldersLoading: boolean;
  isGroupsLoading: boolean;
  groupList: typeGroup[];
  username?: string;
  role?: ROLE_TYPE;
  groupStorage: GROUP_STORAGE;
  treeList: typeFolderGroups;
  sapGroups: string[];
  groupsChose: (payload: PayloadChoseFunction) => void;
  groupsDelete: (payload: PayloadGroupsDelete) => void;
};

const InfoBoxGroup: FC<Props> = ({ 
  chosen, 
  clickedLink, 
  isFoldersLoading, 
  isGroupsLoading,
  groupList,
  username,
  role,
  groupStorage,
  treeList,
  sapGroups,
  groupsChose,
  groupsDelete,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>((clickedLink as number) || 1);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [openModalAddMembers, setOpenModalAddMembers] = useState<boolean>(false);
  const [openModalAddFolders, setOpenModalAddFolder] = useState<boolean>(false);

  const isNew = useMemo(() => chosen[0] === "new", [chosen]);
  const group: typeGroup = getGroupFromListByID(groupList, chosen[0]);
  const isMulti = useMemo(() => chosen.length > 1, [chosen]);
  const isGroupAccess = useMemo(() => !!group && group.users.some(x => x.username === username), [group, username]);
  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);

  const isSap: boolean = useMemo(() => group && isSapGroup(group?.id, sapGroups), [group?.id, sapGroups]);

  useEffect(() => {
    if (isNew) {
      setActiveTab(1);
    }
  }, [isNew]);

  const handleOpenModalAddMembers = (value: boolean) => {
    setOpenModalAddMembers(value);
  }

  const handleOpenModalAddFolders = (value: boolean) => {
    setOpenModalAddFolder(value);
  }

  const handleClose = () => {
    groupsChose({ status: false, choseAll: true });
  };

  const handleDeleteGroup = () => {
    groupsDelete({ id: chosen[0] });
  };

  const configTabs: typeInfoBoxTab[] = [
    { index: 1, title: "Settings", component: 
      <Settings /> 
    },
    { index: 2, title: "Folders", component: 
      <Folders handleModalFolders={handleOpenModalAddFolders} modalFoldersValue={openModalAddFolders} isGroupAccess={isAdmin || isGroupAccess } /> 
    },
    { index: 3, title: "Members", component: 
      <Members handleModalMembers={handleOpenModalAddMembers} modalMembersValue={openModalAddMembers}/> 
    },
  ];

  return (
    <>
      <InfoBox isLoading={isFoldersLoading || isGroupsLoading}>
        <InfoBoxHeader 
          title={isNew ? t("groups-New") : group.name }
          iconName="icon-group-circle"
          handleClose={handleClose}
        />
        <InfoBoxTabs activeTab={activeTab} setActiveTab={setActiveTab} configTabs={isNew ? [configTabs[0]] : configTabs} />
        <InfoBoxContent>
          {isMulti ? <Multiply /> : configTabs[activeTab - 1].component }
        </InfoBoxContent>
        <InfoBoxActions>
          {!!isGroupAccess || isAdmin || isNew ? (
            activeTab === 1 && (
              <Button
                id="button_action_save_setting" 
                theme="primary" 
                form="submitGroupForm"
                type="submit"
              >
                {t("button-Save")}
              </Button>
            )
          ) : null}
          {(isAdmin || isGroupAccess) && (
            <>
              {activeTab === 2 && (
                Object.keys(treeList).length > 0 ? (
                  <>
                    {!isNew && (isAdmin || isGroupAccess) && (
                      <Button
                        id="button_action_edit_folder"
                        onClick={() => handleOpenModalAddFolders(!openModalAddFolders)}
                      >
                        {t("button-Edit")}
                      </Button>
                    )}
                  </>
                ) : null
              )}
              {activeTab === 3 && (
                groupStorage === "local" ? (
                  <>
                    {!isNew && (isAdmin || isGroupAccess) && (
                      <Button
                        id="button_action_edit_member"
                        onClick={() => handleOpenModalAddMembers(!openModalAddMembers)}
                      >
                        {t("button-Edit")}
                      </Button>
                    )}
                  </>
                ) : <span>&nbsp;</span>
              )}
              {!isNew && !isSap && (
                <Button 
                  id="button_group_action_trash" 
                  onClick={() => setShowConfirm(true)} 
                  icon="icon-trash" 
                  theme="danger-link" 
                />
              )}
            </>
          )}
        </InfoBoxActions>
      </InfoBox>

      {showConfirm && (
        <Modal handleClose={() => setShowConfirm(false)} className={cnModal("Confirmation")}>
          <div className={cnModal("Header")}>{t("button-Delete")}</div>
          <div className={cnModal("Scroll")}>
            <p className={cnModal("ConfirmationText")}>{t("modal-faq-group", { count: chosen.length })}</p>
            <div className={cnModal("ConfirmationList")}>
              {chosen &&
                chosen.map((id: string) => {
                  const group = getGroupFromListByID(groupList, id);
                  return (
                    <div className={cnModal("ConfirmationItem")} key={id}>
                      <Icon name="icon-group-circle" width={30} height={30} />
                      {group && group.name}
                    </div>
                  );
                })}
            </div>
            <p className={cnModal("ConfirmationText", ["red"])}>{t("modal-error-group")}</p>
          </div>
          <div className={cnModal("Actions")}>
            <Button id="button_action_delete" onClick={() => handleDeleteGroup()}>
              {t("button-Delete")}
            </Button>
            <Button id="button_action_cancel" theme="secondary-outline" onClick={() => setShowConfirm(false)}>
              {t("button-Cancel")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({
  folders: { treeList, isLoading: isFoldersLoading },
  groups: { chosen, isLoading: isGroupsLoading, list: groupList },
  settings: { clickedLink, groupStorage, sapGroups },
  user: { username, role }
}: RootState) => ({
  chosen,
  clickedLink,
  groupList,
  treeList,
  isFoldersLoading,
  isGroupsLoading,
  username,
  role,
  groupStorage,
  sapGroups,
});

const mapDispatchToProps = { groupsChose, groupsDelete };

export default connect(mapStateToProps, mapDispatchToProps)(InfoBoxGroup);
