import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";

import { getDataFromLS, LOCAL_STORAGE, SecurityServices } from "core";

import { logout } from "store/actions";

export const action: Routine = createRoutine("APP_USER_INIT");

function* handler() {
  try {
    const user = getDataFromLS(LOCAL_STORAGE.USER);

    if (user) {
      if (user.saveAccount || user.expiresInTime > new Date().getTime()) {
        SecurityServices.setEncryptionKey(user.securityKey);

        yield put(action.success(user));
      } else {
        yield put(logout.trigger());
      }
    }
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
