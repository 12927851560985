import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnContentHeaderMobile } from "components/Layout";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ContentHeaderMobile: FC<IProps> = ({ children, className = "", ...props }) => {
  return (
    <div className={`${cnContentHeaderMobile()} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default ContentHeaderMobile;