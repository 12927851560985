import React from "react";
import { Routes, productsAll } from "core";

import { UnprotectedRoute } from "components/UnprotectedRoutes/index";

import { InstallPassword, ForgotPassword, Login, GettingQRCode, VpnConnection } from "pages";

const UnprotectedRoutes = [
  <UnprotectedRoute key={Routes.Login} path={Routes.Login} exact component={Login} />,
  <UnprotectedRoute key={Routes.ForgotPassword} path={Routes.ForgotPassword} exact component={ForgotPassword} productTypeAccess={productsAll} />,
  <UnprotectedRoute key={Routes.InstallPassword} path={Routes.InstallPassword} exact component={InstallPassword} productTypeAccess={productsAll} />,
  <UnprotectedRoute key={Routes.GettingQRCode} path={Routes.GettingQRCode} exact component={GettingQRCode} productTypeAccess={productsAll} />,
  <UnprotectedRoute key={Routes.VpnConnection} path={Routes.VpnConnection} exact component={VpnConnection} productTypeAccess={productsAll} />,
]

export default UnprotectedRoutes;
