import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

import { http } from "core";

export const action: Routine = createRoutine("ACTIVITY_LOG_GET_FILTERS");

const getFilters = () =>
  http({
    route: "activity-log/filters",
  });

function* handler() {
  try {
    const response = yield call(getFilters);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
