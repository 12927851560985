import { typeTemplate, PRODUCT_TYPE, typeFolder, typeKey, typeOption } from "core/types";
import i18n from "locales/i18n";

/**
 * Get value for is supGroup
 * @param groupID: id Group
 * @param sapFolders: Array id's Groups
 */
export function isSapGroup(groupID: string, sapFolders: string[]): boolean {
  return sapFolders.includes(groupID);
}

/**
 * Get value for is supFolders
 * @param {typeTemplate[]} templates: templates list
 * @param {PRODUCT_TYPE} productType: product type
 * @param {typeFolder | undefined} folder: folder
 * @param {boolean} isSap: is sap system
 * @param {typeKey | undefined} keys: keys list
 * @return {typeOption[]}
 */
export function getTemplatesForKeys(
  templates: typeTemplate[],
  productType: PRODUCT_TYPE,
  folder: typeFolder | undefined,
  isSap: boolean,
  keys: typeKey | undefined,
): typeOption[] {

  const sapTemplates = [
    "sap_rfc_template",
    "sap_login_template",
  ];

  if (!isSap) {
    return convertTemplatesToOptions(templates.filter(({ product_type }) => product_type.includes(productType)));
  }

  if (isSap && !keys) {
    return convertTemplatesToOptions(templates.filter(({ title }) => !["sap_login_template"].includes(title)));
  }

  if (isSap && keys && !sapTemplates.includes(keys.template)) {
    return convertTemplatesToOptions(templates.filter(({ title }) => !sapTemplates.includes(title)));
  }

  // if (!keys && folder && sapFolders.includes(folder.id)) {
  //   // return convertTemplatesToOptions(templates.filter(({ title }) => ["sap_login_template"].includes(title)));
  //   return convertTemplatesToOptions(templates.filter(({ title }) => ["_"].includes(title)));
  // }

  // if (folder && sapFolders.includes(folder.id)) {
  //   // return convertTemplatesToOptions(templates.filter(({ title }) => ["sap_login_template", "sap_rfc_template"].includes(title)));
  //   return convertTemplatesToOptions(templates.filter(({ title }) => ["_"].includes(title)));
  // }

  return convertTemplatesToOptions(
    templates
      .filter(({ product_type }) => product_type.includes(productType))
      .filter(({ title }) => !["_"].includes(title)),
      // .filter(({ title }) => !["sap_login_template"].includes(title)),
  );
}

function convertTemplatesToOptions(templates: typeTemplate[]): typeOption[] {
  return templates.map(({ title }: typeTemplate) => ({
    label: i18n.t(`key_template_${title}`),
    value: title,
  }));
}
