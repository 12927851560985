import React, { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";

import { FormTextarea } from "components/Form";

import { Validation, FIELD_TYPE, getFieldValue } from "core";

import { FieldContext } from "./Types";

type Props = {
  keyId?: string,
};

const fieldType = FIELD_TYPE.DESCRIPTION;

const FieldDescription: FC<Props> = ({
  keyId
}) => {
  const { t } = useTranslation();

  const context = useContext(FieldContext);

  useEffect(() => {
    context.handleChange(fieldType, "");
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <Field
      component={FormTextarea}
      isEditor={true}
      label={t("form_input-Description")}
      name={fieldType}
      placeholder={t("form_input_enter-Description")}
      validate={[Validation.maxLength2048]}
      fieldId={keyId}
    />
  );
};

export default FieldDescription;
