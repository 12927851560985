import Color from "color";
import React, { FC, useMemo } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { customSelectStyles } from "components/Form/Select";

import { cnSettings } from "pages/Settings";
import { defaultCustomValue } from "pages/Settings/Customize/Customize";

import { RootState } from "store/reducers";
import { setBestIcon, setGlobalSettings } from "store/actions";

import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

type Props = {
  customize: SettingsCustomizeState;
  cleanMonth?: number;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const ActivityLogCleaning: FC<Props> = ({ cleanMonth, customize, setGlobalSettings }) => {
  const { t } = useTranslation();

  const FormatOptionsCleanActiveLog = [1, 3, 6, 9, 12, 18].map(e => ({
    label: e,
    value: e,
  }));

  const color: string = useMemo(() => customize.colorMain || defaultCustomValue.primaryColor, [customize.colorMain]);

  const setActivityLogCleaning = (value: number) => {
    setGlobalSettings({
      name: "LOG_ROTATION_PERIOD",
      value,
    });
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_timeout">
        <div className={cnSettings("Header")}>{t("settings_header_cleanLog")}</div>
        <div className={cnSettings("Hint")}>{t("settings_hunt_cleanLog")}</div>
        <div className={cnSettings("SelectLogout")}>
          <Select
            className={cnSettings("LogoutTime")}
            value={FormatOptionsCleanActiveLog.find(x => x.value === cleanMonth)}
            isClearable={false}
            isSearchable={false}
            options={FormatOptionsCleanActiveLog}
            onChange={selected => selected && (setActivityLogCleaning(selected.value))}
            styles={customSelectStyles({ isMultiple: false }, color)}
            menuPlacement="top"
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#EAFBFD",
                primary75: color,
                primary50: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
                primary: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
              },
            })}
          />
          <label>{t("reset_pass_settings_month_log")}</label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings: { cleanMonth }, settingsTest: { customize } }: RootState) => ({
  cleanMonth,
  customize,
});

const mapDispatchToProps = {
  setBestIcon,
  setGlobalSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogCleaning);
