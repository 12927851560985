/* eslint-disable react-hooks/exhaustive-deps, no-return-assign, no-param-reassign */

import { find, flatMap, has, isEmpty } from "lodash";

import { typeFolderFilter } from "components/InfoBox/FolderFilter/FolderFilterTypes";

import { FOLDER_ACCESS, FOLDER_TYPE, typeFolder, typeGroup, typeOption, typeSelectedFolder, typeSharedFolder } from "core";

/**
 * Update tree, with reorder (change parent), and update some folder
 * @param tree - array folders tree typeFolder[]
 * @param folder - folder for update
 */
export function updateFolderStructure(tree: typeFolder[], folder: typeFolder): typeFolder[] {
  let temporaryStorage: typeFolder | undefined;

  const searchAndCutItem = (tree: typeFolder[], folder: typeFolder): typeFolder[] => {
    return tree.map((item: typeFolder) => {
      const copyItem: typeFolder = { ...item };

      if (copyItem.children.filter(({ id }: typeFolder) => id === folder.id && folder.root !== copyItem.id).length > 0) {
        [temporaryStorage] = copyItem.children.filter(({ id }: typeFolder) => id === folder.id && folder.root !== copyItem.id);
        temporaryStorage = { ...temporaryStorage, ...folder };
      }

      copyItem.children = copyItem.children.filter(
        ({ id }: typeFolder) => (id === folder.id && folder.root === copyItem.id) || id !== folder.id,
      );

      if (copyItem.children && copyItem.children.length > 0) {
        copyItem.children = searchAndCutItem(copyItem.children, folder);
      }

      return copyItem;
    });
  };

  const addItemWithChildInTree = (tree: typeFolder[], folder: typeFolder): typeFolder[] => {
    return tree.map((item: typeFolder) => {
      const copyItem: typeFolder = { ...item };

      if (folder.root === copyItem.id) {
        copyItem.children.unshift(folder);
      }

      if (copyItem.children && copyItem.children.length > 0) {
        copyItem.children = addItemWithChildInTree(copyItem.children, folder);
      }

      return copyItem;
    });
  };

  const cuttableTree: typeFolder[] = searchAndCutItem(tree, folder);

  return temporaryStorage === undefined ? cuttableTree : addItemWithChildInTree(cuttableTree, temporaryStorage);
}

/**
 * Update folder in folder tree with keys and other folder with children
 * @param tree - array folders tree typeFolder[]
 * @param folder - updated folder for updated data in tree
 */
export function updateFolderInTree(tree: typeFolder[], folder: typeFolder): typeFolder[] {
  return tree.map((item: typeFolder) => {
    const copyItem: typeFolder = { ...item };

    if (copyItem.children && copyItem.children.length > 0) {
      copyItem.children = updateFolderInTree(copyItem.children, folder);
    }

    return copyItem.id === folder.id ? { ...copyItem, ...folder } : copyItem;
  });
}

/**
 * Update in tree folder item, by folder id
 * @param tree - folders tree typeFolder[]
 * @param item - item folder object
 */
export function updateItemInTreeByID(tree: typeFolder[], item: typeFolder): typeFolder[] {
  return tree.map((folder: typeFolder) => {
    let children: typeFolder[] = [];
    if (folder.children.length > 0) {
      children = updateItemInTreeByID(folder.children, item);
    }

    return folder.id === item.id ? { ...folder, ...item, children } : { ...folder, children };
  });
}

/**
 * Expand folders tree array to flat list array
 * @param folders: typeFolder[] - folders tree array
 * @param level: number - folders tree array
 */
export function getExpandFoldersList(folders: typeFolder[], level: number = 1): typeFolder[] {
  return folders.reduce((flatList, folder) => {
    flatList.push({ ...folder, level });
    if (folder.children && folder.children.length > 0) {
      flatList.push(...getExpandFoldersList(folder.children, level + 1));
    }
    return flatList;
  }, [] as typeFolder[]);
  // .sort((a: typeFolder, b: typeFolder) => {
  //   const A = a.title.toUpperCase();
  //   const B = b.title.toUpperCase();
  //   return A > B ? 1 : -1;
  // });
}

/**
 * Get IDs folder and him parents an array
 * @param tree - array folders tree typeFolder[]
 * @param id - searchable folder
 */
export function getIDsActiveFolder(tree: typeFolder[] = [], id?: string): string[] {
  if (tree.length === 0 || id === undefined) return [];
  const folderIDs: string[] = [];

  for (let i = 0; i < tree.length; i++) {
    const folder = tree[i];
    const childId: string[] = [];
    if (folder.children) {
      childId.push(...getIDsActiveFolder(folder.children, id));
    }
    if (folder.id === id || childId.length > 0) {
      folderIDs.push(folder.id, ...childId);
    }
  }

  return folderIDs;
}

/**
 * Return folder by id from folder tree
 * @param tree - array folders tree typeFolder[]
 * @param id - searchable folder
 */
export function findFolderInTree(tree: typeFolder[] = [], id?: string): typeFolder | undefined {
  if (tree.length === 0 || id === undefined) return undefined;

  for (let i = 0; i < tree.length; i++) {
    const folder = tree[i];
    if (folder.children) {
      const child = findFolderInTree(folder.children, id);
      if (child) return child;
    }
    if (folder.id === id) {
      return folder;
    }
  }
  return undefined;
}

/**
 * Get folder object from folders tree by folder id
 * @param {typeFolder[]} tree - tree of folder item
 * @param {string} id - folder id
 */
export function getFolderFromTreeById(tree: typeFolder[], id: string | null | undefined): typeFolder {
  const flatList = getExpandFoldersList(tree);
  return findFolderById(flatList, id);
}

/**
 * Get folder object from folders tree by folder id for Sharing key
 * @param {typeFolder[]} tree - tree of folder item
 * @param {any} id - folder id
 */
export function getFolderFromTreeByIdForSharing(tree: typeFolder[], id: any): typeFolder {
  const flatList = getExpandFoldersList(tree);
  return findFolderById(flatList, id);
}

/**
 * Get boolean value for shown that folder equaled filter or not
 * @param folder {typeFolderFilter[]}
 * @param groups {typeGroup[]}
 * @param filters {typeFolder[]}
 */
export function filteringFolderByParam(folder: typeFolder, groups: typeGroup[], filters: typeFolderFilter): boolean {
  if (filters.write && folder.access === FOLDER_ACCESS.WRITE) {
    return true;
  }
  if (filters.read && folder.access === FOLDER_ACCESS.READ) {
    return true;
  }
  if (filters.no_access && folder.access === FOLDER_ACCESS.NO_ACCESS) {
    return true;
  }
  if (filters.web_access && folder.webAccess) {
    return true;
  }

  // if (filters.sealing === true && folder.sealing === true) {
  //   return true;
  // }

  if (
    filters.groups &&
    filters.groups.length > 0 &&
    folder.folderGroups &&
    folder.folderGroups.filter(({ group }) => {
      return filters.groups && flatMap(filters.groups, (i: typeOption) => i.value).includes(group);
    }).length > 0
  ) {
    return true;
  }

  return !!(
    filters.users &&
    filters.users.length > 0 &&
    folder.folderGroups &&
    folder.folderGroups.filter(({ group }) => {
      const filterUserId = flatMap(filters.users, (i: typeOption) => i.value);
      const filterGroupByUser = groups && groups.filter(a => a.users.some(a => filterUserId.toString().includes(a.id)));
      return filterGroupByUser && flatMap(filterGroupByUser, (i: typeGroup) => i.id).includes(group);
    }).length > 0
  );
}

/**
 * Get count folder after filtering
 * @param folders
 * @param filters
 */
export function getCountFilteringFolderByParam(folders: typeFolder[], groups: typeGroup[], filters: typeFolderFilter): number {
  return getExpandFoldersList(folders).reduce((count, folder) => (filteringFolderByParam(folder, groups, filters) ? count + 1 : count), 0);
}

/**
 * Get folder title by product type, folder type and folder root
 * @param productType: PRODUCT_TYPE
 * @param role: ROLE_TYPE
 * @param folder?: typeFolder
 */

/**
 * Filter folders list by type
 * @param folders - flat folder list
 * @param type - folder type: FOLDER_TYPEl
 */
export function getFoldersByType(folders: typeFolder[], type: FOLDER_TYPE): typeFolder[] {
  return folders.filter((folder: typeFolder) => folder.type === type);
}

/**
 * Add in tree folder item, by folder root id
 * @param tree - folders tree typeFolder[]
 * @param item - item folder object
 */
export function addItemInTree(tree: typeFolder[], item: typeFolder): typeFolder[] {
  if (!item.root) {
    return [...tree, { ...item, children: [], key: [] }];
  }
  return tree.map((folder: typeFolder) => {
    let children: typeFolder[] = [];
    if (folder.id === item.root) {
      children = [...folder.children, { ...item, children: [], key: [] }];
    } else if (folder.children.length > 0) {
      children = addItemInTree(folder.children, item);
    }
    return { ...folder, children };
  });
}

/**
 * Get folder object from folders list by folder id
 * @param {typeSharedFolder|typeFolder} folderList - folder items
 * @param {string} id - folder id
 */
export function findFolderById<T extends typeSharedFolder | typeFolder>(folderList: T[], id: string | null | undefined): T {
  return folderList.filter((folder: T) => folder.id === id)[0];
}

/**
 * Remove from folders tree, folder by id
 * @param tree - folders tree typeFolder[]
 * @param id - id removed folder
 */
export function removeFromTreeByID(tree: typeFolder[], id: string): typeFolder[] {
  return tree.filter((folder: typeFolder) => {
    // eslint-disable-next-line no-param-reassign
    if (folder.children) folder.children = removeFromTreeByID(folder.children, id);
    return folder.id !== id;
  });
}

/**
 * Remove children folder from folders list
 * @param {typeSelectedFolder[]} folders
 * @return {string[]}
 */
export function removeFolderChild(folders: typeSelectedFolder[]): string[] {
  if (folders.length === 0) return [];

  const filteredList: string[] = [];

  folders.forEach((folder: typeSelectedFolder) => {
    if (find(folders, ["value", folder.root]) === undefined) {
      filteredList.push(folder.value);
    }
  });

  return filteredList;
}

export function getFolderType(data: any, list: typeFolder[], folder?: typeFolder): FOLDER_TYPE {
  let type: FOLDER_TYPE = FOLDER_TYPE.GROUP;

  if (folder) {
    return folder.type;
  }

  if (has(data, "parent.value") && !isEmpty(data.parent.value)) {
    type = getFolderFromTreeById(list, data.parent.value).type;
  }

  return type;
}
