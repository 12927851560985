/* eslint-disable no-unneeded-ternary */
import React, { FC, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "components/Form";
import { Validation, FIELD_TYPE, getFieldValue } from "core";
import { FieldContext } from "../Types";

type Props = {};
const fieldType = FIELD_TYPE.RFC_CONNECTION_TYPE;

const FieldRfcConnectionId: FC<Props> = () => {
  const { t } = useTranslation();
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <Field
      component={FormInput}
      label={t(`manage_key_field_${fieldType}_label`)}
      name={fieldType}
      disabled={context.key ? true : false}
      // className="Required"
      placeholder={t(`manage_key_field_${fieldType}_placeholder`)}
      validate={[Validation.maxLength255]}
      isAutoCompleteOn={false}
    />
  );
};

export default FieldRfcConnectionId;
