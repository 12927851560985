import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, has } from "lodash";
import { format } from "date-fns";
import { Action } from "redux-actions";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

import { http, IActivityLogFilter } from "core";

export const action: Routine = createRoutine("ACTIVITY_LOG_GET_ALL");

export interface Payload {
  search?: string;
  filters: IActivityLogFilter;
}

const getAll = ({ filters }: Payload) => {
  const usersFilter = filters && filters.users.map(item => item.value);
  return http({
    route: `activity-log?page=${filters.activePage}`,
    method: "POST",
    payload: {
      dateFrom: format(new Date(filters.period.start), "dd.MM.yyyy"),
      dateTo: format(new Date(filters.period.end), "dd.MM.yyyy"),
      entityType: filters.object && filters.object !== "all" ? filters.object : "",
      operations: filters.operations || [],
      users: usersFilter || [],
      sortBy: filters.sortBy || null,
      sortOrder: filters.sortOrder?.toLowerCase() || null,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(getAll, payload);

    yield put(action.success(response));
  } catch (error) {
    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
