import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { WrappedFieldProps } from "redux-form";
import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";
import { List } from "react-virtualized";
import { MultiValue, SingleValue } from "react-select";

import { typeOption } from "core";

// const getInitialValue = (value: string | number): typeOption | undefined => {
//   if (typeof value === "object") {
//     return value;
//   }

//   return undefined;
// };

const MenuList = props => {
  const rows = props.children;
  const rowRenderer = ({ key, index, style }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );
  return (
    <List
      style={{ width: "100%" }}
      width={600}
      height={rows.length > 0 ? 250 : 38}
      onScroll={scroll =>
        scroll.clientHeight + scroll.scrollTop > scroll.scrollHeight - scroll.clientHeight && props.selectProps.handleScrolledToBottom()
      }
      rowHeight={38}
      rowCount={rows.length || 0}
      rowRenderer={rowRenderer}
    />
  );
};

type Props = {
  className?: string;
  disabled?: boolean;
  label: string;
  name: string;
  loadOptions: any;
  placeholder?: string;
  template?: (label: string, value: string) => JSX.Element;
  isMultiple?: boolean;
  renderOutside?: boolean;
  selectType: string;
  isLoading?: boolean;
  hideSelectedOptions?: boolean;
  menuPlacement?: "auto" | "bottom" | "top";
  templateList?: (label: string, value: string) => JSX.Element;
} & WrappedFieldProps;

const FormLazySelect: React.FC<Props> = ({
  className = "",
  disabled,
  input,
  loadOptions,
  label,
  meta: { touched, error },
  name,
  placeholder = "",
  template,
  isMultiple = false,
  renderOutside = false,
  selectType = "",
  isLoading = false,
  menuPlacement = "auto",
  hideSelectedOptions = false,
  templateList,
}) => {
  const customStyles = {
    control: (provided: any, state) => ({
      ...provided,
      border: "0",
      borderWidth: "0",
      boxShadow: state.isFocused ? "0 5px 15px #80CFF1" : "0 5px 15px rgba(0, 0, 0, 0.16)",
      fontSize: "14px",
      height: "40px",
      minHeight: "40px",
      padding: "0 0 0 16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      cursor: "pointer",
    }),
    placeholder: (provided: any) => ({
      ...provided,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#80CFF1",
    }),
  };

  const isError = touched && Boolean(error);

  const formatOptionLabel = ({ value, label }: any) => (template ? template(label, value) : label);

  const getOptionLabel = (option: any) => {
    switch (selectType) {
      case "group":
        return option.title;
      default:
        return option.label;
    }
  };

  const getOptionValue = (option: any) => {
    switch (selectType) {
      case "group":
        return option.dn;
      default:
        return option.value;
    }
  };

  return (
    <div
      className={`${cnField()} ${cnField("Select", { hasError: isError })} ${className}`}
      id={`input_lazy_select_${input.name}_container`}
    >
      {label && (
        <span className="Label" id={`input_lazy_select_${input.name}_label`}>
          {label}
        </span>
      )}
      <AsyncPaginate
        className={className}
        classNamePrefix={className}
        components={{ MenuList }}
        debounceTimeout={1000}
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={hideSelectedOptions}
        id={`input_lazy_select_${input.name}`}
        isDisabled={disabled}
        isOptionDisabled={option => (option.isDisabled !== undefined ? option.isDisabled : false)}
        isSearchable
        loadOptions={loadOptions}
        name={name}
        onChange={(option: SingleValue<typeOption> | MultiValue<typeOption>) => input.onChange(option)}
        placeholder={placeholder}
        styles={customStyles}
        value={input.value}
        closeMenuOnSelect={!isMultiple}
        controlShouldRenderValue={!(renderOutside && isMultiple)}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isClearable={isMultiple}
        // isLoading={true}
        isMulti={isMultiple}
        menuPlacement={menuPlacement}
      />

      {renderOutside && isMultiple && templateList && (
        <div className="selected-options" id={`input_multi_select_outside_${input.name}_select_options`}>
          {input.value.length > 0 && input.value.map(item => templateList(item.label, item.value))}
        </div>
      )}

      {isError && (
        <span id={`input_lazy_select_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default FormLazySelect;
