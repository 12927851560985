/* eslint-disable react/no-array-index-key, no-unneeded-ternary */
import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, FolderIcon, Icon } from "ss-ui";
import { RouteComponentProps, useParams } from "react-router";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  emptyFolder,
  FOLDER_ACCESS,
  FOLDER_TYPE,
  getFolderFromTreeById,
  getFolderFromTreeByIdForSharing,
  getKey,
  getKeyFromArrayByID,
  replaceUrlParam,
  Routes,
  typeFolder,
  typeKey,
  typeKeySharing,
} from "core";

import { Payload as PayloadGetKeySharing } from "store/routines/keys/getKeySharing";
import { Payload as PayloadSetKeySharing } from "store/routines/keys/setKeySharing";
import { Payload as PayloadDeleteKeySharing } from "store/routines/keys/deleteKeySharing";
import { RootState } from "store/reducers";
import { getSharing, setSharing, deleteKeySharing } from "store/actions";
import { ModalFolderSelect } from "components/Generic";

const cnSharing = cn("InfoBoxKeySharing");

interface iParams {
  folderID: string;
}

type Props = {
  chosen: string[];
  getSharing: (payload: PayloadGetKeySharing) => void;
  setSharing: (payload: PayloadSetKeySharing) => void;
  deleteKeySharing: (payload: PayloadDeleteKeySharing) => void;
  keyList: typeKey[];
  listFolders?: typeFolder[];
  accessibleFolders?: typeKeySharing[];
  restrictedFolders?: typeKeySharing[];
  rootSharingFolder?: typeKeySharing;
} & RouteComponentProps<{ id: string }>;

const Sharing: FC<Props> = ({
  chosen,
  setSharing,
  getSharing,
  deleteKeySharing,
  keyList,
  listFolders = [],
  accessibleFolders,
  restrictedFolders,
  rootSharingFolder,
}) => {
  const { t } = useTranslation();

  const { folderID } = useParams<iParams>();
  const [isShowAdd, setIsShowAdd] = useState<boolean>(false);

  const key: typeKey | undefined = useMemo(() => getKeyFromArrayByID(keyList, chosen[0]), [keyList, chosen]);
  const isSharingKey = useMemo(() => folderID !== key?.mainFolderId, [folderID, key]);

  const mainKeyFolder: typeFolder = useMemo(
    () => getFolderFromTreeByIdForSharing(listFolders, key?.mainFolderId) || emptyFolder,
    [listFolders, key],
  );

  const isFolderWritable = useMemo(() => mainKeyFolder && FOLDER_ACCESS.WRITE === mainKeyFolder.access, [mainKeyFolder]);
  const accessibleFoldersList = useMemo(() => accessibleFolders && accessibleFolders.map((item: typeKeySharing) => item.id), [accessibleFolders]);

  const isAccessRootFolder: boolean = useMemo(() => !isEmpty(mainKeyFolder.id), [mainKeyFolder]); // true if not empty
  const isHasAccessFolder: boolean = useMemo(() => ![FOLDER_ACCESS.NO_ACCESS].includes(mainKeyFolder.access), [mainKeyFolder]); // true if access no_access
  const isSharingKeySearch: boolean = useMemo(() => mainKeyFolder && !isHasAccessFolder, [isAccessRootFolder, isHasAccessFolder] );

  useEffect(() => {
    if (key) {
      getSharing({ id: key.id });
    }
  }, [key]);

  const handleRemove = (id: string) => {
    if (key) {
      deleteKeySharing({
        keyId: key.id,
        folders: id ? [id] : [],
      });
      setIsShowAdd(false);
    }
  };

  const handleSave = (value: string | null) => {
    if (key) {
      setSharing({
        keyId: key.id,
        folders: value ? [value] : [],
      });
      setIsShowAdd(false);
    }
  };

  return (
    <>
      <div className={ cnSharing() }>
        {folderID ? (
          <>
            {!isSharingKey ? (
              <>
                {key?.folders.length === 0 ? (
                  <div className={ cnSharing("Empty") }>
                    {isFolderWritable ? t("groups-List-Empty") : t("toast_key_error_Access_Denied.")}
                  </div>
                ) : (
                  <div className={ cnSharing("FoldersList") }>
                    <div className={ cnSharing("Empty") }>
                      {t("folders-List")}:
                    </div>
                    {key && accessibleFolders &&
                      accessibleFolders.map((folderItem: typeKeySharing, index) => {
                        const folder = getFolderFromTreeById(listFolders, folderItem.id);
                        return (
                          <div
                            key={ folderItem.id }
                            className={ cnSharing("Folder") }
                          >
                            <div className={ cnSharing("Title") }>
                              <FolderIcon
                                access={ folder.access }
                                isGroup={ folder.type === FOLDER_TYPE.GROUP }
                                level={ folder.level }
                              />

                              <span className={ cnSharing("Name") }>
                                <Link
                                  id={ getKey(`link_to_folder_${folder.title}`) }
                                  title={ folder.title }
                                  to={ replaceUrlParam(Routes.Keys, "folderID", folder.id) }
                                >
                                  <span>{folder.title.trim()}</span>
                                </Link>
                              </span>
                            </div>
                            {isFolderWritable && (
                              <button
                                className={ cnSharing("Delete") }
                                id={ getKey("btn_handle_remove_sharing", index) }
                                type="button"
                                onClick={ () => handleRemove(folder.id) }
                              >
                                <Icon
                                  height={ 16 }
                                  name="icon-trash"
                                  width={ 16 }
                                />
                              </button>
                            )}
                          </div>
                        );
                      })
                    }
                    {key && restrictedFolders &&
                      restrictedFolders.map((folderItem: typeKeySharing, index) => {
                        return (
                          <div
                            key={ `key_folders${index}` }
                            className={ cnSharing("Folder", [cnSharing("Grayscale")]) }
                          >
                            <div className={ cnSharing("Title") }>
                              <FolderIcon />
                              <span className={ cnSharing("Name") }>
                                <span>{folderItem.title}</span>
                              </span>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                )}
                {isFolderWritable && (
                  <Button
                    className={ cnSharing("ButtonAdd") }
                    icon="icon-plus"
                    id="button_key_action_sharing_folder"
                    theme="primary-link"
                    type="button"
                    onClick={ () => setIsShowAdd(true) }
                  >
                    {t("button_share-Folder")}
                  </Button>
                )}
              </>
            ) : (
              <>
                <div className={ cnSharing("Empty") }>
                  {t("key-sharing-text")}
                  <br />
                  {t("key-sharing-list")}
                </div>
                <div className={ cnSharing("Folder") }>
                  {isAccessRootFolder ? (
                    <>
                      <div className={ cnSharing("Title") }>
                        <FolderIcon
                          access={ mainKeyFolder?.access }
                          isGroup={ mainKeyFolder?.type === "group" }
                          level={ mainKeyFolder?.level }
                          size={ 25 }
                        />
                        <span className={ cnSharing("Name") }>{mainKeyFolder?.title}</span>
                      </div>
                      <Link
                        id={ getKey(`link_to_folder_${mainKeyFolder?.title}`) }
                        title={ mainKeyFolder.title }
                        to={ replaceUrlParam(Routes.Keys, "folderID", mainKeyFolder.id) }
                      >
                        <Icon
                          className={ cnSharing("Icon") }
                          height={ 16 }
                          name="icon-shared"
                          width={ 16 }
                        />
                      </Link>
                    </>
                  ) : (
                    <div className={ cnSharing("Title", [cnSharing("Grayscale")]) }>
                      <div className={ cnSharing("Title") }>
                        <FolderIcon />
                        <span className={ cnSharing("Name") }>
                          {rootSharingFolder?.title}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={ cnSharing("Empty") }>
              {isSharingKeySearch && (
                <>
                  {t("key-sharing-text")}
                  <br />
                  {t("key-sharing-list")}
                </>
              )}
            </div>
            {!isSharingKeySearch ? (
              <div className={ cnSharing("FoldersList") }>
                <div className={ cnSharing("Empty") }>
                  {t("folders-List")}
:
                </div>
                {key && accessibleFolders &&
                  accessibleFolders.map((folderItem: typeKeySharing, index) => {
                    const folder = getFolderFromTreeById(listFolders, folderItem.id);
                    return (
                      <div
                        key={ folderItem.id }
                        className={ cnSharing("Folder") }
                      >
                        <div className={ cnSharing("Title") }>
                          <FolderIcon
                            access={ folder.access }
                            isGroup={ folder.type === FOLDER_TYPE.GROUP }
                            level={ folder.level }
                          />
                          <span className={ cnSharing("Name") }>
                            <Link
                              id={ getKey(`link_to_folder_${folder.title}`) }
                              title={ folder.title }
                              to={ replaceUrlParam(Routes.Keys, "folderID", folder.id) }
                            >
                              <span>{folder.title.trim()}</span>
                            </Link>
                          </span>
                        </div>
                        {isFolderWritable && (
                          <button
                            className={ cnSharing("Delete") }
                            id={ getKey("btn_handle_remove_sharing", index) }
                            type="button"
                            onClick={ () => handleRemove(folder.id) }
                          >
                            <Icon
                              height={ 16 }
                              name="icon-trash"
                              width={ 16 }
                            />
                            &nbsp;
                          </button>
                        )}
                      </div>
                    );
                  })
                }
                {key && restrictedFolders &&
                  restrictedFolders.map((folderItem: typeKeySharing, index) => {
                    return (
                      <div
                        key={ `key_folders${index}` }
                        className={ cnSharing("Folder", [cnSharing("Grayscale")]) }
                      >
                        <div className={ cnSharing("Title") }>
                          <FolderIcon />
                          <span className={ cnSharing("Name") }>
                            <span>{folderItem.title}</span>
                          </span>
                        </div>
                      </div>
                    );
                  })
                }
                {isFolderWritable && (
                  <Button
                    className={ cnSharing("ButtonAdd") }
                    icon="icon-plus"
                    id="button_key_action_sharing_folder"
                    theme="primary-link"
                    type="button"
                    onClick={ () => setIsShowAdd(true) }
                  >
                    {t("button_share-Folder")}
                  </Button>
                )}
              </div>
            ) : (
              <div className={ cnSharing("Title", [cnSharing("Grayscale")]) }>
                <div className={ cnSharing("Title") }>
                  <FolderIcon />
                  <span className={ cnSharing("Name") }>
                    {rootSharingFolder?.title}
                  </span>
                </div>
              </div>
            )}
          </>
        )}

        {accessibleFolders && key && isShowAdd && (
          <ModalFolderSelect
            accessArr={ [FOLDER_ACCESS.WRITE] }
            checkboxRootFolder={ false }
            folderList={ accessibleFoldersList }
            handleClose={ setIsShowAdd }
            modalTitle={ t("modal-share-key") }
            shareFolder={ folderID ? folderID : key.mainFolderId }
            type={ FOLDER_TYPE.GROUP }
            onChanges={ handleSave }
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  folders: { list: listFolders },
  keys: { chosen, list: keyList, accessibleFolders, restrictedFolders, rootSharingFolder },
}: RootState) => ({
  chosen,
  keyList,
  listFolders,
  accessibleFolders,
  restrictedFolders,
  rootSharingFolder,
});

const mapDispatchToProps = {
  setSharing,
  getSharing,
  deleteKeySharing,
};

export default compose<Props, Partial<Props>>(connect(mapStateToProps, mapDispatchToProps), withRouter)(Sharing);
