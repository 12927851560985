import { find } from "lodash";
import { Action } from "redux-actions";

import { GROUP_STORAGE, IAuthorizationSupports, IEncryption, IMicrosoftAuth, PRODUCT_TYPE, USER_STORAGE, typeError } from "core";

import {
  getConfig,
  getGlobalSettings,
  getProtectedConfig,
  getSettings,
  setBackUpNotify,
  setFolderNotify,
  setBestIcon,
  setBestIconCheck,
  setClickedLink,
  setCustomize,
  setGlobalSettings,
  setOpenedFolder,
  setOpenedNavigationFolder,
  setSearch,
  settingsClear,
  setInitialize,
} from "store/actions";

export interface State {
  /**
   * PWNed: hibpwnedEnabled for business and corporate, pwned for free
   */
  hibpwnedEnabled?: boolean;
  pwned?: boolean;

  /**
   * Simple settings
   */
  hasEmptyLogos?: boolean;
  bestIconEnabled?: boolean;
  globalOtp?: boolean;

  /**
   * Other
   */
  initialize: boolean;
  errorMessage?: typeError;
  isLoading: boolean;

  authorizationSupports: IAuthorizationSupports;
  autoLogoutTimeout?: number;

  autobackupNotification?: boolean;
  autobackupNotificationError?: boolean;
  autobackupNotificationSuccess?: boolean;

  folderNotification?: boolean;
  folderSharing?: boolean;
  keyCreation?: boolean;
  keyDeleting?: boolean;
  keySharing?: boolean;
  keySharingRemoval?: boolean;

  canUpdatePassword?: boolean;
  clickedLink: string | number;
  encryption: IEncryption;
  historyState: boolean;
  isLoadingSettings: boolean;
  isNavigationFolderOpened: boolean;
  license?: { limit: number; active_user: number };
  limitingKeys: number;
  logoutTime?: number;
  cleanMonth?: number;
  microsoftAuth: IMicrosoftAuth;
  notify?: boolean;
  openedFolder?: string;
  search: string;
  groupStorage: GROUP_STORAGE;
  productType: PRODUCT_TYPE;
  userStorage: USER_STORAGE;
  sapGroups: string[];
  offlineMode?: boolean; 
  offlineModeFree?: boolean;
}

const initialState = {
  authorizationSupports: {
    microsoft: false,
    ps: true,
  },
  groupStorage: GROUP_STORAGE.LOCAL,
  userStorage: USER_STORAGE.LOCAL,
  historyState: false,
  clickedLink: 0,
  encryption: {
    enabled: false,
    loop: 1000,
  },
  errorMessage: undefined,
  bestIconEnabled: false,
  canUpdatePassword: false,
  hibpwnedEnabled: false,
  pwned: false,
  notify: false,
  globalOtp: false,
  logoutTime: 5,
  cleanMonth: 3,
  autoLogoutTimeout: 5,

  autobackupNotification: false,
  autobackupNotificationError: false,
  autobackupNotificationSuccess: false,

  folderNotification: false,
  folderSharing: false,
  keyCreation: false,
  keyDeleting: false,
  keySharing: false,
  keySharingRemoval: false,

  hasEmptyLogos: false,
  license: undefined,
  isLoading: false,
  isLoadingSettings: false,
  isNavigationFolderOpened: false,
  limitingKeys: 50,
  microsoftAuth: {
    client_id: undefined,
    tenant_id: undefined,
  },
  productType: PRODUCT_TYPE.STANDARD,
  openedFolder: undefined,
  search: "",
  initialize: true,

  sapGroups: [],
  offlineMode: true,
  offlineModeFree: true
};

export default function settings(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case getConfig.TRIGGER:
    case getGlobalSettings.TRIGGER:
    case getProtectedConfig.TRIGGER:
    case setBestIcon.TRIGGER:
    case setBackUpNotify.TRIGGER:
    case setFolderNotify.TRIGGER:
    case setClickedLink.TRIGGER:
    case setCustomize.TRIGGER:
    case setGlobalSettings.TRIGGER:
    case setOpenedFolder.TRIGGER:
    case setOpenedNavigationFolder.TRIGGER:
    case setSearch.TRIGGER:
    case settingsClear.TRIGGER:
    case setInitialize.TRIGGER:  
      return {
        ...state,
        isLoading: true,
      };

    case setBestIconCheck.TRIGGER:
      return {
        ...state,
      };

    case getSettings.TRIGGER:
      return {
        ...state,
        isLoadingSettings: true,
      };
    
    case setInitialize.SUCCESS:
      return {
        ...state,
        initialize: true,
        isLoading: false,
      }

    case getConfig.SUCCESS:
      return {
        ...state,
        initialize: true,
        authorizationSupports: payload["authorization-support"],
        groupStorage: payload["group-storage"] ? payload["group-storage"] : GROUP_STORAGE.LOCAL,
        userStorage: payload["user-storage"] ? payload["user-storage"] : USER_STORAGE.LOCAL,
        license: payload.license || undefined,
        microsoftAuth: payload.microsoft,
        productType: payload.rate,
      };

    case getProtectedConfig.SUCCESS:
      return {
        ...state,
        historyState: payload.history.isEnabled,
        encryption: {
          loop: payload.encrypt.encryptor_loop,
          enabled: payload.encrypt.encryption_enabled,
        },
        sapGroups: payload.sapGroup ? payload.sapGroup : []
      };

    case setBestIconCheck.SUCCESS:
      return {
        ...state,
        hasEmptyLogos: false,
      };

    case setBackUpNotify.SUCCESS:
      return {
        ...state,
        autobackupNotification: payload.autobackupNotification,
        autobackupNotificationError: payload.autobackupNotification.failure ? payload.autobackupNotification.failure : false,
        autobackupNotificationSuccess: payload.autobackupNotification.success ? payload.autobackupNotification.success : false,
        isLoading: false,
      };

    case setFolderNotify.SUCCESS:
      return {
        ...state,
        folderNotification: payload.folderNotification,
        folderSharing: payload.folderNotification.groupFolderSharing ? payload.folderNotification.groupFolderSharing : false,
        keyCreation: payload.folderNotification.groupKeyCreation ? payload.folderNotification.groupKeyCreation : false,
        keyDeleting: payload.folderNotification.groupKeyDeleting ? payload.folderNotification.groupKeyDeleting : false,
        keySharing: payload.folderNotification.groupKeySharing ? payload.folderNotification.groupKeySharing : false,
        keySharingRemoval: payload.folderNotification.groupKeySharingRemoval ? payload.folderNotification.groupKeySharingRemoval : false,
        isLoading: false,
      };

    case getSettings.SUCCESS: {
      return {
        ...state,
        bestIconEnabled: payload.besticonEnabled,
        canUpdatePassword: payload.canUpdatePassword,
        hibpwnedEnabled: payload.hibpwnedEnabled,
        autoLogoutTimeout: payload.autologoutTimeout,
        offlineModeFree: payload.offlineMode,
        autobackupNotification: payload.autobackupNotification,
        autobackupNotificationError: payload.autobackupNotification && payload.autobackupNotification.failure ? payload.autobackupNotification.failure : false,
        autobackupNotificationSuccess: payload.autobackupNotification && payload.autobackupNotification.success ? payload.autobackupNotification.success : false,
        folderNotification: payload.folderNotification,
        folderSharing: payload.folderNotification && payload.folderNotification.groupFolderSharing ? payload.folderNotification.groupFolderSharing : false,
        keyCreation: payload.folderNotification && payload.folderNotification.groupKeyCreation ? payload.folderNotification.groupKeyCreation : false,
        keyDeleting: payload.folderNotification && payload.folderNotification.groupKeyDeleting ? payload.folderNotification.groupKeyDeleting : false,
        keySharing: payload.folderNotification && payload.folderNotification.groupKeySharing ? payload.folderNotification.groupKeySharing : false,
        keySharingRemoval: payload.folderNotification && payload.folderNotification.groupKeySharingRemoval ? payload.folderNotification.groupKeySharingRemoval : false,
        isLoadingSettings: false,
      };
    }

    case setBestIcon.SUCCESS:
      return {
        ...state,
        autoLogoutTimeout: payload.autologoutTimeout,
        bestIconEnabled: payload.besticonEnabled,
        hasEmptyLogos: payload.hasEmptyLogos,
        hibpwnedEnabled: payload.hibpwnedEnabled,
        offlineModeFree: payload.offlineMode,
        isLoading: false,
      };

    case getGlobalSettings.SUCCESS:
      return {
        ...state,
        logoutTime: find(payload, ["setting", "AUTO_LOGOUT_TIMEOUT"])?.value,
        notify: find(payload, ["setting", "FOLDERS_ACCESS_EVENTS_NOTIFY"])?.value,
        globalOtp: find(payload, ["setting", "OTP_GLOBAL"])?.value,
        pwned: find(payload, ["setting", "HIB_PWNED"])?.value,
        cleanMonth: find(payload, ["setting", "LOG_ROTATION_PERIOD"])?.value,
        offlineMode: find(payload, ["setting", "OFFLINE_MODE_SETTINGS"])?.value,
        isLoading: false,
      };

    case setGlobalSettings.SUCCESS: {
      const additionalSettings: any = {};

      switch (payload.setting) {
        case "HIB_PWNED":
          additionalSettings.pwned = payload.value;
          break;
        case "FOLDERS_ACCESS_EVENTS_NOTIFY":
          additionalSettings.notify = payload.value;
          break;
        case "OTP_GLOBAL":
          additionalSettings.globalOtp = payload.value;
          break;
        case "AUTO_LOGOUT_TIMEOUT":
          additionalSettings.logoutTime = payload.value;
          break;
        case "LOG_ROTATION_PERIOD":
          additionalSettings.cleanMonth = payload.value;
          break;
        case "OFFLINE_MODE_SETTINGS":
          additionalSettings.offlineMode = payload.value;
          break;
      }

      return {
        ...state,
        ...additionalSettings,
        isLoading: false,
      };
    }

    case settingsClear.SUCCESS:
      return {
        ...state,
        clickedLink: initialState.clickedLink,
        isLoading: initialState.isLoading,
        isNavigationFolderOpened: initialState.isNavigationFolderOpened,
        search: initialState.search,
      };

    case setClickedLink.SUCCESS:
      return {
        ...state,
        clickedLink: payload,
      };

    case setOpenedFolder.SUCCESS:
      return {
        ...state,
        openedFolder: payload,
      };

    case setSearch.SUCCESS:
      return {
        ...state,
        search: payload,
      };

    case setOpenedNavigationFolder.SUCCESS:
      return {
        ...state,
        isNavigationFolderOpened: payload,
      };

    case getConfig.FAILURE:
      return {
        ...state,
        initialize: true,
        isLoadingSettings: false,
      };

    case getConfig.FULFILL:
    case getGlobalSettings.FAILURE:
    case getProtectedConfig.FULFILL:
    case getSettings.FAILURE:
    case setBestIcon.FAILURE:
    case setBackUpNotify.FAILURE:
    case setFolderNotify.FAILURE:
    case setBestIconCheck.FAILURE:
    case setClickedLink.FAILURE:
    case setClickedLink.FULFILL:
    case setCustomize.FAILURE:
    case setCustomize.FULFILL:
    case setGlobalSettings.FAILURE:
    case setOpenedFolder.FAILURE:
    case setOpenedFolder.FULFILL:
    case setOpenedNavigationFolder.FAILURE:
    case setOpenedNavigationFolder.FULFILL:
    case setSearch.FAILURE:
    case setSearch.FULFILL:
    case settingsClear.FAILURE:
    case settingsClear.FULFILL:
    case setInitialize.FAILURE:
    case setInitialize.FULFILL:
      return {
        ...state,
        isLoading: false,
        isLoadingSettings: false,
      };

    default:
      return state;
  }
}
