/* eslint-disable import/no-mutable-exports */
import { typeOption } from "core/types";

import i18n from "locales/i18n";

let optionsAccessType: typeOption[] = [];
let optionsCountry: typeOption[] = [];
let optionsCreditCardTypes: typeOption[] = [];
let optionsEmailTypes: typeOption[] = [];
let optionsIDType: typeOption[] = [];
let optionsMailSecurityTypes: typeOption[] = [];
let optionsProtocol: typeOption[] = [];

/**
 * Function fill needed for translate, when user change language
 */
function fill() {
  optionsAccessType = [{ value: "access", label: "Access type" }];

  optionsEmailTypes = [
    { value: "SMTP", label: i18n.t("options_email_types_smtp") },
    { value: "POP3", label: i18n.t("options_email_types_pop3") },
    { value: "IMAP", label: i18n.t("options_email_types_imap") },
  ];

  optionsMailSecurityTypes = [
    { value: "none", label: i18n.t("options_mail_security_types_none") },
    { value: "STARTTLS", label: i18n.t("options_mail_security_types_starttls") },
    { value: "SSL/TLS", label: i18n.t("options_mail_security_types_ssl_tls") },
  ];

  optionsCreditCardTypes = [
    { value: "amex", label: i18n.t("options_credit_card_types_amex") },
    { value: "china_union_pay", label: i18n.t("options_credit_card_types_china_union_pay") },
    { value: "diners", label: i18n.t("options_credit_card_types_diners") },
    { value: "discover", label: i18n.t("options_credit_card_types_discover") },
    { value: "jcb", label: i18n.t("options_credit_card_types_jcb") },
    { value: "mastercard", label: i18n.t("options_credit_card_types_mastercard") },
    { value: "visa", label: i18n.t("options_credit_card_types_visa") },
  ];

  optionsIDType = [
    { value: "driver_license", label: i18n.t("options_id_type_driver_license") },
    { value: "passport", label: i18n.t("options_id_type_passport") },
    { value: "other", label: i18n.t("options_id_type_other") },
    // { value: "tax_number", label: i18n.t("options_id_type_tax_number") },
  ];

  optionsProtocol = [
    { value: "ARP", label: i18n.t("option_protocol_arp") },
    { value: "DHCP", label: i18n.t("option_protocol_dhcp") },
    { value: "DNS", label: i18n.t("option_protocol_dns") },
    { value: "FTP", label: i18n.t("option_protocol_ftp") },
    { value: "HTTP", label: i18n.t("option_protocol_http") },
    { value: "HTTPS", label: i18n.t("option_protocol_https") },
    { value: "ICMP", label: i18n.t("option_protocol_icmp") },
    { value: "IGMP", label: i18n.t("option_protocol_igmp") },
    { value: "IMAP4", label: i18n.t("option_protocol_imap4") },
    { value: "NTP", label: i18n.t("option_protocol_ntp") },
    { value: "POP3", label: i18n.t("option_protocol_pop3") },
    { value: "RTP", label: i18n.t("option_protocol_rtp") },
    { value: "SIP", label: i18n.t("option_protocol_sip") },
    { value: "SMTP", label: i18n.t("option_protocol_smtp") },
    { value: "SNMP2/3", label: i18n.t("option_protocol_snmp2_3") },
    { value: "SSH", label: i18n.t("option_protocol_ssh") },
    { value: "TCP", label: i18n.t("option_protocol_tcp") },
    { value: "TCP/IP", label: i18n.t("option_protocol_tcp_ip") },
    { value: "TFTP", label: i18n.t("option_protocol_tftp") },
    { value: "TLS", label: i18n.t("option_protocol_tls") },
  ];

  optionsCountry = [
    { value: "ABH", label: i18n.t("options_country_ABH") },
    { value: "AUS", label: i18n.t("options_country_AUS") },
    { value: "AUT", label: i18n.t("options_country_AUT") },
    { value: "AZE", label: i18n.t("options_country_AZE") },
    { value: "ALB", label: i18n.t("options_country_ALB") },
    { value: "DZA", label: i18n.t("options_country_DZA") },
    { value: "ASM", label: i18n.t("options_country_ASM") },
    { value: "AIA", label: i18n.t("options_country_AIA") },
    { value: "AGO", label: i18n.t("options_country_AGO") },
    { value: "AND", label: i18n.t("options_country_AND") },
    { value: "ATA", label: i18n.t("options_country_ATA") },
    { value: "ATG", label: i18n.t("options_country_ATG") },
    { value: "ARG", label: i18n.t("options_country_ARG") },
    { value: "ARM", label: i18n.t("options_country_ARM") },
    { value: "ABW", label: i18n.t("options_country_ABW") },
    { value: "AFG", label: i18n.t("options_country_AFG") },
    { value: "BHS", label: i18n.t("options_country_BHS") },
    { value: "BGD", label: i18n.t("options_country_BGD") },
    { value: "BRB", label: i18n.t("options_country_BRB") },
    { value: "BHR", label: i18n.t("options_country_BHR") },
    { value: "BLR", label: i18n.t("options_country_BLR") },
    { value: "BLZ", label: i18n.t("options_country_BLZ") },
    { value: "BEL", label: i18n.t("options_country_BEL") },
    { value: "BEN", label: i18n.t("options_country_BEN") },
    { value: "BMU", label: i18n.t("options_country_BMU") },
    { value: "BGR", label: i18n.t("options_country_BGR") },
    { value: "BOL", label: i18n.t("options_country_BOL") },
    { value: "BES", label: i18n.t("options_country_BES") },
    { value: "BIH", label: i18n.t("options_country_BIH") },
    { value: "BWA", label: i18n.t("options_country_BWA") },
    { value: "BRA", label: i18n.t("options_country_BRA") },
    { value: "IOT", label: i18n.t("options_country_IOT") },
    { value: "BRN", label: i18n.t("options_country_BRN") },
    { value: "BFA", label: i18n.t("options_country_BFA") },
    { value: "BDI", label: i18n.t("options_country_BDI") },
    { value: "BTN", label: i18n.t("options_country_BTN") },
    { value: "VUT", label: i18n.t("options_country_VUT") },
    { value: "HUN", label: i18n.t("options_country_HUN") },
    { value: "VEN", label: i18n.t("options_country_VEN") },
    { value: "VGB", label: i18n.t("options_country_VGB") },
    { value: "VIR", label: i18n.t("options_country_VIR") },
    { value: "VNM", label: i18n.t("options_country_VNM") },
    { value: "GAB", label: i18n.t("options_country_GAB") },
    { value: "HTI", label: i18n.t("options_country_HTI") },
    { value: "GUY", label: i18n.t("options_country_GUY") },
    { value: "GMB", label: i18n.t("options_country_GMB") },
    { value: "GHA", label: i18n.t("options_country_GHA") },
    { value: "GLP", label: i18n.t("options_country_GLP") },
    { value: "GTM", label: i18n.t("options_country_GTM") },
    { value: "GIN", label: i18n.t("options_country_GIN") },
    { value: "GNB", label: i18n.t("options_country_GNB") },
    { value: "DEU", label: i18n.t("options_country_DEU") },
    { value: "GGY", label: i18n.t("options_country_GGY") },
    { value: "GIB", label: i18n.t("options_country_GIB") },
    { value: "HND", label: i18n.t("options_country_HND") },
    { value: "HKG", label: i18n.t("options_country_HKG") },
    { value: "GRD", label: i18n.t("options_country_GRD") },
    { value: "GRL", label: i18n.t("options_country_GRL") },
    { value: "GRC", label: i18n.t("options_country_GRC") },
    { value: "GEO", label: i18n.t("options_country_GEO") },
    { value: "GUM", label: i18n.t("options_country_GUM") },
    { value: "DNK", label: i18n.t("options_country_DNK") },
    { value: "JEY", label: i18n.t("options_country_JEY") },
    { value: "DJI", label: i18n.t("options_country_DJI") },
    { value: "DMA", label: i18n.t("options_country_DMA") },
    { value: "DOM", label: i18n.t("options_country_DOM") },
    { value: "EGY", label: i18n.t("options_country_EGY") },
    { value: "ZMB", label: i18n.t("options_country_ZMB") },
    { value: "ESH", label: i18n.t("options_country_ESH") },
    { value: "ZWE", label: i18n.t("options_country_ZWE") },
    { value: "ISR", label: i18n.t("options_country_ISR") },
    { value: "IND", label: i18n.t("options_country_IND") },
    { value: "IDN", label: i18n.t("options_country_IDN") },
    { value: "JOR", label: i18n.t("options_country_JOR") },
    { value: "IRQ", label: i18n.t("options_country_IRQ") },
    { value: "IRN", label: i18n.t("options_country_IRN") },
    { value: "IRL", label: i18n.t("options_country_IRL") },
    { value: "ISL", label: i18n.t("options_country_ISL") },
    { value: "ESP", label: i18n.t("options_country_ESP") },
    { value: "ITA", label: i18n.t("options_country_ITA") },
    { value: "YEM", label: i18n.t("options_country_YEM") },
    { value: "CPV", label: i18n.t("options_country_CPV") },
    { value: "KAZ", label: i18n.t("options_country_KAZ") },
    { value: "KHM", label: i18n.t("options_country_KHM") },
    { value: "CMR", label: i18n.t("options_country_CMR") },
    { value: "CAN", label: i18n.t("options_country_CAN") },
    { value: "QAT", label: i18n.t("options_country_QAT") },
    { value: "KEN", label: i18n.t("options_country_KEN") },
    { value: "CYP", label: i18n.t("options_country_CYP") },
    { value: "KGZ", label: i18n.t("options_country_KGZ") },
    { value: "KIR", label: i18n.t("options_country_KIR") },
    { value: "CHN", label: i18n.t("options_country_CHN") },
    { value: "CCK", label: i18n.t("options_country_CCK") },
    { value: "COL", label: i18n.t("options_country_COL") },
    { value: "COM", label: i18n.t("options_country_COM") },
    { value: "COG", label: i18n.t("options_country_COG") },
    { value: "COD", label: i18n.t("options_country_COD") },
    { value: "PRK", label: i18n.t("options_country_PRK") },
    { value: "KOR", label: i18n.t("options_country_KOR") },
    { value: "CRI", label: i18n.t("options_country_CRI") },
    { value: "CIV", label: i18n.t("options_country_CIV") },
    { value: "CUB", label: i18n.t("options_country_CUB") },
    { value: "KWT", label: i18n.t("options_country_KWT") },
    { value: "CUW", label: i18n.t("options_country_CUW") },
    { value: "LAO", label: i18n.t("options_country_LAO") },
    { value: "LVA", label: i18n.t("options_country_LVA") },
    { value: "LSO", label: i18n.t("options_country_LSO") },
    { value: "LBN", label: i18n.t("options_country_LBN") },
    { value: "LBY", label: i18n.t("options_country_LBY") },
    { value: "LBR", label: i18n.t("options_country_LBR") },
    { value: "LIE", label: i18n.t("options_country_LIE") },
    { value: "LTU", label: i18n.t("options_country_LTU") },
    { value: "LUX", label: i18n.t("options_country_LUX") },
    { value: "MUS", label: i18n.t("options_country_MUS") },
    { value: "MRT", label: i18n.t("options_country_MRT") },
    { value: "MDG", label: i18n.t("options_country_MDG") },
    { value: "MYT", label: i18n.t("options_country_MYT") },
    { value: "MAC", label: i18n.t("options_country_MAC") },
    { value: "MWI", label: i18n.t("options_country_MWI") },
    { value: "MYS", label: i18n.t("options_country_MYS") },
    { value: "MLI", label: i18n.t("options_country_MLI") },
    { value: "UMI", label: i18n.t("options_country_UMI") },
    { value: "MDV", label: i18n.t("options_country_MDV") },
    { value: "MLT", label: i18n.t("options_country_MLT") },
    { value: "MAR", label: i18n.t("options_country_MAR") },
    { value: "MTQ", label: i18n.t("options_country_MTQ") },
    { value: "MHL", label: i18n.t("options_country_MHL") },
    { value: "MEX", label: i18n.t("options_country_MEX") },
    { value: "FSM", label: i18n.t("options_country_FSM") },
    { value: "MOZ", label: i18n.t("options_country_MOZ") },
    { value: "MDA", label: i18n.t("options_country_MDA") },
    { value: "MCO", label: i18n.t("options_country_MCO") },
    { value: "MNG", label: i18n.t("options_country_MNG") },
    { value: "MSR", label: i18n.t("options_country_MSR") },
    { value: "MMR", label: i18n.t("options_country_MMR") },
    { value: "NAM", label: i18n.t("options_country_NAM") },
    { value: "NRU", label: i18n.t("options_country_NRU") },
    { value: "NPL", label: i18n.t("options_country_NPL") },
    { value: "NER", label: i18n.t("options_country_NER") },
    { value: "NGA", label: i18n.t("options_country_NGA") },
    { value: "NLD", label: i18n.t("options_country_NLD") },
    { value: "NIC", label: i18n.t("options_country_NIC") },
    { value: "NIU", label: i18n.t("options_country_NIU") },
    { value: "NZL", label: i18n.t("options_country_NZL") },
    { value: "NCL", label: i18n.t("options_country_NCL") },
    { value: "NOR", label: i18n.t("options_country_NOR") },
    { value: "ARE", label: i18n.t("options_country_ARE") },
    { value: "OMN", label: i18n.t("options_country_OMN") },
    { value: "BVT", label: i18n.t("options_country_BVT") },
    { value: "IMN", label: i18n.t("options_country_IMN") },
    { value: "NFK", label: i18n.t("options_country_NFK") },
    { value: "CXR", label: i18n.t("options_country_CXR") },
    { value: "HMD", label: i18n.t("options_country_HMD") },
    { value: "CYM", label: i18n.t("options_country_CYM") },
    { value: "COK", label: i18n.t("options_country_COK") },
    { value: "TCA", label: i18n.t("options_country_TCA") },
    { value: "PAK", label: i18n.t("options_country_PAK") },
    { value: "PLW", label: i18n.t("options_country_PLW") },
    { value: "PSE", label: i18n.t("options_country_PSE") },
    { value: "PAN", label: i18n.t("options_country_PAN") },
    { value: "VAT", label: i18n.t("options_country_VAT") },
    { value: "PNG", label: i18n.t("options_country_PNG") },
    { value: "PRY", label: i18n.t("options_country_PRY") },
    { value: "PER", label: i18n.t("options_country_PER") },
    { value: "PCN", label: i18n.t("options_country_PCN") },
    { value: "POL", label: i18n.t("options_country_POL") },
    { value: "PRT", label: i18n.t("options_country_PRT") },
    { value: "PRI", label: i18n.t("options_country_PRI") },
    { value: "MKD", label: i18n.t("options_country_MKD") },
    { value: "REU", label: i18n.t("options_country_REU") },
    { value: "RUS", label: i18n.t("options_country_RUS") },
    { value: "RWA", label: i18n.t("options_country_RWA") },
    { value: "ROU", label: i18n.t("options_country_ROU") },
    { value: "WSM", label: i18n.t("options_country_WSM") },
    { value: "SMR", label: i18n.t("options_country_SMR") },
    { value: "STP", label: i18n.t("options_country_STP") },
    { value: "SAU", label: i18n.t("options_country_SAU") },
    { value: "SHN", label: i18n.t("options_country_SHN") },
    { value: "MNP", label: i18n.t("options_country_MNP") },
    { value: "BLM", label: i18n.t("options_country_BLM") },
    { value: "MAF", label: i18n.t("options_country_MAF") },
    { value: "SEN", label: i18n.t("options_country_SEN") },
    { value: "VCT", label: i18n.t("options_country_VCT") },
    { value: "KNA", label: i18n.t("options_country_KNA") },
    { value: "LCA", label: i18n.t("options_country_LCA") },
    { value: "SPM", label: i18n.t("options_country_SPM") },
    { value: "SRB", label: i18n.t("options_country_SRB") },
    { value: "SYC", label: i18n.t("options_country_SYC") },
    { value: "SGP", label: i18n.t("options_country_SGP") },
    { value: "SXM", label: i18n.t("options_country_SXM") },
    { value: "SYR", label: i18n.t("options_country_SYR") },
    { value: "SVK", label: i18n.t("options_country_SVK") },
    { value: "SVN", label: i18n.t("options_country_SVN") },
    { value: "GBR", label: i18n.t("options_country_GBR") },
    { value: "USA", label: i18n.t("options_country_USA") },
    { value: "SLB", label: i18n.t("options_country_SLB") },
    { value: "SOM", label: i18n.t("options_country_SOM") },
    { value: "SDN", label: i18n.t("options_country_SDN") },
    { value: "SUR", label: i18n.t("options_country_SUR") },
    { value: "SLE", label: i18n.t("options_country_SLE") },
    { value: "TJK", label: i18n.t("options_country_TJK") },
    { value: "THA", label: i18n.t("options_country_THA") },
    { value: "TWN", label: i18n.t("options_country_TWN") },
    { value: "TZA", label: i18n.t("options_country_TZA") },
    { value: "TLS", label: i18n.t("options_country_TLS") },
    { value: "TGO", label: i18n.t("options_country_TGO") },
    { value: "TKL", label: i18n.t("options_country_TKL") },
    { value: "TON", label: i18n.t("options_country_TON") },
    { value: "TTO", label: i18n.t("options_country_TTO") },
    { value: "TUV", label: i18n.t("options_country_TUV") },
    { value: "TUN", label: i18n.t("options_country_TUN") },
    { value: "TKM", label: i18n.t("options_country_TKM") },
    { value: "TUR", label: i18n.t("options_country_TUR") },
    { value: "UGA", label: i18n.t("options_country_UGA") },
    { value: "UZB", label: i18n.t("options_country_UZB") },
    { value: "UKR", label: i18n.t("options_country_UKR") },
    { value: "WLF", label: i18n.t("options_country_WLF") },
    { value: "URY", label: i18n.t("options_country_URY") },
    { value: "FRO", label: i18n.t("options_country_FRO") },
    { value: "FJI", label: i18n.t("options_country_FJI") },
    { value: "PHL", label: i18n.t("options_country_PHL") },
    { value: "FIN", label: i18n.t("options_country_FIN") },
    { value: "FLK", label: i18n.t("options_country_FLK") },
    { value: "FRA", label: i18n.t("options_country_FRA") },
    { value: "GUF", label: i18n.t("options_country_GUF") },
    { value: "PYF", label: i18n.t("options_country_PYF") },
    { value: "ATF", label: i18n.t("options_country_ATF") },
    { value: "HRV", label: i18n.t("options_country_HRV") },
    { value: "CAF", label: i18n.t("options_country_CAF") },
    { value: "TCD", label: i18n.t("options_country_TCD") },
    { value: "MNE", label: i18n.t("options_country_MNE") },
    { value: "CZE", label: i18n.t("options_country_CZE") },
    { value: "CHL", label: i18n.t("options_country_CHL") },
    { value: "CHE", label: i18n.t("options_country_CHE") },
    { value: "SWE", label: i18n.t("options_country_SWE") },
    { value: "SJM", label: i18n.t("options_country_SJM") },
    { value: "LKA", label: i18n.t("options_country_LKA") },
    { value: "ECU", label: i18n.t("options_country_ECU") },
    { value: "GNQ", label: i18n.t("options_country_GNQ") },
    { value: "ALA", label: i18n.t("options_country_ALA") },
    { value: "SLV", label: i18n.t("options_country_SLV") },
    { value: "ERI", label: i18n.t("options_country_ERI") },
    { value: "SWZ", label: i18n.t("options_country_SWZ") },
    { value: "EST", label: i18n.t("options_country_EST") },
    { value: "ETH", label: i18n.t("options_country_ETH") },
    { value: "ZAF", label: i18n.t("options_country_ZAF") },
    { value: "SGS", label: i18n.t("options_country_SGS") },
    { value: "OST", label: i18n.t("options_country_OST") },
    { value: "SSD", label: i18n.t("options_country_SSD") },
    { value: "JAM", label: i18n.t("options_country_JAM") },
    { value: "_JP", label: i18n.t("options_country_JP") },
  ];
}

fill();

i18n.on("languageChanged init", () => fill());

export {
  optionsAccessType,
  optionsCountry,
  optionsCreditCardTypes,
  optionsEmailTypes,
  optionsIDType,
  optionsMailSecurityTypes,
  optionsProtocol,
};
