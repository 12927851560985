import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("CHECK_CONNECTION");

const checkConnection = () => {
  return http({ route: "check/connection", isProtected: false });
};

function* handler() {
  try {
    const response = yield call(checkConnection);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
