import { all, fork } from "redux-saga/effects";

import { saga as usersChose } from "store/routines/users/chose";
import { saga as usersClear } from "store/routines/users/clear";
import { saga as usersControlBlock } from "store/routines/users/controlBlock";
import { saga as usersControlOTP } from "store/routines/users/controlOTP";
import { saga as usersDelete } from "store/routines/users/delete";
import { saga as usersDeleteMulti } from "store/routines/users/deleteMulti";
import { saga as usersGet } from "store/routines/users/get";
import { saga as usersGetAll } from "store/routines/users/getAll";
import { saga as usersGetAllLDAP } from "store/routines/users/getAllLDAP";
import { saga as usersGetAvatar } from "store/routines/users/getAvatar";
import { saga as usersManagement } from "store/routines/users/manage";
import { saga as usersManagementLocal } from "store/routines/users/manageLocal";
import { saga as usersSync } from "store/routines/users/sync";
import { saga as usersUploadAvatar } from "store/routines/users/uploadAvatar";
import { saga as deferredUsers } from "store/routines/users/deferredUsers";

export default function* users() {
  return yield all([
    fork(usersChose),
    fork(usersClear),
    fork(usersControlBlock),
    fork(usersControlOTP),
    fork(usersDelete),
    fork(usersDeleteMulti),
    fork(usersGet),
    fork(usersGetAll),
    fork(usersGetAllLDAP),
    fork(usersGetAvatar),
    fork(usersManagement),
    fork(usersManagementLocal),
    fork(usersSync),
    fork(usersUploadAvatar),
    fork(deferredUsers),
  ]);
}
