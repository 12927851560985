import React, { FC } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";

import { getGroupFromListByID, typeGroup } from "core";

import { RootState } from "store/reducers";

const cnMultiply = cn("InfoBoxGroupMultiply");
type Props = {
  chosen: string[];
  list: typeGroup[];
};

const Multiply: FC<Props> = ({ chosen, list }) => {
  return (
    <div className={cnMultiply()}>
      {chosen.map((id: string) => {
        const group: typeGroup = getGroupFromListByID(list, id);
        return (
          <div className={cnMultiply("Item")} key={group.id}>
            <Icon name="icon-group-circle" width={30} height={30} /> <span>{group.name}</span>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ groups: { chosen, list } }: RootState) => ({ chosen, list });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Multiply);
