import { cn } from "@bem-react/classname";
import { Modal } from "components/Generic";
import Color from "color";
import {
  ACTION,
  FORM_GROUP_MANAGEMENT,
  getGroupFromListByID,
  getUserFromListByID,
  GROUP_STORAGE,
  typeGroup,
  typeGroupFolder,
  typeUser,
  http,
} from "core";
import { AsyncPaginate } from "react-select-async-paginate";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { Button, Icon } from "ss-ui";
import { groupsManagement } from "store/actions";
import { RootState } from "store/reducers";
import { FormValues, Payload as PayloadGroupsManagement } from "store/routines/groups/manage";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

import RenderMembersList from "./RenderMembersList";

const cnMembers = cn("InfoBoxGroupMembers");
const cnGroups = cn("InfoBoxUserGroups");

type Props = {
  chosen: string[];
  groupsManagement: (payload: PayloadGroupsManagement) => void;
  list: typeGroup[];
  groupStorage: GROUP_STORAGE;
  handleModalMembers: (value: boolean) => void;
  modalMembersValue: boolean;
  customize: SettingsCustomizeState;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const Members: FC<Props> = ({
  chosen,
  groupsManagement,
  handleSubmit,
  list,
  groupStorage,
  change,
  handleModalMembers,
  modalMembersValue,
  customize,
}) => {
  const isNew = chosen[0] === "new";
  const group: typeGroup = getGroupFromListByID(list, chosen[0]);
  const [groupChecked, setGroupChecked] = useState<any>(group && group.users);
  const { t } = useTranslation();
  const [isShowAdd, setIsShowAdd] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    setChecked(isNew || !(group.users && group.users.length > 0) ? [] : group.users.map(({ id }: typeUser) => id));
    setGroupChecked( group && group.users);
    setCurrentPageUsers(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  const handleForm = () => {
    groupsManagement({
      id: isNew ? undefined : group.id,
      action: isNew ? ACTION.CREATE : ACTION.UPDATE,
      payload: {
        folders: isNew
          ? []
          : group.folders.map((folder: typeGroupFolder) => ({
              folder: folder.folder,
              access: folder.access,
              recursion: folder.recursion,
            })),
        users: checked,
        name: isNew ? "" : group.name,
        azureId: isNew || !group.azureId || groupStorage === GROUP_STORAGE.LOCAL ? undefined : group.azureId,
      },
    });
    handleModalMembers(false);
  };

  const handleChange = (value: any) => {
    setIsShowAdd(false);
    setGroupChecked([...groupChecked, { email: "", id: value.value, username: value.label } ]);
    setChecked([...checked, value.value]);
    change( "user", null);
    setCurrentPageUsers(1);
  };

  const templateType = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-group-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  const handleRemove = (id: string) => {
    setChecked([...checked.filter((item: string) => item !== id)]);
  };

  return (
    <>
      {!isNew && <RenderMembersList />}
      {isNew && <div className={cnMembers("Error")}>{t("groups-Error")}</div>}

      {modalMembersValue ? (
        <Modal handleClose={() => handleModalMembers(false)} className={cnMembers("Modal")}>
          <div className={cnMembers("Modal-Header")}>
            <Icon name="icon-group-circle" width={30} height={30} /> <p>{isNew ? t("groups-New") : group.name}</p>
          </div>

          <form onSubmit={handleSubmit(handleForm)} className={cnMembers("Modal-Form")}>
            {/* {!isNew && checked.length === 0 && <div className={cnMembers("Empty")}>{t("groups-List-Empty")}</div>} */}
            <div className={cnMembers("Modal-List-Header")}>{t("groups-List-Members")}</div>

            <div className={cnMembers("Modal-List")}>
              {groupChecked.length !== 0 &&
                checked.map((id: string) => {
                  const user: typeUser = getUserFromListByID(groupChecked, id);
                  return (
                    <div key={id} className={cnMembers("Modal-List-Item")}>
                      <div className={cnMembers("Modal-List-Icon")}>
                      <Icon name="icon-user-circle" width={20} height={20} />
                      </div>
                      <div className={cnMembers("Modal-List-User")}>
                        <p>
                          {user.username}
                        </p>
                        {/* <span>{mapRolesName[user.roles]}</span> */}
                      </div>
                      <button type="button" onClick={() => handleRemove(id)} className={cnMembers("Delete")} id="btn_remove_members">
                        <Icon name="icon-trash" width={16} height={16} />
                        &nbsp;
                      </button>
                    </div>
                  );
                })}

              {isShowAdd &&
                <>
                  <AsyncPaginate
                    placeholder={t("folder_filters_box_placeholder_user")}
                    isSearchable={true}
                    menuPlacement={groupChecked.length > 5 ? "top" : "auto"}
                    className={cnGroups("Select-Group")}
                    classNamePrefix={cnGroups("Select-Group")}
                    onChange={handleChange}
                    formatOptionLabel={templateType}
                    loadOptions={async selectSearchUsers => {
                      let params: { currentPage: number; pageSize: number; search?: string; } = { 
                        currentPage: currentPageUsers,
                        pageSize: 20, 
                        search: selectSearchUsers,
                      };
                      if (currentPageUsers) params = { ...params, currentPage: currentPageUsers };
                      if (selectSearchUsers !== "") params = { ...params, search: selectSearchUsers, currentPage: 1 };
                      const response: any = await http({ route: "manager/users", method: "GET", payload: { params } });
                      if (response.pagesCount !== currentPageUsers) {
                        setCurrentPageUsers(response.currentPage + 1);
                      }
                      return {
                        hasMore: currentPageUsers < response.pagesCount,
                          options: response.results.map(user => ({
                          label: user.username,
                          value: user.id,
                          isDisabled: checked.includes(user.id),
                        })),
                      };
                    }}
                    styles={{
                      indicatorSeparator: (provided: any) => ({
                        ...provided,
                        backgroundColor: "transparent",
                      }),
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary25: "#EAFBFD",
                        primary75: mainColor,
                        primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                        primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                      },
                    })}
                  />
                </>
              }

              {!isNew && !isShowAdd && (
                <Button
                  id="button_action_add_user"
                  className={cnMembers("ButtonAdd")}
                  icon="icon-plus"
                  onClick={() => setIsShowAdd(true)}
                  theme="primary-link"
                  type="button"
                >
                  {t("button_add-User")}
                </Button>
              )}
            </div>
            <div className={cnMembers("Modal-Action")}>
              <Button id="button_action_save_member" type="submit">
                {t("button-Save")}
              </Button>
              <Button id="button_action_cancel_member" theme="secondary-outline" onClick={() => handleModalMembers(false)}>
                {t("button-Cancel")}
              </Button>
            </div>
          </form>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = ({
  groups: { list, chosen, },
  settings: { groupStorage },
  settingsTest: { customize }
}: RootState) => ({
  chosen,
  list,
  groupStorage,
  customize,
});

const mapDispatchToProps = { groupsManagement };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_GROUP_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(Members);
