import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { folderGetAll, getKeysPagination, favoritesGet } from "store/actions";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export type Payload = {
  keys: string[];
  folderID: string;
};

export const action: Routine = createRoutine("KEYS_MULTIPLE_DELETE");

const multipleDelete = ({ keys, folderID }: Payload) => http({ route: `keys/remove/${folderID}`, method: "POST", payload: { keys } });

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(multipleDelete, payload);

    toast.success(i18n.t("toast_key_success_removed", { count: 2 }));

    yield put(action.success());
    yield put(favoritesGet.trigger());

    yield put(getKeysPagination.trigger({ folderID: payload.folderID }));

    yield put(folderGetAll.trigger());
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
