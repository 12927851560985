import React from "react";

export enum VARIANT_DRAFT_UTILS {
  BLOCK = "block",
  INLINE = "inline",
}

export enum STYLE_DRAFT_UTILS {
  HEADER_ONE = "header-one",
  HEADER_TWO = "header-two",
  UNORDERED_LIST_ITEM = "unordered-list-item",
  ORDERED_LIST_ITEM = "ordered-list-item",
  BOLD = "BOLD",
  ITALIC = "ITALIC",
  UNDERLINE = "UNDERLINE",
}

export type TYPE_DRAFT_UTILS = {
  label: string | JSX.Element;
  style: STYLE_DRAFT_UTILS;
  type: VARIANT_DRAFT_UTILS;
};

export const DRAFT_UTILS: TYPE_DRAFT_UTILS[] = [
  { label: "H1", style: STYLE_DRAFT_UTILS.HEADER_ONE, type: VARIANT_DRAFT_UTILS.BLOCK },
  { label: "H2", style: STYLE_DRAFT_UTILS.HEADER_TWO, type: VARIANT_DRAFT_UTILS.BLOCK },
  {
    label: (
      <span>
        <svg
          enableBackground="new 0 0 60.123 60.123"
          version="1.1"
          viewBox="0 0 60.123 60.123"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          width="15px"
          height="15px"
        >
          <path d="m57.124 51.893h-40.204c-1.657 0-3-1.343-3-3s1.343-3 3-3h40.203c1.657 0 3 1.343 3 3s-1.342 3-2.999 3z" />
          <path d="m57.124 33.062h-40.204c-1.657 0-3-1.343-3-3s1.343-3 3-3h40.203c1.657 0 3 1.343 3 3 1e-3 1.657-1.342 3-2.999 3z" />
          <path d="m57.124 14.231h-40.204c-1.657 0-3-1.343-3-3s1.343-3 3-3h40.203c1.657 0 3 1.343 3 3s-1.342 3-2.999 3z" />
          <circle cx="4.029" cy="11.463" r="4.029" />
          <circle cx="4.029" cy="30.062" r="4.029" />
          <circle cx="4.029" cy="48.661" r="4.029" />
        </svg>
      </span>
    ),
    style: STYLE_DRAFT_UTILS.UNORDERED_LIST_ITEM,
    type: VARIANT_DRAFT_UTILS.BLOCK,
  },
  {
    label: (
      <span>
        <svg enableBackground="new 0 0 512.003 512.003" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px">
          <path d="m155 90.001h342c8.284 0 15-6.716 15-15s-6.716-15-15-15h-342c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
          <path d="m497 241h-342c-8.284 0-15 6.716-15 15s6.716 15 15 15h342c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
          <path d="m497 422h-342c-8.284 0-15 6.716-15 15s6.716 15 15 15h342c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
          <path
            d="m26.003 30.001h8.512v95c0 8.284 6.716 15 15 15s15-6.716 15-15v-110c0-8.284-6.716-15-15-15h-23.512c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
          <path
            d="m74.083 295.753c-8.847.111-18.162.192-26.478.228 5.659-7.649 13.006-17.786 22.378-31.176 7.1-10.143 11.74-19.777 13.794-28.635.114-.491.203-.987.267-1.487l.533-4.173c.081-.63.121-1.265.121-1.901 0-23.494-19.113-42.607-42.606-42.607-20.314 0-37.897 14.453-41.808 34.365-1.597 8.129 3.699 16.013 11.828 17.609 8.13 1.595 16.013-3.699 17.609-11.828 1.154-5.879 6.357-10.146 12.37-10.146 6.692 0 12.183 5.242 12.583 11.835l-.281 2.203c-.932 3.577-3.26 9.377-8.988 17.563-17.055 24.369-27.23 37.691-32.696 44.849-6.906 9.042-10.71 14.023-8.206 22.166 1.492 4.849 5.153 8.565 10.048 10.196 2.03.677 3.657 1.219 24.6 1.219 8.314 0 19.673-.085 35.31-.282 8.283-.104 14.914-6.904 14.811-15.188-.106-8.283-6.918-14.902-15.189-14.81z" />
          <path
            d="m44.637 372.001c-20.264 0-37.802 14.417-41.702 34.28-1.597 8.129 3.699 16.013 11.828 17.609 8.13 1.595 16.013-3.699 17.609-11.829 1.145-5.829 6.303-10.06 12.265-10.06 6.893 0 12.5 5.607 12.5 12.5s-5.607 12.5-12.5 12.5c-8.284 0-15 6.716-15 15s6.716 15 15 15c6.893 0 12.5 5.607 12.5 12.5s-5.607 12.5-12.5 12.5c-6.202 0-11.524-4.616-12.378-10.736-.081-.58-.122-1.173-.122-1.764 0-8.284-6.716-15-15-15s-15 6.716-15 15c0 1.972.138 3.959.409 5.909 2.911 20.86 21.007 36.591 42.091 36.591 23.435 0 42.5-19.065 42.5-42.5 0-10.481-3.822-20.082-10.134-27.5 6.313-7.418 10.134-17.019 10.134-27.5 0-23.434-19.066-42.5-42.5-42.5z" />
        </svg>
      </span>
    ),
    style: STYLE_DRAFT_UTILS.ORDERED_LIST_ITEM,
    type: VARIANT_DRAFT_UTILS.BLOCK,
  },
  { label: <span className="bold">B</span>, style: STYLE_DRAFT_UTILS.BOLD, type: VARIANT_DRAFT_UTILS.INLINE },
  { label: <span className="italic">I</span>, style: STYLE_DRAFT_UTILS.ITALIC, type: VARIANT_DRAFT_UTILS.INLINE },
  {
    label: <span className="underline">U</span>,
    style: STYLE_DRAFT_UTILS.UNDERLINE,
    type: VARIANT_DRAFT_UTILS.INLINE,
  },
];
