/* eslint-disable import/no-mutable-exports */
import i18n from "locales/i18n";

import { FOLDER_ACCESS, FOLDER_TYPE, ROLE_TYPE, typeFolder } from "core/types";

export const PARSE_DATE_FORMAT = "DD-MM-YYYY HH:mm";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm";
export const TIME_FORMAT_LITE = "HH:mm";
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATETIME_FORMAT_LITE = `${DATE_FORMAT} ${TIME_FORMAT_LITE}`;

export const emptyFolder: typeFolder = {
  access: FOLDER_ACCESS.NO_ACCESS,
  createdAt: "00-00-0000 00:00",
  updatedAt: "00-00-0000 00:00",
  favorite: false,
  id: "",
  key: [],
  countKeys: 0,
  level_security: 1,
  root: null,
  title: "",
  type: FOLDER_TYPE.PERSONAL,
  webAccess: false,
  children: [],
};

let folderAccess: { label: string; type: FOLDER_ACCESS }[] = [];
let mapRolesName: { [role in ROLE_TYPE]: string };
let mapRolesNameNoTranslate: { [role in ROLE_TYPE]: string };

/**
 * Function fill needed for translate, when user change language
 */
function fill() {
  folderAccess = [
    {
      label: i18n.t("folder-asses-write"),
      type: FOLDER_ACCESS.WRITE,
    },
    {
      label: i18n.t("folder-asses-read"),
      type: FOLDER_ACCESS.READ,
    },
    {
      label: i18n.t("folder-asses-no_access"),
      type: FOLDER_ACCESS.NO_ACCESS,
    },
  ];

  mapRolesName = {
    [ROLE_TYPE.USER]: i18n.t("user_role-User"),
    [ROLE_TYPE.FREE_USER]: i18n.t("user_role-User"),
    [ROLE_TYPE.STANDARD_USER]: i18n.t("user_role-User"),
    [ROLE_TYPE.MANAGER]: i18n.t("user_role-Manager"),
    [ROLE_TYPE.ADMIN]: i18n.t("user_role-Administrator"),
  };

  mapRolesNameNoTranslate = {
    [ROLE_TYPE.USER]: "user",
    [ROLE_TYPE.FREE_USER]: "freeUser",
    [ROLE_TYPE.STANDARD_USER]: "standard",
    [ROLE_TYPE.MANAGER]: "manager",
    [ROLE_TYPE.ADMIN]: "admin",
  };
}

fill();

i18n.on("languageChanged init", () => fill());

export { folderAccess, mapRolesName, mapRolesNameNoTranslate };
