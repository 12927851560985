import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import { connect } from "react-redux";
import { cnSettings } from "pages/Settings";
import { Checkbox } from "ss-ui";
import { typeAccessControl } from "core";
import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { setGlobalSettings } from "store/actions";

type Props = {
  settings?: typeAccessControl;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const AccessControlManagement: FC<Props> = ({ 
  settings,
  setGlobalSettings,
}: Props) => {

  const { t } = useTranslation();

  const handleChangeValue = (valueSettings: string, value: boolean, valueRole: string) => {
    setGlobalSettings({
      name: "ACCESS_CONTROL_SETTINGS",
      value: valueSettings,
      accessControlValue: { [valueRole]: value },
    });
  }

  return (
    <>
      {settings && (
      <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_best_icon_services">
        <div className={cnSettings("Header")}>{t("settings_header_control_access")}</div>
        <div className={cnSettings("Hint")}>{t("hint_pass_settings_control_access")}</div>
      </div>
      <div className={cnSettings("Table")}>
        <div className={cnSettings("Table-Header")}>
          <div className={cnSettings("Row-Name")} />
          <div className={cnSettings("Row-User")}>
            {t("user_role-User")}
          </div>
          <div className={cnSettings("Row-Manager")}>
            {t("user_role-Manager")}
          </div>
        </div>
        <div className={cnSettings("Table-Body")}>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={cnSettings("Row-Name")}>{t("label_option_operation_keys_import")}</div>
            <div className={cnSettings("Row-User")}>
              <Checkbox
                checked={settings.accessImport.user}
                onChange={() => handleChangeValue(
                  "accessImport",
                  !settings.accessImport.user,
                  "user",
                )}
              />
            </div>
            <div className={cnSettings("Row-Manager")}>
              <Checkbox
                checked={settings.accessImport.manager}
                onChange={() => handleChangeValue(
                  "accessImport",
                  !settings.accessImport.manager,
                  "manager",
                )}
              />
            </div>
          </div>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={cnSettings("Row-Name")}>{t("label_option_operation_keys_export")}</div>
            <div className={cnSettings("Row-User")}>
              <Checkbox
                checked={settings.accessExport.user}
                onChange={() => handleChangeValue(
                  "accessExport",
                  !settings.accessExport.user,
                  "user",
                )}
              />
            </div>
            <div className={cnSettings("Row-Manager")}>
              <Checkbox
                checked={settings.accessExport.manager}
                onChange={() => handleChangeValue(
                  "accessExport",
                  !settings.accessExport.manager,
                  "manager",
                )}
              />
            </div>
          </div>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={cnSettings("Row-Name")}>{t("settings_access_control_management")}</div>
            <div className={cnSettings("Row-User")} />
            <div className={cnSettings("Row-Manager")}>
              <Checkbox
                checked={settings.accessManagement.manager}
                onChange={() => handleChangeValue(
                  "accessManagement",
                  !settings.accessManagement.manager,
                  "manager",
                )}
              />
            </div>
          </div>
          <div className={cnSettings("Table-Body-Row")}>
            <div className={cnSettings("Row-Name")}>{t("activity_log_name")}</div>
            <div className={cnSettings("Row-User")} />
            <div className={cnSettings("Row-Manager")}>
              <Checkbox
                checked={settings.accessActivityLog.manager}
                onChange={() => handleChangeValue(
                  "accessActivityLog",
                  !settings.accessActivityLog.manager,
                  "manager",
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  )
}

const mapStateToProps = ({ settingsTest: { accessControl: { settings } } }: RootState) => ({
  settings,
});

const mapDispatchToProps = { setGlobalSettings };

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlManagement);