import i18n from "locales/i18n";

export type translationType = "en" | "ru" | "ua" | "es" | "it" | "fr" | "eu" | "de";

export interface ILanguages {
  code: string;
  title: string;
}

export interface ITranslation {
  languages: ILanguages[];
}

export const translation: ITranslation = {
  languages: [
    {
      code: "de",
      title: i18n.t("language_de"),
    },
    {
      code: "en",
      title: i18n.t("language_en"),
    },
    // {
    //   code: "ru",
    //   name: i18n.t("language_ru"),
    // },
  ],
};
