/* eslint-disable react-hooks/exhaustive-deps, no-nested-ternary, jsx-a11y/click-events-have-key-events, import/extensions, jsx-a11y/no-static-element-interactions */
import { cn } from "@bem-react/classname";

import { ButtonDiv, FolderStatus } from "components/Generic";

import {
  FOLDER_ACCESS,
  getIDsActiveFolder,
  getKey,
  PRODUCT_TYPE,
  replaceUrlParam,
  ROLE_TYPE,
  Routes,
  typeFolder,
} from "core";
import { get } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { FolderIcon } from "ss-ui";

import { setOpenedNavigationFolder } from "store/actions";
import { RootState } from "store/reducers";

export const cnTree = cn("Tree");

type Props = {
  folder: typeFolder;
  folders: typeFolder[];
  level?: number;
  productType: PRODUCT_TYPE;
  role: ROLE_TYPE;
  setOpenedNavigationFolder: (value: boolean) => void;
};

const Tree: FC<Props> = ({ role, folder, folders, level = 0, productType, setOpenedNavigationFolder }) => {
  const route = useRouteMatch(Routes.Keys);
  const folderID = get(route, "params.folderID", undefined);

  const [isOpen, setOpen] = useState(false);
  const { push } = useHistory();

  const { id, title, children, countKeys, type, access, root, level_security } = folder;

  const activeTreeFolder = getIDsActiveFolder(folders, folderID);

  useEffect(() => {
    activeTreeFolder.pop();
    if (activeTreeFolder.includes(folder.id)) {
      setOpen(true);
    }
  }, [folderID]);

  const handleClickFolder = () => {
    setOpen(!isOpen);
  };

  const handleClickFolderOpen = () => {
    setOpenedNavigationFolder(!isOpen);
    setOpen(!isOpen);
  };

  const handleClickLink = () => {
    setOpen(!isOpen);
    push(replaceUrlParam(Routes.Keys, "folderID", folder.id));
    const layoutElement = document.querySelector(".Layout-Container");
    if (layoutElement) {
      layoutElement.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  const isActive = folderID === folder.id;

  const { t } = useTranslation();

  return (
    <div
      className={ cnTree({ level }) }
      id={ getKey(`menu_tree_folder_item_${title}`, level + 1) }
    >
      <div className={ cnTree("Folder", { isActive }) }>
        {folder.children.length > 0 ? (
          <ButtonDiv
            key={ id }
            className={ cnTree({ isOpen }) }
            id={ getKey(`menu_tree_folder_item_${title}_btn_open`, level + 1) }
            onClick={ root === null ? handleClickFolderOpen : handleClickFolder }
          >
            <FolderStatus isActive={ isOpen } />
          </ButtonDiv>
        ) : (
          <div className="EmptyFolderStatus">&nbsp;</div>
        )}
        <div
          className={ cnTree("Box") }
          id={ getKey(`menu_tree_folder_item_${title}_title_btn_open`, level + 1) }
          onClick={ handleClickLink }
          onDoubleClick={ handleClickFolderOpen }
        >
          <div
            className={ cnTree({ No_access: type === "group" && access === FOLDER_ACCESS.NO_ACCESS }) }
          >
            <FolderIcon
              isGroup={ type === "group" }
              isRoot={ root === null && type !== "group" }
              level={ level_security }
              size={ 30 }
            />
          </div>
          <div
            className={ cnTree("Title") }
            id={ getKey(`menu_tree_folder_item_${title}_title`, level + 1) }
            title={ folder.title }
          >
            {folder.title}
            <div className={ cnTree("Count") }>
              {countKeys}
              {" "}
              {t("navigation_keys")}
            </div>
          </div>
        </div>
      </div>
      {isOpen &&
        children.length > 0 &&
        children.map((folder: typeFolder) => (
          <Tree
            key={ folder.id }
            folder={ folder }
            folders={ folders }
            level={ level + 1 }
            productType={ productType }
            role={ role }
            setOpenedNavigationFolder={ setOpenedNavigationFolder }
          />
        ))}
    </div>
  );
};

const mapStateToProps = ({ folders: { list: folders }, settings: { productType }, user: { role } }: RootState) => ({
  folders,
  productType,
  role,
});

export default connect(mapStateToProps, { setOpenedNavigationFolder })(Tree);
