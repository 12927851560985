import { createRoutine, Routine } from "redux-saga-routines";
import { push } from "connected-react-router";
import { put, takeLatest } from "redux-saga/effects";

import { LOCAL_STORAGE, MainRoutes, removeDataFromLS } from "core";

import { favoritesClear, folderClear, groupsClear, historyClear, keyClear, settingsClear, usersClear } from "store/actions";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("LOGOUT");

function* handler() {
  try {
    yield removeDataFromLS(LOCAL_STORAGE.USER);
    yield removeDataFromLS(LOCAL_STORAGE.SEND_URL);

    yield put(favoritesClear.success());
    yield put(folderClear.success());
    yield put(groupsClear.success());
    yield put(historyClear.success());
    yield put(keyClear.success());
    yield put(settingsClear.success());
    yield put(usersClear.success());

    yield put(action.success());

    yield put(push(MainRoutes.Login));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
