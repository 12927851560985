import { typeKey, typeKeyHistory } from "core/types";
import CryptoJS from "crypto-js";

export class SecurityServices {
  private static instance: SecurityServices;

  private static hashIteration: number = 3200;

  private static keyLength: number = 32;

  private static masterKey: any = null;

  private static iv: string = "1234567812345678";

  private static keySize: number = 256;

  private constructor() {
    if (SecurityServices.instance) {
      throw new Error(
        "Error: Instantiation failed: Use SecurityServices.getInstance(hashIteration: number = 3200, keyLength: number = 32) instead of new.",
      );
    }
    SecurityServices.instance = this;
  }

  public static getInstance(hashIteration: number = 3200, keyLength: number = 32): SecurityServices {
    if (!this.instance) {
      this.instance = new this();

      this.hashIteration = hashIteration;
      this.keyLength = keyLength;
    }

    return this.instance;
  }

  public static cutByLength(string: string): string | boolean {
    if (!string) return false;

    try {
      return this.keyLength > string.length
        ? string.repeat(this.keyLength / string.length + 1).substring(0, this.keyLength)
        : string.substring(0, this.keyLength);
    } catch (error) {
      return false;
    }
  }

  public static createEncryptionKey(string: string) {
    if (!string) return false;

    try {
      const cutString = this.cutByLength(string);

      if (typeof cutString === "boolean") {
        throw new Error("Invalid string");
      }

      this.masterKey = CryptoJS.PBKDF2(cutString, "salt", {
        hasher: CryptoJS.algo.SHA256,
        iterations: this.hashIteration,
        keySize: this.keySize / this.keyLength,
      });

      return this.masterKey;
    } catch (error) {
      return false;
    }
  }

  public static setEncryptionKey(key: string[]) {
    if (!key) return false;

    this.masterKey = key;

    return this.masterKey;
  }

  public static getHash(string: string) {
    if (!string) return false;

    try {
      return CryptoJS.SHA256(string).toString(CryptoJS.enc.Base64);
    } catch (error) {
      return false;
    }
  }

  public static getVerifyString(string: string) {
    if (!string) return false;

    try {
      const key = this.cutByLength(string);

      if (typeof key === "boolean") {
        throw new Error("Invalid string");
      }

      return this.encrypt(this.getHash(key) + key);
    } catch (error) {
      return false;
    }
  }

  public static verifyMasterKey(string: string) {
    if (!string) return false;

    try {
      const decrypt = this.decrypt(string);

      if (typeof decrypt === "boolean") {
        throw new Error("Invalid string");
      }

      const hashLength = decrypt.length - this.keyLength;

      return decrypt.substring(0, hashLength) === this.getHash(decrypt.substring(hashLength));
    } catch (error) {
      return false;
    }
  }

  public static decryptKey(key: typeKey): typeKey {
    return {
      ...key,
      description: key.encryptionEnabled ? (this.decrypt(key.description) as string) : key.description,
      login: key.encryptionEnabled ? (this.decrypt(key.login) as string) : key.login,
      title: key.encryptionEnabled ? (this.decrypt(key.title) as string) : key.title,
      uri: key.encryptionEnabled ? (this.decrypt(key.uri) as string) : key.uri,
    };
  }

  public static decryptHistoryKey(key: typeKeyHistory): typeKeyHistory {
    return {
      ...key,
      data: {
        ...key.data,
        ...(key.encryptionEnabled
          ? {
            description: key.data.description ? (this.decrypt(key.data.description) as string) : key.data.description,
            login: key.data.login ? (this.decrypt(key.data.login) as string) : key.data.login,
            title: key.data.title ? (this.decrypt(key.data.title) as string) : key.data.title,
            uri: key.data.uri ? (this.decrypt(key.data.uri) as string) : key.data.uri,
          }
          : {}),
      },
    };
  }

  public static encryptKey({ description, login, password, title, uri }: any) {
    return {
      description: description ? (this.encrypt(description) as string) : "",
      encryptionEnabled: true,
      login: this.encrypt(login) as string,
      password: this.encrypt(password) as string,
      title: this.encrypt(title) as string,
      uri: this.encrypt(uri) as string,
    };
  }

  public static wrapKeyFormData({ description, encryptionEnabled, login, title, uri, password }: any) {
    const form = new FormData();

    form.append("encryptionEnabled", encryptionEnabled);

    if (description) {
      form.append("description", description);
    }

    form.append("login", login);
    form.append("password", password);
    form.append("title", title);
    form.append("uri", uri);

    return form;
  }

  public static encrypt(string: string) {
    if (!string) return false;

    try {
      return CryptoJS.AES.encrypt(string, this.masterKey, {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
      }).ciphertext.toString(CryptoJS.enc.Base64);
    } catch (error) {
      return false;
    }
  }

  public static decrypt(string: string) {
    if (!string) return false;

    try {
      return CryptoJS.AES.decrypt(
        CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(string),
        }),
        this.masterKey,
        {
          iv: CryptoJS.enc.Utf8.parse(this.iv),
        },
      ).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("error")
      return false;
    }
  }
}
