import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Button, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";

import { Modal, cnModal } from "components/Generic";
import { cnField, FormSwitch } from "components/Form";
import { cnMessages } from "components/Messages";

import { useRegistration } from "core/hook/yubikey";
import { typeYubikeyList, parseDate, DATETIME_FORMAT } from "core";

import { cnSettings } from "pages/Settings/index";

import { Payload as payloadDeleteYubikey } from "store/routines/user/deleteYubikey";
import { Payload as payloadUpdateYubikeyEnable } from "store/routines/user/updateYubikeyEnable";
import { RootState } from "store/reducers";
import { getRegisterYubikey, deleteYubikey, updateYubikeyEnable } from "store/actions";

export const cnYubikey = cn("Yubikey");

type Props = {
  deleteYubikey: (payload: payloadDeleteYubikey) => void;
  email?: string;
  fullName?: string;
  getRegisterYubikey: () => void;
  updateYubikeyEnable: (payload: payloadUpdateYubikeyEnable) => void;
  username?: string;
  yubikeyEnable?: boolean;
  yubikeys: typeYubikeyList[];
};

type State = {
  error: boolean;
  modal: boolean;
  name: string;
};

const Yubikey: FC<Props> = ({
  deleteYubikey,
  email,
  fullName,
  getRegisterYubikey,
  updateYubikeyEnable,
  username,
  yubikeyEnable = false,
  yubikeys,
}) => {
  const { t } = useTranslation();

  const registerYubikey = useRegistration({});

  const [state, setState] = useState<State>({
    error: false,
    modal: false,
    name: "",
  });

  useEffect(() => {
    getRegisterYubikey();
  }, []);

  const handleRegisterYubikey = async () => {
    if (isEmpty(state.name)) {
      setState({ ...state, error: true });
      return;
    }

    await registerYubikey({
      displayName: (fullName || email) as string,
      name: state.name,
      username: (username || fullName || email) as string,
    });

    handleCloseModal();

    toast.success(t("settings_yubikey_added"));

    getRegisterYubikey();
  };

  const handleCloseModal = () => {
    setState({
      error: false,
      modal: false,
      name: "",
    });
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_hunt_password_services">
        <div className={cnSettings("Header")}>
          {t("settings_yubikey_header")}

          <div className={cnSettings("Switch")}>
            <FormSwitch isOn={yubikeyEnable} handleToggle={action => updateYubikeyEnable({ action })} fieldName="iconSwitchYubikey" />
            {yubikeyEnable ? <p>{t("settings_switch_on")}</p> : <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>}
          </div>
        </div>
        <div className={cnSettings("Hint")}>
          <Trans i18nKey="settings_yubikey_hint">
            <a href="https://yubico.com" rel="noreferrer" target="_blank">
              Yubico
            </a>
          </Trans>
        </div>
        {yubikeyEnable && (
          <>
            <ul className={cnYubikey("List")}>
              <li className={cnYubikey("ListHeader")}>
                <div>{t("settings_yubikey_header_keys")}</div>
                <div>{t("settings_yubikey_header_created")}</div>
                <div>{t("settings_yubikey_header_last_used")}</div>
                <div>&nbsp;</div>
              </li>
              {yubikeys.map(yubikey => {
                return (
                  <li key={yubikey.id}>
                    <div>
                      <Icon fill="#1A90BB" name="icon-yubikey" /> {yubikey.name}
                    </div>
                    <div>{moment(parseDate(yubikey.created_at)).format(DATETIME_FORMAT)}</div>
                    <div>
                      {yubikey.last_used_at
                        ? moment(parseDate(yubikey.last_used_at)).format(DATETIME_FORMAT)
                        : t("settings_yubikey_not_used")}
                    </div>
                    <div>
                      <Button icon="icon-trash" type="button" theme="danger-link" onClick={() => deleteYubikey({ id: yubikey.id })} />
                    </div>
                  </li>
                );
              })}
              {yubikeys.length === 0 && <li className="empty">{t("settings_yubikey_empty")}</li>}
            </ul>
            <Button type="button" theme="primary-link" icon="icon-plus" onClick={() => setState({ ...state, modal: true })}>
              {t("settings_yubikey_btn_add_key")}
            </Button>
          </>
        )}
      </div>
      {state.modal && (
        <Modal handleClose={handleCloseModal} className={cnModal("Confirmation")}>
          <div className={cnModal("Header")}>{t("settings_yubikey_modal_header")}</div>
          <div className={cnModal("Scroll")}>
            <p className={cnModal("ConfirmationText")}>{t("settings_yubikey_modal_help_text")}</p>
            <div className={cnField("Input")}>
              <input
                id="yubikey_name"
                onChange={({ target: { value } }) => setState({ ...state, name: value, error: false })}
                placeholder={t("settings_yubikey_placeholder")}
                type="text"
                value={state.name}
              />
              {state.error && (
                <span id="input_yubikey_error_error" className={`${cnMessages()} ${cnMessages("Error")}`}>
                  {t("settings_yubikey_error")}
                </span>
              )}
            </div>
          </div>
          <div className={cnModal("Actions")}>
            <Button id="button_update_icon_confirm" onClick={() => handleRegisterYubikey()}>
              {t("button-Confirm")}
            </Button>
            <Button id="button_update_icon_cancel" theme="primary-outline" onClick={handleCloseModal}>
              {t("button-Cancel")}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { email, fullName, username, yubikeys, yubikeyEnable } }: RootState) => ({
  email,
  fullName,
  username,
  yubikeyEnable,
  yubikeys,
});

const mapDispatchToProps = {
  deleteYubikey,
  getRegisterYubikey,
  updateYubikeyEnable,
};

export default connect(mapStateToProps, mapDispatchToProps)(Yubikey);
