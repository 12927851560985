import { Action } from "redux-actions";

import { templatesGetAll, setDeviceAccessDenied } from "store/actions";
import { typeTemplates } from "core";

export interface State {
  templates: typeTemplates;
  isLoading: boolean;
  deviceAccessDenied: boolean;
  error?: any;
}

const initialState: State = {
  templates: [],
  isLoading: false,
  deviceAccessDenied: false,
  error: undefined,
};

export default function templates(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case templatesGetAll.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case setDeviceAccessDenied.TRIGGER:
      return {
        ...state,
        deviceAccessDenied: false,
      };

    case templatesGetAll.SUCCESS:
      return {
        ...state,
        templates: payload,
      };
    
    case setDeviceAccessDenied.SUCCESS:
      return {
        ...state,
        deviceAccessDenied: false,
      };

    case templatesGetAll.FAILURE:
      return {
        ...state,
        error: payload,
      };
    
    case setDeviceAccessDenied.FAILURE:
      return {
        ...state,
        deviceAccessDenied: true,
      };

    case templatesGetAll.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
