/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { getKey } from "../../../core";

export const cnModal = cn("Modal");

const modalRoot = document.getElementById("modal-root");

type Props = {
  className?: string;
  handleClose?: () => void;
  hasRoute?: boolean;
  isCloseable?: boolean;
  isIndexHigh?: boolean;
  isClosed?: boolean;
};

const Modal: FC<Props> = ({ 
  children, 
  className = null, 
  isIndexHigh = false, 
  handleClose = () => ({}), 
  hasRoute = false, 
  isCloseable = true,
  isClosed = false,
}) => {
  const history = useHistory();

  const el = useRef(document.createElement("div"));

  useEffect(() => {
    if (modalRoot) modalRoot.appendChild(el.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (modalRoot) modalRoot.removeChild(el.current);
    };
  }, []);

  return createPortal(
    <>
      <div
        className={cnModal("Substrate", { closeble: isCloseable, indexHigh: isIndexHigh })}
        onClick={() => (hasRoute ? history.goBack() : isClosed ? null : handleClose())}
        role="button"
        tabIndex={-1}
        onKeyDown={() => null}
        id={getKey(`modal_${className}`)}
      >
        &nbsp;
      </div>
      <div className={cnModal("Body", [className || "Common"])}>
        {isCloseable && (
          <Icon 
            className={cnModal("Close")}
            name="icon-close"
            onClick={() => (hasRoute ? history.goBack() : handleClose())}
            id={getKey(`modal_${className}_btn_close`)}
            width={16}
            height={16}
          />
        )}
        <div className={cnModal("Content")} id={getKey(`modal_${className}_content`)}>
          {children}
        </div>
      </div>
    </>,
    el.current,
  );
};

export default Modal;
