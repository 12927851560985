import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type formValues = {
  uri: string;
  login: string;
};

export type Payload = {
  values: formValues;
  folderID: string;
};

export const action: Routine = createRoutine("KEYS_VALIDATE_BY_URI_LOGIN");

const get = ({ folderID, values }: Payload) => {
  return http({ route: `key-by-uri-login/${folderID}`, method: "POST", payload: values });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
