import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

import { get as lodashGet } from "lodash";

export type Payload = {
  status: boolean;
};

export const action: Routine = createRoutine("SET_AUTO_BACKUP_STATUS");

const setAutoBackupStatus = ({ status }: Payload) => {
  return http({
    route: "user/settings",
    method: "POST",
    payload: {
      autoBackupEnabled: status,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setAutoBackupStatus, payload);
    yield put(action.success(response));
    if (payload.status) {
      toast.success(i18n.t("toast_bestIcon_success"));
    } else {
      toast.success(i18n.t("toast_bestIcon_error"));
    }
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
