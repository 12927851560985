import { typeHistoryList, typeStatistic } from "core";
import { Action } from "redux-actions";

import { historyClear, historyGet, historyRemove, historyRestoreFolder, historyRestoreKey, historyStatistic } from "store/actions";

export interface State {
  error?: string;
  isLoading: boolean;
  isLoadingStatistic: boolean,
  status: boolean;
  list: typeHistoryList;
  listPagesCount: number;
  listCurrentPage: number;
  listStatistic: typeStatistic;
  restoreSuccess: boolean;
}

const initialState: State = {
  error: undefined,
  isLoading: false,
  isLoadingStatistic: false,
  status: false,
  list: {},
  listPagesCount: 1,
  listCurrentPage: 1,
  listStatistic: {
    foldersCreated: 0,
    foldersDeleted: 0,
    foldersRestored: 0,
    foldersUpdated: 0,
    keysCreated: 0,
    keysDeleted: 0,
    keysRestored: 0,
    keysUpdated: 0,
  },
  restoreSuccess: false,
};

export default function histories(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case historyGet.TRIGGER:
    case historyRemove.TRIGGER:
    case historyRestoreFolder.TRIGGER:
    case historyRestoreKey.TRIGGER:
      return {
        ...state,
        restoreSuccess: false,
        isLoading: true,
      };

    case historyStatistic.TRIGGER:
      return {
        ...state,
        isLoadingStatistic: true,
      };

    case historyClear.SUCCESS:
      return {
        ...initialState,
      };

    case historyGet.SUCCESS:
      return {
        ...state,
        list: payload.results,
        listPagesCount: payload.pagesCount,
        listCurrentPage: payload.currentPage,
        isLoading: false,
      };

    case historyStatistic.SUCCESS:
      return {
        ...state,
        listStatistic: payload,
        isLoadingStatistic: false,
      };

    case historyRemove.SUCCESS: {
      const copyList: typeHistoryList = { ...state.list };

      delete copyList[payload];

      return {
        ...state,
        list: copyList,
        isLoading: false,
      };
    }

    case historyRestoreFolder.SUCCESS: {
      const copyList: typeHistoryList = { ...state.list };

      if (copyList[payload].folder !== undefined) {
        copyList[payload].folder!.restore = false;
      }

      return {
        ...state,
        list: copyList,
        restoreSuccess: true,
        isLoading: false,
      };
    }

    case historyRestoreKey.SUCCESS: {
      const copyList: typeHistoryList = { ...state.list };

      if (copyList[payload].key !== undefined) {
        copyList[payload].key!.restore = false;
      }

      return {
        ...state,
        list: copyList,
        restoreSuccess: true,
        isLoading: false,
      };
    }

    case historyGet.FAILURE:
    case historyRemove.FAILURE:
    case historyRestoreFolder.FAILURE:
    case historyRestoreKey.FAILURE:
    case historyStatistic.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        restoreSuccess: false,
        isLoadingStatistic: false,
      };

    default:
      return state;
  }
}
