import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnInfoBox } from "components/InfoBox";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const InfoBoxContent: FC<IProps> = ({ children, className = "", ...props }) => {
  return (
    <div className={cnInfoBox("Content")} {...props}>
      {children}
    </div>
  );
};

export default InfoBoxContent;