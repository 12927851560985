import React, { FC, useMemo, useState } from "react";
import { cnModal, LoadingContainer, Modal } from "components/Generic";
import { cn } from "@bem-react/classname";
import { findFolderInTree, FOLDER_TYPE, removeFolderChild, ROLE_TYPE, typeFolder, typeSelectedFolder } from "core";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Checkbox, Icon } from "ss-ui";
import { RootState } from "store/reducers";
import { Tree } from "./fragments/Tree";

const cnSettings = cn("Settings");

export type Props = {
  modalTitle?: string;
  activeFolder?: string | null;
  isLoading: boolean;
  folders: typeFolder[];
  type?: FOLDER_TYPE;
  role?: ROLE_TYPE;
  folderID?: string;
  checkboxRootFolder?: boolean;
  isSharing?: boolean | null;
  folderList?: string[];
  className?: string;
  onChanges: (value: string | null) => void;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  // Export key functions
  isMulti?: boolean;
  subSolderCheckboxValue?: boolean;
  changeSubFoldersCheckbox?: (value: boolean) => void;
  accessArr: string[];
  isIndexHigh?: boolean;
  importInRoot?: boolean;
  isAdmin?: boolean;
  importOnChange?: (value: boolean) => void;
  importInRootValue?: boolean;
  filterFolders?: boolean;
  shareFolder?: string;
};

const ModalFolderSelect: FC<Props> = ({
  modalTitle,
  activeFolder,
  isLoading,
  folders,
  type,
  role,
  folderID,
  checkboxRootFolder = false,
  isSharing,
  folderList = [],
  isAdmin = false,
  onChanges,
  handleClose,
  // Export key functions
  isMulti = false,
  subSolderCheckboxValue = true,
  changeSubFoldersCheckbox,
  accessArr,
  className = "",
  isIndexHigh = false,
  importInRoot = false,
  importOnChange,
  importInRootValue = false,
  filterFolders = false,
  shareFolder,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [checkboxSubFolders, setCheckboxSubFolders] = useState<boolean>(subSolderCheckboxValue);
  const [activeFolders, setActiveFolders] = useState<string | null>(String(activeFolder) || "");
  const isStandardUser: boolean = useMemo(() => role === ROLE_TYPE.STANDARD_USER, [role]);
  const [selectedFolder, setSelectedFolder] = useState<typeSelectedFolder[]>(
    folderList.map(id => {
      const folder = findFolderInTree(folders, id) as typeFolder;
      return { value: id, root: folder?.root || undefined };
    }),
  );
  const [isRootFolder, setRootFolder] = useState<boolean>(false);

  const resetParent = () => {
    handleClose(false);
  };

  const onChange = (data: { value: string; label: string }) => {
    if (isMulti) {
      if (selectedFolder.filter(item => item.value === data.value).length > 0) {
        setSelectedFolder(currentFolder => currentFolder.filter(item => item.value !== data.value));
      } else {
        setSelectedFolder(currentFolder => [...currentFolder, data]);
      }
    } else {
      setActiveFolders(data.value);
    }
  };

  const handleSave = () => {
    if (isMulti) {
      if (selectedFolder.length !== 0) {
        if (checkboxSubFolders) {
          onChanges(removeFolderChild(selectedFolder).join(","));
        } else {
          onChanges(selectedFolder.map(item => item.value).join(","));
        }
      }
    } else {
      onChanges(activeFolders);
    }
    handleClose(false);
  };

  const handleRootFolder = () => {
    setRootFolder(!isRootFolder);
    onChanges(null);
    if(!isRootFolder) {
      setActiveFolders(null);
    } else {
      setActiveFolders("undefined");
    }
  };

  const handleImportRootFolder = () => {
    setRootFolder(!importInRootValue);
    if (importOnChange) {
      importOnChange(!importInRootValue);
    }
  };

  const onChangeSubFolders = () => {
    setCheckboxSubFolders(!checkboxSubFolders);
    if (changeSubFoldersCheckbox) {
      changeSubFoldersCheckbox(!checkboxSubFolders);
    }
  };

  return (
    <Modal handleClose={() => handleClose(false)} isIndexHigh={isIndexHigh} className={`${cnModal("ModalFolderSelect")} ${className}`}>
      <LoadingContainer isLoading={isLoading}>
        <div className={cnModal("Header")}>{!modalTitle ? t("modal-move") : modalTitle}</div>
        <div className={cnModal("Scroll")}>
          {checkboxRootFolder &&
            (!isStandardUser && (type === FOLDER_TYPE.GROUP || type === FOLDER_TYPE.UNDEFINED) ? (
              <div className={cnModal("ModalFolderSelect-Checkbox")}>
                <Checkbox checked={isRootFolder} onChange={handleRootFolder} />
                <span>{t("modal-root-folder")}</span>
              </div>
            ) : null)}

          {importInRoot && isAdmin && !isStandardUser ? (
              <div className={cnModal("ModalFolderSelect-Checkbox")}>
                <Checkbox checked={importInRootValue} onChange={handleImportRootFolder} />
                <span>{t("modal-root-folder-import")}</span>
              </div>
            ) : null}

          <div className={cnModal("Search")}>
            <div className={cnModal("Search-Icon")}>
              <Icon name="icon-search" fill="#727272" width={16} height={16} />
            </div>
            <input type="text" id="modal_search" value={search} onChange={e => setSearch(e.target.value)} />
          </div>

          {isMulti && !filterFolders && (
            <>
              <p className={cnModal("Info")}>{t("settings_modal_export_list")}</p>
              <div className={cnSettings("Checked-SubFolder")}>
                <Checkbox checked={checkboxSubFolders} onChange={onChangeSubFolders} />
                <p>{t("settings_export_include_sub_folders")}</p>
              </div>
            </>
          )}

          {isSharing && <p className={cnModal("Info")}>{t("modal-info-text")}</p>}

          <p className={cnModal("ConfirmationText")}>{t("modal-select")}</p>

            <div className={cnModal("Folders")}>
            {folders.map((folder: typeFolder) => {
              if (isSharing && folder.type === FOLDER_TYPE.PERSONAL) {
                return null;
              }

              return (folder.type === type || type === FOLDER_TYPE.UNDEFINED) && !isRootFolder && !importInRootValue ? (
                <Tree
                  activeFolder={activeFolders}
                  folder={folder}
                  folderID={folderID}
                  folders={folders}
                  key={folder.id}
                  onChange={onChange}
                  search={search}
                  folderList={selectedFolder.length === 0 ? [] : folderList}
                  selectedFolders={selectedFolder}
                  isMulti={isMulti}
                  accessArr={accessArr}
                  shareFolder={shareFolder}
                />
              ) : null;
            })}
          </div>

        </div>

        <div className={cnModal("Actions")}>
          <Button 
            id="button_folder_select_save" 
            onClick={() => handleSave()} 
            type="button" 
            disabled={activeFolders === "undefined" && !isMulti}>
            {t("button-Save")}
          </Button>
          <Button id="button_folder_select_cancel" onClick={() => resetParent()} theme="secondary-outline">
            {t("button-Cancel")}
          </Button>
        </div>
      </LoadingContainer>
    </Modal>
  );
};

const mapStateToProps = ({ folders: { list, isLoading }, user: { role } }: RootState) => ({
  role,
  folders: list,
  isLoading,
});

export default connect(mapStateToProps)(ModalFolderSelect);
