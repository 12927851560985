import React, { FC, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";

import { FormInput } from "components/Form";

import { FIELD_TYPE, Validation, getFieldValue } from "core";

import { FieldContext } from "./Types";

type Props = {};

const fieldType = FIELD_TYPE.EMAIL;

const FieldEmail: FC<Props> = () => {
  const { t } = useTranslation();
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <div className="FieldEmail">
      <Field
        component={FormInput}
        label={t(`manage_key_field_${fieldType}_label`)}
        name={fieldType}
        className="Required"
        placeholder={t(`manage_key_field_${fieldType}_placeholder`)}
        validate={[Validation.required, Validation.email]}
      />
    </div>
  );
};

export default FieldEmail;
