import React, { FC, useState, ChangeEvent, useContext, useEffect } from "react";
import { Icon } from "ss-ui";
import { getSizeImage, FIELD_TYPE } from "core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { ImageCropper, ButtonDiv } from "components/Generic";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { FieldContext } from "./Types";

type Props = {};

const fieldType = FIELD_TYPE.LOGO;

const FieldLogo: FC<Props> = () => {
  const { t } = useTranslation();

  const context = useContext(FieldContext);

  const [croppedImage, setCroppedImage] = useState<any | null>("");
  const [imageSrc, setImageSrc] = useState<any | null>("");
  const [imageType, setImageType] = useState<string>("image/png");

  const [errors, setErrors] = useState({
    maxSize: false,
    type: false,
  });

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else if (context.key?.[fieldType]) {
      handleChange(context.key?.[fieldType]);
    }
  }, [context.key]);

  const handleRemove = () => {
    context.handleChange(fieldType, "");
    setImageSrc("");
    setCroppedImage("");
  };

  const handleUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize: number = 5;
    const reader = new FileReader();

    if (files && files[0]) {
      if (files[0].size / 1000000 > maxFileSize) {
        toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
        setErrors(e => ({ ...e, maxSize: true }));
      } else if (!["image/jpeg", "image/png", "image/jpg"].includes(files[0].type)) {
        toast.error(t("validate_avatar_format"));
        setErrors(e => ({ ...e, type: true }));
      } else {
        setErrors(e => ({ ...e, maxSize: false }));
        setErrors(e => ({ ...e, type: false }));

        setImageType(files[0].type);
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          const imgUrl = reader.result;
          setImageSrc(imgUrl);
        };
      }
    }
  };

  const handleChange = (value: string) => {
    if (getSizeImage(value) > 500) {
      toast.error(t("validate_avatar_size_limit_kb", { size: "500" }));
      setErrors(f => ({ ...f, maxSize: true }));
      context.handleChange(fieldType, null);
      setImageSrc(null);
      setCroppedImage(null);
    } else {
      context.handleChange(fieldType, value);
      setCroppedImage(value);
    }
  };

  return (
    <div className={cnManageKey("Logo")}>
      <div className="Field">
        <span className="label">{t("form_input-Logo")}</span>
      </div>
      {croppedImage && (
        <div className={cnManageKey("Logo-Image")}>
          <img alt="" src={croppedImage} />
        </div>
      )}
      {croppedImage ? (
        <ButtonDiv id="btn_remove_icon" className={cnManageKey("Upload Remove-Logo")} onClick={handleRemove}>
          <Icon name="icon-trash" width={16} height={16} />
          <div className="text">{t("tooltip-delete")}</div>
        </ButtonDiv>
      ) : (
        <>
          <label className={cnManageKey("Upload")}>
            <Icon name="icon-upload" width={16} height={16} />
            <div className="text">{t("button-Attach")}</div>
            <input onChange={handleUpload} type="file" name={fieldType} id="input_file_key_avatar" accept="image/*" />
          </label>
        </>
      )}

      {errors.maxSize && <div className="error">{t("validate_avatar_size_limit_kb", { size: 500 })}</div>}
      {errors.type && <div className="error">{t("validate_avatar_format")}</div>}

      {imageSrc && (
        <div className={cnManageKey("Crop")}>
          <ImageCropper
            aspect={1 / 1}
            cropArea={false}
            cropHeight={150}
            cropWidth={150}
            handleClose={() => setImageSrc(null)}
            image={imageSrc}
            onChangeImage={handleChange}
            setImageValue={setImageSrc}
            type={imageType}
          />
        </div>
      )}
    </div>
  );
};

export default FieldLogo;
