import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = {
  folderID: string;
  page?: number;
  search?: string;
  pageSize?: number;
};

export const action: Routine = createRoutine("KEYS_GET_PAGINATION");

const getKeysPagination = ({
  folderID,
  page = 1,
  search = "",
  pageSize = 50,
}: Payload) => {
  return http({
    route: `folder/${folderID}/key-list/all?page=${page}&pageSize=${pageSize}&search=${search}`
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    if (!payload.folderID) {
      throw new Error("Folder identification is empty");
    }

    const response = yield call(getKeysPagination, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
