import React, { FC } from "react";
import { Icon, Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { Trans } from "react-i18next";
import { Modal, Timer } from "components/Generic";

export const cnExpireWindow = cn("ExpireWindow");

type Props = {
  leftTime: number;
  theme: string;
  contentText: string;
  contentButton: string;
  setLeftTime: (value: number) => void;
  handleRedirect: () => void;
};

const ExpireWindow: FC<Props> = ({ 
  contentText,
  contentButton,
  leftTime,
  theme,
  setLeftTime,
  handleRedirect,
}) => {

  return (
    <Modal isCloseable={false}>
      <div className={cnExpireWindow()} id="modal_expire_window">
        <div className={cnExpireWindow(theme)}>
          {theme === "Attention" ? <span>!</span> : 
            <Icon name="icon-checked" fill="#49B109" width={35} height={35} />
          }
        </div>
        <div className={cnExpireWindow("Text")}>
          {contentText}
        </div>
        <p>
          <Trans i18nKey="expire-window-text">
            <Timer seconds={leftTime} setSeconds={setLeftTime} />
          </Trans>
        </p>
        <Button id="button_expire_window" onClick={() => handleRedirect()} theme="danger" width="100%">
          {contentButton}
        </Button>
        </div>
    </Modal>
  );
};

export default ExpireWindow;
