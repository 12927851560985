import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

import { logout } from "store/actions/user";

export const action: Routine = createRoutine("MANAGER_CLOSE_SESSIONS");

const closeUsersSessions = () => {
  return http({ route: "manager/close-sessions" });
};

function* handler() {
  try {
    const response = yield call(closeUsersSessions);

    yield put(action.success(response));

    yield put(logout());

    toast.success(i18n.t("toast_sessions_closed_success"), { toastId: "SESSIONS_CLOSED_SUCCESS" });
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
