import React, { ChangeEvent, FC, useState } from "react";
import { WrappedFieldProps } from "redux-form";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";
import { Button } from "ss-ui";
import { ROLE_TYPE, getPortalLink } from "core";
import { connect } from "react-redux";
import { RootState } from "store/reducers";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  change: (field: string, value: any) => void;
  className?: string;
  label?: string;
  labelOnly?: boolean;
  role: ROLE_TYPE;
} & WrappedFieldProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormFile: FC<Props> = ({ change, className = "", input, label, meta: { touched, error }, role, labelOnly = false, ...rest }) => {
  const { t } = useTranslation();
  const isError = touched && Boolean(error);
  const [fileName, setFileName] = useState<string>();
  const [errorMaxSize, setErrorMaxSize] = useState<boolean>(false);

  const handleUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {

    if (files && files[0]) {
      switch (role) {
        case ROLE_TYPE.FREE_USER:
          if (files[0].size / 1000000 > 1) {
            toast.info(
              <Trans i18nKey="validate_file_size_limit_free" size={1}>
                <a target="_blank" href={`${getPortalLink(window.location.host)}/login`}>
                  Upgrade
                </a>
              </Trans>
            )
            setErrorMaxSize(true);
          } else {
            setFileName(files[0].name);
            change(input.name, files[0]);
            setErrorMaxSize(false);
          }
          break;
        case ROLE_TYPE.STANDARD_USER:
          if (files[0].size / 1000000 > 3) {
            toast.error(t("validate_file_size_limit_standard", { size: 3 }));
            setErrorMaxSize(true);
          } else {
            setFileName(files[0].name);
            change(input.name, files[0]);
            setErrorMaxSize(false);
          }
          break;
        case ROLE_TYPE.USER:
        case ROLE_TYPE.ADMIN:
        case ROLE_TYPE.MANAGER:
          if (files[0].size / 1000000 > 5) {
            toast.error(t("validate_file_size_limit_business", { size: 5 }));
            setErrorMaxSize(true);
          } else {
            setFileName(files[0].name);
            change(input.name, files[0]);
            setErrorMaxSize(false);
          }
          break; 
      }
    }
  };

  const getErrorFileSize = () => {
    if (role === ROLE_TYPE.FREE_USER) {
      return  <Trans i18nKey="validate_file_size_limit_free" size={1}>
                <a target="_blank" href={`${getPortalLink(window.location.host)}/login`}>
                  Upgrade
                </a>
              </Trans>
    }
    if(role === ROLE_TYPE.STANDARD_USER) {
      return t("validate_file_size_limit_standard", { size: 3 });
    } 
    return t("validate_file_size_limit_business", { size: 5 });
  }

  const handleRemoveFile = () => {
    setFileName(label);
    change("file", "0");
  };

  return (
    <>
      <div className={`${cnField()} ${cnField("File", { hasError: isError })} ${className}`} id={`input_file_${input.name}_container`}>
        <div className={cnField("FileUploadField")}>
          {labelOnly ? label : (fileName || label) && <span className={cnField("FileUploadButton")}>{fileName || label}</span>}
          <input type="file" onChange={e => handleUpload(e)} id={`input_file_${input.name}`} {...rest} />
        </div>
        {!labelOnly && fileName && fileName !== label && (
          <Button id="button_remove_file" theme="danger-link" icon="icon-trash" type="button" onClick={() => handleRemoveFile()} />
        )}
      </div>
      {errorMaxSize && (
        <div className="error-notMargin">
          {getErrorFileSize()}
        </div>
      )}
      {!labelOnly && isError && (
        <span id={`input_file_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </>
  );
};

const mapStateToProps = ({ user: { role } }: RootState) => ({
  role,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormFile);
