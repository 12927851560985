import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";
import moment from "moment";

import { http, IHistoryFilter } from "core";

export const action: Routine = createRoutine("HISTORIES_GET_LIST");

export type Payload = {
  filters?: IHistoryFilter;
};

const getAll = ({ filters }: Payload) => {
  const params: any = {
    page: filters?.activePage || 1,
  };

  if (filters) {
    params.from_date = moment(filters.period.start).format("YYYY-MM-DD");
    params.to_date = moment(filters.period.end).format("YYYY-MM-DD");

    if (filters.sealing !== "") {
      params.sealing = filters.sealing;
    }

    if (filters.operations.length > 0) {
      params.action = filters.operations;
    }
  }

  return http({
    method: "GET",
    route: "history",
    payload: { params },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(getAll, payload);
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
