import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnInfoBox } from "components/InfoBox";
import { Icon } from "ss-ui";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
  iconName?: string;
  isChildren?: boolean;
  handleClose?: () => void;
}

const InfoBoxHeader: FC<IProps> = ({ 
  children, 
  className = "",
  title,
  iconName,
  isChildren,
  handleClose,
  ...props 
}) => {
  return (
    <div className={cnInfoBox("Header")} {...props}>
      {isChildren ? (
        children
      ) : (
        <Icon name={iconName} width={20}/>
      )}
      <p title={title} className={cnInfoBox("Header-Title")}>{title}</p>
      <Icon 
        className={cnInfoBox("Close")}
        name="icon-close"
        onClick={handleClose}
        id="btn_close_info_box_key"
      />
    </div>
  );
};

export default InfoBoxHeader;