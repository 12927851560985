/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Checkbox } from "ss-ui";
import { WrappedFieldProps } from "redux-form";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

type Props = {
  className?: string;
  disabled?: boolean;
  help?: string;
  label: string;
  labelSide?: "left" | "right";
  theme?: "" | "switch" | "squre";
} & WrappedFieldProps;

const FormCheckbox: React.FC<Props> = ({ className = "", help, input, label, labelSide = "left", meta: { touched, error }, ...rest }) => {
  return (
    <div className={`${cnField()} ${cnField("Checkbox")} ${className}`} id={`input_checkbox_${rest.theme}_${input.name}_container`}>
      {label && labelSide === "left" && (
        <span className="Label" id={`input_checkbox_${rest.theme}_${input.name}_label`}>
          {label}
          {help && (
            <span className="help" id={`input_checkbox_${rest.theme}_${input.name}_label_help`}>
              {help}
            </span>
          )}
        </span>
      )}
      <Checkbox {...input} {...rest} checked={input.value} />
      {label && labelSide === "right" && (
        <span className="Label" id={`input_checkbox_${rest.theme}_${input.name}_label_right`}>
          {label}
          {help && (
            <span id={`input_checkbox_${rest.theme}_${input.name}_label_right_help`} className="help">
              {help}
            </span>
          )}
        </span>
      )}
      {touched && Boolean(error) && (
        <span id={`input_checkbox_${rest.theme}_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default FormCheckbox;
