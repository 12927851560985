import React, { FC } from "react";
import { Icon, Button } from "ss-ui";
import { connect } from "react-redux";
import { has, isEmpty, get } from "lodash";
import { useTranslation } from "react-i18next";

import { typeKey, FIELD_TYPE } from "core";

import { Payload as PayloadKeyAttached } from "store/routines/keys/attached";
import { keyAttached } from "store/actions";

import { cnSettings } from "../Settings";

type Props = {
  field: FIELD_TYPE;
  item: typeKey;
  keyAttached: (payload: PayloadKeyAttached) => void;
};

const RowFile: FC<Props> = ({ item, field, keyAttached }) => {
  const { t } = useTranslation();

  if (!has(item, field) || isEmpty(get(item, field))) {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Attached")}>
        <div className={cnSettings("Attached-Value")}>
          <Icon name="icon-file" width={16} height={16} fill="#000" />
          <span title={get(item, field)}>{get(item, field)}</span>
        </div>
        <div className={cnSettings("Attached-Actions")}>
          <Button
            icon="icon-download"
            onClick={() =>
              keyAttached({
                id: item.id,
                fileName: get(item, field),
                property: field
              })
            }
            theme="primary-link"
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = { keyAttached };

export default connect(null, mapDispatchToProps)(RowFile);
