import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http, clearUrlParam, ProtectedRoutes, } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { folderGetAll } from "store/actions";

import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";
import { push } from "connected-react-router";


export type Payload = { id: string };

export const action: Routine = createRoutine("HISTORIES_REMOVE");

const remove = ({ id }: Payload) => {
  return http({ method: "DELETE", route: `history/${id}` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(remove, payload);

    toast.success(i18n.t("toast_history_remove"));

    yield put(action.success(payload.id));
    yield put(folderGetAll.trigger());
    yield put(push(clearUrlParam(ProtectedRoutes.Keys)));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
