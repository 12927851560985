import { Action } from "redux-actions";
import { pick } from "lodash";

import {
  usersChose,
  usersClear,
  usersControlBlock,
  usersControlOTP,
  usersDelete,
  usersDeleteMulti,
  usersGet,
  usersGetAll,
  usersGetAllLDAP,
  usersGetAvatar,
  usersManagement,
  usersManagementLocal,
  usersSync,
  usersUploadAvatar,
  deferredUsers,
} from "store/actions";
import { ACTION, typeUser, usersData } from "core";

export interface State {
  LDAPlist: string[];
  chosen: string[];
  currentPage: number;
  error?: string | string[];
  isLoading: boolean;
  item?: typeUser;
  list: typeUser[];
  pageSize: number;
  pagesCount: number;
  skipToken?: string;
  totalItems: number;
  usersCounter: boolean;
}

const initialState: State = {
  LDAPlist: [],
  chosen: [],
  currentPage: 0,
  error: undefined,
  isLoading: false,
  item: undefined,
  list: [],
  pageSize: 0,
  pagesCount: 0,
  skipToken: undefined,
  totalItems: 0,
  usersCounter: false,
};

export default function users(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case usersControlBlock.TRIGGER:
    case usersControlOTP.TRIGGER:
    case usersDelete.TRIGGER:
    case usersDeleteMulti.TRIGGER:
    case usersGet.TRIGGER:
    case usersGetAll.TRIGGER:
    case usersGetAllLDAP.TRIGGER:
    case usersGetAvatar.TRIGGER:
    case usersManagement.TRIGGER:
    case usersManagementLocal.TRIGGER:
    case usersSync.TRIGGER:
    case usersUploadAvatar.TRIGGER:
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };
    case deferredUsers.TRIGGER:
      return {
        ...state,
        usersCounter: false,
        isLoading: true,
      };
    
    case deferredUsers.SUCCESS:
      return {
        ...state,
        usersCounter: true,
        isLoading: true,
      };

    case usersClear.SUCCESS:
      return {
        ...initialState,
      };

    case usersChose.SUCCESS: {
      let list: string[] = state.chosen;

      if (payload.oneOnly && payload.status) {
        return {
          ...state,
          chosen: [payload.id],
        };
      }

      if (!payload.isNew && state.chosen.length === 1 && state.chosen[0] === "new") {
        list.length = 0;
      }

      if (payload.status) {
        list = payload.choseAll ? [...payload.ids] : [...list, payload.id];
      } else {
        list = payload.choseAll ? [] : [...list.filter(id => payload.id !== id)];
      }

      return {
        ...state,
        chosen: payload.status && payload.isNew ? ["new"] : [...list],
      };
    }

    case usersDelete.SUCCESS:
      return {
        ...state,
        chosen: [],
        isLoading: false,
        list: state.list.filter(({ id }: typeUser) => id !== payload),
      };

    case usersDeleteMulti.SUCCESS:
      return {
        ...state,
        chosen: [],
        isLoading: false,
        list: state.list.filter(({ id }: typeUser) => !payload.includes(id)),
      };

    case usersGet.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case usersControlBlock.SUCCESS: {
      return {
        ...state,
        list: state.list.map(user =>
          user.id === payload.id
            ? {
                ...user,
                blocked: !payload.enabled,
                enabled: payload.enabled,
                status: payload.status,
              }
            : user,
        ),
        isLoading: false,
      };
    }

    case usersControlOTP.SUCCESS: {
      return {
        ...state,
        list: state.list.map(user =>
          user.id === payload.id
            ? {
                ...user,
                otp: payload.action === "reset" ? user.otp : payload.action === "enable",
              }
            : user,
        ),
        isLoading: false,
      };
    }

    case usersGetAll.SUCCESS:
      return {
        ...state,
        ...pick(payload, ["currentPage", "pageSize", "pagesCount", "totalItems"]),
        list: usersData(payload.results),
        isLoading: false,
      };

    case usersGetAllLDAP.SUCCESS:
      return {
        ...state,
        skipToken: payload.skip_token,
        LDAPlist: [...state.LDAPlist, ...payload.users],
        isLoading: false,
      };

    case usersManagementLocal.SUCCESS:
    case usersManagement.SUCCESS: {
      let list: typeUser[] = [];

      if (payload.action === ACTION.CREATE) {
        list = [{ ...payload.response, roles: payload.response.roles[0] }, ...state.list];
      } else {
        list = [
          ...state.list.map((user: typeUser) =>
            user.id === payload.response.id ? { ...user, ...payload.response, roles: payload.response.roles[0] } : user,
          ),
        ];
      }

      return {
        ...state,
        list: [...list],
        chosen: payload.action === ACTION.CREATE ? [payload.response.id] : [...state.chosen],
        isLoading: false,
      };
    }

    case usersSync.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case usersGetAvatar.SUCCESS:
    case usersUploadAvatar.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: [...state.list.map(user => (user.id === payload.id ? { ...user, ...payload } : user))],
      };
    
    case deferredUsers.FAILURE:
      return {
        ...state,
        usersCounter: false,
        isLoading: false,
      };

    case usersChose.FAILURE:
    case usersControlBlock.FAILURE:
    case usersControlOTP.FAILURE:
    case usersDelete.FAILURE:
    case usersDeleteMulti.FAILURE:
    case usersGet.FAILURE:
    case usersGetAll.FAILURE:
    case usersGetAllLDAP.FAILURE:
    case usersGetAvatar.FAILURE:
    case usersManagement.FAILURE:
    case usersManagementLocal.FAILURE:
    case usersSync.FAILURE:
    case usersUploadAvatar.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
