/* eslint-disable global-require */
import React, { FC, useState } from "react";
import { cn } from "@bem-react/classname";

import { isEnvDevelop, LOGIN_TYPE } from "core";

import LayoutAnonymous from "components/Layout/LayoutAnonymous/LayoutAnonymous";

import { LoginForm } from ".";

export const cnLogin = cn("Login");

const initialValues = {
  username: isEnvDevelop() ? process.env.REACT_APP_USERNAME || "" : "",
  password: isEnvDevelop() ? process.env.REACT_APP_PASSWORD || "" : "",
};

const Login: FC = () => {
  const [loginType, setLoginType] = useState<LOGIN_TYPE>(LOGIN_TYPE.SIMPLE);

  return (
    <LayoutAnonymous>
      {loginType === LOGIN_TYPE.SIMPLE && <LoginForm initialValues={initialValues} setLoginType={setLoginType} />}
    </LayoutAnonymous>
  );
};

export default Login;
