/* eslint-disable no-nested-ternary */
import React, { FC, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { has, isEmpty, get } from "lodash";
import { Icon } from "ss-ui";

import { typeKey, FIELD_TYPE, copyTypeToClipboard, apiCallSecurityPassword } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
};

const RowSecurity: FC<Props> = ({ item, field }) => {
  const { t } = useTranslation();

  const [isVisible, setVisible] = useState<boolean>(false);
  const [security, setSecurity] = useState<string>();

  /**
   * Check if value is empty
   */
  const empty: boolean = useMemo(() => !has(item, field) || isEmpty(get(item, field)), [item, field]);

  /**
   * Decrypt security value
   */
  useEffect(() => {
    if (!empty) {
      apiCallSecurityPassword({
        password: item[field],
      }).then(value => {
        setSecurity(value);
      });
    }
  }, [item, field])

  /**
   * if empty, don't show
   */
  if (empty) {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={`${cnSettings("Item")} ${cnSettings("Item-Password")}`}>
        <div className={`${cnSettings("Value")} ${cnSettings("Value-Password")}`} id={`info_box_key_${field}`}>
          <span>{isVisible ? security : field === FIELD_TYPE.PASSWORD ? "••••••••••" : "••••"}</span>
        </div>
        <div className={cnSettings("Actions InfoBoxKeySettings-Actions-Right")}>
          <button
            type="button"
            className={`${cnSettings("ShowPassword tooltip tooltip-left")} ${!isVisible ? "inputShow" : "inputClose"}`}
            data-tooltip={isVisible ? t("tooltip-pass-hide") : t("tooltip-pass-show")}
            onClick={() => setVisible(!isVisible)}
            id={`btn_show_${field}`}
          >
            <Icon name="icon-eye" width={20} height={14} />
          </button>
          &nbsp;
          <div className="tooltip tooltip-left" data-tooltip={t("tooltip-copy")}>
            <Icon
            onClick={() => copyTypeToClipboard(security as string, field)}
            name={field === FIELD_TYPE.PASSWORD ? "icon-copy-pass" : "icon-copy"}
            id={`btn_copy_to_clipboard_key_${field}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowSecurity;
