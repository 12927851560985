/* eslint-disable react-hooks/exhaustive-deps */

import moment from "moment";
import React, { FC, useEffect, useState, useMemo } from "react";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { Button, Icon } from "ss-ui";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { Aside, cnAsideContent, Content, ContentWrapper, ContentHeader, cnContentHeader } from "components/Layout";
import { Header } from "components/Navigation";
import { LoadingContainer, Pagination } from "components/Generic";

import {
  DATETIME_FORMAT,
  HistoryNavigation,
  IHistoryFilter,
  Routes,
  SecurityServices,
  getKey,
  parseDate,
  replaceUrlParam,
  typeHistory,
  typeHistoryList,
  typeStatistic,
  ROLE_TYPE,
  typeAccessControl,
  getAccessControl
} from "core";

import { RootState } from "store/reducers";
import { Payload as PayloadHistoryRemove } from "store/routines/histories/remove";
import { Payload as PayloadHistoryRestoreFolder } from "store/routines/histories/restoreFolder";
import { Payload as PayloadHistoryRestoreKey } from "store/routines/histories/restoreKey";
import { Payload as PayloadHistoryGet } from "store/routines/histories/get";
import {
  historyGet,
  historyRemove,
  historyRestoreFolder,
  historyRestoreKey,
  historyStatistic,
  setHeaderIcon,
  setHeaderTitle,
} from "store/actions";

import HistoryFilter from "./HistoryFilter";

export const cnHistory = cn("History");

type Props = {
  historyGet: (payload: PayloadHistoryGet) => void;
  historyRemove: (payload: PayloadHistoryRemove) => void;
  historyRestoreFolder: (payload: PayloadHistoryRestoreFolder) => void;
  historyRestoreKey: (payload: PayloadHistoryRestoreKey) => void;
  historyStatistic: () => void;
  isLoading: boolean;
  isLoadingStatistic: boolean;
  list: typeHistoryList;
  listCurrentPage: number;
  listPagesCount: number;
  listStatistic: typeStatistic;
  restoreSuccess: boolean;
  role: ROLE_TYPE;
  accessSettings: typeAccessControl;
  setHeaderIcon: (payload: string) => void;
  setHeaderTitle: (payload: string) => void;
};

const History: FC<Props> = ({
  historyGet,
  historyRemove,
  historyRestoreFolder,
  historyRestoreKey,
  historyStatistic,
  isLoading,
  isLoadingStatistic,
  list,
  listCurrentPage,
  listPagesCount,
  listStatistic,
  restoreSuccess,
  role,
  setHeaderIcon,
  accessSettings,
  setHeaderTitle,
}) => {
  const { t } = useTranslation();

  const isAccessActivityLog: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessActivityLog, role), [role, accessSettings]);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [activePages, setActivePage] = useState<number>(1);
  const [filters, setFilters] = useState<IHistoryFilter>({
    activePage: 1,
    pageCount: 1,
    period: {
      start: moment().subtract(1, "months").toDate(),
      end: moment().toDate(),
    },
    operations: [],
    sealing: "",
  });

  const isAdmin: boolean = useMemo(() => [ROLE_TYPE.ADMIN].includes(role), [role]);
  const isManager: boolean = useMemo(() => [ROLE_TYPE.MANAGER].includes(role), [role]);

  useEffect(() => {
    historyStatistic();
    historyGet({ filters });
    setHeaderTitle(t("navigation_bottom-History"));
    setHeaderIcon("history");
  }, []);

  useEffect(() => {
    if (restoreSuccess) {
      historyGet({ filters: { ...filters, activePage: activePages } });
    }
  }, [restoreSuccess]);

  useEffect(() => {
    setFilters({
      ...filters,
      activePage: listCurrentPage,
      pageCount: listPagesCount,
    });
  }, [listPagesCount, listCurrentPage]);

  const handleFilter = () => {
    setShowFilters(false);
    historyGet({ filters: { ...filters, activePage: 1 } });
  };

  const handleFilterCancel = () => {
    setFilters({
      activePage: 1,
      pageCount: listPagesCount,
      period: {
        start: moment().subtract(1, "months").toDate(),
        end: moment().toDate(),
      },
      operations: [],
      sealing: "",
    });
    historyGet({
      filters: {
        activePage: 1,
        pageCount: listPagesCount,
        period: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        },
        operations: [],
        sealing: "",
      },
    });
  };

  const handlePageChange = (page: number) => {
    historyGet({ filters: { ...filters, activePage: page } });
    setActivePage(page);
  };

  // const handleRemove = (id: string) => {
  //   historyRemove({ id });
  // };

  const handleRestore = (history: typeHistory, historyID: string) => {
    if (history.key) {
      historyRestoreKey({ id: history.key.id, historyID });
    } else if (history.folder) {
      historyRestoreFolder({ id: history.folder.id, historyID });
    }
  };

  return (
    <ContentWrapper id="history_content_wrapper">
      <Content className={cnHistory("Loading")} id="history_content">
        <ContentHeader id="keys_content_header">
          {isAdmin || (isManager && isAccessActivityLog) ? <Header linkList={HistoryNavigation} /> : <div className={cnContentHeader("CleanFull")} />}
          <Button
            icon="icon-filter"
            onClick={() => setShowFilters(!showFilters)}
            id="btn_show_filter_2"
            size={30}
            theme="primary-outline"
            isActive={showFilters}
          />
        </ContentHeader>
        <LoadingContainer isLoading={isLoading}>
          <div className={cnHistory()}>
            {list &&
              Object.keys(list).map((historyID: string, index) => {
                const history: typeHistory = list[historyID];

                const item = history.key ? history.key : history.folder;

                const historyDelete = history.route === "delete";

                // eslint-disable-next-line no-nested-ternary
                const title = history.key
                  ? history.key.encryptionEnabled
                    ? SecurityServices.decrypt(history.key.title || "")
                    : history.key.title
                  : history.folder?.title;

                let circleClass: string = "Yellow";

                let actionIcon;

                switch (history.route) {
                  case "update":
                    circleClass = "Yellow";
                    actionIcon = <Icon width={16} height={16} name="icon-pencil" fill="#ffffff" />;
                    break;
                  case "create":
                    circleClass = "Green";
                    actionIcon = <Icon width={16} height={16} name="icon-plus" fill="#ffffff" />;
                    break;
                  case "delete":
                    circleClass = "Red";
                    actionIcon = <Icon width={16} height={16} name="icon-trash" fill="#ffffff" />;
                    break;
                  case "restore":
                    circleClass = "Blue";
                    actionIcon = <Icon width={16} height={16} name="icon-update" fill="#ffffff" />;
                    break;
                }

                return (
                  <div className={cnHistory("Item")} key={historyID}>
                    <div className={cnHistory("Icon")}>
                      <div className={cnHistory(`Circle Circle-${circleClass}`)}>{actionIcon}</div>
                    </div>
                    <div className={cnHistory("Type")}>
                      {history.key ? (
                        <Icon name="colored/icon-key" width={25} height={25} />
                      ) : (
                        <div className="FolderIcon">
                          <Icon width={25} height={25} name="colored/icon-folder" />
                        </div>
                      )}
                    </div>
                    <div className={cnHistory("Info")}>
                      <div className={cnHistory("Info-Name")}>
                        {history.key && (
                          <Link
                            className={cnHistory("Info-Name-Link", { isDelete: historyDelete })}
                            to={replaceUrlParam(Routes.Keys, "folderID", history.key.folderId)}
                            title={history.key.title}
                            id={getKey(`link_to_key_${history.key.title}`)}
                          >
                            {history.key.sealing && isManager && <Icon name="icon-sealing" width={15} height={15} />}
                            {history.key.title}
                          </Link>
                        )}
                        {history.folder && (
                          <Link
                            className={cnHistory("Info-Name-Link", { isDelete: historyDelete })}
                            to={replaceUrlParam(Routes.Keys, "folderID", history.folder.id)}
                            id={getKey(`link_to_folder_${title}`)}
                            title={title?.toString()}
                          >
                            {title}
                          </Link>
                        )}
                      </div>
                      <div className={cnHistory("Info-Text")}>
                        {t("history-Was")} {t(`history-${history.route}`)} {t("history-By")}{" "}
                        <span title={history.user} className={cnHistory("UserName")}>
                          {history.user}
                        </span>
                      </div>
                      <div className={cnHistory("Info-Date")}>
                        <span>{moment(parseDate(history.createdAt)).format(DATETIME_FORMAT)}</span>
                      </div>
                    </div>

                    <div className={cnHistory("Actions")}>
                      {item && item.restore && (
                        <Button
                          id={getKey("button_history_restore", index)}
                          icon="icon-update"
                          data-tooltip={t("tooltip-restore")}
                          className="tooltip tooltip-left"
                          onClick={() => handleRestore(history, historyID)}
                          theme="primary-link"
                        />
                      )}
                      {/* <Button
                        id={getKey("button_history_delete", index)}
                        data-tooltip={t("tooltip-delete")}
                        className="tooltip tooltip-left"
                        theme="outline-red"
                        icon="icon-trash"
                        onClick={() => handleRemove(historyID)}
                      /> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </LoadingContainer>
        {filters.pageCount > 1 && (
          <Pagination
            id="users_pagination"
            itemPagesCount={filters.pageCount}
            currentPage={filters.activePage}
            onChange={handlePageChange}
          />
        )}
      </Content>
      <Aside side="right" id="history_aside_right">
        {showFilters && (
          <HistoryFilter
            handleFilterCancel={handleFilterCancel}
            setIsShow={setShowFilters}
            filters={filters}
            setFilters={setFilters}
            handleFilter={handleFilter}
            isManager={isManager}
          />
        )}
        {!showFilters && (
          <div className={cnAsideContent()}>
            <LoadingContainer isLoading={isLoadingStatistic}>
              <div className={cnAsideContent("Header")}>
                <h2>{t("aside_right-Information")}</h2>
              </div>
              <div className={cnAsideContent("Description")}>
                <p>{t("aside_right_text-History")}</p>
              </div>
              <div className={cnAsideContent("Total")}>
                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Green">
                    <Icon width={16} height={16} name="icon-plus" fill="#ffffff" />
                  </div>
                  <div className="FolderIcon">
                    <Icon width={20} height={20} name="colored/icon-folder" />
                  </div>
                  <Trans i18nKey="history-folder-count" count={listStatistic.foldersCreated}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-create")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Red">
                    <Icon width={16} height={16} name="icon-trash" fill="#ffffff" />
                  </div>
                  <div className="FolderIcon">
                    <Icon width={20} height={20} name="colored/icon-folder" />
                  </div>
                  <Trans i18nKey="history-folder-count" count={listStatistic.foldersDeleted}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-delete")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Blue">
                    <Icon width={16} height={16} name="icon-update" fill="#ffffff" />
                  </div>
                  <div className="FolderIcon">
                    <Icon width={20} height={20} name="colored/icon-folder" />
                  </div>
                  <Trans i18nKey="history-folder-count" count={listStatistic.foldersRestored}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-restore")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Yellow">
                    <Icon width={16} height={16} name="icon-pencil" fill="#ffffff" />
                  </div>
                  <div className="FolderIcon">
                    <Icon width={20} height={20} name="colored/icon-folder" />
                  </div>
                  <Trans i18nKey="history-folder-count" count={listStatistic.foldersUpdated}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-update")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Green">
                    <Icon width={16} height={16} name="icon-plus" fill="#ffffff" />
                  </div>
                  <Icon width={20} height={20} name="colored/icon-key" />
                  <Trans i18nKey="history-key-count" count={listStatistic.keysCreated}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-create")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Red">
                    <Icon width={16} height={16} name="icon-trash" fill="#ffffff" />
                  </div>
                  <Icon width={20} height={20} name="colored/icon-key" />
                  <Trans i18nKey="history-key-count" count={listStatistic.keysDeleted}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-delete")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Blue">
                    <Icon width={16} height={16} name="icon-update" fill="#ffffff" />
                  </div>
                  <Icon width={20} height={20} name="colored/icon-key" />
                  <Trans i18nKey="history-key-count" count={listStatistic.keysRestored}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-restore")}</p>
                </div>

                <div className={cnAsideContent("Total-Count")}>
                  <div className="History-Circle Circle-Yellow">
                    <Icon width={16} height={16} name="icon-pencil" fill="#ffffff" />
                  </div>
                  <Icon width={20} height={20} name="colored/icon-key" />
                  <Trans i18nKey="history-key-count" count={listStatistic.keysUpdated}>
                    <span>0</span>
                  </Trans>{" "}
                  &nbsp;
                  <p>{t("history-update")}</p>
                </div>
              </div>
            </LoadingContainer>
          </div>
        )}
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  histories: { list, isLoading, isLoadingStatistic, listPagesCount, listCurrentPage, listStatistic, restoreSuccess },
  settingsTest: { customize, accessControl: { settings: accessSettings } },
  user: { role },
}: RootState) => ({
  isLoading,
  isLoadingStatistic,
  list,
  listCurrentPage,
  listPagesCount,
  listStatistic,
  restoreSuccess,
  role,
  accessSettings,
});

const mapDispatchToProps = {
  historyGet,
  historyRemove,
  historyRestoreFolder,
  historyRestoreKey,
  setHeaderTitle,
  setHeaderIcon,
  historyStatistic,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
