import React from "react";
import i18n from "locales/i18n";
import { Action } from "redux-actions";
import { Trans } from "react-i18next";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { get as lodashGet, has, snakeCase, trim } from "lodash";
import { stopSubmit } from "redux-form";
import { toast } from "react-toastify";
import { folderGetAll } from "store/actions";

import { FORM_KEY_MANAGEMENT, http } from "core";

export type Payload = {
  folder: string;
  formData: any;
  id?: string;
};

export const action: Routine = createRoutine("KEYS_MANAGE");

const get = ({ id, folder, formData }: Payload) => {
  const data: FormData = new FormData();

  for (const field in formData) {
    data.append(field, formData[field]);
  }

  return http({
    route: id ? `key/${id}/folder/${folder}` : `folder/${folder}/key`,
    method: "POST",
    payload: data,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    yield put(action.success({ key: response, new: !payload.id }));

    toast.success(i18n.t(`toast_key_success_${payload.id ? "update" : "create"}`));
    yield put(folderGetAll.trigger());
  } catch ({ response: { data } }) {
    if (has(data, "detail")) {
      const errors = Object.entries(lodashGet(data, "detail")).reduce((acc: { [key: string]: string }, item: any) => {
        const [field, errors] = item;
        if (field === "password") {
          toast.error(i18n.t("toast_key_error_pass_error"));
        }
        acc[field] = i18n.t(`toast_key_error_${snakeCase(trim(errors[0]))}`);
        return acc;
      }, {} as { [key: string]: string });

      if (errors.password && errors.password.includes("{{")) {
        const test = String(errors.password).split("{{").pop()?.split("}}").shift();
        toast.error(<Trans i18nKey="pass_validation_min_count" count={Number(test)} />);
      }

      if (errors.messages) {
        toast.error(errors.messages);
      }

      yield put(stopSubmit(FORM_KEY_MANAGEMENT, errors));
    }

    if (has(data, "error")) {
      const errors = {
        file: i18n.t(`toast_key_error_${snakeCase(trim(lodashGet(data, "error")))}`),
        certificate: i18n.t(`toast_key_error_${snakeCase(trim(lodashGet(data, "error")))}`)
      }

      yield put(stopSubmit(FORM_KEY_MANAGEMENT, errors));
    }

    if (has(data, "error_code")) {
      const errors = {
        file: i18n.t(`toast_key_error_${snakeCase(trim(lodashGet(data, "error_code")))}`),
        certificate: i18n.t(`toast_key_error_${snakeCase(trim(lodashGet(data, "error_code")))}`)
      }

      toast.error(i18n.t(`toast_key_error_${snakeCase(trim(lodashGet(data, "error_code")))}`));
      

      yield put(stopSubmit(FORM_KEY_MANAGEMENT, errors));
    }

    yield put(action.failure(lodashGet(data, "detail")));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
