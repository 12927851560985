import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { folderGetAll } from "store/actions";
import { get as lodashGet } from "lodash";

export type Payload = { id: string; version: number };

export const action: Routine = createRoutine("KEYS_REVERT");

const get = ({ id, version }: Payload) => {
  return http({ route: `key/${id}/revert/${version}` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    toast.success(i18n.t("toast_key_success_revert"), {
      toastId: "KEY_SUCCESS_REVERT",
    });

    yield put(action.success(response));

    yield put(folderGetAll.trigger());
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
