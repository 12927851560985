/* eslint-disable jsx-a11y/click-events-have-key-events, no-lonely-if, jsx-a11y/no-static-element-interactions, react/no-array-index-key */

import React, { FC, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Button, Checkbox, Icon } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import i18n from "locales/i18n";

import { get, snakeCase } from "lodash";

import { FormRadio } from "components/Form";
import { cnModal, Modal, ModalFolderSelect } from "components/Generic";

import {
  FOLDER_ACCESS,
  FOLDER_TYPE,
  FORM_KEY_MOVE,
  getFolderFromTreeById,
  ROLE_TYPE,
  typeFolder,
} from "core";

import { CONVEYANCE_ACTION, CONVEYANCE_FORMAT, CONVEYANCE_PRODUCTS, FormatOptions, productLabel } from "pages/Settings/SettingsType";
import { cnSettings } from "pages/Settings";

import { Payload as PayloadKeysExport } from "store/routines/keys/keysExport";
import { Payload as PayloadKeysImport } from "store/routines/keys/keysImport";
import { keyFilesExport, keysExport, keysImport, showReImportModal, cleanImportValue } from "store/actions";
import { RootState } from "store/reducers";

import ConveyanceFile from "./ConveyanceFile";
import ExportTree from "./ExportTree";

type Props = {
  keyFilesExport: () => void;
  change: (field: string, value: any) => void;
  keysExport: (payload: PayloadKeysExport) => void;
  keysImport: (payload: PayloadKeysImport) => void;
  showReImportModal: () => void;
  cleanImportValue: () => void;
  handleClose: (status: boolean) => void;
  product: CONVEYANCE_PRODUCTS;
  action: CONVEYANCE_ACTION;
  list: typeFolder[];
  userRole: ROLE_TYPE;
  showImportModal: boolean;
  importModalError: any;
  clearImport: boolean;
  importCounter: boolean;
};

const ConveyanceModal: FC<Props> = ({
  userRole,
  change,
  list,
  product,
  action,
  handleClose,
  keyFilesExport,
  keysExport,
  keysImport,
  showReImportModal,
  cleanImportValue,
  showImportModal = false,
  importModalError,
  clearImport,
  importCounter = false,
}) => {
  const { t } = useTranslation();

  const [format, setFormat] = useState<CONVEYANCE_FORMAT>(CONVEYANCE_FORMAT.XLS);
  const [file, setFile] = useState<File>();
  const [archive, setArchive] = useState<any>();
  const [groupFolder, setGroupFolder] = useState<any | null>();
  const [archiveError, setArchiveError] = useState<string>();
  const [folderId, setFolderId] = useState<string | null>(null);
  const [preserve, setPreserve] = useState<string>("1");
  const [encryptedPassword, setEncryptedPassword] = useState<string>("0");
  const [includeSubFolders, setIncludeSubFolders] = useState<string>("1");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string>("");
  const [errorField, setErrorField] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [errorTextCount, setErrorTextCount] = useState<string | undefined>("");
  const [showFolderSelect, setShowFolderSelect] = useState<boolean>(false);
  const [rootFolderImport, setRootFolderImport] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [checkboxSubFolders, setCheckboxSubFolders] = useState<boolean>(true);
  const [showFolderSelectMulti, setShowFolderSelectMulti] = useState<boolean>(false);
  const [listFolders, setListFolders] = useState<string[]>([]);
  const isPsProduct: boolean = useMemo(() => product === "ps", [product]);
  const isKeepassProduct: boolean = useMemo(() => product === "keepass", [product]);
  const isUser = useMemo(() => userRole === ROLE_TYPE.USER, [userRole]);
  const isManager = useMemo(() => userRole === ROLE_TYPE.MANAGER, [userRole]);
  const isFreeUser: boolean = useMemo(() => userRole === ROLE_TYPE.FREE_USER, [userRole]);
  const isAdmin: boolean = useMemo(() => userRole === ROLE_TYPE.ADMIN, [userRole]);

  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [allowAcceptImport, setAllowAcceptImport] = useState<boolean>(false);

  const [countRequests, setCountRequests] = useState<number>(0);

  useEffect(() => {
    if (countRequests > 300000) {
      setCountRequests(0);
      cleanImportValue();
      setFile(undefined);
      setArchive(undefined);
      setArchiveError(undefined);
      handleClose(false);
    } else {
      if (importCounter) {
        setCountRequests(countRequests + 6000);
        setTimeout(handleAction, 6000);
      }
    }
  }, [importCounter]);

  useEffect(() => {
    setShowModal(showImportModal);
    setErrorKey(get(importModalError, "key", ""));
    setErrorField(get(importModalError, "field", ""));
    setErrorText(get(importModalError, "error", ""));
  }, [showImportModal, importModalError]);

  useEffect(() => {
    setCloseModal(clearImport);
  }, [clearImport]);

  useEffect(() => {
    if (closeModal) {
      setFile(undefined);
      setArchive(undefined);
      setArchiveError(undefined);
      cleanImportValue();
      handleClose(false);
    }
  }, [closeModal]);

  useEffect(() => {
    if (errorText.includes("{{")) {
      setErrorTextCount(errorText.split("{{").pop()?.split("}}").shift());
    }
  }, [errorText]);

  useEffect(() => {
    if ((groupFolder && groupFolder.type === "group") || isUser || isManager || isFreeUser) {
      setCheckbox(false);
      setPreserve("0");
    } else {
      setCheckbox(true);
      setPreserve("1");
    }
  }, []);

  const handleCloseImportWarning = () => {
    showReImportModal();
    setShowModal(false);
    handleClose(false);
    cleanImportValue();
  };

  const handleAction = () => {
    if (action === CONVEYANCE_ACTION.EXPORT) {
      keysExport({
        format,
        product,
        listFolders,
        encryptedPassword,
        includeSubFolders,
      });
      // handleClose(false);
    }
    if (action === CONVEYANCE_ACTION.IMPORT && file) {
      keysImport({
        product,
        file,
        folderId,
        archive,
        preserve,
        rootFolderImport,
        allowPassword: allowAcceptImport,
      });
    }
    if (action === CONVEYANCE_ACTION.IMPORT && archive && !file) {
      setArchiveError(t("toast_import_error_Archive"));
    } else if (action === CONVEYANCE_ACTION.IMPORT && !file) {
      setArchiveError(t("toast_import_error_file_required"));
    }
  };

  const handleImportAllow = () => {
    if (action === CONVEYANCE_ACTION.IMPORT && file) {
      keysImport({
        product,
        file,
        folderId,
        archive,
        preserve,
        rootFolderImport,
        allowPassword: true,
      });
    }
    if (action === CONVEYANCE_ACTION.IMPORT && archive && !file) {
      setArchiveError(t("toast_import_error_Archive"));
    } else if (action === CONVEYANCE_ACTION.IMPORT && !file) {
      setArchiveError(t("toast_import_error_file_required"));
    }
  }

  const handleDownloadAttachment = () => {
    keyFilesExport();
    handleClose(false);
  };

  const handlePreserve = (value: string) => {
    setCheckbox(!checkbox);
    setPreserve(value);
  };

  const handleEncryptedPassword = (value: string) => {
    setEncryptedPassword(value);
  };

  const handleAddFolder = (value: string | null) => {
    if (value !== "null") {
      setFolderId(value);
    }
    setGroupFolder(getFolderFromTreeById(list, value));
  };

  const handleAddRootFolder = (value: boolean) => {
    setRootFolderImport(value);
  };

  const handleAddFolders = (value: string | null) => {
    if (value) {
      setListFolders(value.split(","));
    } else {
      setListFolders([]);
    }
  };

  const handleSubFolder = (value: boolean) => {
    setIncludeSubFolders(value ? "1" : "0");
    setCheckboxSubFolders(value);
  };

  const handleRemoveInListFolders = (value: string) => {
    setListFolders(currentFolder => currentFolder.filter(item => item !== value));
  };

  const radioArrayForPassword = [
    { radioName: "true", radioValue: "0" },
    { radioName: "false", radioValue: "1" },
  ];

  return (
    <>
      {!showModal && (
        <Modal className={cnModal("ModalFolderSelect")} handleClose={() => handleClose(false)}>
          <div className={cnModal("Header")}>
            <Icon name="icon-pointer-left" width={15} height={16} className={cnSettings("Icon", [action])} />
            {t(`settings_${action}`)}
            &nbsp;
            {productLabel[product]}
            &nbsp;
            {t("settings_keys")}
          </div>

          <div className={cnModal("Scroll")}>
            {action === CONVEYANCE_ACTION.EXPORT && (
              <div>
                <label>{t("settings_modal_select_type")}</label>
                <Select
                  className={cnSettings("ModalSelect")}
                  defaultValue={format === CONVEYANCE_FORMAT.XLS ? FormatOptions[0] : FormatOptions[1]}
                  isClearable={false}
                  isSearchable={false}
                  options={FormatOptions}
                  onChange={selected => selected && setFormat(selected.value)}
                  styles={{
                    indicatorSeparator: (provided: any) => ({
                      ...provided,
                      backgroundColor: "transparent",
                    }),
                  }}
                />
                {isPsProduct && (
                  <div className={cnSettings("EncryptedPassword")}>
                    <div className={cnSettings("Checked")}>
                      {radioArrayForPassword.map(radioItem => {
                        return (
                          <Field
                            key={radioItem.radioName}
                            name="encryptedPassword"
                            component={FormRadio}
                            type="radio"
                            checked={radioItem.radioValue === encryptedPassword}
                            value={radioItem.radioValue}
                            label={t(`settings_export_pass_value_${radioItem.radioValue}`)}
                            onChange={() => handleEncryptedPassword(radioItem.radioValue)}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className={cnSettings("ExportFolders")}>
                  <p>{t("settings_modal_export_button")}</p>
                  <Button
                    id="button_select_folder"
                    icon="icon-plus"
                    onClick={() => setShowFolderSelectMulti(!showFolderSelectMulti)}
                    theme="primary-link"
                    type="button"
                  >
                    {t("button_Select-Folder")}
                  </Button>
                </div>

                <div className={cnSettings("ExportFolders-List")}>
                  {listFolders.length > 0 && <p>{t("settings_modal_export_checked_list")}</p>}
                  {listFolders &&
                    listFolders.map((id, i) => {
                      const folder = getFolderFromTreeById(list, id);
                      return (
                        <ExportTree
                          key={i}
                          folder={folder}
                          isCheckboxSubFolders={checkboxSubFolders}
                          isRoot={true}
                          level={0}
                          listFolders={listFolders}
                          onChange={handleRemoveInListFolders}
                        />
                      );
                    })}
                </div>

                {showFolderSelectMulti && (
                  <>
                    <ModalFolderSelect
                      isIndexHigh={true}
                      modalTitle={t("settings_modal_export_title")}
                      handleClose={setShowFolderSelectMulti}
                      type={FOLDER_TYPE.UNDEFINED}
                      checkboxRootFolder={false}
                      onChanges={handleAddFolders}
                      changeSubFoldersCheckbox={handleSubFolder}
                      subSolderCheckboxValue={checkboxSubFolders}
                      isMulti={true}
                      folderList={listFolders}
                      accessArr={[FOLDER_ACCESS.WRITE, FOLDER_ACCESS.READ]}
                    />
                  </>
                )}
              </div>
            )}
            {action === CONVEYANCE_ACTION.IMPORT && (
              <div className={cnSettings("ModalUpload")}>
                <ConveyanceFile
                  setFile={setFile}
                  file={file}
                  label={t("settings_select_file_type")}
                  allowedFormat={["csv", "xml", "ms-excel", "xlsx", "xls"]}
                />
                {archiveError && !file && <p className={cnSettings("ErrorArchive")}>{archiveError}</p>}
                {isPsProduct && (
                  <ConveyanceFile setFile={setArchive} file={archive} label={t("settings_select_archive_type")} allowedFormat={["zip"]} />
                )}

                <div className="Field Field-ParentModal">
                  <span className="Label">{t("form_input-folder")}</span>
                  <div
                    tabIndex={0}
                    className="Field-ParentModal-Input"
                    onKeyDown={() => setShowFolderSelect(!showFolderSelect)}
                    onClick={() => setShowFolderSelect(!showFolderSelect)}
                    role="button"
                  >
                    <div className="FolderIcon">
                      <Icon width={20} height={20} name="colored/icon-folder" />
                    </div>
                    {rootFolderImport ? (
                      <span>{t("modal-root-folder")}</span>
                    ) : (
                      <span
                        title={
                          folderId ? getFolderFromTreeById(list, folderId).title : t("form_input-folder")
                        }
                      >
                        {folderId ? getFolderFromTreeById(list, folderId).title : t("form_input-folder")}
                      </span>
                    )}
                  </div>
                </div>

                {isUser || isManager || isFreeUser || rootFolderImport ? (
                  <></>
                ) : (
                  <>
                    {!isKeepassProduct &&
                      (isUser ? (
                        groupFolder &&
                        groupFolder.type !== "group" && (
                          <div className={cnSettings("Checked")}>
                            <Checkbox checked={checkbox} onChange={() => (checkbox ? handlePreserve("0") : handlePreserve("1"))} />
                            <p>{t("settings_import_checkbox")}</p>
                          </div>
                        )
                      ) : (
                        <div className={cnSettings("Checked")}>
                          <Checkbox checked={checkbox} onChange={() => (checkbox ? handlePreserve("0") : handlePreserve("1"))} />
                          <p>{t("settings_import_checkbox")}</p>
                        </div>
                      ))}
                  </>
                )}

                {showFolderSelect && (
                  <ModalFolderSelect
                    isIndexHigh={true}
                    modalTitle={t("settings_modal_import_title")}
                    activeFolder={folderId}
                    handleClose={setShowFolderSelect}
                    type={FOLDER_TYPE.UNDEFINED}
                    checkboxRootFolder={false}
                    importInRoot={true}
                    importInRootValue={rootFolderImport}
                    importOnChange={handleAddRootFolder}
                    isAdmin={isAdmin}
                    onChanges={handleAddFolder}
                    accessArr={[FOLDER_ACCESS.WRITE]}
                  />
                )}
              </div>
            )}
          </div>

          <div className={cnModal("Actions")}>
            <Button id={`button_setting_action_${action}`} onClick={handleAction} >
              {t(`settings_actions_${action}`)}
            </Button>
            {action === CONVEYANCE_ACTION.EXPORT && product === CONVEYANCE_PRODUCTS.PS && (
              <Button id="settings_key_attachments" onClick={handleDownloadAttachment}>
                {t("settings_key_attachments")}
              </Button>
            )}
          </div>
        </Modal>
      )}
      {showModal && (
        <Modal className={cnModal("ModalFolderSelect")} handleClose={() => handleCloseImportWarning()}>
          <div className={cnModal("Header")}>
            <Icon name="icon-pointer-left" width={15} height={16} className={cnSettings("Icon", [action])} />
            {t(`settings_${action}`)}
            {productLabel[product]}
            {t("settings_keys")}
          </div>
          <div className={cnModal("Scroll")}>
            <p>{t("settings_allow_import_text")}</p>
            <div className={cnModal("Errors")}>
              {errorText.includes("{{") ? (
                <p>
                  {i18n.t("navigation_key")} {errorKey}: {errorField} <br /> -
                  <Trans i18nKey="pass_validation_min_count" count={Number(errorTextCount)} />
                </p>
              ) : (
                <p>
                  {i18n.t("navigation_key")} {errorKey}: {errorField} <br /> -{t(`toast_import_error_${snakeCase(errorText)}`)}
                </p>
              )}
            </div>
          </div>
          <div className={cnModal("Actions")}>
            <Button id={`button_setting_action_${action}`} onClick={
              () => {
                setAllowAcceptImport(true);
                handleImportAllow();
              }}>
              {t("settings_allow_import_submit")}
            </Button>
            <Button id="settings_key_attachments" onClick={handleCloseImportWarning}>
              {t("settings_allow_import_cancel")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
const mapStateToProps = ({
  folders: { list },
  user: { role },
  keys: { showImportModal, importModalError = false, clearImport, importCounter },
}: RootState) => ({
  list,
  userRole: role,
  showImportModal,
  importModalError,
  clearImport,
  importCounter,
});

const mapDispatchToProps = { keyFilesExport, keysExport, keysImport, showReImportModal, cleanImportValue };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<{ folderID: string }>({
    form: FORM_KEY_MOVE,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(ConveyanceModal);
