import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { folderGetAll, favoritesGet } from "store/actions";

import i18n from "locales/i18n";
import { get as lodashGet, has } from "lodash";

export type Payload = {
  id: string;
};

export const action: Routine = createRoutine("GROUPS_DELETE");

const remove = ({ id }: Payload) => {
  return http({ route: `manager/group/${id}`, method: "DELETE" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(remove, payload);

    toast.success(i18n.t("toast_group_success_remove"));

    yield put(folderGetAll.trigger());
    yield put(favoritesGet.trigger());

    yield put(action.success(payload.id));
  } catch (error) {
    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
