import i18n from "locales/i18n";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, has, snakeCase } from "lodash";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  clearUrlParam,
  getDataFromLS,
  getHash,
  http,
  LOCAL_STORAGE,
  ProtectedRoutes,
  removeDataFromLS,
  typeLoginAzureResponse,
} from "core";

export type Payload = {
  accessToken: string;
  account: {
    environment: string;
    name: string;
    username: string;
  };
  expiresOn: Date;
  scope: string;
  tokenType: string;
};

export const action: Routine = createRoutine("LOGIN_AZURE");

const loginAzure = (payload: Payload) => {
  return http({
    method: "POST",
    payload: { token: payload.accessToken },
    route: "token/create",
    isProtected: false,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response: typeLoginAzureResponse = yield call(loginAzure, payload);

    yield put(action.success({ ...response, isMicrosoft: true }));

    const prevURL = getDataFromLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL);
    const copyURL = getDataFromLS(LOCAL_STORAGE.SEND_URL);

    yield removeDataFromLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL);

    if (response.locale) {
      i18n.changeLanguage(response.locale);
    }

    if (copyURL && copyURL.send_url.split("/").includes("login") !== true) {
      window.location = copyURL.send_url;
    } else if (prevURL && getHash(response.username) === prevURL.username && prevURL.url.split("/").includes("login") !== true) {
      window.location = prevURL.url;
    } else {
      yield put(push(clearUrlParam(ProtectedRoutes.Keys)));
    }
    yield removeDataFromLS(LOCAL_STORAGE.SEND_URL);
  } catch (error) {
    if (has(error, "response.data.error_description") && i18n.exists(`error_${snakeCase(get(error, "response.data.error_description"))}`)) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.error_description"))}`));
    } else if (has(error, "response.data.detail.messages") && i18n.exists(`error_${snakeCase(get(error, "response.data.detail.messages"))}`)) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.detail.messages"))}`));
    } else {
      yield put(action.failure("error_connection"));
    }
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
