import { Action } from "redux-actions";
import { getGlobalSettings, setCustomizeMailer, setCustomizeMailerCheck, setCustomizeMailerSwitch } from "store/actions";
import { typeMailerSettings } from "core";
import { find } from "lodash";

export interface SettingsMailerState {
  isLoading: boolean;
  mailerEnabled: boolean;
  mailerStatus: boolean;
  value: typeMailerSettings;
}

const initialState = {
  isLoading: false,
  mailerEnabled: false,
  mailerStatus: false,
  value: {
    mailerEncryption: "", 
    mailerHost: "",
    mailerPassword: "",
    mailerPort: "",
    mailerUser: "",
    mailerSendMail: "",
    mailerSendName: "",
  }
};

export default function mailerSettings(
  state: SettingsMailerState = initialState,
  { payload, type }: Action<any>,
): SettingsMailerState {
  switch (type) {
    case getGlobalSettings.TRIGGER: 
    case setCustomizeMailer.TRIGGER:
    case setCustomizeMailerSwitch.TRIGGER:  
    case setCustomizeMailerCheck.TRIGGER:  
      return {
        ...state,
        isLoading: true
      };
    
    case setCustomizeMailerSwitch.SUCCESS: 
      return {
        ...state,
        isLoading: false,
        mailerEnabled: payload.mailerEnabled,
      }

    case getGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        value: find(payload, ["setting", "MAILER_SETTINGS"])?.value,
        mailerEnabled: find(payload, ["setting", "MAILER_SETTINGS"])?.value.mailerEnabled,
        mailerStatus: find(payload, ["setting", "MAILER_SETTINGS"])?.value.mailerStatus,
      };

    case setCustomizeMailer.SUCCESS:
      return {
        ...state,
        isLoading: false,
        value: payload,
      };

    case setCustomizeMailerCheck.SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailerStatus: payload.status,
      };

    case getGlobalSettings.FAILURE:
    case getGlobalSettings.FULFILL:
    case setCustomizeMailer.FAILURE:
    case setCustomizeMailer.FULFILL:
    case setCustomizeMailerCheck.FAILURE:
    case setCustomizeMailerCheck.FULFILL:
    case setCustomizeMailerSwitch.FAILURE:
    case setCustomizeMailerSwitch.FULFILL:    
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
