import { Action } from "redux-actions";
import {
  groupsChose,
  groupsClear,
  groupsDelete,
  groupsGet,
  groupsGetAll,
  groupsManagement,
  syncGroup,
  deferredGroups
} from "store/actions";
import { ACTION, typeGroup } from "core";

export interface State {
  chosen: string[];
  error?: string;
  isLoading: boolean;
  item?: typeGroup;
  list: typeGroup[];
  groupsCounter: boolean;
}

const initialState: State = {
  chosen: [],
  error: undefined,
  isLoading: false,
  item: undefined,
  list: [],
  groupsCounter: false,
};

export default function groups(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case groupsDelete.TRIGGER:
    case groupsGet.TRIGGER:
    case groupsGetAll.TRIGGER:
    case groupsManagement.TRIGGER:
    case syncGroup.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };
    
    case deferredGroups.TRIGGER:
      return {
        ...state,
        groupsCounter: false,
        isLoading: true,
      };
      
    case deferredGroups.SUCCESS:
      return {
        ...state,
        groupsCounter: true,
        isLoading: true,
      };

    case groupsClear.SUCCESS:
      return {
        ...initialState,
      };

    case groupsChose.SUCCESS: {
      let list: string[] = state.chosen;

      if (payload.oneOnly && payload.status) {
        return {
          ...state,
          chosen: [payload.id],
        };
      }

      if (!payload.isNew && state.chosen.length === 1 && state.chosen[0] === "new") {
        list.length = 0;
      }

      if (payload.status) {
        list = payload.choseAll ? [...payload.ids] : [...list, payload.id];
      } else {
        list = payload.choseAll ? [] : [...list.filter(id => payload.id !== id)];
      }

      return {
        ...state,
        chosen: payload.status && payload.isNew ? ["new"] : [...list],
      };
    }

    case groupsDelete.SUCCESS:
      return {
        ...state,
        chosen: [],
        isLoading: false,
        list: state.list.filter(({ id }: typeGroup) => id !== payload),
      };

    case groupsGet.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case groupsGetAll.SUCCESS:
      return {
        ...state,
        list: payload,
        isLoading: false,
      };

    case syncGroup.SUCCESS:
      return {
        ...state,
        list: payload,
        isLoading: false,
      };

    case groupsManagement.SUCCESS: {
      let list: typeGroup[] = [];

      if (payload.action === ACTION.CREATE) {
        list = [...state.list, payload.response];
      } else {
        list = [...state.list.map((group: typeGroup) => (group.id === payload.response.id ? { ...group, ...payload.response } : group))];
      }

      return {
        ...state,
        list: [...list],
        chosen: payload.action === ACTION.CREATE ? [payload.response.id] : [...state.chosen],
        isLoading: false,
      };
    }
      
    case deferredGroups.FAILURE:
      return {
        ...state,
        groupsCounter: false,
        isLoading: false,
      };

    case groupsChose.FAILURE:
    case groupsDelete.FAILURE:
    case groupsGet.FAILURE:
    case groupsGetAll.FAILURE:
    case groupsManagement.FAILURE:
    case syncGroup.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
