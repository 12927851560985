import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Pagination } from "components/Generic";
import { typeSearchKey, getKey } from "core";
import { Payload as payloadGetKeysSearch } from "store/routines/search/getKeysSearch";
import { getKeysSearch } from "store/actions";
import { cnTable, PropsTableWithSort, TableHeaderElement, TableWithSort, typeTableHeaderElement } from "components/Table";
import { RootState } from "store/reducers";
import { tableHeader } from "../../TableConfig";

import SearchKeyItem from "./SearchKeyItem";

const cnKeysList = cn("KeysList");

type Props = {
  keysList: typeSearchKey[];
  keysCurrentPage: number,
  keysPagesCount: number,
  searchProps: string;
  isLoadingKeys: boolean;
  getKeysSearch: (payload: payloadGetKeysSearch) => void;
} & PropsTableWithSort;

const SearchKey: FC<Props> = ({
  searchProps,
  keysList,
  sortBy,
  sortDirection,
  keysCurrentPage,
  keysPagesCount,
  isLoadingKeys,
  getKeysSearch,
  sortCallback,
  handleSort,
}) => {
  const { t } = useTranslation();

  const handleChangePage = (page: number) => {
    getKeysSearch({ currentPage: page, search: searchProps });
  };

  return (
    <>
    <div className={cnKeysList("", ["KeysSearch"])}>
      <div className={cnTable("Head Table-Head-Small Table-Key")}>
        <div className={cnTable("Head-Th")} />
        {tableHeader.map(({ title, ...data }: typeTableHeaderElement, index: number) => (
          <TableHeaderElement
            handleSort={handleSort}
            id={getKey(title, index)}
            key={title}
            sortBy={sortBy}
            sortDirection={sortDirection}
            {...data}
          >
            {t(`table_title-${title}`)}
          </TableHeaderElement>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {keysList.length > 0 ? (
            keysList.sort(sortCallback).map(( item: typeSearchKey) => {
              return (
                <SearchKeyItem keyItem={item} key={item.id}/>
              )
            }
          )) : (
            <>
            {isLoadingKeys ? null : <div className={cnTable("Empty")}><p>{t("search_results_keys_is_empty")}</p></div>}
            </>
          )}
        </div>
      </div>
    </div>
    {keysList.length > 0 && 
      <Pagination 
        id="search_key_pagination"
        itemPagesCount={keysPagesCount}
        currentPage={keysCurrentPage}
        onChange={handleChangePage}
      />
    }
    </>
  );
};

const mapStateToProps = ({
  search: { 
    search, 
    keysList, 
    keysCurrentPage,
    keysPagesCount,
    isLoadingKeys,
  },
}: RootState) => ({
  search, 
  keysList, 
  keysCurrentPage,
  keysPagesCount,
  searchProps: search,
  isLoadingKeys,
});

const mapDispatchToProps = {
  getKeysSearch,
};

export default compose<Props, Partial<Props>>(TableWithSort, connect(mapStateToProps, mapDispatchToProps))(SearchKey);