/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "components/Generic";
import React, { FC, useEffect } from "react";
import { Button, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldArray, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { NavLink } from "react-router-dom";
import {
  ACTION,
  FORM_GROUP_MANAGEMENT,
  getGroupFromListByID, GROUP_STORAGE,
  Routes,
  typeFolder,
  typeFolderGroups,
  typeGroup,
  typeGroupFolder,
  typeUser,
} from "core";
import { FormValues, Payload as PayloadGroupsManagement } from "store/routines/groups/manage";
import { RootState } from "store/reducers";
import { folderGetTree, groupsManagement } from "store/actions";
import RenderFoldersList from "./RenderFoldersList";
import renderFolders from "./RenderFolders";

export const cnFolders = cn("InfoBoxGroupFolders");

type Props = {
  chosen: string[];
  folderGetTree: () => void;
  groupsManagement: (payload: PayloadGroupsManagement) => void;
  list: typeGroup[];
  treeList: typeFolderGroups;
  folderList: typeFolder[];
  groupStorage: GROUP_STORAGE;
  handleModalFolders: (value: boolean) => void;
  modalFoldersValue: boolean;
  isGroupAccess: boolean;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const Folders: FC<Props> = ({
  change,
  chosen,
  folderGetTree,
  groupsManagement,
  handleSubmit,
  list,
  treeList,
  folderList,
  groupStorage,
  handleModalFolders,
  modalFoldersValue,
  isGroupAccess,
}) => {
  const { t } = useTranslation();
  const isNew = chosen[0] === "new";
  const group: typeGroup = getGroupFromListByID(list, chosen[0]);

  useEffect(() => {
    if (!isNew) {
      change("folders", group.folders);
    }
  }, [group]);

  useEffect(() => {
    folderGetTree();
  }, []);

  const handleForm = (data: any) => {
    groupsManagement({
      id: group.id,
      action: ACTION.UPDATE,
      payload: {
        folders: data.folders.map(({ access, folder, recursion }: typeGroupFolder) => ({
          access,
          folder,
          recursion,
        })),
        name: group.name,
        users: group.users.map(({ id }: typeUser) => id),
        azureId: isNew || !group.azureId || groupStorage === GROUP_STORAGE.LOCAL ? undefined : group.azureId,
      },
    });
    handleModalFolders(false);
  };

  if (!isGroupAccess && group.folders.length === 0) {
    return (
      <p>{t("groups-List-Empty")}</p>
    )
  }

  return (
    <>
      {isNew && <div className={cnFolders("Error")}>{t("groups-Error")}</div>}
      {group.folders.length > 0 ? (
        <RenderFoldersList />
      ) : (
        <>
          <p>{t("group-Folders-Empty")}</p>
          <NavLink to={Routes.FolderManagement}>
            <span>{t("button_add-Folder")}</span>
          </NavLink>
        </>
      )}
      {modalFoldersValue && (
        <Modal handleClose={() => handleModalFolders(false)} className={cnFolders("Modal")}>
          <div className={cnFolders("Modal-Header")}>
            <Icon name="icon-group-circle" width={30} height={30} /> <p>{isNew ? t("groups-New") : group.name}</p>
          </div>
          <form onSubmit={handleSubmit(handleForm)} className={cnFolders("Modal-Form")}>
            {Object.keys(treeList).length > 0 && (
              <FieldArray name="folders" component={renderFolders} treeList={folderList} chosenGroup={group} />
            )}
            <div className={cnFolders("Modal-Action")}>
              <Button id="button_action_save" type="submit">
                {t("button-Save")}
              </Button>
              <Button id="button_action_cancel" theme="secondary-outline" onClick={() => handleModalFolders(false)}>
                {t("button-Cancel")}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({
  folders: { treeList, list: folderList },
  groups: { list, chosen },
  settings: { groupStorage },
}: RootState) => ({
  chosen,
  treeList,
  list,
  folderList,
  groupStorage,
});

const mapDispatchToProps = { groupsManagement, folderGetTree };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_GROUP_MANAGEMENT,
  }),
)(Folders);
