import React, { FC, useContext, useEffect, useState } from "react";
import { Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "ss-ui";

import { FormInputMask } from "components/Form";

import { FIELD_TYPE, Validation, FORM_KEY_MANAGEMENT, creditCardType, getFieldValue } from "core";

import { RootState } from "store/reducers";

import { FieldContext } from "./Types";

type Props = {
  number?: string;
};

const fieldType = FIELD_TYPE.CARD_NUMBER;

const FieldCardNumber: FC<Props> = ({ number = "" }) => {
  const { t } = useTranslation();

  const context = useContext(FieldContext);

  const [type, setType] = useState<string | undefined>(undefined);

  useEffect(() => {
    setType(creditCardType(number.replace(/\s/g, "")));
  }, [number]);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <div className="FieldCardNumber">
      <Field
        component={FormInputMask}
        label={t(`manage_key_field_${fieldType}_label`)}
        name={fieldType}
        inputFormat="#### #### #### ####"
        className="Required"
        placeholder={t(`manage_key_field_${fieldType}_placeholder`)}
        validate={[Validation.required, Validation.creditCardNumber]}
      />
      {type && <Icon name={`colored/icon-${type}`} />}
    </div>
  );
};

const selector = formValueSelector(FORM_KEY_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  number: selector(state, fieldType),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FieldCardNumber);
