import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http, IGroupsFilter } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, has } from "lodash";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

export const action: Routine = createRoutine("GROUPS_GET_ALL");

export type Payload = {
  filters?: IGroupsFilter;
};

const getAll = ({ filters }: Payload) => {
  const params: any = {};
  const usersFilter = filters && filters.users.map(item => item.value);

  if (filters) {
    params.search = filters.search;
    params.member = usersFilter;
    params.folder = filters.folders;
  }

  return http({ 
    route: "manager/groups", 
    method: "GET",
    payload: { params },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(getAll, payload);

    yield put(action.success(response));
  } catch (error) {
    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
