import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http, getDataFromLS, LOCAL_STORAGE } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { favoritesGet, folderGetAll, favoritesGetKeys, setGlobalSearch } from "store/actions";
import { get as lodashGet } from "lodash";

export type Payload = {
  id: string;
  folderID: string;
};

export const action: Routine = createRoutine("KEYS_DELETE");

const remove = ({ id, folderID }: Payload) => {
  return http({ route: `key/${id}/folder/${folderID}`, method: "DELETE" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(remove, payload);

    const lastSearch = getDataFromLS(LOCAL_STORAGE.LAST_SEARCH_VALUE);

    toast.success(i18n.t("toast_key_success_removed", { count: 1 }));

    yield put(action.success(payload.id));

    if (window.location.pathname.includes("search") && lastSearch) {
      yield put(setGlobalSearch.trigger(lastSearch));
    }

    yield put(folderGetAll.trigger());
    yield put(favoritesGetKeys.trigger({ page: 1 }));
    yield put(favoritesGet.trigger());
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
