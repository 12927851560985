import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export type Payload = {
  bestIcon?: boolean;
  hibpwned?: boolean;
  autoLogoutTimeout?: number;
  offlineModeValue?: boolean;
};

export const action: Routine = createRoutine("SET_BESTICON");

const setBestIcon = ({ bestIcon, hibpwned, autoLogoutTimeout, offlineModeValue }: Payload) => {
  return http({
    route: "user/settings",
    method: "POST",
    payload: {
      besticonEnabled: bestIcon,
      hibpwnedEnabled: hibpwned,
      autologoutTimeout: autoLogoutTimeout,
      offlineMode: offlineModeValue,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setBestIcon, payload);
    if (payload.bestIcon || payload.hibpwned || payload.autoLogoutTimeout || payload.offlineModeValue) {
      toast.success(i18n.t("toast_bestIcon_success"));
    } else {
      toast.success(i18n.t("toast_bestIcon_error"));
    }
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
