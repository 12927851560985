import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

import { usersGetAll, getConfig, deferredUsers } from "store/actions";

export const action: Routine = createRoutine("USERS_SYNC");

const usersSync = () => {
  return http({ route: "manager/users-sync", method: "GET" });
};

function* handler() {
  try {
    yield call(usersSync);

    yield put(action.success());

    yield put(usersGetAll.trigger({}));

    yield put(getConfig.trigger());

    toast.success(i18n.t("toast_sync_group_success"));
  } catch (error) {

    const errorStatus = lodashGet(error, "response.status", null);

    if (errorStatus === 422) {
      yield put(deferredUsers.trigger());
    } else { 
      yield put(action.failure(lodashGet(error, "message", undefined)));
      toast.error(i18n.t("toast_sync_group_error"));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
