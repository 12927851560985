import React, { FC, useState, useMemo, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FormInput } from "components/Form";
import {
  Validation,
  testPasswordForBusiness,
  typePasswordSetting,
  typeReturnTestPasswordForBusiness,
  FIELD_TYPE,
  apiCallSecurityPassword,
  apiCallCheckField,
} from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";
import { FieldContext } from "pages/ManageKey/Fragments/Types";

import { RootState } from "store/reducers";

import { GeneratePassword, ValidationPassword, ValidationPasswordFilter } from "./index";

type Props = {
  generatorPasswordSettings: typePasswordSetting[];
  pwnedOff: boolean;
  securityCheck?: boolean;
  isSapTemplate?: boolean;
  passwordValid: (value: boolean) => void;
  passwordPwnedValid: (value: number) => void;
};

const fieldType = FIELD_TYPE.PASSWORD;

const FieldPassword: FC<Props> = ({
  generatorPasswordSettings,
  pwnedOff,
  securityCheck = false,
  passwordValid,
  passwordPwnedValid,
  isSapTemplate
}) => {
  
  const { t } = useTranslation();

  /**
   * pwned status got from server
   */
  const [pwnedStatus, setPwnedStatus] = useState<number>(4);

  /**
   * Component loading state
   */
  const [isLoading, setLoading] = useState<boolean>(false);
  const context = useContext(FieldContext);

  /**
   * The validation object with a validation password status
   */
  const [validationsError, setValidationsError] = useState<typeReturnTestPasswordForBusiness>({
    digitsPresence: undefined,
    lowercaseLetters: undefined,
    minCharactersCount: undefined,
    specialChars: undefined,
    uppercaseLetters: undefined,
    validation: undefined,
  });

  const passwordSetting: typePasswordSetting | undefined = useMemo(() => {
    return generatorPasswordSettings && generatorPasswordSettings.find(x => x.value === Number(context.folder?.level_security || 1));
  }, [generatorPasswordSettings, context.folder]);

  useEffect(() => {
    sendValidationError();
  }, [validationsError])

  useEffect(() => {
    if (context.key?.password && !context.formData[fieldType]) {
      apiCallSecurityPassword({
        password: context.key.password,
      })
        .then(password => {
          handleChangePasswordValue(password);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (context.formData[fieldType]) {
      handleChangePasswordValue(context.formData[fieldType]);
    }
  }, [context.key]);

  const resetPasswordValidation = () => {
    setPwnedStatus(0);
    passwordValid(false);
  }

  const handleChangePasswordValue = (value: string) => {
    resetPasswordValidation();
    if (!pwnedOff) {
      setLoading(true);
    }
    context.handleChange(fieldType, value);
    securityCheck && setValidationsError(testPasswordForBusiness(value , passwordSetting));
    pwnedCheck(value, true);
  }

  const handleChange = (value: any, generatePassword?: boolean) => {
    context.handleChange(fieldType, value);
    securityCheck && setValidationsError(testPasswordForBusiness(value, passwordSetting));

    if (!value || pwnedOff) {
      return;
    }

    setPwnedStatus(0);
    passwordPwnedValid(0);
    if (generatePassword) {
      pwnedCheck(value, true);
    }
  }

  const sendValidationError = () => {
    if (validationsError.validation) {
      passwordValid(validationsError.validation);
    } else {
      passwordValid(false);
    }
  }

  const handleFocusField = () => {
    if (!validationsError.validation) {
      passwordValid(false);
    }
  }

  const pwnedCheck = async (value: string, firstCall: boolean) => {

    if (pwnedStatus !== 0 && !firstCall) {
      return;
    }

    setPwnedStatus(4);
    setLoading(true);

    const status = await apiCallCheckField({
      field: fieldType,
      value,
    });

    setPwnedStatus(status ? 1 : 2);
    passwordPwnedValid(status ? 1 : 2);
    if (!status) {
      toast.warning(t("toast_pwned_warning_password"));
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={cnManageKey("CheckInput", {
          checkedLoginWait: pwnedStatus === 0,
          checkedPasswordSuccess: pwnedStatus === 1,
          checkedPasswordError: pwnedStatus === 2
        })}
      >
        <input className="fakePassport" type="password" name="fakepassword" />
        <Field
          className={isSapTemplate ? "PassInput" : "PassInput Required"}
          component={FormInput}
          isShown
          label={t("form_input-Password")}
          name={fieldType}
          onChange={(e: any) => handleChange(e.target.value)}
          onBlur={(el: any) => pwnedCheck(el.target.value, false)}
          placeholder={t("form_input-Enter-Password")}
          validate={isSapTemplate ?
            [Validation.maxLength255, Validation.emoji, Validation.notSpace]
            : [Validation.required, Validation.maxLength255, Validation.emoji, Validation.notSpace]}
          onFocus={() => handleFocusField()}
        />
        {isLoading && <div className={cnManageKey("CheckInput-Loading")}>{t("key-field-check")}</div>}
        {pwnedStatus === 1 && (
          <div className={cnManageKey("CheckInput-Checked")}>
            <Icon name="icon-checked" fill="#49B109" />
            {t("key-field-check-success")}
          </div>
        )}
        {pwnedStatus === 2 && (
          <div className={cnManageKey("CheckInput-Warning")}>
            <Icon name="icon-info-circle" fill="#F0A600" width={15} height={15} />
            {t("key-field-check-notValid")}
          </div>
        )}
        {pwnedStatus === 0 && !pwnedOff && (
        <div className={cnManageKey("CheckInput-Waiting")}>
          {t("key-field-check-wait")} &#8594;
        </div>
      )}
      </div>

      {securityCheck && !isSapTemplate && <ValidationPasswordFilter error={validationsError.validation} />}

      {securityCheck && passwordSetting && !isSapTemplate && <ValidationPassword validationsError={validationsError} passwordSetting={passwordSetting} />}

      {securityCheck && context.folder?.level_security && <GeneratePassword levelSecurity={context.folder?.level_security} handleGenerate={handleChange} />}
    </>
  );
};

const mapStateToProps = ({
  settings: { hibpwnedEnabled, pwned },
  settingsTest: {
    generatorPassword: { settings: generatorPasswordSettings },
  },
}: RootState) => ({
  generatorPasswordSettings,
  pwnedOff: !hibpwnedEnabled && !pwned,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FieldPassword);
