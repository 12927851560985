import { Action } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { toast } from "react-toastify";

import { http } from "core";

import i18n from "locales/i18n";

export const action: Routine = createRoutine("USER_DELETE_REGISTER_YUBIKEY");

export interface Payload {
  id: string;
}

const deleteRegisterYubikey = ({ id }: Payload) => {
  return http({
    route: `user/yubikeys/${id}`,
    method: "DELETE",
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(deleteRegisterYubikey, payload);

    yield put(action.success(payload));

    toast.success(i18n.t("settings_yubikey_removed"));
  } catch (error) {
    yield put(action.failure(null));
  } finally {
    yield put(action.fulfill())
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
