/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Navigation } from "components/Navigation";
import { RootState } from "store/reducers";
import { cnAside, Aside, Footer, Header, cnLayout } from "components/Layout";
import { ErrorBoundary, BMobileMenu } from "components/Generic";

type Props = {
  initialization: boolean;
};

const Layout: FC<Props> = ({ initialization, children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const history = useHistory();

  history.listen(() => {
    setMenuIsOpen(false);
  });

  return (
    <>
      {!initialization && (
        <div className={cnLayout()} id="page_layout">
          <BMobileMenu handleClick={() => setMenuIsOpen(!menuIsOpen)} isOpen={menuIsOpen} />
          <Header />
          <div className={cnLayout("Container")}>
            <Aside className={cnAside("LayoutMenu", { isOpen: menuIsOpen })} id="layout_aside_left">
              <Navigation />
            </Aside>
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ user: { initialization } }: RootState) => ({
  initialization,
});

export default connect(mapStateToProps)(Layout);
