import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { typeReturnTestPasswordForBusiness, typePasswordSetting } from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { ValidationTest } from "./index";

type Props = {
  validationsError: typeReturnTestPasswordForBusiness;
  passwordSetting: typePasswordSetting;
};

const ValidationPassword: FC<Props> = ({ validationsError, passwordSetting }) => {
  const { t } = useTranslation();

  return (
    <div className={cnManageKey("PassError", { Filter: validationsError.minCharactersCount === undefined })}>
      <ValidationTest isShow={true} isSuccess={validationsError.minCharactersCount || false}>
        <Trans i18nKey="pass_validation_min_count" count={passwordSetting.min_characters_count}>
          <span>0</span>
        </Trans>
      </ValidationTest>
      <ValidationTest isShow={passwordSetting.lowercase_letters_presence} isSuccess={validationsError.lowercaseLetters || false}>
        {t("pass_validation_lowercase")}
      </ValidationTest>
      <ValidationTest isShow={passwordSetting.uppercase_letters_presence} isSuccess={validationsError.uppercaseLetters || false}>
        {t("pass_validation_uppercase")}
      </ValidationTest>
      <ValidationTest isShow={passwordSetting.digits_presence} isSuccess={validationsError.digitsPresence || false}>
        {t("pass_validation_digitsPresence")}
      </ValidationTest>
      <ValidationTest isShow={passwordSetting.specialchars_presence} isSuccess={validationsError.specialChars || false}>
        {t("pass_validation_special_chars")}
      </ValidationTest>
    </div>
  );
};

export default ValidationPassword;
