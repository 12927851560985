import React, { FC } from "react";

import { cnManageKey } from "pages/ManageKey/ManageKey";

type Props = {
  error?: boolean;
};

const ValidationPasswordFilter: FC<Props> = ({ error = undefined }) => {
  return (
    <div
      className={cnManageKey("PasswordValidation", {
        success: error === true,
        Filter: error === undefined,
      })}
    >
      &nbsp;
    </div>
  );
};

export default ValidationPasswordFilter;
