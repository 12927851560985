import Color from "color";
import React, { FC, useMemo } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { customSelectStyles } from "components/Form/Select";

import { PRODUCT_TYPE } from "core";

import { cnSettings } from "pages/Settings";
import { defaultCustomValue } from "pages/Settings/Customize/Customize";

import { RootState } from "store/reducers";
import { setBestIcon, setGlobalSettings } from "store/actions";

import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { Payload as PayloadSetBestIcon } from "store/routines/settings/setBestIcon";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

const FormatOptionsLogoutTime = [1, 2, 3, 4, 5, 7, 8, 9, 10, 15, 20, 25, 30, 45, 60].map(e => ({
  label: `${e} Min.`,
  value: e,
}));

type Props = {
  autoLogoutTimeout?: number;
  customize: SettingsCustomizeState;
  logoutTime?: number;
  productType: PRODUCT_TYPE;
  setBestIcon: (payload: PayloadSetBestIcon) => void;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const AutomaticLogout: FC<Props> = ({ autoLogoutTimeout = 5, customize, logoutTime = 5, productType, setBestIcon, setGlobalSettings }) => {
  const { t } = useTranslation();

  const color: string = useMemo(() => customize.colorMain || defaultCustomValue.primaryColor, [customize.colorMain]);
  const isBusiness: boolean = useMemo(() => [PRODUCT_TYPE.ENTERPRISE, PRODUCT_TYPE.CORPORATE].includes(productType), [productType]);

  const setLogoutTime = (value: number) => {
    setGlobalSettings({
      name: "AUTO_LOGOUT_TIMEOUT",
      value,
    });
  };

  const setLogoutTimeFree = (value: number) => {
    setBestIcon({
      autoLogoutTimeout: value,
    });
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_timeout">
        <div className={cnSettings("Header")}>{t("settings_header_logout")}</div>
        <div className={cnSettings("Hint")}>{t("settings_hunt_logout")}</div>
        <div className={cnSettings("SelectLogout")}>
          <Select
            className={cnSettings("LogoutTime")}
            value={FormatOptionsLogoutTime.find(x => x.value === (isBusiness ? logoutTime : autoLogoutTimeout))}
            isClearable={false}
            isSearchable={false}
            options={FormatOptionsLogoutTime}
            onChange={selected => selected && (isBusiness ? setLogoutTime(selected.value) : setLogoutTimeFree(selected.value))}
            styles={customSelectStyles({ isMultiple: false }, color)}
            theme={theme => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#EAFBFD",
                primary75: color,
                primary50: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
                primary: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
              },
            })}
          />
          <label>Timeout</label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings: { autoLogoutTimeout, logoutTime, productType }, settingsTest: { customize } }: RootState) => ({
  autoLogoutTimeout,
  customize,
  logoutTime,
  productType,
});

const mapDispatchToProps = {
  setBestIcon,
  setGlobalSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticLogout);
