import { Action } from "redux-actions";
import { settingsClear, setHeaderTitle, setHeaderIcon } from "store/actions";

export interface SettingsHeaderState {
  icon?: string;
  title?: string;
}

const initialState = {
  title: "",
  icon: "",
};

export default function header(state: SettingsHeaderState = initialState, { payload, type }: Action<any>): SettingsHeaderState {
  switch (type) {

    case settingsClear.SUCCESS:
      return {
        ...state,
        title: initialState.title,
        icon: initialState.icon,
      };

    case setHeaderTitle.SUCCESS:
      return {
        ...state,
        title: payload,
      };

    case setHeaderIcon.SUCCESS:
      return {
        ...state,
        icon: payload,
      };

    case setHeaderIcon.FAILURE:
    case setHeaderIcon.FULFILL:
    case setHeaderTitle.FAILURE:
    case setHeaderTitle.FULFILL:
      return {
        ...state,
      };

    default:
      return state;
  }
}
