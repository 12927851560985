import { isEmpty, get } from "lodash";

import { getPublicApiUrl } from "core/helpers/functions/url";
import { ICustomize } from "core/types";

import packageJson from "../../../../package.json";

/**
 * Get additional login data, for request
 * @param otp {string} - One time password
 */
export function additionalLoginData(otp?: string) {
  return {
    app_version: packageJson.version,
    grant_type: "password",
    device_id: navigator.appCodeName,
    device_name: navigator.platform,
    otp: otp || "otp",
  };
}

/**
 * Get navigator data
 */
export function navigatorData() {
  return {
    device_id: navigator.appCodeName,
    device_name: navigator.platform,
  };
}

/**
 * Return boolean if environment is equal development
 */
export function isEnvDevelop(): boolean {
  return process.env.NODE_ENV === "development";
}

/**
 * Give the user a file received from the server via an api request
 * @param blob - File data
 * @param fileName - how the file will be named when downloading
 */
export function sendFileForDownloading(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
}

export function parseCustomizeResponse(response: { [key: string]: string }): ICustomize {
  return {
    logoImageName: !isEmpty(get(response, "style_company_logo", "")) ? get(response, "style_company_logo") : undefined,
    logo: !isEmpty(get(response, "style_company_logo", "")) ? `${getPublicApiUrl()}${get(response, "style_company_logo")}` : undefined,
    backgroundLoginImageName: !isEmpty(get(response, "style_login_bg_image", "")) ? get(response, "style_login_bg_image") : undefined,
    backgroundLogin: !isEmpty(get(response, "style_login_bg_image", "")) ?
      `${getPublicApiUrl()}${get(response, "style_login_bg_image")}` :
      undefined,
    companyName: get(response, "style_full_company_name", undefined),
    companyAddress: get(response, "style_login_company_address", undefined),
    colorMain: get(response, "style_main_color", undefined),
    colorSub: get(response, "style_sub_color", undefined),
    footerEn: get(response, "style_footer_text_en", undefined),
    footerDe: get(response, "style_footer_text_de", undefined),
    fonts: undefined,
  };
}

/**
 * Get credit card type by number
 * @param {string} cc
 * @return {string | undefined}
 */
export function creditCardType(cc: string): string | undefined {
  const amex = new RegExp("^3[47][0-9]{13}$");
  const visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  const cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
  const cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

  const mastercard = new RegExp("^5[1-5][0-9]{14}$");
  const mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

  const disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
  const disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
  const disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

  const diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
  const jcb = new RegExp("^35[0-9]{14}[0-9]*$");

  if (visa.test(cc)) {
    return "visa";
  }
  if (amex.test(cc)) {
    return "amex";
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return "mastercard";
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return "discover";
  }
  if (diners.test(cc)) {
    return "diners";
  }
  if (jcb.test(cc)) {
    return "jcb";
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    return "china_union_pay";
  }
  return undefined;
}

export const isValidEmail = (email: string): boolean => {
  const emailRegExp = new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,20}$", "i");
  return emailRegExp.test(email);
};
