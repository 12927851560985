import { http } from "core/services";

import { preparePublicKeyCredentials, preparePublicKeyOptions } from "./common";

interface IUseRegistration {
  actionUrl?: string;
  optionsUrl?: string;
}

/**
 * Hook for register yubikey
 * @param {string} actionUrl
 * @param {string} optionsUrl
 * @return {function(*): Promise<any|string>}
 */
const useRegistration = ({
  actionUrl = "/user/yubikeys/register",
  optionsUrl = "/user/yubikeys/register/options",
}: IUseRegistration) => {
  return async ({ username, displayName, name }) => {
    const optionsResponse = await http({
      method: "POST",
      payload: {
        username,
        displayName
      },
      route: optionsUrl,
    });

    const publicKey = preparePublicKeyOptions(optionsResponse);
    const credentials = await navigator.credentials.create({ publicKey });
    const publicKeyCredential = preparePublicKeyCredentials(credentials);

    return http({
      method: "POST",
      route: actionUrl,
      payload: {
        data: publicKeyCredential,
        name,
      },
    });
  };
};

export default useRegistration;
