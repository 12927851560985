import React, { FC, ReactNode, useMemo } from "react";
import { Icon, LanguageSelect } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import i18n, { getUserLocale } from "locales/i18n";

import { Footer } from "components/Layout/index";

import { isDesktopBrowser, translation, PRODUCT_TYPE, typeAuthorizationSupports, USER_STORAGE } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

import packageJson from "../../../../package.json";

export const cnLayoutAnonymous = cn("LayoutAnonymous");

type Props = {
  authorizationSupports: typeAuthorizationSupports;
  children: ReactNode;
  customize: SettingsCustomizeState;
  productType: PRODUCT_TYPE;
  userStorage: USER_STORAGE;
};

const LayoutAnonymous: FC<Props> = ({ authorizationSupports, children, customize, productType, userStorage }: Props) => {
  const { t } = useTranslation();
  const isBusiness: boolean = useMemo(() => [PRODUCT_TYPE.ENTERPRISE, PRODUCT_TYPE.CORPORATE].indexOf(productType) >= 0, [productType]);

  const styleForCustomise = {
    background: "rgba(250,250,250, 0.4)",
    maxWidth: "320px",
    padding: "5px",
    borderRadius: "6px",
    color: "#000",
    boxShadow: "0 5px 15px rgb(0 0 0 / 16%)",
  };

  return (
    <>
      <div className={cnLayoutAnonymous()}>
        <div className={cnLayoutAnonymous("Header")} id="page_login_header">
          <div className={cnLayoutAnonymous("HeaderIcon")} id="page_login_header_icon">
            {customize.logo ? <img src={customize.logo} alt="Logo" /> : <Icon name="app/pass-logo-horizontal" />}
          </div>
          <div className={cnLayoutAnonymous("HeaderLanguage")} id="page_login_header_language">
            {!isDesktopBrowser() && isBusiness && !authorizationSupports.microsoft && userStorage !== USER_STORAGE.LOCAL && (
              <a
                className={cnLayoutAnonymous("HeaderLanguage-Controlium")}
                href={`https://${window.location.hostname}/${getUserLocale()}/controlium/login`}
                id="page_login_controlium_link"
              >
                <Icon name="colored/icon-controlium" />
              </a>
            )}
            <div className={cnLayoutAnonymous("HeaderLanguage-Select")} id="page_login_language_select_container">
              <LanguageSelect
                currentLanguage={{ code: getUserLocale(), title: `language_${getUserLocale()}` }}
                setLanguage={({ code }) => i18n.changeLanguage(code)}
                languages={translation.languages}
              />
            </div>
          </div>
        </div>
        <div className={cnLayoutAnonymous("Content")} id="page_login_form_content">
          <div
            className={cnLayoutAnonymous("Background")}
            id="page_login_form_container"
            style={{
              backgroundImage: customize.backgroundLogin ? `url(${customize.backgroundLogin})` : "",
            }}
          >
            {children}

            <p id="page_login_footer" style={customize.backgroundLogin ? styleForCustomise : {}}>
              {new Date().getFullYear()} {customize.companyAddress || t("login_footer_name")} <br />
              {t("login_footer_version")} {packageJson.version}
            </p>
          </div>
        </div>
      </div>
      <div className={cnLayoutAnonymous("Footer")}>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = ({ settings: { productType, authorizationSupports, userStorage }, settingsTest: { customize } }: RootState) => ({
  authorizationSupports,
  customize,
  productType,
  userStorage,
});

export default connect(mapStateToProps)(LayoutAnonymous);
