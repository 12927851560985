import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { cnInfoBox } from "components/InfoBox";
import { getKey, typeInfoBoxTab } from "core";

type Props = {
  activeTab: number;
  configTabs: typeInfoBoxTab[];
  setActiveTab: (index: number) => void;
};

const InfoBoxTabs: FC<Props> = ({ activeTab, configTabs, setActiveTab }) => {
  const { t } = useTranslation();
  return (
    <div className={cnInfoBox("Tabs")}>
      {configTabs.map(({ index, title, tabAccess = true }: typeInfoBoxTab) => {
        if (!tabAccess) {
          return null
        }
        return (
          <button
            id={getKey(`btn_tabs_${title}`, index)}
            onClick={() => setActiveTab(index)}
            className={cnInfoBox("Tabs-Item", { isActive: activeTab === index })}
            type="button"
            key={index}
          >
            {t(`tabs-${title}`)}
          </button>
        )
      })}
    </div>
  );
};

export default InfoBoxTabs;
