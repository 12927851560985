/* eslint-disable object-shorthand */
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { takeLatest, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export type Payload = {
  name: string;
  value: any;
  passwordSettingValue?: passwordSettingValueType;
  accessControlValue?: {};
  accessDeviceValue?: boolean;
};

export type passwordSettingValueType = {
  min_characters_count?: string,
  lowercase_letters_presence?: boolean,
  uppercase_letters_presence?: boolean,
  specialchars_presence?: boolean,
  digits_presence?: boolean,
}

export const action: Routine = createRoutine("SET_GLOBAL_SETTINGS");

const setGlobalSettings = ({ name, value, passwordSettingValue, accessControlValue, accessDeviceValue }: Payload) => {
  let valueArray: {};

  if (["AUTO_LOGOUT_TIMEOUT", "AUTO_BACKUP_PASSWORD", "LOG_ROTATION_PERIOD"].includes(name)) {
    valueArray = { value: value }
  } else if (name === "PASSWORD_REQUIREMENTS") {
    valueArray = {
      value: {
        securityLevel: value,
        requirements: passwordSettingValue,
      }
    }
  } else if (name === "ACCESS_CONTROL_SETTINGS"){
    valueArray = {
      value: {
        setting: value,
        requirements: accessControlValue,
      }
    }
  } else if (name === "ACCESS_DEVICES") {
    valueArray = {
      value: {
        [value]: accessDeviceValue
      }
    }
  } else if (value) {
    valueArray = { value: 1 }
  } else {
    valueArray = { value: 0 }
  }

  return http({
    route: `global/setting/${name}`,
    method: "POST",
    payload: valueArray,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setGlobalSettings, payload);

    if (!["AUTO_BACKUP_PASSWORD"].includes(payload.name)) {
      if (payload.value) {
        toast.success(i18n.t("toast_bestIcon_success"));
      } else {
        toast.success(i18n.t("toast_bestIcon_error"));
      }
    }

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
