import { Action } from "redux-actions";
import { typeOAuthLifeTime } from "core";
import { getOAuthLifeTime, setOAuthLifeTime } from "store/actions";

export interface OAuthLifeTimeState extends typeOAuthLifeTime {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
  accessToken: 0,
  refreshToken: 0,
};

export default function header(state: OAuthLifeTimeState = initialState, { payload, type }: Action<any>): OAuthLifeTimeState {
  switch (type) {
    case getOAuthLifeTime.SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
      };

    case setOAuthLifeTime.SUCCESS:
      return {
        ...state,
        accessToken: payload.access_token,
        refreshToken: payload.refresh_token,
        isLoading: false,
      };

    case getOAuthLifeTime.FAILURE:
    case setOAuthLifeTime.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
