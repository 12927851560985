import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = { id: string; folderID: string };

export const action: Routine = createRoutine("KEYS_GET");

const get = ({ id, folderID }: Payload) => {
  return http({ route: `key/${id}/folder/${folderID}` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
