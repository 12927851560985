/* eslint-disable react-hooks/exhaustive-deps, no-nested-ternary, react/no-array-index-key, jsx-a11y/click-events-have-key-events, import/extensions, jsx-a11y/no-static-element-interactions */
import { cn } from "@bem-react/classname";

import React, { FC, useState } from "react";
import { getKey, typeFolder } from "core";
import { ButtonDiv, cnModal, FolderStatus } from "components/Generic";
import { FolderIcon, Icon } from "ss-ui";

export const cnTree = cn("Tree");

type Props = {
  folder: typeFolder;
  isCheckboxSubFolders: boolean;
  isRoot: boolean;
  level: number;
  listFolders: string[];
  onChange: (value: any) => void;
};

const ExportTree: FC<Props> = ({ folder, isCheckboxSubFolders, isRoot, level = 0, listFolders, onChange }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <div className={cnModal("ConfirmationItemFolders-Box", { level })}>
      <div className={cnModal("ConfirmationItemFolders")}>
        <div className={cnModal("ConfirmationItemFolders-Info")}>
          {isCheckboxSubFolders &&
            (folder.children.length > 0 ? (
              <ButtonDiv className={cnTree({ isOpen })} onClick={() => setOpen(!isOpen)} id={getKey("btn_open_folder_tree")}
                         key={folder.id}>
                <FolderStatus isActive={isOpen} />
              </ButtonDiv>
            ) : (
              <div className="EmptyFolderStatus">&nbsp;</div>
            ))}
          <div className={cnModal("ConfirmationItemFolders-Name")}>
            <FolderIcon size={25} />
            <span>{folder && folder.title}</span>
          </div>
          {isRoot && (
            <div className={cnModal("ConfirmationItemFolders-Remove")} onClick={() => onChange(folder.id)}>
              <Icon name="icon-close" fill="#E3000B" width={16} height={16} />
            </div>
          )}
        </div>
        <div className={cnModal("ConfirmationItemFolders-Children")}>
          {isCheckboxSubFolders &&
            isOpen &&
            folder.children.map((item, index) => {
              return (
                <ExportTree
                  key={index}
                  folder={item}
                  isCheckboxSubFolders={isCheckboxSubFolders}
                  isRoot={false}
                  level={item.level_security}
                  listFolders={listFolders}
                  onChange={onChange}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ExportTree;
