import React, { FC, useState, useEffect } from "react";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Timer, LoadingContainer } from "components/Generic";

import { typeKey, FIELD_TYPE, copyTypeToClipboard, typeOTP, getFieldValue } from "core";

import { RootState } from "store/reducers";
import { otpDecode } from "store/actions";
import { Payload as PayloadOtpDecode } from "store/routines/keys/otpDecode";

import { cnSettings } from "../Settings";

type Props = {
  field: FIELD_TYPE;
  item: typeKey;
  otp?: typeOTP;
  isLoadingOtp: boolean;
  otpDecode: (payload: PayloadOtpDecode) => void;
};

const RowOTP: FC<Props> = ({ item, field, otp, otpDecode, isLoadingOtp }) => {
  const { t } = useTranslation();

  const [OTPTimer, setOTPTimer] = useState<number>(0);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    getFieldValue(field, item).then(setValue);
  }, [item[field]]);

  useEffect(() => {
    value && OTPTimer === 0 && otpDecode({ secret: value });
  }, [OTPTimer, item, field, value]);

  useEffect(() => {
    otpDecode(value ? { secret: value } : { secret: "", clear: true });
  }, [value]);

  useEffect(() => {
    otp && setOTPTimer(otp.expire);
  }, [otp]);

  if (!value) {
    return null;
  }

  return (
    <div className={`${cnSettings("Container")} ${cnSettings("Container-Relative")}`}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={`${cnSettings("Item")} ${cnSettings("Item-Relative")}`}>
        <LoadingContainer isLoading={isLoadingOtp}>
        <div className={cnSettings("Value")} id={`info_box_key_${field}`}>
          {otp ? (
            <div className={cnSettings("OTP")}>
              <span className="code">{otp.key}</span>
              {OTPTimer >= 0 && (
                <span className="timer">
                  <svg className="circle" viewBox="-10 -10 120 120" width="13" height="13">
                    <path
                      fill="none"
                      strokeLinecap="round"
                      strokeWidth="10"
                      stroke="#49B109"
                      strokeDasharray={`${(otp.expire - OTPTimer) * (314 / otp.expire)},314`}
                      d="M 50 0 A 50 50 0 1,0 50,100 A 50 50 0 1,0 0,50"
                    />
                  </svg>
                  <span className="second">
                    <Timer seconds={OTPTimer} setSeconds={setOTPTimer} /> sec
                  </span>
                </span>
              )}
            </div>
            ) : (
                <span className="error">{t("toast_enabled_otp_error_otp_is_invalid")}</span>
            )}
        </div>
        {otp && (
          <div className={cnSettings("Actions InfoBoxKeySettings-Actions-Right")}>
            <div className="tooltip tooltip-left" data-tooltip={t("tooltip-copy")}>
              <Icon onClick={() => copyTypeToClipboard(otp.key, "otp")} name="icon-copy" id={`btn_copy_to_clipboard_key_${field}`} />
            </div>
          </div>
          )}
          </LoadingContainer>
        </div>
    </div>
  );
};

const mapStateToProps = ({ keys: { otp, isLoadingOtp } }: RootState) => ({
  otp,
  isLoadingOtp,
});

const mapDispatchToProps = { otpDecode };

export default connect(mapStateToProps, mapDispatchToProps)(RowOTP);
