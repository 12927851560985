import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "ss-ui";
import { NavLink } from "react-router-dom";
import { RootState } from "store/reducers";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { FolderNavHeader, Folders } from "components/Navigation";

import {
  AsideNavigation,
  clearUrlParam,
  getKey,
  PRODUCT_TYPE,
  ProtectedRoutes,
  ROLE_TYPE,
  typeRoute,
  typeAccessControl,
  getAccessControl,
} from "core";

import { folderGetAll } from "store/actions";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

export const cnNavigation = cn("Navigation");

type Props = {
  folderGetAll: () => void;
  historyState: boolean;
  productType: PRODUCT_TYPE;
  customize: SettingsCustomizeState;
  role: ROLE_TYPE;
  accessSettings?: typeAccessControl;
};

const Navigation: FC<Props> = ({ customize, historyState, productType, role, accessSettings, folderGetAll }) => {
  useEffect(() => {
    folderGetAll();
  }, [folderGetAll]);

  const { t } = useTranslation();
  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);
  const isAccessManagement: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessManagement, role), [role, accessSettings]);

  const [isClosedBottomNav, setIsClosedBottomNav] = useState<boolean>(false);

  const navigationRef = useRef<HTMLDivElement>(null);
  const topMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const navigationHeight = navigationRef.current?.clientHeight;
      const topMenuHeight = topMenuRef.current?.clientHeight;
      const isDesktopScreen = window.innerWidth > 1024;

      if (isDesktopScreen) {
        if (topMenuHeight && navigationHeight) {
          setIsClosedBottomNav(topMenuHeight > navigationHeight - 200);
        }
      } else {
        setIsClosedBottomNav(true);
      }
    });
    observer.observe(navigationRef.current!);
    observer.observe(topMenuRef.current!);

    return () => {
      observer.disconnect();
    };
  }, [topMenuRef, navigationRef]);

  return (
    <>
      <div className={ cnNavigation("Logo") }>
        {customize.logo ? (
          <img
            alt="Logo"
            src={ customize.logo }
          />
        ) : <Icon name="app/pass-logo-horizontal" />}
      </div>
      <div
        ref={ navigationRef }
        className={ cnNavigation() }
      >
        <div
          ref={ topMenuRef }
          className={ cnNavigation("Top") }
          id="menu_top"
        >
          <FolderNavHeader />
          <Folders />
        </div>
        <ul
          className={ cnNavigation("Bottom", { "Close": isClosedBottomNav }) }
          id="menu_bottom"
        >
          {AsideNavigation.map(({ title, route, icon, roleAccess, productTypeAccess }: typeRoute, index: number) => {
            if (role && roleAccess && !roleAccess.includes(role)) {
              return null;
            }

            if (route === "/users" && !isAccessManagement && !isAdmin) {
              return null;
            }

            if (productTypeAccess && !productTypeAccess.includes(productType)) {
              return null;
            }

            if (route === ProtectedRoutes.History && !historyState) {
              return null;
            }

            const titleFinish: string = typeof title === "function" ? title(role) : title;

            return (
              <li
                key={ getKey(titleFinish, index + 1) }
                id={ getKey(`list_item_menu_${titleFinish}`) }
              >
                <NavLink
                  activeClassName={ cnNavigation("isActive") }
                  className={ cnNavigation("Bottom_Link tooltip") }
                  data-tooltip={ t(`navigation_bottom-${titleFinish}`) }
                  id={ getKey(`link_item_menu_${titleFinish}`) }
                  to={ clearUrlParam(route) }
                  onClick={ () => {
                    const layoutElement = document.querySelector(".Layout-Container");
                    if (layoutElement) {
                      layoutElement.scrollTo({
                        top: 0,
                        left: 0,
                      });
                    }
                  } }
                >
                  <Icon
                    fill="#A7A7A8"
                    height={ 20 }
                    name={ icon }
                    width={ 20 }
                  />
                  <span>{t(`navigation_bottom-${titleFinish}`)}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = ({
  settings: { historyState, productType },
  user: { role },
  settingsTest: { customize, accessControl: { settings: accessSettings } },
}: RootState) => ({
  historyState,
  customize,
  productType,
  role,
  accessSettings,
});

export default connect(mapStateToProps, { folderGetAll })(Navigation);
