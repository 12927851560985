/* eslint-disable react/jsx-no-target-blank, react-hooks/exhaustive-deps */
import Color from "color";
import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { customSelectStyles } from "components/Form/Select";

import { optionsTimeRefreshToken, typeOption } from "core";

import { cnSettings } from "pages/Settings";

import { OAuthLifeTimeState } from "store/reducers/settings/oAuthLifeTime";
import { Payload as payloadSetOAuthLifeTime } from "store/routines/settings/setOAuthLifeTime";
import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";
import { getOAuthLifeTime, setOAuthLifeTime } from "store/actions";

export const cnOAuthLifeTimeControl = cn("OAuthLifeTimeControl");

const TimesAccessToken: typeOption[] = [10, 20, 30, 40, 50, 60, 90, 120].map(e => ({
  label: `${e} Min.`,
  value: e * 60,
}));

interface Props {
  getOAuthLifeTime: () => void;
  oAuthLifeTime: OAuthLifeTimeState;
  setOAuthLifeTime: (payload: payloadSetOAuthLifeTime) => void;
  customize: SettingsCustomizeState;
}

const OAuthLifeTimeControl: FC<Props> = ({ getOAuthLifeTime, setOAuthLifeTime, oAuthLifeTime, customize }) => {
  const { t } = useTranslation();

  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  /**
   * UseEffect one time when page loaded
   */
  useEffect(() => {
    getOAuthLifeTime();
  }, []);

  const handleChangeTime = (value: number, type: "access" | "refresh") => {
    setOAuthLifeTime({
      access_token: type === "access" ? value : oAuthLifeTime.accessToken,
      refresh_token: type === "refresh" ? value : oAuthLifeTime.refreshToken,
    });
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnOAuthLifeTimeControl()}>
        <div className={cnOAuthLifeTimeControl("Header")}>{t("settings_oauth_life_time_header")}</div>
        <label>{t("settings_oauth_life_time_label_token")}</label>
        <Select
          className={cnOAuthLifeTimeControl("Select")}
          isClearable={false}
          isSearchable={false}
          id="select_settings_oauth_life_time_token"
          onChange={selected => handleChangeTime((selected?.value as number) || oAuthLifeTime.accessToken, "access")}
          options={TimesAccessToken}
          styles={customSelectStyles({ isMultiple: false }, mainColor)}
          value={TimesAccessToken.find(x => x.value === oAuthLifeTime.accessToken)}
          theme={theme => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#EAFBFD",
              primary75: mainColor,
              primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
              primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
            },
          })}
        />
        {/* <div className={cnOAuthLifeTimeControl("Hint")}>At vero eos et accusam et justo duo dolores</div> */}
        <label>{t("settings_oauth_life_time_label_refresh")}</label>
        <Select
          className={cnOAuthLifeTimeControl("Select")}
          isClearable={false}
          isSearchable={false}
          id="select_settings_oauth_life_time_refresh"
          onChange={selected => handleChangeTime((selected?.value as number) || oAuthLifeTime.refreshToken, "refresh")}
          options={optionsTimeRefreshToken}
          styles={customSelectStyles({ isMultiple: false }, mainColor)}
          value={optionsTimeRefreshToken.find(x => x.value === oAuthLifeTime.refreshToken)}
          theme={theme => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#EAFBFD",
              primary75: mainColor,
              primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
              primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
            },
          })}
        />
        {/* <div className={cnOAuthLifeTimeControl("Hint")}>At vero eos et accusam et justo duo dolores</div> */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { oAuthLifeTime, customize } }: RootState) => ({
  oAuthLifeTime,
  customize,
});

const mapDispatchToProps = {
  getOAuthLifeTime,
  setOAuthLifeTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuthLifeTimeControl);
