import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { compose } from "recompose";
import { Icon } from "ss-ui";

import { keyPasswordSecurity } from "store/actions";

import { Payload as PayloadKeyPasswordSecurity } from "store/routines/keys/passwordSecurity";

type Props = {
  value: string;
  iconName?: string;
  encryptionEnabled: boolean;
  tooltipName: string;
  toastName: string;
  keyPasswordSecurity: (payload: PayloadKeyPasswordSecurity) => void;
};

const KeysActions: FC<Props> = ({
  value,
  iconName = "icon-copy",
  encryptionEnabled,
  tooltipName,
  toastName,
  keyPasswordSecurity,
}) => {

  const { t } = useTranslation();

  const copyToClipboardPassword = (password: string, encryptionEnabled: boolean) => {
    keyPasswordSecurity({
      action: "decrypt",
      copy: true,
      encryptionEnabled,
      password,
    });
    toast.info(t(toastName), { toastId: toastName });
  };

  return (
    <div className="KeysList-Action-Item tooltip tooltip-left" data-tooltip={t(tooltipName)}>
      <Icon
        name={iconName}
        width={16}
        height={16}
        onClick={() => copyToClipboardPassword(value, encryptionEnabled)}
      />
    </div>
  );
};


const mapDispatchToProps = { keyPasswordSecurity };

export default compose<Props, Partial<Props>>(
  connect(null, mapDispatchToProps),
)(KeysActions);
