import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { setCustomizeMailerCheck } from "store/actions";
import { Action } from "redux-actions";
import { toast } from "react-toastify";
import { http } from "core";
import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("SETTINGS_SET_CUSTOMIZE_MAILER");

export type Payload = {
  mailerEncryption: string, 
  mailerHost: string,
  mailerPassword: string,
  mailerPort: string,
  mailerUser: string,
  mailerSendMail: string,
  mailerSendName: string,
}

const updateCustomizeMailer = ({
  mailerEncryption, 
  mailerHost,
  mailerPassword,
  mailerPort,
  mailerUser,
  mailerSendMail,
  mailerSendName,
}: Payload) => {
  const formData = new FormData();

  formData.append("mailerEncryption", mailerEncryption);
  formData.append("mailerHost", mailerHost);
  formData.append("mailerPassword", mailerPassword);
  formData.append("mailerPort", mailerPort);
  formData.append("mailerUser", mailerUser);
  formData.append("mailerSendMail", mailerSendMail);
  formData.append("mailerSendName", mailerSendName);

  return http({
    route: "global/mailer/set",
    method: "POST",
    payload: formData,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(updateCustomizeMailer, payload);

    if (response.status === false) {
      yield put (setCustomizeMailerCheck.success(response))
      toast.error(i18n.t("customize_mail_toast_save_error"));
    } else {
      toast.success(i18n.t("customize_mail_toast_save_success"));
      yield put(action.success(response));
    }

  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
