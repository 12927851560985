import React, { FC } from "react";
import { cn } from "@bem-react/classname";

export const cnBMobileMenu = cn("BMobileMenu");

type Props = {
  handleClick: () => void;
  isOpen?: boolean;
};

const BMobileMenu: FC<Props> = ({ isOpen, handleClick }) => {
  return (
    <button onClick={handleClick} type="button" className={cnBMobileMenu({ isOpen })} id="layout_btn_mobile_menu">
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </button>
  );
};

export default BMobileMenu;
