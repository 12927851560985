import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

export type Payload = {
  id: string;
  action: string;
};

export const action: Routine = createRoutine("USERS_CONTROL_OTP");

const controlOTP = ({ id, action }: Payload) => {
  return http({ route: `manager/user/${id}/otp/${action}`, method: "GET" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(controlOTP, payload);

    yield put(action.success(payload));
  } catch (error) {
    yield put(action.failure(get(error, "response.data.detail.messages", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
