/* eslint-disable no-nested-ternary */

import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";

import { USER_STORAGE } from "core";

import { RootState } from "store/reducers";

import UserLocal from "./UserLocal";
import UserLDAP from "./UserLDAP";

export const cnSettings = cn("InfoBoxUserSettings");

type Props = {
  setActiveTab: (index: number) => void;
  userStorage: USER_STORAGE;
};

const Settings: FC<Props> = ({ setActiveTab, userStorage }) => {
  return userStorage === USER_STORAGE.LOCAL ? <UserLocal setActiveTab={setActiveTab} /> : <UserLDAP />;
};

const mapStateToProps = ({ settings: { userStorage } }: RootState) => ({
  userStorage,
});

const mapDispatchToProps = {};

export default compose<Props, Partial<Props>>(connect(mapStateToProps, mapDispatchToProps))(Settings);
