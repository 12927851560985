import React, { FC } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";

import { getKeyFromArrayByID, typeKey, getTemplateIcon } from "core";

import { RootState } from "store/reducers";

const cnMultiply = cn("InfoBoxKeyMultiply");

type Props = {
  chosen: string[];
  list: typeKey[];
};

const Multiply: FC<Props> = ({ chosen, list }) => {
  return (
    <div className={cnMultiply()}>
      {chosen &&
        chosen.map((id: string) => {
          const key = getKeyFromArrayByID(list, id);

          return key && (
            <div className={cnMultiply("Item")} key={id} title={key.title}>
              <Icon name={getTemplateIcon(key.template)} width={18} />
              <div className={cnMultiply("Item-Name")}>{key.title}</div>
            </div>
          );

          // return (
          //   <div className={cnMultiply("Item")} key={id} title={key && key.title}>
          //     <Icon name="colored/icon-key" width={20} height={9}/>
          //     <Icon name={getTemplateIcon(key.template)} width={18} />
          //     {key && key.title}
          //   </div>
          // );
        })}
    </div>
  );
};

const mapStateToProps = ({ keys: { chosen, list } }: RootState) => ({ chosen, list });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Multiply);
