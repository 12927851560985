import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnContentHeader } from "components/Layout";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ContentHeader: FC<IProps> = ({ children, className = "", ...props }) => {
  return (
    <div className={`${cnContentHeader()} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default ContentHeader;
