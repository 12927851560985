import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "ss-ui";

import { typeKey, FIELD_TYPE, copyTypeToClipboard, getFieldValue } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
};

const RowDate: FC<Props> = ({ item, field }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>();

  useEffect(() => {
    getFieldValue(field, item).then(setValue);
  }, [item[field]]);

  if (!value) {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Item")}>
        <div className={cnSettings("Value")} id={`info_box_key_${field}`}>
          <span title={value}>{value}</span>
        </div>
        <div className={cnSettings("Actions InfoBoxKeySettings-Actions-Right")}>
          <div className="tooltip tooltip-left" data-tooltip={t("tooltip-copy")}>
            <Icon onClick={() => copyTypeToClipboard(value, field)} name="icon-copy" id={`btn_copy_to_clipboard_key_${field}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowDate;
