import React, { FC, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";

import {
  Routes,
  clearUrlParam,
  productsEnterprise,
  rolesAccessAdmin,
  rolesAccessManagers,
  rolesAccessPaid,
  rolesAccessAdminAndFree,
  productsCorporate,
} from "core";

import { Layout } from "components/Layout";

import ProtectedRoute from "./ProtectedRoute";

const ActivityLog = React.lazy(() => import("pages/ActivityLog/ActivityLog"));
const Favorites = React.lazy(() => import("pages/Favorites/Favorites"));
const FolderManagement = React.lazy(() => import("pages/FolderManagement/FolderManagement"));
const Groups = React.lazy(() => import("pages/Groups/Groups"));
const History = React.lazy(() => import("pages/History/History"));
const Keys = React.lazy(() => import("pages/Keys/Keys"));
const Profile = React.lazy(() => import("pages/Profile/Profile"));
const Search = React.lazy(() => import("pages/Search/Search"));
const Users = React.lazy(() => import("pages/Users/Users"));

const Conveyance = React.lazy(() => import("pages/Settings/Conveyance/Conveyance"));
const Customize = React.lazy(() => import("pages/Settings/Customize/Customize"));
const General = React.lazy(() => import("pages/Settings/General/General"));
const Security = React.lazy(() => import("pages/Settings/Security/Security"));
const TokenManager = React.lazy(() => import("pages/Settings/TokenManager/TokenManager"));

const ProtectedRoutes: FC = () => {
  return (
    <Layout>
      <Suspense fallback={<span />}>
        <Switch>
          <ProtectedRoute component={Profile} exact path={Routes.Profile} />
          <ProtectedRoute component={Search} exact path={Routes.Search} />

          <ProtectedRoute component={Keys} exact path={Routes.Keys} />
          <ProtectedRoute component={Keys} exact path={Routes.ManageKey} />

          <ProtectedRoute component={Favorites} exact path={Routes.Favorites} />

          <ProtectedRoute component={History} exact path={Routes.History} roleAccess={rolesAccessPaid} />
          <ProtectedRoute
            component={ActivityLog}
            exact
            path={Routes.ActivityLog}
            productTypeAccess={productsEnterprise}
            roleAccess={rolesAccessManagers}
          />

          <ProtectedRoute component={General} exact path={Routes.settings.General} />
          <ProtectedRoute component={Security} exact path={Routes.settings.Security} roleAccess={rolesAccessAdminAndFree} />
          <ProtectedRoute
            component={TokenManager}
            exact
            path={Routes.settings.TokenManager}
            productTypeAccess={productsCorporate}
            roleAccess={rolesAccessAdmin}
          />
          <ProtectedRoute component={Conveyance} exact path={Routes.settings.Conveyance} />
          <ProtectedRoute
            component={Customize}
            exact
            path={Routes.settings.Customize}
            productTypeAccess={productsEnterprise}
            roleAccess={rolesAccessAdmin}
          />

          <ProtectedRoute
            component={FolderManagement}
            exact
            path={Routes.FolderManagement}
            productTypeAccess={productsEnterprise}
            roleAccess={rolesAccessManagers}
          />

          <ProtectedRoute
            component={Groups}
            exact
            path={Routes.Groups}
            productTypeAccess={productsEnterprise}
            roleAccess={rolesAccessManagers}
          />

          <ProtectedRoute
            component={Users}
            exact
            path={Routes.Users}
            productTypeAccess={productsEnterprise}
            roleAccess={rolesAccessManagers}
          />

          <Redirect to={clearUrlParam(Routes.Keys)} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default ProtectedRoutes;
