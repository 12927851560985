import React, { FC, useEffect, useState, useMemo } from "react";
import { Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Aside, cnAsideContent, Content, ContentWrapper, ContentHeader, cnContentHeader } from "components/Layout";
import { 
  PayloadChoseFunction, 
  UsersNavigation, 
  USER_STORAGE, 
  typeUser, 
  ROLE_TYPE, 
  IUsersFilter,
  getPortalLink,
  typeAccessControl,
  getAccessControl,
  mapRolesName,
} from "core";
import { Pagination, cnModal, Modal } from "components/Generic";
import { Header } from "components/Navigation";
import { InfoBoxUser } from "components/InfoBox";
import { getUserLocale } from "locales/i18n";
import { cnGroups } from "pages/Groups/Groups";
import { RootState } from "store/reducers";
import { setClickedLink, setHeaderIcon, setHeaderTitle, usersChose, usersGetAll, usersSync } from "store/actions";
import { Payload as PayloadSetClickedLink } from "store/routines/settings/setClickedLink";
import { Payload as payloadUsersGetAll } from "store/routines/users/getAll";

import TableUsers from "./TableUsers";
import TableUsersLocal from "./TableUsersLocal";
import UsersFilter from "./fragments/UsersFilter";

export const cnUsers = cn("Users");

type Props = {
  chosen: string[];
  currentPage: number;
  error?: string | string[];
  license?: { limit: number; active_user: number };
  pagesCount: number;
  list: typeUser[];
  totalItems: number;
  userStorage: USER_STORAGE;
  role: ROLE_TYPE;
  accessSettings?: typeAccessControl;
  globalOtp?: boolean;
  usersCounter: boolean;
  setClickedLink: (payload: PayloadSetClickedLink) => void;
  setHeaderIcon: (payload: string | undefined) => void;
  setHeaderTitle: (payload: string | undefined) => void;
  usersChose: (payload: PayloadChoseFunction) => void;
  usersGetAll: (payload: payloadUsersGetAll) => void;
  usersSync: () => void;
};

const Users: FC<Props> = ({
  chosen,
  currentPage,
  error,
  license,
  pagesCount,
  list,
  totalItems,
  userStorage,
  accessSettings,
  role,
  globalOtp,
  usersCounter,
  setClickedLink,
  setHeaderIcon,
  setHeaderTitle,
  usersChose,
  usersGetAll,
  usersSync,
}) => {
  const { t } = useTranslation();
  const defaultFilters = { search: "", roles: [], groups: [], status: "", otp: "" } 
  const [activePages, setActivePages] = useState<number>(1);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showLimitModal, setShowLimitModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<IUsersFilter>(defaultFilters);
  const isLimitAvailable: boolean = useMemo(() => [USER_STORAGE.LOCAL, USER_STORAGE.AZURE].includes(userStorage) ,[userStorage]);
  const itemStatus = [{ status: 0 }, { status: 1 }, { status: 2 }];
  const itemRoles = [{ role: ROLE_TYPE.ADMIN }, { role: ROLE_TYPE.MANAGER }, { role: ROLE_TYPE.USER }];
  const isAccessManagement: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessManagement, role), [role, accessSettings]);
  const isAdmin: boolean = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);

  const [countRequests, setCountRequests] = useState<number>(0);

  useEffect(() => {
    setHeaderTitle(t("navigation_bottom-Users & Groups"));
    setHeaderIcon("group");
  }, [setHeaderTitle, setHeaderIcon]);

  useEffect(() => {
    usersGetAll({ page: activePages, filters: { ...filters } });
    return usersChose({ status: false, choseAll: true });
  }, []);

  useEffect(() => {
    if (usersCounter) {
        setCountRequests(countRequests + 6000);
        setTimeout(usersSync, 6000);
      }
  }, [usersCounter]);

  useEffect(() => {
    if (chosen.length > 0) {
      setShowFilters(false);
    }
  }, [chosen]);

  useEffect(() => {
    if (error && error.length === 1 && error[0] === "user_limit" && isLimitAvailable) {
      setShowLimitModal(true);
    }
  }, [error]);

  const countStatus = (status: number) => {
    return list.filter(item => item.status === status).length;
  }

  const countRoles = (roles: ROLE_TYPE) => {
    return list.filter(item => item.roles === roles).length;
  }

  const handleAddUser = (tab: number) => {
    if (license && license.active_user >= license.limit && isLimitAvailable) {
      setShowLimitModal(true);
      return;
    }
    usersChose({
      isNew: true,
      status: true,
    });
    setClickedLink({ clickedLink: tab });
  };

  const handleChangePage = (page: number) => {
    usersChose({ status: false, choseAll: true });
    usersGetAll({ page, filters: { ...filters } });
    setActivePages(page);
  };

  const handleFilter = () => {
    usersChose({ status: false, choseAll: true });
    setShowFilters(false);
    usersGetAll({ page: 1, filters: { ...filters } });
  };

  const handleFilterCancel = () => {
    setFilters(defaultFilters);
    usersGetAll({ page: 1, filters: defaultFilters });
  };

  return (
    <ContentWrapper id="users_content_wrapper">
      <Content id="users_content">
        {!isAccessManagement && !isAdmin ? (
          <div className="Table-EmptyStatus">
            <p>{t("settings_access_control_denied")}</p>
          </div>
        ) : (
          <>
          <ContentHeader id="keys_content_header">
            <Header linkList={UsersNavigation} />
            {userStorage === USER_STORAGE.AZURE && (
              <Button
                id="button_sync_users_restore"
                onClick={usersSync}
                className={cnContentHeader("Margin")}
                icon="icon-generate"
                size={30}
                theme="primary-outline"
              />
            )}
            <Button
              id="button_add_user"
              className={cnContentHeader("Margin")}
              onClick={() => handleAddUser(1)}
              icon="icon-user-add"
              theme="primary-outline"
              size={30}
              isActive={chosen.length > 0 && chosen[0] === "new"}
            />
            <Button
              id="btn_show_filter_2"
              onClick={() => setShowFilters(!showFilters)}
              icon="icon-filter"
              theme="primary-outline"
              size={30}
              isActive={showFilters}
            />
          </ContentHeader>
          <div className={cnUsers()}>
            {userStorage === USER_STORAGE.LOCAL ? <TableUsersLocal /> : <TableUsers />}
          </div>
          {pagesCount > 1 && (
            <Pagination id="users_pagination" itemPagesCount={pagesCount} currentPage={currentPage} onChange={handleChangePage} />
          )}
          </>
        )}
      </Content>
      <Aside side="right" id="users_aside_right">
        {!isAccessManagement && !isAdmin ? null : (
          <>
          {showFilters && (
            <UsersFilter 
              handleFilterCancel={handleFilterCancel}
              setIsShow={setShowFilters}
              filters={filters}
              setFilters={setFilters}
              handleFilter={handleFilter}
              localUsers={userStorage === USER_STORAGE.LOCAL}
            />
          )}
          {chosen.length > 0 && !showFilters && <InfoBoxUser />}
          {chosen.length === 0 && !showFilters && (
            <div className={cnAsideContent()}>
              <div className={cnAsideContent("Header")}>
                <h2>{t("aside_right-Information")}</h2>
              </div>
              <div className={cnAsideContent("Description")}>
                <p>
                  {t("aside_right_text-Users")}<br />
                  {t("aside_right_text-Users_list")}<br />
                  {t("aside_right_text-Users_list2")}
                </p>
              </div>
              {license && isLimitAvailable ? (
                <div className={cnAsideContent("Total")}>
                  <div className={cnAsideContent("Total-Count")}>
                    <span className={cnAsideContent("Total-Count-Black")}>
                      {license.active_user}/{license.limit}
                    </span>{" "}
                    {t("nav-Users")}&nbsp;&nbsp;&nbsp;
                    {(license.active_user / license.limit) * 100 >= 90 && (
                      <a href={`https://portal.pass-securium.ch/${getUserLocale() === "de" ? "vertrag" : "en/contract"}`} target="_blank">
                        {t("users_link_upgrade_users_limits")}
                      </a>
                    )}
                  </div>
                </div>
              ) : (
                <div className={cnAsideContent("Total")}>
                  <div className={cnAsideContent("Total-Count")}>
                    <span>{totalItems}</span>
                    <p>{t("nav-Users")}</p>
                  </div>
                </div>
              )}

              {userStorage === USER_STORAGE.LOCAL && (
                <div className={cnAsideContent("Total")}>
                  <p>{t("table_title-Status")}:</p>
                  {itemStatus.map((item) => {
                    return (
                      <div key={item.status} className={cnAsideContent("Total-Count")}>
                        <div className={`${cnAsideContent("Total-Status")} ${cnAsideContent(`Total-Status-${item.status}`)}`}>
                          <span>{t(`table_user_local_status_${item.status}`)}</span>
                        </div>
                        <p className={cnAsideContent("Total-Status-Count")}>{countStatus(item.status)} {t("nav-Users")}</p>
                      </div>
                    )
                  })}
                </div>  
              )}

              {userStorage === USER_STORAGE.LOCAL && (
                <div className={cnAsideContent("Total")}>
                  <p>{t("table_title-2FA")}:</p>
                  <div className={cnAsideContent("Total-Count")}>
                      <div className="Field-FakeSwitch" />
                    <p className={cnAsideContent("Total-Status-Count")}>
                      {t("user_infobox_otp_global")}
                    </p>
                  </div>
                  <div className={cnAsideContent("Total-Count")}>
                    <div className="Field-FakeSwitch Field-FakeSwitch-Green" />
                    <p className={cnAsideContent("Total-Status-Count")}>
                      {t("user_infobox_otp_active")}
                    </p>
                  </div>
                  <div className={cnAsideContent("Total-Count")}>
                    <div className="Field-FakeSwitch Field-FakeSwitch-Gray" />
                    <p className={cnAsideContent("Total-Status-Count")}>
                      {t("user_infobox_otp_hidden")}
                    </p>
                  </div>
                </div> 
              )}

              {userStorage === USER_STORAGE.LOCAL && (
                <div className={cnAsideContent("Total")}>
                  <p>{t("table_title-Roles")}:</p>
                  {itemRoles.map((item) => {
                    return (
                      <div key={item.role} className={cnAsideContent("Total-Count")}>
                        <span className={cnAsideContent("Total-Count-Black")}>{mapRolesName[item.role]}</span>
                        <p>-&nbsp;&nbsp;{countRoles(item.role)} {t("nav-Users")}</p>
                      </div>
                    )
                  })}
                </div>
              )}

              <div className={cnAsideContent("Action")}>
                <Button id="button_add_user_action" onClick={() => handleAddUser(1)} icon="icon-user-add" width="100%">
                  {t("button_add-User")}
                </Button>
              </div>

              {userStorage === USER_STORAGE.AZURE && (
                <Button id="button_add_sync" className={cnGroups("Sync")} icon="icon-generate" theme="primary-outline" onClick={() => usersSync()}>
                  <span>{t("button-sync-users")}</span>
                </Button>
              )}
            </div>
          )}
          </>
        )}
      </Aside>
      {showLimitModal && isLimitAvailable && (
        <Modal handleClose={() => setShowLimitModal(false)} className={cnModal("UsersLimit")} isCloseable={false}>
          <div className={cnModal("Header")}>{t("modal_users_limit_header")}</div>
          <div className={cnModal("Scroll")}>{t("modal_users_limit_body")}</div>
          <div className={cnModal("Actions")}>
            <a
              className="btn btn-blue"
              href={`${getPortalLink(window.location.host)}/${getUserLocale() === "de" ? "vertrag" : "en/contract"}`}
              target="_blank"
            >
              {t("modal_users_limit_action_upgrade")}
            </a>
            <Button theme="primary-outline" onClick={() => setShowLimitModal(false)}>
              {t("modal_users_limit_action_close")}
            </Button>
          </div>
        </Modal>
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  settings: { license, userStorage, globalOtp },
  users: { chosen, currentPage, error, pagesCount, totalItems, list, usersCounter },
  user: { role },
  settingsTest: { accessControl: { settings: accessSettings } }
}: RootState) => ({
  chosen,
  currentPage,
  error,
  license,
  pagesCount,
  totalItems,
  userStorage,
  list,
  role,
  accessSettings,
  globalOtp,
  usersCounter,
});

const mapDispatchToProps = { setHeaderTitle, setHeaderIcon, usersGetAll, usersChose, setClickedLink, usersSync };

export default connect(mapStateToProps, mapDispatchToProps)(Users);
