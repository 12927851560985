/* eslint-disable @typescript-eslint/no-unused-vars, react/no-array-index-key, no-unused-vars  */

import React, { FC, useEffect, useState } from "react";
import { Icon, SearchInput } from "ss-ui";
import { cn } from "@bem-react/classname";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { PropsTableWithSearch, TableWithSearch } from "components/Table";

import { FORM_USER_MANAGEMENT, getUserFromListByID, typeGroup, typeUser } from "core";

import { FormValues } from "store/routines/users/manage";
import { RootState } from "store/reducers";
import { groupsGetAll } from "store/actions";
import { Payload as PayloadGroupsGetAll } from "store/routines/groups/getAll";
import { LoadingContainer } from "components/Generic";

const cnGroups = cn("InfoBoxUserGroups");

type Props = {
  chosen: string[];
  groupsGetAll: (payload: PayloadGroupsGetAll) => void;
  groupsList: typeGroup[];
  isLoadingGroup: boolean;
  list: typeUser[];
} & InjectedFormProps &
  PropsTableWithSearch &
  WrappedFieldArrayProps<any>;

const RenderGroupList: FC<Props> = ({ chosen, groupsGetAll, isLoadingGroup, groupsList, setSearch, searchCallback, list }) => {
  const isNew = chosen[0] === "new";
  const user: typeUser = getUserFromListByID(list, chosen[0]);

  const [checked, setChecked] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
      groupsGetAll({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setChecked(isNew || !(user.groups && user.groups.length > 0) ? [] : user.groups.map(({ id }: typeGroup) => id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {user.groups && user.groups.length !== 0 ? (
        <LoadingContainer isLoading={isLoadingGroup}>
          <div className={cnGroups("Header")}>{t("groups-List")}</div>
          <div className={cnGroups("Search")} id="div_search_input">
            <SearchInput onChange={setSearch} />
          </div>
          <div className={cnGroups("List")}>
            {groupsList.length !== 0 &&
              user.groups.filter(searchCallback).map((group, i) => {
                return (
                  <div key={i} className={cnGroups("UserItem")}>
                    <Icon name="icon-group-circle" width={20} height={20} />
                    <div title={group.name} className="name">{group.name}</div>
                  </div>
                );
              })}
          </div>
        </LoadingContainer>
      ) : (
        <p>{t("groups-List-Empty")}</p>
      )}
    </>
  );
};

const mapStateToProps = ({ groups: { list: groupsList, isLoading: isLoadingGroup }, users: { list, chosen } }: RootState) => ({
  chosen,
  groupsList,
  isLoadingGroup,
  list,
});

const mapDispatchToProps = { groupsGetAll };

export default compose<Props, Partial<Props>>(
  TableWithSearch,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_USER_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(RenderGroupList);
