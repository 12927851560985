import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { has, isEmpty, get } from "lodash";
import { Icon } from "ss-ui";

import { typeKey, FIELD_TYPE, copyTypeToClipboard, validateURL } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
};

const RowURI: FC<Props> = ({ item, field }) => {
  const { t } = useTranslation();

  if (!has(item, field) || isEmpty(get(item, field))) {
    return null;
  }

  const correctUrl: string = item[field].includes("//") ? item[field] : `http://${item[field]}`;

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Item")}>
        <div className={cnSettings("Value InfoBoxKeySettings-Value-Small")} id={`info_box_key_${field}`}>
          <span title={correctUrl}>{correctUrl}</span>
        </div>
        <div className={cnSettings("Actions InfoBoxKeySettings-Actions-Right")}>
          <a
            className="tooltip tooltip-left"
            data-tooltip={t("tooltip-show")}
            href={validateURL(correctUrl)}
            target="_blank"
            id="link_key_url"
          >
            <Icon name="icon-open" width={16} height={16} />
          </a>
          &nbsp;
          <div className="tooltip tooltip-left" data-tooltip={t("tooltip-copy")}>
            <Icon onClick={() => copyTypeToClipboard(correctUrl, field)} name="icon-copy" id={`btn_copy_to_clipboard_key_${field}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowURI;
