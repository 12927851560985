/* eslint-disable global-require */
import React, { FC } from "react";
import { cn } from "@bem-react/classname";

import LayoutAnonymous from "components/Layout/LayoutAnonymous/LayoutAnonymous";

import ForgotForm from "./ForgotForm";

export const cnLogin = cn("ForgotPassword");

const ForgotPassword: FC = () => {
  return (
    <LayoutAnonymous>
      <ForgotForm />
    </LayoutAnonymous>
  );
};

export default ForgotPassword;
