import Color from "color";
import React, { useState, useEffect } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { WrappedFieldProps } from "redux-form";
import { connect } from "react-redux";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

import { typeOption } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

const getInitialValue = (value: string | number, options: typeOption[]): typeOption | undefined => {
  if (typeof value === "object") {
    return value;
  }

  if (value && options.length > 0) {
    return options.find((obj: typeOption) => obj.value === value);
  }

  return undefined;
};

export const customSelectStyles = (options: { isMultiple?: boolean, required?: boolean } = { isMultiple: false, required: false }, mainColorApp: string = "#1A90BB") => ({
  control: (provided: any, state) => ({
    ...provided,
    border: options.required ? `1px solid ${mainColorApp}` : "0",
    borderWidth: "0",
    boxShadow: state.isFocused ? `0 5px 15px ${mainColorApp}` : "0 5px 15px rgba(0, 0, 0, 0.16)",
    fontSize: "14px",
    height: !options.isMultiple ? "40px" : "auto",
    minHeight: "40px",
    padding: "0 0 0 16px",
    boxSizing: "border-box",
    backgroundColor: "white",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    cursor: "pointer",
    color: mainColorApp,
  }),
  placeholder: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    border: `1px solid ${mainColorApp}`,
  }),
  menu: (provided: any, state) => ({
    ...provided,
    zIndex: 10,
    backgroundColor: "white",
    border: `0 solid ${mainColorApp}`,
    // color: state.i ? "red" : "blue",
  }),
  menuList: (provided: any) => ({
    ...provided,
    zIndex: 10,
    maxHeight: 200,
    backgroundColor: "white",
    borderRadius: "6px",
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 10,
    backgroundColor: "white",
  }),
});

export const SelectTheme = (mainColor) => (theme) => ({
  ...theme,
  borderRadius: 6,
  colors: {
    ...theme.colors,
    primary25: Color(mainColor).lighten(0.717).lighten(0.33313).hsl().string(),
    primary75: mainColor,
    primary50: Color(mainColor).lighten(0.717).lighten(0.33313).hsl().string(),
    primary: Color(mainColor).lighten(0.717).lighten(0.33313).hsl().string(),
    neutral0: "black",
  },
})

type Props = {
  className?: string;
  customize: SettingsCustomizeState;
  defaultSelect?: typeOption[];
  disabled?: boolean;
  isLoading?: boolean;
  isMultiple?: boolean;
  isSearchable?: boolean;
  label: string;
  menuPlacement?: "auto" | "bottom" | "top";
  name: string;
  options: typeOption[];
  placeholder?: string;
  required?: boolean;
  selectType: string;
  template?: (label: string, value: string) => JSX.Element;
} & WrappedFieldProps;

const FormSelect: React.FC<Props> = ({
  className = "",
  customize,
  defaultSelect,
  disabled,
  input,
  isLoading = false,
  isMultiple = false,
  isSearchable = false,
  label,
  menuPlacement = "auto",
  meta: { touched, error },
  name,
  options,
  placeholder = "",
  required = false,
  selectType = "",
  template,
}) => {
  const isError = touched && Boolean(error);

  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  const getOptionLabel = (option: any) => {
    switch (selectType) {
      case "group":
        return option.title;
      default:
        return option.label;
    }
  };

  const getOptionValue = (option: any) => {
    switch (selectType) {
      case "group":
        return option.dn;
      default:
        return option.value;
    }
  };

  const formatOptionLabel = ({ value, label }: any) => (template ? template(label, value) : label);

  return (
    <div className={`${cnField()} ${cnField("Select", { hasError: isError })} ${className}`} id={`input_select_${input.name}_container`}>
      {label && (
        <span className="Label" id={`input_select_${input.name}_label`}>
          {label}
        </span>
      )}
      <Select
        className={className}
        classNamePrefix={className}
        closeMenuOnSelect={!isMultiple}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={false}
        id={`input_select_${input.name}`}
        isClearable={isMultiple}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMultiple}
        menuPlacement={menuPlacement}
        isOptionDisabled={option => (option.isDisabled !== undefined ? option.isDisabled : false)}
        isSearchable={isMultiple || isSearchable}
        name={name}
        onBlur={(option: any) => ((option as typeOption).value ? input.onChange((option as typeOption).value) : null)}
        onChange={(option: SingleValue<typeOption> | MultiValue<typeOption>) => input.onChange((option as typeOption).value)}
        options={options}
        defaultValue={defaultSelect}
        placeholder={placeholder}
        styles={customSelectStyles({ isMultiple, required }, mainColor)}
        value={getInitialValue(input.value, options)}
        theme={SelectTheme(mainColor)}
      />
      {isError && (
        <span id={`input_select_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormSelect);
