import { Action } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";

import { http } from "core";
import { get } from "lodash";

export interface Payload {
  hash: string;
}

export const action: Routine = createRoutine("OTP_HASH_ACTIVATION");

const OTPHashActivation = ({ hash }: Payload) => {
  return http({
    method: "GET",
    route: `check/otp-hash-activation/${hash}`,
    isProtected: false,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response: string = yield call(OTPHashActivation, payload);
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(get(error, "response.data.detail")));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
