import { createRoutine, Routine } from "redux-saga-routines";
import { Action } from "redux-actions";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("SET_BESTICONCHEK");

export type Payload = {
  isCancel: boolean;
};

const setBestIconCheck = () => {
  return http({
    route: "key/logos/generate",
    method: "POST",
  });
};

function* handler({ payload: { isCancel = false } }: Action<Payload>) {
  try {
    if (!isCancel) {
      yield call(setBestIconCheck);
    }
    yield put(action.success());
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
