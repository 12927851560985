import { all, fork } from "redux-saga/effects";

import { saga as confirmOTPActivation } from "store/routines/user/confirmOTPActivation";
import { saga as closeSession } from "store/routines/user/closeSession";
import { saga as closeSessions } from "store/routines/user/closeSessions";
import { saga as deleteYubikey } from "store/routines/user/deleteYubikey";
import { saga as getAvatar } from "store/routines/user/getAvatar";
import { saga as getProfile } from "store/routines/user/getProfile";
import { saga as getQRCode } from "store/routines/user/getQRCode";
import { saga as getRegisterYubikey } from "store/routines/user/getRegisterYubikey";
import { saga as getSessions } from "store/routines/user/getSessions";
import { saga as init } from "store/routines/user/init";
import { saga as installPassword } from "store/routines/user/installPassword";
import { saga as login } from "store/routines/user/login";
import { saga as loginAzure } from "store/routines/user/loginAzure";
import { saga as loginYubikey } from "store/routines/user/loginYubikey";
import { saga as logout } from "store/routines/user/logout";
import { saga as OTPHashActivation } from "store/routines/user/OTPHashActivation";
import { saga as resetAdminPassword } from "store/routines/user/resetAdminPassword";
import { saga as resetPassword } from "store/routines/user/resetPassword";
import { saga as setAvatar } from "store/routines/user/setAvatar";
import { saga as setUnsubscribe } from "store/routines/user/setUnsubscribe";
import { saga as updateOTP } from "store/routines/user/updateOTP";
import { saga as updateYubikeyEnable } from "store/routines/user/updateYubikeyEnable";

export default function* user() {
  return yield all([
    fork(confirmOTPActivation),
    fork(closeSession),
    fork(closeSessions),
    fork(deleteYubikey),
    fork(getAvatar),
    fork(getProfile),
    fork(getQRCode),
    fork(getRegisterYubikey),
    fork(getSessions),
    fork(init),
    fork(installPassword),
    fork(login),
    fork(loginAzure),
    fork(loginYubikey),
    fork(logout),
    fork(OTPHashActivation),
    fork(resetAdminPassword),
    fork(resetPassword),
    fork(setAvatar),
    fork(setUnsubscribe),
    fork(updateOTP),
    fork(updateYubikeyEnable),
  ]);
}
