/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnContentWrapper } from "components/Layout";

const ContentWrapper: FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <div className={cnContentWrapper()} {...props}>
      {children}
    </div>
  );
};

export default ContentWrapper;
