import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import { http, typeLoginResponse, MainRoutes } from "core";

import i18n from "locales/i18n";

export interface Payload {
  password: string;
  token: string;
}

export const action: Routine = createRoutine("USER_INSTALL_PASSWORD");

const installPassword = ({ password, token }: Payload) => {
  return http({
    method: "POST",
    payload: {
      password: {
        first: password,
        second: password,
      },
    },
    route: `confirmation/${token}`,
    isProtected: false,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response: typeLoginResponse = yield call(installPassword, payload);

    toast.success(i18n.t("install_password_success_message"));

    yield put(action.success(response));

    yield put(push(MainRoutes.Login));
  } catch (error) {
    yield put(action.failure());
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
