import React, { FC, useEffect, useState, useMemo } from "react";
import { Button, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { InfoBoxGroup } from "components/InfoBox";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "store/reducers";
import { Header } from "components/Navigation";
import { Aside, cnContentHeader, cnAsideContent, Content, ContentWrapper, ContentHeader } from "components/Layout";
import {
  GROUP_STORAGE,
  PayloadChoseFunction,
  typeGroup,
  UsersNavigation,
  IGroupsFilter,
  typeFolder,
  ROLE_TYPE,
  typeAccessControl,
  getAccessControl,
} from "core";
import { groupsChose, groupsGetAll, setClickedLink, setHeaderIcon, setHeaderTitle, syncGroup } from "store/actions";
import { Payload as PayloadSetClickedLink } from "store/routines/settings/setClickedLink";
import { Payload as PayloadGroupsGetAll } from "store/routines/groups/getAll";

import TableGroups from "./TableGroups";
import GroupsFilter from "./GroupsFilter";

export const cnGroups = cn("Groups");

type Props = {
  chosen: string[];
  groupStorage: GROUP_STORAGE;
  list: typeGroup[];
  listFolders: typeFolder[];
  role: ROLE_TYPE;
  accessSettings?: typeAccessControl;
  groupsCounter: boolean;
  groupsChose: (payload: PayloadChoseFunction) => void;
  groupsGetAll: (payload: PayloadGroupsGetAll) => void;
  syncGroup: () => void;
  setClickedLink: (payload: PayloadSetClickedLink) => void;
  setHeaderTitle: (payload: string | undefined) => void;
  setHeaderIcon: (payload: string | undefined) => void;
};

const Groups: FC<Props> = ({
  chosen,
  groupStorage,
  list,
  listFolders,
  role,
  accessSettings,
  groupsCounter,
  groupsChose,
  groupsGetAll,
  setHeaderTitle,
  setHeaderIcon,
  setClickedLink,
  syncGroup,
}) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<IGroupsFilter>({
    search: "",
    folders: [],
    users: [],
  });

  const [countRequests, setCountRequests] = useState<number>(0);

  const isAccessManagement: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessManagement, role), [role, accessSettings]);
  const isAdmin: boolean = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);

  useEffect(() => {
    if (chosen.length > 0) {
      setShowFilters(false);
    }
  }, [chosen]);

  useEffect(() => {
    if (groupsCounter) {
        setCountRequests(countRequests + 6000);
        setTimeout(handleSyncGroup, 6000);
      }
  }, [groupsCounter]);

  useEffect(() => {
    groupsGetAll({ filters });
    setHeaderTitle(t("navigation_bottom-Users & Groups"));
    setHeaderIcon("group");
  }, []);

  const handleAddGroup = (tab: number) => {
    groupsChose({
      isNew: true,
      status: true,
    });
    setClickedLink({ clickedLink: tab });
  };

  const handleSyncGroup = () => {
    syncGroup();
  };

  useEffect(() => () => groupsChose({ status: false, choseAll: true }), []);

  const handleFilter = () => {
    groupsChose({ status: false, choseAll: true });
    setShowFilters(false);
    groupsGetAll({ filters: { ...filters } });
  };

  const handleFilterCancel = () => {
    setFilters({ search: "", folders: [], users: [] });
    groupsGetAll({
      filters: {
        search: "",
        users: [],
        folders: [],
      },
    });
  };

  return (
    <ContentWrapper id="groups_content_wrapper">
      <Content id="groups_content">
        {!isAccessManagement && !isAdmin ? (
          <div className="Table-EmptyStatus">
            <p>{t("settings_access_control_denied")}</p>
          </div>
        ) : (
          <>
            <ContentHeader id="keys_content_header">
              <Header linkList={UsersNavigation} />
              {groupStorage === GROUP_STORAGE.LOCAL ? null : (
                <Button
                  id="button_sync_restore"
                  onClick={handleSyncGroup}
                  className={cnContentHeader("Margin")}
                  icon="icon-generate"
                  size={30}
                  theme="primary-outline"
                />
              )}
              <Button
                id="button_add_group"
                className={cnContentHeader("Margin")}
                onClick={() => handleAddGroup(1)}
                icon="icon-group-add"
                theme="primary-outline"
                size={30}
                isActive={chosen.length > 0 && chosen[0] === "new"}
              />
              <Button
                onClick={() => setShowFilters(!showFilters)}
                id="btn_show_filter_2"
                icon="icon-filter"
                size={30}
                theme="primary-outline"
                isActive={showFilters}
              />
            </ContentHeader>
            <div className={cnGroups()}>
              <TableGroups />
            </div>
          </>
        )}
      </Content>
      <Aside side="right" id="groups_aside_right">
        {!isAccessManagement && !isAdmin ? null : (
          <>
            {showFilters && (
              <GroupsFilter
                handleFilterCancel={handleFilterCancel}
                setIsShow={setShowFilters}
                filters={filters}
                setFilters={setFilters}
                handleFilter={handleFilter}
                listFolders={listFolders}
              />
            )}
            {chosen.length > 0 && !showFilters && <InfoBoxGroup />}
            {chosen.length === 0 && !showFilters && (
              <div className={cnAsideContent()}>
                <div className={cnAsideContent("Header")}>
                  <h2>{t("aside_right-Information")}</h2>
                </div>
                <div className={cnAsideContent("Description")}>
                  <p>{t("aside_right_text-Groups")}</p>
                </div>
                <div className={cnAsideContent("Total")}>
                  <p>{t("form_input-Asses")}:</p>
                  <ul className={cnAsideContent("Total-List")}>
                    <li>
                      <Icon name="colored/icon-folder-access-write" width={20} />
                      {t("groups-write")}
                    </li>
                    <li>
                      <Icon name="colored/icon-folder-access-read" width={20} />
                      {t("groups-read")}
                    </li>
                    <li>
                      <Icon name="colored/icon-folder-access-no_access" width={20} />
                      {t("groups-no_access")}
                    </li>
                  </ul>
                </div>
                <div className={cnAsideContent("Total")}>
                  <div className={cnAsideContent("Total-Count")}>
                    <span>{list.length}</span>
                    <p>{t("table_title-Groups")}</p>
                  </div>
                </div>
                <div className={cnAsideContent("Action")}>
                  <Button id="button_add_group" onClick={() => handleAddGroup(1)} icon="icon-group-add">
                    {t("button_add-Group")}
                  </Button>

                  {groupStorage !== GROUP_STORAGE.LOCAL && (
                    <Button
                      id="button_add_sync"
                      className={cnGroups("Sync")}
                      icon="icon-generate"
                      theme="primary-outline"
                      onClick={handleSyncGroup}
                    >
                      <span>{t("button-sync-group")}</span>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  settings: { groupStorage },
  groups: { list, chosen, groupsCounter },
  folders: { list: listFolders },
  user: { role },
  settingsTest: { customize, accessControl: { settings: accessSettings } }
}: RootState) => ({
  chosen,
  groupStorage,
  list,
  listFolders,
  role,
  accessSettings,
  groupsCounter
});

const mapDispatchToProps = { setHeaderTitle, setHeaderIcon, groupsGetAll, groupsChose, setClickedLink, syncGroup };

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
