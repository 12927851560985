import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { CONVEYANCE_ACTION, CONVEYANCE_PRODUCTS } from "pages/Settings/SettingsType";
import { cnSettings } from "pages/Settings";

import ConveyanceMenu from "./ConveyanceMenu";
import ConveyanceModal from "./ConveyanceModal";

type Props = {
  accessImport: boolean;
  accessExport: boolean;
  isAdmin: boolean;
};

const Conveyance: FC<Props> = ({
  accessImport,
  accessExport,
  isAdmin
}) => {
  const { t } = useTranslation();

  const [action, setAction] = useState<CONVEYANCE_ACTION>(CONVEYANCE_ACTION.EXPORT);
  const [product, setProduct] = useState<CONVEYANCE_PRODUCTS>(CONVEYANCE_PRODUCTS.PS);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOpenModal = (exportAction: CONVEYANCE_ACTION, productType: CONVEYANCE_PRODUCTS) => {
    setAction(exportAction);
    setProduct(productType);
    setShowModal(true);
  };

  return (
    <>
      <div className={cnSettings("Container")}>
        <div className={cnSettings("Header")}>{t("settings_header_addition_option")}</div>
        <div className={cnSettings("Hint")}>
          <Trans i18nKey="settings_hint_addition_option">
            <a href="https://www.lastpass.com/" target="_blank">
              LastPass
            </a>
          </Trans>
        </div>
        <div className={cnSettings("ButtonGroup")}>
          {accessImport || isAdmin ? <ConveyanceMenu onClickMenu={handleOpenModal} type={CONVEYANCE_ACTION.IMPORT} /> : null}
          {accessExport || isAdmin ? <ConveyanceMenu onClickMenu={handleOpenModal} type={CONVEYANCE_ACTION.EXPORT} /> : null}
        </div>
      </div>
      {showModal && action && product && <ConveyanceModal action={action} product={product} handleClose={setShowModal} />}
    </>
  );
};

export default Conveyance;
