import React, { Component, ErrorInfo, ReactNode } from "react";
import { Link, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

export const cnErrorBoundary = cn("ErrorBoundary");

type Props = {
  children: ReactNode;
} & WithTranslation;

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Do something, example show error in console
    // console.error("Uncaught error:", error, errorInfo);
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  public render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <div className={cnErrorBoundary()} id="app_error_boundary_container">
          <div className={cnErrorBoundary("Box")}>
            <div className={cnErrorBoundary("Header")}>
              <p>{t("error_boundary_warning")}</p>
            </div>
            <div className={cnErrorBoundary("Icon")}>
              <div className={cnErrorBoundary("Warning")}>
                <Icon name="icon-warning" width={50} height={50} fill="#F0A600"/>
              </div>
              <p>
                {t("error_boundary_text1")}
                <br />
                {t("error_boundary_text2")}
              </p>
            </div>
            <div className={cnErrorBoundary("Action")}>
              <Link href="/" id="app_error_boundary_link">
                {t("error_boundary_button")}
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
