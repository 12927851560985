import React, { FC, useEffect } from "react";
import { Icon } from "ss-ui";
import { NavLink } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";

import { Routes } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";
import { SettingsHeaderState } from "store/reducers/settings/header";
import { favoritesGet } from "store/actions";

import HeaderSearch from "./Fragments/HeaderSearch";
import Profile from "./Fragments/Profile";

const cnHeader = cn("Header");

type Props = {
  customize: SettingsCustomizeState;
  favoriteCount: number;
  favoritesGet: () => void;
  header: SettingsHeaderState;
  username: string | undefined;
};

const Header: FC<Props> = ({ header, customize, favoriteCount, favoritesGet, username }) => {
  useEffect(() => {
    favoritesGet();
  }, [favoritesGet]);

  return (
    <header className={cnHeader()} id="page_header">
      <div className={cnHeader("Content")} id="page_header_content">
        <div className={cnHeader("Logo")} id="page_header_logo">
          {customize.logo ? <img src={customize.logo} alt="Logo" /> : <Icon name="app/pass-logo-horizontal" />}
        </div>

        <div className={cnHeader("Info")} id="page_header_info">
          <div className={cnHeader("PageTitle")} id="page_header_title_icon">
            <div className={cnHeader("PageTitle-Icon")} id="page_header_title_icon">
              {header.icon && <Icon width={25} height={25} name={`icon-${header.icon}`} />}
            </div>
            <div className={cnHeader("PageTitle-Name")} title={header.title || ""} id="page_header_title_name">
              {header.title || ""}
            </div>
          </div>
          <HeaderSearch />

          <NavLink 
            className={cnHeader("Favorite")} 
            to={Routes.Favorites} 
            id="page_header_right_favorite_link"
            onClick={() => {
              const layoutElement = document.querySelector(".Layout-Container");
                if (layoutElement) {
                  layoutElement.scrollTo({ top: 0, left: 0 });
              }
            }}
          >
            <Icon width={25} height={25} name={favoriteCount > 0 ? "icon-star-fill" : "icon-star"} />
            <div className={cnHeader("Favorite-Badge")} id="page_header_right_favorite_count">
              {favoriteCount}
            </div>
          </NavLink>
        </div>

        <Profile title={username} />
      </div>
    </header>
  );
};

const mapStateToProps = ({ favorites: { count }, user: { username }, settingsTest: { header, customize } }: RootState) => ({
  header,
  customize,
  favoriteCount: count,
  username,
});

const mapDispatchToProps = { favoritesGet };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
