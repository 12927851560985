/* eslint-disable react/jsx-no-target-blank, react/self-closing-comp */
import React, { FC, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { compose } from "recompose";
import { Checkbox, Icon } from "ss-ui";
import { useParams } from "react-router";
import { isSafari } from "react-device-detect";
import { cn } from "@bem-react/classname";

import { ButtonDiv, LoadingContainer, KeysActions } from "components/Generic";
import { cnTable, PropsTableWithSort, TableHeaderElement, TableWithSort, typeTableHeaderElement } from "components/Table";

import {
  DATETIME_FORMAT_LITE,
  FOLDER_ACCESS,
  FOLDER_TYPE,
  PayloadChoseFunction,
  ROLE_TYPE,
  Routes,
  clearUrlParam,
  getKey,
  getKeyFromArrayByID,
  getTemplateIcon,
  parseDate,
  replaceUrlParam,
  typeFolder,
  typeKey,
  validateURL,
  getKeyAnnotation,
} from "core";

import { PropsWithChosen, WithChosen } from "hoc";

import { favoritesSetKey, keyChose } from "store/actions";
import { RootState } from "store/reducers";
import { Payload as PayloadFavoritesSetKey } from "store/routines/favorites/setKey";

import { tableHeader } from "../TableConfig";

export const cnKeysList = cn("KeysList");

interface iParams {
  folderID: string;
}

type Props = PropsWithChosen &
PropsTableWithSort & {
  chosen: string[];
  favoritesSetKey: (payload: PayloadFavoritesSetKey) => void;
  folder?: typeFolder;
  isLoading: boolean;
  isLoadingFolder: boolean;
  isLoadingFavorites: boolean;
  keyChose: (payload: PayloadChoseFunction) => void;
  list: typeKey[];
  role: ROLE_TYPE;
};

const KeysList: FC<Props> = ({
  chosen,
  favoritesSetKey,
  folder,
  handleChosenAllItems,
  handleSort,
  handleUpdateChosenItems,
  isLoading,
  isLoadingFolder,
  isLoadingFavorites,
  keyChose,
  list,
  role,
  sortBy,
  sortCallback,
  sortDirection,
}) => {
  const { t } = useTranslation();
  const { folderID } = useParams<iParams>();

  useEffect(() => {
    handleChosenAllItems(keyChose, false, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFavorite = (folderID: string, keyID: string) => {
    favoritesSetKey({
      folderID,
      keyID,
    });
  };

  const handleChosen = (id: string) => {
    handleUpdateChosenItems(keyChose, !(chosen.indexOf(id) >= 0), id, true);
  };

  /**
   * Check item by id, and verify
   * @param {string} id - key id
   * @param {boolean} status - check or uncheck
   * @param {boolean} isSharing - is key share to this folder
   */
  const handleChosenItem = ({ id, status, isSharing }) => {
    if (isSharing) {
      return handleUpdateChosenItems(keyChose, status, id, true);
    }

    // if chosen keys not empty, remove sharing key from chosen list
    if (chosen.length !== 0) {
      chosen.forEach(keyID => {
        const { id, mainFolderId } = getKeyFromArrayByID(list, keyID) as typeKey;
        if (folderID !== mainFolderId) {
          toast.info(t("toast_info_check_only_own_key"), { toastId: "CHECK_INFO_ONLY_OWN_KEY" });
          handleUpdateChosenItems(keyChose, false, id);
        }
      });
    }

    return handleUpdateChosenItems(keyChose, status, id);
  };

  const handleChosenItems = (status: boolean) => {
    // if chosen keys not empty, remove sharing key from chosen list
    handleChosenAllItems(
      keyChose,
      status,
      list && list.length > 0 ? list.filter(({ mainFolderId }: typeKey) => folderID === mainFolderId).map(({ id }: typeKey) => id) : [],
    );
  };

  const themeCheckAll = chosen.length < (list ? list.length : 0) && chosen.length > 0 ? "squre" : "";

  return (
    <div className={ cnKeysList() }>
      <LoadingContainer isLoading={ isLoading || isLoadingFavorites || isLoadingFolder }>
        {(folder && folder.access === FOLDER_ACCESS.NO_ACCESS) ||
        (role === ROLE_TYPE.ADMIN && folder && folder.type === FOLDER_TYPE.GROUP) ? (
            <>
              {role === ROLE_TYPE.ADMIN ? (
                <div className={ cnTable("Empty") }>
                  <Icon
                    fill="#A7A7A8"
                    name="icon-lock-close"
                  />
                  <p>{t("key-page-access-admin")}</p>
                </div>
              ) : (
                <div className={ cnTable("Empty") }>
                  <Icon
                    fill="#A7A7A8"
                    name="icon-lock-close"
                  />
                  <p>{t("key-page-access")}</p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className={ cnTable("Head Table-Key") }>
                <div className={ cnTable("Head-Th") }>
                  <Checkbox
                    checked={ chosen.length > 0 && chosen[0] !== "new" }
                    id="checkbox_check_all"
                    theme={ themeCheckAll }
                    onChange={ handleChosenItems }
                  />
                </div>
                {tableHeader.map(({ title, ...data }: typeTableHeaderElement, index: number) => (
                  <TableHeaderElement
                    key={ title }
                    handleSort={ handleSort }
                    id={ getKey(title, index) }
                    sortBy={ sortBy }
                    sortDirection={ sortDirection }
                    { ...data }
                  >
                    {t(`table_title-${title}`)}
                  </TableHeaderElement>
                ))}
              </div>
              <div className={ cnTable() }>
                <div className={ cnTable("Body") }>
                  {list.length > 0 &&
                  list
                    .sort(sortCallback)
                    .map(
                      (
                        {
                          qr_barcode = undefined,
                          encryptionEnabled,
                          favorite,
                          folders,
                          id,
                          logo,
                          mainFolderId,
                          sealing,
                          template,
                          title,
                          updatedAt,
                          data,
                          uri,
                        }: typeKey,
                        index,
                      ) => {
                        let correctUrl: string = "";
                        const isKeySharing = folderID !== mainFolderId;
                        const {
                          card_pin = "",
                          login = "",
                          password = "",
                          card_number = "",
                          client_number = "",
                          doc_number = "",
                          serial_number = "",
                          hostname_ip = "",
                        } = JSON.parse(data);

                        if (uri) {
                          correctUrl = uri.includes("//") ? uri : `http://${uri}`;
                        }

                        return (
                          <ButtonDiv
                            key={ id }
                            className={ cnTable("Row", { chosen: chosen.indexOf(id) >= 0 }) }
                            id={ getKey("btn_key_chosen", index) }
                            onClick={ () => handleChosen(id) }
                          >
                            <ButtonDiv
                              className={ cnKeysList("Checkbox Table-Th") }
                              id={ getKey("btn_key_chosen_checkbox", index) }
                              onClick={ e => e.stopPropagation() }
                            >
                              <Checkbox
                                checked={ chosen.indexOf(id) >= 0 }
                                onChange={ (status: boolean) =>
                                  handleChosenItem({
                                    id,
                                    status,
                                    isSharing: isKeySharing,
                                  })
                                }
                              />
                            </ButtonDiv>
                            <div className={ cnKeysList("Type Table-Th") }>
                              {logo ? (
                                <div className={ cnKeysList("Logo") }>
                                  <img
                                    alt=""
                                    className={ cnKeysList("Logo-Image") }
                                    src={ logo }
                                  />
                                  <div className={ cnKeysList("Logo-Icon") }>
                                    <Icon
                                      name={ getTemplateIcon(template) }
                                      width={ 9 }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className={ cnKeysList("Logo Logo-Mask") }>
                                  <p className={ cnKeysList("Logo-Text") }>{getKeyAnnotation(title)}</p>
                                  <div className={ cnKeysList("Logo-Icon") }>
                                    <Icon
                                      name={ getTemplateIcon(template) }
                                      width={ 9 }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={ cnKeysList("Title Table-Th") }>
                              <div className={ cnKeysList("Title-Column") }>
                                <p title={ title }>{title}</p>
                                <span title={ uri }>{uri}</span>
                              </div>
                              <div className={ cnKeysList("Title-Icon") }>
                                {folders.length > 0 && (
                                  <Icon
                                    className={ cnKeysList("Sharing", { isSharing: !isKeySharing }) }
                                    height={ 16 }
                                    name="icon-shared"
                                    width={ 16 }
                                  />
                                )}
                                {sealing && role === ROLE_TYPE.MANAGER && (
                                  <Icon
                                    className={ cnKeysList("Sealing") }
                                    height={ 16 }
                                    name="icon-sealing"
                                    width={ 16 }
                                  />
                                )}
                                {!isKeySharing && (
                                  <button
                                    className={
                                      isLoadingFavorites ? "KeysList-Action-Favorite" : "KeysList-Action-Favorite tooltip tooltip-left"
                                    }
                                    data-tooltip={ favorite ? t("tooltip_remove-favorite") : t("tooltip_add-favorite") }
                                    id={ getKey("btn_favorite_key", index) }
                                    type="button"
                                    onClick={ e => {
                                      e.stopPropagation();
                                      handleFavorite(mainFolderId, id);
                                    } }
                                  >
                                    <Icon
                                      height={ 16 }
                                      name={ favorite ? "icon-star-fill" : "icon-star" }
                                      width={ 16 }
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className={ cnKeysList("Date Table-Th") }>{moment(parseDate(updatedAt)).format(DATETIME_FORMAT_LITE)}</div>
                            <div className={ cnKeysList("Action Table-Th") }>
                              <ButtonDiv
                                className={ cnKeysList("Action-Button", { chosen: chosen.indexOf(id) >= 0 }) }
                                id={ getKey("btn_stop_propagation", index + 1) }
                                onClick={ e => e.stopPropagation() }
                              >
                                <Icon
                                  height={ 16 }
                                  name="icon-actions"
                                  width={ 16 }
                                />
                              </ButtonDiv>
                              <ButtonDiv
                                className={ cnKeysList("Action-Row", { chosen: chosen.indexOf(id) >= 0 }) }
                                id={ getKey("tooltip_container", index + 1) }
                                onClick={ e => e.stopPropagation() }
                              >
                                {folder && (
                                  <>
                                    {![FOLDER_ACCESS.READ, FOLDER_ACCESS.NO_ACCESS].includes(folder.access) && !isKeySharing && (
                                      <Link
                                        className="KeysList-Action-Item tooltip tooltip-left"
                                        data-tooltip={ t("tooltip-edit") }
                                        id={ getKey(`link_to_manage_key_${title}`) }
                                        to={ replaceUrlParam(replaceUrlParam(Routes.ManageKey, "id", id), "folderID", folder.id) }
                                      >
                                        <Icon
                                          height={ 16 }
                                          name="icon-pencil"
                                          width={ 16 }
                                        />
                                      </Link>
                                    )}
                                  </>
                                )}
                                {correctUrl ? (
                                  <a
                                    className="KeysList-Action-Item tooltip tooltip-left"
                                    data-tooltip={ t("tooltip-show") }
                                    href={ validateURL(correctUrl) }
                                    target="_blank"
                                  >
                                    <Icon
                                      height={ 16 }
                                      name="icon-open"
                                      width={ 16 }
                                    />
                                  </a>
                                ) : null}

                                {/* {qr_barcode && (
                                  <KeysActionsImage
                                    value={id}
                                    tooltipName="keys_copy_card_pin"
                                  />
                                )} */}

                                {!isSafari && card_pin && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    iconName="icon-copy"
                                    toastName="toast_card_pin_success"
                                    tooltipName="keys_copy_card_pin"
                                    value={ card_pin }
                                  />
                                )}

                                {!isSafari && hostname_ip && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    iconName="icon-copy"
                                    toastName="toast_hostname_ip_success"
                                    tooltipName="keys_copy_hostname_ip"
                                    value={ hostname_ip }
                                  />
                                )}

                                {!isSafari && login && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    iconName="icon-copy-login"
                                    toastName="toast_login_copy"
                                    tooltipName="tooltip-login"
                                    value={ login }
                                  />
                                )}

                                {!isSafari && password && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    iconName="icon-copy-pass"
                                    toastName="toast_password_copy"
                                    tooltipName="tooltip-pass"
                                    value={ password }
                                  />
                                )}

                                {!isSafari && card_number && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    toastName="toast_card_number_success"
                                    tooltipName="keys_copy_card_number"
                                    value={ card_number }
                                  />
                                )}

                                {!isSafari && client_number && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    toastName="toast_client_number_success"
                                    tooltipName="keys_copy_client_number"
                                    value={ client_number }
                                  />
                                )}

                                {!isSafari && doc_number && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    toastName="toast_doc_number_success"
                                    tooltipName="keys_copy_doc_number"
                                    value={ doc_number }
                                  />
                                )}

                                {!isSafari && serial_number && (
                                  <KeysActions
                                    encryptionEnabled={ encryptionEnabled }
                                    toastName="toast_serial_number_success"
                                    tooltipName="keys_copy_serial_number"
                                    value={ serial_number }
                                  />
                                )}
                              </ButtonDiv>
                            </div>
                          </ButtonDiv>
                        );
                      },
                    )}
                  {!isLoading && list.length === 0 && (
                    <div className={ cnTable("Empty") }>
                      {folder && folder.access === FOLDER_ACCESS.WRITE ? (
                        <Icon
                          fill="#A7A7A8"
                          name="icon-key-add"
                        />
                      ) : null}
                      <p>{t("key-No-keys")}</p>
                      {folder && folder.access === FOLDER_ACCESS.WRITE ? (
                        <Link
                          id={ getKey("link_to_add_key") }
                          to={ clearUrlParam(replaceUrlParam(Routes.ManageKey, "folderID", folder.id)) }
                        >
                          {t("button_add-Key")}
                        </Link>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
      </LoadingContainer>
    </div>
  );
};

const mapStateToProps = ({
  favorites: { isLoading: isLoadingFavorites },
  keys: { chosen, list, isLoading },
  folders: { isLoading: isLoadingFolder },
  user: { role },
}: RootState) => ({
  chosen,
  isLoading,
  isLoadingFavorites,
  isLoadingFolder,
  list,
  role,
});

const mapDispatchToProps = {
  keyChose,
  favoritesSetKey,
};

export default compose<Props, Partial<Props>>(WithChosen, TableWithSort, connect(mapStateToProps, mapDispatchToProps))(KeysList);
