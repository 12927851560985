/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from "react";

import { getKey } from "core";

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ButtonDiv: FC<IProps> = ({ children, id, ...props }) => {
  return (
    <div id={id ? `btn_div_${id}` : getKey(`btn_div_${children}`)} onKeyPress={() => ({})} role="button" tabIndex={-1} {...props}>
      {children}
    </div>
  );
};

export default ButtonDiv;
