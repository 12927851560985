/* eslint-disable react/no-danger */
import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { typeKey, FIELD_TYPE, getFieldValue } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
};

const RowRichText: FC<Props> = ({ item, field }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>();

  useEffect(() => {
    getFieldValue(field, item).then(setValue);
  }, [item[field]]);

  if (!value) {
    return null;
  }

  if (!value || value === "<p><br></p>") {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Item InfoBoxKeySettings-Item-Description")}>
        <p dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    </div>
  );
};

export default RowRichText;
