import React, { FC, useState } from "react";
import { Button } from "ss-ui";
import { ImageWatcher } from "components/Generic";

type Props = {
  value: string;
};

const KeysActionsImage: FC<Props> = ({
  value,
}) => {

  const [isOpenModalImage, setIsOpenModalImage] = useState<boolean>(false);

  return (
    <>
      <Button
        className="OpenImage"
        icon="icon-eye"
        onClick={() => setIsOpenModalImage(!isOpenModalImage)}
        theme="primary-link"
      />
      {isOpenModalImage && (
        <ImageWatcher
          imageUrl={value}
          handleClose={() => setIsOpenModalImage(false)}
        />
    )}
    </>
  );
};

export default KeysActionsImage;
