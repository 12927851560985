import React from "react";

import { FIELD_TYPE, typeTemplate, typeKey, typeFolder } from "core";

export type FieldProps = {
  handleChanged?: (field: FIELD_TYPE, value: any) => void;
};

export function isFieldShow(field: FIELD_TYPE, template?: typeTemplate) {
  if (!template) return false;
  return template.fields.indexOf(field) >= 0;
}

interface IFieldContext {
  formData?: any;
  key?: typeKey;
  folder?: typeFolder;
  handleChange: (field: FIELD_TYPE, value: any) => void;
}

export const FieldContext = React.createContext<IFieldContext>({
  handleChange: () => {},
});
