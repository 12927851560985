import { Action } from "redux-actions";

import { IActivityLogFiltersData, IActivityLogFiltersUsersData, IActivityLogRecord } from "core";

import { ActivityLogGetAll, ActivityLogChose, ActivityLogGetFilters } from "store/actions";

export interface State {
  isLoading: boolean;
  currentPage: number;
  pageSize: number;
  pagesCount: number;
  totalItems: number;
  results: IActivityLogRecord[];
  chosen: string[];
  filters: IActivityLogFiltersData[];
  users: IActivityLogFiltersUsersData[];
}

const initialState: State = {
  isLoading: false,
  currentPage: 1,
  pageSize: 1,
  pagesCount: 1,
  totalItems: 0,
  results: [],
  chosen: [],
  filters: [],
  users: [],
};

export default function activityLog(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case ActivityLogGetAll.TRIGGER:
    case ActivityLogGetFilters.TRIGGER:
      return {
        ...state,
        chosen: [],
        isLoading: true,
      };

    case ActivityLogGetAll.SUCCESS:
    case ActivityLogGetFilters.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...payload,
      };

    case ActivityLogChose.SUCCESS: {
      let activityLog: string[];

      if (payload.oneOnly && payload.status) {
        return {
          ...state,
          chosen: [payload.id],
        };
      }

      if (payload.status) {
        activityLog = payload.choseAll ? [...payload.ids] : [...state.chosen, payload.id];
      } else {
        activityLog = payload.choseAll ? [] : [...state.chosen.filter(id => payload.id !== id)];
      }

      return {
        ...state,
        chosen: activityLog,
      };
    }

    case ActivityLogGetAll.FULFILL:
    case ActivityLogGetFilters.FULFILL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
