import React, { FC, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { Button, Icon } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { get as lodashGet } from "lodash";
import { logout } from "store/actions";

import { LoadingContainer } from "components/Generic";
import { cnField } from "components/Form";
import { customSelectStyles, SelectTheme } from "components/Form/Select";

import { optionsLength, optionsLevelSecurity, typeOption, typeOptionPassword, typePasswordSetting, http } from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

type Props = {
  customize: SettingsCustomizeState;
  genSettings: typePasswordSetting[];
  handleGenerate?: (password: string, generatePassword?: boolean) => void;
  levelSecurity?: number;
  logout: () => void;
};

const GeneratePassword: FC<Props> = ({ customize, genSettings, levelSecurity = 1, handleGenerate, logout }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [mainColor, setMainColor] = useState<string>("#1a90bb");
  const [generatorOption, setGeneratorOption] = useState<{
    length?: typeOption;
    level: typeOption;
  }>({
    length: undefined,
    level: optionsLevelSecurity.find(n => n.value === levelSecurity) as typeOptionPassword,
  });

  const [generatorOptions, setGeneratorOptions] = useState<{
    length: typeOption[];
    level: typeOption[];
  }>({
    length: optionsLength,
    level: optionsLevelSecurity,
  });

  const genSetting = useMemo(() => genSettings.find(x => x.value === generatorOption.level.value), [generatorOption.level.value]);

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  useEffect(() => {
    setGeneratorOption(g => ({
      ...g,
      length: optionsLength.find(x => x.value === (genSetting?.min_characters_count as number)),
    }));

    setGeneratorOptions({
      length: optionsLength.filter(x => x.value >= (genSetting?.min_characters_count || 4)),
      level: optionsLevelSecurity.filter(n => n.value >= levelSecurity),
    });
  }, [genSetting]);

  const handleLengthValue = (length: any) => {
    setGeneratorOption(g => ({ ...g, length }));
  };

  const handleLevelValue = (level: any) => {
    setGeneratorOption(g => ({ ...g, level }));
    if (genSetting) {
      setGeneratorOption(g => ({ ...g, length: optionsLength.find(x => x.value === genSetting.min_characters_count) }));
    }
  };

  const handle = async () => {
    setLoading(true);
    try {
      const response = await http({
        route: "password/generate",
        method: "POST",
        payload: { length: generatorOption.length?.value, level: generatorOption.level.value },
      });
      const { password } = response;
      if (handleGenerate && password) {
        handleGenerate(password, true);
      }
    } catch ({ response: { data } }) {
        if (lodashGet(data, "error", "") === "account_is_blocked") {
          logout();
        }
    } finally {
      setTimeout(() => setLoading(false), 800);
    }
  };

  return (
    <div className={cnManageKey("PasswordGenerator")}>
      <LoadingContainer isLoading={isLoading}>
        <div className={`${cnField()} ${cnField("Select")} Length`} id="input_select_length_container">
          <span className="Label" id="input_select_length_label">
            {t("form_input-Length")}
          </span>
          <Select
            className="Length"
            menuPlacement="top"
            onChange={handleLengthValue}
            options={generatorOptions.length}
            styles={customSelectStyles()}
            theme={SelectTheme(mainColor)}
            value={generatorOption.length}
          />
        </div>

        <div className={`${cnField()} ${cnField("Select")} Level`} id="input_select_level_container">
          <span className="Label" id="input_select_level_label">
            {t("form_input-LevelSecurity")}
          </span>
          <Select
            className="Level"
            menuPlacement="top"
            onChange={handleLevelValue}
            options={generatorOptions.level}
            styles={customSelectStyles()}
            theme={SelectTheme(mainColor)}
            value={generatorOption.level}
            formatOptionLabel={({ label, value }): JSX.Element => (
              <div className="ManageKey-Option">
                <Icon name={`icon-dot-${value}`} width={22} />
                &nbsp;
                {label}
              </div>
            )}
          />
        </div>
        <Button id="button_generate_password" type="button" onClick={() => handle()} theme="primary-link" icon="icon-generate">
          {t("button-Generate")}
        </Button>
      </LoadingContainer>
    </div>
  );
};

const mapStateToProps = ({
  settingsTest: {
    customize,
    generatorPassword: { settings: genSettings },
  },
}: RootState) => ({
  customize,
  genSettings,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePassword);
