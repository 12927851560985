import { cn } from "@bem-react/classname";

export const cnLayout = cn("Layout");
export const cnAside = cn("Aside");
export const cnAsideContent = cn("AsideContent");
export const cnContent = cn("Content");
export const cnContentHeader = cn("ContentHeader");
export const cnContentHeaderMobile = cn("ContentHeaderMobile");
export const cnContentWrapper = cn("ContentWrapper");
export const cnFilter = cn("Filter");

export { default as Aside } from "./Aside/Aside";
export { default as Content } from "./Content/Content";
export { default as ContentWrapper } from "./Content/ContentWrapper";
export { default as ContentHeader } from "./Content/ContentHeader";
export { default as ContentHeaderMobile } from "./Content/ContentHeaderMobile";
export { default as Filter } from "./Filter/Filter";
export { default as Footer } from "./Footer/Footer";
export { default as Header } from "./Header/Header";
export { default as Layout } from "./LayoutComponent/Layout";
