import React, { FC } from "react";
import QRCode from "react-qr-code";

type Props = {
    value?: string;
    size?: number;
};

const DisplayQRCode: FC<Props> = ({
    value = "error",
    size = 190
}) => {

    return (
        <QRCode
            value={value}
            size={size}
            style={{ margin: "0 auto" }}
            viewBox="0 0 190 190"
        />
  );
};

export default DisplayQRCode;
