import { Action } from "redux-actions";
import { typeAccessControl } from "core";
import { getGlobalSettings, setGlobalSettings } from "store/actions";
import { find } from "lodash";

export interface SettingsAccessControlState {
  isLoading: boolean;
  settings: typeAccessControl;
}

const initialState = {
  isLoading: false,
  settings: {
    accessImport: {
      user: true,
      manager: true
    },
    accessExport: {
      user: true,
      manager: true
    },
    accessManagement: {
      user: true,
      manager: true
    },
    accessActivityLog: {
      user: true,
      manager: false
    }
  }
};

export default function accessControl(
  state: SettingsAccessControlState = initialState,
  { payload, type }: Action<any>,
): SettingsAccessControlState {
  switch (type) {
    case getGlobalSettings.TRIGGER:
    case setGlobalSettings.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case getGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: find(payload, ["setting", "ACCESS_CONTROL_SETTINGS"])?.value,
      };

    case setGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: payload.setting === "ACCESS_CONTROL_SETTINGS" ? payload.value : state.settings,
      };

    case getGlobalSettings.FAILURE:
    case getGlobalSettings.FULFILL:
    case setGlobalSettings.FAILURE:
    case setGlobalSettings.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
