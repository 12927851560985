/* eslint-disable @typescript-eslint/no-unused-vars, react/no-array-index-key, no-unused-vars  */
import React, { FC, useEffect } from "react";
import { Icon, SearchInput } from "ss-ui";
import { useTranslation } from "react-i18next";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { PropsTableWithSearch, TableWithSearch } from "components/Table";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";

import { FORM_GROUP_MANAGEMENT, getGroupFromListByID, getUserFromListByID, typeGroup, typeUser } from "core";

import { RootState } from "store/reducers";
import { usersGetAll } from "store/actions";
import { FormValues } from "store/routines/groups/manage";
import { LoadingContainer } from "components/Generic";

const cnMembers = cn("InfoBoxGroupMembers");

type Props = {
  chosen: string[];
  list: typeGroup[];
  isLoading: boolean;
  usersGetAll: () => void;
  usersList: typeUser[];
} & InjectedFormProps &
  PropsTableWithSearch &
  WrappedFieldArrayProps<any>;

const RenderMembersList: FC<Props> = ({ chosen, setSearch, searchCallback, list, isLoading, usersGetAll, usersList }) => {
  const group: typeGroup = getGroupFromListByID(list, chosen[0]);
  const { t } = useTranslation();

  useEffect(() => {
    if (usersList.length === 0) {
      usersGetAll();
    }
  }, [usersGetAll, usersList]);

  return (
    <>
      {group.users.length !== 0 ? (
        <LoadingContainer isLoading={isLoading}>
          <div className={cnMembers("Header")}>{t("groups-List-Members")}</div>
          <div className={cnMembers("Search")} id="div_search_input">
            <SearchInput onChange={setSearch} />
          </div>
          <div className={cnMembers("List")}>
            {group.users.length !== 0 &&
              group.users.filter(searchCallback).map((user, i) => {
                const userItem: typeUser = getUserFromListByID(group.users, user.id);
                return (
                  <div key={i} className={cnMembers("UserItem")}>
                    <Icon name="icon-user-circle" width={20} height={20} />
                    <div className="name">
                      {userItem.username}
                    </div>
                  </div>
                );
              })}
          </div>
        </LoadingContainer>
      ) : (
        <p>{t("groups-List-Empty")}</p>
      )}
    </>
  );
};

const mapStateToProps = ({ groups: { list, chosen }, users: { list: usersList, isLoading } }: RootState) => ({
  chosen,
  isLoading,
  list,
  usersList,
});

const mapDispatchToProps = { usersGetAll };

export default compose<Props, Partial<Props>>(
  TableWithSearch,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_GROUP_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(RenderMembersList);
