import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { folderGetAll, historyGet } from "store/actions";

import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export type Payload = { id: string; historyID: string };

export const action: Routine = createRoutine("HISTORIES_RESTORE_FOLDER");

const restore = ({ id }: Payload) => {
  return http({ method: "GET", route: `folder/restore/${id}` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(restore, payload);

    toast.success(i18n.t("toast_folder_success_restore"), {
      toastId: "FOLDER_SUCCESS_RESTORE",
    });
    yield put(folderGetAll.trigger());
    yield put(historyGet.trigger());
    yield put(action.success(payload.historyID));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
