import React, { FC } from "react";
import { cnModal, Modal } from "components/Generic";

type Props = {
  imageUrl: string | undefined;
  handleClose: () => void;
};

const ImageWatcher: FC<Props> = ({
  imageUrl,
  handleClose,
}) => {

  return (
    <Modal handleClose={handleClose} className={cnModal("Watcher")}>
      <div className={cnModal("Watcher-Header")} id="modal_cropper_header" />
      <div className={cnModal("Watcher-Body")} id="modal_cropper_body">
        <img src={imageUrl} alt="Watcher" />
      </div>
    </Modal>
  );
};

export default ImageWatcher;