import { history } from "components/App/App";

import { MainRoutes } from "core";

export enum LOCAL_STORAGE {
  SETTINGS = "APP_SETTINGS",
  USER = "APP_USER",
  PREVIOUS_SESSION_URL = "PREVIOUS_SESSION_URL",
  LANGUAGE = "i18nextLng",
  DISPLAY_TYPE = "DISPLAY_TYPE",
  FOLDER_STRUCTURE = "FOLDER_STRUCTURE",
  SEND_URL = "SEND_URL",
  LAST_SEARCH_VALUE = "LAST_SEARCH_VALUE"
}

export const saveDataToLS = (key: LOCAL_STORAGE, data: any): boolean => {
  try {
    const existData = getDataFromLS(key);

    localStorage.setItem(key, JSON.stringify(existData ? { ...existData, ...data } : data));

    return true;
  } catch (e) {
    return false;
  }
};

export const getDataFromLS = (key: LOCAL_STORAGE): any => {
  try {
    const data = localStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const removeDataFromLS = (key: LOCAL_STORAGE): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const clearLS = (path: string = MainRoutes.Login) => {
  try {
    localStorage.clear();
    history.push(`${path}`);
    return true;
  } catch (e) {
    return false;
  }
};
