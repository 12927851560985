import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";
import { PayloadChoseFunction, FORM_FOLDER_MANAGEMENT } from "core";
import { get as lodashGet } from "lodash";
import { reset } from "redux-form";

export const action: Routine = createRoutine("FOLDER_CHOSE");

function* handler({ payload }: Action<PayloadChoseFunction>) {
  try {
    yield put(reset(FORM_FOLDER_MANAGEMENT));
    yield put(action.success(payload));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
