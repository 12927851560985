/* eslint-disable react-hooks/rules-of-hooks, react/jsx-no-target-blank,
no-lone-blocks, react/no-danger, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, no-nested-ternary,
jsx-a11y/no-static-element-interactions */
import React, { FC, useMemo, useState } from "react";
import moment from "moment";
import { Button, FolderIcon, Icon } from "ss-ui";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import { InfoBoxFolder } from "components/InfoBox/Folder";
import { InfoBoxKey } from "components/InfoBox";
import { cnAsideContent } from "components/Layout";

import {
  clearUrlParam,
  DATE_FORMAT,
  DATETIME_FORMAT,
  FOLDER_TYPE,
  getLastKeyFromFlatKeyList,
  parseDate,
  PayloadChoseFunction,
  replaceUrlParam,
  Routes,
  typeFolder,
  typeKey,
  getPortalLink,
  getTemplateIcon,
} from "core";

import { favoritesSetFolder, folderChose, keyChose } from "store/actions";

import { RootState } from "store/reducers";
import { Payload as PayloadFavoritesSetFolder } from "store/routines/favorites/setFolder";

type Props = {
  chosenFolder: string[];
  chosenKeys: string[];
  favoritesSetFolder: (payload: PayloadFavoritesSetFolder) => void;
  folder: typeFolder;
  folderAccess: string;
  folderChose: (payload: PayloadChoseFunction) => void;
  foldersList: typeFolder[];
  hasAccess: boolean;
  isAdmin: boolean;
  isFolderWritable: boolean;
  isFreeUser: boolean;
  isGroupAndRoleUser: boolean;
  isNoAccess?: boolean;
  isStandard: boolean;
  keyChose: (payload: PayloadChoseFunction) => void;
  keysList: typeKey[];
  limitingKeys: number;
  showFilters: boolean;
  totalKeys?: number;
};

const KeysAside: FC<Props> = ({
  chosenFolder,
  chosenKeys,
  favoritesSetFolder,
  folder,
  folderAccess,
  folderChose,
  foldersList,
  hasAccess,
  isAdmin,
  isFolderWritable,
  isFreeUser,
  isGroupAndRoleUser,
  isNoAccess,
  isStandard,
  keyChose,
  keysList,
  limitingKeys,
  showFilters,
  totalKeys,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [readMore, setReadMore] = useState(false);
  const countKeys = Number(foldersList.filter(e => e.type === "personal" && e.root === null).map(item => item.countKeys));
  const lastKeyInfo: typeKey | undefined = useMemo(() => getLastKeyFromFlatKeyList(keysList), [keysList]);
  const favoriteAccess = useMemo(() => isAdmin && folder.type === "group", [folder, isAdmin]);

  const handleFavorite = (folderID: string) => {
    favoritesSetFolder({
      folderID,
    });
  };

  const handleAddFolder = (id: string) =>
    folderChose({
      isNew: id === "new",
      status: true,
      id,
    });

  const handleAddKey = () => {
    if (isFreeUser && countKeys >= limitingKeys) {
      toast.info(
        <Trans i18nKey="toast_free_user_key_limit">
          <a target="_blank" href={`${getPortalLink(window.location.host)}/login`}>
            Upgrade
          </a>
        </Trans>,
        {
          toastId: "toast_free_user_key_limit",
          autoClose: false,
          position: "top-center",
        },
      );
    } else {
      push(clearUrlParam(replaceUrlParam(Routes.ManageKey, "folderID", folder.id)));
    }
  };

  const iconSize = [
    { value: 1, width: 5 },
    { value: 2, width: 12 },
    { value: 3, width: 19 },
    { value: 4, width: 26 },
  ];

  return (
    <>
      {chosenFolder.length > 0 && chosenKeys.length === 0 && !showFilters ? <InfoBoxFolder /> : ""}
      {chosenKeys.length > 0 && !showFilters && <InfoBoxKey />}

      {chosenKeys.length === 0 && chosenFolder.length === 0 && !showFilters && (
        <div className={cnAsideContent()}>
          {folder && (
            <>
              <div className={cnAsideContent("Header-Folder")}>
                <FolderIcon
                  level={folder.level_security}
                  size={30}
                  isRoot={!isStandard && folder.root === null && folder.type !== FOLDER_TYPE.GROUP}
                  isGroup={!isStandard && folder.type === FOLDER_TYPE.GROUP}
                  access={!isStandard ? folderAccess : ""}
                />
                <div className={cnAsideContent("Header-Folder-Info")}>
                  <h2 title={folder.title}>{folder.title}</h2>
                  {!isAdmin && (!isFolderWritable || isGroupAndRoleUser || isFreeUser) ? null : (
                    <div
                      className={cnAsideContent("Header-Folder-Button")}
                      onClick={() => handleAddFolder(folder.id)}
                      id="btn_div_edit_folder"
                    >
                      <Icon width={12} height={12} name="icon-pencil" />
                      <span>{t("button-EditFolder")}</span>
                    </div>
                  )}
                </div>
              </div>

              {folder.description && folder.description !== "<p><br></p>" && (
                <div className={cnAsideContent("Description")}>
                  <p>
                    {folder.description.length > 180 ? (
                      readMore ? (
                        <span dangerouslySetInnerHTML={{ __html: folder.description }} />
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: folder.description
                              .slice(0, 180)
                              .replace(/&$|&n$|&nb$|&nbs$|<$/, "")
                              .concat("..."),
                          }}
                        />
                      )
                    ) : (
                      <span dangerouslySetInnerHTML={{ __html: folder.description }} />
                    )}
                    {folder.description.length > 180 && (
                      <button
                        id="btn_show_more_description"
                        className={cnAsideContent("ReadMoreLink")}
                        type="button"
                        onClick={() => setReadMore(!readMore)}
                      >
                        {folder.description.length > 180 ? (readMore ? t("key-description-hide") : t("key-description-show")) : null}
                      </button>
                    )}
                  </p>
                </div>
              )}

              <div className={cnAsideContent("Total", ["AsideContent-Total-Dot"])}>
                <p>{t("aside_right-LevelSecurity")}:</p>
                <span>
                  <Icon
                    name={`icon-dot-${folder.level_security}`}
                    width={Number(iconSize.filter(e => e.value === folder.level_security).map(el => el.width))}
                    height={4}
                  />
                  {t(`security-level-${folder.level_security}`)}
                </span>
              </div>

              {!isStandard && (
                <div className={cnAsideContent("Total")}>
                  <p>{t("form_input-Asses")}:</p>
                  <span>
                    <Icon name={`colored/icon-folder-access-${folder.access}`} />
                    {t(`folder-asses-${folder.access}`)}
                  </span>
                </div>
              )}

              {hasAccess && !favoriteAccess && (
                <>
                  {keysList.length > 0 && lastKeyInfo && (
                    <div className={cnAsideContent("Total")}>
                      <p>{t("aside_right-Last")}:</p>
                      <div className={cnAsideContent("Total-LastKey")}>
                        <Icon name={getTemplateIcon(lastKeyInfo.template)} width={20} />
                        <div
                          className={cnAsideContent("Total-LastKey-Info")}
                          onClick={() => keyChose({ status: true, id: lastKeyInfo.id })}
                        >
                          <span title={lastKeyInfo.title}>{lastKeyInfo.title}</span>
                          <time>{moment(parseDate(lastKeyInfo.updatedAt)).format(DATETIME_FORMAT)}</time>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className={cnAsideContent("FolderInfo")}>
                <div className={cnAsideContent("FolderInfo-Item")}>
                  <p>{t("table_title-Created-date")}:</p>
                  <span>{moment(parseDate(folder.createdAt)).format(DATE_FORMAT)}</span>
                </div>
                {(hasAccess || isAdmin) && (
                  <div className={cnAsideContent("FolderInfo-Item")}>
                    <p>{t("navigation_keys")}:</p>
                    <span>{totalKeys}</span>
                  </div>
                )}
              </div>

              {!isNoAccess ? (
                <>
                  {!isFreeUser && !favoriteAccess ? (
                    <div className={cnAsideContent("Favorite")}>
                      <Button
                        theme="primary-link"
                        icon={folder.favorite ? "icon-star-fill" : "icon-star"}
                        onClick={() => {
                          handleFavorite(folder.id);
                        }}
                        id="btn_favorite_folder"
                      >
                        {folder.favorite ? t("favorites_remove") : t("favorites_add")}
                      </Button>
                    </div>
                  ) : null}
                </>
              ) : null}

              {(hasAccess || isAdmin) && (
                <div className={cnAsideContent("Action")}>
                  {isAdmin && folder.type === FOLDER_TYPE.GROUP ? (
                    <></>
                  ) : (
                    <Button id="button_add_key" onClick={() => handleAddKey()} icon="icon-key-add">
                      {t("button_add-Key")}
                    </Button>
                  )}
                  {isGroupAndRoleUser || isFreeUser ? null : (
                    <Button id="button_add-folder" onClick={() => handleAddFolder("new")} icon="icon-folder-add">
                      {t("button_add-Folder")}
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  folders: { list: foldersList, chosen: chosenFolder },
  keys: { chosen: chosenKeys, list: keysList },
  settings: { limitingKeys },
  user: { username },
}: RootState) => ({
  chosenFolder,
  chosenKeys,
  foldersList,
  keysList,
  limitingKeys,
  username,
});

const mapDispatchToProps = {
  favoritesSetFolder,
  folderChose,
  keyChose,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeysAside);
