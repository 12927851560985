import { createRoutine, Routine } from "redux-saga-routines";
import { Action } from "redux-actions";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get, snakeCase } from "lodash";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

export const action: Routine = createRoutine("USERS_UPLOAD_AVATAR");

export interface Payload {
  id: string;
  userAvatar: string;
}

const request = ({ id, userAvatar }: Payload) => {
  // const data: FormData = new FormData();

  // data.append("avatar", avatar);

  return http({ 
    route: `user/avatar/${id}`, 
    method: "POST", 
    payload: {
      avatar: userAvatar,
    }, 
    responseType: "arraybuffer" 
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(request, payload);
    yield put(action.success({
      id: payload.id,
      avatarFile: payload.userAvatar ? payload.userAvatar : null,
    }));
    toast.success(i18n.t("user_infobox_avatar_success"));
  } catch (error) {
    const errorText = get(JSON.parse(new TextDecoder().decode(get(error, "response.data", undefined))), "detail", undefined);
    yield put(action.failure(errorText));
    toast.error(i18n.t(`user_infobox_avatar_error_${snakeCase(errorText)}`));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
