import React, { FC, useMemo } from "react";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  FIELD_TYPE,
  getKeyFromArrayByID,
  optionsAccessType,
  optionsCountry,
  optionsEmailTypes,
  optionsIDType,
  optionsMailSecurityTypes,
  optionsProtocol,
  typeKey,
} from "core";
import { RootState } from "store/reducers";

import {
  RowCardNumber,
  RowDate,
  RowFile,
  RowImage,
  RowOTP,
  RowRichText,
  RowSecurity,
  RowTemplate,
  RowText,
  RowTextFromSelect,
  RowURI,
} from "./Fragments/index";

export const cnSettings = cn("InfoBoxKeySettings");
export const cnManageKey = cn("ManageKey")

type Props = {
  chosen: string[];
  list: typeKey[];
};

const Settings: FC<Props> = ({ chosen, list }) => {
  const { t } = useTranslation();

  const key: typeKey | undefined = useMemo(() => getKeyFromArrayByID(list, chosen[0]), [list, chosen]);

  if (!key) return null;

  return (
    <div className={cnSettings()}>
      <RowTemplate item={key} field={FIELD_TYPE.TEMPLATE} />

      <RowText item={key} field={FIELD_TYPE.EMAIL} />
      <RowTextFromSelect item={key} field={FIELD_TYPE.EMAIL_TYPE} options={optionsEmailTypes} />

      <RowText item={key} field={FIELD_TYPE.LOGIN} />

      { /* Sap Field */}
      
      <RowText item={key} field={FIELD_TYPE.USER_NAME} />
      <RowText item={key} field={FIELD_TYPE.RFC_CONNECTION_ID} />
      <RowText item={key} field={FIELD_TYPE.RFC_CONNECTION_USER} />
      <RowText item={key} field={FIELD_TYPE.RFC_CONNECTION_TYPE} />
      <RowText item={key} field={FIELD_TYPE.RFC_CONNECTION_NAME} />

      <RowSecurity item={key} field={FIELD_TYPE.PASSWORD} />
      <RowURI item={key} field={FIELD_TYPE.URI} />

      <RowText item={key} field={FIELD_TYPE.HOLDER} />

      <RowCardNumber item={key} field={FIELD_TYPE.CARD_NUMBER} />

      <div className={cnSettings("Row")}>
        <RowText item={key} field={FIELD_TYPE.CARD_EXPIRATION_DATE} />
        <RowSecurity item={key} field={FIELD_TYPE.CVV} />
      </div>

      <RowSecurity item={key} field={FIELD_TYPE.CARD_PIN} />

      <RowText item={key} field={FIELD_TYPE.HOSTNAME_IP} />

      <div className={cnSettings("Row")}>
        <RowText item={key} field={FIELD_TYPE.PORT} />
        <RowTextFromSelect item={key} field={FIELD_TYPE.PROTOCOL} options={optionsProtocol} />
      </div>

      <RowTextFromSelect item={key} field={FIELD_TYPE.ID_CARD_TYPE} options={optionsIDType} />

      <RowText item={key} field={FIELD_TYPE.DOC_NUMBER} />

      {key && key.template === "email_template" ? (
        <div className={cnManageKey("Field-Title")}>
          <p>{t("manage_key_incoming_title")}</p>
        </div>
      ) : null}

      <RowText item={key} field={FIELD_TYPE.INCOMING_MAIL_SERVER} />

      <div className={cnSettings("Row")}>
        <RowTextFromSelect item={key} field={FIELD_TYPE.INCOMING_MAIL_SECURITY_TYPE} options={optionsMailSecurityTypes} />
        <RowText item={key} field={FIELD_TYPE.INCOMING_MAIL_PORT} />
      </div>

      {key && key.template === "email_template" ? (
        <div className={cnManageKey("Field-Title")}>
          <p>{t("manage_key_outgoing_title")}</p>
        </div>
      ) : null}

      <RowText item={key} field={FIELD_TYPE.OUTGOING_MAIL_SERVER} />

      <div className={cnSettings("Row")}>
        <RowTextFromSelect item={key} field={FIELD_TYPE.OUTGOING_MAIL_SECURITY_TYPE} options={optionsMailSecurityTypes} />
        <RowText item={key} field={FIELD_TYPE.OUTGOING_MAIL_PORT} />
      </div>

      <div className={cnSettings("Row")}>
        <RowDate item={key} field={FIELD_TYPE.ISSUE_DATE} />
        <RowDate item={key} field={FIELD_TYPE.EXPIRATION_DATE} />
      </div>

      <RowTextFromSelect item={key} field={FIELD_TYPE.COUNTRY} options={optionsCountry} />

      <RowText item={key} field={FIELD_TYPE.PLACE_ISSUE} />

      <RowText item={key} field={FIELD_TYPE.IBAN} />

      <RowText item={key} field={FIELD_TYPE.BIC_SWIFT} />

      <RowText item={key} field={FIELD_TYPE.ACCOUNT_NUMBER} />

      <RowText item={key} field={FIELD_TYPE.NAME} />

      <RowText item={key} field={FIELD_TYPE.CLIENT_NUMBER} />

      <RowText item={key} field={FIELD_TYPE.SERIAL_NUMBER} />

      <RowText item={key} field={FIELD_TYPE.IAM_ID} />

      <RowTextFromSelect item={key} field={FIELD_TYPE.ACCESS_TYPE} options={optionsAccessType} />

      {/* Text */}
      <RowRichText item={key} field={FIELD_TYPE.KEY} />
      <RowRichText item={key} field={FIELD_TYPE.DESCRIPTION} />

      <RowOTP item={key} field={FIELD_TYPE.OTP} />

      {/* Files */}
      {/* <RowImage item={key} field={FIELD_TYPE.LOGO} /> */}

      <RowImage item={key} field={FIELD_TYPE.PHOTO} />
      <RowImage item={key} field={FIELD_TYPE.QR_BARCODE} />

      <RowFile item={key} field={FIELD_TYPE.FILE_NAME} />
      <RowFile item={key} field={FIELD_TYPE.FILE} />
      <RowFile item={key} field={FIELD_TYPE.CERTIFICATE} />

      <div className={cnSettings("Container")}>
        <div className={cnSettings("Label")}>
          {t("key-LastModified")}: {key.updatedAt}
        </div>
        <div className={cnSettings("Label")}>
          {t("history_filter_operations_option_create")}: {key.createdAt}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ keys: { chosen, list } }: RootState) => ({
  chosen,
  list,
});

export default connect(mapStateToProps, {})(Settings);
