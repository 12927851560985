import React, { FC, useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { typeFolder, ROLE_TYPE, } from "core";
import { RootState } from "store/reducers";
import { TreeList } from "components/Generic/ModalFolderSelect/fragments/TreeList";
import { PropsWithChosen, WithChosen } from "hoc";
import { favoritesSetFolder, folderChose } from "store/actions";

// types
import { FolderListPropsType } from "./FolderListTypes";

type Props = {
  role: ROLE_TYPE;
} & FolderListPropsType & PropsWithChosen;

const FolderList: FC<Props> = ({
  folders = [],
  chosen,
  handleUpdateChosenItems,
  folderChose,
  favoritesSetFolder,
  favoriteLoading,
  role,
}) => {
  const handleChosen = (data: any) => {
    handleUpdateChosenItems(folderChose, !(chosen.indexOf(data.value) >= 0), data.value, true);
  };

  const isAdmin: boolean = useMemo(() => [ROLE_TYPE.ADMIN].includes(role), [role]);

  return (
    <div>
      {folders.map((folder: typeFolder) => (
        <TreeList
          folder={folder}
          key={folder.id}
          folders={folders}
          onChange={handleChosen}
          chosen={chosen}
          favoritesSetFolder={favoritesSetFolder}
          favoriteLoading={favoriteLoading}
          isAdmin={isAdmin}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({
  folders: { list, chosen, isLoading: favoriteLoading },
  user: { role },
}: RootState) => ({
  folders: list,
  chosen,
  favoriteLoading,
  role,
});

const mapDispatchToProps = { folderChose, favoritesSetFolder };

export default compose<Props, Partial<Props>>(
  WithChosen,
  connect(mapStateToProps, mapDispatchToProps),
)(FolderList);
