import React, { FC, useEffect, useState } from "react";
import { Button, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps, } from "redux-form";
import { FORM_CUSTOMERS_MAILER, Validation } from "core";
import { FormInput, FormSelect, FormSwitch } from "components/Form";
import { RootState } from "store/reducers";
import { SettingsMailerState } from "store/reducers/settings/mailerSettings";
import { Payload as payloadSetCustomizeMailer } from "store/routines/settings/setCustomizeMailer";
import { Payload as payloadSetCustomizeMailerCheck } from "store/routines/settings/setCustomizeMailerCheck";
import { Payload as payloadSetCustomizeMailerSwitch } from "store/routines/settings/setCustomizeMailerSwitch";
import { setCustomizeMailer, setCustomizeMailerCheck, setCustomizeMailerSwitch } from "store/actions";
import { LoadingContainer } from "components/Generic";
import CustomizeMailerModal from "./CustomizeMailerModal";

const cnCustomize = cn("Customize");
const cnSettings = cn("Settings");

type Props = {
  mailerSettings: SettingsMailerState;
  setCustomizeMailer: (payload: payloadSetCustomizeMailer) => void;
  setCustomizeMailerCheck: (payload: payloadSetCustomizeMailerCheck) => void;
  setCustomizeMailerSwitch: (payload: payloadSetCustomizeMailerSwitch) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const CustomizeMailerSettings: FC<Props> = ({ 
  mailerSettings, 
  handleSubmit, 
  setCustomizeMailer,
  setCustomizeMailerCheck,
  setCustomizeMailerSwitch,
  change 
}: Props) => {
  const { t } = useTranslation();

  const encryptionOptions = [
    { label: "SSL", value: "ssl" },
    { label: "TLS", value: "tls" }
  ];

  const [checkMailData, setCheckMailData] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  /**
   * UseEffect update customize Settings Mailer
   */
  useEffect(() => {
    if (mailerSettings.value) {
      change("mailerEncryption", mailerSettings.value.mailerEncryption);
      change("mailerHost", mailerSettings.value.mailerHost);
      change("mailerPassword", mailerSettings.value.mailerPassword);
      change("mailerPort", mailerSettings.value.mailerPort);
      change("mailerUser", mailerSettings.value.mailerUser);
      change("mailerSendMail", mailerSettings.value.mailerSendMail);
      change("mailerSendName", mailerSettings.value.mailerSendName);
    }
  }, [mailerSettings.value]);

  /**
   * Send update Mailer Info
   */
   const handleFormMailSettings = (data: any) => {
    setCustomizeMailer(data);
  };

  /**
   * Send check mail settings
   */
   const handleFormCheckMailSettings = (data: any) => {
     setCustomizeMailerCheck({
       dataValue: data
     });
  };

  /**
   * Send test mail
   */
  const handleMailCheck = (data: any) => {
    setCheckMailData(data);
    setOpenModal(true);
  };

  /**
   * Switch mail settings
   */
  const handleMailSwitch = (value: boolean) => {
    setCustomizeMailerSwitch({
      mailerEnabledSwitch: value
    })
  };

  return (
    <div className={`${cnCustomize("Sections")} ${cnCustomize("FixInputs")}`}>
      <LoadingContainer isLoading={mailerSettings.isLoading}>
      <div className={cnCustomize("Section")}>
        <div className={cnCustomize("Header")}>
          <span className={cnCustomize("Header-Name")}>{t("customize_mail_header")}</span>
          <div className={cnSettings("Switch")}>
            <FormSwitch 
              isOn={mailerSettings.mailerEnabled} 
              handleToggle={handleMailSwitch} 
              fieldName="mailerSwitch" 
            />
            {mailerSettings.mailerEnabled ? (
              <p>{t("settings_switch_on")}</p>
            ) : (
              <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
            )}
            </div>
        </div>
        <div className={cnSettings("Hint")}>{t("customize_mail_hint")}</div>
          {mailerSettings.mailerEnabled ? (
            <>
              {mailerSettings.mailerStatus ? (
                <div className={cnSettings("Success")}>
                  <Icon name="icon-checked" width={25} height={25} fill="#49B109"/>
                  <span>{t("customize_mail_check_success")}</span>
                </div>
              ) : (
                <div className={cnSettings("Warning")}>
                  <Icon name="icon-warning" width={25} height={25} fill="#E3000B"/>
                  <span>{t("customize_mail_check_error")}</span>
                </div>
              )}
              <form id="submit-mailer" onSubmit={handleSubmit(handleFormMailSettings)}>
                <div className={cnCustomize("Content")}>
                  <div className={cnCustomize("Content-Left")}>
                  <Field
                    className="Required"
                    component={FormInput}
                    label={t("customize_mail_field_username")}
                    name="mailerUser"
                    validate={[Validation.required]}
                  />
                  <Field
                    className="Required"
                    component={FormInput}
                    name="mailerHost"
                    label={t("customize_mail_field_server")}
                    validate={[Validation.required]}
                  />
                </div>
                  <div className={cnCustomize("Content-Right")}>
                    <Field
                      component={FormInput}
                      isShown
                      className="Required"
                      label={t("customize_mail_field_password")}
                      name="mailerPassword"
                      validate={[Validation.required, Validation.maxLength255, Validation.emoji, Validation.notSpace]}
                  />
                  <div className={cnCustomize("Content-Right-Row")}>
                    <Field
                      className="Required"
                      component={FormInput}
                      label={t("customize_mail_field_port")}
                      name="mailerPort"
                      validate={[Validation.required, Validation.port, Validation.onlyDigit]}
                    />
                    <Field
                      required
                      name="mailerEncryption"
                      component={FormSelect}
                      options={encryptionOptions}
                      label={t("customize_mail_field_connection")}
                      menuPlacement="top"
                      template={(label: string, value: string): JSX.Element => (
                        <div className="ManageKey-Option">
                          {label}
                        </div>
                      )}
                      validate={[Validation.required]}
                    />
                  </div>
                </div>
              </div>
              <div className={cnCustomize("Content")}>
                <div className={cnCustomize("Content-Left")}>
                  <Field
                    className="Required"
                    component={FormInput}
                    label={t("customize_mail_field_sender_email")}
                    name="mailerSendMail"
                    validate={[Validation.email, Validation.required]}
                  />
                </div>
                <div className={cnCustomize("Content-Right")}>
                  <Field
                    className="Required"
                    component={FormInput}
                    label={t("customize_mail_field_sender_name")}
                    name="mailerSendName"
                    validate={[Validation.required]}
                  />
                </div>
              </div>
              <div className={cnCustomize("Content")}>
                <div className={cnCustomize("Content-Left")} />
                <div className={cnCustomize("Content-Right")}>
                  <div className={cnCustomize("SubmitMail")}>
                    <Button
                      className="mr20"
                      theme="primary-outline"
                      type="button"
                      onClick={handleSubmit(handleMailCheck)}
                      disabled={!mailerSettings.mailerStatus}
                    >
                      {t("customize_mail_button_mail_test")}
                    </Button>
                    <Button className="mr20" theme="primary-outline" type="button" onClick={handleSubmit(handleFormCheckMailSettings)}>
                      {t("customize_mail_button_mail_check")}
                    </Button>
                    <Button form="submit-mailer" type="submit" disabled={!mailerSettings.mailerStatus}>
                      {t("customize_mail_button_mail_save")}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : null}  
      </div>
      </LoadingContainer>
      {openModal && (
        <CustomizeMailerModal 
          isLoading={mailerSettings.isLoading} 
          mailerData={checkMailData} 
          handleCloseModal={setOpenModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { mailerSettings } }: RootState) => ({
  mailerSettings,
});

const mapDispatchToProps = { setCustomizeMailer, setCustomizeMailerCheck, setCustomizeMailerSwitch };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<payloadSetCustomizeMailer>({
    form: FORM_CUSTOMERS_MAILER,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(CustomizeMailerSettings);
