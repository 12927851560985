import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";

import { http, typeConfigs } from "core";

import { userInit } from "store/actions";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("GET_CONFIG");

const getConfig = () => {
  return http({ route: "config", isProtected: false });
};

function* handler() {
  try {
    const response: typeConfigs = yield call(getConfig);

    yield put(action.success(response));

    yield put(userInit.trigger());
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
