/* eslint-disable jsx-a11y/click-events-have-key-events,  jsx-a11y/no-static-element-interactions */
import Color from "color";
import React, { FC, useState, useEffect } from "react";
import Select , { SingleValue } from "react-select";
import { Checkbox } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { customSelectStyles } from "components/Form/Select";

import { typePasswordSetting, optionsLengthString, typeSelectedFolder } from "core";

import { passwordSettingValueType, Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";
import { SettingsGeneratorPasswordState } from "store/reducers/settings/generatorPassword";
import { setGlobalSettings } from "store/actions";
import { toast } from "react-toastify";

export const cnSettings = cn("Settings");

interface Props {
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
  generatorPassword: SettingsGeneratorPasswordState;
  customize: SettingsCustomizeState;
}

const MIN_PASS_LENGTH = 2;

const PasswordGeneratorControl: FC<Props> = ({ setGlobalSettings, generatorPassword, customize }) => {
  const { t } = useTranslation();

  const [settingsValue, setSettingsValue] = useState<typePasswordSetting[]>(generatorPassword.settings);
  const [passSettingsValue, setPassSettingsValue] = useState<number>(settingsValue[0].value);
  const [passSettingsLowerCaseLettersPresence, setPassSettingsLowerCaseLettersPresence] = useState<boolean>(
    settingsValue[0].lowercase_letters_presence,
  );
  const [passSettingsUppercaseLettersPresence, setPassSettingsUppercaseLettersPresence] = useState<boolean>(
    settingsValue[0].uppercase_letters_presence,
  );
  const [passSettingsSpecialCharsPresence, setPassSettingsSpecialCharsPresence] = useState<boolean>(settingsValue[0].specialchars_presence);
  const [passSettingDigitsPresence, setPassSettingDigitsPresence] = useState<boolean>(settingsValue[0].digits_presence);
  const [passSettingMinCharactersCount, setPassSettingMinCharactersCount] = useState<string>(String(settingsValue[0].min_characters_count));

  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  useEffect(() => {
    setSettingsValue(generatorPassword.settings);
    setHintPasswordValue(String(generatorPassword.settings[passSettingsValue - 1].value));
  }, [generatorPassword.settings]);

  const setHintPasswordValue = (value: string) => {
    const HintValue = generatorPassword.settings.find(x => x.value === Number(value));

    setPassSettingsValue(Number(value));
    setPassSettingsLowerCaseLettersPresence(HintValue ? HintValue.lowercase_letters_presence : passSettingsLowerCaseLettersPresence);
    setPassSettingsUppercaseLettersPresence(HintValue ? HintValue.uppercase_letters_presence : passSettingsUppercaseLettersPresence);
    setPassSettingsSpecialCharsPresence(HintValue ? HintValue.specialchars_presence : passSettingsSpecialCharsPresence);
    setPassSettingDigitsPresence(HintValue ? HintValue.digits_presence : passSettingDigitsPresence);
    setPassSettingMinCharactersCount(HintValue ? String(HintValue.min_characters_count) : passSettingMinCharactersCount);
  };

  const handlePasswordSetting = (value: passwordSettingValueType) => {
    setGlobalSettings({
      name: "PASSWORD_REQUIREMENTS",
      value: passSettingsValue,
      passwordSettingValue: value,
    });
  };

  const validateCheckbox = (isChecked: boolean): boolean => {
    const allCheckboxesValue = [
      passSettingsLowerCaseLettersPresence,
      passSettingsUppercaseLettersPresence,
      passSettingsSpecialCharsPresence,
      passSettingDigitsPresence,
    ];

    if (passSettingMinCharactersCount === String(MIN_PASS_LENGTH) && !isChecked ) {
      const trueCount = allCheckboxesValue.filter(value => value).length;
      return trueCount >= MIN_PASS_LENGTH;
    }
    return false;
  };

  const handleMinCharactersSelect = (selected: SingleValue<typeSelectedFolder>) => {
    if (!selected || selected.value === passSettingMinCharactersCount) return;

    const settingsValues = settingsValue[passSettingsValue - 1];
    const trueValuesCount = Object.values(settingsValues).filter(value => value === true).length;

    if (selected.value === String(MIN_PASS_LENGTH) && trueValuesCount > MIN_PASS_LENGTH) {
      handlePasswordSetting({
        min_characters_count: selected.value,
        lowercase_letters_presence: false,
        uppercase_letters_presence: false,
        specialchars_presence: false,
        digits_presence: false,
      });

      toast.info(<Trans i18nKey="hint_pass_settings_warning" />);
    } else {
      handlePasswordSetting({ min_characters_count: selected.value });
    }
  };

  const handleCheckboxDisable = () => {
    toast.info(
      <Trans i18nKey="hint_pass_settings_checkbox_disabled_warning" />,
    );
  };

  return (
    <div className={ cnSettings("Container") }>
      <div className={ cnSettings("SwitchItem HintPassword") }>
        <div className={ cnSettings("Header") }>{t("hint_pass_settings_title")}</div>
        <div className={ cnSettings("Hint") }>{t("hint_pass_settings_description")}</div>
        <div className={ cnSettings("Select") }>
          <label>{t("hint_pass_settings_security_level")}</label>
          <Select
            className={ cnSettings("PassSelect") }
            isClearable={ false }
            isSearchable={ false }
            options={ settingsValue.map(e => ({
              label: t(`security-level-${e.value}`).toString(),
              value: e.value,
            })) }
            styles={ customSelectStyles({ isMultiple: false }, mainColor) }
            theme={ theme => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#EAFBFD",
                primary75: mainColor,
                primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
              },
            }) }
            value={ settingsValue
              .filter(x => x.value === passSettingsValue)
              .map(e => ({
                label: t(`security-level-${e.value}`).toString(),
                value: e.value,
              })) }
            onChange={ selected => selected && setHintPasswordValue(selected.value.toString()) }
          />
        </div>
        <div className={ cnSettings("Select") }>
          <label>{t("hint_pass_settings_password_length")}</label>
          <Select
            className={ cnSettings("PassSelect") }
            isClearable={ false }
            isSearchable={ false }
            options={ optionsLengthString }
            styles={ customSelectStyles({ isMultiple: false }, mainColor) }
            theme={ theme => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#EAFBFD",
                primary75: mainColor,
                primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
              },
            }) }
            value={ optionsLengthString
              .filter(x => String(x.value) === passSettingMinCharactersCount)
              .map(() => ({
                label: passSettingMinCharactersCount,
                value: passSettingMinCharactersCount,
              })) }
            onChange={ handleMinCharactersSelect }
          />
        </div>
        <div
          className={ cnSettings("Switch-Right") }
          onClick={ validateCheckbox(passSettingsLowerCaseLettersPresence) ? handleCheckboxDisable : undefined }
        >
          <Checkbox
            checked={ passSettingsLowerCaseLettersPresence }
            disabled={ validateCheckbox(passSettingsLowerCaseLettersPresence) }
            id="checkbox_pass_settings_lowercase_letters_presence"
            onChange={ () => handlePasswordSetting({ lowercase_letters_presence: !passSettingsLowerCaseLettersPresence }) }
          />
          <label
            htmlFor="checkbox_pass_settings_lowercase_letters_presence"
          >
            {t("hint_pass_settings_lowercase")}
          </label>
        </div>

        <div
          className={ cnSettings("Switch-Right") }
          onClick={ validateCheckbox(passSettingsUppercaseLettersPresence) ? handleCheckboxDisable : undefined }
        >
          <Checkbox
            checked={ passSettingsUppercaseLettersPresence }
            disabled={ validateCheckbox(passSettingsUppercaseLettersPresence) }
            id="checkbox_pass_settings_uppercase_letters_presence"
            onChange={ () => handlePasswordSetting({ uppercase_letters_presence: !passSettingsUppercaseLettersPresence }) }
          />
          <label htmlFor="checkbox_pass_settings_uppercase_letters_presence">
            {t("hint_pass_settings_uppercase")}
          </label>
        </div>

        <div
          className={ cnSettings("Switch-Right") }
          onClick={ validateCheckbox(passSettingsSpecialCharsPresence) ? handleCheckboxDisable : undefined }
        >
          <Checkbox
            checked={ passSettingsSpecialCharsPresence }
            disabled={ validateCheckbox(passSettingsSpecialCharsPresence) }
            id="checkbox_pass_settings_special_chars_presence"
            onChange={ () => handlePasswordSetting({ specialchars_presence: !passSettingsSpecialCharsPresence }) }
          />
          <label htmlFor="checkbox_pass_settings_special_chars_presence">{t("hint_pass_settings_special")}</label>
        </div>

        <div
          className={ cnSettings("Switch-Right") }
          onClick={ validateCheckbox(passSettingDigitsPresence) ? handleCheckboxDisable : undefined }
        >
          <Checkbox
            checked={ passSettingDigitsPresence }
            disabled={ validateCheckbox(passSettingDigitsPresence) }
            id="checkbox_pass_settings_digits_presence"
            onChange={ () => handlePasswordSetting({ digits_presence: !passSettingDigitsPresence }) }
          />
          <label htmlFor="checkbox_pass_settings_digits_presence">{t("hint_pass_settings_digits")}</label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { generatorPassword, customize } }: RootState) => ({
  generatorPassword,
  customize,
});

const mapDispatchToProps = { setGlobalSettings };

export default connect(mapStateToProps, mapDispatchToProps)(PasswordGeneratorControl);
