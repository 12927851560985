import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { cnContent } from "components/Layout";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Content: FC<IProps> = ({ children, className = "", ...props }) => {
  return (
    <div className={`${cnContent()} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default Content;
