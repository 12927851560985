/* eslint-disable no-nested-ternary */
import { cn } from "@bem-react/classname";

import { ButtonDiv, FolderStatus } from "components/Generic";

import { getIDsActiveFolder, getKey, PRODUCT_TYPE, ROLE_TYPE, typeFolder, typeSelectedFolder } from "core";
import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FolderIcon } from "ss-ui";
import { RootState } from "store/reducers";
import { find } from "lodash";

export const cnTree = cn("ModalSelectTree");

type Props = {
  activeFolder?: string | null;
  folder: typeFolder & { onChange?: (value: any) => void };
  folderID?: string;
  folders: typeFolder[];
  level?: number;
  onChange: (value: any) => void;
  productType: PRODUCT_TYPE;
  role: ROLE_TYPE;
  search?: string;
  folderList?: string[];
  isMulti?: boolean;
  selectedFolders?: typeSelectedFolder[];
  accessArr: string[];
  shareFolder?: string;
};

const Tree: FC<Props> = ({
  isMulti,
  selectedFolders,
  role,
  productType,
  activeFolder,
  folder,
  folderID = "",
  folders,
  level = 0,
  onChange,
  search = "",
  folderList = [],
  accessArr,
  shareFolder,
}) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(true);

  const { id, title, children, countKeys, type, root, access, level_security } = folder;

  const activeTreeFolder = useMemo(() => getIDsActiveFolder(folders, String(activeFolder)), [folders, activeFolder]);
  const isActive = useMemo(() => activeFolder === id, [activeFolder, id]);
  const isActiveMulti = useMemo(() => !!find(selectedFolders, ["value", id]), [selectedFolders, id]);
  const isAccess: boolean = useMemo(() => access && accessArr.includes(access), [access, accessArr]);
  const isExistFolder = useMemo(() => folderList && folderList.includes(id), [folderList, id]);
  const isSharedFolder = useMemo(() => shareFolder && shareFolder === id, [shareFolder, id]);

  useEffect(() => {
    activeTreeFolder.pop();
    if (activeTreeFolder.includes(folder.id)) {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, []);

  if (id === folderID) {
    return null;
  }

  const handleClickFolder = () => {
    setOpen(!isOpen);
  };

  const handleClick = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    if (isMulti) {
      onChange({
        value: id,
        label: folder.title,
        root,
      });
    } else {
      onChange({
        value: id,
        label: folder.title,
      });
    }
  };

  const handleClickClear = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
  };

  return (
    <>
      {search === "" ? (
        <div className={cnTree({ level })}>
          <ButtonDiv
            className={cnTree("Folder", { isActive, isExistFolder, isActiveMulti })}
            key={id}
            onClick={handleClickFolder}
            id={getKey(title, level)}
          >
            {children && children.length > 0 ? <FolderStatus id={getKey(`folder_status_${title}`, level)} isActive={!isOpen} /> : <div className="EmptyFolderStatus">&nbsp;</div>}
            <div className={cnTree(!isAccess || isSharedFolder ? "No_access" : "")}>
              <ButtonDiv onClick={!isAccess || isExistFolder || isSharedFolder ? handleClickClear : handleClick} id={getKey(`btn_open_folder_${title}`)}>
                <FolderIcon
                  level={level_security}
                  size={30}
                  isRoot={root === null && type !== "group"}
                  isGroup={type === "group"}
                  access={type === "group" ? access : "" || root === null ? "write" : ""}
                />
              </ButtonDiv>
            </div>
            <ButtonDiv
              onClick={!isAccess || isExistFolder || isSharedFolder ? handleClickClear : handleClick}
              className={cnTree("Title")}
              id={getKey(`btn_div_title_${folder.title}`, level)}
              title={folder.title}
            >
              {folder.title}
              <div className={cnTree("Count")}>
                {children.length} {t("navigation_folders")} {countKeys} {t("navigation_keys")}
              </div>
            </ButtonDiv>
          </ButtonDiv>
          {!isOpen &&
            children.length > 0 &&
            children.map((folder: typeFolder & { onChange?: (value: any) => void }) => (
              <Tree
                activeFolder={activeFolder}
                folder={folder}
                folderID={folderID}
                folders={folders}
                key={folder.id}
                level={level_security}
                onChange={onChange}
                productType={productType}
                role={role}
                search={search}
                folderList={folderList}
                isMulti={isMulti}
                selectedFolders={selectedFolders}
                accessArr={accessArr}
                shareFolder={shareFolder}
              />
            ))}
        </div>
      ) : (
        <div className={cnTree("")}>
          {title.toLowerCase().indexOf(search.toLowerCase()) === -1 ? (
            ""
          ) : (
            <ButtonDiv
              className={cnTree("Folder", { isActive, isExistFolder, isActiveMulti })}
              key={id}
              onClick={handleClickFolder}
              id={getKey(title, level)}
            >
              <div>&nbsp;&nbsp;&nbsp;</div>
              <div className={cnTree(!isAccess || isSharedFolder ? "No_access" : "")}>
                <ButtonDiv onClick={!isAccess || isSharedFolder ? handleClickClear : handleClick} id={getKey(`btn_open_folder_access_${title}`)}>
                  <FolderIcon
                    access={type === "group" ? access : "" || root === null ? "write" : ""}
                    isGroup={type === "group"}
                    isRoot={root === null && type !== "group"}
                    level={level_security}
                    size={30}
                  />
                </ButtonDiv>
              </div>
              <ButtonDiv
                onClick={!isAccess || isSharedFolder ? handleClickClear : handleClick}
                className={cnTree("Title")}
                id={getKey(`btn_div_title_${folder.title}`, level)}
              >
                <Highlighter highlightClassName={cnTree("SearchHighLight")} searchWords={[search]} textToHighlight={folder.title} />
                <div className={cnTree("Count")}>
                  {children.length} {t("navigation_folders")} {countKeys} {t("navigation_keys")}
                </div>
              </ButtonDiv>
            </ButtonDiv>
          )}

          {children.length > 0 &&
            children.map((folder: typeFolder & { onChange?: (value: any) => void }) => (
              <Tree
                activeFolder={activeFolder}
                folder={folder}
                folderID={folderID}
                folders={folders}
                key={folder.id}
                level={level_security}
                onChange={onChange}
                productType={productType}
                role={role}
                search={search}
                isMulti={isMulti}
                selectedFolders={selectedFolders}
                accessArr={accessArr}
              />
            ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ settings: { productType }, user: { role } }: RootState) => ({
  productType,
  role,
});

export default connect(mapStateToProps, {})(Tree);
