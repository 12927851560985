import { all, fork } from "redux-saga/effects";

import { saga as folderChose } from "store/routines/folders/chose";
import { saga as folderClear } from "store/routines/folders/clear";
import { saga as folderDelete } from "store/routines/folders/delete";
import { saga as folderFavorite } from "store/routines/folders/favorite";
import { saga as folderGet } from "store/routines/folders/get";
import { saga as folderGetAll } from "store/routines/folders/getAll";
import { saga as folderGetAllWithKeys } from "store/routines/folders/getAllWithKeys";
import { saga as folderGetTree } from "store/routines/folders/tree";
import { saga as folderManage } from "store/routines/folders/manage";
import { saga as folderSetItemByID } from "store/routines/folders/setItemByID";

export default function* folders() {
  return yield all([
    fork(folderChose),
    fork(folderClear),
    fork(folderDelete),
    fork(folderFavorite),
    fork(folderGet),
    fork(folderGetAll),
    fork(folderGetAllWithKeys),
    fork(folderGetTree),
    fork(folderManage),
    fork(folderSetItemByID),
  ]);
}
