import { Action } from "redux-actions";
import { typeAccessDevice } from "core";
import { getGlobalSettings, setGlobalSettings } from "store/actions";
import { find } from "lodash";

export interface SettingsAccessDeviceState {
  isLoading: boolean;
  settings: typeAccessDevice;
}

const initialState = {
  isLoading: false,
  settings: {
    browser_extension: true,
    mobile_app: true,
    web_browser: true,
  }
};

export default function accessControl(
  state: SettingsAccessDeviceState = initialState,
  { payload, type }: Action<any>,
): SettingsAccessDeviceState {
  switch (type) {
    case getGlobalSettings.TRIGGER:
    case setGlobalSettings.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case getGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: find(payload, ["setting", "ACCESS_DEVICES"])?.value,
      };

    case setGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: payload.setting === "ACCESS_DEVICES" ? payload.value : state.settings,
      };

    case getGlobalSettings.FAILURE:
    case getGlobalSettings.FULFILL:
    case setGlobalSettings.FAILURE:
    case setGlobalSettings.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
