import { cnSettings } from "pages/Settings";
import { CONVEYANCE_ACTION, CONVEYANCE_PRODUCTS, ExportOptions, ImportOptions, typeProductOption } from "pages/Settings/SettingsType";
import React, { FC, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useTranslation } from "react-i18next";
import { Icon, Button } from "ss-ui";

type Props = {
  onClickMenu: (action: CONVEYANCE_ACTION, product: CONVEYANCE_PRODUCTS) => void;
  type: CONVEYANCE_ACTION;
};

const ConveyanceMenu: FC<Props> = ({ onClickMenu, type }) => {
  const { t } = useTranslation();

  const conveyanceMenu = type === CONVEYANCE_ACTION.IMPORT ? ImportOptions : ExportOptions;

  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <div className={cnSettings("ButtonWrapper")}>
      <Button
        id={`btn_settings_${type}`}
        type="button"
        onClick={() => setOpen(!isOpen)}
        icon={type === "import" ? "icon-pointer-down" : "icon-pointer-up"}
      >
        {t(`settings_button_${type}`)}
        <Icon name="icon-arrow-down" fill="#ffffff" className={cnSettings("Arrow")} />
      </Button>
      {/* <button
        id={`btn_settings_${type}`}
        type="button"
        className={cnSettings("Button", { isOpen }, ["import"])}
        onClick={() => setOpen(!isOpen)}
      >
        <Icon name="icon-pointer-left" fill="#ffffff" width={15} height={16} className={cnSettings("Icon", [type])} />{" "}
        {t(`settings_button_${type}`)}
        <Icon name="icon-arrow-down" fill="#ffffff" className={cnSettings("ButtonArrow")} />
      </button> */}
      {isOpen && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={cnSettings("Options")}>
            {conveyanceMenu.map(({ name, code }: typeProductOption) => {
              return (
                <button
                  type="button"
                  className={cnSettings("Option")}
                  id={`btn_settings_${code}`}
                  onClick={() => {
                    onClickMenu(type, code);
                    setOpen(false);
                  }}
                  key={code}
                >
                  <span>
                    <Icon name={`colored/icon-settings-${code}`} fill="#ffffff" width={20} height={20} />
                  </span>
                  {name}
                </button>
              );
            })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ConveyanceMenu;
