import { http } from "core/services";

import { preparePublicKeyCredentials, preparePublicKeyOptions } from "./common";

/**
 * Hook for login by yubikey
 * @param {string} actionUrl
 * @return {function(*): Promise<any|string>}
 */
const useLogin = ({ actionUrl = "/yubikey/token/create" }: any) => {
  return async data => {
    const publicKey = preparePublicKeyOptions(data.authenticateOptions);
    const credentials = await navigator.credentials.get({ publicKey });
    const publicKeyCredential = preparePublicKeyCredentials(credentials);

    return http({
      isProtected: false,
      method: "POST",
      route: actionUrl,
      payload: {
        user: data.user,
        data:publicKeyCredential
      },
    });
  };
};

export default useLogin;
