import { cn } from "@bem-react/classname";

export const cnInfoBox = cn("InfoBox");
export const cnInfoBoxFolder = cn("InfoBoxFolder");

export { default as InfoBox } from"./InfoBox/InfoBox";
export { default as InfoBoxActions } from"./InfoBox/Fragments/Actions";
export { default as InfoBoxContent } from"./InfoBox/Fragments/Content";
export { default as InfoBoxHeader } from"./InfoBox/Fragments/Header";
export { default as InfoBoxTabs } from"./InfoBox/Fragments/Tabs";

export { default as InfoBoxGroup } from "./Group/InfoBoxGroup";
export { default as InfoBoxKey } from "./Key/InfoBoxKey";
export { default as InfoBoxUser } from "./User/InfoBoxUser";
