import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";

import { http } from "core";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("HISTORIES_STATUS");

const getAll = () => {
  return http({ route: "history-state" });
};

function* handler() {
  try {
    const response = yield call(getAll);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
