import i18n from "locales/i18n";
import { get, snakeCase } from "lodash";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

export type Payload = {
  avatar: string;
};

export const action: Routine = createRoutine("USER_SET_AVATAR");

const setAvatar = ({ avatar }: Payload) => {
  const data = new FormData();

  data.append("avatar", avatar);

  return http({
    route: "user/avatar",
    method: "POST",
    payload: { avatar },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(setAvatar, payload);

    yield put(action.success(payload.avatar));
  } catch ({ response: { data } }) {
    const errorMessage = get(data, "response.data", false);

    if (get(data, "error", "") === "account_is_blocked") {
      yield put(action.failure());
    } else if (errorMessage) {
      const regex = /\(\d+\/\d+\)px/gm;
      const regexEmpty = /\(\/\)px/gm;
      const match = errorMessage.match(regex);
      const size: string = match ? match[0] : "(0/0)px";
      const key = `error_${snakeCase(errorMessage.replace(match ? regex : regexEmpty, ""))}`;

      yield put(action.failure(i18n.t(key, { size })));
    } else {
      yield put(action.failure(i18n.t("toast_error_something_went_wrong")));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
