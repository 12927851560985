import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = { secret: string; clear?: boolean };

export const action: Routine = createRoutine("KEYS_OTP_DECODE");

const get = ({ secret }: Payload) => {
  return http({
    method: "POST",
    route: "otp-decode",
    payload: {
      secret,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = payload.clear === true ? undefined : yield call(get, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
