import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = {
  valueFolderSharing?: boolean;
  valueKeyCreation?: boolean,
  valueKeyDeleting?: boolean,
  valueKeySharing?: boolean,
  valueKeySharingRemoval?: boolean,
};

export const action: Routine = createRoutine("SET_FOLDER_NOTIFY");

const setFolderNotify = ({ 
  valueFolderSharing,
  valueKeyCreation,
  valueKeyDeleting,
  valueKeySharing,
  valueKeySharingRemoval,
}: Payload) => {
  return http({
    route: "user/settings",
    method: "POST",
    payload: {
      folderNotification: {
        groupFolderSharing: valueFolderSharing,
        groupKeyCreation: valueKeyCreation,
        groupKeyDeleting: valueKeyDeleting,
        groupKeySharing: valueKeySharing,
        groupKeySharingRemoval: valueKeySharingRemoval,
      }
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setFolderNotify, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
