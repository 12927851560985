import i18n from "locales/i18n";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

export interface FormValues {
  userPassword: string;
}

export interface Payload {
  userPassword: FormValues;
}

export const action: Routine = createRoutine("SET_BACKUP_PARAMS");

const setBackUpParams = ({ userPassword }: Payload) => {
  const data = { password: userPassword };
  return http({
    method: "POST",
    payload: data,
    route: "autobackup_details",
    isProtected: true,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setBackUpParams, payload);
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(null));
    toast.error(i18n.t("error_invalid_credentials"));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}