import React, { FC, useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { ContentWrapper, Content, ContentHeader, Aside, cnContentHeader } from "components/Layout";
import { Header } from "components/Navigation";
import { LoadingContainer, Pagination } from "components/Generic";
import InfoBoxActivityLog from "components/InfoBox/ActivityLog/InfoBoxActivityLog";

import {
  HistoryNavigation,
  IActivityLogFilter,
  SORT_BY,
  DIRECTION,
  PayloadChoseFunction,
  typeAccessControl,
  getAccessControl,
  ROLE_TYPE
} from "core";

import { RootState } from "store/reducers";
import { ActivityLogChose, setHeaderTitle, setHeaderIcon, ActivityLogGetAll } from "store/actions";
import { Payload as PayloadActivityLogTable } from "store/routines/activity-log/getAll";

import ActivityLogFilters from "pages/ActivityLog/ActivityLogFilter";
import ActivityLogTable from "pages/ActivityLog/ActivityLogTable";

export const cnActivityLog = cn("ActivityLog");

const initialActivityLogFilters: IActivityLogFilter = {
  activePage: 1,
  period: {
    start: moment().subtract(1, "months").toDate(),
    end: moment().toDate(),
  },
  object: undefined,
  operations: [],
  users: [],
  sortBy: undefined,
  sortOrder: undefined,
};

interface Props {
  ActivityLogChose: (payload: PayloadChoseFunction) => void;
  ActivityLogGetAll: (payload: PayloadActivityLogTable) => void;
  chosen: string[];
  isLoading: boolean;
  pagesCount: number;
  setHeaderIcon: (payload: string) => void;
  setHeaderTitle: (payload: string) => void;
  role: ROLE_TYPE;
  accessSettings?: typeAccessControl;
}

const ActivityLog: FC<Props> = ({
  ActivityLogChose,
  ActivityLogGetAll,
  setHeaderIcon,
  setHeaderTitle,
  chosen,
  isLoading,
  pagesCount,
  role,
  accessSettings,
}) => {
  const { t } = useTranslation();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<IActivityLogFilter>(initialActivityLogFilters);

  const isAccessActivityLog: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessActivityLog, role), [role, accessSettings]);
  const isAdmin: boolean = useMemo(() => [ROLE_TYPE.ADMIN].includes(role), [role]);

  /**
   * Set header and Icon in header associate with Activity log
   */
  useEffect(() => {
    setHeaderTitle(t("nav-Activity Log"));
    setHeaderIcon("file-with-gear");
  }, []);

  /**
   * Getting activity logs from server when some value in the filters changed
   */
  useEffect(() => {
    ActivityLogGetAll({ filters });
  }, []);

  /**
   * Hidden filter popup if some log is opened and chosen is not empty
   */
  useEffect(() => {
    if (chosen.length > 0 && showFilters) {
      setShowFilters(false);
    }
  }, [chosen]);

  const handleFilter = () => {
    setShowFilters(false);
    setFilters(filters => ({
      ...filters,
      activePage: 1,
    }));
    ActivityLogGetAll({ filters: { ...filters, activePage: 1 } });
  };

  const handlePageChange = (page: number) => {
    setFilters({ ...filters, activePage: page });
    ActivityLogGetAll({ filters: { ...filters, activePage: page } });
  };

  /**
   * Set the initial values for the filter, close the filter popup
   */
  const handleCancelFilter = () => {
    setShowFilters(false);
    setFilters(initialActivityLogFilters);
    ActivityLogGetAll({ filters: initialActivityLogFilters });
  };

  const handleToggleFilter = () => {
    /**
     * Hidden log if filter opened and chosen ot empty
     */
    if (!showFilters && chosen.length > 0) {
      ActivityLogChose({ status: false, choseAll: true })
    }
    setShowFilters(!showFilters);
  };

  const handleSort = (sortBy?: SORT_BY) =>
    sortBy &&
    setFilters(filters => ({
      ...filters,
      sortBy,
      sortOrder: filters.sortOrder === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC,
    }));

  // const handleExport = () => {
  //   console.log("CLICK EXPORT HERE");
  // };

  // const handleRetention = () => {
  //   console.log("CLICK RETENTION HERE");
  // };

  return (
    <ContentWrapper id="activity_log_content_wrapper">
      <Content id="activity_log_content">
        {!isAccessActivityLog && !isAdmin ? (
          <div className="Table-EmptyStatus">
            <p>{t("settings_access_control_denied")}</p>
          </div>
        ) : (
          <>
            <ContentHeader id="activity_log_content_header">
              <Header linkList={HistoryNavigation} />
              <Button
                icon="icon-filter"
                id="btn_show_filter"
                isActive={showFilters}
                onClick={() => handleToggleFilter()}
                className={cnContentHeader("Margin")}
                size={30}
                theme="primary-outline"
              />
              {/* <Button icon="icon-move-out" onClick={handleExport} theme="primary-outline" id="btn_show_export" size={30} /> */}
            </ContentHeader>
            <LoadingContainer isLoading={isLoading}>

              <div className={cnActivityLog()}>
                <ActivityLogTable handleSort={handleSort} filters={filters} />
              </div>
              {pagesCount > 1 && (
                <Pagination
                  id="activity_log_pagination"
                  currentPage={filters.activePage}
                  itemPagesCount={pagesCount}
                  onChange={(page) => handlePageChange(page)}
                />
              )}

            </LoadingContainer>
          </>
        )}
      </Content>
      <Aside side="right" id="activity_log_aside_right">
        {/* {chosen.length === 0 && !showFilters && ( */}
        {/*   <div> */}
        {/*     <div className={cnActivityLog("Retention")}> */}
        {/*       <b>-T- 180 days</b>&nbsp;retention&nbsp; */}
        {/*       /!* <Icon name="icon-info-circle" fill="#A7A7A8" /> *!/ */}
        {/*       <Button theme="primary-link" onClick={handleRetention}> */}
        {/*         -T- Change */}
        {/*       </Button> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* )} */}
        {chosen.length > 0 && !showFilters && <InfoBoxActivityLog />}
        {showFilters && (
          <ActivityLogFilters
            filters={filters}
            handleCancel={handleCancelFilter}
            handleClose={setShowFilters}
            handleSubmit={handleFilter}
            setFilters={setFilters}
          />
        )}
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  activityLog: { chosen, isLoading, pagesCount },
  settingsTest: { accessControl: { settings: accessSettings } },
  user: { role },
}: RootState) => ({
  pagesCount,
  chosen,
  isLoading,
  accessSettings,
  role,
});

const mapDispatchToProps = {
  ActivityLogChose,
  ActivityLogGetAll,
  setHeaderIcon,
  setHeaderTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
