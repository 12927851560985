/* eslint-disable react-hooks/rules-of-hooks, react/jsx-no-target-blank
, no-lone-blocks, react/no-danger, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, no-nested-ternary,
 jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Redirect, useParams, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, FolderIcon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { Breadcrumbs, Pagination, LoadingContainer } from "components/Generic";
import useLocalStorage from "core/hook/useLocalStorage";
import { ManageKey } from "pages/ManageKey";
import {
  Content,
  cnContentHeaderMobile,
  ContentWrapper,
  ContentHeader,
  ContentHeaderMobile,
  Aside,
  Filter,
} from "components/Layout";
import {
  BreadcrumbsItem,
  clearUrlParam,
  DISPLAY_TYPE,
  emptyFolder,
  findFolderInTree,
  FOLDER_ACCESS,
  FOLDER_TYPE,
  getFolderFromTreeById,
  getIDsActiveFolder,
  getUserEnvKey,
  LOCAL_STORAGE,
  PayloadChoseFunction,
  PRODUCT_TYPE,
  replaceUrlParam,
  ROLE_TYPE,
  Routes,
  typeFolder,
  typeKey,
  getPortalLink,
} from "core";

import {
  favoritesSetFolder,
  folderChose,
  folderSetItemByID,
  keyChose,
  setHeaderIcon,
  setHeaderTitle,
  setOpenedFolder,
  getKeysPagination,
} from "store/actions";
import { RootState } from "store/reducers";
import { Payload as PayloadGetKeysPagination } from "store/routines/keys/getKeysPagination";
import { Payload as PayloadFavoritesSetFolder } from "store/routines/favorites/setFolder";

import KeysBlock from "./KeysBlock/KeysBlock";
import KeysList from "./KeysList/KeysList";
import KeysAside from "./KeysAside/KeysAside";

const cnFilter = cn("Filter");

type Props = {
  favoritesSetFolder: (payload: PayloadFavoritesSetFolder) => void;
  folderChose: (payload: PayloadChoseFunction) => void;
  folderSetItemByID: (payload: string) => void;
  foldersList: typeFolder[];
  getKeysPagination: (payload: PayloadGetKeysPagination) => void;
  keysList: typeKey[];
  limitingKeys: number;
  productType: PRODUCT_TYPE;
  role: ROLE_TYPE;
  setHeaderTitle: (payload: string) => void;
  setHeaderIcon: (payload: string) => void;
  setOpenedFolder: (payload: string) => void;
  username?: string;
  chosenFolder: string[];
  chosenKeys: string[];
  currentPage: number;
  pagesCount: number;
  totalItems: number;
  isLoadingFolder: boolean;
  isLoadingKeys: boolean;
  moveSuccess: boolean;
};

const Keys: FC<Props> = ({
  favoritesSetFolder,
  folderChose,
  folderSetItemByID,
  foldersList,
  getKeysPagination,
  keysList,
  limitingKeys,
  productType,
  role,
  setHeaderTitle,
  setHeaderIcon,
  setOpenedFolder,
  username,
  chosenFolder,
  chosenKeys,
  currentPage,
  pagesCount,
  totalItems,
  isLoadingFolder,
  isLoadingKeys,
  moveSuccess,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { folderID } = useParams<{ folderID: string }>();
  const isKeyManage = useRouteMatch(Routes.ManageKey);
  const [displayType, setDisplayType] = useLocalStorage(getUserEnvKey(LOCAL_STORAGE.DISPLAY_TYPE, username), DISPLAY_TYPE.LIST);
  const folder: typeFolder = useMemo(() => getFolderFromTreeById(foldersList, folderID) || emptyFolder, [foldersList, folderID]);
  const countKeys = Number(foldersList.filter(e => e.type === "personal" && e.root === null).map(item => item.countKeys));
  const folderAccess: string = useMemo(
    () => (folder.type === FOLDER_TYPE.GROUP ? folder.access : folder.root === null ? "write" : ""),
    [folder],
  );
  const hasAccess: boolean = useMemo(() => folder && FOLDER_ACCESS.WRITE === folder.access, [folder]);
  const isFolderWritable: boolean = useMemo(() => FOLDER_ACCESS.WRITE === folder.access, [folder]);
  const isNoAccess: boolean = useMemo(() => FOLDER_ACCESS.NO_ACCESS === folder.access, [folder]);
  const isFreeUser: boolean = useMemo(() => role === ROLE_TYPE.FREE_USER, [role]);
  const isGroupAndRoleUser: boolean = useMemo(() => folder.type === FOLDER_TYPE.GROUP && role === ROLE_TYPE.USER, [folder, role]);
  const isStandard: boolean = useMemo(() => productType === PRODUCT_TYPE.STANDARD, [productType]);
  const isTile: boolean = useMemo(() => displayType === DISPLAY_TYPE.TILE, [displayType]);

  const isAdmin = role === ROLE_TYPE.ADMIN;

  const [filterSearchValue, setFilterSearchValue] = useState<string>("");
  const [showFilters, setShowFilters] = useState<boolean>(false);

  useEffect(() => {
    if (folderID) {
      folderSetItemByID(folderID);
      setOpenedFolder(folderID);
      setFilterSearchValue("");
      setShowFilters(false);
      getKeysPagination({
        folderID,
        page: 1,
        search: "",
      });
    }
  }, [folderID]);

  useEffect(() => {
    if (moveSuccess) {
      getKeysPagination({
        folderID,
        page: 1,
        search: "",
      });
    }
  }, [moveSuccess]);

  useEffect(() => {
    setHeaderTitle(folder ? folder.title : t("navigation_bottom-Folder management"));
    setHeaderIcon("folder");
  }, [folder.title]);

  useEffect(() => {
    if (chosenKeys.length > 0 || chosenFolder.length > 0) {
      setShowFilters(false);
    }
  }, [chosenKeys, chosenFolder]);

  if ((!folderID || !folder) && foldersList.length > 0) {
    return <Redirect to={ replaceUrlParam(Routes.Keys, "folderID", foldersList[0].id) } />;
  }

  const activeFolder: string[] = getIDsActiveFolder(foldersList, folderID);

  const BreadcrumbsList: BreadcrumbsItem[] =
    activeFolder &&
    activeFolder.map((id: string) => {
      const folder = findFolderInTree(foldersList, id);
      const folderName = (folder && folder.title) || "";

      return {
        title: folderName,
        link: folder ? replaceUrlParam(Routes.Keys, "folderID", folder.id) : "",
      };
    });

  const handleAddFolder = (id: string) =>
    folderChose({
      isNew: id === "new",
      status: true,
      id,
    });

  const handleChangePage = (page: number) => {
    folderChose({
      status: false,
      choseAll: true,
    });
    getKeysPagination({
      folderID,
      page,
    });
  };

  const handleAddKey = () => {
    if (isFreeUser && countKeys >= limitingKeys) {
      toast.info(
        <Trans i18nKey="toast_free_user_key_limit">
          <a
            href={ `${getPortalLink(window.location.host)}/login` }
            target="_blank"
          >
            Upgrade
          </a>
        </Trans>,
        {
          toastId: "toast_free_user_key_limit",
          autoClose: false,
          position: "top-center",
        },
      );
    } else {
      push(clearUrlParam(replaceUrlParam(Routes.ManageKey, "folderID", folder.id)));
    }
  };

  const handleFilterCancel = () => {
    setFilterSearchValue("");
    getKeysPagination({
      folderID,
      page: 1,
      search: "",
    });
  };

  const handleFilter = () => {
    folderChose({
      status: false,
      choseAll: true,
    });
    setShowFilters(false);
    getKeysPagination({
      folderID,
      page: 1,
      search: filterSearchValue,
    });
  };

  const handleFavorite = (folderID: string) => {
    favoritesSetFolder({ folderID });
  };

  return (
    <ContentWrapper id="keys_content_wrapper">
      <Content id="keys_content">
        <ContentHeader id="keys_content_header">
          <Breadcrumbs
            folderType={ folder.type }
            items={ BreadcrumbsList }
          />
            <Button
              icon={ isTile ? "icon-key-list" : "icon-key-block" }
              id="button_add_user"
              size={ 30 }
              theme="primary-outline"
              onClick={ () => setDisplayType(isTile ? DISPLAY_TYPE.LIST : DISPLAY_TYPE.TILE) }
            />
            <Button
              icon="icon-filter"
              id="button_add_user"
              isActive={ showFilters }
              size={ 30 }
              theme="primary-outline"
              onClick={ () => setShowFilters(!showFilters) }
            />
        </ContentHeader>

        <ContentHeaderMobile>
          <div className={ cnContentHeaderMobile("Info") }>
            <div className={ cnContentHeaderMobile("Folder") }>
              <FolderIcon
                access={ !isStandard ? folderAccess : "" }
                isGroup={ !isStandard && folder.type === FOLDER_TYPE.GROUP }
                isRoot={ folder.root === null && folder.type !== FOLDER_TYPE.GROUP }
                level={ folder.level_security }
                size={ 30 }
              />
              <h2>{folder.title}</h2>
            </div>
          </div>
          <div className={cnContentHeaderMobile("Button")}>
            {(hasAccess || isAdmin) && (
              <>
                {isGroupAndRoleUser || isFreeUser ? null : (
                  <Button
                    icon={ folder.favorite ? "icon-star-fill" : "icon-star" }
                    id="button_handle_folder_favorite"
                    size={ 30 }
                    type="button"
                    onClick={ () => handleFavorite(folder.id) }
                  />
                )}
              </>
            )}
            {!isAdmin && (!isFolderWritable || isGroupAndRoleUser || isFreeUser) ? null : (
              <Button
                icon="icon-pencil"
                id="button_edit_key_mobile"
                size={ 30 }
                onClick={ () => handleAddFolder(folder.id) }
              />
            )}
            {(hasAccess || isAdmin) && (
              <>
                {isAdmin && folder.type === FOLDER_TYPE.GROUP ? null : (
                  <Button
                    icon="icon-key-add"
                    id="button_add_key_mobile"
                    size={ 30 }
                    onClick={ () => handleAddKey() }
                  />
                )}
                {isGroupAndRoleUser || isFreeUser ? null : (
                  <Button
                    icon="icon-folder-add"
                    id="button_add_folder_mobile"
                    size={ 30 }
                    onClick={ () => handleAddFolder("new") }
                  />
                )}
              </>
            )}
          </div>
        </ContentHeaderMobile>

        {isTile ? <KeysBlock folder={ folder } /> : <KeysList folder={ folder } />}

        {pagesCount > 1 && (
          <Pagination
            currentPage={ currentPage }
            id="keys_pagination"
            itemPagesCount={ pagesCount }
            onChange={ handleChangePage }
          />
        )}

        {isKeyManage && <ManageKey />}
      </Content>

      <Aside
        id="keys_aside_right"
        side="right"
      >
        {showFilters && (
          <Filter
            filterClose={ setShowFilters }
            handleFilter={ handleFilter }
            handleFilterCancel={ handleFilterCancel }
          >
            <div className={ cnFilter("Field") }>
              <label>{t("search-Name")}</label>
              <input
                className={ cnFilter("Field-Search") }
                id="page_group_search_input"
                placeholder={ t("search-Name") }
                type="text"
                value={ filterSearchValue }
                onChange={ e => setFilterSearchValue(e.target.value) }
              />
            </div>
          </Filter>
        )}
        <LoadingContainer isLoading={ isLoadingFolder || isLoadingKeys }>
          <KeysAside
            folder={ folder }
            folderAccess={ folderAccess }
            hasAccess={ hasAccess }
            isAdmin={ isAdmin }
            isFolderWritable={ isFolderWritable }
            isFreeUser={ isFreeUser }
            isGroupAndRoleUser={ isGroupAndRoleUser }
            isNoAccess={ isNoAccess }
            isStandard={ isStandard }
            showFilters={ showFilters }
            totalKeys={ totalItems }
          />
        </LoadingContainer>
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  folders: { list: foldersList, chosen: chosenFolder, isLoading: isLoadingFolder },
  keys: { chosen: chosenKeys, list: keysList, isLoading: isLoadingKeys, currentPage, pagesCount, totalItems, moveSuccess },
  settings: { productType, limitingKeys },
  user: { role, username },
}: RootState) => ({
  chosenFolder,
  chosenKeys,
  foldersList,
  keysList,
  limitingKeys,
  productType,
  role,
  username,
  isLoadingFolder,
  isLoadingKeys,
  currentPage,
  pagesCount,
  totalItems,
  moveSuccess,
});

const mapDispatchToProps = {
  favoritesSetFolder,
  folderChose,
  folderSetItemByID,
  keyChose,
  getKeysPagination,
  setHeaderTitle,
  setHeaderIcon,
  setOpenedFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Keys);
