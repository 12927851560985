import { createRoutine, Routine } from "redux-saga-routines";
import { http, LOCAL_STORAGE, saveDataToLS } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

export const action: Routine = createRoutine("USER_GET_PROFILE");

const getProfile = () => {
  return http({ route: "user/profile" });
};

export interface GetProfileOTPResponse {
  key: string | null;
  global: boolean;
  enabled: boolean;
  url: string;
}

export interface GetProfileResponse {
  email: string;
  full_name: string;
  locale: string;
  product_id: string;
  rate: string;
  roles: string;
  username: string;
  otp?: GetProfileOTPResponse;
}

function* handler() {
  try {
    const response: GetProfileResponse = yield call(getProfile);

    saveDataToLS(LOCAL_STORAGE.USER, {
      roles: response.roles,
      username: response.username,
    });

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
