/* eslint-disable react-hooks/exhaustive-deps, no-useless-escape, object-shorthand, prefer-const, no-return-assign, no-param-reassign */

import CryptoJS from "crypto-js";
import { trim } from "lodash";
import React from "react";

import { LOCAL_STORAGE, typePasswordSetting } from "core";

/**
 * Exclude capital letters
 * @param {string} string
 * @return {string}
 */
export function excludeCapitalLetters(string: string): string {
  return string.replace(/\s/g, "").toLowerCase();
}

/**
 * Remove space from string
 * @param {string} string
 * @return {string}
 */
export function removeSpaces(string: string): string {
  return string.replace(/\s/g, "");
}

/**
 * Update input value by callback
 * @param {React.FormEvent<HTMLInputElement>} event
 * @param {(string: string) => string} cb callback
 */
export function updateOnInputString(event: React.FormEvent<HTMLInputElement>, cb: (string: string) => string) {
  if (event && event.target && (event.target as HTMLInputElement).value && cb) {
    (event.target as HTMLInputElement).value = cb((event.target as HTMLInputElement).value);
  }
}

/**
 * The function checks if a value from an array exists in the string
 * @param str - string for search
 * @param arr - array whose values will be checked for existence in the string
 * @return boolean
 */
export function ifStringInclude(str: string, arr: string[]): boolean {
  return arr.some(v => str.includes(v));
}

/**
 * Validate URL by exist http|https in them
 * @param url - string
 */
export const validateURL = (url: string): string => {
  try {
    const parsed = new URL(url);
    return ["https:", "http:"].includes(parsed.protocol) ? url : "";
  } catch (e) {
    return "";
  }
};

/**
 * Get key annotation, example "New Key" -> "NK", "Google" -> "GO", "Yandex Market" -> "YM"
 * @param title - string
 */
export const getKeyAnnotation = (title?: string) => {
  const trimmedTitle = trim(title);

  const arrayTrimmedTitle = trimmedTitle.split(/\s/).filter(e => e !== "");

  if (!trimmedTitle) {
    return "KY";
  }

  return arrayTrimmedTitle.length === 1
    ? trimmedTitle.substr(0, 2)
    : arrayTrimmedTitle.map((word) => word[0]).join("").slice(0,2);
};

/**
 * get hash from string
 * @param s - string
 */
export const getHash = (s: string): string => CryptoJS.SHA256(s).toString(CryptoJS.enc.Base64);

/**
 * Capitalize first letter of word
 * @param s: String
 */
export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * Replace [^a-zA-Z0-9] to _
 * @param string
 */
export function replaceNonAlphaDigit(string: string) {
  return string.replace(/[^a-zA-Z0-9]/g, "_");
}

/**
 * Test password string by server params
 * @param password - password for test
 */
export function testPassword(password: string): number {
  if (/^(?=.{13,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/.test(password)) {
    return 4;
  }
  if (/^(?=.{10,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/.test(password)) {
    return 3;
  }
  if (/^(?=.{6,})(?=.*[a-z])(?=.*[0-9]).*$/.test(password)) {
    return 2;
  }
  if (/^(?=.{6,})(?=.*[0-9]).*$/.test(password)) {
    return 1;
  }
  return 0;
}

/**
 * Test password backup string by server params
 * @param password - password for test
 */
export function testPasswordBackUp(password: string): number {
  if (password.length < 16) {
    return 2;
  }

  if (/^(?=.{16,})((?=.*[a-z!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).)*$/i.test(password)) {
    if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/.test(password)) {
      return 1;
    }
  }
  return 0;
}

export type typeReturnTestPasswordForBusiness = {
  minCharactersCount?: boolean;
  lowercaseLetters?: boolean;
  uppercaseLetters?: boolean;
  digitsPresence?: boolean;
  specialChars?: boolean;
  validation?: boolean;
};

/**
 * Test password string by server params
 * @param password - password for test
 * @param settings
 * @return typeReturnTestPasswordForBusiness
 */
export function testPasswordForBusiness(password: string, settings: typePasswordSetting | undefined): typeReturnTestPasswordForBusiness {
  let minCharactersCount = false;
  let lowercaseLetters = false;
  let uppercaseLetters = false;
  let digitsPresence = false;
  let specialChars = false;
  let validation = false;

  if (settings) {
    if (password.length >= settings?.min_characters_count) {
      minCharactersCount = true;
    }

    if (settings.lowercase_letters_presence) {
      if (/[a-z]/.test(password)) {
        lowercaseLetters = true;
      }
    } else {
      lowercaseLetters = true;
    }

    if (settings.uppercase_letters_presence) {
      if (/[A-Z]/.test(password)) {
        uppercaseLetters = true;
      }
    } else {
      uppercaseLetters = true;
    }

    if (settings.digits_presence) {
      if (/[0-9]/.test(password)) {
        digitsPresence = true;
      }
    } else {
      digitsPresence = true;
    }

    if (settings.specialchars_presence) {
      if (/[`!"?$?%^&*([)(\])_\-+={}}£:;@'~#|<,>.?/]/.test(password)) {
        specialChars = true;
      }
    } else {
      specialChars = true;
    }
  }

  if (minCharactersCount && lowercaseLetters && digitsPresence && uppercaseLetters && specialChars) {
    validation = true;
  }

  return {
    minCharactersCount: minCharactersCount,
    lowercaseLetters: lowercaseLetters,
    uppercaseLetters: uppercaseLetters,
    digitsPresence: digitsPresence,
    specialChars: specialChars,
    validation: validation,
  };
}

/**
 * Unique key for loop and element id
 * @param title first item of uniq key
 * @param index two item of uniq key
 */
export function getKey(title: string = "", index?: number): string {
  return `${index !== undefined ? `${index}_` : ""}${replaceNonAlphaDigit(title).toLowerCase()}`;
}

/**
 * Get key for local storage, base by hash username and LOCAL_STORAGE key
 * @param key: LOCAL_STORAGE
 * @param username: string = default
 */
export function getUserEnvKey(key: LOCAL_STORAGE, username = "default"): string {
  return `${getHash(username)}_${key}`;
}

/**
 * Random password feature when create/update user
 * @param length - length of generation string
 */
export function generatePassword(length: number = 20): string {
  return Array(length)
    .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$")
    .map(x => x[Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * x.length)])
    .join("");
}
