import React, { FC, useMemo } from "react";
import { cn } from "@bem-react/classname";
import { FOLDER_STRUCTURE, getUserEnvKey, LOCAL_STORAGE, ROLE_TYPE } from "core";
import useLocalStorage from "core/hook/useLocalStorage";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "ss-ui";

import { RootState } from "store/reducers";

export const cnFolderNavHeader = cn("FolderNavHeader");

type Props = {
  role: ROLE_TYPE;
  username?: string;
};

const FolderNavHeader: FC<Props> = ({ role, username }) => {
  const [folderStructure, setFolderStructure] = useLocalStorage<FOLDER_STRUCTURE>(
    getUserEnvKey(LOCAL_STORAGE.FOLDER_STRUCTURE, username),
    FOLDER_STRUCTURE.TREE,
  );

  const isTree = useMemo(() => folderStructure === FOLDER_STRUCTURE.TREE, [folderStructure]);
  const isFree = useMemo(() => role === ROLE_TYPE.FREE_USER, [role]);

  const { t } = useTranslation();

  return (
    <div className={cnFolderNavHeader()} id="menu_top_header">
      {isFree && <p>{t("folder-free")}</p>}
      {!isFree && isTree && <p>{t("folder-tree")}</p>}
      {!isFree && !isTree && <p>{t("folder-list")}</p>}
      {!isFree && (
        <Button
          type="button"
          onClick={() => setFolderStructure(isTree ? FOLDER_STRUCTURE.SINGLE : FOLDER_STRUCTURE.TREE)}
          id="menu_top_header_btn_change_view"
          icon={isTree ? "icon-menu-tree" : "icon-menu-list"}
          theme="primary-link"
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user: { username, role } }: RootState) => ({
  username,
  role,
});

export default connect(mapStateToProps)(FolderNavHeader);
