import { combineReducers, Reducer } from "redux";

import backup, { SettingsBackupState } from "./backup";
import customize, { SettingsCustomizeState } from "./customize";
import generatorPassword, { SettingsGeneratorPasswordState } from "./generatorPassword";
import header, { SettingsHeaderState } from "./header";
import oAuthLifeTime, { OAuthLifeTimeState } from "./oAuthLifeTime";
import resetPassword, { SettingsResetPasswordState } from "./resetPassword";
import mailerSettings, { SettingsMailerState } from "./mailerSettings";
import accessControl, { SettingsAccessControlState } from "./accessControl";
import accessDevice, { SettingsAccessDeviceState } from "./accessDevice";

export interface SettingsState {
  backup: SettingsBackupState;
  customize: SettingsCustomizeState;
  generatorPassword: SettingsGeneratorPasswordState;
  header: SettingsHeaderState;
  oAuthLifeTime: OAuthLifeTimeState;
  resetPassword: SettingsResetPasswordState;
  mailerSettings: SettingsMailerState;
  accessControl: SettingsAccessControlState;
  accessDevice: SettingsAccessDeviceState;
}

export default function rootReducer(): Reducer<SettingsState> {
  return combineReducers<SettingsState>({
    backup,
    customize,
    generatorPassword,
    header,
    oAuthLifeTime,
    resetPassword,
    mailerSettings,
    accessControl,
    accessDevice,
  });
}
