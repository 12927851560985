import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = { folderID: string };

export const action: Routine = createRoutine("KEYS_GET_ALL_BY_FOLDER");

const getAllByFolder = ({ folderID }: Payload) => {
  return http({ route: `folder/${folderID}/key/all` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    if (!payload.folderID) {
      throw new Error("Folder identification is empty");
    }

    const response = yield call(getAllByFolder, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
