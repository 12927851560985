import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Icon, FolderIcon } from "ss-ui";
import { Redirect } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { formValueSelector, InjectedFormProps, reduxForm, WrappedFieldArrayProps, getFormValues } from "redux-form";
import { pick, omit, isEmpty, isUndefined } from "lodash";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { LoadingContainer, Modal } from "components/Generic";

import {
  FIELD_TYPE,
  FOLDER_TYPE,
  FORM_KEY_MANAGEMENT,
  PRODUCT_TYPE,
  ROLE_TYPE,
  Routes,
  SECURE_FIELDS_LIST,
  Validation,
  apiCallSecurityPassword,
  findFolderInTree,
  getKeyFromArrayByID,
  getTemplateIcon,
  optionsLevelSecurity,
  replaceUrlParam,
  typeFolder,
  typeKey,
  typeTemplate,
  typeTemplates,
  getKeyAnnotation,
  getFilterDataOnTemplate,
} from "core";

import { Payload as PayloadKeyManage } from "store/routines/keys/manage";
import { Payload as PayloadKeyGet } from "store/routines/keys/get";
import { RootState } from "store/reducers";
import { getGlobalSettings, getSettings, keyManage, templatesGetAll, keyGet } from "store/actions";

import {
  FieldAccountNumber,
  FieldBICSwift,
  FieldCVV,
  FieldCardExpirationDate,
  FieldCardNumber,
  FieldCardPIN,
  FieldClientNumber,
  FieldContext,
  FieldCountry,
  FieldDescription,
  FieldDocNumber,
  FieldEmail,
  FieldEmailType,
  FieldExpirationDate,
  FieldFileName,
  FieldHolder,
  FieldHostname,
  FieldIAMID,
  FieldIBAN,
  FieldIDType,
  FieldIssueDate,
  FieldKey,
  FieldLogin,
  FieldLogo,
  FieldMailPort,
  FieldMailSecurityType,
  FieldMailServer,
  FieldOTP,
  FieldPassword,
  FieldPhoto,
  FieldPlaceIssue,
  FieldPort,
  FieldProtocol,
  FieldQRBarcode,
  FieldSealing,
  FieldSerialNumber,
  FieldString,
  FieldTemplate,
  FieldTitle,
  FieldURI,
  isFieldShow,
  FieldUserName,
  FieldRfcConnectionId,
  FieldRfcConnectionName,
  FieldRfcConnectionType,
  FieldRfcConnectionUser,
} from "./Fragments";

export const cnManageKey = cn("ManageKey");

type Props = {
  errors: any;
  foldersList: typeFolder[];
  getGlobalSettings: () => void;
  getSettings: () => void;
  isBusiness: boolean;
  isLoading: boolean;
  isManagers: boolean;
  keyManage: (payload: PayloadKeyManage) => void;
  list: typeKey[];
  openedFolder: string;
  selectedTemplate?: string;
  templates: typeTemplates;
  templatesGetAll: () => void;
  keyGet: (payload: PayloadKeyGet) => void;
  formData?: any;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

interface IParams {
  id?: string;
}

const ManageKey: FC<Props> = ({
  change,
  errors,
  foldersList,
  getGlobalSettings,
  getSettings,
  handleSubmit,
  isBusiness,
  isLoading,
  isManagers,
  keyManage,
  list,
  openedFolder,
  selectedTemplate,
  submitSucceeded,
  templates,
  templatesGetAll,
  keyGet,
  formData,
}) => {
  const { goBack, push } = useHistory();
  const { id } = useParams<IParams>();
  const { t } = useTranslation();

  const [passwordCheck, setPasswordCheck] = useState<boolean>(false);
  const [loginCheck, setLoginCheck] = useState<number>(1);
  const [passwordPwnedValid, setPasswordPwnedValid] = useState<number>(1);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [isLoadingKey, setIsLoadingKey] = useState<boolean>(true);

  const pwnedButtonCheck: boolean = useMemo(() => [loginCheck, passwordPwnedValid].includes(0), [loginCheck, passwordPwnedValid]);
  const pwnedButtonAnyway: boolean = useMemo(() => [loginCheck, passwordPwnedValid].includes(2), [loginCheck, passwordPwnedValid]);

  const template: typeTemplate | undefined = useMemo(
    () => templates.find(({ title }) => selectedTemplate && title === selectedTemplate),
    [templates, selectedTemplate],
  );

  const isSapTemplate: boolean | undefined = useMemo(
    () => template && ["sap_login_template", "sap_rfc_template"].includes(template.title), [template]);

  const folder: typeFolder | undefined = useMemo(() => findFolderInTree(foldersList, openedFolder), [foldersList, openedFolder]);
  const key: typeKey | undefined = useMemo(() => (id ? getKeyFromArrayByID(list, id) : undefined), [list, id]);

  const [keyHeaderIcon, setKeyHeaderIcon] = useState<string>(key ? getTemplateIcon(key.template) : getTemplateIcon("login_template"));

  useEffect(() => {
    getSettings();

    if (templates.length === 0) {
      templatesGetAll();
    }

    if (isBusiness) {
      getGlobalSettings();
    }
  }, []);

  useEffect(() => {
    if ((id && folder) && !key) {
      keyGet({ id, folderID: folder.id });
    }
  }, [list]);

  useEffect(() => {
    setIsLoadingSubmit(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isUndefined(key)) {
      setIsLoadingKey(false);
    } else {
      setIsLoadingKey(true);
      setTimeout(() => setIsLoadingKey(false), 2000);
    }
  }, [key]);

  const handleForm = async (data: any) => {

    if (loginCheck === 0) {
      return;
    }

    setIsLoadingSubmit(true);
    const cloneData = getFilterDataOnTemplate(data, template);

    // Encrypt security fields
    for (const field in cloneData) {
      if (SECURE_FIELDS_LIST.includes(field as FIELD_TYPE) && !isEmpty(cloneData[field])) {
        // eslint-disable-next-line no-await-in-loop
        cloneData[field] = await apiCallSecurityPassword({ password: cloneData[field], action: "encrypt" });
      }
    }

    const payload = {
      id: key?.id || undefined,
      folder: key?.mainFolderId || folder?.id || openedFolder,
      formData: {
        encryptionEnabled: "0",
        ...pick(cloneData, [
          FIELD_TYPE.TEMPLATE,
          isManagers ? FIELD_TYPE.SEALING : "",
          FIELD_TYPE.CERTIFICATE,
          FIELD_TYPE.FILE,
          FIELD_TYPE.LOGO,
          FIELD_TYPE.PHOTO,
          FIELD_TYPE.QR_BARCODE,
          FIELD_TYPE.TITLE,
          FIELD_TYPE.URI,
        ]),
        ...pick(data, [
          template?.title === "sap_login_template" ? FIELD_TYPE.PASSWORD : ""
        ]),
        data: JSON.stringify(
          omit(cloneData, [
            FIELD_TYPE.CERTIFICATE,
            FIELD_TYPE.FILE,
            FIELD_TYPE.LOGO,
            FIELD_TYPE.PHOTO,
            FIELD_TYPE.QR_BARCODE,
            FIELD_TYPE.SEALING,
            FIELD_TYPE.TEMPLATE,
            FIELD_TYPE.TITLE,
            FIELD_TYPE.URI,
          ]),
        ),
      },
    };

    if (template?.title === "sap_login_template") {
      if (!payload.formData.password) {
        payload.formData.password = "";
      }
    };

    keyManage(payload);
  };

  if (!isLoading && submitSucceeded && folder && !errors) {
    return <Redirect to={replaceUrlParam(Routes.Keys, "folderID", folder.id)} />;
  }

  const handleClickClose = () => {
    push(replaceUrlParam(Routes.Keys, "folderID", (key && key.mainFolderId) || (folder && folder.id) || ""));
  };

  const handleFieldChanged = (field: FIELD_TYPE, value: any) => {
    change(field, value);
  };

  return (
    <Modal className={cnManageKey()} isClosed={true} handleClose={() => handleClickClose()}>
      <FieldContext.Provider
        value={{
          formData,
          key,
          folder,
          handleChange: handleFieldChanged,
        }}
      >
        <LoadingContainer isLoading={isLoadingSubmit || isLoadingKey}>
          <div className={cnManageKey("Header")}>
            {key ? (
              <div className={cnManageKey("Header-Logo")}>
                {key.logo ? (
                  <img alt="" className={cnManageKey("Header-Logo-Image")} src={key.logo} />
                ) : (
                  <p className={cnManageKey("Header-Logo-Text")}>{getKeyAnnotation(key.title)}</p>
                )}
              </div>
            ) : (
              <Icon name={keyHeaderIcon} className={cnManageKey("HeaderKey")} />
            )}
            {key?.sealing && isManagers && <Icon className={cnManageKey("HeaderKey-Sealing")} name="icon-sealing" width={18} height={18} />}
            {!id && t("key-create")}
            <span>{key && key.title}</span>
          </div>
          <div className={cnManageKey("FolderSecurityLevel")}>
            {folder && <FolderIcon level={folder.level_security} size={30} />}
            <span>{folder && folder.title}</span>
            <div className={cnManageKey("FolderSecurityLevel-Text")}>
              - {t("key-level")}&nbsp; : &nbsp;
              <b>{folder && optionsLevelSecurity[folder.level_security - 1].label}</b>
            </div>
          </div>
          <form onSubmit={handleSubmit(handleForm)}>
            <div className={cnManageKey("Content")}>
              <div className={cnManageKey("ContentLeft")}>
                {isFieldShow(FIELD_TYPE.TITLE, template) && <FieldTitle />}

                <div className={cnManageKey("SealingBox")}>
                  <FieldTemplate getKeyIcon={setKeyHeaderIcon} folder={folder} keys={key} />
                  {folder?.type === FOLDER_TYPE.GROUP && isFieldShow(FIELD_TYPE.SEALING, template) && <FieldSealing />}
                </div>

                {isFieldShow(FIELD_TYPE.HOSTNAME_IP, template) && <FieldHostname />}

                {/* TODO: What options would be in the access type select? */}
                {/* {isFieldShow(FIELD_TYPE.ACCESS_TYPE, template) && <FieldAccessType />} */}
                {isFieldShow(FIELD_TYPE.IAM_ID, template) && <FieldIAMID />}

                {isFieldShow(FIELD_TYPE.URI, template) && <FieldURI />}

                <div className={`${cnManageKey("Row")} ${cnManageKey("Row-MailType")}`}>
                  {isFieldShow(FIELD_TYPE.EMAIL, template) && <FieldEmail />}
                  {isFieldShow(FIELD_TYPE.EMAIL_TYPE, template) && <FieldEmailType />}
                </div>

                {isFieldShow(FIELD_TYPE.LOGIN, template) && <FieldLogin loginValid={setLoginCheck} />}

                {/* Sap Field */}
                {isFieldShow(FIELD_TYPE.USER_NAME, template) && <FieldUserName />}

                {isFieldShow(FIELD_TYPE.RFC_CONNECTION_ID, template) && <FieldRfcConnectionId />}
                {isFieldShow(FIELD_TYPE.RFC_CONNECTION_NAME, template) && <FieldRfcConnectionName />}
                {isFieldShow(FIELD_TYPE.RFC_CONNECTION_USER, template) && <FieldRfcConnectionUser />}
                {isFieldShow(FIELD_TYPE.RFC_CONNECTION_TYPE, template) && <FieldRfcConnectionType />}

                {isFieldShow(FIELD_TYPE.PASSWORD, template) && (
                  <FieldPassword
                    isSapTemplate={isSapTemplate}
                    securityCheck={true}
                    passwordValid={setPasswordCheck}
                    passwordPwnedValid={setPasswordPwnedValid}
                  />
                )}

                {isFieldShow(FIELD_TYPE.INCOMING_MAIL_SERVER, template) && (
                  <div className={cnManageKey("Field-Title")}>
                    <p>{t("manage_key_incoming_title")}</p>
                  </div>
                )}

                {isFieldShow(FIELD_TYPE.INCOMING_MAIL_SERVER, template) && (
                  <FieldMailServer fieldType={FIELD_TYPE.INCOMING_MAIL_SERVER} />
                )}
                <div className={`${cnManageKey("Row")} ${cnManageKey("Row-Mail")}`}>
                  {isFieldShow(FIELD_TYPE.INCOMING_MAIL_SECURITY_TYPE, template) && (
                    <FieldMailSecurityType fieldType={FIELD_TYPE.INCOMING_MAIL_SECURITY_TYPE} />
                  )}
                  {isFieldShow(FIELD_TYPE.INCOMING_MAIL_PORT, template) && <FieldMailPort fieldType={FIELD_TYPE.INCOMING_MAIL_PORT} />}
                </div>

                {isFieldShow(FIELD_TYPE.OUTGOING_MAIL_SERVER, template) && (
                  <div className={cnManageKey("Field-Title")}>
                    <p>{t("manage_key_outgoing_title")}</p>
                  </div>
                )}

                {isFieldShow(FIELD_TYPE.OUTGOING_MAIL_SERVER, template) && (
                  <FieldMailServer fieldType={FIELD_TYPE.OUTGOING_MAIL_SERVER} />
                )}
                <div className={`${cnManageKey("Row")} ${cnManageKey("Row-Mail")}`}>
                  {isFieldShow(FIELD_TYPE.OUTGOING_MAIL_SECURITY_TYPE, template) && (
                    <FieldMailSecurityType fieldType={FIELD_TYPE.OUTGOING_MAIL_SECURITY_TYPE} />
                  )}
                  {isFieldShow(FIELD_TYPE.OUTGOING_MAIL_PORT, template) && <FieldMailPort fieldType={FIELD_TYPE.OUTGOING_MAIL_PORT} />}
                </div>

                <div className={cnManageKey("Row")}>
                  {isFieldShow(FIELD_TYPE.PROTOCOL, template) && <FieldProtocol />}
                  {isFieldShow(FIELD_TYPE.PORT, template) && <FieldPort />}
                </div>

                {/* ! Auto-detected, no need this field */}
                {/* {isFieldShow(FIELD_TYPE.CARD_TYPE, template) && <FieldCardType />} */}

                <div className={cnManageKey("Row")}>
                  {isFieldShow(FIELD_TYPE.IBAN, template) && <FieldIBAN />}
                  {isFieldShow(FIELD_TYPE.BIC_SWIFT, template) && <FieldBICSwift />}
                </div>

                {isFieldShow(FIELD_TYPE.CARD_NUMBER, template) && <FieldCardNumber />}

                <div className={cnManageKey("Row")}>
                  {isFieldShow(FIELD_TYPE.CARD_EXPIRATION_DATE, template) && <FieldCardExpirationDate />}
                  {isFieldShow(FIELD_TYPE.CVV, template) && <FieldCVV />}
                  {isFieldShow(FIELD_TYPE.CARD_PIN, template) && <FieldCardPIN />}
                </div>

                {isFieldShow(FIELD_TYPE.NAME, template) && (
                  <FieldString fieldType={FIELD_TYPE.NAME} validations={[Validation.onlyLettersAll, Validation.emoji]} />
                )}
                {isFieldShow(FIELD_TYPE.CLIENT_NUMBER, template) && <FieldClientNumber />}

                {isFieldShow(FIELD_TYPE.ACCOUNT_NUMBER, template) && <FieldAccountNumber />}
                {isFieldShow(FIELD_TYPE.HOLDER, template) && <FieldHolder />}

                {isFieldShow(FIELD_TYPE.ID_CARD_TYPE, template) && <FieldIDType />}
                {isFieldShow(FIELD_TYPE.DOC_NUMBER, template) && <FieldDocNumber />}
                {isFieldShow(FIELD_TYPE.SERIAL_NUMBER, template) && <FieldSerialNumber />}

                <div className={cnManageKey("Row")}>
                  {isFieldShow(FIELD_TYPE.ISSUE_DATE, template) && <FieldIssueDate />}
                  {isFieldShow(FIELD_TYPE.EXPIRATION_DATE, template) && <FieldExpirationDate />}
                </div>

                {isFieldShow(FIELD_TYPE.COUNTRY, template) && <FieldCountry />}
                {isFieldShow(FIELD_TYPE.PLACE_ISSUE, template) && <FieldPlaceIssue />}
                {isFieldShow(FIELD_TYPE.KEY, template) && <FieldKey />}
                {isFieldShow(FIELD_TYPE.OTP, template) && <FieldOTP />}
              </div>

              <div className={cnManageKey("ContentRight")}>
                {isFieldShow(FIELD_TYPE.LOGO, template) && <FieldLogo />}
                {isFieldShow(FIELD_TYPE.DESCRIPTION, template) && <FieldDescription keyId={key?.id ? key.id : "new"} />}

                {isFieldShow(FIELD_TYPE.FILE, template) && <FieldFileName type={FIELD_TYPE.FILE} />}
                {isFieldShow(FIELD_TYPE.CERTIFICATE, template) && <FieldFileName type={FIELD_TYPE.CERTIFICATE} />}

                {isFieldShow(FIELD_TYPE.PHOTO, template) && <FieldPhoto />}
                {isFieldShow(FIELD_TYPE.QR_BARCODE, template) && <FieldQRBarcode />}
              </div>
            </div>
            <div className={cnManageKey("Actions")}>
              {template && (
                <>
                  {pwnedButtonAnyway ? (
                    <Button
                      id="button_action_create_key_anyway"
                      disabled={["login_template", "bank_account_template", "server_access_template", "email_template"].includes(template.title) && !passwordCheck}
                      type="submit"
                      theme="warning"
                    >
                      {id ? t("button-Save-Anyway") : t("button-Create-Anyway")}
                    </Button>
                  ) : (
                    <Button
                      id="button_action_create_key"
                      disabled={["login_template", "bank_account_template", "server_access_template", "email_template"].includes(template.title) && !passwordCheck}
                      type={pwnedButtonCheck ? "button" : "submit"}
                    >
                      {id ? t("button-Save") : t("button-Create")}
                    </Button>
                  )}
                </>
              )}
              <Button id="button_action_cancel_create_key" type="button" theme="secondary-outline" onClick={() => goBack()}>
                {t("button-Cancel")}
              </Button>
            </div>
          </form>
        </LoadingContainer>
      </FieldContext.Provider>
    </Modal>
  );
};

const selector = formValueSelector(FORM_KEY_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  formData: getFormValues(FORM_KEY_MANAGEMENT)(state),
  isManagers: [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER].includes(state.user.role),
  isBusiness: state.settings.productType === PRODUCT_TYPE.ENTERPRISE || state.settings.productType === PRODUCT_TYPE.CORPORATE,
  errors: state.keys.error,
  foldersList: state.folders.list,
  isLoading: state.keys.isLoadingManageKey,
  list: state.keys.list,
  openedFolder: state.settings.openedFolder,
  productType: state.settings.productType,
  selectedTemplate: selector(state, "template"),
  templates: state.templates.templates,
});

const mapDispatchToProps = {
  getGlobalSettings,
  getSettings,
  keyManage,
  templatesGetAll,
  keyGet,
};

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<any>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_KEY_MANAGEMENT,
    touchOnChange: true,
  }),
)(ManageKey);
