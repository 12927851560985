import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export type Payload = {
  backUpSuccess?: boolean;
  backUpError?: boolean;
};

export const action: Routine = createRoutine("SET_BACKUP_NOTIFY");

const setBackUpNotify = ({ backUpSuccess, backUpError }: Payload) => {
  return http({
    route: "user/settings",
    method: "POST",
    payload: {
      autobackupNotification: {
        failure: backUpError,
        success: backUpSuccess,
      }
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setBackUpNotify, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
