import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { connect } from "react-redux";

import {
  LOCAL_STORAGE,
  MainRoutes,
  PRODUCT_TYPE,
  ProtectedRoutes,
  ROLE_TYPE,
  clearUrlParam,
  getDataFromLS,
  productsAll,
  rolesAccessAll,
} from "core";

import { RootState } from "store/reducers";

interface IProps extends RouteProps {
  component: any;
  hasPermission: boolean;
  productType: PRODUCT_TYPE;
  productTypeAccess?: PRODUCT_TYPE[];
  role: ROLE_TYPE;
  roleAccess?: ROLE_TYPE[];
}

const ProtectedRoute: FC<IProps> = ({
  component: Component,
  hasPermission,
  productType,
  productTypeAccess = productsAll,
  role = ROLE_TYPE.USER,
  roleAccess = rolesAccessAll,
  ...rest
}) => {
  return (
    <Route {...rest}>
      {props => {
        if (!roleAccess.includes(role) || !productTypeAccess.includes(productType)) {
          return <Redirect to={clearUrlParam(ProtectedRoutes.Keys)} />;
        }
        return hasPermission ? <Component {...props} /> : <Redirect to={MainRoutes.Login} />;
      }}
    </Route>
  );
};

const mapStateToProps = ({ user: { role }, settings: { productType } }: RootState) => {
  const user = getDataFromLS(LOCAL_STORAGE.USER);
  return {
    hasPermission: Boolean(user && user.username),
    productType,
    role,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
