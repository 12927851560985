import { all, fork } from "redux-saga/effects";

import { saga as deferredImport } from "store/routines/keys/deferredImport";
import { saga as getKeysPagination } from "store/routines/keys/getKeysPagination";
import { saga as getSharing } from "store/routines/keys/getKeySharing";
import { saga as keyAttached } from "store/routines/keys/attached";
import { saga as keyChose } from "store/routines/keys/chose";
import { saga as keyClear } from "store/routines/keys/clear";
import { saga as keyDelete } from "store/routines/keys/delete";
import { saga as keyFavorite } from "store/routines/keys/favorite";
import { saga as keyFilesExport } from "store/routines/keys/keyFilesExport";
import { saga as keyGet } from "store/routines/keys/get";
import { saga as keyGetAllByFolder } from "store/routines/keys/getAllByFolder"; // old get keys method
import { saga as keyGetLog } from "store/routines/keys/getLog";
import { saga as keyManage } from "store/routines/keys/manage";
import { saga as keyMove } from "store/routines/keys/move";
import { saga as keyMultipleDelete } from "store/routines/keys/multipleDelete";
import { saga as keyPasswordSecurity } from "store/routines/keys/passwordSecurity";
import { saga as keyRevert } from "store/routines/keys/revert";
import { saga as keyShowReImportModal } from "store/routines/keys/showReImportModal";
import { saga as keyValidateByURILogin } from "store/routines/keys/validateByURILogin";
import { saga as keysExport } from "store/routines/keys/keysExport";
import { saga as keysImport } from "store/routines/keys/keysImport";
import { saga as otpDecode } from "store/routines/keys/otpDecode";
import { saga as setSharing } from "store/routines/keys/setKeySharing";
import { saga as deleteKeySharing } from "store/routines/keys/deleteKeySharing";

export default function* keys() {
  return yield all([
    fork(deferredImport),
    fork(getKeysPagination),
    fork(getSharing),
    fork(keyAttached),
    fork(keyChose),
    fork(keyClear),
    fork(keyDelete),
    fork(keyFavorite),
    fork(keyFilesExport),
    fork(keyGet),
    fork(keyGetAllByFolder), // old get keys method
    fork(keyGetLog),
    fork(keyManage),
    fork(keyMove),
    fork(keyMultipleDelete),
    fork(keyPasswordSecurity),
    fork(keyRevert),
    fork(keyShowReImportModal),
    fork(keyValidateByURILogin),
    fork(keysExport),
    fork(keysImport),
    fork(otpDecode),
    fork(setSharing),
    fork(deleteKeySharing)
  ]);
}
