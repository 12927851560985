/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import LayoutAnonymous from "components/Layout/LayoutAnonymous/LayoutAnonymous";
import { Modal, cnModal, LoadingContainer } from "components/Generic";
import { useHistory } from "react-router-dom";
import { http, MainRoutes } from "core";
import { Button } from "ss-ui";
import { toast } from "react-toastify";

type Props = {}

const VpnConnection: FC<Props> = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { push } = useHistory();

    const checkConnections = () => {
        setIsLoading(true);

        (http({ route: "config", isProtected: false }) as Promise<({})>)
        .then(() => {
            setIsLoading(false);
            push(MainRoutes.Login);
            window.location.reload();
        })
        .catch(() => {
            setIsLoading(false);
            toast.error(t("error_connection_vpn"));
        })
    };

    return (
        <LayoutAnonymous>
            <Modal isCloseable={false} className={cnModal("ModalFolderSelect")}>
                <LoadingContainer isLoading={isLoading}>
                    <div className={cnModal("Scroll Modal-Vpn")}>
                        <h3>{t("vpn_page_header")}</h3>
                        <p>{t("vpn_page_text")}</p>
                        <svg width="68" height="125" viewBox="0 0 68 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.5721 0.806152H2.70235C1.20921 0.806152 0 2.01257 0 3.49849V30.2687C0 31.7563 1.20921 32.961 2.70235 32.961H29.5721C31.0653 32.961 32.2745 31.7563 32.2745 30.2687V3.49849C32.2745 2.01257 31.0653 0.806152 29.5721 0.806152ZM26.8698 27.5763H5.40471V6.19084H26.8698V27.5763Z" fill="#006892"/>
                            <path d="M29.572 3.49902H2.70227V30.2692H29.572V3.49902Z" fill="#1A90BB"/>
                            <path d="M22.8397 23.5613C26.5412 19.8734 26.5412 13.8942 22.8396 10.2063C19.1381 6.51843 13.1366 6.51842 9.43499 10.2063C5.7334 13.8942 5.7334 19.8734 9.435 23.5613C13.1366 27.2492 19.1381 27.2492 22.8397 23.5613Z" fill="#00618E"/>
                            <path d="M14.3463 17.2413C14.5434 16.0315 13.7188 14.8916 12.5045 14.6952C11.2902 14.4988 10.146 15.3204 9.94894 16.5302C9.75183 17.74 10.5764 18.8799 11.7907 19.0763C13.005 19.2727 14.1492 18.4511 14.3463 17.2413Z" fill="#004872"/>
                            <path d="M21.7116 18.4535C22.5815 17.5868 22.5815 16.1817 21.7116 15.3151C20.8417 14.4484 19.4314 14.4484 18.5615 15.3151C17.6916 16.1817 17.6916 17.5868 18.5615 18.4535C19.4314 19.3201 20.8417 19.3201 21.7116 18.4535Z" fill="#004872"/>
                            <g opacity="0.2">
                                <path d="M32.2736 3.49849V30.2687C32.2736 31.7563 31.0644 32.961 29.5712 32.961H16.1372V0.806152H29.5712C31.0644 0.806152 32.2736 2.01257 32.2736 3.49849Z" fill="#006892"/>
                            </g>
                            <path d="M22.2738 76.6189L22.2738 84.1478C22.2738 85.9458 20.8217 87.4023 19.0313 87.4023L13.2809 87.4023C12.293 87.4023 11.4081 86.9583 10.8137 86.2591L10.8137 86.2582C10.3306 85.6906 10.0393 84.9533 10.0393 84.1478L10.0393 76.6189L22.2747 76.6189L22.2738 76.6189Z" fill="#006892"/>
                            <path d="M28.6647 59.0685L28.6647 74.1082C28.6647 76.4301 26.7883 78.3135 24.474 78.3135L7.8372 78.3135C5.52384 78.3135 3.64746 76.4301 3.64746 74.1082L3.64746 59.0685L28.6656 59.0685L28.6647 59.0685Z" fill="#1A90BB"/>
                            <path d="M2.15744 59.0679L30.1548 59.0679C30.4586 59.0679 30.7048 58.8207 30.7048 58.5158L30.7048 56.3091C30.7048 56.0042 30.4586 55.757 30.1548 55.757L2.15744 55.757C1.85364 55.757 1.60736 56.0042 1.60736 56.3091L1.60736 58.5158C1.60736 58.8207 1.85364 59.0679 2.15744 59.0679Z" fill="#1A90BB"/>
                            <path d="M12.4015 55.7578L8.13757 55.7578L8.13757 45.5228C8.13757 44.3414 9.09205 43.3834 10.2691 43.3834C11.4461 43.3834 12.4006 44.3414 12.4006 45.5228L12.4006 55.7578L12.4015 55.7578Z" fill="#00618E"/>
                            <path d="M22.0421 43.3823C23.2191 43.3823 24.1736 44.3413 24.1736 45.5218L24.1736 55.7567L19.9097 55.7567L19.9097 45.5218C19.9097 44.3403 20.8651 43.3823 22.0412 43.3823L22.0421 43.3823Z" fill="#00618E"/>
                            <path d="M28.6656 59.0684L3.64746 59.0684L3.64746 60.2806L28.6656 60.2806L28.6656 59.0684Z" fill="#006892"/>
                            <path d="M22.2728 78.313L10.0383 78.313L10.0383 79.0994L22.2728 79.0994L22.2728 78.313Z" fill="#00618E"/>
                            <path d="M12.4015 54.2593L8.13757 54.2593L8.13757 55.7576L12.4015 55.7576L12.4015 54.2593Z" fill="#004872"/>
                            <path d="M24.1745 54.2593L19.9105 54.2593L19.9105 55.7576L24.1745 55.7576L24.1745 54.2593Z" fill="#004872"/>
                            <path d="M14 87L14 125L18 125L18 87L14 87Z" fill="#1A90BB"/>
                            <path d="M47.8 12.878L43.712 23H42.011L37.923 12.878H39.435C39.5983 12.878 39.7313 12.9177 39.834 12.997C39.9367 13.0763 40.0137 13.179 40.065 13.305L42.452 19.479C42.5313 19.6797 42.606 19.9013 42.676 20.144C42.7507 20.382 42.8207 20.6317 42.886 20.893C42.942 20.6317 43.0027 20.382 43.068 20.144C43.1333 19.9013 43.2057 19.6797 43.285 19.479L45.658 13.305C45.6953 13.1977 45.7677 13.0997 45.875 13.011C45.987 12.9223 46.1223 12.878 46.281 12.878H47.8ZM52.1377 17.967C52.483 17.967 52.784 17.925 53.0407 17.841C53.2974 17.7523 53.5097 17.6287 53.6777 17.47C53.8504 17.3067 53.9787 17.1107 54.0627 16.882C54.1467 16.6487 54.1887 16.3897 54.1887 16.105C54.1887 15.8343 54.1467 15.5893 54.0627 15.37C53.9787 15.1507 53.8527 14.964 53.6847 14.81C53.5167 14.656 53.3044 14.5393 53.0477 14.46C52.791 14.376 52.4877 14.334 52.1377 14.334H50.7307V17.967H52.1377ZM52.1377 12.878C52.8144 12.878 53.4 12.9573 53.8947 13.116C54.3894 13.2747 54.7977 13.4963 55.1197 13.781C55.4417 14.0657 55.6797 14.4063 55.8337 14.803C55.9924 15.1997 56.0717 15.6337 56.0717 16.105C56.0717 16.595 55.99 17.0453 55.8267 17.456C55.6634 17.862 55.4184 18.212 55.0917 18.506C54.765 18.8 54.3544 19.0287 53.8597 19.192C53.3697 19.3553 52.7957 19.437 52.1377 19.437H50.7307V23H48.8477V12.878H52.1377ZM66.0487 12.878V23H65.0827C64.9333 23 64.8073 22.9767 64.7047 22.93C64.6067 22.8787 64.511 22.7947 64.4177 22.678L59.1327 15.93C59.1607 16.238 59.1747 16.5227 59.1747 16.784V23H57.5157V12.878H58.5027C58.582 12.878 58.6497 12.8827 58.7057 12.892C58.7663 12.8967 58.8177 12.9107 58.8597 12.934C58.9063 12.9527 58.9507 12.983 58.9927 13.025C59.0347 13.0623 59.0813 13.1137 59.1327 13.179L64.4387 19.955C64.4247 19.7917 64.413 19.6307 64.4037 19.472C64.3943 19.3133 64.3897 19.1663 64.3897 19.031V12.878H66.0487Z" fill="black"/>
                        </svg>
                    </div>
                    <div className={cnModal("Actions")}>
                        <Button id="button_try_again" onClick={checkConnections} type="button">
                            {t("vpn_page_button")}
                        </Button>
                    </div>
                </LoadingContainer>
            </Modal>
        </LayoutAnonymous>
    );
};

export default VpnConnection;
