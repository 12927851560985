/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Icon } from "ss-ui";
import { NavLink } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getKey, typeRoute, ROLE_TYPE, rolesAccessAll, productsAll, PRODUCT_TYPE } from "core";

import { RootState } from "store/reducers";

export const cnHeader = cn("HeaderNavigation");

type Props = {
  linkList: typeRoute[];
  productType: PRODUCT_TYPE;
  role: ROLE_TYPE;
};

const Header: React.FC<Props> = ({ linkList, role, productType }) => {
  const { t } = useTranslation();

  return (
    <div className={cnHeader()}>
      {linkList.map(({ title, route, icon, roleAccess = rolesAccessAll, productTypeAccess = productsAll }: typeRoute, index) => {
        const titleFinish: string = typeof title === "function" ? title(role) : title;
        return (
          roleAccess.indexOf(role) >= 0 &&
          productTypeAccess.indexOf(productType) >= 0 && (
            <NavLink
              activeClassName={cnHeader("isActive")}
              className={cnHeader("Link")}
              id={getKey(titleFinish, index + 100)}
              key={getKey(titleFinish, index + 100)}
              to={route}
              onClick={() => {
                const layoutElement = document.querySelector(".Layout-Container");
                  if (layoutElement) {
                    layoutElement.scrollTo({ top: 0, left: 0 });
                }
              }}
            >
              <Icon fill="#727272" name={icon} height={16} />
              <span>{t(`nav-${titleFinish}`)}</span>
            </NavLink>
          )
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ user: { role }, settings: { productType } }: RootState) => ({
  productType,
  role,
});

export default connect(mapStateToProps)(Header);
