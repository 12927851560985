import { http } from "core";
import { get as lodashGet, has, snakeCase } from "lodash";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { folderGetAll } from "store/actions";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

export type Payload = {
  keyId?: string;
  folders?: string[];
};

export const action: Routine = createRoutine("KEYS_SET_SHARED");

const setSharing = ({ keyId, folders }: Payload) => {
  return http({
    method: "POST",
    payload: { folders },
    route: `key/${keyId}/share`,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setSharing, payload);

    yield put(action.success(response));
    yield put(folderGetAll.trigger());
  } catch (error) {
    if (has(error, "response.data.detail")) {
      yield put(action.failure(lodashGet(error, "response.data.detail")));
      toast.error(i18n.t(`toast_key_error_${snakeCase(lodashGet(error, "response.data.detail.messages[0]"))}`));
    } else {
      yield put(action.failure(lodashGet(error, "messages", undefined)));
      toast.error(i18n.t("toast_key_error_access_denied"));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
