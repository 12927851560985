/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useMemo, useEffect, useState } from "react";
import { Icon, Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { reduxForm, InjectedFormProps, Form, Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { FormInput } from "components/Form";
import LayoutAnonymous from "components/Layout/LayoutAnonymous/LayoutAnonymous";

import { useQuery } from "core/hook/useQuery";
import { FORM_INSTALL_PASSWORD, Validation, http } from "core";

import { withLoading, PropsWithLoading } from "hoc";

import { RootState } from "store/reducers";
import { installPassword } from "store/actions";
import { Payload as payloadInstallPassword } from "store/routines/user/installPassword";

export const cnInstallPassword = cn("InstallPassword");

interface IResponseCheckInstallPasswordToken {
  firstName?: string;
  userName?: string;
}

interface FormValues {
  password: string;
  repeatPassword: string;
}

const checkInstallPasswordToken = (token: string): Promise<IResponseCheckInstallPasswordToken> => {
  return http({
    isProtected: false,
    route: `confirmation/${token}`,
  }) as Promise<IResponseCheckInstallPasswordToken>;
};

type Props = {
  installPassword: (payload: payloadInstallPassword) => void;
  isLoading: boolean;
} & InjectedFormProps<FormValues> &
  PropsWithLoading;

const InstallPassword: FC<Props> = ({ handleSubmit, installPassword, isLoading, setIsLoading }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState<IResponseCheckInstallPasswordToken>({});
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);

  const query = useQuery();
  const token: string = useMemo(() => query.get("token") || "", [query]);

  useEffect(() => {
    if (token) {
      setIsLoadingLocal(true);
      checkInstallPasswordToken(token)
        .then(user => {
          if (user) {
            setUser(user as IResponseCheckInstallPasswordToken);
          }
        })
        .finally(() => {
          setIsLoadingLocal(false);
        });
    }
  }, []);

  useEffect(() => {
    setIsLoading(isLoading || isLoadingLocal);
  }, [isLoading, isLoadingLocal]);

  const handleFormSubmit = ({ password }: FormValues) => installPassword({ password, token });

  const removeSpaces = e => {
    e.target.value = e.target.value.replace(/\s/g, "");
  };

  return (
    <LayoutAnonymous>
      <Form className={cnInstallPassword()} onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={cnInstallPassword("FormHeader")}>
          <Icon name="icon-user" />
          <p>{t("install_password_header")}</p>
        </div>
        {!(isLoading || isLoadingLocal) &&
          (user.userName ? (
            <span className="username">{t("install_password_hello_username", { name: user.firstName || user.userName })}</span>
          ) : (
            <span className="invalid-token">{t("install_password_invalid_token")}</span>
          ))}
        <div className={cnInstallPassword("FieldsContainer")}>
          <div className={cnInstallPassword("FieldGroup")} id="field_group_login_username_password">
            <Field
              className={cnInstallPassword("FieldGroupInput")}
              component={FormInput}
              isShown
              name="password"
              label={t("forgotPassword_field_password")}
              placeholder={t("install_password_placeholder_password")}
              type="password"
              validate={[Validation.required, Validation.minLength8, Validation.emoji, Validation.notSpace]}
              onInput={removeSpaces}
            />
            <Field
              className={cnInstallPassword("FieldGroupInput")}
              component={FormInput}
              label={t("forgotPassword_field_repeat_password")}
              isShown
              name="repeatPassword"
              placeholder={t("install_password_placeholder_repeat_password")}
              type="password"
              validate={[Validation.passwordsMatch]}
              onInput={removeSpaces}
            />
          </div>
        </div>
        <div className={cnInstallPassword("ButtonContainer")} id="page_login_button_container">
          <Button
            disabled={!user.userName}
            className={cnInstallPassword("ButtonSubmit")}
            id="button_login_ps"
            type="submit"
            theme="primary"
          >
            {t("install_password_submit")}
          </Button>
        </div>
      </Form>
    </LayoutAnonymous>
  );
};

const mapStateToProps = ({ user: { isLoading } }: RootState) => ({
  isLoading,
});

export default compose<Props, Partial<Props>>(
  withLoading,
  withRouter,
  connect(mapStateToProps, { installPassword }),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_INSTALL_PASSWORD,
  }),
)(InstallPassword);
