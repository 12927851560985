/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";

export type PropsTableWithSearch = {
  search: string;
  searchCallback: (entity: any) => boolean;
  setSearch: (searchValue: string) => void;
};

const TableWithSearch = <P extends {}>(Component: React.ComponentType<P>): React.FC<P> => {
  const WithSearchComponent = ({ ...props }) => {
    const [search, setSearch] = useState<string>("");

    const searchCallback = (entity: any) => {
      if (!search) return true;

      const urlReplace = search.replace(/^.*?:\/\//, "").replace(/\\|\//g, "");

      const reg = new RegExp(
        `(${urlReplace
          .trim()
          .split(" ")
          .map(s => `${s}`)
          .join("|")})`,
        "i",
      );

      let status = false;

      for (const [key, value] of Object.entries(entity)) {
        if (
          ["username", "name", "title", "uri", "login", "description"].includes(key) &&
          value &&
          typeof value === "string" &&
          reg.test(value.toString())
        ) {
          status = true;
        }
      }

      return status;
    };

    return <Component search={search} searchCallback={searchCallback} setSearch={setSearch} {...(props as P)} />;
  };

  return WithSearchComponent;
};

export default TableWithSearch;
