import React, { FC, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";

import { FormInput } from "components/Form";

import { FIELD_TYPE, getFieldValue } from "core";

import { FieldContext } from "./Types";

type Props = {};

const fieldType = FIELD_TYPE.OTP;

const FieldOTP: FC<Props> = () => {
  const { t } = useTranslation();
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <Field
      component={FormInput}
      isShown
      label={t("form_input-2FA")}
      name={fieldType}
      placeholder={t("form_input-Enter-Otp")}
    />
  );
};

export default FieldOTP;
