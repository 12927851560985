import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Pagination } from "components/Generic";
import { typeSearchFolder, getKey, typeFolder } from "core";
import { Payload as payloadGetFavoritesFolders } from "store/routines/favorites/getFolders";
import { favoritesGetFolders } from "store/actions";
import { cnTable, PropsTableWithSort, TableHeaderElement, TableWithSort, typeTableHeaderElement } from "components/Table";
import { RootState } from "store/reducers";
import { tableHeaderFolder } from "../../TableConfig";

import FavoritesFolderItem from "./FavoritesFolderItem";

const cnSearchFolder = cn("SearchFolder");

type Props = {
  foldersList: typeSearchFolder[];
  foldersCurrentPage: number,
  foldersPagesCount: number,
  isLoadingFolders: boolean;
  isUser: boolean;
  list: typeFolder[];
  favoritesGetFolders: (payload: payloadGetFavoritesFolders) => void;
} & PropsTableWithSort;

const FavoritesFolder: FC<Props> = ({
  foldersList,
  sortBy,
  sortDirection,
  foldersCurrentPage,
  foldersPagesCount,
  isLoadingFolders,
  isUser,
  list,
  favoritesGetFolders,
  sortCallback,
  handleSort,
}) => {
  const { t } = useTranslation();

  const handleChangePage = (page: number) => {
    favoritesGetFolders({ currentPage: page });
  };

  return (
    <>
    <div className={cnSearchFolder()}>
      <div className={cnTable("Head Table-Head-Small Table-Key")}>
        <div className={cnTable("Head-Th")} />
        {tableHeaderFolder.map(({ title, ...data }: typeTableHeaderElement, index: number) => (
          <TableHeaderElement
            handleSort={handleSort}
            id={getKey(title, index)}
            key={title}
            sortBy={sortBy}
            sortDirection={sortDirection}
            {...data}
          >
            {t(`table_title-${title}`)}
          </TableHeaderElement>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {foldersList.length > 0 ? (
            foldersList.sort(sortCallback).map(( item: typeSearchFolder) => {
              return (
                <FavoritesFolderItem isUser={isUser} folderList={list} folderItem={item} key={item.id}/>
              )
            }
          )) : (
            <>{isLoadingFolders ? null : <div className={cnTable("Empty")}><p>{t("favorites-no-favorites")}</p></div>}</>
          )}
        </div>
      </div>
    </div>
    {foldersList.length > 0 && 
      <Pagination 
        id="favorites_folders_pagination"
        itemPagesCount={foldersPagesCount}
        currentPage={foldersCurrentPage}
        onChange={handleChangePage}
      />
    }
    </>
  );
};

const mapStateToProps = ({
  favorites: { 
    foldersList, 
    foldersCurrentPage,
    foldersPagesCount,
    isLoadingFolders,
  },
  folders: { list }
}: RootState) => ({
  foldersList, 
  foldersCurrentPage,
  foldersPagesCount,
  isLoadingFolders,
  list
});

const mapDispatchToProps = {
  favoritesGetFolders,
};

export default compose<Props, Partial<Props>>(TableWithSort, connect(mapStateToProps, mapDispatchToProps))(FavoritesFolder);