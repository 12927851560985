import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { copyToClipboard, http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

import { get as lodashGet } from "lodash";

export type Payload = {
  action: "encrypt" | "decrypt";
  copy?: boolean;
  encryptionEnabled?: boolean;
  password: string;
};

export const action: Routine = createRoutine("KEYS_PASSWORD_SECURE");

const secure = ({ action, password }: Payload) => {
  return http({ route: `password/${action}`, method: "POST", payload: { password } });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(secure, payload);

    if (payload.copy) {
      // copyToClipboard(payload.encryptionEnabled ? SecurityServices.decrypt(response.password) : response.password);
      copyToClipboard(response.password);
    }

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
