import React, { FC, useState, useContext, useEffect } from "react";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import { FormSwitch } from "components/Form";

import { ROLE_TYPE, FIELD_TYPE, getFieldValue } from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { RootState } from "store/reducers";

import { FieldContext } from "./Types";

type Props = {
  userRole: ROLE_TYPE;
};

const fieldType = FIELD_TYPE.SEALING;

const FieldSealing: FC<Props> = ({ userRole }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<boolean>(false);
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      const contextDataValue = get(context, `formData.${fieldType}`, "0");
      setStatus(contextDataValue);
      context.handleChange(fieldType, contextDataValue ? "1" : "0");
    } else {
      const contextKeyValue = Boolean(Number(get(context, `key.${fieldType}`, "0")));
      setStatus(contextKeyValue);
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key])

  if (userRole !== ROLE_TYPE.MANAGER) {
    return null;
  }

  const handleSealing = (value: boolean) => {
    setStatus(value);
    context.handleChange(fieldType, value ? "1" : "0");
  };

  return (
    <div className={cnManageKey("Sealing")}>
      <div className={cnManageKey("Sealing-Label")}>
        <label htmlFor="sealing">{t("form_input-Sealing")}</label>
        <div className="tooltip tooltip-right tooltip-sealing" data-tooltip={t("tooltip-sealing")}>
          <Icon name="icon-info-circle" width={15} height={15} />
        </div>
      </div>
      <div className={cnManageKey("Sealing-Field")}>
        <Icon name="icon-sealing" className={cnManageKey(`Sealing-Icon${status ? "-Blue" : ""}`)} width={20} height={20} />
        <FormSwitch isOn={status} handleToggle={handleSealing} fieldName="sealing" />
        {status ? <p>{t("settings_switch_on")}</p> : <p>{t("settings_switch_off")}</p>}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user: { role: userRole } }: RootState) => ({
  userRole,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FieldSealing);
