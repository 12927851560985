import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Button } from "ss-ui";
import { cnFilter } from "components/Layout";

export interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  filterClose(value: boolean): void;
  handleFilter: () => void;
  handleFilterCancel: () => void;
  disableSubmit?: boolean
}

const Filter: FC<Props> = ({
  children,
  className = "",
  filterClose,
  handleFilter,
  handleFilterCancel,
  disableSubmit = false,
  ...props
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    filterClose(false);
  };
  return (
    <div className={`${cnFilter()} ${className}`} {...props}>
      <div className={cnFilter("Container")}>
        <div className={cnFilter("Header")}>
          <Icon name="icon-filter" />
          <p className={cnFilter("Header-Title")}>{t("history_filter_header_title")}</p>
          <Icon
            className={cnFilter("Close")}
            name="icon-close"
            onClick={handleClose}
            id="btn_close_info_box_key"
          />
        </div>
        <div className={cnFilter("Content")}>
          {children}
        </div>
        <div className={cnFilter("Action")}>
          <Button disabled={disableSubmit} id="button_history_filter_btn_submit" onClick={() => handleFilter()}>
            {t("history_filter_btn_submit")}
          </Button>
          <Button id="button_history_filter_btn_cancel" theme="secondary-outline" onClick={() => handleFilterCancel()}>
            {t("history-clear")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
