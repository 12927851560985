/* eslint-disable prefer-spread */
import React, { FC, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { FolderIcon, Button } from "ss-ui";
import { useTranslation } from "react-i18next";
import { cnModal, LoadingContainer, Modal } from "components/Generic";
import { InfoBox, InfoBoxHeader, InfoBoxTabs, InfoBoxContent, InfoBoxActions, cnInfoBox } from "components/InfoBox";
import {
  FOLDER_TYPE,
  ROLE_TYPE,
  FOLDER_ACCESS,
  getFolderFromTreeById,
  PayloadChoseFunction,
  typeFolder,
  typeInfoBoxTab,
} from "core";
import { folderChose, folderDelete } from "store/actions";
import { RootState } from "store/reducers";
import { Payload as PayloadFolderDelete } from "store/routines/folders/delete";
import { Groups, Settings } from "./Single";

type Props = {
  chosen: string[];
  clickedLink: string | number;
  isLoading: boolean;
  list: typeFolder[];
  role: ROLE_TYPE;
  folderChose: (payload: PayloadChoseFunction) => void;
  folderDelete: (payload: PayloadFolderDelete) => void;
};

const InfoBoxFolder: FC<Props> = ({ clickedLink, chosen, isLoading, list, role, folderChose, folderDelete }) => {
  const { t } = useTranslation();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>((clickedLink as number) || 1);
  const [openModalAddGroups, setOpenModalAddGroups] = useState<boolean>(false);
  const [parentFolder, setParentFolder] = useState<string | null | undefined>(null);
  const parentFolderObject: typeFolder = useMemo(() => getFolderFromTreeById(list, parentFolder), [list, parentFolder]);

  const parentFolderList = useMemo(
    () =>
      (folder: typeFolder, result: any[] = []) => {
        if (!folder) {
          return [];
        }
        const folderRoot = getFolderFromTreeById(list, folder && folder.root);
        const folderGroup = folder && folder.folderGroups?.filter(e => e.recursion === true).map(e => e);
        result.push(folderGroup);
        if (folder && folder.root !== null) {
          parentFolderList(folderRoot, result);
        }
        return [].concat.apply([], result);
      },
    [parentFolderObject],
  );

  const isNew = useMemo(() => chosen[0] === "new", [chosen]);
  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);
  const folder: typeFolder = useMemo(() => getFolderFromTreeById(list, chosen[0]), [list, chosen]);

  const isFolderPersonal = useMemo(() => folder && folder.type === FOLDER_TYPE.PERSONAL, [folder]);
  const isFolderWritable = useMemo(() => folder && FOLDER_ACCESS.WRITE === folder.access, [folder]);
  const isFolderPersonalRoot: boolean = useMemo(() => folder && folder.root === null && folder.type === FOLDER_TYPE.PERSONAL, [folder]);
  const isPersonalParent: boolean = useMemo(() => getFolderFromTreeById(list, parentFolder)?.type === FOLDER_TYPE.PERSONAL, [parentFolder]);

  useEffect(() => {
    setActiveTab(1);
  }, [chosen]);

  const handleOpenModalAddGroups = (value: boolean) => {
    setOpenModalAddGroups(value);
  };

  const handleClose = () => {
    folderChose({ status: false, choseAll: true });
  };

  const handleDelete = () => {
    if (chosen.length === 1) {
      folderDelete({
        id: chosen[0],
      });
    }
  };

  const configTabs: typeInfoBoxTab[] = [
    {
      index: 1,
      title: "Settings",
      component: (
        <Settings
          getParentFolder={setParentFolder}
          isPersonal={isFolderPersonal || isPersonalParent}
          setActiveTab={setActiveTab}
          folder={folder}
          parentFolderList={parentFolderList(parentFolderObject)}
        />
      ),
    },
    {
      index: 2,
      title: "Groups",
      component: (
        <Groups
          setActiveTab={setActiveTab}
          handleModalGroups={handleOpenModalAddGroups}
          modalGroupsValue={openModalAddGroups}
          parentFolderList={parentFolderList(parentFolderObject)}
        />
      ),
    },
  ];

  return (
    <>
      <InfoBox isLoading={isLoading}>
        <InfoBoxHeader isChildren title={folder ? folder.title : t("folders-New")} handleClose={handleClose}>
          {!folder ? (
            <FolderIcon />
          ) : (
            <FolderIcon
              level={folder.level_security}
              size={30}
              isRoot={folder.root === null && folder.type !== FOLDER_TYPE.GROUP}
              isGroup={folder.type === FOLDER_TYPE.GROUP}
              access={folder.access}
            />
          )}
        </InfoBoxHeader>
        <InfoBoxTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          configTabs={isFolderPersonal || isPersonalParent ? [configTabs[0]] : configTabs}
        />
        <InfoBoxContent>{configTabs[activeTab - 1].component}</InfoBoxContent>
        <InfoBoxActions>
          {isFolderWritable || isAdmin || isNew ? (
            <>
              {activeTab === 1 && (
                <Button id="button_action_save_folder" form="submit-folder-form" type="submit">
                  {t("button-Save")}
                </Button>
              )}
              {activeTab === 2 && (
                <div className={cnInfoBox("ButtonGroups")}>
                  {(parentFolderList(parentFolderObject)?.length !== 0 || folder) && (
                    <Button form="submit-group-form" id="button_action_saves" type="submit">
                      {t("button-Save")}
                    </Button>
                  )}
                  <Button
                    theme="primary-outline"
                    id="button_group_action_edit"
                    onClick={() => handleOpenModalAddGroups(!openModalAddGroups)}
                  >
                    {t("button-Edit")}
                  </Button>
                </div>
              )}
              {!isFolderPersonalRoot && !isNew ? (
                <Button id="button_folder_show_confirm" theme="danger-link" onClick={() => setShowConfirm(true)} icon="icon-trash" />
              ) : null}
            </>
          ) : null}
        </InfoBoxActions>
      </InfoBox>
      {showConfirm && (
        <Modal handleClose={() => setShowConfirm(false)} className={cnModal("Confirmation")}>
          <LoadingContainer isLoading={isLoading}>
            <div className={cnModal("Header")}>{t("button-Delete")}</div>
            <div className={cnModal("Scroll")}>
              <p className={cnModal("ConfirmationText")}>{t("modal-faq-folder", { count: chosen.length })}</p>
              <div className={cnModal("ConfirmationList")}>
                {chosen &&
                  chosen.map((id: string) => {
                    const folder = getFolderFromTreeById(list, chosen[0]);
                    return (
                      <div className={cnModal("ConfirmationItem")} key={id}>
                        <FolderIcon size={25} />
                        <span>{folder && folder.title}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={cnModal("Actions")}>
              <Button id="button_action_delete" onClick={() => handleDelete()}>
                {t("button-Delete")}
              </Button>
              <Button id="button_action_cancel" theme="secondary-outline" onClick={() => setShowConfirm(false)}>
                {t("button-Cancel")}
              </Button>
            </div>
          </LoadingContainer>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({ folders: { chosen, isLoading, list }, settings: { clickedLink }, user: { role } }: RootState) => ({
  chosen,
  clickedLink,
  isLoading,
  list,
  role,
});

const mapDispatchToProps = { folderChose, folderDelete };

export default connect(mapStateToProps, mapDispatchToProps)(InfoBoxFolder);
