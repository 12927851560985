import React, { FC } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";

import { getUserFromListByID, typeUser } from "core";

import { RootState } from "store/reducers";

const cnMultiply = cn("InfoBoxUserMultiply");
type Props = {
  chosen: string[];
  list: typeUser[];
};

const Multiply: FC<Props> = ({ chosen, list }) => {
  return (
    <div className={cnMultiply()}>
      {chosen.map((id: string) => {
        const user: typeUser = getUserFromListByID(list, id);
        return (
          <div className={cnMultiply("Item")} key={user.id}>
            <Icon name="icon-user-circle" width={20} height={20} />{" "}
            <span>
              {user.firstName} {user.lastName}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ users: { chosen, list } }: RootState) => ({ chosen, list });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Multiply);
