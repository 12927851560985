import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("USERS_CLEAR");

function* handler() {
  try {
    yield put(action.success());
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
