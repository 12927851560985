import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Icon } from "ss-ui";
import Color from "color";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { LoadingContainer, Modal } from "components/Generic";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

import {
  ACTION,
  FORM_USER_MANAGEMENT,
  getGroupFromListByID,
  getKey,
  getUserFromListByID,
  ROLE_TYPE,
  typeGroup,
  typeOption,
  typeUser,
  USER_STORAGE,
  preFirstName,
} from "core";

import { FormValues, Payload as PayloadUsersManagement } from "store/routines/users/manage";
import { RootState } from "store/reducers";
import { groupsGetAll, usersManagement } from "store/actions";
import { Payload as PayloadGroupsGetAll } from "store/routines/groups/getAll";

import RenderGroupList from "./RenderGroupList";

const cnGroups = cn("InfoBoxUserGroups");

type Props = {
  chosen: string[];
  groupsGetAll: (payload: PayloadGroupsGetAll) => void;
  groupsList: typeGroup[];
  isLoading: boolean;
  isLoadingGroup: boolean;
  list: typeUser[];
  role?: ROLE_TYPE;
  userStorage: USER_STORAGE;
  username?: string;
  usersManagement: (payload: PayloadUsersManagement) => void;
  handleModalGroups: (value: boolean) => void;
  modalGroupsValue: boolean;
  customize: SettingsCustomizeState;
} & InjectedFormProps &
  WrappedFieldArrayProps<FormValues>;

const Groups: FC<Props> = ({
  chosen,
  groupsGetAll,
  groupsList,
  handleSubmit,
  isLoading,
  isLoadingGroup,
  list,
  role,
  userStorage,
  username,
  usersManagement,
  handleModalGroups,
  modalGroupsValue,
  customize, 
}) => {
  const { t } = useTranslation();

  const [isShowAdd, setIsShowAdd] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  const isNew: boolean = useMemo(() => chosen[0] === "new", [chosen]);
  const user: typeUser = useMemo(() => getUserFromListByID(list, chosen[0]), [chosen]);
  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);

  useEffect(() => {
      groupsGetAll({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  useEffect(() => {
    setChecked(isNew || !(user.groups && user.groups.length > 0) ? [] : user.groups.map(({ id }: typeGroup) => id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const optionsGroupsList: typeOption[] = useMemo(
    () =>
      groupsList
        .filter(item => (!isAdmin ? item.users.some(x => x.username === username) : item))
        .map(({ id, name }: typeGroup) => ({
          value: id,
          label: name,
          isDisabled: checked.includes(id),
        })),

    [checked, groupsList],
  );

  const handleForm = () => {
    const userData: FormValues = {
      groups: checked,
      roles: user.roles,
      locale: user.locale,
      email: user.email,
      username: isNew ? "" : user.username,
    };

    if (userStorage === USER_STORAGE.LOCAL) {
      userData.firstName = user.firstName;
      userData.lastName = user.lastName;
      userData.email = user.email;
      userData.username = user.username;
      userData.appeal = user.appeal || preFirstName[0].value as string;
    }

    usersManagement({
      id: isNew ? undefined : user.id,
      action: isNew ? ACTION.CREATE : ACTION.UPDATE,
      payload: userData,
    });
    handleModalGroups(false);
  };

  const handleChange = (value: any) => {
    setIsShowAdd(false);
    setChecked([...checked, value.toString()]);
  };

  const handleRemove = (id: string) => {
    setChecked([...checked.filter((item: string) => item !== id)]);
  };

  const templateType = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-group-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  return (
    <div className={cnGroups()}>
      {!isNew && <RenderGroupList />}
      {isNew && <div className={cnGroups("Error")}>{t("error_user_group")}</div>}
      {modalGroupsValue ? (
        <Modal handleClose={() => handleModalGroups(false)} className={cnGroups("Modal")}>
          <div className={cnGroups("Modal-Header")}>
            <Icon name="icon-user-circle" width={20} height={20} /> <p>{isNew ? t("user-New") : `${user.firstName}${user.lastName}`}</p>
          </div>
          <LoadingContainer isLoading={isLoading}>
            <form onSubmit={handleSubmit(handleForm)} className={cnGroups("Modal-Form")}>
              {/* {!isNew && checked.length === 0 && <div className={cnGroups("Empty")}>{t("groups-List-Empty")}</div>} */}
              <div className={cnGroups("Modal-List-Header")}>{t("groups-List")}</div>

              <div className={cnGroups("Modal-List")}>
                {groupsList.length !== 0 &&
                  checked.map((id: string, index) => {
                    const group: typeGroup = getGroupFromListByID(groupsList, id);
                    const isGroupAccess = !!group && group.users.some(x => x.username === username);
                    return (
                      <div key={id} className={cnGroups("Modal-List-Item")}>
                        {isAdmin || isGroupAccess ? (
                          <div className={cnGroups("Modal-List-Icon")}>
                            <Icon name="icon-group-circle" width={25} height={24} />
                          </div>
                        ) : (
                          <div className={cnGroups("Modal-List-Icon Icon-Filter")}>
                            <Icon name="icon-group-circle" width={25} height={25} />
                          </div>
                        )}
                        <div className={cnGroups("Modal-List-User")}>
                          <p title={group.name}>{group.name}</p>
                        </div>
                        {(isAdmin || isGroupAccess) && (
                          <Icon
                            onClick={() => handleRemove(id)}
                            className={cnGroups("Delete")}
                            id={getKey("btn_remove_group", index)}
                            name="icon-trash"
                            width={16}
                            height={16}
                            fill="#A7A7A8"
                          />
                        )}
                      </div>
                    );
                  })}

                <div>
                  {groupsList.length !== 0 && isShowAdd && (
                    <Select
                      className={cnGroups("Select-Group")}
                      classNamePrefix={cnGroups("Select-Group")}
                      isSearchable={true}
                      menuPlacement={ checked.length >= 6 ? "top" : "bottom" }
                      placeholder={t("form_input_select-group")}
                      options={optionsGroupsList}
                      formatOptionLabel={templateType}
                      onChange={(e) => handleChange(e?.value)}
                      styles={{
                        indicatorSeparator: (provided: any) => ({
                          ...provided,
                          backgroundColor: "transparent",
                        }),
                      }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 6,
                        colors: {
                          ...theme.colors,
                          primary25: "#EAFBFD",
                          primary75: mainColor,
                          primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                          primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                        },
                      })}
                    />
                  )}
                </div>

                {!isNew && !isShowAdd && (
                  <Button
                    id="button_group_action_add"
                    className={cnGroups("ButtonAdd")}
                    icon="icon-plus"
                    onClick={() => setIsShowAdd(true)}
                    theme="primary-link"
                    type="button"
                  >
                    {t("button_add-Group")}
                  </Button>
                )}
              </div>
              <div className={cnGroups("Modal-Action")}>
                <Button id="button_group_action_save" type="submit">
                  {t("button-Save")}
                </Button>
                <Button id="button_group_action_cancel" theme="secondary-outline" onClick={() => handleModalGroups(false)}>
                  {t("button-Cancel")}
                </Button>
              </div>
            </form>
          </LoadingContainer>
        </Modal>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({
  groups: { list: groupsList, isLoading: isLoadingGroup },
  settings: { userStorage },
  user: { username, role },
  users: { list, chosen, isLoading },
  settingsTest: { customize }
}: RootState) => ({
  chosen,
  groupsList,
  isLoading,
  isLoadingGroup,
  list,
  role,
  userStorage,
  username,
  customize,
});

const mapDispatchToProps = { usersManagement, groupsGetAll };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_USER_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(Groups);
