import React, { FC, useEffect } from "react";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { cnField } from "components/Form";
import { Filter, cnFilter } from "components/Layout";
import { groupsGetAll } from "store/actions";
import { IUsersFilter, optionsRoleWithAdmin, typeGroup, userStatusOptions, userOtpOptions } from "core";
import { RootState } from "store/reducers";
import { Payload as PayloadGroupsGetAll } from "store/routines/groups/getAll";
import Select from "react-select";

type UsersFilterProps = {
  filters: IUsersFilter;
  groupList: typeGroup[],
  isLoadingGroup: boolean,
  localUsers: boolean,
  handleFilter: () => void;
  handleFilterCancel: () => void;
  setFilters: (filters: IUsersFilter) => void;
  setIsShow: (status: boolean) => void;
  groupsGetAll: (payload: PayloadGroupsGetAll) => void;
};

const UsersFilter: FC<UsersFilterProps> = ({
  filters,
  groupList,
  isLoadingGroup,
  localUsers,
  handleFilter,
  setFilters,
  setIsShow,
  handleFilterCancel,
  groupsGetAll,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    groupsGetAll({});
  }, [])

  const templateTypeRole = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-user-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  const templateTypeGroup = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-group-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  const handleRemoveRole = (id: string) => {
    setFilters({ ...filters, roles: filters.roles.filter((item: any) => item.value !== id) });
  };

  const handleRemoveGroup = (id: string) => {
    setFilters({ ...filters, groups: filters.groups.filter((item: any) => item.value !== id) });
  };

  const handleRoles = (value: any) => {
    setFilters({ ...filters, roles: value });
  }

  const handleGroups = (value: any) => {
    setFilters({ ...filters, groups: value });
  }

  const handleStatus = (value: any) => {
    setFilters({ ...filters, status: value.value });
  }

  const handleOtp = (value: any) => {
    setFilters({ ...filters, otp: value.value });
  }

  const customStyles = {
    control: (provided: any, state) => ({
      ...provided,
      border: "0",
      borderWidth: "0",
      boxShadow: state.isFocused ? "0 5px 15px #80CFF1" : "0 5px 15px rgba(0, 0, 0, 0.16)",
      fontSize: "14px",
      height: "40px",
      minHeight: "40px",
      padding: "0 0 0 16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      cursor: "pointer",
    }),
    placeholder: (provided: any) => ({
      ...provided,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0",
    }),
    menuList: (provided: any) => ({
      ...provided,
      height: "auto",
      maxHeight: "200px",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#80CFF1",
    }),
  };

  return (
    <Filter handleFilterCancel={handleFilterCancel} filterClose={(value: boolean) => setIsShow(value)} handleFilter={handleFilter}>
      <div className={cnFilter("Field")}>
        <label>{t("search-Name")}</label>
        <input
          onChange={e => setFilters({ ...filters, search: e.target.value })}
          className={cnFilter("Field-Search")}
          placeholder={t("search-Name")}
          type="text"
          value={filters.search}
          id="page_group_search_input"
        />
      </div>
      <div className={cnFilter("Field")}>
        <label>{t("folder_filters_box_label_role")}</label>
        <Select
          className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
          classNamePrefix={cnFilter("Select")}
          options={optionsRoleWithAdmin}
          isMulti={true}
          value={filters.roles}
          styles={customStyles}
          placeholder={t("form_input_select-Role")}
          onChange={(el) => handleRoles(el)}
          controlShouldRenderValue={false}
          formatOptionLabel={templateTypeRole}
        />
        {filters.roles.map((item) => (
          <div key={item?.value} className={cnFilter("SelectItem")}>
            <div className={cnFilter("SelectItem-Name")}>
              <Icon name="icon-user-circle" width={20} height={20} /> <span>{item.label}</span>
            </div>
            <button type="button" onClick={() => handleRemoveRole(item.value)} id="btn_handle_remove_role">
              <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
            </button>
          </div>
        ))}
      </div>
      <div className={cnFilter("Field")}>
        <label>{t("folder_filters_box_label_group")}</label>
        <Select
          className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
          classNamePrefix={cnFilter("Select")}
          options={groupList.map(({ id, name }: typeGroup) => ({ value: id, label: name }))}
          isLoading={isLoadingGroup}
          styles={customStyles}
          placeholder={t("users-filters-placeholder-group")}
          controlShouldRenderValue={false}
          isMulti={true}
          menuPlacement="top"
          value={filters.groups}
          onChange={(el) => handleGroups(el)}
          closeMenuOnSelect={false}
          formatOptionLabel={templateTypeGroup}
        />
        {filters.groups.map((item) => (
          <div key={item?.value} className={cnFilter("SelectItem")}>
            <div className={cnFilter("SelectItem-Name")}>
              <Icon name="icon-group-circle" width={20} height={20} /> <span>{item.label}</span>
            </div>
            <button type="button" onClick={() => handleRemoveGroup(item.value)} id="btn_handle_remove_user">
              <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
            </button>
          </div>
        ))}
      </div>
      {localUsers && (
        <>
          <div className={cnFilter("Field")}>
            <label>{t("folder_filters_box_label_status")}</label>
            <Select
              className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
              classNamePrefix={cnFilter("Select")}
              options={userStatusOptions}
              isLoading={isLoadingGroup}
              styles={customStyles}
              placeholder={t("folder_filters_box_input_status")}
              isMulti={false}
              menuPlacement="top"
              value={userStatusOptions.filter(e => e.value === filters.status)}
              onChange={(el) => handleStatus(el)}
            />
          </div>
          <div className={cnFilter("Field")}>
            <label>{t("folder_filters_box_label_otp")}</label>
            <Select
              className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
              classNamePrefix={cnFilter("Select")}
              options={userOtpOptions}
              isLoading={isLoadingGroup}
              styles={customStyles}
              placeholder={t("folder_filters_box_input_otp")}
              isMulti={false}
              menuPlacement="top"
              value={userOtpOptions.filter(e => e.value === filters.otp)}
              onChange={(el) => handleOtp(el)}
            />
          </div>
        </>
      )}
    </Filter>
  );
};

const mapDispatchToProps = { groupsGetAll };

const mapStateToProps = ({ 
  groups: { list: groupList, isLoading: isLoadingGroup },
}: RootState) => ({
  groupList,
  isLoadingGroup,
});

export default (connect(mapStateToProps, mapDispatchToProps))(UsersFilter);
