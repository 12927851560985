/* eslint-disable import/no-mutable-exports */
import { typeOption, FOLDER_TYPE, ROLE_TYPE, typeOptionPassword } from "core/types";
import { mapRolesName } from "core/const";

import i18n from "locales/i18n";

export const optionsLength = [
  { label: "2", value: 2 },
  { label: "4", value: 4 },
  { label: "6", value: 6 },
  { label: "8", value: 8 },
  { label: "10", value: 10 },
  { label: "12", value: 12 },
  { label: "14", value: 14 },
  { label: "16", value: 16 },
  { label: "20", value: 20 },
  { label: "24", value: 24 },
  { label: "28", value: 28 },
  { label: "32", value: 32 },
  { label: "40", value: 40 },
  { label: "48", value: 48 },
  { label: "56", value: 56 },
];

export const optionsLengthString = [
  { label: "2", value: "2" },
  { label: "4", value: "4" },
  { label: "6", value: "6" },
  { label: "8", value: "8" },
  { label: "10", value: "10" },
  { label: "12", value: "12" },
  { label: "14", value: "14" },
  { label: "16", value: "16" },
  { label: "20", value: "20" },
  { label: "24", value: "24" },
  { label: "28", value: "28" },
  { label: "32", value: "32" },
  { label: "40", value: "40" },
  { label: "48", value: "48" },
  { label: "56", value: "56" },
];

let folderTypes: typeOption[] = [];
let folderTypesUser: typeOption[] = [];
let operationOptions: typeOption[] = [];
let optionAllUser: typeOption;
let optionsKeyType: typeOption[] = [];
let optionsLevelSecurity: typeOptionPassword[] = [];
let optionsRole: typeOption[] = [];
let optionsRoleWithAdmin: typeOption[] = [];
let optionsTimeRefreshToken: typeOption[] = [];
let preFirstName: typeOption[] = [];
let sealingOptions: typeOption[] = [];
let userOtpOptions: typeOption[] = [];
let userStatusOptions: typeOption[] = [];

/**
 * Function fill needed for translate, when user change language
 */
function fill() {
  folderTypes = [
    { label: i18n.t("group-type-1"), value: FOLDER_TYPE.PERSONAL },
    { label: i18n.t("group-type-2"), value: FOLDER_TYPE.GROUP },
  ];

  folderTypesUser = [{ label: i18n.t("group-type-1"), value: FOLDER_TYPE.PERSONAL }];

  operationOptions = [
    { value: "update", label: i18n.t("history_filter_operations_option_update") },
    { value: "create", label: i18n.t("history_filter_operations_option_create") },
    { value: "delete", label: i18n.t("history_filter_operations_option_delete") },
    { value: "restore", label: i18n.t("history_filter_operations_option_restore") },
  ];

  optionsKeyType = [{ label: i18n.t("login-template"), value: "Login template" }];

  optionsLevelSecurity = [
    { label: i18n.t("security-level-1"), value: 1 },
    { label: i18n.t("security-level-2"), value: 2 },
    { label: i18n.t("security-level-3"), value: 3 },
    { label: i18n.t("security-level-4"), value: 4 },
  ];

  optionsRole = [
    { label: mapRolesName[ROLE_TYPE.USER], value: ROLE_TYPE.USER },
    { label: mapRolesName[ROLE_TYPE.MANAGER], value: ROLE_TYPE.MANAGER },
  ];

  optionsRoleWithAdmin = [...optionsRole, { label: mapRolesName[ROLE_TYPE.ADMIN], value: ROLE_TYPE.ADMIN }];

  sealingOptions = [
    { value: "", label: i18n.t("history_filter_sealing_any_sealing") },
    { value: "1", label: i18n.t("history_filter_sealing_yes") },
    { value: "0", label: i18n.t("history_filter_sealing_no") },
  ];

  userStatusOptions = [
    { value: "1", label: i18n.t("table_user_local_status_1") },
    { value: "0", label: i18n.t("table_user_local_status_0") },
    { value: "2", label: i18n.t("table_user_local_status_2") },
  ];

  userOtpOptions = [
    { value: "1", label: i18n.t("folder_filters_otp_true") },
    { value: "0", label: i18n.t("folder_filters_otp_false") },
  ];

  optionsTimeRefreshToken = [
    { value: 86400, label: i18n.t("option_time_refresh_token_one_day") },
    { value: 172800, label: i18n.t("option_time_refresh_token_two_day") },
    { value: 259200, label: i18n.t("option_time_refresh_token_three_day") },
    { value: 604800, label: i18n.t("option_time_refresh_token_one_week") },
    { value: 864000, label: i18n.t("option_time_refresh_token_ten_days") },
    { value: 1209600, label: i18n.t("option_time_refresh_token_two_week") },
    { value: 1814400, label: i18n.t("option_time_refresh_token_three_week") },
    { value: 2592000, label: i18n.t("option_time_refresh_token_month") },
    { value: 5184000, label: i18n.t("option_time_refresh_token_two_month") },
    { value: 7776000, label: i18n.t("option_time_refresh_token_three_month") },
    { value: 10368000, label: i18n.t("option_time_refresh_token_four_month") },
  ];

  /**
   * Default options for getting select where user can choose the user to see his sessions
   * @var {typeOption}
   */
  optionAllUser = {
    label: i18n.t("settings_active_sessions_options_all_users"),
    value: "all",
  };

  preFirstName = [
    {
      value: "Mr",
      label: i18n.t("option_Mr"),
    },
    {
      value: "Mrs",
      label: i18n.t("option_Mrs"),
    },
    {
      value: "Ms",
      label: i18n.t("option_Ms"),
    },
    {
      value: "Miss",
      label: i18n.t("option_Miss"),
    },
  ];
}

fill();

i18n.on("languageChanged init", () => fill());

export {
  folderTypes,
  folderTypesUser,
  operationOptions,
  optionAllUser,
  optionsKeyType,
  optionsLevelSecurity,
  optionsRole,
  optionsRoleWithAdmin,
  optionsTimeRefreshToken,
  preFirstName,
  sealingOptions,
  userOtpOptions,
  userStatusOptions,
};
