/* eslint-disable jsx-a11y/click-events-have-key-events, react/no-danger, no-nested-ternary, jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { FolderIcon, Icon } from "ss-ui";
import { Link } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { ButtonDiv, FolderStatus } from "components/Generic";

import { DATE_FORMAT, FOLDER_TYPE, getKey, parseDate, replaceUrlParam, Routes, typeFolder, FOLDER_ACCESS } from "core";

import { Payload as PayloadFavoritesSetFolder } from "store/routines/favorites/setFolder";

export const cnTree = cn("TreeList");

type Props = {
  chosen: string[];
  favoriteLoading: boolean;
  favoritesSetFolder: (payload: PayloadFavoritesSetFolder) => void;
  folder: typeFolder & { onChange?: (value: any) => void };
  folderID?: string;
  folders: typeFolder[];
  level?: number;
  isAdmin: boolean;
  onChange: (value: any) => void;
};

const TreeList: FC<Props> = ({
  chosen,
  favoriteLoading,
  favoritesSetFolder,
  folder,
  folderID = "",
  folders,
  level = 0,
  isAdmin,
  onChange
}) => {
  const location = useLocation();

  const [isOpen, setOpen] = useState(false);
  const { access, children, createdAt, description, favorite, id, countKeys, level_security, root, title, type } = folder;
  const handleClickFolder = () => {
    setOpen(!isOpen);
  };

  // Open the first personal folder if exist a hash parameters like #personal, from the Profile page
  useEffect(() => {
    if (location.hash === "#personal" && type === FOLDER_TYPE.PERSONAL && level === 0) {
      setOpen(true);
    }
  }, []);

  const handleChosen = (id: string) => {
    onChange({
      value: id,
      label: title,
    });
  };
  const { t } = useTranslation();

  const handleFavorite = (folderID: string) => {
    favoritesSetFolder({
      folderID,
    });
  };

  if (id === folderID) {
    return null;
  }

  return (
    <div className={cnTree({ level })}>
      <div className={cnTree("Folder", { chosen: chosen.indexOf(id) >= 0 })}>
        <div className={cnTree("Status")}>
          {folder.children.length > 0 ? (
            <ButtonDiv className={cnTree("Folder", { isOpen })} key={id} onClick={handleClickFolder} id={getKey(title, level_security)}>
              <FolderStatus isActive={isOpen} />
            </ButtonDiv>
          ) : (
            <div className="EmptyFolderStatus">&nbsp;</div>
          )}
        </div>
        <div onClick={() => handleChosen(id)} className={cnTree("Title")} id={getKey(`btn_folder_chose_${title}`)}>
          <FolderIcon
            level={level_security}
            size={30}
            isRoot={root === null && type !== "group"}
            isGroup={type === "group"}
            access={type === "group" ? access : "" || root === null ? "write" : ""}
          />
          <span title={title} className={cnTree("Title-Truncate")}>{title}</span>

          {(isAdmin && type === "group") || ( access === FOLDER_ACCESS.NO_ACCESS) ? null : (
            <button
              className={cnTree("Favorites")}
              type="button"
              id={getKey(`btn_folder_favorite_${title}`)}
              onClick={e => {
                e.stopPropagation();
                handleFavorite(id);
              }}
              data-tooltip={favorite ? t("tooltip_remove-favorite") : t("tooltip_add-favorite")}
            >
              <Icon width={16} height={16} name={favorite ? "icon-star-fill" : "icon-star"} />
            </button>
          ) }
        </div>
        <div className={cnTree("Description")}>
          <span className={cnTree("Description-Truncate")}>
            {description?.replace(/(<([^>]+)>)/ig, "").replace(/&(nbsp|amp|quot|lt|gt);/g, "")}
          </span>
        </div>
        <div className={cnTree("Date")}>{moment(parseDate(createdAt)).format(DATE_FORMAT)}</div>
        <div className={cnTree("Keys")}>
          <Link
            className={cnTree("Keys-Item tooltip tooltip-left")}
            to={replaceUrlParam(Routes.Keys, "folderID", id)}
            id={getKey(`link_to_folder_${title}`)}
            data-tooltip={t("tooltip-show")}
          >
            <Icon name="icon-key" />
            <span>{countKeys}</span>
          </Link>
        </div>
      </div>
      {isOpen &&
        children.length > 0 &&
        children.map((folder: typeFolder & { onChange?: (value: any) => void }) => (
          <TreeList
            chosen={chosen}
            favoriteLoading={favoriteLoading}
            favoritesSetFolder={favoritesSetFolder}
            folder={folder}
            folderID={folderID}
            folders={folders}
            key={folder.id}
            level={level_security}
            onChange={onChange}
            isAdmin={isAdmin}
          />
        ))}
    </div>
  );
};

export default TreeList;
