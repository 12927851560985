import { Action } from "redux-actions";
import { typePasswordSetting } from "core";
import { getGlobalSettings, setGlobalSettings } from "store/actions";
import { find } from "lodash";

export interface SettingsGeneratorPasswordState {
  isLoading: boolean;
  settings: typePasswordSetting[];
}

const initialState = {
  isLoading: false,
  settings: [
    {
      value: 1,
      min_characters_count: 4,
      lowercase_letters_presence: false,
      uppercase_letters_presence: false,
      digits_presence: false,
      specialchars_presence: false,
    },
    {
      value: 2,
      min_characters_count: 8,
      lowercase_letters_presence: true,
      uppercase_letters_presence: true,
      digits_presence: true,
      specialchars_presence: true,
    },
    {
      value: 3,
      min_characters_count: 10,
      lowercase_letters_presence: true,
      uppercase_letters_presence: true,
      digits_presence: true,
      specialchars_presence: true,
    },
    {
      value: 4,
      min_characters_count: 12,
      lowercase_letters_presence: true,
      uppercase_letters_presence: true,
      digits_presence: true,
      specialchars_presence: true,
    },
  ],
};

export default function generatorPassword(
  state: SettingsGeneratorPasswordState = initialState,
  { payload, type }: Action<any>,
): SettingsGeneratorPasswordState {
  switch (type) {
    case getGlobalSettings.TRIGGER:
    case setGlobalSettings.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case getGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: find(payload, ["setting", "PASSWORD_REQUIREMENTS"])?.value,
      };

    case setGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: payload.setting === "PASSWORD_REQUIREMENTS" ? payload.value : state.settings,
      };

    case getGlobalSettings.FAILURE:
    case getGlobalSettings.FULFILL:
    case setGlobalSettings.FAILURE:
    case setGlobalSettings.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
