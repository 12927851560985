import React, { FC, useEffect, useMemo, useState } from "react";
import { Icon, Button } from "ss-ui";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { isEmpty } from "lodash";
import { isIOS, isAndroid } from "react-device-detect";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";

import { Modal, ResetPassword, ExpireWindow, Unsubscribe, cnUnsubscribe, cnModal } from "components/Generic";
import { ProtectedRoutes } from "components/ProtectedRoutes";

import {
  LOCAL_STORAGE,
  PRODUCT_TYPE,
  PayloadChoseFunction,
  ROLE_TYPE,
  Routes,
  changeMainColor,
  changeSubColor,
  externalPages,
  getDataFromLS,
  removeDataFromLS,
  saveDataToLS,
} from "core";

import UnprotectedRoutes from "components/UnprotectedRoutes/UnprotectedRoutes";

import { RootState } from "store/reducers";
import {
  folderChose,
  getConfig,
  getGlobalSettings,
  getProtectedConfig,
  getSettings,
  keyChose,
  logout,
  templatesGetAll,
  setDeviceAccessDenied,
  setInitialize,
} from "store/actions";
import { SettingsResetPasswordState } from "store/reducers/settings/resetPassword";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

type Props = {
  autoLogoutTimeout?: number;
  canUpdatePassword: boolean;
  chosenFolders: string[];
  chosenKeys: string[];
  customize: SettingsCustomizeState;
  folderChose: (payload: PayloadChoseFunction) => void;
  getConfig: () => void;
  setInitialize: () => void;
  getGlobalSettings: () => void;
  getProtectedConfig: () => void;
  getSettings: () => void;
  initialize: boolean;
  isLoadingExport?: boolean;
  isUnsubscribe: boolean;
  keyChose: (payload: PayloadChoseFunction) => void;
  logout: () => void;
  logoutTime?: number;
  productType: PRODUCT_TYPE;
  resetPassword: SettingsResetPasswordState;
  role: ROLE_TYPE;
  templatesGetAll: () => void;
  username?: string;
  deviceAccessDenied: boolean;
  setDeviceAccessDenied: () => void;
};

const App: FC<Props> = ({
  autoLogoutTimeout = 5,
  canUpdatePassword,
  chosenFolders,
  chosenKeys,
  customize,
  folderChose,
  getConfig,
  getGlobalSettings,
  getProtectedConfig,
  getSettings,
  initialize,
  isLoadingExport,
  isUnsubscribe,
  keyChose,
  logout,
  setInitialize,
  logoutTime,
  productType,
  resetPassword,
  role,
  templatesGetAll,
  username = undefined,
  deviceAccessDenied,
  setDeviceAccessDenied,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [isExpire, setIsExpire] = useState<boolean>(false);
  const [azureGoApp, setAzureGoApp] = useState<string | undefined>(undefined);
  const [azureGoAppModal, setAzureGoAppModal] = useState<boolean>(true);
  const [leftTime, setLeftTime] = useState<number>(20);
  const [resetPasswordLocalStorage, setResetPasswordLocalStorage] = useState<boolean>(true);
  const [valueLogoutTime, setValueLogoutTime] = useState<any>(300000);
  const [valueLogoutTimeFree, setValueLogoutTimeFree] = useState<any>(300000);
  const [copyUrl, setCopyUrl] = useState<string | undefined>(getDataFromLS(LOCAL_STORAGE.SEND_URL)?.send_url);

  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);
  const isBusiness: boolean = useMemo(() => [PRODUCT_TYPE.ENTERPRISE, PRODUCT_TYPE.CORPORATE].includes(productType), [productType]);
  const isStandardUser: boolean = useMemo(() => [ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER].includes(role), [role]);

  const { push } = useHistory();

  useEffect(() => {
    if (customize.colorMain && !isEmpty(customize.colorMain)) {
      changeMainColor(customize.colorMain);
    }
    if (customize.colorSub && !isEmpty(customize.colorSub)) {
      changeSubColor("#a7a7a8");
    }
  }, [customize]);

  /**
   * ⌚️ Effect one time, when application creating
   * 🌎 Get global configuration
   */
  useEffect(() => {
    if (!location.pathname.includes(Routes.VpnConnection)) {
      getConfig();
    } else {
      setInitialize();
    }
  }, []);

  useEffect(() => {
    if (username) {
      templatesGetAll();
    }
  }, [username]);

  /**
   * ⌚️ Effect one time, when application creating
   * 🆕 Reset password by reset date (from server?)
   */
  useEffect(() => {
    const resetPassTime = JSON.parse(localStorage.getItem("passwordReset")!);
    if (resetPassTime) {
      const resetPassDate = new Date(resetPassTime.expiry);
      const now = new Date();
      if (resetPassDate <= now) {
        setResetPasswordLocalStorage(true);
      }
    }
  }, []);

  /**
   * ⌚️ Effect one time, when application creating
   */
  useEffect(() => {
    /**
     *  📲 Open new window if page opened by microsoft auth from mobile application
     */
    const { hash }: URL = new URL(window.location.href);

    if (!hash) return;

    let hashParameters: string = hash;

    /**
     * #️⃣ Remove a hash (#) from the string if it exist
     * From: "#code=test&state=platform", to: "code=test&state=platform"
     */
    if (hashParameters[0] === "#") hashParameters = hashParameters.slice(1);

    /**
     * 🔀 Parse hash parameters to object
     * 1. From string to array: "code=test&state=platform" => "['code=test', 'state=platform']"
     * 2. Split each elements: "['code=test", 'state=platform']" => "[['code', 'test'], ['state', 'platform']]"
     * 3. Create object from array: "[['code', 'test'], ['state', 'platform']]" => "{code: 'test', state: 'platform'}"
     */
    const parsedParameters: Map<string, string> = new Map(hashParameters.split("&").map(i => i.split("=")) as [string, string][]);

    const urlAndroid = `msauth://ch.alpeinsoft.passsecurium.abo/${
      parsedParameters.has("hash") ? parsedParameters.get("hash") : "ogY2mTyUpdDXn7IgGTQKl5AqFi0="
    }?${hashParameters}`;

    if (parsedParameters.has("state")) {
      switch (parsedParameters.get("state")) {
        case "platform_android":
          if (isAndroid) {
            setAzureGoApp(urlAndroid);
          } else {
            window.open(urlAndroid, "_blank");
          }
          break;
        case "platform_ios":
          if (isIOS) {
            window.location.assign(`msauth.ch.alpeinsoft.passsecurium-abo://auth/?${hashParameters}`);
          } else {
            window.open(`msauth.ch.alpeinsoft.passsecurium-abo://auth/?${hashParameters}`);
          }
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (username) {
      /**
       * 📝 Get protected config, when a username fill from a local storage if it exist
       */
      getProtectedConfig();
      removeDataFromLS(LOCAL_STORAGE.SEND_URL);

      if (isBusiness) getGlobalSettings();
      getSettings();
    }
  }, [isAdmin, isBusiness, isStandardUser, username]);

  useEffect(() => {
    setValueLogoutTime(logoutTime && logoutTime * 60 * 1000);
    setValueLogoutTimeFree(autoLogoutTimeout && autoLogoutTimeout * 60 * 1000);
    setTimeout(() => {
      username ? reset() : pause();
    }, 5000);
  }, [logoutTime, autoLogoutTimeout]);

  useEffect(() => {
    if (leftTime === 0) {
      setLeftTime(20);
      setIsExpire(false);
      logout();
    }
  }, [leftTime]);

  useEffect(() => {
    username ? reset() : pause();
  }, [username]);

  const resetExpire = () => {
    setLeftTime(20);
    setIsExpire(false);
    reset();
  };

  const handleOnIdle = () => {
    setIsExpire(true);
  };

  const localStorageResetPasswordValue = (value: boolean) => {
    setResetPasswordLocalStorage(value);
  };

  const { reset, pause } = useIdleTimer({
    debounce: 500,
    events: ["keydown", "mousedown", "touchstart", "MSPointerDown"],
    onIdle: handleOnIdle,
    startOnMount: false,
    stopOnIdle: true,
    timeout: isBusiness ? valueLogoutTime : valueLogoutTimeFree,
  });

  history.listen(() => {
    if (chosenFolders.length > 0) {
      folderChose({ status: false, choseAll: true });
    }
    if (chosenKeys.length > 0) {
      keyChose({ status: false, choseAll: true });
    }
  });

  useEffect(() => {
    const { pathname, href } = window.location;

    if (externalPages.some(page => pathname.startsWith(page.replace(/\/:hash\?/gi, "")))) {
      removeDataFromLS(LOCAL_STORAGE.SEND_URL);
      setCopyUrl(undefined);
    } else {
      saveDataToLS(LOCAL_STORAGE.SEND_URL, {
        send_url: new URL(href),
      });
      setCopyUrl(href);
    }
  }, [copyUrl]);

  const handleAzureGoApp = () => {
    if (azureGoApp) {
      const a = document.createElement("a");
      a.href = azureGoApp;
      a.click();
    }
    setAzureGoAppModal(false);
    setAzureGoApp(undefined);
    push(Routes.Login);
  };

  const accessDeviceLogout = () => {
    setDeviceAccessDenied();
    logout();
  };

  if (!initialize) {
    return null;
  }

  return (
    <>
      {isExpire && !isLoadingExport && (
        <ExpireWindow
          leftTime={leftTime}
          setLeftTime={setLeftTime}
          handleRedirect={resetExpire}
          theme="Attention"
          contentText={t("expire-window-header")}
          contentButton={t("expire-window-button")}
        />
      )}
      {azureGoApp && azureGoAppModal && (
        <Modal className="Modal-Azure-Go-App" handleClose={() => setAzureGoAppModal(false)}>
          <div className="Modal-Azure-Go-App-Header">
            <span>{t("login_login")}</span>
            <Icon name="icon-lock-open" width={16} height={16} fill="#ffffff" />
          </div>
          <div className="Modal-Azure-Go-App-Content">
            <div className="Modal-Azure-Go-App-Icon">
              <Icon name="app/pass-logo" />
            </div>
            <h2>{t("login_modal-text")}</h2>
            <Button id="button_Modal-Azure-Go-App" onClick={handleAzureGoApp} width="320px">
              {t("login_modal-button")}
            </Button>
          </div>
        </Modal>
      )}

      {isUnsubscribe && <Unsubscribe />}

      {resetPasswordLocalStorage && isAdmin && resetPassword.enabled && resetPassword.required && canUpdatePassword && (
        <div className={cnUnsubscribe()}>
          <ResetPassword onChange={localStorageResetPasswordValue} isForcibly={true} />
        </div>
      )}

      {deviceAccessDenied && !location.pathname.includes(Routes.Login) && (
        <div className={cnUnsubscribe()}>
          <Modal className={cnModal("ModalFolderSelect")} isCloseable={false}>
            <div className={cnModal("Header")}>
              <Icon fill="#E3000B" name="icon-warning" />
              {t("error_boundary_warning")}
            </div>
            <div className={cnModal("Scroll")}>{t("error_device_access_denied")}</div>
            <div className={cnModal("Actions")}>
              <Button id="button_device_access_denied" onClick={accessDeviceLogout}>
                {t("button-Logout")}
              </Button>
            </div>
          </Modal>
        </div>
      )}

      <Switch>
        {UnprotectedRoutes.map(item => item)}
        <ProtectedRoutes />

        <Redirect to={Routes.Login} />
      </Switch>
      <ToastContainer autoClose={4000} limit={5} />
    </>
  );
};

const mapStateToProps = ({
  folders: { chosen: chosenFolders },
  keys: { chosen: chosenKeys, isLoadingExport },
  settings: { productType, logoutTime, autoLogoutTimeout, canUpdatePassword, initialize },
  settingsTest: { resetPassword, customize },
  user: { username, isUnsubscribe, role },
  templates: { deviceAccessDenied },
}: RootState) => ({
  autoLogoutTimeout,
  canUpdatePassword,
  chosenFolders,
  chosenKeys,
  customize,
  initialize,
  isLoadingExport,
  isUnsubscribe,
  logoutTime,
  productType,
  resetPassword,
  role,
  username,
  deviceAccessDenied,
});

const mapDispatchToProps = {
  folderChose,
  getConfig,
  getGlobalSettings,
  getProtectedConfig,
  getSettings,
  keyChose,
  logout,
  templatesGetAll,
  setDeviceAccessDenied,
  setInitialize,
};

export default compose<Props, Partial<Props>>(connect(mapStateToProps, mapDispatchToProps))(App);
