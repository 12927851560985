import React, { FC, useEffect, useState } from "react";
import { Icon } from "ss-ui";
import { isEmpty } from "lodash";
import { RootState } from "store/reducers";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Field, formValueSelector, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { FormValuesFolder } from "store/routines/folders/manage";
import { folderAccess, FORM_FOLDER_FILTER, typeFolder, typeGroup, typeUser, http } from "core";
import { FormCheckbox, FormMultiSelectOutsideRender, FormLazySelect } from "components/Form";
import { groupsGetAll, usersGetAll } from "store/actions";
import { Payload as PayloadGroupsGetAll } from "store/routines/groups/getAll";
import { LoadingContainer } from "components/Generic";
import { useTranslation } from "react-i18next";
import { typeFolderFilter } from "components/InfoBox/FolderFilter/FolderFilterTypes";
import { Filter, cnFilter } from "components/Layout";

type Props = {
  usersList: typeUser[];
  groupsList: typeGroup[];
  foldersList: typeFolder[];
  usersGetAll: () => void;
  groupsGetAll: (payload: PayloadGroupsGetAll) => void;
  handleClose?: () => void;
  handleReset?: () => void;
  handleSubmitFilter: (payload: typeFolderFilter) => void;
  isLoadingUsers: boolean;
  isLoadingGroups: boolean;
  formData: any;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const FolderFilter: FC<Props> = ({
  reset,
  usersList,
  groupsList,
  foldersList,
  usersGetAll,
  groupsGetAll,
  isLoadingUsers,
  isLoadingGroups,
  handleClose = () => false,
  handleReset = () => false,
  handleSubmitFilter,
  handleSubmit,
  formData,
  change,
}): JSX.Element => {
  const { t } = useTranslation();

  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);

  useEffect(() => {
    if (usersList.length === 0 && !isLoadingUsers) {
      usersGetAll();
    }
    if (groupsList.length === 0 && !isLoadingGroups) {
      groupsGetAll({});
    }
  }, [usersList, groupsList, usersGetAll, groupsGetAll, isLoadingUsers, isLoadingGroups]);

  // const countFolder = useMemo(() => getCountFilteringFolderByParam(foldersList, groupsList, formData), [foldersList, groupsList, formData]);

  const handleSubmitForm = () => {
    handleSubmitFilter({ ...formData, isFiltering: true })
    handleClose();
  };

  const handleCancel = () => {
    reset();
    handleReset();
    handleSubmitFilter({ ...[], isFiltering: false })
  };

  const handleRemoveGroup = (id: string) => {
    change(
      "groups",
      formData.groups.filter(({ value }) => value !== id),
    );
  };

  const handleRemoveUser = (id: string) => {
    change(
      "users",
      formData.users.filter(({ value }) => value !== id),
    );
  };

  return (
    <>
      <Filter disableSubmit={isEmpty(formData)} handleFilterCancel={handleCancel} filterClose={handleClose} handleFilter={handleSubmitForm}>
        <LoadingContainer isLoading={isLoadingUsers || isLoadingGroups}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={cnFilter("Field")}>
              <label>{t("folder_filters_box_label_access")}</label>
              {folderAccess.map(access => (
                <div className={cnFilter("Field-Checkbox")} key={access.type}>
                  <Field
                    component={FormCheckbox}
                    label={access.label}
                    name={access.type}
                    labelSide="right"
                  />
                </div>
              ))}
            </div>
            <div className={cnFilter("Field")}>
              <label>{t("folder_filters_box_label_ext")}</label>
              <div className={cnFilter("Field-Checkbox")}>
                <Field
                  component={FormCheckbox}
                  label={t("folder_filters_box_input_ext_yes")}
                  name="web_access"
                  labelSide="right"
                />
              </div>
            </div>
            {/* Sealing Key */}
            {/* <div className={cnFilter("Field")}>
            <label>{t("folder_filters_box_label_seal")}</label>
            <div className={cnFilter("Field-Checkbox")}>
              <Field
                component={FormCheckbox} 
                label={t("folder_filters_box_input_seal_yes")} 
                name="sealing" 
                labelSide="right" 
              /> 
            </div>
          </div> */}
            <div className={cnFilter("Field")}>
              <label>{t("folder_filters_box_label_group")}</label>
              <Field
                component={FormMultiSelectOutsideRender}
                placeholder={t("folder_filters_box_placeholder_group")}
                isSearchable={true}
                renderOutside={true}
                className={cnFilter("Select")}
                classNamePrefix={cnFilter("Select-Prefix")}
                name="groups"
                menuPlacement="top"
                templateList={(label, value) => (
                  <div key={value} className={cnFilter("SelectItem")}>
                    <div className={cnFilter("SelectItem-Name")}>
                      <Icon name="icon-group-circle" width={20} height={20} /> <span>{label}</span>
                    </div>
                    <button type="button" onClick={() => handleRemoveGroup(value)} id="btn_handle_remove_group">
                      <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
                    </button>
                  </div>
                )}
                isMultiple={true}
                hideSelectedOptions={true}
                closeMenuOnSelect={false}
                options={groupsList.map(({ id, name }: typeGroup) => ({ value: id, label: name }))}
                template={(label: string, value: string): JSX.Element => (
                  <div className="ManageKey-Option">
                    <Icon name="icon-group-circle" width={20} height={20} />
                    &nbsp;
                    <p title={label}>{label}</p>
                  </div>
                )}
              />
            </div>
            <div className={cnFilter("Field")}>
              <label>{t("folder_filters_box_label_user")}</label>
              <Field
                component={FormLazySelect}
                placeholder={t("folder_filters_box_placeholder_user")}
                isSearchable={true}
                renderOutside={true}
                name="users"
                menuPlacement="top"
                className={cnFilter("Select")}
                hideSelectedOptions={true}
                classNamePrefix={cnFilter("Select-Prefix")}
                loadOptions={async selectSearchUsers => {
                  let params: { currentPage: number; pageSize: number; search?: string; } = {
                    currentPage: currentPageUsers,
                    pageSize: 20,
                    search: selectSearchUsers,
                  };
                  if (currentPageUsers) params = { ...params, currentPage: currentPageUsers };
                  if (selectSearchUsers !== "") params = { ...params, search: selectSearchUsers, currentPage: 1 };
                  const response: any = await http({ route: "manager/users", method: "GET", payload: { params } });
                  setCurrentPageUsers(response.currentPage + 1);
                  return {
                    hasMore: currentPageUsers < response.pagesCount,
                    options: response.results.map(user => ({
                      label: user.username,
                      value: user.id,
                    })),
                  };
                }}
                templateList={(label, value) => (
                  <div key={value} className={cnFilter("SelectItem")}>
                    <div className={cnFilter("SelectItem-Name")}>
                      <Icon name="icon-user-circle" width={20} height={20} /> <span>{label}</span>
                    </div>
                    <button type="button" onClick={() => handleRemoveUser(value)} id="btn_handle_remove_user">
                      <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
                    </button>
                  </div>
                )}
                isMultiple={true}
                template={(label: string, value: string): JSX.Element => (
                  <div className="ManageKey-Option">
                    <Icon name="icon-user-circle" width={20} height={20} />
                    &nbsp;
                    {label}
                  </div>
                )}
              />
            </div>
          </form>
        </LoadingContainer>
      </Filter>
    </>
  );
};

const selector = formValueSelector(FORM_FOLDER_FILTER);

const mapStateToProps = (state: RootState) => ({
  isLoadingUsers: state.users.isLoading,
  isLoadingGroups: state.groups.isLoading,
  usersList: state.users.list,
  groupsList: state.groups.list,
  chosen: state.folders.chosen,
  isLoading: state.folders.isLoading,
  foldersList: state.folders.list,
  formData: selector(state, "write", "read", "no_access", "web_access", "sealing", "groups", "users"),
});

const mapDispatchToProps = { usersGetAll, groupsGetAll };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValuesFolder>({
    form: FORM_FOLDER_FILTER,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
)(FolderFilter);
