import { createRoutine, Routine } from "redux-saga-routines";
import { http, typeResponseLDAPUsers } from "core";
import { call, put, takeLeading } from "redux-saga/effects";
import { Action } from "redux-actions";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("USERS_GET_ALL_LDAP");

export type Payload = {
  skipToken?: string;
};

const getAllLDAP = ({ skipToken }: Payload) => {
  const payload = skipToken ? { params: { skip_token: skipToken } } : {};
  return http({ route: "manager/users-ldap", method: "GET", payload });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response: typeResponseLDAPUsers = yield call(getAllLDAP, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLeading(action.TRIGGER, handler);
}
