import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";

import { cnAside } from "components/Layout";

export type AsideSideType = "right" | "left";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  side?: AsideSideType;
}

const Aside: FC<IProps> = ({ children, className = "", side = "left", ...props }) => {
  return (
    <aside className={`${cnAside({ side })} ${className}`} {...props}>
      {children}
    </aside>
  );
};

export default Aside;
