import { get as lodashGet, has, snakeCase } from "lodash";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http, getDataFromLS, LOCAL_STORAGE } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { favoritesGet, folderGetAll, favoritesGetKeys, setGlobalSearch } from "store/actions";

export type Payload = {
  keys: string[];
  folderID: string;
};

export const action: Routine = createRoutine("KEYS_MOVE");

const move = ({ keys, folderID }: Payload) => http({ route: `keys/change-folder/${folderID}`, method: "POST", payload: { keys } });

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(move, payload);

    toast.success(i18n.t("toast_key_success_move", { count: payload.keys.length }));

    const lastSearch = getDataFromLS(LOCAL_STORAGE.LAST_SEARCH_VALUE);

    if (window.location.pathname.includes("search") && lastSearch) {
      yield put(setGlobalSearch.trigger(lastSearch));
    }

    yield put(folderGetAll.trigger());

    yield put(action.success(payload.keys));
    yield put(favoritesGet.trigger());
    yield put(favoritesGetKeys.trigger({ page: 1 }));
  } catch ({ response: { data } }) {
    if (has(data, "detail.messages")) {
      toast.error(i18n.t(`error_${snakeCase(lodashGet(data, "detail.messages[0]"))}`));
    } else if (lodashGet(data, "detail")) {
      toast.error(i18n.t(`toast_key_error_${snakeCase(lodashGet(data, "detail"))}`));
    } else {
      //
    }

    yield put(folderGetAll.trigger());

    yield put(action.failure(lodashGet(data, "detail")));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
