import React, { FC } from "react";
import { Icon, Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { LoadingContainer } from "components/Generic";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import { connect } from "react-redux";
import { RootState } from "store/reducers";
import { setUnsubscribe } from "store/actions";

import { Payload as PayloadSetUnsubscribe } from "store/routines/user/setUnsubscribe";

export const cnUnsubscribe = cn("Unsubscribe");

type Props = {
  isLoading: boolean;
  unsubscribeToken: string;
  setUnsubscribe: (payload: PayloadSetUnsubscribe) => void;
};

const Unsubscribe: FC<Props> = ({ 
  isLoading,
  unsubscribeToken,
  setUnsubscribe,
}) => {

  const { t } = useTranslation();

  const handleUnsubscribe = (value: string) => {
    setUnsubscribe({
      method: value,
      token: unsubscribeToken,
    });
  };

  return (
    <div className={cnUnsubscribe()} id="unsubscribe_container">
      <div className={cnUnsubscribe("Modal")}>
        <LoadingContainer isLoading={isLoading}>
          <div className={cnUnsubscribe("Header")}>
            <Icon name="icon-warning" fill="#E3000B" width={18} height={18} />
            {t("unsubscribe-title")}
          </div>
          <div className={cnUnsubscribe("Content")}>
            <p>{t("unsubscribe-message")}</p>
          </div>
          <div className={cnUnsubscribe("Actions")}>
            <Button id="button_unsubscribe_success" onClick={() => handleUnsubscribe("post")} theme="danger">
              {t("unsubscribe-success")}
            </Button>
            <Button id="button_unsubscribe_cancel" theme="secondary-outline" onClick={() => handleUnsubscribe("delete")}>
              {t("unsubscribe-cancel")}
            </Button>
          </div>
        </LoadingContainer>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user: { unsubscribeToken },
}: RootState) => ({
  unsubscribeToken,
  setUnsubscribe,
});

const mapDispatchToProps = { setUnsubscribe };

export default compose<Props, Partial<Props>>(connect(mapStateToProps, mapDispatchToProps))(Unsubscribe);
