import { SORT_BY } from "core";
import { typeTableHeaderElement } from "components/Table";

export const tableHeader: typeTableHeaderElement[] = [
  {
    id: "user_name",
    title: "Name",
    sortInit: SORT_BY.USER_NAME,
  },
  {
    id: "user_role",
    title: "Role",
    sortInit: SORT_BY.ROLE,
  },
  {
    id: "user_groups",
    title: "Groups",
    sortInit: SORT_BY.GROUPS_ARR,
  },
];

export const tableHeaderLocal: typeTableHeaderElement[] = [
  {
    id: "user_name",
    title: "Name",
    sortInit: SORT_BY.USER_NAME,
  },
  {
    id: "user_status",
    title: "Status",
    sortInit: SORT_BY.ROLE,
  },
  {
    id: "user_role",
    title: "Role",
    sortInit: SORT_BY.ROLE,
  },
  {
    id: "user_otp",
    title: "2FA",
    sortInit: SORT_BY.ROLE,
  },
  {
    id: "user_groups",
    title: "Groups",
    sortInit: SORT_BY.GROUPS_ARR,
  },
];
