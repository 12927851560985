/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useState, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { ITabs } from "components/Generic/Tabs/Tabs";
import { ROLE_TYPE } from "core";
import { InfoBoxKey } from "components/InfoBox";
import { InfoBoxFolder } from "components/InfoBox/Folder";
import { PropsWithChosen, WithChosen } from "hoc";
import { LoadingContainer, Tabs } from "components/Generic";
import { Aside, cnAsideContent, Content, ContentWrapper, ContentHeader } from "components/Layout";
import { RootState } from "store/reducers";
import { Payload as payloadGetKeysSearch } from "store/routines/search/getKeysSearch";
import { Payload as payloadGetFolderSearch } from "store/routines/search/getFoldersSearch";
import { getKeysSearch, setHeaderIcon, setHeaderTitle, getFoldersSearch } from "store/actions";
import SearchKey from "./Fragments/Keys/SearchKey";
import SearchFolder from "./Fragments/Folders/SearchFolder";


type Props = {
  searchProps: string;
  keysTotalItems: number;
  foldersTotalItems: number;
  isLoadingKeys: boolean,
  isLoadingFolders: boolean,
  chosenKeys: string[];
  chosenFolders: string[];
  role: ROLE_TYPE;
  setHeaderIcon: (payload: string | undefined) => void;
  setHeaderTitle: (payload: string | undefined) => void;
  getKeysSearch: (payload: payloadGetKeysSearch) => void;
  getFoldersSearch: (payload: payloadGetFolderSearch) => void;
} & PropsWithChosen;

const Search: FC<Props> = ({
  searchProps,
  keysTotalItems,
  isLoadingKeys,
  isLoadingFolders,
  foldersTotalItems,
  chosenKeys,
  chosenFolders,
  role,
  setHeaderIcon,
  setHeaderTitle,
  getKeysSearch,
  getFoldersSearch,
}) => {
  const { t } = useTranslation();

  const isFreeUser: boolean = useMemo(() => role === ROLE_TYPE.FREE_USER, [role]);
  const isUser: boolean = useMemo(() => role === ROLE_TYPE.USER, [role]);

  const [tabs, setTabs] = useState<ITabs[]>(
    isFreeUser ?
      [{
        id: 1,
        isActive: true,
        label: t("table_title-Keys"),
        icon: "key",
        count: keysTotalItems,
      }] :
      [{
          id: 1,
          isActive: true,
          label: t("table_title-Keys"),
          icon: "key",
          count: keysTotalItems,
        },
        {
          id: 2,
          isActive: false,
          label: t("table_title-Folders"),
          icon: "folder",
          count: foldersTotalItems,
        }
      ]
  );

  useEffect(() => {
    setTabs(isFreeUser ?
      [{
        id: 1,
        isActive: true,
        label: t("table_title-Keys"),
        icon: "key",
        count: keysTotalItems,
      }] :
      [{
          id: 1,
          isActive: tabs[0].isActive,
          label: t("table_title-Keys"),
          icon: "key",
          count: keysTotalItems,
        },
        {
          id: 2,
          isActive: tabs[1].isActive,
          label: t("table_title-Folders"),
          icon: "folder",
          count: foldersTotalItems,
        }
      ])
  }, [foldersTotalItems, keysTotalItems]);

  useEffect(() => {
    setHeaderTitle(t("header-search-results"));
    setHeaderIcon("search");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(searchProps)) {
      getKeysSearch({ currentPage: 1, search: searchProps });
      if (!isFreeUser) {
        getFoldersSearch({ currentPage: 1, search: searchProps });
      }
    }
  }, [searchProps]);

  const handleChangeTab = (id: number) => {
    setTabs(tabs.map((tab: ITabs) => ({ ...tab, isActive: tab.id === id })));
  };

  return (
    <ContentWrapper id="search_content_wrapper">
      <Content id="search_content">
        <ContentHeader id="keys_content_header">
          <Tabs moreClass="Search" tabs={tabs} onClick={handleChangeTab} />
        </ContentHeader>
        <LoadingContainer isLoading={isLoadingKeys || isLoadingFolders}>
          {tabs && tabs[0].isActive && (
            <SearchKey />
          )}
          {!isFreeUser && tabs[1].isActive && (
            <SearchFolder isUser={ isUser } />
          )}
        </LoadingContainer>
      </Content>
      <Aside side="right" id="search_aside_right">
        {chosenKeys.length > 0 && <InfoBoxKey />}
        {chosenFolders.length > 0 && <InfoBoxFolder />}
        {chosenKeys.length > 0 || chosenFolders.length > 0 ? null : (
          <div className={cnAsideContent()}>
            <div className={cnAsideContent("Header")}>
              <h2>{t("aside_right-Information")}</h2>
            </div>
            <div className={cnAsideContent("Description")}>
              <p>{t("aside_right_text-Search")}</p>
            </div>
            <div className={cnAsideContent("Total")}>
              <div className={cnAsideContent("Total-Count")}>
                <p>{t("aside_right-Total")} {t("search-Name")}:</p>
              </div>
              <div className={cnAsideContent("Total-Count")}>
                <span>{keysTotalItems}</span>
                <span>{t("table_title-Keys")}</span>
              </div>
              {!isFreeUser && (
                <div className={cnAsideContent("Total-Count")}>
                  <span>{foldersTotalItems}</span>
                  <span>{t("table_title-Folders")}</span>
                </div>
              )}
            </div>
          </div>
        )}
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  search: {
    search,
    isLoadingKeys,
    isLoadingFolders,
    keysTotalItems,
    foldersTotalItems,
  },
  keys: { chosen: chosenKeys },
  folders: { chosen: chosenFolders },
  user: { role },
}: RootState) => ({
  searchProps: search,
  isLoadingKeys,
  isLoadingFolders,
  keysTotalItems,
  foldersTotalItems,
  chosenKeys,
  chosenFolders,
  role,
});

const mapDispatchToProps = {
  setHeaderIcon,
  setHeaderTitle,
  getKeysSearch,
  getFoldersSearch,
};

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(Search);
