import { http } from "core/services";
import { now } from "lodash";

/**
 * Security method to encrypt/decrypt field value
 * @param password
 * @param action
 */
export async function apiCallSecurityPassword({
  password,
  action = "decrypt",
}: {
  password: string;
  action?: "decrypt" | "encrypt";
}): Promise<string> {
  const response = await http({
    route: `password/${action}`,
    method: "POST",
    payload: { password },
  });

  return response.password as string;
}

/**
 * Check the field to secure (login, password)
 * @param field - field name
 * @param value - field value
 * @return Promise<boolean>
 */
export async function apiCallCheckField({ field, value }: { field: string; value: string }): Promise<boolean> {
  const data: FormData = new FormData();

  data.append("field", field);
  data.append("value", value);

  try {
    await http({
      route: "key/check",
      method: "POST",
      payload: data,
    });

    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Receiving a file from the server, format conversion to base 64
 * @param id key id
 * @param field field name
 * @return Promise<string>
 */
export async function apiCallFetchFileImage(id, field): Promise<string> {
  const res = await http({ route: `key/${id}/attached/${field}?${now()}`, responseType: "arraybuffer" });
  const blob = new Blob([res], { type: "image/jpeg" });
  const reader = new FileReader();

  reader.readAsDataURL(blob);

  return new Promise((resolve, reject) => {
    try {
      reader.onloadend = () => {
        const base64 = reader.result;
        resolve(base64 as string);
      };
    } catch (e) {
      reject(e);
    }
  });
}
