import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";

export const action: Routine = createRoutine("GROUPS_DEFERRED_SYNC");

function* handler() {
  try {
    yield put(action.success());
  } catch (error) {
    yield put(action.failure());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}