import axios, { AxiosError, AxiosInstance, Method } from "axios";
import { toast } from "react-toastify";

import i18n from "locales/i18n";
import { history } from "components/App/App";

import { capitalizeFirstLetter, MainRoutes } from "core";
import { getApiUrl } from "core/helpers/functions/url";

import { get } from "lodash";
import { getDataFromLS, LOCAL_STORAGE, removeDataFromLS } from "./localStorage";

interface IPropsGetHeader {
  contentType?: string;
  additionalHeader?: { [key: string]: string };
}

const getHeaders = ({ contentType = "application/json", additionalHeader = {} }: IPropsGetHeader) => ({
  "Content-Type": contentType,
  Accept: "application/json",
  ...additionalHeader,
});

const axiosInstance: AxiosInstance = axios.create({
  baseURL: getApiUrl(),
});

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

interface Props {
  additionalHeader?: { [key: string]: string };
  isProtected?: boolean;
  method?: Method;
  payload?: object;
  responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream";
  route: string;
}

/**
 * Wrapper Axios, for telling with server
 * @param {object} additionalHeader - add additional header to request
 * @param {boolean} isProtected - boolean, default = true
 * @param {string} method - Method, default = get
 * @param {object} payload - data to send it to api
 * @param {string} responseType - "arraybuffer" | "blob" | "document" | "json" | "text" | "stream", default = json
 * @param {string} route - route to request it
 */
export const http = ({
  additionalHeader = {
    "time-zone": timeZone,
  },
  isProtected = true,
  method = "get",
  payload,
  responseType = "json",
  route,
}: Props): Promise<any> | Error => {
  const user = getDataFromLS(LOCAL_STORAGE.USER);

  axiosInstance.defaults.responseType = responseType;
  axiosInstance.defaults.headers =
    payload instanceof FormData
      ? getHeaders({
          contentType: "multipart/form-data",
          additionalHeader,
        })
      : getHeaders({ additionalHeader });

  if (isProtected && user.token) {
    axiosInstance.defaults.headers.Authorization = `${capitalizeFirstLetter(user.tokenType)} ${user.token}`;
  } else if (isProtected && (!user.token || !user.tokenType)) {
    return new Error("The token is empty, please sign in again");
  }

  return axiosInstance[method.toLowerCase() as "get"](route, method.toLowerCase() === "delete" ? { data: payload } : payload);
};

// *********************************************
// *************** REFRESH TOKEN ***************
// *********************************************
const handleRejectToken = (e: any) => {
  removeDataFromLS(LOCAL_STORAGE.USER);
  history.push(MainRoutes.Login);
  toast.info(i18n.t("toast_automatic_logout"), { toastId: "toast_automatic_logout" });
  return Promise.reject(e);
};

// *********************************************
// *************** GO TO VPN PAGE ***************
// *********************************************
const handleVpnPage = (e: any) => {
  toast.error(i18n.t("error_connection_vpn"), { toastId: "error_connection_vpn" });
  history.push(MainRoutes.VpnConnection);
  window.location.reload();
  return Promise.reject(e);
};

// *********************************************
// *************** Block Users Standard ***************
// *********************************************
const handleBlockUser = (e: any) => {
  removeDataFromLS(LOCAL_STORAGE.USER);
  history.push(MainRoutes.Login);
  toast.error(i18n.t("error_account_is_blocked_all"), { toastId: "error_account_is_blocked_all" });
  if (!window.location.pathname.includes(MainRoutes.Login)) { 
    window.location.reload();
  }
  return Promise.reject(e);
};

// let statusRefreshToken: boolean = false;
// let requestRefreshToken: any;

// const getCallRefreshToken = (error: AxiosError<any>) => {
//   const user = getDataFromLS(LOCAL_STORAGE.USER);

//   if (!user || !user.refreshToken) {
//     return handleRejectToken(error);
//   }

//   const { refreshToken } = user;

//   if (statusRefreshToken === true) {
//     return requestRefreshToken;
//   }

//   requestRefreshToken = axios
//     .post(
//       "oauth/token/create",
//       {
//         grant_type: "refresh_token",
//         refresh_token: refreshToken,
//         device_id: navigator.appCodeName,
//         device_name: navigator.platform,
//       },
//       { headers: getHeaders() },
//     )
//     .then(({ status, data }) => {
//       if (status === 401) {
//         return handleRejectToken(error);
//       }
//       saveDataToLS(LOCAL_STORAGE.USER, data);

//       statusRefreshToken = false;

//       return Promise.resolve(data);
//     })
//     .catch(e => handleRejectToken(e));

//   statusRefreshToken = true;
//   return requestRefreshToken;
// };

axiosInstance.interceptors.response.use(
  response => {
    if (response.status === 204) {
      return null;
    }
    return response.data;
  },
  (error: AxiosError) => {
    switch (canUserRefreshing(error)) {
      case 0:
      case 1:
        return handleRejectToken(error);
      // return getCallRefreshToken(error).then(() => axiosInstance.request(error.config));
      case 3:
        return handleVpnPage(error);
      case 4:
        return handleBlockUser(error);
      case 2:
      default:
        return Promise.reject(error);
    }
  },
);

function canUserRefreshing(error: AxiosError): number {
  const user = getDataFromLS(LOCAL_STORAGE.USER);

  const {
    response: {
      data: { error_description: errorDescription = null, message = null, detail: { messages = [] } = {} } = {},
      status = null,
    } = {},
  } = error;

  if (status === 401 && (get(error, "response.data.error", "") === "account_is_blocked" || messages[0] === "account_is_blocked")) {
    return 4;
  }

  if (status === 401 && user && !user.saveAccount && messages[0] !== "Not privileged to request the resource.") {
    return 0;
  }

  if (status === 401 && user && user.token_type && user.token_type !== "oauth") {
    return 0;
  }

  if (errorDescription && errorDescription === "Refresh token has expired") {
    return 0;
  }

  if (errorDescription && errorDescription === "Invalid refresh token") {
    return 0;
  }

  if (messages && messages.length > 0 && messages[0] === "Expired JWT Token") {
    return 0;
  }

  if (status === 401 && message === "The access token provided is invalid.") {
    return 1;
  }

  if (status === 401 && message === "The access token provided has expired.") {
    return 1;
  }

  if (!error.response && error.message === "Network Error") {
    toast.error(i18n.t("error_connection"), { toastId: "error_connection" });
    return 2;
  }

  if (error && status === 403 && get(error, "response.data.status", "") === "Access denied") {
    return 2;
  }

  if (error && status === 403 && get(error, "response.data.detail.messages", "") === "device_access_denied") {
    return 2;
  }

  if (error && status === 403 && get(error, "response.data", "").includes("403 Forbidden")) {
    return 3;
  }

  if (status !== 403 && errorDescription !== "Invalid username and password combination") {
    return 2;
  }

  if (status === 403) {
    return 2;
  }

  if (status === 403 && messages && messages.length > 0 && messages[0] === "Access Denied.") {
    return 2;
  }

  if (status === 401 && messages[0] === "Not privileged to request the resource.") {
    return 2;
  }

  return 0;
}

// *********************************************
// ************* END REFRESH TOKEN *************
// *********************************************
