/* eslint-disable react/jsx-no-target-blank, react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Aside, cnAsideContent } from "components/Layout";
import { ROLE_TYPE, PRODUCT_TYPE, USER_STORAGE } from "core";
import { setHeaderIcon, setHeaderTitle, getProfile } from "store/actions";
import { RootState } from "store/reducers";
import { 
  AutomaticLogout, 
  BestIcon, 
  EmailNotifications, 
  ActivityLogCleaning, 
  // Yubikey, 
  CustomizeMailerSettings 
} from "pages/Settings/Fragments";
import Settings from "pages/Settings/Settings";

type Props = {
  getProfile: () => void;
  isAdmin: boolean;
  isCorporate: boolean;
  isLocalUser: boolean;
  isStandardUser: boolean;
  setHeaderIcon: (payload: string) => void;
  setHeaderTitle: (payload: string) => void;
};

const General: FC<Props> = ({
  getProfile,
  isAdmin,
  isCorporate,
  isLocalUser,
  isStandardUser,
  setHeaderIcon,
  setHeaderTitle,
}) => {
  const { t } = useTranslation();

  /**
   * UseEffect one time when page loaded
   */
  useEffect(() => {
    getProfile();
    setHeaderTitle(t("nav-General"));
    setHeaderIcon("setting");
  }, []);

  const aside =
    <Aside side="right" id="settings_general_aside_right">
      <div className={cnAsideContent()}>
        <div className={cnAsideContent("Header")}>
          <h2>{t("aside_right-Information")}</h2>
        </div>
        <div className={cnAsideContent("Description")}>
          <p>{t("aside_right_text_settings_general")}</p>
        </div>
      </div>
    </Aside>;

  return (
    <Settings id="settings_general" aside={aside}>
      {!isAdmin && !isStandardUser && <EmailNotifications />}
      <BestIcon />
      {(isAdmin || isStandardUser) && <AutomaticLogout />}
      {isAdmin && <ActivityLogCleaning />}
      {/* {isCorporate && isLocalUser && <Yubikey />} */}
      {isAdmin && <CustomizeMailerSettings />}
    </Settings>
  );
};

const mapStateToProps = ({ user: { role }, settings: { productType, userStorage } }: RootState) => ({
  isAdmin: role && role === ROLE_TYPE.ADMIN,
  isCorporate: productType && productType === PRODUCT_TYPE.CORPORATE,
  isLocalUser: userStorage && userStorage === USER_STORAGE.LOCAL,
  isStandardUser: [ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER].includes(role),
});

const mapDispatchToProps = {
  getProfile,
  setHeaderIcon,
  setHeaderTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
