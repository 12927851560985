import { Action } from "redux-actions";

import {
  ACTION,
  addItemInTree,
  decryptKeysInFoldersList,
  findFolderInTree,
  getFolderFromTreeById,
  removeFromTreeByID,
  typeFolder,
  typeFolderGroups,
  typeSharedFolder,
  updateFolderInTree,
  updateFolderStructure,
  updateItemInTreeByID,
} from "core";

import {
  folderChose,
  folderClear,
  folderDelete,
  folderFavorite,
  folderGet,
  folderGetAll,
  folderGetAllWithKeys,
  folderGetTree,
  folderManage,
  folderSetItemByID,
} from "store/actions";

export interface State {
  chosen: string[];
  error?: { [key: string]: string[] };
  isLoading: boolean;
  item?: typeFolder;
  list: typeFolder[];
  treeList: typeFolderGroups;
  sharedList?: typeSharedFolder[];
}

const initialState: State = {
  chosen: [],
  error: undefined,
  isLoading: false,
  item: undefined,
  list: [],
  treeList: {},
  sharedList: undefined,
};

export default function folders(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case folderDelete.TRIGGER:
    case folderGetAll.TRIGGER:
    case folderGetAllWithKeys.TRIGGER: 
    case folderGetTree.TRIGGER:
    case folderManage.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case folderClear.SUCCESS:
      return {
        ...initialState,
      };

    case folderGet.TRIGGER:
      return {
        ...state,
        item: undefined,
        isLoading: true,
      };

    case folderChose.SUCCESS: {
      let list: string[] = state.chosen;

      if (payload.oneOnly && payload.status) {
        return {
          ...state,
          chosen: [payload.id],
        };
      }

      if (!payload.isNew && state.chosen.length === 1 && state.chosen[0] === "new") {
        list.length = 0;
      }

      if (payload.status) {
        list = payload.choseAll ? [...payload.ids] : [...list, payload.id];
      } else {
        list = payload.choseAll ? [] : [...list.filter(id => payload.id !== id)];
      }

      return {
        ...state,
        chosen: payload.status && payload.isNew ? ["new"] : [...list],
      };
    }

    case folderDelete.SUCCESS:
      return {
        ...state,
        list: removeFromTreeByID(state.list, payload),
        chosen: [],
        isLoading: false,
      };

    case folderFavorite.SUCCESS: {
      const folder = getFolderFromTreeById(state.list, payload.folderID);
      folder.favorite = payload.status;

      return {
        ...state,
        list: updateItemInTreeByID(state.list, folder),
      };
    }

    case folderGet.SUCCESS:
      return {
        ...state,
        item: payload,
        isLoading: false,
      };

    case folderGetTree.SUCCESS:
      return {
        ...state,
        treeList: payload,
        isLoading: false,
      };

    case folderGetAll.SUCCESS:
    case folderGetAllWithKeys.SUCCESS:
      return {
        ...state,
        list: decryptKeysInFoldersList(payload),
        isLoading: false,
      }; 

    case folderManage.SUCCESS:
      return {
        ...state,
        list:
          payload.action === ACTION.CREATE
            ? addItemInTree(state.list, payload.response)
            : updateFolderInTree(updateFolderStructure(state.list, payload.response), payload.response),
        chosen: payload.action === ACTION.CREATE ? [payload.response.id] : [...state.chosen],
        isLoading: false,
      };

    case folderSetItemByID.SUCCESS:
      return {
        ...state,
        item: findFolderInTree(state.list, payload),
      };

    case folderChose.FAILURE:
    case folderDelete.FAILURE:
    case folderGet.FAILURE:
    case folderGetAll.FAILURE:
    case folderGetAllWithKeys.FAILURE:
    case folderGetTree.FAILURE:
    case folderManage.FAILURE:
    case folderSetItemByID.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
