/* eslint-disable no-unneeded-ternary */
import React, { FC, useMemo, useEffect, useContext } from "react";
import { Field, formValueSelector } from "redux-form";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import { FormSelect } from "components/Form";

import { typeOption, typeKey, typeFolder, typeTemplate, FIELD_TYPE, FORM_KEY_MANAGEMENT, getTemplateIcon, PRODUCT_TYPE, getTemplatesForKeys } from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { RootState } from "store/reducers";

import { FieldContext } from "./Types";

type Props = {
  getKeyIcon: (value: string) => void;
  productType: PRODUCT_TYPE;
  templates: typeTemplate[];
  folder?: typeFolder;
  keys?: typeKey;
  sapGroups: string[];
  folders: typeFolder[];
};

const fieldType = FIELD_TYPE.TEMPLATE;

const FieldTemplate: FC<Props> = ({ templates, getKeyIcon, productType, folder = undefined, sapGroups, keys, folders }) => {
  const { t } = useTranslation();
  const context = useContext(FieldContext);

  const sapTemplates = [
    "sap_rfc_template",
    "sap_login_template",
  ];

  // const templateOptions: typeOption[] = useMemo(
  //   () =>
  //     templates
  //       .filter(({ product_type }) => product_type.includes(productType))
  //       .map(({ title }: typeTemplate) => ({
  //         label: t(`key_template_${title}`),
  //         value: title,
  //       })),
  //   [templates],
  // );


  const isSap: boolean = useMemo(() => sapGroups.length > 0, [sapGroups]);
  const disableTemplate: boolean = useMemo(() => (keys && sapTemplates.includes(keys?.template) ? true : false), [keys]);

  const templateOptions: typeOption[] = useMemo(
    () => getTemplatesForKeys(templates, productType, folder, isSap, keys),
    [templates],
  );

  useEffect(() => {
    if (templateOptions.length !== 0) {
      context.handleChange(fieldType, get(context, "key.template", null) || templateOptions[0].value);
    }
  }, [templateOptions, context.key]);

  if (templateOptions.length === 0 || templates.length === 0) {
    return null;
  }

  const handleSelectValue = (value: any) => {
    getKeyIcon(getTemplateIcon(value));
    context.handleChange(fieldType, value);
  };

  return (
    <Field
      component={FormSelect}
      label={t("form_input-Type")}
      name={fieldType}
      disabled={disableTemplate}
      options={templateOptions}
      onChange={(value: any) => handleSelectValue(value)}
      template={(label: string, value: string): JSX.Element => (
        <div className={cnManageKey("Option")}>
          <Icon name={`${getTemplateIcon(value)}`} />
          &nbsp;
          {label}
        </div>
      )}
    />
  );
};

const selector = formValueSelector(FORM_KEY_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  productType: state.settings.productType,
  template: selector(state, fieldType),
  templates: state.templates.templates,
  sapGroups: state.settings.sapGroups,
  folders: state.folders.list,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FieldTemplate);
