/* eslint-disable @typescript-eslint/no-use-before-define */
import NumberFormat from "react-number-format";
import React, { FC } from "react";
import { WrappedFieldProps } from "redux-form";
import { Icon } from "ss-ui";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

type Props = {
  className?: string;
  help?: string;
  inputFormat?: string;
  label: string;
  mask?: string;
  max?: number;
  type: string;
} & WrappedFieldProps;

const FormInputMask: FC<Props> = ({
  className = "",
  help = undefined,
  input,
  inputFormat = "",
  label,
  mask = "",
  max,
  meta: { touched, error },
  type,
  ...rest
}) => {
  const withValueLimit = ({ floatValue }) => (max ? floatValue <= max : true);

  const isError = touched && Boolean(error);

  return (
    <div
      className={`${cnField()} ${cnField("Input", { hasError: isError })} ${input.value === "" ? className : className}`}
      id={`input_mask_${input.name}_container`}
    >
      {label && (
        <span className="Label" id={`input_mask_${input.name}_label`}>
          {label}
          {help && (
            <span data-tooltip={help} className="help tooltip tooltip-right tooltip-sealing">
              <Icon name="icon-info-circle" width={15} height={15} />
            </span>
          )}
        </span>
      )}
      <div className={cnField("InputWrapper")}>
        {/* <input id={`input_mask_${input.name}`} {...input} {...rest} /> */}
        <NumberFormat id={`input_mask_${input.name}`} {...input} {...rest} format={inputFormat} mask={mask} isAllowed={withValueLimit} />
      </div>
      {isError && (
        <span id={`input_mask_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default FormInputMask;
