import { FormValuesFolder as typeFolderManage } from "store/routines/folders/manage";

export const foldersForm = (data: any, isPersonal: boolean): typeFolderManage => {
  const dataToSend: typeFolderManage = {
    description: data.description,
    levelSecurity: data.level_security,
    parent: data.parent.value ? data.parent.value : null,
    title: data.title,
    webAccess: data.webAccess,
    folderGroups: isPersonal ? undefined : data.folderGroups || [],
  };

  return dataToSend;
};
