import { Action } from "redux-actions";
import { ISettingsAutoBackup, typeBackIpConfig } from "core";
import { setBackUpParams, setBackUpClear, getSettings, setAutoBackupStatus, getGlobalSettings, setGlobalSettings } from "store/actions";
import { find } from "lodash";

export interface SettingsBackupState {
  autoBackup: ISettingsAutoBackup;
  bashScript: string;
  config: typeBackIpConfig;
  error: boolean;
  isLoading: boolean;
  modal: boolean;
  notify?: boolean;
}

const initialState = {
  autoBackup: {
    show: false,
    enabled: false,
    password: undefined,
    appID: undefined,
  },
  config: {
    appId: "",
    filepath: "",
    password: "",
    url: "",
    username: "",
  },
  bashScript: "",
  error: false,
  isLoading: false,
  modal: false,
  notify: false,
};

export default function header(state: SettingsBackupState = initialState, { payload, type }: Action<any>): SettingsBackupState {
  switch (type) {
    case setAutoBackupStatus.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case setBackUpParams.TRIGGER:
      return {
        ...state,
        error: false,
        isLoading: true,
        modal: false,
      };

    case setBackUpClear.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case setBackUpParams.SUCCESS:
      return {
        ...state,
        bashScript: payload["bash-script"],
        config: payload.config,
        error: false,
        isLoading: false,
        modal: true,
      };

    case setBackUpClear.SUCCESS:
      return {
        ...state,
        isLoading: false,
        bashScript: "",
        error: false,
        modal: false,
      };

    case getSettings.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        autoBackup: {
          ...state.autoBackup,
          appID: payload.appId,
          enabled: payload.autoBackupEnabled,
          show: payload.showAutoBackupFeature,
        },
      };
    }

    case setAutoBackupStatus.SUCCESS:
      return {
        ...state,
        isLoading: false,
        autoBackup: {
          ...state.autoBackup,
          appID: payload.appId,
          enabled: payload.autoBackupEnabled,
        },
      };

    case getGlobalSettings.SUCCESS:
      return {
        ...state,
        isLoading: false,
        notify: find(payload, ["setting", "AUTO_BACKUP_FAILURE_NOTIFICATION"])?.value,
        autoBackup: {
          ...state.autoBackup,
          password: find(payload, ["setting", "AUTO_BACKUP_PASSWORD"])?.value,
        },
      };

    case setGlobalSettings.SUCCESS:
      return {
        ...state,
        notify: payload.setting === "AUTO_BACKUP_FAILURE_NOTIFICATION" ? payload.value : state.notify,
        autoBackup: payload.setting === "AUTO_BACKUP_PASSWORD" ? { ...state.autoBackup, password: payload.value } : state.autoBackup,
        isLoading: false,
      };

    case setAutoBackupStatus.FAILURE:
    case setBackUpParams.FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
        modal: false,
      };

    case setBackUpClear.FAILURE:
      return {
        ...state,
        error: false,
        isLoading: false,
        modal: true,
      };

    default:
      return state;
  }
}
