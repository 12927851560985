import { all, fork } from "redux-saga/effects";

import { saga as favoritesClear } from "store/routines/favorites/clear";
import { saga as favoritesGet } from "store/routines/favorites/get";
import { saga as favoritesGetFolders } from "store/routines/favorites/getFolders";
import { saga as favoritesGetKeys } from "store/routines/favorites/getKeys";
import { saga as favoritesSetFolder } from "store/routines/favorites/setFolder";
import { saga as favoritesSetKey } from "store/routines/favorites/setKey";

export default function* keys() {
  return yield all([
    fork(favoritesClear),
    fork(favoritesGet),
    fork(favoritesGetFolders),
    fork(favoritesGetKeys),
    fork(favoritesSetFolder),
    fork(favoritesSetKey),
  ]);
}
