import React, { FC, useEffect, useState } from "react";
import { Button, Icon } from "ss-ui";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FormInput } from "components/Form";
import { cn } from "@bem-react/classname";
import { FORM_RESET_ADMIN_PASSWORD, Validation } from "core";
import { PropsWithLoading, withLoading } from "hoc";
import { FormValues, Payload as PayloadLogin } from "store/routines/user/resetPassword";
import { RootState } from "store/reducers";
import { logout, resetAdminPassword } from "store/actions";
import { ExpireWindow } from "components/Generic";

export const cnLogin = cn("ForgotPassword");

type Props = PropsWithLoading &
  InjectedFormProps<FormValues> &
  RouteComponentProps<undefined> & {
    isLoading: boolean;
    isDone: boolean;
    isForcibly: boolean;
    errorResetPassOld: string;
    errorResetPassNew: string;
    errorResetPassGlobal: string;
    logout: () => void;
    onChange: (value: boolean) => void;
    resetAdminPassword: (payload: PayloadLogin) => void;
  };

const ResetPassword: FC<Props> = ({
  change,
  logout,
  handleSubmit,
  isLoading,
  resetAdminPassword,
  setIsLoading,
  isDone,
  isForcibly,
  errorResetPassOld,
  errorResetPassGlobal,
  errorResetPassNew,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isExpire, setIsExpire] = useState<boolean>(false);
  const [leftTime, setLeftTime] = useState<number>(5);

  useEffect(() => {
    if (isDone) {
      setIsExpire(true);
      setTimeout(() => redirectToLogin(), 5000);
    }
    setIsLoading(isLoading);

    if (isForcibly) {
      if (localStorage.getItem("passwordReset")) {
        const now = new Date();
        const storageItem = localStorage.getItem("passwordReset");
        const storageItemJson = JSON.parse(storageItem!);
        if (now.getTime() > storageItemJson.expiry) {
          onChange(true);
        } else {
          onChange(false);
        }
      } else {
        onChange(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isDone, onChange]);

  const redirectToLogin = () => {
    setLeftTime(5);
    setIsExpire(false);
    logout();
  };

  useEffect(() => {
    if (leftTime === 0) {
      setLeftTime(5);
      setIsExpire(false);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftTime]);

  const handleLogin = (data: any) => {
    resetAdminPassword({ values: data });
  };

  const handleResetPassCancel = () => {
    setWithExpiry("passwordReset", 8.64e7);
    onChange(false);
  };

  const handleModalClose = () => {
    onChange(false);
  };

  const setWithExpiry = (key: string, time: number) => {
    const now = new Date();
    const item = {
      expiry: now.getTime() + time,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  return (
    <>
      {isExpire && (
        <ExpireWindow 
          leftTime={leftTime} 
          setLeftTime={setLeftTime} 
          handleRedirect={redirectToLogin}
          theme="Done"
          contentText={t("expire-window-success")}
          contentButton={t("expire-window-button-now")}
        />
      )}
      {!isExpire && (
        <Form className={cnLogin("Form")} onSubmit={handleSubmit(handleLogin)}>
          <div className={cnLogin("FieldsContainer")}>
            <div className={cnLogin("FormHeader")}>
              <h2>{t("reset_password_form_header")}</h2>
              {!isForcibly && (
                <button onClick={handleModalClose} type="button" className={cnLogin("Close")} id="btn_handle_close_modal">
                  <Icon name="icon-close" width={14} height={14} />
                </button>
              )}
            </div>
            <div className={cnLogin("FieldGroup")} id="field_group_reset_password_old_password">
              <Field
                className={cnLogin("FieldGroupInput", { "has-error": !!errorResetPassOld || !!errorResetPassGlobal })}
                component={FormInput}
                name="oldPassword"
                placeholder={t("reset_password_form_old_password")}
                type="password"
                isShown
                label={t("reset_password_form_old_password")}
                validate={[Validation.minLength8, Validation.required]}
              />
              {errorResetPassOld && <div className={cnLogin("Error")}>{t("reset_password_form_error_password_old")}</div>}
            </div>
            <div className={cnLogin("FieldGroup")} id="field_group_reset_password_password">
              <Field
                className={cnLogin("FieldGroupInput", { "has-error": !!errorResetPassNew || !!errorResetPassGlobal })}
                component={FormInput}
                name="password"
                placeholder={t("reset_password_form_new_password")}
                type="password"
                isShown
                label={t("reset_password_form_new_password")}
                validate={[Validation.oldPassword, Validation.required]}
              />
              {errorResetPassNew && <div className={cnLogin("Error")}>{t("reset_password_form_error_password_new")}</div>}
            </div>
            <div className={cnLogin("FieldGroup")} id="field_group_reset_password_repeat_password">
              <Field
                className={cnLogin("FieldGroupInput")}
                component={FormInput}
                name="repeatPassword"
                isShown
                label={t("reset_password_form_repeat_password")}
                placeholder={t("reset_password_form_repeat_password")}
                type="password"
                validate={[Validation.passwordsMatch, Validation.required]}
              />
            </div>
            {errorResetPassGlobal && <div className={cnLogin("Error")}>{t(errorResetPassGlobal)}</div>}
          </div>

          <div className={cnLogin("ButtonContainer")}>
            <Button id="button_reset_password_form_button_confirm" type="submit">
              {t("reset_password_form_button_confirm")}
            </Button>
            <Button
              id="button_reset_password_form_button_cancel"
              onClick={isForcibly ? handleResetPassCancel : handleModalClose}
              theme="secondary-outline"
            >
              {t("reset_password_form_button_cancel")}
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

const mapStateToProps = ({ user: { isLoading, isDone, errorResetPassOld, errorResetPassNew, errorResetPassGlobal } }: RootState) => ({
  isLoading,
  errorResetPassOld,
  errorResetPassNew,
  errorResetPassGlobal,
  logout,
  isDone,
});

export default compose<Props, Partial<Props>>(
  withLoading,
  withRouter,
  connect(mapStateToProps, { resetAdminPassword, logout }),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_RESET_ADMIN_PASSWORD,
  }),
)(ResetPassword);
