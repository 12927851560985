/* eslint-disable @typescript-eslint/quotes */
import i18n from "locales/i18n";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, has, snakeCase } from "lodash";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  additionalLoginData,
  clearUrlParam,
  getDataFromLS,
  getHash,
  http,
  LOCAL_STORAGE,
  ProtectedRoutes,
  removeDataFromLS,
  typeLoginResponse,
  externalPages,
} from "core";

// import packageJson from "../../../../package.json";

export interface FormValues {
  otp?: string;
  password: string;
  save_account: boolean;
  username: string;
}

export type Payload = FormValues | { values: FormValues };

export const action: Routine = createRoutine("LOGIN");

const login = (rest: FormValues) => {
  const data = {
    ...rest,
    ...additionalLoginData(rest.otp),
  };
  return http({
    method: "POST",
    payload: data,
    route: "oauth/token/create",
    isProtected: false,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const rest = "values" in payload ? payload.values : payload;
    const response: typeLoginResponse = yield call(login, rest);

    const windowUrl = decodeURIComponent(window.location.hash.slice(1));
    const urlList = windowUrl.split(/[\s#]+/);

    if (urlList[0] === "unsubscribe") {
      yield put(action.success({ ...rest, ...response, isMicrosoft: false, isUnsubscribe: true, unsubscribeToken: urlList[1] }));
    } else {
      yield put(action.success({ ...rest, ...response, isMicrosoft: false, isUnsubscribe: false }));
    }

    const prevURL = getDataFromLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL);
    const copyURL = getDataFromLS(LOCAL_STORAGE.SEND_URL);
    yield removeDataFromLS(LOCAL_STORAGE.PREVIOUS_SESSION_URL);

    if (response.locale) {
      i18n.changeLanguage(response.locale);
    }

    if (copyURL && externalPages.filter(route => copyURL.send_url.includes(route)).length === 0) {
      window.location = copyURL.send_url;
    } else if (prevURL && getHash(response.username) === prevURL.username && prevURL.url.split("/").includes("login") !== true) {
      window.location = prevURL.url;
    } else {
      yield put(push(clearUrlParam(ProtectedRoutes.Keys)));
    }
    yield removeDataFromLS(LOCAL_STORAGE.SEND_URL);
  } catch (error) {
    if (get(error, "response.data.error", false) === "yubikey_required" && has(error, "response.data.authenticate_options")) {
      yield put(action.failure(get(error, "response.data")));
    } else if (
      has(error, "response.data.error_description") ||
      i18n.exists(`error_${snakeCase(get(error, "response.data.error_description"))}`)
    ) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.error_description"))}`));
    } else if (i18n.exists(`error_${snakeCase(get(error, "response.data.detail.messages[0]"))}`)) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.detail.messages[0]"))}`));
    } else if (get(error, "response.data.detail")) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.detail"))}`));
    } else {
      yield put(action.failure("error_connection"));
    }
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
