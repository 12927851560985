/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";

export const cnWithLoading = cn("WithLoading");

export type PropsWithLoading = {
  setIsLoading: (status: boolean) => void;
};

const WithLoading = <P extends {}>(Component: React.ComponentType<P>): FC<P> => {
  const WithLoadingComponent = ({ ...props }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
      <div className={cnWithLoading()}>
        {isLoading && (
          <div className={cnWithLoading("Background")}>
            <div className={cnWithLoading("Animate")}>
              <Icon name="icon-lock-loading" />
            </div>
          </div>
        )}
        <Component {...(props as P)} setIsLoading={setIsLoading} />
      </div>
    );
  };

  return WithLoadingComponent;
};

export default WithLoading;
