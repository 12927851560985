import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";
import { toast } from "react-toastify";

import { http } from "core";

import { keyFavorite } from "store/actions";

import i18n from "locales/i18n";

export type Payload = {
  keyID: string;
  folderID: string;
};

export const action: Routine = createRoutine("FAVORITE_SET_KEY");

const get = ({ folderID, keyID }: Payload) => {
  return http({ route: `user/key/${keyID}/folder/${folderID}/favorite` });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    toast.success(i18n.t(`toast_key_success_${response.favorite ? "set" : "unset"}_favorite`));

    yield put(action.success({ keyID: payload.keyID, status: response.favorite }));
    yield put(keyFavorite.trigger({ keyID: payload.keyID, status: response.favorite }));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
