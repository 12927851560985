/* eslint-disable @typescript-eslint/quotes */
import { Action } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { push } from "connected-react-router";

import { get, snakeCase } from "lodash";
import { http, MainRoutes } from "core";
import { toast } from "react-toastify";
import i18n from "locales/i18n";


export interface FormValues {
  hash: string;
  otp: string;
  username: string;
}

export const action: Routine = createRoutine("CONFIRM_OTP_ACTIVATION");

const confirmOTPActivation = (data: FormValues) => {
  return http({
    isProtected: false,
    method: "POST",
    payload: data,
    route: "confirm-otp-activation",
  });
};

function* handler({ payload }: Action<FormValues>) {
  try {
    const response: string = yield call(confirmOTPActivation, payload);

    toast.success(i18n.t("toast_enabled_otp_success"));
    yield put(push(MainRoutes.Login));
    yield put(action.success(response));
  } catch (error) {
    const errorMessage = get(error, "response.data.detail.messages[0]", undefined);
    if (i18n.exists(`error_${snakeCase(errorMessage)}`)) {
      yield put(action.failure(`error_${snakeCase(errorMessage)}`));
    } else {
      yield put(action.failure("error_connection"));
    }
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
