/* eslint-disable import/no-mutable-exports */
import { PRODUCT_TYPE, ROLE_TYPE, typeRoute } from "core/types";

export const rolesAccessAdmin = [ROLE_TYPE.ADMIN];
export const rolesAccessAdminAndFree = [ROLE_TYPE.ADMIN, ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER];
export const rolesAccessAll = [ROLE_TYPE.USER, ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER, ROLE_TYPE.MANAGER, ROLE_TYPE.ADMIN];
export const rolesAccessEnterprise = [ROLE_TYPE.USER, ROLE_TYPE.MANAGER, ROLE_TYPE.ADMIN];
export const rolesAccessManagers = [ROLE_TYPE.MANAGER, ROLE_TYPE.ADMIN];
export const rolesAccessPaid = [ROLE_TYPE.USER, ROLE_TYPE.STANDARD_USER, ROLE_TYPE.MANAGER, ROLE_TYPE.ADMIN];

export const productsAll = [PRODUCT_TYPE.STANDARD, PRODUCT_TYPE.CORPORATE, PRODUCT_TYPE.ENTERPRISE];
export const productsCorporate = [PRODUCT_TYPE.CORPORATE];
export const productsEnterprise = [PRODUCT_TYPE.CORPORATE, PRODUCT_TYPE.ENTERPRISE];

export const MainRoutes = {
  Home: "/",
  Login: "/login",
  ForgotPassword: "/forgot-password",
  InstallPassword: "/confirmation/password",
  GettingQRCode: "/getting-qr-code/:hash?",
  VpnConnection: "/vpn-connection",
};

export const externalPages: string[] = [
  MainRoutes.Login,
  MainRoutes.ForgotPassword,
  MainRoutes.InstallPassword,
  MainRoutes.VpnConnection,
  MainRoutes.GettingQRCode,
];

export const ProtectedRoutes = {
  Keys: "/explorer/:folderID?",
  ManageKey: "/explorer/:folderID?/key/:id?",

  ActivityLog: "/activity-log",
  Favorites: "/favorites",
  FolderManagement: "/folder-management",
  Groups: "/groups",
  History: "/history",
  ManageGroup: "/groups/:id?",
  ManageUser: "/users/:id?",
  Profile: "/profile",
  Search: "/search",
  Users: "/users",
  Customize: "/customize",

  settings: {
    Conveyance: "/settings/conveyance",
    Customize: "/settings/customize",
    General: "/settings/general",
    Security: "/settings/security",
    TokenManager: "/settings/token-manager",
  },
};

export const Routes = {
  ...MainRoutes,
  ...ProtectedRoutes,
};

export const UsersNavigation: typeRoute[] = [
  {
    icon: "icon-user",
    route: Routes.Users,
    title: "Users",
    roleAccess: rolesAccessManagers,
    productTypeAccess: productsEnterprise,
  },
  {
    icon: "icon-group",
    route: Routes.Groups,
    title: "Groups",
    roleAccess: rolesAccessManagers,
    productTypeAccess: productsEnterprise,
  },
];

export const HistoryNavigation: typeRoute[] = [
  {
    icon: "icon-history",
    route: Routes.History,
    title: "History",
    roleAccess: rolesAccessPaid,
    productTypeAccess: productsAll,
  },
  {
    icon: "icon-file-with-gear",
    route: Routes.ActivityLog,
    title: "Activity Log",
    roleAccess: rolesAccessManagers,
    productTypeAccess: productsEnterprise,
  },
];

export const SettingsNavigation: typeRoute[] = [
  {
    icon: "icon-setting",
    route: Routes.settings.General,
    title: "General",
    roleAccess: rolesAccessAll,
    productTypeAccess: productsAll,
  },
  {
    icon: "icon-shield",
    route: Routes.settings.Security,
    title: "Security",
    roleAccess: rolesAccessAdminAndFree,
    productTypeAccess: productsAll,
  },
  {
    icon: "icon-user-settings",
    route: Routes.settings.TokenManager,
    title: "TokenManager",
    roleAccess: rolesAccessAdmin,
    productTypeAccess: productsCorporate,
  },
  {
    icon: "icon-conveyance",
    route: Routes.settings.Conveyance,
    title: (role: ROLE_TYPE) => `Import and ${role === ROLE_TYPE.ADMIN ? "Backup" : "Export"}`,
    roleAccess: rolesAccessAll,
    productTypeAccess: productsAll,
  },
  {
    icon: "icon-customize",
    route: Routes.settings.Customize,
    title: "Customize",
    roleAccess: rolesAccessAdmin,
    productTypeAccess: productsEnterprise,
  },
];

export const AsideNavigation: typeRoute[] = [
  {
    icon: "icon-folder",
    route: Routes.FolderManagement,
    title: "Folder management",
    roleAccess: rolesAccessManagers,
    productTypeAccess: productsEnterprise,
  },
  {
    icon: "icon-group",
    route: Routes.Users,
    title: "Users & Groups",
    roleAccess: rolesAccessManagers,
    productTypeAccess: productsEnterprise,
  },
  {
    icon: "icon-user",
    route: Routes.Profile,
    title: "Profile",
    roleAccess: rolesAccessAll,
    productTypeAccess: productsAll,
  },
  {
    icon: "icon-setting",
    route: Routes.settings.General,
    title: "Settings",
    roleAccess: rolesAccessAll,
    productTypeAccess: productsAll,
  },
  {
    icon: "icon-history",
    route: Routes.History,
    title: "History",
    roleAccess: rolesAccessPaid,
    productTypeAccess: productsAll,
  },
];
