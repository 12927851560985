import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

export const action: Routine = createRoutine("USER_GET_AVATAR");

const getAvatar = () => {
  return http({ route: "user/avatar", responseType: "arraybuffer" });
};

function* handler() {
  try {
    const response = yield call(getAvatar);
    const base64 = Buffer.from(response, "binary").toString("base64");
    yield put(action.success(base64 ? `data:image/jpeg;base64,${base64}` : null));
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
