import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Aside, cnAsideContent, Content, ContentWrapper, ContentHeader, cnContentHeader } from "components/Layout";
import { FolderFilter } from "components/InfoBox/FolderFilter";
import { InfoBoxFolder } from "components/InfoBox/Folder";
import { LoadingContainer } from "components/Generic";
import { typeFolderFilter } from "components/InfoBox/FolderFilter/FolderFilterTypes";

import { FOLDER_TYPE, getExpandFoldersList, PayloadChoseFunction, typeFolder, ROLE_TYPE, http } from "core";

import { folderChose, setHeaderIcon, setHeaderTitle, setOpenedFolder, folderGetAll } from "store/actions";
import { RootState } from "store/reducers";

import { FolderList } from "./fragments/folderList";
import FolderFilterList from "./FolderFilterList";

const cnFolderManagement = cn("FolderManagement");

type Props = {
  chosen: string[];
  folderChose: (payload: PayloadChoseFunction) => void;
  folderGetAll: () => void;
  isLoading: boolean;
  list: typeFolder[];
  role: ROLE_TYPE;
  setHeaderIcon: (payload: string | undefined) => void;
  setHeaderTitle: (payload: string | undefined) => void;
  setOpenedFolder: (payload: string) => void;
};

type typeFolderAndKeyCount = {
  folderCount: number;
  keyCount: number;
};

function getFoldersEndKeyCount(type: FOLDER_TYPE, list: typeFolder[]): typeFolderAndKeyCount {
  const filterFolders = list.filter(item => item.type === type);
  const folderKeysFilter = list.filter((e: typeFolder) => e.type === type && e.root === null).map((el: typeFolder) => el.countKeys);
  const filterFoldersKeys = folderKeysFilter.reduce((prevValue, currentValue) => prevValue + currentValue, 0);
  return { folderCount: filterFolders.length, keyCount: filterFoldersKeys };
}

const FolderManagement: FC<Props> = ({
  chosen,
  folderChose,
  folderGetAll,
  isLoading,
  list,
  role,
  setHeaderIcon,
  setHeaderTitle,
  setOpenedFolder,
}) => {
  const { t } = useTranslation();

  const [groupKeysCount, setGroupKeysCount] = useState<number>(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<typeFolderFilter>({
    isFiltering: false,
    write: false,
    read: false,
    no_access: false,
    web_access: false,
    sealing: false,
    groups: [],
    users: [],
  });

  const isAdmin: boolean = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);
  const expandFoldersList: typeFolder[] = useMemo(() => getExpandFoldersList(list), [list]);
  const personalLength: typeFolderAndKeyCount = useMemo(
    () => getFoldersEndKeyCount(FOLDER_TYPE.PERSONAL, expandFoldersList),
    [expandFoldersList],
  );
  const groupLength: typeFolderAndKeyCount = useMemo(
    () => getFoldersEndKeyCount(FOLDER_TYPE.GROUP, expandFoldersList),
    [expandFoldersList, role],
  );

  useEffect(() => {
    setOpenedFolder("");

    // Request to a server to get count a keys in a groups folders, only for an admin user type
    if (isAdmin) {
      (http({ route: "key/count" }) as Promise<{ group: number }>).then(({ group }) => {
        setGroupKeysCount(group);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chosen.length > 0) {
      setShowFilters(false);
    }
  }, [chosen]);

  useEffect(() => {
    folderGetAll();
  }, [folderGetAll]);

  useEffect(() => {
    setHeaderTitle(t("navigation_bottom-Folder management"));
    setHeaderIcon("folder");
  }, [setHeaderTitle, t, setHeaderIcon]);

  const handleAddFolder = () => {
    folderChose({
      isNew: true,
      status: true,
    });
  };

  const handleSubmitFilter = (formData: typeFolderFilter) => {
    setFilters({ ...filters, ...formData });
  };

  const handleResetFilter = () => {
    setFilters({ isFiltering: false });
  };

  return (
    <ContentWrapper id="folder_manager_content_wrapper">
      <Content id="folder_manager_content">
        <ContentHeader id="keys_content_header">
          <div className={cnContentHeader("CleanFull")} />
          <Button
            id="button_add_user"
            className={cnContentHeader("Margin")}
            onClick={() => handleAddFolder()}
            icon="icon-folder-add"
            theme="primary-outline"
            size={30}
            isActive={chosen.length > 0 && chosen[0] === "new"}
          />
          <Button
            id="btn_show_filter_2"
            onClick={() => setShowFilters(!showFilters)}
            icon="icon-filter"
            theme="primary-outline"
            size={30}
            isActive={showFilters}
          />
        </ContentHeader>

        <LoadingContainer isLoading={isLoading}>
          <div className={cnFolderManagement()}>
            <div className={cnFolderManagement("Header")}>
              <div className={cnFolderManagement("Header-Title")}>{t("table_title-Title")}</div>
              <div className={cnFolderManagement("Header-Description")}>{t("table_title-Descriptions")}</div>
              <div className={cnFolderManagement("Header-Date")}>{t("table_title-Created-date")}</div>
              <div className={cnFolderManagement("Header-Keys")}>{t("table_title-Keys")}</div>
            </div>
            {filters.isFiltering ? <FolderFilterList filters={filters} folders={list} /> : <FolderList />}
          </div>
        </LoadingContainer>
      </Content>
      <Aside side="right" id="folder_manager_aside_right">
        {showFilters && (
          <FolderFilter handleReset={handleResetFilter} handleSubmitFilter={handleSubmitFilter} handleClose={() => setShowFilters(false)} />
        )}
        {chosen.length > 0 && !showFilters && <InfoBoxFolder />}

        {chosen.length === 0 && !showFilters && (
          <div className={cnAsideContent()}>
            <div className={cnAsideContent("Header")}>
              <h2>{t("aside_right-Information")}</h2>
            </div>

            <div className={cnAsideContent("Description")}>
              <p>{t("aside_right_text-Folders")}</p>
            </div>

            <div className={cnAsideContent("Total")}>
              <p>{t("form_input-Asses")}:</p>
              <ul className={cnAsideContent("Total-List")}>
                <li>
                  <Icon name="colored/icon-folder-access-write" width={20} />
                  {t("groups-write")}
                </li>
                <li>
                  <Icon name="colored/icon-folder-access-read" width={20} />
                  {t("groups-read")}
                </li>
                <li>
                  <Icon name="colored/icon-folder-access-no_access" width={20} />
                  {t("groups-no_access")}
                </li>
              </ul>
            </div>

            <div className={cnAsideContent("Total")}>
              <p>{t("form_input-LevelSecurity")}:</p>
              <ul className={cnAsideContent("Total-List", ["AsideContent-Total-Dot"])}>
                <li>
                  <Icon name="icon-dot-1" width={26} />
                  {t("security-level-1")}
                </li>
                <li>
                  <Icon name="icon-dot-2" width={26} />
                  {t("security-level-2")}
                </li>
                <li>
                  <Icon name="icon-dot-3" width={26} />
                  {t("security-level-3")}
                </li>
                <li>
                  <Icon name="icon-dot-4" width={26} />
                  {t("security-level-4")}
                </li>
              </ul>
            </div>

            <div className={cnAsideContent("Total")}>
              <div className={cnAsideContent("Total-Count")}>
                <span>{groupLength.folderCount}</span>
                <p>{t("folders-groups")}</p>
              </div>
              <div className={cnAsideContent("Total-Count")}>
                <span>{isAdmin ? groupKeysCount : groupLength.keyCount}</span>
                <p>{t("keys-groups")}</p>
              </div>
              <div className={cnAsideContent("Total-Count")}>
                <span>{personalLength.folderCount}</span>
                <p>{t("folders-personal")}</p>
              </div>
              <div className={cnAsideContent("Total-Count")}>
                <span>{personalLength.keyCount}</span>
                <p>{t("keys-personal")}</p>
              </div>
            </div>

            <div className={cnAsideContent("Action")}>
              <Button icon="icon-folder-add" id="button_folders_action_add" onClick={() => handleAddFolder()}>
                {t("button_add-Folder")}
              </Button>
            </div>
          </div>
        )}
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({ folders: { list, chosen, isLoading }, user: { role } }: RootState) => ({
  chosen,
  isLoading,
  list,
  role,
});

const mapDispatchToProps = { setHeaderTitle, setHeaderIcon, folderChose, setOpenedFolder, folderGetAll };

export default connect(mapStateToProps, mapDispatchToProps)(FolderManagement);
