import { all, fork } from "redux-saga/effects";

import { saga as historyClear } from "store/routines/histories/clear";
import { saga as historyGet } from "store/routines/histories/get";
import { saga as historyRemove } from "store/routines/histories/remove";
import { saga as historyRestoreFolder } from "store/routines/histories/restoreFolder";
import { saga as historyRestoreKey } from "store/routines/histories/restoreKey";
import { saga as historyStatus } from "store/routines/histories/status";
import { saga as historyStatistic } from "store/routines/histories/statistic";

export default function* folders() {
  return yield all([
    fork(historyClear),
    fork(historyGet),
    fork(historyRemove),
    fork(historyRestoreFolder),
    fork(historyRestoreKey),
    fork(historyStatus),
    fork(historyStatistic),
  ]);
}
