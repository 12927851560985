import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { Action } from "redux-actions";

import { http } from "core";

import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("SETTINGS_SET_OAUTH_LIFE_TIME");

export interface Payload {
  access_token: number;
  refresh_token: number;
}

const setOAuthLifeTime = ({ access_token, refresh_token }: Payload) => {
  return http({
    route: "oauth/life-time",
    method: "POST",
    payload: { access_token, refresh_token },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setOAuthLifeTime, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
