/* eslint-disable jsx-a11y/click-events-have-key-events, no-nested-ternary, jsx-a11y/no-static-element-interactions */

import React, { FC, useMemo, useEffect } from "react";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { reduxForm, InjectedFormProps, WrappedFieldArrayProps, Field, formValueSelector } from "redux-form";
import { Button } from "ss-ui";
import { FormInput, FormCheckbox } from "components/Form";
import { toast } from "react-toastify";

import { FORM_LOCAL_USER_MANAGEMENT, typeUser, getUserFromListByID, localUserForm, ACTION, Routes, Validation } from "core";
import { validationsFormLocalUser } from "core/helpers/validation";
import { Trans, useTranslation } from "react-i18next";

import { FormValues, Payload as PayloadUsersManagementLocal } from "store/routines/users/manageLocal";

import { Payload as PayloadUsersControlBlock } from "store/routines/users/controlBlock";
import { Payload as PayloadUsersControlOTP } from "store/routines/users/controlOTP";
import { usersManagementLocal, usersControlBlock, usersControlOTP } from "store/actions";
import { RootState } from "store/reducers";

export const cnSecurity = cn("InfoBoxUserSecurity");

// const optionNever: typeOption = {
//   label: "Never",
//   value: 0,
// };

type Props = {
  chosen: string[];
  formData: FormValues;
  isLoading: boolean;
  list: typeUser[];
  globalOtp?: boolean;
  setActiveTab: (index: number) => void;
  usersControlBlock: (payload: PayloadUsersControlBlock) => void;
  usersControlOTP: (payload: PayloadUsersControlOTP) => void;
  usersManagementLocal: (payload: PayloadUsersManagementLocal) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<FormValues>;

const Security: FC<Props> = (
  {
    change,
    chosen,
    formData,
    handleSubmit,
    isLoading,
    list,
    globalOtp,
    setActiveTab,
    usersControlBlock,
    usersControlOTP,
    usersManagementLocal,
  }) => {
  const { t } = useTranslation();

  const user: typeUser = useMemo(() => getUserFromListByID(list, chosen[0]), [list, chosen]);
  const isNew: boolean = useMemo(() => chosen[0] === "new", [chosen]);

  useEffect(() => {
    change("password_expiration", isNew ? formData.password_expiration || 0 : user.password_expiration);
    change("otp", isNew ? formData.otp || false : user.otp);
    change("blocked", isNew ? formData.blocked || false : user.blocked);
    change("password", formData.password || "");
    change("himself_password", isNew ? formData.himself_password || false : user.himself_password);
    change("repeatPassword", formData.repeatPassword || "");
  }, [chosen]);

  useEffect(() => {
    change("otp", isNew ? formData.otp || false : user.otp);
  }, [user]);

  const handleForm = (values, dispatch, props) => {
    /**
     * if exist error in another forms, open it
     */
    if (Object.keys(props.syncErrors).length > 0) {
      setActiveTab(1);
    } else {
      usersManagementLocal({
        id: isNew ? undefined : user.id,
        action: isNew ? ACTION.CREATE : ACTION.UPDATE,
        payload: localUserForm(values, user),
      });
    }
  };

  const handleBlock = (status: boolean) => {
    usersControlBlock({ id: user.id, action: status ? "disable" : "enable" });
  };

  const handleOTP = (actionsName: string) => {
    usersControlOTP({ id: user.id, action: actionsName });
  };

  const handleOTPDisable = () => {
    toast.info(
      <Trans i18nKey="toast_global_otp_info">
        <a href={ Routes.settings.Security }> </a>
      </Trans>,
      { toastId: "global_otp_error" },
    );
  };

  // const handleSend2FAToken = () => {
  //   toast.info("Handle Send 2FA Token");
  // };
  // const handleReset2FAToken = () => {
  //   toast.info("Handle Reset 2FA Token");
  // };
  // const handleResetPassword = () => {
  //   toast.info("Handle Reset Password");
  // };

  return (
    <>
      <form id="submit-security-form" className={cnSecurity()} onSubmit={handleSubmit(handleForm)}>
        <Field
          component={FormCheckbox}
          label={t("user_infobox_password_himself")}
          name="himself_password"
          theme="switch"
          help={t("user_infobox_password_himself_help")}
          className={cnSecurity("Switch")}
        />

        {!formData.himself_password && (
          <>
            <Field
              component={FormInput}
              label={t("form_input-Enter-Password")}
              name="password"
              isShown={true}
              validate={[Validation.minLength8, Validation.emoji, Validation.notSpace]}
            />
            <Field
              className={`${cnSecurity("NotError")}`}
              component={FormInput}
              label={t("forgotPassword_field_repeat_password")}
              name="repeatPassword"
              isShown={true}
            />
          </>
        )}

        {/* <Field component={FormSelect} label="Password expiration" name="password_expiration" options={[optionNever, ...FormatOptionsResetPass]} defaultSelect={optionNever} placeholder="Password expiration" /> */}
        {user && (
          <>
            <div onClick={globalOtp ? handleOTPDisable : undefined}>
              <Field
                component={FormCheckbox}
                className={cnSecurity("Switch")}
                name="otp"
                disabled={globalOtp}
                label={t("user_infobox_activate_otp")}
                theme="switch"
                onChange={(value: any) => handleOTP(value ? "enable" : "disable")}
                help={t("user_infobox_activate_otp_help")}
              />
            </div>
            <Field
              component={FormCheckbox}
              className={cnSecurity("Switch")}
              name="blocked"
              label={t("user_infobox_block-user")}
              theme="switch"
              onChange={(value: any) => handleBlock(value as boolean)}
              help={t("user_infobox_block-user_help")}
              />
          </>
        )}

      </form>
      <div className={cnSecurity("Actions")}>
        {!isNew && (
          <Button width="100%"
                  onClick={() => handleOTP("reset")}
                  theme="danger-outline"
                  disabled={!user.otp && !globalOtp}
          >
            {t("button-reset-otp")}
          </Button>
        )}
        {/*   <Button onClick={() => handleResetPassword()} theme="outline-red">Reset password</Button> */}
        {/*   <Button onClick={() => handleSend2FAToken()} theme="outline">Send 2FA token</Button> */}
      </div>
    </>
  );
};

const selector = formValueSelector(FORM_LOCAL_USER_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  isLoading: state.users.isLoading,
  chosen: state.users.chosen,
  list: state.users.list,
  globalOtp: state.settings.globalOtp,
  formData: selector(state, "password_expiration", "otp", "password", "himself_password", "repeatPassword"),
});

const mapDispatchToProps = { usersManagementLocal, usersControlBlock, usersControlOTP };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_LOCAL_USER_MANAGEMENT,
    destroyOnUnmount: false,
    validate: validationsFormLocalUser,
  }),
)(Security);
