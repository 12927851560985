/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect } from "react";
import moment from "moment";
import { Checkbox, Icon } from "ss-ui";
import { Link } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { isSafari } from "react-device-detect";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

import { ButtonDiv, LoadingContainer, KeysActions } from "components/Generic";

import {
  DATETIME_FORMAT_LITE,
  FOLDER_ACCESS,
  FOLDER_TYPE,
  PayloadChoseFunction,
  ROLE_TYPE,
  Routes,
  clearUrlParam,
  getKey,
  getKeyAnnotation,
  getKeyFromArrayByID,
  getTemplateIcon,
  parseDate,
  replaceUrlParam,
  typeFolder,
  typeKey,
  validateURL,
} from "core";

import { PropsWithChosen, WithChosen } from "hoc";

import { Payload as PayloadFavoritesSetKey } from "store/routines/favorites/setKey";
import { RootState } from "store/reducers";
import { favoritesSetKey, keyChose } from "store/actions";

export const cnKeysBlock = cn("KeysBlock");
export const cnTable = cn("Table");

interface iParams {
  folderID: string;
}

type Props = PropsWithChosen & {
  chosen: string[];
  favoritesSetKey: (payload: PayloadFavoritesSetKey) => void;
  folder?: typeFolder;
  isLoading: boolean;
  isLoadingFavorites: boolean;
  keyChose: (payload: PayloadChoseFunction) => void;
  list: typeKey[];
  role: ROLE_TYPE;
};

const KeysBlock: FC<Props> = ({
  chosen,
  favoritesSetKey,
  folder,
  handleChosenAllItems,
  handleUpdateChosenItems,
  isLoading,
  isLoadingFavorites,
  keyChose,
  list,
  role,
}) => {
  useEffect(() => {
    handleChosenAllItems(keyChose, false, []);
  }, []);

  const { t } = useTranslation();
  const { folderID } = useParams<iParams>();

  const handleFavorite = (folderID: string, keyID: string) => {
    favoritesSetKey({
      folderID,
      keyID,
    });
  };

  // TODO: some function in keys table template, need refactor this
  /**
   * Check item by id, and verify
   * @param {string} id - key id
   * @param {boolean} status - check or uncheck
   * @param {boolean} isSharing - is key share to this folder
   */
  const handleChosenItems = ({ id, status, isSharing }) => {
    if (isSharing) {
      return handleUpdateChosenItems(keyChose, status, id, true);
    }

    // if chosen keys not empty, remove sharing key from chosen list
    if (chosen.length !== 0) {
      chosen.forEach(keyID => {
        const { id, mainFolderId } = getKeyFromArrayByID(list, keyID) as typeKey;
        if (folderID !== mainFolderId) {
          toast.info(t("toast_info_check_only_own_key"), { toastId: "CHECK_INFO_ONLY_OWN_KEY" });
          handleUpdateChosenItems(keyChose, false, id);
        }
      });
    }

    return handleUpdateChosenItems(keyChose, status, id);
  };

  return (
    <div className={cnKeysBlock("Container")}>
      {folder && (
        <div className={cnKeysBlock()}>
          <LoadingContainer isLoading={isLoading || isLoadingFavorites}>
            {folder.access === FOLDER_ACCESS.NO_ACCESS || (role === ROLE_TYPE.ADMIN && folder.type === FOLDER_TYPE.GROUP) ? (
              <>
                {role === ROLE_TYPE.ADMIN ? (
                  <div className={cnTable("Empty")}>
                    <Icon name="icon-lock-close" fill="#A7A7A8" />
                    <p>{t("key-page-access-admin")}</p>
                  </div>
                ) : (
                  <div className={cnTable("Empty")}>
                    <Icon name="icon-lock-close" fill="#A7A7A8" />
                    <p>{t("key-page-access")}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                {list.length > 0 &&
                  list.map(
                    (
                      {
                        qr_barcode,
                        encryptionEnabled,
                        favorite,
                        folders,
                        id,
                        // login,
                        logo,
                        mainFolderId,
                        // password,
                        sealing,
                        template,
                        title,
                        updatedAt,
                        uri,
                        data,
                      }: typeKey,
                      index,
                    ) => {
                      let correctUrl: string = "";

                      const { card_pin, login, password, card_number, client_number, doc_number, serial_number, hostname_ip } = JSON.parse(data);

                      if (uri) {
                        correctUrl = uri.includes("//") ? uri : `http://${uri}`;
                      }

                      const isKeySharing = folderID !== mainFolderId;

                      return (
                        <ButtonDiv
                          onClick={() => handleUpdateChosenItems(keyChose, !(chosen.indexOf(id) >= 0), id, true)}
                          key={id}
                          id={getKey("btn_chosen_key", index)}
                          className={cnKeysBlock("Item", { chosen: chosen.indexOf(id) >= 0 })}
                        >
                          <div className={cnKeysBlock("Item-Image")}>
                            {logo ? (
                              <img alt="" className="key-logo" src={logo} />
                            ) : (
                              <p className={cnKeysBlock("Item-Image-Text")}>{getKeyAnnotation(title)}</p>
                            )}
                            <div className={cnKeysBlock("Item-Image-Checkbox")}>
                              <ButtonDiv
                                onClick={e => e.stopPropagation()}
                                className={cnKeysBlock("Checkbox Table-Th")}
                                id={getKey("btn_chosen_key_checkbox", index)}
                              >
                                <Checkbox
                                  checked={chosen.indexOf(id) >= 0}
                                  onChange={(status: boolean) => handleChosenItems({ id, status, isSharing: isKeySharing })}
                                />
                              </ButtonDiv>
                            </div>
                            <div className={cnKeysBlock("Item-Image-Icon")}>
                              <Icon name={getTemplateIcon(template)} width={16} fill="#ffffff" />
                            </div>
                            <div className={cnKeysBlock("Item-Image-Actions")}>
                              <ButtonDiv
                                id={getKey("btn_key_stop_propagation", index)}
                                onClick={e => e.stopPropagation()}
                                className={cnKeysBlock("Item-Image-Actions-Box")}
                              >
                                
                                {folder && (folder.access !== FOLDER_ACCESS.READ && !isKeySharing) && (
                                  <div className="Actions-Item">
                                    <Link
                                      to={replaceUrlParam(replaceUrlParam(Routes.ManageKey, "id", id), "folderID", folder.id)}
                                      id={getKey(`link_to_edit_key_${title}`)}
                                      data-tooltip={t("tooltip-edit")}
                                      className="tooltip tooltip-right"
                                    >
                                      <Icon name="icon-pencil" width={16} height={16} />
                                    </Link>
                                  </div>
                                )}
                                {correctUrl ? (
                                  <a
                                    href={validateURL(correctUrl)}
                                    target="_blank"
                                    className="Actions-Item tooltip tooltip-right"
                                    data-tooltip={t("tooltip-show")}
                                  >
                                    <Icon name="icon-open" width={16} height={16} />
                                  </a>
                                ): null}

                                {/* {qr_barcode && (
                                  <KeysActionsImage
                                    value={qr_barcode}
                                    tooltipName="keys_copy_card_pin"
                                  />
                                )} */}

                                {!isSafari && card_pin && (
                                  <KeysActions
                                    value={card_pin}
                                    encryptionEnabled={encryptionEnabled}
                                    iconName="icon-copy"
                                    tooltipName="keys_copy_card_pin"
                                    toastName="toast_card_pin_success"
                                  />
                                )}

                                {!isSafari && hostname_ip && (
                                  <KeysActions
                                    value={hostname_ip}
                                    encryptionEnabled={encryptionEnabled}
                                    iconName="icon-copy"
                                    tooltipName="keys_copy_hostname_ip"
                                    toastName="toast_hostname_ip_success"
                                  />
                                )}

                                {!isSafari && login && (
                                  <KeysActions
                                    value={login}
                                    encryptionEnabled={encryptionEnabled}
                                    iconName="icon-copy-login"
                                    tooltipName="tooltip-login"
                                    toastName="toast_login_copy"
                                  />
                                )}

                                {!isSafari && password && (
                                  <KeysActions
                                    value={password}
                                    encryptionEnabled={encryptionEnabled}
                                    iconName="icon-copy-pass"
                                    tooltipName="tooltip-pass"
                                    toastName="toast_password_copy"
                                  />
                                )}

                                {!isSafari && card_number && (
                                  <KeysActions
                                    value={card_number}
                                    encryptionEnabled={encryptionEnabled}
                                    tooltipName="keys_copy_card_number"
                                    toastName="toast_card_number_success"
                                  />
                                )}

                                {!isSafari && client_number && (
                                  <KeysActions
                                    value={client_number}
                                    encryptionEnabled={encryptionEnabled}
                                    tooltipName="keys_copy_client_number"
                                    toastName="toast_client_number_success"
                                  />
                                )}

                                {!isSafari && doc_number && (
                                  <KeysActions
                                    value={doc_number}
                                    encryptionEnabled={encryptionEnabled}
                                    tooltipName="keys_copy_doc_number"
                                    toastName="toast_doc_number_success"
                                  />
                                )}

                                {!isSafari && serial_number && (
                                  <KeysActions
                                    value={serial_number}
                                    encryptionEnabled={encryptionEnabled}
                                    tooltipName="keys_copy_serial_number"
                                    toastName="toast_serial_number_success"
                                  />
                                )}
                              </ButtonDiv>
                            </div>
                          </div>
                          <div className={cnKeysBlock("Item-Image-Favorites")}>
                            {!isKeySharing && (
                              <button
                                type="button"
                                id="btn_handle_favorite_key"
                                className={isLoadingFavorites ? "Keys-Favorite" : "Keys-Favorite tooltip tooltip-left"}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleFavorite(mainFolderId, id);
                                }}
                                data-tooltip={favorite ? t("tooltip_remove-favorite") : t("tooltip_add-favorite")}
                              >
                                <Icon width={16} height={16} name={favorite ? "icon-star-fill" : "icon-star"} />
                              </button>
                            )}
                            {sealing && role === ROLE_TYPE.MANAGER && (
                              <div className="sealingIconBlock">
                                <Icon name="icon-sealing" width={16} height={16} />
                              </div>
                            )}
                            {folders.length > 0 && (
                              <Icon
                                className={cnKeysBlock("Sharing", { isSharing: !isKeySharing })}
                                width={16}
                                height={16}
                                name="icon-shared"
                              />
                            )}
                          </div>
                          <div className={cnKeysBlock("Item-Info")}>
                            <p title={title}>
                              {title}
                            </p>
                            <span>{moment(parseDate(updatedAt)).format(DATETIME_FORMAT_LITE)}</span>
                          </div>
                        </ButtonDiv>
                      );
                    },
                  )}
                {!isLoading && list.length === 0 && (
                  <div className={cnTable("Empty")}>
                    <Icon name="icon-key-add" fill="#A7A7A8" />
                      {t("key-No-keys")}
                      {folder && folder.access === FOLDER_ACCESS.WRITE ? (
                        <Link to={clearUrlParam(replaceUrlParam(Routes.ManageKey, "folderID", folder.id))} id="link_new_key">
                          {t("button_add-Key")}
                        </Link>
                      ) : null}
                  </div>
                )}
              </>
            )}
          </LoadingContainer>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  favorites: { isLoading: isLoadingFavorites },
  keys: { chosen, list, isLoading },
  user: { role },
}: RootState) => ({
  chosen,
  isLoading,
  isLoadingFavorites,
  list,
  role,
});

const mapDispatchToProps = { keyChose, favoritesSetKey };

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(KeysBlock);
