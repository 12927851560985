/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { FC, useState, useMemo } from "react";
import { cn } from "@bem-react/classname";
import moment from "moment";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Checkbox } from "ss-ui";
import { ButtonDiv } from "components/Generic";
import {
  typeSearchFolder,
  replaceUrlParam,
  Routes,
  PayloadChoseFunction,
  getKey,
  DATETIME_FORMAT_LITE,
  parseDate,
  ROLE_TYPE,
  getFolderFromTreeById,
  typeFolder,
  FOLDER_TYPE,
} from "core";

import { folderChose, keyChose, favoritesSetFolder } from "store/actions";
import { Payload as PayloadFavoritesSetFolder } from "store/routines/favorites/setFolder";
import { cnTable } from "components/Table";
import { RootState } from "store/reducers";
import { PropsWithChosen, WithChosen } from "hoc";

const cnSearchFolder = cn("SearchFolder");

type Props = {
  chosenFolders: string[];
  folderItem: typeSearchFolder;
  isFree: boolean;
  isUser: boolean;
  folderList: typeFolder[];
  folderChose: (payload: PayloadChoseFunction) => void;
  keyChose: (payload: PayloadChoseFunction) => void;
  favoritesSetFolder: (payload: PayloadFavoritesSetFolder) => void;
} & PropsWithChosen;

const SearchFolderItem: FC<Props> = ({
  chosenFolders,
  folderItem,
  isFree,
  isUser,
  folderList,
  folderChose,
  keyChose,
  handleChosenAllItems,
  favoritesSetFolder,
}) => {
  const { t } = useTranslation();

  const [itemFavorites, setItemFavorites] = useState<boolean>(folderItem.favorite);

  const folder: typeFolder = useMemo(() => getFolderFromTreeById(folderList, folderItem.id), [folderList, folderItem.id]);

  const handleFavorite = (folderID: string) => {
      favoritesSetFolder({
        folderID
      });
      setItemFavorites(!itemFavorites);
  };

  const handleChosenFolder = (id: string) => {
    handleChosenAllItems(keyChose, false, []);
    handleChosenAllItems(folderChose, !(chosenFolders.indexOf(id) >= 0), [id]);
  };

  return (
  <>
    {folder && (
      <ButtonDiv
      onClick={folder.type === FOLDER_TYPE.GROUP && isUser ? (e) => e.stopPropagation() : () => handleChosenFolder(folderItem.id)}
      key={folderItem.id}
      id={getKey("btn_key_chosen", Number(folderItem.id))}
      className={cnTable("Row", { chosen: chosenFolders.includes(folderItem.id) })}
    >
      <ButtonDiv
        id={getKey("btn_key_chosen_checkbox", 1)}
        onClick={e => e.stopPropagation()}
        className={cnSearchFolder("Checkbox Table-Th")}
      >
        <Checkbox
          checked={chosenFolders.includes(folderItem.id)}
          onChange={() =>
            handleChosenFolder(folderItem.id)
          }
          disabled={folder.type === FOLDER_TYPE.GROUP && isUser}
        />
      </ButtonDiv>
      <div className={cnSearchFolder("Type Table-Th")}>
        <div className="FolderIcon">
          <Icon width={20} height={20} name="colored/icon-folder" />
        </div>
      </div>

      <div className={cnSearchFolder("Title Table-Th")}>
        <div className={cnSearchFolder("Title-Column")}>
          <p title={folderItem.title}>{folderItem.title}</p>
              <span title={folderItem.path.length > 0 ? folderItem.path.join("/") : folderItem.title}>
                {folderItem.path.length > 0 ? folderItem.path.join("/") : folderItem.title}
              </span>
        </div>
      </div>
      <div className={cnSearchFolder("Date Table-Th")}>{moment(parseDate(folderItem.updatedAt)).format(DATETIME_FORMAT_LITE)}</div>
      <div className={cnSearchFolder("Keys Table-Th")}>
        <div className={cnSearchFolder("Keys-Item")}>
          <Icon name="colored/icon-key" width={14} height={9}/>
          <span>{folderItem.keyCount}</span>
        </div>
      </div>
      <div className={cnSearchFolder("Actions Table-Th")}>
        <button
          type="button"
          className={cnSearchFolder("Title-Favorite tooltip tooltip-left")}
          data-tooltip={itemFavorites ? t("favorites_remove") : t("favorites_add")}
          onClick={e => {
            e.stopPropagation();
            handleFavorite(folderItem.id);
          }}
        >
          <Icon width={16} height={16} name={itemFavorites ? "icon-star-fill" : "icon-star"} />
        </button>
        <ButtonDiv onClick={e => e.stopPropagation()}>
          <Link
            id={getKey(`link_to_folder_${folderItem.title}`)}
            to={replaceUrlParam(Routes.Keys, "folderID", folderItem.id)}
            className="KeysList-Action-Item tooltip tooltip-left"
            data-tooltip={t("search-to-folder-action")}
          >
            <Icon name="icon-to-folder" width={16} height={16}/>
          </Link>
        </ButtonDiv>
      </div>
    </ButtonDiv>
      )}
      </>
      );
};

const mapStateToProps = ({
  folders: { chosen: chosenFolders },
  user: { role },
}: RootState) => ({
  chosenFolders,
  isFree: [ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER].includes(role),
});

const mapDispatchToProps = {
  folderChose,
  favoritesSetFolder,
  keyChose,
};

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(SearchFolderItem);
