/* eslint-disable jsx-a11y/click-events-have-key-events, object-shorthand,  prefer-const, jsx-a11y/no-static-element-interactions, react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo } from "react";
import { Checkbox, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { ButtonDiv } from "components/Generic";
import { cnTable, typeTableHeaderElement } from "components/Table";

import { getKey, PayloadChoseFunction, typeUser, DIRECTION } from "core";

import { PropsWithChosen, PropsWithLoading, WithChosen, withLoading } from "hoc";

import { RootState } from "store/reducers";
import { usersChose } from "store/actions";
import { tableHeaderLocal } from "./TableConfig";
import TableUsersLocalItem  from "./fragments/TableUsersLocalItem";

const cnTableUsers = cn("TableUsersLocal");

type Props = PropsWithLoading &
  PropsWithChosen & {
    chosen: string[];
    isLoading: boolean;
    list: typeUser[];
    usersChose: (payload: PayloadChoseFunction) => void;
  };

const TableUsersLocal: FC<Props> = ({
  chosen,
  handleChosenAllItems,
  isLoading,
  list,
  setIsLoading,
  usersChose,
}) => {
  const { t } = useTranslation();

  const themeCheckAll = useMemo(() => (chosen.length < (list ? list.length : 0) && chosen.length > 0 ? "squre" : ""), [chosen, list]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <div className={cnTableUsers()}>
      <div className={cnTable("Head Table-Head-Small")}>
        <div className={cnTable("Head-Th")}>
          <Checkbox
            checked={chosen.length > 0 && chosen[0] !== "new"}
            id="checkbox_check_all"
            onChange={(status: boolean) => {
              handleChosenAllItems(
                usersChose,
                status,
                list.map(({ id }: typeUser) => id),
              );
            }}
            theme={themeCheckAll}
          />
        </div>
        {tableHeaderLocal.map(({ id, title, sortInit }: typeTableHeaderElement, index: number) => (
          <ButtonDiv className={cnTable("Head-Th", { active: false })} onClick={() => ({})} id={getKey(id, index)} key={id}>
            <span className={cnTable("ThTitle")}>{t(`table_title-${title}`)}</span>
            {false && <Icon className={cnTable("SortIcon", [DIRECTION.ASC])} name="colored/icon-sort-down" />}
          </ButtonDiv>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {list.length > 0 &&
            list.map((item: typeUser) => {
              return (
                <div key={item.id}>
                  <TableUsersLocalItem  listItem={item}/>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ users: { list, chosen, isLoading } }: RootState) => ({
  chosen,
  isLoading,
  list,
});

const mapDispatchToProps = { usersChose };

export default compose<Props, Partial<Props>>(WithChosen, withLoading, connect(mapStateToProps, mapDispatchToProps))(TableUsersLocal);
