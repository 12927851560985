import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

export const action: Routine = createRoutine("GET_GLOBAL_SETTINGS");

const getGlobalSettings = () => {
  return http({ route: "global/settings" });
};

function* handler() {
  try {
    const response = yield call(getGlobalSettings);
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
