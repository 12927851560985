import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { Action } from "redux-actions";
import { toast } from "react-toastify";

import { http } from "core";
import i18n from "locales/i18n";

import { get as lodashGet } from "lodash";


export const action: Routine = createRoutine("SETTINGS_SET_CUSTOMIZE");

export type Payload = {
  companyName?: string;
  companyAddress?: string;
  logo?: File | string;
  background?: File | string;
  primaryColor?: string;
  secondaryColor?: string;
  footerEn?: string;
  footerDe?: string;
}

const updateCustomize = ({
  companyName,
  companyAddress,
  logo = undefined,
  background = undefined,
  primaryColor,
  secondaryColor,
  footerEn,
  footerDe,
}: Payload) => {
  const formData = new FormData();

  if (logo) {
    formData.append("companyLogo", logo);
  } else if (logo === "reset") {
    formData.append("companyLogo", "");
  }
  if (background) {
    formData.append("loginBgImage", background);
  }
  if (companyAddress) {
    formData.append("loginCompanyAddress", companyAddress);
  }
  if (companyName) {
    formData.append("fullCompanyName", companyName);
  }
  if (primaryColor) {
    formData.append("mainColor", primaryColor);
  }
  if (secondaryColor) {
    formData.append("subColor", secondaryColor);
  }
  if (footerEn) {
    formData.append("footerTextEn", footerEn);
  }
  if (footerDe) {
    formData.append("footerTextDe", footerDe);
  }

  return http({
    route: "global/styling",
    method: "POST",
    payload: formData,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(updateCustomize, payload);

    yield put(action.success(response));

    toast.success(i18n.t("customize_successfully_save"), {
      toastId: "customize_successfully_save"
    });
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
