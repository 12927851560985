import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { FormSwitch } from "components/Form";

import { PRODUCT_TYPE } from "core";

import { cnSettings } from "pages/Settings";

import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { Payload as PayloadSetBestIcon } from "store/routines/settings/setBestIcon";
import { RootState } from "store/reducers";
import { setBestIcon, setGlobalSettings } from "store/actions";

type Props = {
  hibpwnedEnabled?: boolean;
  productType: PRODUCT_TYPE;
  pwned?: boolean;
  setBestIcon: (payload: PayloadSetBestIcon) => void;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const PWnedService: FC<Props> = ({ setBestIcon, setGlobalSettings, hibpwnedEnabled = false, pwned = false, productType }) => {
  const { t } = useTranslation();

  const isBusiness: boolean = useMemo(() => [PRODUCT_TYPE.ENTERPRISE, PRODUCT_TYPE.CORPORATE].includes(productType), [productType]);

  const handleToggle = (value: boolean) => {
    if (isBusiness) {
      setGlobalSettings({
        name: "HIB_PWNED",
        value,
      });
    } else {
      setBestIcon({
        hibpwned: value,
      });
    }
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_hunt_password_services">
        <div className={cnSettings("Header")}>
          {t("settings_header_password")}
          <div className={cnSettings("Switch")}>
            <FormSwitch isOn={isBusiness ? pwned : hibpwnedEnabled} handleToggle={handleToggle} fieldName="iconHaveibeenpwned" />
            {hibpwnedEnabled || pwned ? (
              <p>{t("settings_switch_on")}</p>
            ) : (
              <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
            )}
          </div>
        </div>
        <div className={cnSettings("Hint")}>{t("settings_hunt_password")}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings: { hibpwnedEnabled, pwned, productType } }: RootState) => ({
  hibpwnedEnabled,
  productType,
  pwned,
});

const mapDispatchToProps = {
  setBestIcon,
  setGlobalSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(PWnedService);
