/* eslint-disable no-lonely-if */
import React from "react";
import { getDataFromLS, http, LOCAL_STORAGE, ROLE_TYPE, getPortalLink } from "core";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { get as lodashGet, get, isObject, snakeCase, has } from "lodash";

import { folderGetAll, showReImportModal, deferredImport, favoritesGet } from "store/actions";

import i18n from "locales/i18n";

import { CONVEYANCE_PRODUCTS } from "pages/Settings/SettingsType";
import { Trans } from "react-i18next";

export const action: Routine = createRoutine("KEYS_IMPORT");

export interface Payload {
  product: CONVEYANCE_PRODUCTS;
  file: File;
  folderId: string | null;
  archive: File;
  preserve: string;
  allowPassword?: boolean;
  rootFolderImport?: boolean;
}

const keysImport = ({ product, file, archive, folderId, preserve, allowPassword, rootFolderImport }: Payload) => {
  const data: FormData = new FormData();

  data.append("import-main", file);
  if (archive) {
    data.append("import-extra", archive);
  }
  if (rootFolderImport) {
    data.append("import-in-root", "1");
  } else {
    if (folderId) {
      data.append("import-folder-id", folderId);
    } else {
      data.append("import-folder-id", "");
    }
  }
  data.append("preserve-structure", preserve);

  if (allowPassword) {
    data.append("allow_insecure_passwords", "1");
  }

  return http({ route: `key/import/${product}`, method: "POST", payload: data });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(keysImport, payload);
    yield put(action.success());
    toast.success(i18n.t("toast_success_import_key"));
    yield put(folderGetAll.trigger());
    yield put(favoritesGet.trigger());
  } catch (error) {
    const errorStatus = get(error, "response.status", null);
    const { role } = getDataFromLS(LOCAL_STORAGE.USER);
    const errorMessage = get(error, "response.data.error", null);
    const warningInfo = get(error, "response.data.info", null);
    if (errorStatus === 422) {
      yield put(deferredImport.trigger());
    } else {
      if (warningInfo) {
        const key = get(warningInfo, "key", "");
        const field = get(warningInfo, "field", "");
        const error = get(warningInfo, "error", false);
        const some_error = snakeCase(get(errorMessage, "some_error_occurred", ""));
        if (error.includes("{{")) {
          const test = error.split("{{").pop().split("}}").shift();
          toast.error(
            <>
              {i18n.t("navigation_key")} {key}: {field} -
              <Trans i18nKey="pass_validation_min_count" count={test} />
            </>,
          );
        } else {
          toast.error(
            `${i18n.t("navigation_key")} ${key}: ${field} - ${
              i18n.t(`toast_import_error_${snakeCase(error)}`) || i18n.t(`toast_import_error_${some_error}`)
            }`,
          );
        }
        yield put(showReImportModal.success(warningInfo));
      } else if (isObject(errorMessage) && !warningInfo) {
        const key = get(errorMessage, "key", "");
        const field = get(errorMessage, "field", "");
        const error = get(errorMessage, "error", false);
        const some_error = snakeCase(get(errorMessage, "some_error_occurred", ""));
  
        if (snakeCase(error) === "cannot_decrypt_passwords_encryption_is_bound_to_the_specific_user") {
          toast.error(`toast_import_error_${i18n.t(snakeCase(error))}`);
        } else if (snakeCase(error) === "sorry_some_error_occurred") {
          toast.error(`${i18n.t("navigation_key")} ${key}: ${i18n.t(`toast_import_error_${error}`)}`);
        } else if (snakeCase(error) === "forbidden_character") {
          toast.error(`${i18n.t("navigation_key")} ${key}: ${field} - ${i18n.t(`toast_import_error_${error}`)}`);
        } else if (error.includes("{{")) {
          const test = error.split("{{").pop().split("}}").shift();
          toast.error(
            <>
              {i18n.t("navigation_key")} {key}: {field} -
              <Trans i18nKey="pass_validation_min_count" count={test} />
            </>,
          );
        } else {
          toast.error(
            `${i18n.t("navigation_key")} ${key}: ${field} - ${
              i18n.t(`toast_import_error_${snakeCase(error)}`) || i18n.t(`toast_import_error_${some_error}`)
            }`,
          );
        }
        yield put(showReImportModal.success());
      } else {
        const errorMessageSnake = snakeCase(errorMessage);
        // TODO: Need update backend for getting valid error for free user 50 key limit
        if (role === ROLE_TYPE.FREE_USER && errorMessageSnake === "you_do_not_have_permission_to_import_into_this_folder") {
          toast.info(
            <Trans i18n={i18n} i18nKey="toast_free_user_key_limit">
              <a target="_blank" rel="noopener noreferrer" href={`${getPortalLink(window.location.host)}/login`}>
                Upgrade
              </a>
            </Trans>,
            {
              toastId: "toast_free_user_key_limit",
              autoClose: false,
              position: "top-center",
            },
          );
        } else if (has(error, "response.data.status")) {
          toast.error(i18n.t("settings_access_control_denied"));
        } else {
          toast.error(i18n.t(`toast_import_error_${snakeCase(errorMessage)}`));
        }
        yield put(showReImportModal.success());
      }
  
      yield put(action.failure(lodashGet(error, "message", undefined)));
    }
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
