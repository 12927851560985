/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from "react";
import { PayloadChoseFunction } from "core";

export type PropsWithChosen = {
  handleChosenAllItems: (method: (payload: PayloadChoseFunction) => void, status: boolean, ids?: string[]) => void;
  handleUpdateChosenItems: (method: (payload: PayloadChoseFunction) => void, status: boolean, id: string, oneOnly?: boolean) => void;
};

const WithChosen = <P extends {}>(Component: React.ComponentType<P>): FC<P> => {
  const WithChosenComponent = ({ ...props }) => {
    const handleChosenAllItems = (method: (payload: PayloadChoseFunction) => void, status: boolean, ids: string[]) => {
      method({ status, choseAll: true, ids });
    };

    const handleUpdateChosenItems = (method: (payload: PayloadChoseFunction) => void, status: boolean, id: string, oneOnly?: boolean) => {
      method({ id, status, oneOnly });
    };

    return <Component handleChosenAllItems={handleChosenAllItems} handleUpdateChosenItems={handleUpdateChosenItems} {...(props as P)} />;
  };

  return WithChosenComponent;
};

export default WithChosen;
