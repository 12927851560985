import React, { FC, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { FormDatePicker } from "components/Form";
import { FIELD_TYPE, getFieldValue } from "core";
import { FieldContext } from "./Types";

type Props = {};

const fieldType = FIELD_TYPE.EXPIRATION_DATE;

const FieldExpirationDate: FC<Props> = () => {
  const { t } = useTranslation();
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else if (context.key?.[fieldType]) {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    } 
  }, [context.key]);

  return (
    <Field
      className="FieldExpirationDate"
      component={FormDatePicker}
      label={t(`manage_key_field_${fieldType}_label`)}
      name={fieldType}
      placeholder={t(`manage_key_field_${fieldType}_placeholder`)}
      selectOnly
      showMonthDropdown
      showYearDropdown
    />
  );
};

export default FieldExpirationDate;
