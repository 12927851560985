import { Action } from "redux-actions";
import { ICustomize, parseCustomizeResponse } from "core";
import { getConfig, setCustomize } from "store/actions";

export interface SettingsCustomizeState extends ICustomize {
  isLoading: boolean;
}

const initialState = {
  backgroundLogin: undefined,
  backgroundLoginImageName: undefined,
  colorMain: undefined,
  colorSub: undefined,
  companyAddress: undefined,
  companyName: undefined,
  fonts: undefined,
  logo: undefined,
  logoImageName: undefined,
  footerEn: undefined,
  footerDe: undefined,
  isLoading: false,
};

export default function customize(state: SettingsCustomizeState = initialState, { payload, type }: Action<any>): SettingsCustomizeState {
  switch (type) {
    case getConfig.TRIGGER:
    case setCustomize.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case getConfig.SUCCESS:
      return {
        ...state,
        ...parseCustomizeResponse(payload),
        isLoading: false,
      };

    case setCustomize.SUCCESS:
      return {
        ...state,
        ...parseCustomizeResponse(payload),
        isLoading: false,
      };

    case getConfig.FAILURE:
    case getConfig.FULFILL:
    case setCustomize.FAILURE:
    case setCustomize.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
