import { Action } from "redux-actions";
import { DAYS_FORMAT, REMIND_FORMAT } from "pages/Settings/SettingsType";
import { getSettings, setResetPasswordSettings } from "store/actions";

export interface SettingsResetPasswordState {
  days: DAYS_FORMAT;
  enabled: boolean;
  remind: REMIND_FORMAT;
  required: boolean;
  isLoading: boolean;
}

const initialState = {
  days: DAYS_FORMAT.THREE,
  enabled: false,
  remind: REMIND_FORMAT.FIVE,
  required: false,
  isLoading: false,
};

export default function resetPassword(state: SettingsResetPasswordState = initialState, { payload, type }: Action<any>): SettingsResetPasswordState {
  switch (type) {
    case getSettings.TRIGGER:
    case setResetPasswordSettings.TRIGGER:
      return {
        ...state,
        isLoading: true,
      };

    case getSettings.SUCCESS:
      return payload.passwordRenewal
        ? {
            ...state,
            days: payload.passwordRenewal.reneval_interval,
            enabled: payload.passwordRenewal.enabled,
            remind: payload.passwordRenewal.remind_in_advance,
            required: payload.passwordRenewal.required,
            isLoading: false,
          }
        : { ...state };

    case setResetPasswordSettings.SUCCESS:
      return {
        ...state,
        days: payload.passwordRenewal.reneval_interval,
        enabled: payload.passwordRenewal.enabled,
        remind: payload.passwordRenewal.remind_in_advance,
        required: payload.passwordRenewal.required,
        isLoading: false,
      };

    case getSettings.FAILURE:
    case getSettings.FULFILL:
    case setResetPasswordSettings.FAILURE:
    case setResetPasswordSettings.FULFILL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
