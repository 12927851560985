import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Pagination } from "components/Generic";
import { typeSearchFolder, getKey, typeFolder } from "core";
import { Payload as payloadGetFoldersSearch } from "store/routines/search/getFoldersSearch";
import { getFoldersSearch } from "store/actions";
import { cnTable, PropsTableWithSort, TableHeaderElement, TableWithSort, typeTableHeaderElement } from "components/Table";
import { RootState } from "store/reducers";
import { tableHeaderFolder } from "../../TableConfig";

import SearchFolderItem from "./SearchFolderItem";

const cnSearchFolder = cn("SearchFolder");

type Props = {
  foldersList: typeSearchFolder[];
  foldersCurrentPage: number,
  foldersPagesCount: number,
  searchProps: string;
  isLoadingFolders: boolean;
  isUser: boolean;
  list: typeFolder[];
  getFoldersSearch: (payload: payloadGetFoldersSearch) => void;
} & PropsTableWithSort;

const SearchFolder: FC<Props> = ({
  searchProps,
  foldersList,
  sortBy,
  sortDirection,
  foldersCurrentPage,
  foldersPagesCount,
  isLoadingFolders,
  isUser,
  list,
  getFoldersSearch,
  sortCallback,
  handleSort,
}) => {
  const { t } = useTranslation();

  const handleChangePage = (page: number) => {
    getFoldersSearch({ currentPage: page, search: searchProps });
  };

  return (
    <>
    <div className={cnSearchFolder()}>
      <div className={cnTable("Head Table-Head-Small Table-Key")}>
        <div className={cnTable("Head-Th")} />
        {tableHeaderFolder.map(({ title, ...data }: typeTableHeaderElement, index: number) => (
          <TableHeaderElement
            handleSort={handleSort}
            id={getKey(title, index)}
            key={title}
            sortBy={sortBy}
            sortDirection={sortDirection}
            {...data}
          >
            {t(`table_title-${title}`)}
          </TableHeaderElement>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {foldersList.length > 0 ? (
            foldersList.sort(sortCallback).map(( item: typeSearchFolder) => {
              return (
                <SearchFolderItem isUser={isUser} folderList={list} folderItem={item} key={item.id}/>
              )
            }
          )) : (
            <>{isLoadingFolders ? null : <div className={cnTable("Empty")}><p>{t("search_results_folders_is_empty")}</p></div>}</>
          )}
        </div>
      </div>
    </div>
    {foldersList.length > 0 && 
      <Pagination 
        id="search_folders_pagination"
        itemPagesCount={foldersPagesCount}
        currentPage={foldersCurrentPage}
        onChange={handleChangePage}
      />
    }
    </>
  );
};

const mapStateToProps = ({
  search: { 
    search, 
    foldersList, 
    foldersCurrentPage,
    foldersPagesCount,
    isLoadingFolders,
  },
  folders: { list }
}: RootState) => ({
  search, 
  foldersList, 
  foldersCurrentPage,
  foldersPagesCount,
  searchProps: search,
  isLoadingFolders,
  list
});

const mapDispatchToProps = {
  getFoldersSearch,
};

export default compose<Props, Partial<Props>>(TableWithSort, connect(mapStateToProps, mapDispatchToProps))(SearchFolder);