import { Action } from "redux-actions";
import {
  setGlobalSearch,
  getKeysSearch,
  getFoldersSearch,
  removeKeyInSearch,
  removeFolderInSearch,
} from "store/actions";

import { typeSearchKey, typeSearchFolder, unpackSearchKeys } from "core";

export interface State {
  search: string;
  isLoadingKeys: boolean;
  isLoadingFolders: boolean;
  // keys
  keysList: typeSearchKey[];
  keysCurrentPage: number;
  keysPagesCount: number;
  keysTotalItems: number;
  // folders
  foldersList: typeSearchFolder[];
  foldersCurrentPage: number;
  foldersPagesCount: number;
  foldersTotalItems: number;
}

const initialState: State = {
  search: "",
  isLoadingKeys: false,
  isLoadingFolders: false,
  keysList: [],
  keysCurrentPage: 1,
  keysPagesCount: 1,
  keysTotalItems: 0,
  foldersList: [],
  foldersCurrentPage: 1,
  foldersPagesCount: 1,
  foldersTotalItems: 0,
};

export default function favorites(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case getKeysSearch.TRIGGER:
    case removeKeyInSearch.TRIGGER:  
      return {
        ...state,
        isLoadingKeys: true,
      }
    case getFoldersSearch.TRIGGER:
    case removeFolderInSearch.TRIGGER:  
      return {
        ...state,
        isLoadingFolders: true,
      };
    case setGlobalSearch.TRIGGER:
      return {
        ...state,
        search: "",
        isLoadingKeys: true,
        isLoadingFolders: true,
      }

    case setGlobalSearch.SUCCESS:
      return {
        ...state,
        search: payload,
        isLoadingKeys: false,
        isLoadingFolders: false,
      };
    
    case removeKeyInSearch.SUCCESS:
      return {
        ...state,
        keysList: state.keysList.length > 0 ? [...state.keysList.filter(e => e.id !== payload)] : [] ,
        isLoadingKeys: false,
      }
    
    case removeFolderInSearch.SUCCESS:
      return {
        ...state,
        foldersList: state.foldersList.length > 0 ? [...state.foldersList.filter(e => e.id !== payload)] : [] ,
        isLoadingFolders: false,
      }

    case getKeysSearch.SUCCESS:
      return {
        ...state,
        keysList: unpackSearchKeys(payload.results),
        keysCurrentPage: payload.currentPage,
        keysPagesCount: payload.pagesCount,
        keysTotalItems: payload.totalItems,
        isLoadingKeys: false,
      };

    case getFoldersSearch.SUCCESS:
      return {
        ...state,
        foldersList: payload.results,
        foldersCurrentPage: payload.currentPage,
        foldersPagesCount: payload.pagesCount,
        foldersTotalItems: payload.totalItems,
        isLoadingFolders: false,
      };

    case setGlobalSearch.FAILURE:
    case getFoldersSearch.FAILURE:
    case getKeysSearch.FAILURE:
    case removeKeyInSearch.FAILURE:  
    case removeFolderInSearch.FAILURE:  
      return {
        ...state,
        isLoadingKeys: false,
        isLoadingFolders: false,
      };

    default:
      return state;
  }
}
