/* eslint-disable no-unneeded-ternary */
import DatePicker from "react-datepicker";
import { de, enUS } from "date-fns/locale";
import React, { useState, useEffect, FunctionComponent } from "react";
import moment from "moment";
import { Icon } from "ss-ui";
import { WrappedFieldProps } from "redux-form";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

import { parseDate } from "core";

type Props = {
  className?: string;
  dateFormat?: string;
  disabled?: boolean;
  selectOnly?: boolean;
  label: string;
  customInput?: FunctionComponent;
  maxDate?: Date;
  minDate?: Date;
  placeholder?: string;
  readOnly?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
} & WrappedFieldProps;

const FormDatePicker: React.FC<Props> = ({
  className = "",
  dateFormat = "dd.MM.yyyy",
  input,
  label,
  maxDate = undefined,
  meta: { touched, error },
  minDate = undefined,
  placeholder = "",
  selectOnly = false,
  ...rest
}) => {
  const [date, setDate] = useState<Date | null>(null);

  const isError = touched && Boolean(error);
  const currentLang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (input.value && !date) {
      handleChange(parseDate(input.value));
    }
    if (!input.value && minDate) {
      setDate(new Date(minDate));
    }
  }, [input.value]);

  useEffect(() => {
    if (input.value && !date) {
      handleSetSelectedDate(input.value);
    }
  }, []);

  const handleFocus = (e: any) => {
    selectOnly && e.target.blur();
    if (e.target.value) {
      handleSetSelectedDate(e.target.value);
    } else if (!e.target.value && minDate) {
      setDate(new Date(minDate));
    }
  }

  const handleSetSelectedDate = (value: string) => {
    const [day, month, year] = value.split(".");
    const monthCalc = Number(month) - 1;
    const newDateSelected = new Date(Number(year), Number(monthCalc), Number(day));
    setDate(newDateSelected);
  }

  const handleChange = (selectDate: Date) => {
    if (selectDate) {
      input.onChange(moment(selectDate).format(dateFormat?.toUpperCase()));
    } else {
      input.onChange(null);
      setDate(null);
    }
  }

  return (
    <div className={`${cnField()} ${cnField("DatePicker", { hasError: isError })} ${className}`} id={`input_date_picker_${input.name}_container`}>
      {label && (
        <span className="Label" id={`input_date_picker__${input.name}_label`}>
          {label}
        </span>
      )}
      <div className={`${cnField("DatePickerWrapper")}`}>
        <DatePicker
          {...rest}
          {...input}
          dateFormat={dateFormat}
          id="history_filter_date_picker_period_start"
          placeholderText={placeholder || ""}
          maxDate={maxDate}
          minDate={minDate && minDate}
          onFocus={(e: any) => handleFocus(e)}
          autoComplete="off"
          selected={date ? date : null}
          onChange={handleChange}
          locale={currentLang === "de" ? de : enUS}
        />
        <Icon name="icon-calendar" />
      </div>
      {touched && Boolean(error) && (
        <span id={`input_date_picker_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default FormDatePicker;
