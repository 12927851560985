import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import { getConfig } from "store/actions";

export type Payload = {
  id: string;
  action: "enable" | "disable";
};

export const action: Routine = createRoutine("USERS_CONTROL_BLOCK");

const controlBlock = ({ id, action }: Payload) => {
  return http({ route: `manager/user/${id}/${action}`, method: "GET" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(controlBlock, payload);

    yield put(action.success(response));

    yield put(getConfig.trigger());
  } catch (error) {
    yield put(action.failure(get(error, "response.data.detail.messages", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
