/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import moment from "moment";

import { DIRECTION, parseDate, SORT_BY } from "core";

export type PropsTableWithSort = {
  handleSort: (type?: SORT_BY) => void;
  sortBy: SORT_BY;
  sortCallback: (a: any, b: any) => number;
  sortDirection: DIRECTION;
};

const TableWithSort = <P extends {}>(Component: React.ComponentType<P>): React.FC<P> => {
  const WithSortComponent = ({ ...props }) => {
    const [sortBy, setSortBy] = useState<SORT_BY>();
    const [sortDirection, setSortDirection] = useState<DIRECTION>(DIRECTION.ASC);

    const handleSort = (type: SORT_BY) => {
      if (sortBy === type) {
        setSortDirection(sortDirection === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC);
      } else {
        setSortDirection(DIRECTION.ASC);
      }
      setSortBy(type);
    };

    const getSortResult = (A: string | number, B: string | number, sortDirection: DIRECTION) =>
      sortDirection === DIRECTION.ASC ? (A > B ? 1 : A < B ? -1 : 0) : sortDirection === DIRECTION.DESC ? (A > B ? -1 : A < B ? 1 : 0) : 0;

    const sortCallback = (a: any, b: any) => {
      if (!sortBy) return 0;
      switch (sortBy) {
        // Sort string by name
        case SORT_BY.DESCRIPTION:
        case SORT_BY.FULL_NAME:
        case SORT_BY.NAME:
        case SORT_BY.TITLE:
        case SORT_BY.USER_NAME: {
          const A = a[sortBy] !== null ? a[sortBy]!.toUpperCase() : "";
          const B = b[sortBy] !== null ? b[sortBy]!.toUpperCase() : "";
          return getSortResult(A, B, sortDirection);
        }
        case SORT_BY.ROLE: {
          const A = (a[sortBy] || a[sortBy][0]) !== null ? a[sortBy] || a[sortBy][0] : "";
          const B = (b[sortBy] || b[sortBy][0]) !== null ? b[sortBy] || b[sortBy][0] : "";
          return getSortResult(A, B, sortDirection);
        }

        // Sort array by name
        case SORT_BY.FOLDERS:
        case SORT_BY.GROUPS:
        case SORT_BY.GROUPS_ARR:
        case SORT_BY.KEYS:
        case SORT_BY.MEMBERS:
        case SORT_BY.USERS:
          return sortDirection === DIRECTION.ASC ? a[sortBy].length - b[sortBy].length : b[sortBy].length - a[sortBy].length;
        // Sort by boolean
        case SORT_BY.ACTIVE:
          return sortDirection === DIRECTION.ASC ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
        // Sort by date
        case SORT_BY.CREATED_DATE:
        case SORT_BY.UPDATE_DATE:
        case SORT_BY.EXPIRATION_TIME: {
          const dateA = parseDate(a[sortBy]);
          const dateB = parseDate(b[sortBy]);

          return sortDirection === DIRECTION.ASC
            ? moment(dateA).valueOf() - moment(dateB).valueOf()
            : moment(dateB).valueOf() - moment(dateA).valueOf();
        }

        case SORT_BY.ENTITY_TYPE: {
          const A: number = a.key ? 1 : 0;
          const B: number = b.key ? 1 : 0;

          return sortDirection === DIRECTION.ASC ? A - B : B - A;
        }

        case SORT_BY.FAVORITE_TITLE: {
          const A = a.key ? a.key.title.toUpperCase() : a.folder.title.toUpperCase();
          const B = b.key ? b.key.title.toUpperCase() : b.folder.title.toUpperCase();
          return getSortResult(A, B, sortDirection);
        }
        default:
          return 0;
      }
    };

    return (
      <Component sortBy={sortBy} sortDirection={sortDirection} handleSort={handleSort} sortCallback={sortCallback} {...(props as P)} />
    );
  };

  return WithSortComponent;
};

export default TableWithSort;
