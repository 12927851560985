/* eslint-disable no-unneeded-ternary */

import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, has } from "lodash";
import { Action } from "redux-actions";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

import { http, IUsersFilter } from "core";

export const action: Routine = createRoutine("USERS_GET_ALL");

export interface Payload {
  page?: number;
  filters?: IUsersFilter;
}

const getAll = ({ page = 1, filters }: Payload) => {
  const PER_PAGE: number = 20;
  const params: any = {};

  if (filters) {
    params.search = filters.search;
    params.roles = filters.roles.map(item => item.value);
    params.groups = filters.groups.map(item => item.value);
  }

  if (filters && filters.status) {
    params.status = filters.status;
  }

  if (filters && filters.otp) {
    params.otp = filters.otp === "1" ? true : false;
  }

  params.currentPage = page;
  params.pageSize = PER_PAGE;

  return http({
    route: "manager/users",
    method: "GET",
    payload: { params },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(getAll, payload);

    yield put(action.success(response));
  } catch (error) {

    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
