import React, { FC, useEffect, useMemo, useState } from "react";
import { Icon, SearchInput, Button, FolderIcon } from "ss-ui";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { 
  getFolderFromTreeById, 
  typeFolder, 
  typeGroup, 
  FORM_FOLDER_MANAGEMENT, 
  FOLDER_TYPE, 
  ACTION, 
  getKey, 
  ROLE_TYPE,
  getGroupFromListByID,
  foldersForm,
} from "core";
import { toast } from "react-toastify";
import { validationsFormFolder } from "core/helpers/validation";
import { FieldArray, InjectedFormProps, reduxForm, WrappedFieldArrayProps, formValueSelector } from "redux-form";
import { FormValuesFolder, Payload as PayloadFolderManage } from "store/routines/folders/manage";
import { RootState } from "store/reducers";
import { groupsGetAll, folderManage } from "store/actions";
import { PropsTableWithSearch, TableWithSearch } from "components/Table";
import { LoadingContainer, Modal, cnModal } from "components/Generic";
import { Payload as PayloadGroupsGetAll } from "store/routines/groups/getAll";
import { cnInfoBox } from "components/InfoBox";
import RenderGroup from "./RenderGroups";

type Props = {
  chosen: string[];
  groupsList: typeGroup[];
  isLoadingGroup: boolean;
  list: typeFolder[];
  modalGroupsValue: boolean;
  formData: any;
  username?: string;
  role?: ROLE_TYPE;
  parentFolderList: any[];
  folderManage: (payload: PayloadFolderManage) => void;
  handleModalGroups: (value: boolean) => void;
  setActiveTab: (index: number) => void;
  groupsGetAll: (payload: PayloadGroupsGetAll) => void;
} & InjectedFormProps &
PropsTableWithSearch &
WrappedFieldArrayProps<any>;

const Groups: FC<Props> = ({ 
  chosen, 
  groupsList, 
  isLoadingGroup, 
  list, 
  modalGroupsValue,
  formData,
  username,
  role,
  parentFolderList,
  setSearch, 
  searchCallback,
  change,
  handleSubmit,
  folderManage,
  handleModalGroups,
  setActiveTab,
  groupsGetAll, 
}) => {
  const { t } = useTranslation();

  const isNew = useMemo(() => chosen[0] === "new", [chosen]);
  const folder: typeFolder = useMemo(() => getFolderFromTreeById(list, chosen[0]), [list, chosen]);
  const folderGroupList = useMemo(() => folder?.folderGroups?.map(item => (
    { group: item.group, access: item.access, recursion: item.recursion }
  )), [folder]);
  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);
  const [countFields, setCountFields] = useState<number>(folderGroupList?.length || 0);

  useEffect(() => {
      groupsGetAll({});
  }, []);

  useEffect(() => {
    change("folderGroups", isNew ? formData.folderGroups || [] : folderGroupList);
  }, [folder]);

  const handleCloseModal = () => {
    handleModalGroups(false);
    change("folderGroups", isNew ? formData.folderGroups || [] : folderGroupList);
  };

  const handleForm = (values, dispatch, props) => {
    handleModalGroups(false);
    if (Object.keys(props.syncErrors).length > 0 && parentFolderList.length === 0) {
      setActiveTab(1);
      toast.error(t("error_this_value_should_not_be_blank"));
    } else if (Object.keys(props.syncErrors).includes("title")) {
      toast.error(t("error_this_value_should_not_be_blank"));
      setActiveTab(1);
    } else {
      folderManage({
        id: folder && folder.id ? folder.id : undefined,
        action: folder && folder.id ? ACTION.UPDATE : ACTION.CREATE,
        type: FOLDER_TYPE.GROUP,
        payload: foldersForm(values, false),
      });
    }
  };

  return (
    <>
      <div className={cnInfoBox("ContentHeader")}>{t("groups-List")}</div>
      <div className={cnInfoBox("Search")} id="div_search_input">
        <SearchInput onChange={setSearch} />
      </div>
      {isNew && (
        <div className={cnInfoBox("List")}>
          {parentFolderList && parentFolderList.length !== 0 &&
            parentFolderList?.filter(searchCallback).map((group, i) => {
            const itemGroup: typeGroup = getGroupFromListByID(groupsList, group.group);
            return (
              <div key={getKey(group.name, i)} className={cnInfoBox("List-Group")}>
                <div className={cnInfoBox("List-Group-Title")}>
                  <Icon name="icon-group-circle" width={20} height={20} />
                  <span title={group.name}>{group.name}</span>
                </div>
                <div className={cnInfoBox("List-Group-Info")}>
                  <div className={cnInfoBox("List-Group-Folder", [`groups_access_${group.access}`]) }>
                    <Icon name={`colored/icon-folder-access-${group.access}`} />
                  </div>
                  <div className={cnInfoBox("List-Group-Member")}>
                    <Icon name="icon-user" />
                    <span>{itemGroup?.users.length}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <form id="submit-group-form" onSubmit={handleSubmit(handleForm)}>
        {folder && folder?.folderGroups?.length !== 0 && (
          <LoadingContainer isLoading={isLoadingGroup}>
            <div className={cnInfoBox("List")}>
              {groupsList.length !== 0 &&
                folder &&
                folder?.folderGroups?.filter(searchCallback).map((group, i) => {
                  const itemGroup: typeGroup = getGroupFromListByID(groupsList, group.group);
                  return (
                    <div key={getKey(group.name, i)} className={cnInfoBox("List-Group")}>
                      <div className={cnInfoBox("List-Group-Title")}>
                        <Icon name="icon-group-circle" width={20} height={20} />
                        <span title={group.name}>{group.name}</span>
                      </div>
                      <div className={cnInfoBox("List-Group-Info")}>
                        <div className={cnInfoBox("List-Group-Folder", [`groups_access_${group.access}`])}>
                          <Icon name={`colored/icon-folder-access-${group.access}`} />
                        </div>
                        <div className={cnInfoBox("List-Group-Member")}>
                          <Icon name="icon-user" />
                          <span>{itemGroup.users.length}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </LoadingContainer>
        )}
      {(folder?.folderGroups?.length === 0 || isNew) && (parentFolderList.length === 0) && (
        <div className={cnInfoBox("ContentHeader GroupErrorList")}>
          {t("groups-List-Empty")}
        </div>
      )}
      {modalGroupsValue && (
        <Modal handleClose={handleCloseModal} className={cnModal("FolderGroups")}>
          <LoadingContainer isLoading={isLoadingGroup}>
          <div className={cnModal("Header")}>
            {!folder ? (
              <FolderIcon />
            ) : (
              <FolderIcon
                level={folder.level_security}
                size={30}
                isRoot={folder.root === null && folder.type !== FOLDER_TYPE.GROUP}
                isGroup={folder.type === FOLDER_TYPE.GROUP}
                access={folder.access}
              />
            )}
            <span>{isNew ? t("folders-New") : folder.title}</span>
          </div>
          <div className={cnModal("Scroll")}>
            <FieldArray 
              name="folderGroups" 
              component={RenderGroup} 
              groupList={groupsList}
              parentGroupList={parentFolderList && parentFolderList}
              userName={username}
              isAdmin={isAdmin}
              isNew={isNew}
              countFields={setCountFields}
              folderGroupsArray={isNew ? [] : folder.folderGroups}
            />
          </div>
          <div className={cnModal("Actions")}>
            {countFields > 0 ? (
              <Button form="submit-group-form" id="button_action_save" type="submit">
                {t("button-Save")}
              </Button>
            ) : <>&nbsp;</>}
            <Button id="button_action_cancel" theme="secondary-outline" onClick={handleCloseModal}>
              {t("button-Cancel")}
            </Button>
          </div>
          </LoadingContainer>
        </Modal>
      )}
      </form>
    </>
  );
};

const selector = formValueSelector(FORM_FOLDER_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  groupsList: state.groups.list,
  isLoadingGroup: state.groups.isLoading,
  chosen: state.folders.chosen,
  list: state.folders.list,
  username: state.user.username,
  role: state.user.role,
  formData: selector(state, "folderGroups", "parent"),
});

const mapDispatchToProps = { groupsGetAll, folderManage };

export default compose<Props, Partial<Props>>(
  TableWithSearch,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValuesFolder>({
    form: FORM_FOLDER_MANAGEMENT,
    destroyOnUnmount: false,
    validate: validationsFormFolder,
  }),
)(Groups);
