/* eslint-disable @typescript-eslint/no-use-before-define, @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { Button } from "ss-ui";
import { cnModal, Modal } from "components/Generic";
import { useTranslation } from "react-i18next";
import { getCroppedImg } from "core";

type Props = {
  aspect?: number;
  cropArea?: boolean;
  cropHeight?: number;
  cropWidth?: number;
  handleClose: () => void;
  image?: any | null;
  onChangeImage: (payload: any) => void;
  setImageValue: (payload: any | null) => void;
  type?: string;
};

const ImageCropper: FC<Props> = ({
  aspect = 1,
  cropArea,
  cropHeight,
  cropWidth,
  handleClose,
  image,
  onChangeImage,
  setImageValue,
  type,
}) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage, setCroppedImage] = useState<any | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg({
        imageSrc: image,
        pixelCrop: croppedAreaPixels,
        maxWidth: cropWidth,
        maxHeight: cropHeight,
        typeImg: type,
      });
      setCroppedImage(croppedImage);
      onChangeImage(croppedImage);
      setImageValue(null);
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels, onChangeImage]);

  const onZoomChange = zoom => {
    setZoom(zoom);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Modal handleClose={handleClose} className={cnModal("Cropper")}>
      <div className={cnModal("Cropper-Header")} id="modal_cropper_header" />
      <div className={cnModal("Cropper-Body")} id="modal_cropper_body">
        <div className={cnModal("Modal-Crop")} id="modal_cropper">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            cropShape={cropArea ? "round" : "rect"}
            aspect={aspect}
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </div>
      </div>
      <div className={cnModal("Cropper-Slider")}>
        <p>-</p>
        <input
          type="range"
          min="1"
          max="5"
          step="any"
          value={zoom}
          onChange={e => onZoomChange(e.target.value)}
          className="SliderInput"
          id="modal_cropper_input_zoom"
        />
        <p>+</p>
      </div>
      <div id="modal_cropper_actions" className={cnModal("Cropper-Action")}>
        <Button id="button_show_cropping" onClick={() => showCroppedImage()}>
          {t("button-Upload")}
        </Button>
      </div>
    </Modal>
  );
};

export default ImageCropper;
