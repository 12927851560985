import React, { FC } from "react";
import { Icon } from "ss-ui";
import { compose } from "recompose";
import { connect } from "react-redux";
import { has, snakeCase } from "lodash";
import { useTranslation } from "react-i18next";

import { cnTable, TableHeaderElement, typeTableHeaderElement } from "components/Table";
import { ButtonDiv } from "components/Generic";

import { SORT_BY, PayloadChoseFunction, IActivityLogRecord, mapRolesName, ROLE_TYPE, IActivityLogFilter } from "core";

import { PropsWithChosen, WithChosen } from "hoc";

import { RootState } from "store/reducers";
import { ActivityLogChose } from "store/actions";

import { tableHeader } from "./TableConfig";
import { cnActivityLog } from "./ActivityLog";

export const mappingObjectToIcon = (object: string, operation: string): JSX.Element => {
  switch (`${object}_${operation}`) {
    case "key_create":
    case "key_edit":
    case "key_move":
    case "key_favorites_add":
    case "key_favorites_remove":
    case "key_delete":
    case "key_restore":
    case "key_revert":
    case "key_share":
    case "key_share_added":  
    case "key_share_deleted": 
      return <Icon name="icon-key-small" />;
    case "folder_create":
    case "folder_edit":
    case "folder_move":
    case "folder_favorites_add":
    case "folder_favorites_remove":
    case "folder_delete":
    case "folder_restore":
    case "folder_revert":
      return <Icon name="icon-folder" />;
    case "user_create":
    case "user_edit":
    case "user_delete":
    case "user_login":
    case "user_include_into_group":
    case "user_exclude_from_group":
      return <Icon name="icon-user" />;
    case "group_create":
    case "group_edit":
    case "group_delete":
    case "group_group_members_added":
    case "group_group_members_removed":
      return <Icon name="icon-group" />;
    case "setting_switched_on":
    case "setting_switched_off":
    case "setting_value_changed":
    case "access_devices":  
      return <Icon name="icon-setting" />;
    case "system_autobackup_created":
      return <Icon name="icon-screen-setting" />;
    case "system_keys_import":
      return <Icon name="icon-move-in" />;
    case "system_keys_export":
      return <Icon name="icon-move-out" />;
    case "group_rights_add_folder_to_group":
    case "group_rights_remove_folder_from_group":
    case "group_rights_change_folder_right":
      return <Icon name="icon-users-lock" />;
    default:
      return <Icon name="icon-screen-desktop" />;
  }
};

type Props = {
  results: any[];
  chosen: string[];
  ActivityLogChose: (payload: PayloadChoseFunction) => void;
  handleSort: (type?: SORT_BY) => void;
  filters: IActivityLogFilter;
} & PropsWithChosen;

const ActivityLogTable: FC<Props> = ({ filters, results, handleUpdateChosenItems, ActivityLogChose, chosen, handleSort }) => {
  const { t } = useTranslation();

  return (
    <div className={cnActivityLog("Table")}>
      <div className={cnTable("Head Table-Head-Small Table-Key")}>
        {tableHeader.map(({ id, title, sortInit }: typeTableHeaderElement) => (
          <TableHeaderElement
            id={id}
            key={id}
            handleSort={handleSort}
            sortInit={sortInit}
            sortBy={filters.sortBy}
            sortDirection={filters.sortOrder}
          >
            {t(title)}
          </TableHeaderElement>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {results.length === 0 && <div className={cnTable("Empty")}>{t("activity_log_empty_table")}</div>}
          {results.length > 0 &&
            results.map((item: IActivityLogRecord) => (
              <ButtonDiv
                className={cnTable("Row", { chosen: chosen.indexOf(item.id) >= 0 })}
                key={item.id}
                id={item.id}
                onClick={() => handleUpdateChosenItems(ActivityLogChose, !(chosen.indexOf(item.id) >= 0), item.id, true)}
              >
                <div className={cnTable("Table-Th")}>
                  {has(item, "createdAt.date") ? (
                    <>
                      {item.createdAt.date} {item.createdAt.time}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className={cnTable("Table-Th")}>
                  <div data-tooltip={t(`activity_log_object_${item.entityType}`)} className="icon tooltip tooltip-left">
                    {mappingObjectToIcon(item.entityType, item.operation)}
                  </div>
                  {/* <div className="object">{t(`activity_log_object_${item.entityType}`)}</div> */}
                </div>
                <div className={cnTable("Table-Th")}>
                  {item.details?.properties?.title ||
                    item.details?.properties?.name ||
                    (item.details.settingName && t(`activity_log_setting_name_${snakeCase(item.details.settingName)}`)) ||
                    item.details?.properties?.username ||
                    item.user?.username ||
                    `${item.user?.firstName} ${item.user?.lastName}`}
                </div>
                <div className={cnTable("Table-Th")}>{t(`activity_log_operation_${item.operation}`)}</div>
                <div className={cnTable("Table-Th")}>
                  {item.user && (
                    <>
                      <span title={item.user.username} className="username">
                        {item.user.username ? (
                          item.user.username
                        ) : (
                          <>
                            {t(`option_${item.user.appeal}`)} {item.user.firstName} {item.user.lastName}
                          </>
                        )}
                      </span>
                      {item.user.role && <span className="user-role">{mapRolesName[item.user.role as ROLE_TYPE]}</span>}
                    </>
                  )}
                </div>
              </ButtonDiv>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ activityLog: { results, chosen } }: RootState) => ({
  chosen,
  results,
});

const mapDispatchToProps = { ActivityLogChose };

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(ActivityLogTable);
