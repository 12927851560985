import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { LoadingContainer } from "components/Generic";
import { cnInfoBox } from "components/InfoBox";

export interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isLoading: boolean;
}

const InfoBox: FC<IProps> = ({ children, className = "", isLoading, ...props }) => {
  return (
    <div className={cnInfoBox()} {...props}>
      <div className={cnInfoBox("Container")}>
        <LoadingContainer isLoading={isLoading}>
          {children}
        </LoadingContainer>
      </div>
    </div>
  );
};

export default InfoBox;