import React, { FC, useEffect, useState } from "react";
import { Button } from "ss-ui";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { FormInput } from "components/Form";
import { FORM_RESET_PASSWORD, LOCAL_STORAGE, removeDataFromLS, Validation } from "core";
import { PropsWithLoading, withLoading } from "hoc";
import { FormValues, Payload as PayloadLogin } from "store/routines/user/resetPassword";
import { RootState } from "store/reducers";
import { resetPassword } from "store/actions";
import { ExpireWindow } from "components/Generic";
import { cnLogin } from "./ForgotPassword";

type Props = PropsWithLoading &
  InjectedFormProps<FormValues> &
  RouteComponentProps<undefined> & {
  isLoading: boolean;
  isDone: boolean;
  resetPassword: (payload: PayloadLogin) => void;
  stateError: string;
};

const ForgotPassword: FC<Props> = ({ change, handleSubmit, isLoading, resetPassword, setIsLoading, isDone, stateError }) => {
  const { t } = useTranslation();
  const [isExpire, setIsExpire] = useState<boolean>(false);
  const [leftTime, setLeftTime] = useState<number>(5);
  const history = useHistory();

  useEffect(() => {
    if (isDone) {
      setIsExpire(true);
      setTimeout(() => redirectToLogin(), 5000);
    }
    setIsLoading(isLoading);
  }, [isLoading, isDone]);

  const redirectToLogin = () => {
    removeDataFromLS(LOCAL_STORAGE.SEND_URL);
    const path = "login";
    history.push(path);
  };

  const handleLogin = (data: any) => {
    resetPassword({
      password: data.password,
    });
  };

  return (
    <>
      {isExpire && (
        <ExpireWindow 
          leftTime={leftTime} 
          setLeftTime={setLeftTime} 
          handleRedirect={redirectToLogin}
          theme="Done"
          contentText={t("expire-window-success")}
          contentButton={t("expire-window-button-now")}
        />
      )}
      <Form className={cnLogin("Form")} onSubmit={handleSubmit(handleLogin)}>
        <div className={cnLogin("FieldsContainer")}>
          <div className={cnLogin("FormHeader")}>
            <h2>{t("forgotPassword_header")}</h2>
          </div>
          <div className={cnLogin("FieldGroup")} id="field_group_forgot_password_password">
            <Field
              className={cnLogin("FieldGroupInput", { "has-error": !!stateError })}
              component={FormInput}
              name="password"
              placeholder={t("forgotPassword_field_password")}
              type="password"
              isShown
              label={t("forgotPassword_field_password")}
              validate={[Validation.minLength8, Validation.required]}
            />
          </div>
          <div className={cnLogin("FieldGroup")} id="field_group_forgot_password_repeat_password">
            <Field
              className={cnLogin("FieldGroupInput", { "has-error": !!stateError })}
              component={FormInput}
              name="repeatPassword"
              isShown
              label={t("forgotPassword_field_repeat_password")}
              placeholder={t("forgotPassword_field_repeat_password")}
              type="password"
              validate={[Validation.passwordsMatch, Validation.required]}
            />
          </div>
          {stateError && <div className={cnLogin("Error")}>{t(stateError)}</div>}
        </div>

        <div className={cnLogin("ButtonContainer")}>
          <Button id="button_forgot_password_confirm" type="submit">
            {t("forgotPassword_confirm")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = ({ user: { isLoading, isDone, error: stateError } }: RootState) => ({
  isLoading,
  stateError,
  isDone,
});

export default compose<Props, Partial<Props>>(
  withLoading,
  withRouter,
  connect(mapStateToProps, { resetPassword }),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_RESET_PASSWORD,
  }),
)(ForgotPassword);
