import { typeGroup } from "core";

/**
 * Get group object from groups list by group id
 * @param groups - array of groups
 * @param id - string ID
 */
export function getGroupFromListByID(groups: typeGroup[], id: string): typeGroup {
  return groups.filter((group: typeGroup) => group.id === id)[0];
}
