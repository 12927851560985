/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { Icon, SearchInput } from "ss-ui";

import { PropsTableWithSearch, TableWithSearch } from "components/Table";

import { FORM_GROUP_MANAGEMENT, getGroupFromListByID, typeGroup } from "core";

import { FormValues } from "store/routines/groups/manage";
import { RootState } from "store/reducers";

export const cnFolders = cn("InfoBoxGroupFolders");

type Props = {
  chosen: string[];
  list: typeGroup[];
} & InjectedFormProps &
  PropsTableWithSearch &
  WrappedFieldArrayProps<any>;

const RenderFoldersList: FC<Props> = ({ chosen, list, setSearch, searchCallback }) => {
  const { t } = useTranslation();
  const isNew = chosen[0] === "new";
  const group: typeGroup = getGroupFromListByID(list, chosen[0]);
  const folderList = group?.folders;
  if (isNew) {
    return <div className={cnFolders("Error")}>{t("groups-Error")}</div>;
  }
  return (
    <>
      {folderList.length > 0 ? (
        <div>
          <div className={cnFolders("Header")}>{t("folders-List")}</div>
          <div className={cnFolders("Search")} id="div_search_input">
            <SearchInput onChange={setSearch} />
          </div>
          <div className={cnFolders("List")}>
            {folderList.filter(searchCallback).map((folder, i) => (
              <div className={cnFolders("List-Item")} key={i}>
                <Icon name={`colored/icon-folder-access-${folder.access}`} />
                <p title={folder.name} className="input-label">{folder.name}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>{t("groups-List-Empty")}</p>
      )}
    </>
  );
};

const mapStateToProps = ({ folders: { treeList, isLoading }, groups: { list, chosen } }: RootState) => ({
  chosen,
  list,
});

const mapDispatchToProps = {};

export default compose<Props, Partial<Props>>(
  TableWithSearch,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_GROUP_MANAGEMENT,
  }),
)(RenderFoldersList);
