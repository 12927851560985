/* eslint-disable no-nested-ternary, no-lonely-if, no-unneeded-ternary */
import React, { FC, useEffect, useMemo, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps, formValueSelector } from "redux-form";
import { get } from "lodash";
import { Icon, Button } from "ss-ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { FormCheckbox, FormInput, FormSelect, FormTextarea, cnField } from "components/Form";
import { ModalFolderSelect } from "components/Generic";
import { cnInfoBoxFolder } from "components/InfoBox";
import { validationsFormFolder } from "core/helpers/validation";
import {
  ACTION,
  FOLDER_ACCESS,
  FOLDER_TYPE,
  FORM_FOLDER_MANAGEMENT,
  getFolderFromTreeById,
  getFolderType,
  optionsLevelSecurity,
  ROLE_TYPE,
  typeFolder,
  foldersForm,
} from "core";
import { favoritesSetFolder, folderManage } from "store/actions";
import { RootState } from "store/reducers";
import { Payload as PayloadFavoritesSetFolder } from "store/routines/favorites/setFolder";
import { FormValuesFolder, Payload as PayloadFolderManage } from "store/routines/folders/manage";

interface iParams {
  folderID: string;
}

type Props = {
  chosen: string[];
  list: typeFolder[];
  role: ROLE_TYPE;
  formData: any;
  isPersonal: boolean;
  folder: typeFolder;
  parentFolderList: any[];
  favoritesSetFolder: (payload: PayloadFavoritesSetFolder) => void;
  folderManage: (payload: PayloadFolderManage) => void;
  setActiveTab: (index: number) => void;
  getParentFolder: (parent: string | null | undefined) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const Settings: FC<Props> = ({
  chosen,
  list,
  role,
  formData,
  isPersonal,
  folder,
  parentFolderList,
  change,
  favoritesSetFolder,
  folderManage,
  handleSubmit,
  setActiveTab,
  getParentFolder,
}) => {
  const { t } = useTranslation();
  const { folderID } = useParams<iParams>();

  const [showAddParentModal, setShowAddParentModal] = useState<boolean>(false);

  const isNew: boolean = useMemo(() => chosen[0] === "new", [chosen]);

  const folderType: FOLDER_TYPE | undefined = useMemo(() => get(folder, "type", undefined), [folder]);
  const isPersonalRootFolder = useMemo(() => folder && folder.type === FOLDER_TYPE.PERSONAL && folder.root === null, [folder]);
  const isFolderGroup = useMemo(() => folder && folder.type === FOLDER_TYPE.GROUP, [folder]);
  const isFolderWritable = useMemo(() => folder && FOLDER_ACCESS.WRITE === folder.access, [folder]);
  const isReadOnly = useMemo(() => folder && [FOLDER_ACCESS.READ, FOLDER_ACCESS.WRITE].includes(folder.access), [folder]);
  const isManagers: boolean = useMemo(() => [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER].includes(role), [role]);
  const isAdmin: boolean = useMemo(() => [ROLE_TYPE.ADMIN].includes(role), [role]);
  const openedFolder: typeFolder = useMemo(() => getFolderFromTreeById(list, folderID), [list, folderID]);
  const disabledField = useMemo(
    () => folder && (!isFolderWritable || (isFolderGroup && role === ROLE_TYPE.USER)),
    [folder, isFolderWritable, isFolderGroup, role],
  );
  const [parentFolder, setParentFolder] = useState<string | null | undefined>(folder?.root || undefined);

  const fieldFoldersGroup = useMemo(
    () =>
      folder &&
      folder.folderGroups?.map(folderGroupItem => {
        return {
          access: folderGroupItem.access,
          group: folderGroupItem.group,
          recursion: folderGroupItem.recursion,
        };
      }),
    [folder],
  );

  useEffect(() => {
    change(
      "level_security",
      isNew
        ? formData.level_security || optionsLevelSecurity[1].value
        : formData.level_security
        ? formData.level_security
        : optionsLevelSecurity[folder && folder.level_security - 1]?.value,
    );
    change("title", isNew ? formData.title : formData.title ? formData.title : folder && folder.title);
    change("webAccess", isNew ? formData.webAccess || false : folder && folder.webAccess);
    change("description", isNew ? formData.description : folder && folder.description);

    if (isPersonal) {
      change("folderGroups", null);
    } else {
      change("folderGroups", isNew ? formData.folderGroups || [] : fieldFoldersGroup);
    }

    if (formData.parent) {
      change("parent", {
        label: formData.parent?.label,
        value: formData.parent?.value,
      });
      setParentFolder(formData.parent?.value);
    } else {
      if (isNew) {
        change("parent", {
          label: openedFolder ? openedFolder.title : t("form_input_select-parent"),
          value: openedFolder ? openedFolder.id : undefined,
        });
        setParentFolder(openedFolder ? openedFolder.id : undefined);
      } else {
        change("parent", {
          label: folder.root ? getFolderFromTreeById(list, folder.root) : t("form_input_select-parent"),
          value: folder.root ? folder.root : undefined,
        });
        setParentFolder(folder.root ? folder.root : undefined);
      }
    }
  }, [chosen]);

  useEffect(() => {
    getParentFolder(parentFolder);
  }, [parentFolder]);

  const handleFavorite = (folderID: string) => {
    favoritesSetFolder({
      folderID,
    });
  };

  const handleParentFolder = (changeValue: string | null) => {
    setParentFolder(changeValue ? changeValue : undefined);
    change("parent", {
      label: changeValue ? getFolderFromTreeById(list, changeValue) : t("modal-root-folder-value"),
      value: changeValue ? changeValue : undefined,
    });
  };

  const handleForm = (values, dispatch, props) => {
    const folderTypeParent: FOLDER_TYPE | boolean = getFolderType(values, list, folder);

    if (Object.keys(props.syncErrors).length > 0 && !isPersonal) {
      setActiveTab(2);
    } else {
      folderManage({
        id: folder && folder.id ? folder.id : undefined,
        action: folder && folder.id ? ACTION.UPDATE : ACTION.CREATE,
        type: folderType ? folderType : folderTypeParent,
        payload: foldersForm(values, isPersonal),
      });
    }
  };

  return (
    <form id="submit-folder-form" onSubmit={handleSubmit(handleForm)} className={cnInfoBoxFolder()}>
      <Field
        autoFocus
        className="Required"
        component={FormInput}
        disabled={folder ? !isFolderWritable || isPersonalRootFolder : false}
        label={t("form_input-Title")}
        name="title"
        placeholder={t("form_input_enter-Title")}
      />
      {!isPersonal && <Field component={FormInput} hidden name="folderGroups" />}
      <Field
        component={FormSelect}
        label={t("form_input-LevelSecurity")}
        placeholder={t("form_input_select-LevelSecurity")}
        name="level_security"
        options={optionsLevelSecurity}
        template={(label: string, value: string): JSX.Element => (
          <div className="ManageKey-Option">
            <Icon name={`icon-dot-${value}`} width={22} />
            {label}
          </div>
        )}
        disabled={disabledField}
      />
      <div className={cnInfoBoxFolder("Textarea", { isDisable: disabledField })}>
        <Field
          component={FormTextarea}
          label={t("form_input-Description")}
          name="description"
          placeholder={t("form_input_enter-Description")}
          isEditor={true}
          disabled={disabledField}
          fieldId={folder ? folder.id : ""}
        />
      </div>
      <Field
        component={FormCheckbox}
        label={t("form_input-WebAccess")}
        help={
          <Trans i18nKey="form_input-WebAccess_help">
            <a
              href="https://chrome.google.com/webstore/detail/kmmndpeiibkjhdkakihdafcodnhgflcp/publish-accepted"
              rel="noopener noreferrer"
              target="_blank"
            >
              PassSecurium™ Browser Extension
            </a>
          </Trans>
        }
        name="webAccess"
        disabled={disabledField}
      />

      {!folder ||
      (folder && folder.root !== null && folder.type === FOLDER_TYPE.PERSONAL) ||
      (folder && folder.type !== FOLDER_TYPE.PERSONAL) ? (
        <div className="Field Field-ParentModal">
          <span className="Label">{t("form_input-parent")}</span>
          <div
            tabIndex={0}
            className={cnField("ParentModal-Input", { disabledField })}
            onKeyDown={() => setShowAddParentModal(!showAddParentModal)}
            onClick={() => setShowAddParentModal(!showAddParentModal)}
            role="button"
          >
            <div className="FolderIcon">
              <Icon width={20} height={20} name="colored/icon-folder" />
            </div>
            {parentFolder ? (
              <span>{getFolderFromTreeById(list, parentFolder).title}</span>
            ) : parentFolder === null ? (
              t("modal-root-folder-value")
            ) : (
              t("form_input_select-parent")
            )}
          </div>
        </div>
      ) : null}

      {!folder ? null : (
        <div className={cnInfoBoxFolder("Info")}>
          <div className={cnInfoBoxFolder("Info-Item")}>
            <p>{t("table_title-Created-date")}</p>
            <span>{folder.createdAt}</span>
          </div>
          {folder.access !== FOLDER_ACCESS.NO_ACCESS && (
            <div className={cnInfoBoxFolder("Info-Item")}>
              <p>{t("navigation_keys")}</p>
              <span>{folder.countKeys}</span>
            </div>
          )}
          {isAdmin && isFolderGroup ? null : (
            <div className={cnInfoBoxFolder("Favorites")}>
              <Button
                theme="primary-link"
                onClick={() => handleFavorite(folder.id)}
                id="btn_handle_folder_favorite"
                type="button"
                disabled={!isReadOnly}
                icon={folder.favorite ? "icon-star-fill" : "icon-star"}
              >
                {folder.favorite ? t("favorites_remove") : t("favorites_add")}
              </Button>
            </div>
          )}
        </div>
      )}

      {showAddParentModal && (
        <ModalFolderSelect
          modalTitle={t("modal-select-parent")}
          activeFolder={parentFolder}
          handleClose={setShowAddParentModal}
          type={folderType || (!isManagers && FOLDER_TYPE.PERSONAL) || FOLDER_TYPE.UNDEFINED}
          checkboxRootFolder={true}
          onChanges={handleParentFolder}
          folderID={chosen[0]}
          accessArr={[FOLDER_ACCESS.WRITE]}
        />
      )}
    </form>
  );
};

const selector = formValueSelector(FORM_FOLDER_MANAGEMENT);

const mapStateToProps = (state: RootState) => ({
  chosen: state.folders.chosen,
  role: state.user.role,
  list: state.folders.list,
  formData: selector(state, "title", "description", "level_security", "parent", "webAccess", "folderGroups"),
});

const mapDispatchToProps = { folderManage, favoritesSetFolder };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValuesFolder>({
    form: FORM_FOLDER_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: validationsFormFolder,
  }),
)(Settings);
