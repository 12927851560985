export enum DIRECTION {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SORT_BY {
  FAVORITE_TITLE = "FAVORITE_TITLE",
  ENTITY_TYPE = "ENTITY_TYPE",
  ACTIVE = "enabled",
  CREATED_DATE = "createdAt",
  UPDATE_DATE = "updatedAt",
  DESCRIPTION = "description",
  EXPIRATION_TIME = "accountExpiration",
  FOLDERS = "folders",
  FULL_NAME = "displayName",
  GROUPS = "members",
  GROUPS_ARR = "groups",
  KEYS = "key",
  MEMBERS = "members",
  NAME = "name",
  ROLE = "roles",
  TITLE = "title",
  TYPE = "type",
  USERS = "users",
  USER_NAME = "username",
  DATE = "date",
  OBJECT = "object",
  OPERATION = "operation",
  USER = "user",
}
