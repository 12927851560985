import { Action } from "redux-actions";
import { pick } from "lodash";

import { typeKey, typeKeyHistory, typeOTP, typeKeySharing, unpackKeys, unpackKey } from "core";

import {
  cleanImportValue,
  deferredImport,
  getKeysPagination,
  getSettings,
  getSharing,
  keyAttached,
  keyChose,
  keyClear,
  keyDelete,
  keyFavorite,
  keyFilesExport,
  keyGet,
  keyGetAllByFolder,
  keyGetLog,
  keyManage,
  keyMove,
  keyMultipleDelete,
  keyPasswordSecurity,
  keyRevert,
  keyValidateByURILogin,
  keysExport,
  keysImport,
  otpDecode,
  setSharing,
  deleteKeySharing,
  showReImportModal,
} from "store/actions";

export interface State {
  accessibleFolders?: typeKeySharing[];
  chosen: string[];
  clearImport?: boolean;
  currentPage: number;
  error?: string;
  errorValue?: string;
  hibpwnedEnabled: boolean;
  history: typeKeyHistory[];
  importCounter: boolean;
  importModalError?: any;
  isGenerated: boolean;
  isLoading: boolean;
  isLoadingOtp: boolean;
  isLoadingManageKey: boolean;
  isLoadingExport: boolean;
  item?: typeKey;
  list: typeKey[];
  otp?: typeOTP;
  pageSize: number;
  pagesCount: number;
  password?: string;
  restrictedFolders?: typeKeySharing[];
  rootSharingFolder?: typeKeySharing;
  showImportModal?: boolean;
  totalItems: number;
  moveSuccess: boolean;
}

const initialState: State = {
  accessibleFolders: [],
  chosen: [],
  clearImport: false,
  currentPage: 0,
  error: undefined,
  errorValue: undefined,
  hibpwnedEnabled: false,
  history: [],
  importCounter: false,
  importModalError: {},
  isGenerated: false,
  isLoading: false,
  isLoadingOtp: false,
  isLoadingExport: false,
  isLoadingManageKey: false,
  item: undefined,
  list: [],
  otp: undefined,
  pageSize: 0,
  pagesCount: 0,
  password: undefined,
  restrictedFolders: [],
  rootSharingFolder: undefined,
  showImportModal: false,
  totalItems: 0,
  moveSuccess: false,
};

export default function keys(state: State = initialState, { payload, type }: Action<any>): State {
  switch (type) {
    case getSharing.TRIGGER:
    case keyAttached.TRIGGER:
    case keyDelete.TRIGGER:
    case keyGet.TRIGGER:
    case keyGetLog.TRIGGER:
    case keyMultipleDelete.TRIGGER:
    case keyPasswordSecurity.TRIGGER:
    case keyRevert.TRIGGER:
    case keyValidateByURILogin.TRIGGER:
    case setSharing.TRIGGER:
    case deleteKeySharing.TRIGGER:  
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };
    
    case otpDecode.TRIGGER:
      return {
        ...state,
        error: undefined,
        isLoadingOtp: true,
      }
    
    case keyMove.TRIGGER:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        moveSuccess: false,
      }

    case keyManage.TRIGGER:
      return {
        ...state,
        error: undefined,
        isLoadingManageKey: true,
      };
    case deferredImport.TRIGGER:
      return {
        ...state,
        importCounter: false,
        isLoading: true,
      };

    case keyFilesExport.TRIGGER:
    case keysExport.TRIGGER:
    case keysImport.TRIGGER:
      return {
        ...state,
        showImportModal: false,
        error: undefined,
        isLoadingExport: true,
        clearImport: false,
      };

    case cleanImportValue.TRIGGER:
      return {
        ...state,
        isLoadingExport: false,
        clearImport: false,
        importCounter: false,
      };

    case getSharing.SUCCESS:
      return {
        ...state,
        accessibleFolders: payload.accessibleFolders,
        restrictedFolders: payload.restrictedFolders,
        rootSharingFolder: payload.mainFolder,
        isLoading: false,
      };

    case deferredImport.SUCCESS:
      return {
        ...state,
        importCounter: true,
        isLoading: true,
      };

    case keyClear.SUCCESS:
      return {
        ...initialState,
      };

    case showReImportModal.SUCCESS:
      if (payload) {
        return {
          ...state,
          showImportModal: true,
          importModalError: payload,
        };
      }
      return {
        ...state,
        showImportModal: false,
        importModalError: null,
        clearImport: true,
      };

    case getSettings.SUCCESS:
      return {
        ...state,
        hibpwnedEnabled: payload.hibpwnedEnabled,
      };

    case keyGetAllByFolder.TRIGGER:
    case getKeysPagination.TRIGGER:
      return {
        ...state,
        list: [],
        chosen: [],
        isLoading: true,
      };

    case keyRevert.SUCCESS:
      return {
        ...state,
        list: [...state.list.map(key => (key.id === payload.id ? unpackKey(payload) : key))],
        isLoading: false,
      };

    case keyDelete.SUCCESS:
      return {
        ...state,
        chosen: [],
        isLoading: false,
        list: state.list.filter(({ id }: typeKey) => id !== payload),
      };

    case keyFavorite.SUCCESS:
      return {
        ...state,
        list: [...state.list.map((key: typeKey) => (key.id === payload.keyID ? { ...key, favorite: payload.status } : key))],
      };

    case keyGetAllByFolder.SUCCESS:
      return {
        ...state,
        list: unpackKeys(payload),
        isLoading: false,
      };

    case getKeysPagination.SUCCESS:
      return {
        ...state,
        ...pick(payload, ["currentPage", "pageSize", "pagesCount", "totalItems"]),
        list: unpackKeys(payload.results),
        isLoading: false,
      };

    case keyMove.SUCCESS:
      return {
        ...state,
        list: state.list.filter((key: typeKey) => payload.indexOf(key.id) === -1),
        chosen: [],
        isLoading: false,
        moveSuccess: true,
      };

    case setSharing.SUCCESS:
      return {
        ...state,
        list: [...state.list.map(key => (key.id === payload.id ? unpackKey(payload) : key))],
        isLoading: false,
      };
    
    case deleteKeySharing.SUCCESS:
      return {
        ...state,
        list: [...state.list.map(key => (key.id === payload.id ? unpackKey(payload) : key))],
        isLoading: false,
      };

    case keyManage.SUCCESS: {
      return {
        ...state,
        isLoadingManageKey: false,
        list: [
          ...state.list.map(key => (key.id === payload.key.id ? unpackKey(payload.key) : key)),
          payload.new && unpackKey(payload.key),
        ].filter(key => !!key),
        totalItems: payload.new ? state.totalItems + 1 : state.totalItems,
      };
    }

    case keyGet.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.findIndex(item => item.id === payload.id) === -1 ? [...state.list, unpackKey(payload)] : state.list,
      };
    }

    case keyAttached.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case keyValidateByURILogin.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case keyChose.SUCCESS: {
      let keys: string[];

      if (payload.oneOnly && payload.status) {
        return {
          ...state,
          chosen: [payload.id],
        };
      }

      if (payload.status) {
        keys = payload.choseAll ? [...payload.ids] : [...state.chosen, payload.id];
      } else {
        keys = payload.choseAll ? [] : [...state.chosen.filter(id => payload.id !== id)];
      }

      return {
        ...state,
        chosen: keys,
      };
    }

    case keyMultipleDelete.SUCCESS:
      return {
        ...state,
        chosen: [],
        isLoading: false,
      };

    case keyGetLog.SUCCESS:
      return {
        ...state,
        history: payload,
        isLoading: false,
      };

    case keyPasswordSecurity.SUCCESS:
      return {
        ...state,
        password: payload.password,
        isLoading: false,
        isGenerated: false,
      };

    case otpDecode.SUCCESS:
      return {
        ...state,
        otp: payload,
        isLoadingOtp: false,
      };

    case keyFilesExport.SUCCESS:
    case keysExport.SUCCESS:
    case keysImport.SUCCESS:
      return {
        ...state,
        isLoadingExport: false,
        clearImport: true,
        importCounter: false,
      };

    case keyAttached.FAILURE:
    case keyChose.FAILURE:
    case keyDelete.FAILURE:
    case keyFilesExport.FAILURE:
    case keyGet.FAILURE:
    case keyGetAllByFolder.FAILURE:
    case keyGetLog.FAILURE:
    case keyManage.FAILURE:
    case keyMultipleDelete.FAILURE:
    case keyPasswordSecurity.FAILURE:
    case keyRevert.FAILURE:
    case getSharing.FAILURE:
    case setSharing.FAILURE:
    case deleteKeySharing.FAILURE:  
    case keyValidateByURILogin.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        isLoadingManageKey: false,
        isLoadingExport: false,
      };
    
    case keyMove.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        isLoadingManageKey: false,
        isLoadingExport: false,
        moveSuccess: true,
      }

    case deferredImport.FAILURE:
      return {
        ...state,
        importCounter: false,
        isLoading: false,
      };

    case getKeysPagination.FAILURE:
      return {
        ...state,
        currentPage: 0,
        pageSize: 0,
        pagesCount: 0,
        totalItems: 0,
        list: [],
        isLoading: false,
      };

    case keysExport.FAILURE:
      return {
        ...state,
        error: payload,
        clearImport: false,
        isLoadingExport: false,
      };

    case keysImport.FAILURE:
      return {
        ...state,
        error: payload,
        isLoadingExport: false,
        clearImport: false,
      };

    case otpDecode.FAILURE:
      return {
        ...state,
        error: payload,
        otp: undefined,
        isLoadingOtp: false,
      };

    default:
      return state;
  }
}
