import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { Action } from "redux-actions";
import { toast } from "react-toastify";
import { http } from "core";
import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("SETTINGS_SET_CUSTOMIZE_MAILER_SWITCH");

export type Payload = {
  mailerEnabledSwitch: boolean;
}

const updateCustomizeMailerSwitch = ({
  mailerEnabledSwitch,
}: Payload) => {

  return http({
    route: "global/mailer/switch",
    method: "POST",
    payload: {
      mailerEnabled: mailerEnabledSwitch,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(updateCustomizeMailerSwitch, payload);

    yield put(action.success(response));

    toast.success(i18n.t("customize_successfully_save"), {
      toastId: "customize_successfully_save"
    });
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
