/* eslint-disable @typescript-eslint/quotes */
import { Action } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { has, snakeCase, get } from "lodash";

import { http } from "core";

import i18n from "locales/i18n";

export interface FormValues {
  password: string;
  username: string;
  hash: string;
}

export const action: Routine = createRoutine("GET_QR_CODE");

const getQRCode = (data: FormValues) => {
  return http({
    method: "POST",
    payload: data,
    route: "get-qr-code",
    isProtected: false,
  });
};

function* handler({ payload }: Action<FormValues>) {
  try {
    const response: string = yield call(getQRCode, payload);
    yield put(action.success(response));
  } catch (error) {
    if (has(error, "response.data.error_description") || i18n.exists(`error_${snakeCase(get(error, "response.data.error_description"))}`)) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.error_description"))}`));
    } else if (i18n.exists(`error_${snakeCase(get(error, "response.data.detail.messages[0]"))}`)) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.detail.messages[0]"))}`));
    } else {
      yield put(action.failure("error_connection"));
    }
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
