import React, { FC, useMemo } from "react";
import { Icon } from "ss-ui";
import { connect } from "react-redux";
import { has, isEmpty, get, find } from "lodash";
import { useTranslation } from "react-i18next";

import { typeKey, FIELD_TYPE, typeTemplate, getTemplateIcon } from "core";

import { otpDecode } from "store/actions";
import { RootState } from "store/reducers";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
  templates: typeTemplate[];
};

const RowTemplate: FC<Props> = ({ item, field, templates }) => {
  const { t } = useTranslation();

  const empty: boolean = useMemo(() => !has(item, field) || isEmpty(get(item, field)), [item, field]);

  const titleTemplate: string | undefined = useMemo(() => {
    if (empty) {
      return undefined;
    }

    return find(templates, ["title", item[field]])?.title;
  }, [field, item]);

  if (empty || !titleTemplate) {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Item")}>
        <div className={cnSettings("Value")} id={`info_box_key_${field}`}>
          <Icon name={`${getTemplateIcon(titleTemplate as string)}`} />
          {t(`key_template_${titleTemplate}`)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ templates: { templates } }: RootState) => ({
  templates,
});

const mapDispatchToProps = { otpDecode };

export default connect(mapStateToProps, mapDispatchToProps)(RowTemplate);
