import React, { FC, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "ss-ui";

import { typeKey, FIELD_TYPE, copyTypeToClipboard, creditCardType, getFieldValue } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
};

function secureCardNumber(number: string): string {
  return number
    .split(" ")
    .map((item, index) => (index === 3 ? item : "••••"))
    .join(" ");
}

const RowCardNumber: FC<Props> = ({ item, field }) => {
  const { t } = useTranslation();

  const [isVisible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    getFieldValue(field, item).then(setValue);
  }, [item[field]]);

  const type: string | undefined = useMemo(
    () => (value && item[field] ? creditCardType(item[field].replace(/\s/g, "")) : undefined),
    [value, item, field],
  );

  if (!value) {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Item")}>
        <div className={cnSettings("Value")} id={`info_box_key_${field}`}>
          <span title={isVisible ? value : secureCardNumber(value)}>{isVisible ? value : secureCardNumber(value)}</span>
        </div>
        <div className={cnSettings("Actions InfoBoxKeySettings-Actions-Right")}>
          <div className={cnSettings("CreditCardTypeIcon")}>{type && <Icon name={`colored/icon-${type}`} />}&nbsp;</div>
          <button
            type="button"
            className={`${cnSettings("ShowPassword tooltip tooltip-left")} ${!isVisible ? "inputShow" : "inputClose"}`}
            data-tooltip={isVisible ? t("tooltip-pass-hide") : t("tooltip-pass-show")}
            onClick={() => setVisible(!isVisible)}
            id={`btn_show_${field}`}
          >
            <Icon name="icon-eye" width={20} height={14} />
          </button>
          &nbsp;
          <div className="tooltip tooltip-left" data-tooltip={t("tooltip-copy")}>
            <Icon onClick={() => copyTypeToClipboard(value, field)} name="icon-copy" id={`btn_copy_to_clipboard_key_${field}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowCardNumber;
