import { typeUser } from "core";

/**
 * Get user object from users list by user id
 * @param users - array of users
 * @param id - string ID
 */
export function getUserFromListByID(users: typeUser[], id: string | undefined): typeUser {
  return users.filter((user: typeUser) => user.id === id)[0];
}

/**
 * Get exist username from Users array list
 * @param users - users array list
 */
export function getFlatUsernameList(users: typeUser[]): string[] {
  return users.map((user: typeUser) => user.username);
}
