import { ROLE_TYPE, mapRolesNameNoTranslate } from "core";

/**
 * Get status access control
 * @param settings: settings access control
 * @param role: string - user role
 */

export function getAccessControl(settings: any, role: ROLE_TYPE) {
    return [ROLE_TYPE.STANDARD_USER, ROLE_TYPE.FREE_USER].includes(role) ? true : settings[mapRolesNameNoTranslate[role].toLowerCase()];
};