import React, { FC } from "react";
import { Icon } from "ss-ui";

import { ButtonDiv } from "components/Generic";

import { DIRECTION, SORT_BY } from "core";

import { cnTable } from "../Table";

type align = "left" | "center" | "right" | "justify" | "char" | undefined;

type Props = {
  align?: align;
  handleSort?: (type?: SORT_BY) => void;
  id?: string;
  sortBy?: SORT_BY;
  sortDirection?: DIRECTION;
  sortInit?: SORT_BY;
};

export type typeTableHeaderElement = {
  align?: align;
  id?: string;
  sortInit?: SORT_BY;
  title: string;
};

const TableHeaderElement: FC<Props> = ({
  children,
  handleSort = () => ({}),
  id = undefined,
  sortBy,
  sortDirection = DIRECTION.ASC,
  sortInit,
  ...props
}) => {
  return (
    <ButtonDiv
      className={cnTable("Head-Th", { active: sortBy && sortBy === sortInit })}
      onClick={() => handleSort(sortInit)}
      id={id}
      {...props}
    >
      <span className={cnTable("ThTitle")}>{children}</span>
      {sortBy && sortBy === sortInit && (
        <Icon className={cnTable("Head-SortIcon", [sortDirection])} name="colored/icon-sort-down" />
      )}
    </ButtonDiv>
  );
};

export default TableHeaderElement;
