/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from "@bem-react/classname";
import React, { FC, useState } from "react";
import { Button, Icon } from "ss-ui";
import { WrappedFieldArrayProps } from "redux-form";
import { useTranslation } from "react-i18next";
import { ModalFolderSelect, cnModal } from "components/Generic";
import { findFolderInTree, FOLDER_ACCESS, FOLDER_TYPE, getFolderFromTreeById, typeFolder, typeGroup } from "core";
import { cnFolders } from "./Folders";
import ExportTreeTest from "./ExportTreeTest";

export const cnTree = cn("GroupFolderTree");

type Props = WrappedFieldArrayProps & {
  treeList: typeFolder[];
  chosenGroup: typeGroup;
};

const renderFolders: FC<Props> = ({ fields, treeList = [], chosenGroup }) => {
  const { t } = useTranslation();

  const groupFoldersList = chosenGroup.folders.map(item => findFolderInTree(treeList, item.folder));
  const groupFolder = groupFoldersList?.map(item => item?.id);
  const [showModalTree, setShowModalTree] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [groupFolderIds, setGroupFolderIds] = useState<(string | undefined)[]>(groupFolder);
  const [newFolderId, setNewFolderId] = useState<string>("");
  const fieldsListIDs = fields.map((folder, index) => {
    const folderID = fields.get(index).folder || undefined;
    return folderID;
  });

  const filterFolder = createTreeFolder => {
    const filterParam = (tree: typeFolder[], id: string): typeFolder[] => {
      return tree.filter((folder: typeFolder) => {
        // eslint-disable-next-line no-param-reassign,
        if (folder.children) folder.children = filterParam(folder.children, id);
        return folder.id !== id;
      });
    };
    const rootFolders = createTreeFolder?.filter(element => element?.root === null);
    const dontRootFolders = createTreeFolder?.filter(
      el => el?.root && groupFolder?.includes(el?.root) === false && findFolderInTree(rootFolders, el?.id) === undefined,
    );
    return [...rootFolders, ...dontRootFolders];
  };

  const [folderList, setFolderList] = useState<typeFolder[]>(filterFolder(groupFoldersList));

  const addFolder = (changeValue: string | null) => {
    const newFolder = getFolderFromTreeById(treeList, changeValue);
    if (newFolder) {
      const defaultFolderData = {
        access: newFolder.access,
        folder: changeValue,
        name: newFolder.title,
        recursion: false,
      };
      setSearch("");
      setGroupFolderIds([...groupFolderIds, newFolder.id]);
      fields.push(defaultFolderData);
      if (!folderList.some(e => e.id === newFolder.id)) {
        setFolderList(filterFolder([...folderList, newFolder]));
      }
      setNewFolderId(newFolder.id);
    } else {
      setSearch("");
    }
  };

  const removeFolder = (value: string, index: any) => {
    setGroupFolderIds(groupFolderIds.filter(item => item !== value));
    fields.remove(index);
  };

  return (
    <div className={cnFolders("Modal-List", { showModalTree })}>
      {fields.length > 0 && (
        <div className={cnFolders("Table")}>
          <div className={cnModal("Search")}>
            <div className={cnModal("Search-Icon")}>
              <Icon name="icon-search" fill="#727272" width={16} height={16} />
            </div>
            <input type="text" id="search" value={search} onChange={e => setSearch(e.target.value)} />
          </div>
          <span>{t("folder-checkbox-include")}</span>
        </div>
      )}
      {folderList &&
        folderList.map(folders => {
          const folder = getFolderFromTreeById(folderList, folders?.id);
          return (
            <ExportTreeTest
              key={folders?.id}
              treeFolder={folder}
              level={0}
              listFolders={treeList}
              groupFolders={groupFolderIds}
              fields={fields}
              handleRemoveFolder={removeFolder}
              activeFolderId={newFolderId}
              search={search}
            />
          );
        })}
      <Button
        id="button_add_folder"
        className={cnFolders("ButtonAdd")}
        icon="icon-plus"
        onClick={() => setShowModalTree(true)}
        theme="primary-link"
        type="button"
      >
        {t("button_add-Folder")}
      </Button>
      {showModalTree && (
        <ModalFolderSelect
          modalTitle={t("modal-select")}
          handleClose={setShowModalTree}
          type={FOLDER_TYPE.GROUP}
          checkboxRootFolder={false}
          onChanges={addFolder}
          folderList={fieldsListIDs}
          accessArr={[FOLDER_ACCESS.READ, FOLDER_ACCESS.WRITE]}
        />
      )}
    </div>
  );
};

export default renderFolders;
