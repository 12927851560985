import { typeUser, ROLE_TYPE } from "core/types";
import { preFirstName } from "core/const";

import { FormValues as typeLocalUserForm } from "store/routines/users/manageLocal";

/**
 * Formatting data from API to application, extraction array roles
 *
 * @param {typeUser[]} users
 * @return typeUser[]
 */
export const usersData = (users: typeUser[]): typeUser[] => {
  return users.map((user: typeUser) => ({ ...user, roles: user.roles[0] as ROLE_TYPE }));
};

export const localUserForm = (data: any, user: typeUser): typeLocalUserForm => {
  const dataToSend: typeLocalUserForm = {
    username: data.username,
    email: data.email,
    roles: data.roles.value || data.roles,
    groups: user?.groups.map(item => item.id) || [],
    locale: data.locale,
    firstName: data.firstName,
    lastName: data.lastName,
    appeal: data.appeal?.value || data.appeal || preFirstName[0].value,
    himselfPassword: data.himself_password ? 1 : 0,
  };

  if (!data.himself_password) {
    dataToSend.password = {
      first: data.password,
      second: data.password,
    };
  }

  return dataToSend;
};
