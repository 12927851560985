import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { get as lodashGet } from "lodash";
import { setDeviceAccessDenied } from "store/actions";

import { http, typeTemplates } from "core";

export const action: Routine = createRoutine("TEMPLATES_GET_ALL");

const getAll = () => {
  return http({ route: "/templates" });
};

function* handler() {
  try {
    const response: typeTemplates = yield call(getAll);

    yield put(action.success(response));
  } catch (error) {
    const errorMessage = lodashGet(error, "response.data.detail.messages", undefined);

    if (errorMessage === "device_access_denied") {
      yield put(setDeviceAccessDenied.failure());
    }
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
