/* eslint-disable func-names */

import { QWebChannel } from "qwebchannel";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

/**
 * Check that browser is simple or desktop
 */
export function isDesktopBrowser(): boolean {
  return typeof window.qt != "undefined";
}

/**
 * Copy text to Clipboard in desktop PS browser
 * @param text string
 * @param timeout number
 */
export const copyToDesktopClipboard = (text: string, timeout: number = 100): string => {
  (() =>
    new QWebChannel(window.qt.webChannelTransport, channel => {
      channel.objects.copy_to_clipboard.copy(text, timeout);
    }))();

  return text;
};

/**
 * Copy text to Clipboard in simple browser
 * @param text string
 */
export const copyToBrowserClipboard = (text: string): string => {
  navigator.clipboard.writeText(text);
  return text;
};

/**
 * Wrapper function to copy text in Clipboard
 * @param text string Text for copy in Clipboard
 */
export function copyToClipboard(text: string): string {
  return isDesktopBrowser() ? copyToDesktopClipboard(text) : copyToBrowserClipboard(text);
}

/**
 * Copy to clipboard and show message about success copy
 *
 * @param copy
 * @param type
 */
export function copyTypeToClipboard (copy: string, type: string) { // "login" | "password" | "url" | "otp"
  copyToClipboard(copy);
  if (type !== ("password" || "login")) {
    toast.info(i18n.t("toast_default_copy"), { toastId: `copy_${type}` });
  } else {
    toast.info(i18n.t(`toast_${type}_copy`), { toastId: `copy_${type}` });
  }
}
