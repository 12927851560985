import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, now } from "lodash";

export type Payload = { id: string; fileName: string; property: string; };

export const action: Routine = createRoutine("KEYS_GET_ATTACHED");

const getAttached = ({ id, property }: Payload) => {
  return http({ route: `/key/${id}/attached/${property}?${now()}`, responseType: "arraybuffer" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(getAttached, payload);

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(new Blob([response]));
    link.download = payload.fileName;
    link.click();

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
