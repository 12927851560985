import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { has, isEmpty, get, now } from "lodash";
import { Button } from "ss-ui";

import { KeysActionsImage } from "components/Generic";

import { typeKey, FIELD_TYPE, http } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
};

const RowImage: FC<Props> = ({ item, field }) => {
  const { t } = useTranslation();

  const [img, setImg] = useState<string>("");

  useEffect(() => {
    if (!isEmpty(get(item, field)) && !get(item, field, "").includes("base64")) {
      fetchImage();
    } else {
      setImg(item[field]);
    }
  }, [item]);

  const fetchImage = async () => {
    const res = await http({ route: `/key/${item.id}/attached/${field}?${now()}`, responseType: "arraybuffer" });
    const blob = new Blob([res], { type: "image/jpeg" });
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64 = reader.result;
      setImg(base64 as string);
    }
  };

  if (!has(item, field) || isEmpty(get(item, field)) || !img) {
    return null;
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Image")} id={`info_box_key_${field}`}>
        <div className="image">{img && <img src={img} alt={field} />}</div>
        <div className="Action">
          {img && (
            <KeysActionsImage value={img} />
          )}
          <Button
            icon="icon-download"
            onClick={() => {
              const downloadLink = document.createElement("a");
              downloadLink.href = img;
              downloadLink.download = field;
              downloadLink.type = "image/jpeg";
              downloadLink.click();
            }}
            theme="primary-link"
          />
        </div>
      </div>
    </div>
  );
};

export default RowImage;
