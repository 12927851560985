/* eslint-disable no-nested-ternary, react-hooks/exhaustive-deps */
import React, { FC, useMemo } from "react";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { snakeCase, castArray, get, find } from "lodash";
import { useTranslation } from "react-i18next";
import { Icon } from "ss-ui";

import { InfoBox, InfoBoxContent, InfoBoxHeader } from "components/InfoBox/index";

import { PayloadChoseFunction, IActivityLogRecord, isEnvDevelop, IActivityLogDeviceAccessControl, optionsTimeRefreshToken } from "core";

import { mappingObjectToIcon } from "pages/ActivityLog/ActivityLogTable";

import { RootState } from "store/reducers";
import { ActivityLogChose } from "store/actions";

import { LogHistoryComponent, LogListComponent, HistoryDetailsProperty } from "./Component";

export const cnInfoBoxActivityLog = cn("InfoBoxActivityLog");

type Props = {
  ActivityLogChose: (payload: PayloadChoseFunction) => void;
  chosen: string[];
  isLoading: boolean;
  results: any[];
};

const InfoBoxActivityLog: FC<Props> = ({ chosen, isLoading, results, ActivityLogChose }) => {
  const { t } = useTranslation();

  const item: IActivityLogRecord = useMemo(() => results.filter(item => item.id === chosen[0])[0], [chosen]);

  const userNameLocal = useMemo(() => `${item.user?.firstName} ${item.user?.firstName}`, [item]);

  const handleClose = () => {
    ActivityLogChose({ status: false, choseAll: true });
  };

  return (
    <InfoBox isLoading={isLoading}>
      <InfoBoxHeader title={t("activity_log_entry_detail")} iconName="icon-file-with-gear" handleClose={handleClose} />
      <InfoBoxContent>
        <div className={cnInfoBoxActivityLog("Details")}>
          {item.createdAt && item.createdAt.date && (
            <div className={cnInfoBoxActivityLog("Detail")}>
              <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_table_header_date")}</div>
              <div className={cnInfoBoxActivityLog("Value", ["bold"])}>
                <div className="icon">
                  <Icon name="icon-calendar" />
                </div>
                <div className="object">
                  {item.createdAt.date} {item.createdAt.time}
                  {/* {item.createdAt.date}&nbsp;
                  {item.createdAt.time && (
                    <>
                      {t("activity_log_label_at")} {item.createdAt?.time}
                    </>
                  )} */}
                </div>
              </div>
            </div>
          )}

          <div className={cnInfoBoxActivityLog("Detail")}>
            <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_object")}</div>
            <div className={cnInfoBoxActivityLog("Value", ["blue", "bold"])}>
              <div className="icon">{mappingObjectToIcon(item.entityType as string, item.operation)}</div>
              <div className="object">{t(`activity_log_object_${item.entityType}`)}</div>
            </div>
          </div>

          <div className={cnInfoBoxActivityLog("Detail")}>
            <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_table_header_title")}:</div>
            <div className={cnInfoBoxActivityLog("Value", ["bold"])}>
              <div className="object">
                {
                  item.details?.properties?.title ||
                  item.details?.properties?.name ||
                  (
                    item.details.settingName &&
                    t(`activity_log_setting_name_${snakeCase(item.details.settingName)}`)
                  ) ||
                  item.details?.properties?.username ||
                  item.user?.username || userNameLocal
                }
              </div>
            </div>
          </div>

          <div className={cnInfoBoxActivityLog("Detail")}>
            <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_operation")}</div>
            <div className={cnInfoBoxActivityLog("Value", ["bold"])}>{t(`activity_log_operation_${item.operation}`)}</div>

            {item.operation === "move" && item.details && (
              <LogHistoryComponent from={item.details.fromFolder?.title as string} to={item.details.toFolder?.title as string} />
            )}
          </div>

          {item.details && item.details.keysCount && item.details.keysCount !== 0 ? (
            <div className={cnInfoBoxActivityLog("Detail")}>
              <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_keys_count")}</div>
              <div className={cnInfoBoxActivityLog("Value")}>{item.details.keysCount}</div>
            </div>
          ) : null}

          {item.details && item.details.attachedUsers && (
            <LogListComponent title={t("activity_log_attached_users")} list={item.details.attachedUsers} />
          )}
          {item.details && item.details.detachedUsers && (
            <LogListComponent title={t("activity_log_detached_users")} list={item.details.detachedUsers} />
          )}
          {item.details && item.details.attachedGroups && (
            <LogListComponent title={t("activity_log_attached_groups")} list={item.details.attachedGroups} />
          )}
          {item.details && item.details.detachedGroups && (
            <LogListComponent title={t("activity_log_detached_groups")} list={item.details.detachedGroups} />
          )}
          {item.details && item.details.includedFolders && (
            <LogListComponent title={t("activity_log_included_folders")} list={item.details.includedFolders} />
          )}
          {item.details && item.details.excludedFolders && (
            <LogListComponent title={t("activity_log_excluded_folders")} list={item.details.excludedFolders} />
          )}

          {item.details && item.details.properties && <HistoryDetailsProperty properties={item.details.properties} />}

          {item.details &&
            item.details.changedProperties &&
            castArray(item.details.changedProperties).map(properties => (
              <div className={cnInfoBoxActivityLog("Detail")} key={properties.property}>
                <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_changed_property")}</div>
                {get(item, "details.settingName", false) && (
                  <div className={cnInfoBoxActivityLog("Value")}>
                    {t(`activity_log_setting_name_${snakeCase(item.details.settingName)}`)}
                  </div>
                )}
                <div className={cnInfoBoxActivityLog("Value")}>{t(`activity_log_property_${snakeCase(properties.property)}`)}</div>
                {["access"].includes(properties.property) && (
                  <LogHistoryComponent from={t(`groups-${properties.from}`) as string} to={t(`groups-${properties.to}`) as string} />
                )}
                {!["access"].includes(properties.property) && <LogHistoryComponent from={properties.from} to={properties.to} />}
              </div>
            ))}

          {item.details && item.details.settingName && typeof item.details.from !== "object" && typeof item.details.to !== "object" && (
            <div className={cnInfoBoxActivityLog("Detail")}>
              <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_changed_property")}</div>
              <div className={cnInfoBoxActivityLog("Value")}>{t(`activity_log_setting_name_${snakeCase(item.details.settingName)}`)}</div>
              <LogHistoryComponent from={item.details.from} to={item.details.to} />
            </div>
          )}

          {item.details &&
            item.details.settingName &&
            item.details.settingName === "device_access_control" &&
            typeof item.details.from === "object" &&
            typeof item.details.to === "object" && (
              <div className={cnInfoBoxActivityLog("Detail")}>
                <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_changed_property")}</div>
                <div className={cnInfoBoxActivityLog("Value")}>{t(`activity_log_setting_name_${snakeCase(item.details.settingName)}`)}</div>
                {(item.details.from as IActivityLogDeviceAccessControl).accessToken !==
                  (item.details.to as IActivityLogDeviceAccessControl).accessToken && (
                  <LogHistoryComponent
                    from={`${(item.details.from as IActivityLogDeviceAccessControl).accessToken / 60} Min.`}
                    to={`${(item.details.to as IActivityLogDeviceAccessControl).accessToken / 60} Min.`}
                  />
                )}
                {(item.details.from as IActivityLogDeviceAccessControl).refreshToken !==
                  (item.details.to as IActivityLogDeviceAccessControl).refreshToken && (
                  <LogHistoryComponent
                    from={
                      find(optionsTimeRefreshToken, ["value", (item.details.from as IActivityLogDeviceAccessControl).refreshToken])?.label
                    }
                    to={find(optionsTimeRefreshToken, ["value", (item.details.to as IActivityLogDeviceAccessControl).refreshToken])?.label}
                  />
                )}
              </div>
            )}

          {item.user && (
            <div className={cnInfoBoxActivityLog("Detail")}>
              <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_by_user")}</div>
              <div className={cnInfoBoxActivityLog("Value", ["blue", "bold"])}>
                {item.user.username ? (
                  item.user.username
                ) : (
                  <>
                    {t(`option_${item.user.appeal}`)} {item.user.firstName} {item.user.lastName}
                  </>
                )}
              </div>
            </div>
          )}

          {isEnvDevelop() && <pre>ONLY FOR DEVELOPMENT: {JSON.stringify(item, null, 1)}</pre>}
        </div>
      </InfoBoxContent>
    </InfoBox>
  );
};

const mapStateToProps = ({ activityLog: { chosen, isLoading, results } }: RootState) => ({
  results,
  chosen,
  isLoading,
});

const mapDispatchToProps = {
  ActivityLogChose,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoBoxActivityLog);
