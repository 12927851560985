import { all, fork } from "redux-saga/effects";

import { saga as getAll } from "store/routines/templates/getAll";
import { saga as setDeviceAccessDenied } from "store/routines/templates/deviceAccessDenied";

export default function* manager() {
  return yield all([
    fork(getAll),
    fork(setDeviceAccessDenied),
  ]);
}
