import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, has } from "lodash";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

import { getConfig } from "store/actions";

export type Payload = {
  id: string;
};

export const action: Routine = createRoutine("USERS_DELETE");

const remove = ({ id }: Payload) => {
  return http({ route: `manager/user/${id}`, method: "DELETE" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    yield call(remove, payload);

    toast.success(i18n.t("toast_user_success_remove", { count: 1 }));

    yield put(action.success(payload.id));

    yield put(getConfig.trigger());
  } catch (error) {
    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    if (has(error, "response.data.detail.messages")) {
      yield put(action.failure(get(error, "response.data.detail.messages[0]")));
    }
    yield put(action.failure(get(error, "response.data.detail.messages", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
