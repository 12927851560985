/* eslint-disable react/jsx-no-target-blank, react/no-danger,  react/jsx-key */
import React, { 
  FC, 
  useEffect, 
  // useMemo 
} from "react";
import moment from "moment";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  DATE_FORMAT,
  // FOLDER_ACCESS,
  // getFolderFromTreeByIdForSharing,
  // getKey,
  getKeyFromArrayByID,
  parseDate,
  typeFolder,
  typeKey,
  typeKeyHistory,
  validateURL,
} from "core";

// import { Payload as PayloadKeyRevert } from "store/routines/keys/revert";
import { Payload as PayloadKeyGetLog } from "store/routines/keys/getLog";
import { RootState } from "store/reducers";
import { keyGetLog, keyRevert } from "store/actions";
// import { useParams } from "react-router";

const cnHistory = cn("InfoBoxKeyHistory");

// interface iParams {
//   folderID: string;
// }

type Props = {
  chosen: string[];
  history: typeKeyHistory[];
  // keyRevert: (payload: PayloadKeyRevert) => void;
  keyGetLog: (payload: PayloadKeyGetLog) => void;
  list: typeKey[];
  listFolders?: typeFolder[];
};

const History: FC<Props> = ({ 
  keyGetLog, 
  list, 
  chosen, 
  history, 
  // keyRevert, 
  listFolders = [] 
}) => {
  const key = getKeyFromArrayByID(list, chosen[0]);

  const { t } = useTranslation();
  // const { folderID } = useParams<iParams>();
  // const isSharingKey = useMemo(() => folderID && folderID === key?.mainFolderId, [folderID, key]);
  // const folder: typeFolder = useMemo(() => getFolderFromTreeByIdForSharing(listFolders, key?.mainFolderId), [listFolders, key]);
  // const isFolderWritable = useMemo(() => folder && FOLDER_ACCESS.WRITE === folder.access, [folder]);

  useEffect(() => {
    if (key && key.id) {
      keyGetLog({ id: key.id });
    }
  }, [key, keyGetLog]);

  // const handleRevert = (version: number) => {
  //   if (key && key.id && version) {
  //     keyRevert({
  //       id: key.id,
  //       version,
  //     });
  //   }
  // };

  return (
    <div className={cnHistory()}>
      <div className={cnHistory("List")}>
        {history &&
          history.map(({ id, loggedAt, data, action, version, revert }: typeKeyHistory, index) => {
            let circleClass: string = "Yellow";
            let actionIcon;

            switch (action) {
              case "update":
                circleClass = "Blue";
                actionIcon = <Icon width={16} height={16} name="icon-pencil" />;
                break;
              case "create":
                circleClass = "Green";
                actionIcon = <Icon width={16} height={16} name="icon-plus" />;
                break;
              case "delete":
                circleClass = "Red";
                actionIcon = <Icon width={16} height={16} name="icon-trash" />;
                break;
              case "restore":
                circleClass = "Yellow";
                actionIcon = <Icon width={16} height={16} name="icon-shared" />;
                break;
            }

            return (
              <div className={cnHistory("Item")} key={id}>
                <div className={cnHistory("Icon")}>
                  <div className={cnHistory(`Circle Circle-${circleClass}`)}>{actionIcon}</div>
                </div>
                <div className={cnHistory("Box")}>
                  <div className={cnHistory("Info")}>
                    <div className="action">
                      <p className={cnHistory("Mobile-Left")}>
                        {t("history-Was")} {t(`history-${action}`)}&nbsp;
                        <span className={cnHistory("Mobile-Inline")}>{moment(parseDate(loggedAt)).format(DATE_FORMAT)}</span>
                      </p>
                    </div>
                  </div>
                  <div className={cnHistory("Edit")}>
                    {data.title && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-Title")}:</div>
                        <div title={data.title} className={cnHistory("Value")}>{data.title}</div>
                      </div>
                    )}
                    {data.description && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-Description")}:</div>
                        <div className={cnHistory("Value")}>
                          <span className={cnHistory("Value-Description")} dangerouslySetInnerHTML={{ __html: data.description }} />
                        </div>
                      </div>
                    )}
                    {data.uri && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-Url")}:</div>
                        <div className={cnHistory("Value")}>
                          <a title={data.uri} target="_blank" href={validateURL(data.uri)}>
                            {data.uri}
                          </a>
                        </div>
                      </div>
                    )}
                    {data.login && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-Login")}:</div>
                        <div title={data.login} className={cnHistory("Value")}>{data.login}</div>
                      </div>
                    )}
                    {data.password && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-Password")}:</div>
                        <div className={cnHistory("Value")}>********</div>
                      </div>
                    )}
                    {data.otp && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-OTP")}:</div>
                        <div className={cnHistory("Value")}>{data.otp}</div>
                      </div>
                    )}
                    {data.fileName && (
                      <div className={cnHistory("Field")}>
                        <div className={cnHistory("Label")}>{t("form_input-Attached")}:</div>
                        <div title={data.fileName.split("_")[1]} className={cnHistory("Value")}>{data.fileName.split("_")[1]}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className={cnHistory("Actions")}>
                  {isSharingKey && isFolderWritable && revert && (
                    <button
                      type="button"
                      data-tooltip={t("tooltip-undo")}
                      className="btn-revert tooltip tooltip-left"
                      onClick={() => handleRevert(version)}
                      id={getKey("btn_revert_history", index)}
                    >
                      <Icon name="icon-history" fill="#A7A7A8" width={16} height={16} />
                    </button>
                  )}
                </div> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ folders: { list: listFolders }, keys: { history, chosen, list } }: RootState) => ({
  chosen,
  history,
  list,
  listFolders,
});

const mapDispatchToProps = { keyGetLog, keyRevert };

export default connect(mapStateToProps, mapDispatchToProps)(History);
