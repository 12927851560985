import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "ss-ui";
import { connect } from "react-redux";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import { cn } from "@bem-react/classname";
import { FORM_GLOBAL_OTP, http, removeSpaces, updateOnInputString, Validation } from "core";
import { get as lodashGet, snakeCase } from "lodash";
import { FormInput, FormSwitch } from "components/Form";
import { Modal, DisplayQRCode } from "components/Generic";
import { cnSettings } from "pages/Settings";
import { RootState } from "store/reducers";
import { setGlobalSettings, getProfile } from "store/actions";
import { GetProfileOTPResponse } from "store/routines/user/getProfile";
import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { compose } from "recompose";

export const cnOTPAdmin = cn("OTPAdmin");

type OTPForm = {
  otp: string;
};

type Props = InjectedFormProps<OTPForm> & {
  globalOtp?: boolean;
  otp?: GetProfileOTPResponse;
  getProfile: () => void;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const GlobalOTP: FC<Props> = ({
  globalOtp = false,
  otp,
  setGlobalSettings,
  getProfile,
  handleSubmit,
  reset,
}) => {
  const { t } = useTranslation();
  const [modalVerifyOTP, setModalVerifyOTP] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (modalVerifyOTP) {
      reset()
      setFocus();
    }
  }, [modalVerifyOTP]);

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleUpdateGlobalOtp = (value: boolean) => {
    if (value) {
      if (otp && otp.enabled) {
        setGlobalSettings({
          name: "OTP_GLOBAL",
          value: true,
        });
      } else {
        setModalVerifyOTP(true);
      }
    } else {
      setGlobalSettings({
        name: "OTP_GLOBAL",
        value: false,
      });
    }
  };

  async function enable2FA(otp: string) {
    try {
      const response: any = await http({
        route: "user/otp/enable",
        method: "PUT",
        payload: { otp },
      });
      if (response.otp.enabled) {
        setGlobalSettings({
          name: "OTP_GLOBAL",
          value: true,
        });
        setModalVerifyOTP(false);
        getProfile();
      }
    } catch (error) {
      toast.error(t(`toast_enabled_otp_error_${snakeCase(lodashGet(error, "response.data.detail.messages[0]", undefined))}`));
    }
  }

  const handleForm = ({ otp }: OTPForm) => enable2FA(otp);

  return (
    <div className={ cnSettings("Container") }>
      <div className={ cnSettings("SwitchItem") }>
        <div className={ cnSettings("Header") }>
          {t("settings_global_otp_header")}
          <div className={ cnSettings("Switch") }>
            <FormSwitch
              fieldName="settingsGlobalOtp"
              handleToggle={ () => handleUpdateGlobalOtp(!globalOtp) }
              isOn={ globalOtp }
            />
            {globalOtp ? <p>{t("settings_switch_on")}</p> : <p className={ cnSettings("Switch-Disable") }>{t("settings_switch_off")}</p>}
          </div>
        </div>
        <div className={ cnSettings("Hint") }>{t("settings_global_otp_hits")}</div>
        {modalVerifyOTP && (
          <Modal
            className={ cnOTPAdmin("QRCodeModal") }
            handleClose={ () => setModalVerifyOTP(false) }
          >
            <header>{t("profile_modal_qr_code_header")}</header>
            <p>{t("profile_modal_qr_code_text")}</p>
            <DisplayQRCode value={ otp?.url }/>
            <div className="otp-key">{otp?.key}</div>

            <Form onSubmit={ handleSubmit(handleForm) }>
              <label>{t("profile_modal_qr_code_label")}</label>
              <Field
                autoFocus
                isShown
                component={ FormInput }
                inputRef={ inputRef }
                name="otp"
                placeholder={ t("form_input-OTP") }
                type="password"
                validate={ [Validation.required] }
                onInput={ (e: any) => updateOnInputString(e, removeSpaces) }
              />
              <Button
                id="button_profile_modal_qr_code_button"
                type="submit"
                onClick={ setFocus }
              >
                {t("profile_modal_qr_code_button")}
              </Button>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings: { globalOtp }, user: { otp } }: RootState) => ({
  globalOtp,
  otp,
});

const mapDispatchToProps = {
  setGlobalSettings,
  getProfile,
};

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<OTPForm>({
    form: FORM_GLOBAL_OTP,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(GlobalOTP);
