import React, { FC, useEffect } from "react";
import { Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";

import { FORM_CUSTOMERS_FOOTER, Validation } from "core";

import { FormInput } from "components/Form";

import { Payload as payloadSetCustomize } from "store/routines/settings/setCustomize";
import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";
import { setCustomize, setHeaderIcon, setHeaderTitle } from "store/actions";

export const cnCustomize = cn("Customize");

export const defaultCustomValue = {
  footerEn: "Your Pass Securium Team",
  footerDe: "Ihr Pass Securium Team",
};

type Props = {
  customize: SettingsCustomizeState;
  setCustomize: (payload: payloadSetCustomize) => void;
  setHeaderIcon: (payload: string) => void;
  setHeaderTitle: (payload: string) => void;
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const CustomizeFooterMail: FC<Props> = ({ customize, handleSubmit, setCustomize, setHeaderIcon, setHeaderTitle, change }: Props) => {
  const { t } = useTranslation();

  /**
   * UseEffect update customize
   */
  useEffect(() => {
    change("footerEn", customize.footerEn);
    change("footerDe", customize.footerDe);
  }, [customize]);

  /**
   * Send update Footer info
   */
   const handleFormFooter = (data: any) => {
    setCustomize({
      footerEn: data.footerEn,
      footerDe: data.footerDe,
    });
  };

  /**
   * Reset to default Company info
   */
   const handleResetFooter = () => {
    setCustomize({
      footerEn: defaultCustomValue.footerEn,
      footerDe: defaultCustomValue.footerDe,
    });
  };

  return (
    <div className={cnCustomize("Sections")}>
      <div className={cnCustomize("Section")}>
        <div className={cnCustomize("Header")}>
          <span className={cnCustomize("Header-Name")}>{t("customize_footer_label_company_info")}</span>
          <Button theme="danger-link" size={30} icon="icon-refresh" onClick={handleResetFooter}>
            {t("customize_btn_reset_to_default")}
          </Button>
        </div>
        <form id="submit-company-footer" onSubmit={handleSubmit(handleFormFooter)}>
          <div className={cnCustomize("Content")}>
            <div className={cnCustomize("Content-Left")}>
              <Field
                component={FormInput}
                label={t("customize_footer_en")}
                name="footerEn"
                placeholder={t("customize_footer_en")}
                validate={[Validation.emoji, Validation.required, Validation.maxLength100]}
              />
            </div>
            <div className={cnCustomize("Content-Right")}>
              <Field
                component={FormInput}
                label={t("customize_footer_de")}
                name="footerDe"
                placeholder={t("customize_footer_de")}
                validate={[Validation.emoji, Validation.required, Validation.maxLength100]}
              />
            </div>
          </div>
          <div className={cnCustomize("Content-Submit")}>
            <Button form="submit-company-footer" type="submit" className="mr20">
              {t("customize_btn_save")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {
  setCustomize,
  setHeaderIcon,
  setHeaderTitle,
};

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<payloadSetCustomize>({
    form: FORM_CUSTOMERS_FOOTER,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(CustomizeFooterMail);
