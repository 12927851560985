import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { Action } from "redux-actions";

export const action: Routine = createRoutine("USERS_GET_AVATAR");

export interface Payload {
  id: string;
}

const request = ({ id }: Payload) => {
  return http({ route: `user/avatar/${id}`, responseType: "arraybuffer" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(request, payload);
    const base64 = Buffer.from(response, "binary").toString("base64");
    yield put(action.success({
      id: payload.id,
      avatarFile: base64 ? `data:image/jpeg;base64,${base64}` : null
    }));
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
