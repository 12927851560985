import React, { FC, useEffect, useMemo, useRef } from "react";
import { Icon, Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { Link, useHistory, useParams } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { reduxForm, InjectedFormProps, Form, Field } from "redux-form";
import { useTranslation } from "react-i18next";

import LayoutAnonymous from "components/Layout/LayoutAnonymous/LayoutAnonymous";
import { FormInput } from "components/Form";
import { cnModal, Modal, DisplayQRCode } from "components/Generic";

import { FORM_GET_QR_CODE, removeSpaces, updateOnInputString, Validation, Routes, getKey, MainRoutes, removeDataFromLS, LOCAL_STORAGE } from "core";

import { withLoading, PropsWithLoading } from "hoc";

import { cnLogin } from "pages/Login/Login";

import { RootState } from "store/reducers";
import { confirmOTPActivation, getQRCode, OTPHashActivation } from "store/actions";
import { FormValues as FormValuesGetQRCode } from "store/routines/user/getQRCode";
import { FormValues as FormValuesConfirmOTPActivation } from "store/routines/user/confirmOTPActivation";

export const cnGettingQRCode = cn("GettingQRCode");

export type gettingQRCodeForm = {
  otp: string;
  password: string;
};

type Props = PropsWithLoading &
  InjectedFormProps<gettingQRCodeForm> & {
    confirmOTPActivation: (payload: FormValuesConfirmOTPActivation) => void;
    getQRCode: (payload: FormValuesGetQRCode) => void;
    isLoading: boolean;
    OTPHashActivation: (payload: { hash: string }) => void;
    QRCode?: string;
    stateError?: string;
    username?: string;
  };

const GettingQRCode: FC<Props> = ({
  confirmOTPActivation,
  change,
  getQRCode,
  handleSubmit,
  isLoading,
  OTPHashActivation,
  QRCode,
  setIsLoading,
  stateError,
  username,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const secretQR = useMemo(() => QRCode && decodeURIComponent(QRCode).match(/secret=(\w{16})/)?.[1], [QRCode]);
  const isOTPDisabled = useMemo(() => stateError === "error_otp_is_disabled", [stateError]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { hash } = useParams<{ hash: string }>();

  useEffect(() => {
    if (stateError) {
      change("password", null);
      change("otp", null);
    }
  }, [stateError]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (hash) {
      removeDataFromLS(LOCAL_STORAGE.USER);
      OTPHashActivation({ hash });
    }
  }, [hash]);

  const handleForm = ({ otp, password }: gettingQRCodeForm) => {
    if (username) {
      if (otp) {
        confirmOTPActivation({ otp, hash, username });
      } else {
        getQRCode({ password, hash, username });
      }
    }
  };

  const redirectToLogin = () => {
    history.push(MainRoutes.Login);
  };

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <LayoutAnonymous>
      {!isLoading && !username && (
        <Modal isCloseable={false} className={cnModal("ModalFolderSelect")}>
          <div className={cnModal("Scroll Modal-Vpn")}>
            <h3>{t("getting_qr_code_invalid_link_title")}</h3>
            <p>{t("getting_qr_code_invalid_link_text")}</p>
          </div>
          <div className={cnModal("Actions")}>
            <Button onClick={redirectToLogin} type="button">
              {t("getting_qr_code_btn_login_page")}
            </Button>
          </div>
        </Modal>
      )}
      {username && (
        <Form className={cnLogin("Form")} onSubmit={handleSubmit(handleForm)} id="page_login_form">
          <div className={cnLogin("FormHeader")}>
            <Icon name="icon-key-block" />
            <p>{t("getting_qr_code_header")}</p>
          </div>
          {QRCode && !isOTPDisabled && (
            <>
              <div className={cnGettingQRCode("QRImage")}>
                <DisplayQRCode value={QRCode} />
              </div>
              <div className="otp-key">{secretQR}</div>
              <div className={cnGettingQRCode("QRText")}>
                <label>{t("profile_modal_qr_code_label")}</label>
              </div>
            </>
          )}
          {stateError && (
            <div className={cnLogin("Error")}>
              <Icon fill="#E3000B" name="icon-warning" />
              {t(stateError)}
            </div>
          )}

          {!isOTPDisabled && (
            <div className={cnLogin("FieldsContainer")}>
              <Field
                autoFocus
                isShown
                component={FormInput}
                inputRef={inputRef}
                name={QRCode ? "otp" : "password"}
                placeholder={!QRCode ? t("login_user_pass") : t("form_input-OTP")}
                type="password"
                validate={[Validation.required]}
                onInput={(e: any) => updateOnInputString(e, removeSpaces)}
              />
            </div>
          )}
          <div className={cnLogin("ButtonContainer")} id="page_login_button_container">
            {isOTPDisabled ? (
              <Link to={Routes.Login} id={getKey("link_to_login")}>
                <Button id="button_login_ps" type="button" width="100%">
                  {t("getting_qr_code_btn_success")}
                </Button>
              </Link>
            ) : (
              <Button
                id="button_login_ps"
                type="submit"
                width="100%"
                onClick={setFocus}
              >
                {t("getting_qr_code_btn")}
              </Button>
            )}
          </div>
        </Form>
      )}
    </LayoutAnonymous>
  );
};

const mapStateToProps = ({ user: { isLoading, error: stateError, QRCode, username } }: RootState) => ({
  isLoading,
  QRCode,
  stateError,
  username,
});

export default compose<Props, Partial<Props>>(
  withLoading,
  connect(mapStateToProps, { getQRCode, OTPHashActivation, confirmOTPActivation }),
  reduxForm<gettingQRCodeForm>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_GET_QR_CODE,
  }),
)(GettingQRCode);
