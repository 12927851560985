/* eslint-disable react-hooks/exhaustive-deps, no-nested-ternary, react/no-array-index-key, jsx-a11y/click-events-have-key-events, import/extensions, jsx-a11y/no-static-element-interactions, array-callback-return, react/self-closing-comp, @typescript-eslint/quotes */
import { cn } from "@bem-react/classname";

import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FOLDER_ACCESS, getFolderFromTreeById, getIDsActiveFolder, getKey, typeFolder } from "core";
import { Field, FieldArrayFieldsProps } from "redux-form";
import { ButtonDiv, FolderStatus } from "components/Generic";
import { FolderIcon, Icon } from "ss-ui";
import Highlighter from "react-highlight-words";
import { FormCheckbox, FormSelect } from "components/Form";

export const cnTree = cn("GroupFolderTree");

type Props = {
  treeFolder: typeFolder;
  level: number;
  listFolders: typeFolder[];
  groupFolders: (string | undefined)[];
  fields: FieldArrayFieldsProps<any>;
  handleRemoveFolder: (id: string, index: any) => void;
  activeFolderId: string;
  search?: string;
};

const ExportTreeTest: FC<Props> = ({
  handleRemoveFolder,
  treeFolder,
  level = 0,
  listFolders,
  groupFolders,
  fields,
  activeFolderId,
  search = "",
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const isFolderTree = useMemo(() => groupFolders && groupFolders.includes(treeFolder.id), [groupFolders, treeFolder.id]);
  const isActive = useMemo(() => groupFolders && groupFolders[groupFolders.length - 1] === treeFolder.id, [groupFolders, treeFolder.id]);
  const optionAccess = [
    {
      label: t("folder-asses-write"),
      value: "write",
    },
    {
      label: t("folder-asses-read"),
      value: "read",
    },
    {
      label: t("folder-asses-no_access"),
      value: "no_access",
    },
  ];

  const handleClickFolder = (value: boolean) => {
    setOpen(!value);
  };

  const openDisableFolder = (treeFolder: typeFolder) => {
    treeFolder.children.map(item => {
      if (groupFolders.includes(item.id)) {
        setOpen(true);
      } else {
        openDisableFolder(item);
      }
    });
  };

  useEffect(() => {
    if (!isFolderTree && treeFolder.children.length > 0) {
      if (treeFolder.children.length > 0) {
        openDisableFolder(treeFolder);
      }
    }
  }, [treeFolder]);

  const removeFolder = (id: string, index: any) => {
    handleRemoveFolder(id, index);
  };

  const test = (folderId, result: string[] = []) => {
    const folder = getFolderFromTreeById(listFolders, folderId);
    result.push(folder.title);
    if (folder.root !== null) {
      test(folder.root, result);
    }
    return result.reverse().join(" / ");
  };

  const activeTreeFolder = getIDsActiveFolder(listFolders, activeFolderId);

  useEffect(() => {
    activeTreeFolder.pop();
    if (activeTreeFolder.includes(treeFolder.id)) {
      setOpen(true);
    }
  }, [activeFolderId]);

  return (
    <>
      {search === "" ? (
        <div className={cnTree({ level })}>
          <div className={cnTree("Folder", { isFolderTree, isActive })}>
            <div className={cnTree("Icon")}>
              <ButtonDiv
                className={cnTree("OpenButton")}
                key={treeFolder.id}
                onClick={() => handleClickFolder(isOpen)}
                id={getKey(treeFolder.title, level)}
              >
                {treeFolder.children && treeFolder.children.length > 0 ? (
                  <FolderStatus isActive={isOpen} />
                ) : (
                  <div className="EmptyFolderStatus">&nbsp;</div>
                )}
              </ButtonDiv>
              <div className={cnTree("Title")} title={level === 0 && treeFolder.root !== null ? test(treeFolder.root, []) : ""}>
                <FolderIcon level={treeFolder.level_security} size={30} isGroup={true} />
                <span title={treeFolder.title}>{treeFolder.title}</span>
              </div>
            </div>
            {isFolderTree && (
              <div className={cnTree("Form")}>
                {fields.map((folder, index) => {
                  const folderID = fields.get(index).folder || undefined;
                  const isDisabled = fields.get(index).recursion === null;
                  const isAccess = treeFolder.access && treeFolder.access === FOLDER_ACCESS.WRITE;
                  if (treeFolder.id !== folderID) {
                    return null;
                  }

                  return (
                    <div key={getKey("Folder", index)} className={cnTree("Form-Item")}>
                      <div className={cnTree("Form-Item-Access")}>
                        <Field
                          name={`${folder}.access`}
                          className={cnTree("Form-Item-Access-Select")}
                          isSearchable={false}
                          component={FormSelect}
                          disabled={!isAccess}
                          options={optionAccess}
                          template={(label: string, value: string): JSX.Element => (
                            <div className="ManageKey-Option">
                              <span className={cnTree(`Form-Item-Access-Icon GroupFolderTree-Form-Item-Access-Icon-${value}`)} />
                              <p className={cnTree("Form-Item-Access-Icon-Text")}>{label}</p>
                            </div>
                          )}
                        />
                      </div>
                      <div className={cnTree("Form-Item-Recursion")}>
                        <Field name={`${folder}.recursion`} component={FormCheckbox} disabled={!isAccess || isDisabled} />
                      </div>
                      {isAccess && !isDisabled ? (
                        <div className={cnTree("Form-Item-Remove")}>
                          <button
                            type="button"
                            title="Remove Hobby"
                            onClick={() => removeFolder(folderID, index)}
                            id="btn_trash_folder_from_export"
                          >
                            <Icon name="icon-trash" width={16} height={16} fill="#E3000B" />
                          </button>
                        </div>
                      ) : (
                        <div className={cnTree("Form-Item-Remove")}></div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {isOpen &&
            treeFolder.children.length > 0 &&
            treeFolder.children.map((folder: typeFolder & { onChange?: (value: any) => void }) => (
              <ExportTreeTest
                key={folder?.id}
                treeFolder={folder}
                level={level + 1}
                listFolders={listFolders}
                groupFolders={groupFolders}
                fields={fields}
                handleRemoveFolder={handleRemoveFolder}
                activeFolderId={activeFolderId}
                search={search}
              />
            ))}
        </div>
      ) : (
        <div className={cnTree("")}>
          {treeFolder.title.toLowerCase().indexOf(search.toLowerCase()) === -1 ? (
            ""
          ) : (
            <div className={cnTree("Folder", { isFolderTree, isActive })} key={treeFolder.id} id={getKey(treeFolder.title, level)}>
              <div className={cnTree("Icon")}>
                <div className={cnTree("Title")} title={level === 0 && treeFolder.root !== null ? test(treeFolder.root, []) : ""}>
                  <FolderIcon level={treeFolder.level_security} size={30} isGroup={true} />
                  <Highlighter highlightClassName={cnTree("SearchHighLight")} searchWords={[search]} textToHighlight={treeFolder.title} />
                </div>
              </div>
              {isFolderTree && (
                <div className={cnTree("Form")}>
                  {fields.map((folder, index) => {
                    const folderID = fields.get(index).folder || undefined;
                    const isDisabled = fields.get(index).recursion === null;
                    const isAccess = treeFolder.access && treeFolder.access === FOLDER_ACCESS.WRITE;
                    if (treeFolder.id !== folderID) {
                      return null;
                    }

                    return (
                      <div key={getKey("Folder", index)} className={cnTree("Form-Item")}>
                        <div className={cnTree("Form-Item-Access")}>
                          <Field
                            name={`${folder}.access`}
                            className={cnTree("Form-Item-Access-Select")}
                            isSearchable={false}
                            component={FormSelect}
                            disabled={!isAccess}
                            options={optionAccess}
                            template={(label: string, value: string): JSX.Element => (
                              <div className="ManageKey-Option">
                                <span className={cnTree(`Form-Item-Access-Icon GroupFolderTree-Form-Item-Access-Icon-${value}`)} />
                                <p className={cnTree("Form-Item-Access-Icon-Text")}>{label}</p>
                              </div>
                            )}
                          />
                        </div>
                        <div className={cnTree("Form-Item-Recursion")}>
                          <Field name={`${folder}.recursion`} component={FormCheckbox} disabled={!isAccess || isDisabled} />
                        </div>
                        {isAccess && !isDisabled ? (
                          <div className={cnTree("Form-Item-Remove")}>
                            <button
                              type="button"
                              title="Remove Hobby"
                              onClick={() => removeFolder(folderID, index)}
                              id="btn_trash_folder_from_export_2"
                            >
                              <Icon name="icon-trash" width={16} height={16} fill="#E3000B" />
                            </button>
                          </div>
                        ) : (
                          <div className={cnTree("Form-Item-Remove")}></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {treeFolder.children.map((folder: typeFolder & { onChange?: (value: any) => void }) => (
            <ExportTreeTest
              key={folder?.id}
              treeFolder={folder}
              level={level + 1}
              listFolders={listFolders}
              groupFolders={groupFolders}
              fields={fields}
              handleRemoveFolder={handleRemoveFolder}
              activeFolderId={activeFolderId}
              search={search}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ExportTreeTest;
