import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";
import { PayloadChoseFunction } from "core";
import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("KEYS_CHOSE");

function* handler({ payload }: Action<PayloadChoseFunction>) {
  try {
    yield put(action.success(payload));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
