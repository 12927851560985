/* eslint-disable react/jsx-curly-brace-presence */
import React, { FC, useState, useEffect } from "react";
import { Icon } from "ss-ui";
import { useTranslation } from "react-i18next";
import { cnField } from "components/Form";
import { Filter, cnFilter } from "components/Layout";
import { AsyncPaginate } from "react-select-async-paginate";
import { IGroupsFilter, http, typeFolder, FOLDER_ACCESS, FOLDER_TYPE, getFolderFromTreeById, } from "core";
import { ModalFolderSelect } from "components/Generic";

type GroupsFilterProps = {
  filters: IGroupsFilter;
  listFolders: typeFolder[];
  handleFilter: () => void;
  handleFilterCancel: () => void;
  setFilters: (filters: IGroupsFilter) => void;
  setIsShow: (status: boolean) => void;
};

const GroupsFilter: FC<GroupsFilterProps> = ({
  filters,
  listFolders,
  handleFilter,
  setFilters,
  setIsShow,
  handleFilterCancel,
}) => {
  const { t } = useTranslation();

  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
  const [valueUsersFilter, setValueUsersFilter] = useState<any>(filters.users);
  const [showFolderSelectMulti, setShowFolderSelectMulti] = useState<boolean>(false);

  useEffect(() => {
    setFilters({ ...filters, users: valueUsersFilter });
  }, [valueUsersFilter]);

  const templateType = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-group-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  const customStyles = {
    control: (provided: any, state) => ({
      ...provided,
      border: "0",
      borderWidth: "0",
      boxShadow: state.isFocused ? "0 5px 15px #80CFF1" : "0 5px 15px rgba(0, 0, 0, 0.16)",
      fontSize: "14px",
      height: "40px",
      minHeight: "40px",
      padding: "0 0 0 16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      cursor: "pointer",
    }),
    placeholder: (provided: any) => ({
      ...provided,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0",
    }),
    menuList: (provided: any) => ({
      ...provided,
      height: "150px",
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#80CFF1",
    }),
  };

  const handleFilterClear = () => {
    setValueUsersFilter([]);
    handleFilterCancel();
  }

  const handleRemoveUser = (id: string) => {
    setValueUsersFilter(valueUsersFilter.filter((item: any) => item.value !== id));
  };

  const handleAddFolders = (value: string | null) => {
    setFilters({ ...filters, folders: value?.split(",") })
  };

  const handleRemoveFolders = (value: string) => {
    setFilters({ ...filters, folders: filters.folders?.filter(item => item !== value) })
  }

  return (
    <Filter handleFilterCancel={handleFilterClear} filterClose={(value: boolean) => setIsShow(value)} handleFilter={handleFilter}>
      <div className={cnFilter("Field")}>
        <label>{t("search-Name")}</label>
        <input
          onChange={e => setFilters({ ...filters, search: e.target.value })}
          className={cnFilter("Field-Search")}
          placeholder={t("search-Name")}
          type="text"
          value={filters.search}
          id="page_group_search_input"
        />
      </div>


      <div className={cnFilter("Field")}>
        <div className={cnFilter("ParentModal")}>
          <label>{t("folder_filters_box_label_folder")}</label>
          <div
            tabIndex={0}
            className={cnFilter("ParentModal-Input")}
            onKeyDown={() => setShowFolderSelectMulti(!showFolderSelectMulti)}
            onClick={() => setShowFolderSelectMulti(!showFolderSelectMulti)}
            role="button"
          >
            <div className="FolderIcon">
              <Icon width={20} height={20} name="colored/icon-folder" />
            </div>
            <span>{t("button_Select-Folder")}</span>
          </div>
        </div>

        <div className={cnFilter("FoldersLIst")}>
          {filters.folders &&
            filters.folders.map((id, i) => {
              const folder = getFolderFromTreeById(listFolders, id);
              return (
                <div key={folder.id} className={cnFilter("SelectItem")}>
                  <div className={cnFilter("SelectItem-Name")}>
                    <Icon name="colored/icon-folder" width={20} height={20} /> <span>{folder.title}</span>
                  </div>
                  <button type="button" onClick={() => handleRemoveFolders(folder.id)} id="btn_handle_remove_folder">
                    <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
                  </button>
                </div>
              );
            })}
        </div>
      </div>

      <div className={cnFilter("Field")}>
        <label>{t("folder_filters_box_label_user")}</label>
        <AsyncPaginate
          className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
          classNamePrefix={cnFilter("Select")}
          placeholder={t("folder_filters_box_placeholder_user")}
          controlShouldRenderValue={false}
          isSearchable={true}
          value={valueUsersFilter}
          menuPlacement="top"
          isMulti={true}
          styles={customStyles}
          onChange={(el) => setValueUsersFilter(el)}
          closeMenuOnSelect={false}
          hideSelectedOptions={true}
          formatOptionLabel={templateType}
          loadOptions={async selectSearchUsers => {
            let params: { currentPage: number; pageSize: number; search?: string; } = {
              currentPage: currentPageUsers,
              pageSize: 20,
              search: selectSearchUsers,
            };
            if (currentPageUsers) params = { ...params, currentPage: currentPageUsers };
            if (selectSearchUsers !== "") params = { ...params, search: selectSearchUsers, currentPage: 1 };
            const response: any = await http({ route: "manager/users", method: "GET", payload: { params } });
            setCurrentPageUsers(response.currentPage + 1);
            return {
              hasMore: currentPageUsers < response.pagesCount,
              options: response.results.map(user => ({
                label: user.username,
                value: user.id,
              })),
            };
          }}
        />

        {filters.users.map((item) => (
          <div key={item.value} className={cnFilter("SelectItem")}>
            <div className={cnFilter("SelectItem-Name")}>
              <Icon name="icon-user-circle" width={20} height={20} /> <span>{item.label}</span>
            </div>
            <button type="button" onClick={() => handleRemoveUser(item.value)} id="btn_handle_remove_user">
              <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
            </button>
          </div>
        ))}
      </div>

      {showFolderSelectMulti && (
        <ModalFolderSelect
          modalTitle={t("button_Select-Folder")}
          handleClose={() => setShowFolderSelectMulti(false)}
          onChanges={handleAddFolders}
          isMulti={true}
          accessArr={[FOLDER_ACCESS.WRITE]}
          type={FOLDER_TYPE.UNDEFINED}
          folderList={filters.folders ? filters.folders : []}
          filterFolders={true}
        />
      )}

    </Filter>
  );
};

export default GroupsFilter;
