/* eslint-disable jsx-a11y/click-events-have-key-events, object-shorthand,  prefer-const, jsx-a11y/no-static-element-interactions, react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from "react";
import { Checkbox, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { FormSwitch } from "components/Form";

import { cnTable } from "components/Table";
import { toast } from "react-toastify";

import { mapRolesName, PayloadChoseFunction, typeUser, Routes } from "core";

import { PropsWithChosen, PropsWithLoading, WithChosen, withLoading } from "hoc";

import { Payload as PayloadSetClickedLink } from "store/routines/settings/setClickedLink";
import { RootState } from "store/reducers";
import { Payload as PayloadUsersControlOTP } from "store/routines/users/controlOTP";
import { setClickedLink, usersChose, usersControlOTP } from "store/actions";

const cnTableUsers = cn("TableUsersLocal");

type Props = PropsWithLoading &
PropsWithChosen & {
  chosen: string[];
  listItem: typeUser;
  globalOtp?: boolean;
  setClickedLink: (payload: PayloadSetClickedLink) => void;
  usersChose: (payload: PayloadChoseFunction) => void;
  usersControlOTP: (payload: PayloadUsersControlOTP) => void;
};

const TableUsersLocalItem: FC<Props> = ({
  chosen,
  handleUpdateChosenItems,
  listItem,
  globalOtp,
  setClickedLink,
  usersChose,
  usersControlOTP,
}) => {
  const { t } = useTranslation();

  const [itemOtp, setItemOtp] = useState<boolean>(listItem ? listItem.otp : false);
  const [itemStatus, setItemStatus] = useState<number | any>(listItem ? listItem.status : 0);
  const [itemDisable, setItemDisable] = useState<boolean>(listItem ? listItem.blocked : false);

  useEffect(() => {
    setItemOtp(listItem ? listItem.otp : false);
    setItemStatus(listItem ? listItem.status : 0);
    setItemDisable(listItem ? listItem.blocked : false);
  }, [listItem]);

  const handleChosen = (id: string, tab: number) => {
    handleUpdateChosenItems(usersChose, !(chosen.indexOf(id) >= 0), id, true);
    setClickedLink({ clickedLink: tab });
  };

  const handleOTP = (itemId: string) => {
    usersControlOTP({
      id: itemId,
      action: !itemOtp ? "enable" : "disable",
    });
    setItemOtp(!itemOtp);
  };

  const handleOTPDisable = () => {
    toast.info(
      <Trans i18nKey="toast_global_otp_info">
        <a href={ Routes.settings.Security }> </a>
      </Trans>,
      { toastId: "global_otp_error" },
    );
  };

  const handleSwitch = () => globalOtp ? handleOTPDisable() : handleOTP(listItem.id);

  return (
    <div
      key={ listItem.id }
      className={ cnTable("Row", {
        chosen: chosen.indexOf(listItem.id) >= 0,
        hidden: itemDisable,
      }) }
    >
      <div className={ cnTableUsers("Checkbox Table-Th") }>
        <Checkbox
          checked={ chosen.indexOf(listItem.id) >= 0 }
          onChange={ (status: boolean) => handleUpdateChosenItems(usersChose, status, listItem.id) }
        />
      </div>
      <div className={ cnTableUsers("Icon Table-Th") }>
        <Icon
          height={ 28 }
          name="icon-user-circle"
          width={ 28 }
        />
      </div>
      <div
        className={ cnTableUsers("Title Table-Th") }
        onClick={ () => handleChosen(listItem.id, 1) }
      >
        <div className={ cnTableUsers("Title-Column") }>
          <p>{`${listItem.firstName} ${listItem.lastName}`}</p>
          <span>{listItem.email}</span>
        </div>
      </div>

      <div className={ cnTableUsers("Status Table-Th") }>
        <div className={ `${cnTableUsers("Status-Item")} ${cnTableUsers(`Status-Item-${itemStatus}`)}` }>
          <span>{t(`table_user_local_status_${itemStatus}`)}</span>
        </div>
      </div>

      <div className={ cnTableUsers("Role Table-Th") }>{mapRolesName[listItem.roles]}</div>

      <div className={ cnTableUsers("Otp Table-Th") }>
        {globalOtp && !itemOtp ? (
          <div
            className="Field-FakeSwitch"
            onClick={ () => handleOTPDisable() }
          />
        ) : (
          <FormSwitch
            disabled={ globalOtp }
            fieldName={ listItem.id }
            handleToggle={ handleSwitch }
            isOn={ itemOtp }
          />
        )}
      </div>

      <div
        className={ cnTableUsers("Groups Table-Th") }
        onClick={ () => handleChosen(listItem.id, 3) }
      >
        <div className={ cnTableUsers("Groups-Item", { isEmpty: listItem.groups.length === 0 }) }>
          <Icon name="icon-group" />
          <span>{listItem.groups.length}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  users: { list, chosen },
  settings: { globalOtp },
}: RootState) => ({
  chosen,
  list,
  globalOtp,
});

const mapDispatchToProps = {
  usersChose,
  setClickedLink,
  usersControlOTP,
};

export default compose<Props, Partial<Props>>(WithChosen, withLoading, connect(mapStateToProps, mapDispatchToProps))(TableUsersLocalItem);
