import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { mapRolesName, ROLE_TYPE } from "core";

import { cnInfoBoxActivityLog } from "./InfoBoxActivityLog";

export const handlingBoolean = (val: any, ret: [string, string]) => {
  if (typeof val !== "boolean") {
    return val;
  }
  return val ? ret[0] : ret[1];
};

type PropsLogListComponent = {
  title: string;
  list: any[];
};

export const LogListComponent: FC<PropsLogListComponent> = ({ title, list }) => {
  return (
    <div className={cnInfoBoxActivityLog("Detail")}>
      <div className={cnInfoBoxActivityLog("Label")}>{title}</div>
      <div className={cnInfoBoxActivityLog("Value")}>
        <ul className={cnInfoBoxActivityLog("ValueList")}>
          {list.map(item => (
            <li key={item.id}>{item.name || item.title || item.username}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

type PropsLogHistoryComponent = {
  from?: string | boolean | number;
  to?: string | boolean | number;
};

export const LogHistoryComponent: FC<PropsLogHistoryComponent> = ({ from, to }) => {
  const { t } = useTranslation();

  if (from === undefined || to === undefined) {
    return null;
  }
  return (
    <div className={cnInfoBoxActivityLog("History")}>
      <div className="line" />
      <div className="info">
        <div className="from">{handlingBoolean(from, [t("activity_log_enabled"), t("activity_log_disabled")]) || t("activity_log_empty")}</div>
        <div className="to">{handlingBoolean(to, [t("activity_log_enabled"), t("activity_log_disabled")]) || t("activity_log_empty")}</div>
      </div>
    </div>
  );
};

type PropsHistoryDetailsProperty = {
  properties: { [key: string]: string | boolean | null };
};

const clearProperty = (properties: { [key: string]: string | boolean | null }) =>
  Object.keys(properties).filter(key => {
    /**
     * Filter all field with id in key name and encryptionEnabled
     */
    if (key.toLowerCase().indexOf("id") >= 0) {
      return false;
    }
    if (key === "encryptionEnabled") {
      return false;
    }
    /**
     * Check if empty
     */
    if (properties[key] === null || properties[key] === undefined || properties[key] === "") {
      return false;
    }

    return true;
  });

export const HistoryDetailsProperty: FC<PropsHistoryDetailsProperty> = ({ properties }) => {
  const { t } = useTranslation();

  if (clearProperty(properties).length === 0) {
    return null;
  }

  return (
    <div className={cnInfoBoxActivityLog("Detail")}>
      <div className={cnInfoBoxActivityLog("Label")}>{t("activity_log_label_properties")}</div>
      <div className={cnInfoBoxActivityLog("Value")}>
        <ul className={cnInfoBoxActivityLog("ValueList")}>
          {clearProperty(properties).map(key => (
            <li key={key}>
              {t(`activity_log_property_${key}`)}:&nbsp;
              {key === "role"
                ? mapRolesName[properties[key] as ROLE_TYPE]
                : handlingBoolean(properties[key], [t("activity_log_enabled"), t("activity_log_disabled")])}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
