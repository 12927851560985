import { cn } from "@bem-react/classname";

export const cnField = cn("Field");

export { default as FormAvatar } from "./Avatar";
export { default as FormCheckbox } from "./Checkbox";
export { default as FormDatePicker } from "./DatePicker";
export { default as FormFile } from "./File";
export { default as FormInput } from "./Input";
export { default as FormInputMask } from "./InputMask";
export { default as FormLazySelect } from "./LazySelect";
export { default as FormMultiSelectOutsideRender } from "./MultiSelectOutsideRender";
export { default as FormRadio } from "./Radio";
export { default as FormSelect } from "./Select";
export { default as FormSwitch } from "./Switch";
export { default as FormTextarea } from "./Textarea";
