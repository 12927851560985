import React, { FC } from "react";
import { cnField } from "components/Form";

type Props = {
  disabled?: boolean;
  fieldName?: string;
  isOn: boolean;
  handleToggle?: (value: boolean) => void;
};

const FormSwitch: FC<Props> = ({ disabled, isOn, handleToggle, fieldName }) => {
  return (
    <div
      className={ cnField("Switch", { "Disabled": disabled }) }
      id={ `input_switch_${fieldName}_container` }
    >
      <input
        checked={ isOn }
        className={ cnField("Switch-Input") }
        id={ fieldName }
        name={ fieldName }
        type="checkbox"
        onChange={ () => handleToggle ? handleToggle(!isOn) : null }
      />
      <label
        className={ cnField("Switch-Label") }
        htmlFor={ fieldName }
        id={ `input_switch_${fieldName}_label` }
      >
        <span className={ cnField("Switch-Button") } />
      </label>
    </div>
  );
};

export default FormSwitch;
