/* eslint-disable prefer-const */
import Color from "color";

/**
 * Function for replace main color for document
 * @param {string} color
 */
export function changeMainColor(color: string) {
  const dark: string = color;
  const darkLightness: string = Color({ hex: dark }, "hex").lighten(0.12).hex();

  const lightValue: string = Color({ hex: dark }, "hex").lighten(0.717).hex();
  const light: string = lightValue === "#FFFFFF" ? Color({ hex: dark }, "hex").lighten(0.4).hex() : lightValue;
  const lightLightness: string = Color({ hex: light }, "hex").lighten(0.072).hex();

  const blue01value: string = Color({ hex: light }, "hex").lighten(0.33313).hex();
  const blue01: string = blue01value === "#FFFFFF" ? Color({ hex: dark }, "hex").lighten(0.8).hex() : blue01value;
  const blue01Lightness: string = Color({ hex: light }, "hex").lighten(0.05).hex();


  document.documentElement.style.setProperty("--ps-blue-dark", dark);
  document.documentElement.style.setProperty("--ps-blue-dark-lightness-5", darkLightness);
  document.documentElement.style.setProperty("--ps-blue-light", light);
  document.documentElement.style.setProperty("--ps-blue-light-lightness-5", lightLightness);
  document.documentElement.style.setProperty("--ps-blue-01", blue01);
  document.documentElement.style.setProperty("--ps-blue-01-lightness-5", blue01Lightness);
}

/**
 * Function for replace sub color for document
 * @param {string} color
 */
export function changeSubColor(color: string) {
  const gray3: string = color;
  const gray3Lightness: string = Color({ hex: gray3 }, "hex").lighten(0.05).hex();

  // More light
  const gray2: string = Color({ hex: gray3 }, "hex").lighten(0.37).hex();
  const gray2Lightness: string = Color({ hex: gray2 }, "hex").lighten(0.05).hex();

  // More dark
  const gray4: string = Color({ hex: gray3 }, "hex").darken(0.316).hex();
  const gray4Lightness: string = Color({ hex: gray4 }, "hex").lighten(0.1189).hex();

  document.documentElement.style.setProperty("--ps-gray-04", gray4);
  document.documentElement.style.setProperty("--ps-gray-04-lightness-5", gray4Lightness);
  document.documentElement.style.setProperty("--ps-gray-03", gray3);
  document.documentElement.style.setProperty("--ps-gray-03-lightness-5", gray3Lightness);
  document.documentElement.style.setProperty("--ps-gray-02", gray2);
  document.documentElement.style.setProperty("--ps-gray-02-lightness-5", gray2Lightness);
}
