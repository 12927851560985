/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from "react";
import { cn } from "@bem-react/classname";

export const cnFolderStatus = cn("FolderStatus");

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
  isActive?: boolean;
};

const FolderStatus: FC<Props> = ({ isActive = false,  ...rest }) => {
  return <div className={cnFolderStatus({ isActive })} {...rest}>&nbsp;</div>;
};

export default FolderStatus;
