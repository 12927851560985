/* eslint-disable no-nested-ternary */
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FormSwitch } from "components/Form";
import { cnSettings } from "pages/Settings";
import { PRODUCT_TYPE } from "core";
import { Payload as PayloadGlobalSettings } from "store/routines/settings/setGlobalSettings";
import { RootState } from "store/reducers";
import { setGlobalSettings, setBestIcon } from "store/actions";
import { Payload as PayloadSetBestIcon } from "store/routines/settings/setBestIcon";

type Props = {
  offlineMode?: boolean;
  productType: PRODUCT_TYPE;
  offlineModeFree?: boolean;
  setBestIcon: (payload: PayloadSetBestIcon) => void;
  setGlobalSettings: (payload: PayloadGlobalSettings) => void;
};

const OfflineModeControl: FC<Props> = ({
  offlineMode = true,
  productType,
  offlineModeFree = true,
  setGlobalSettings,
  setBestIcon
}) => {
  const { t } = useTranslation();

  const isBusiness: boolean = useMemo(() => [PRODUCT_TYPE.ENTERPRISE, PRODUCT_TYPE.CORPORATE].includes(productType), [productType]);

  const handleToggle = (value: boolean) => {
    if (isBusiness) {
      setGlobalSettings({
        name: "OFFLINE_MODE_SETTINGS",
        value,
      });
    } else {
      setBestIcon({
        offlineModeValue: value,
      });
    }
  };

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")} id="container_hunt_password_services">
        <div className={cnSettings("Header")}>
          {t("settings_header_offline_mode")}
          <div className={cnSettings("Switch")}>
            <FormSwitch isOn={isBusiness ? offlineMode : offlineModeFree} handleToggle={handleToggle} fieldName="offlineModeControl" />
            {isBusiness ? (
              offlineMode ? (
                <p>{t("settings_switch_on")}</p>
              ) : (
                <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
              )
            ) : (
              offlineModeFree ? (
                <p>{t("settings_switch_on")}</p>
              ) : (
                <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
              )
            ) }
          </div>
        </div>
        <div className={cnSettings("Hint")}>
          {t("settings_hint_offline_mode_all")}&nbsp;
          {isBusiness && (
            t("settings_hint_offline_mode_business")
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ settings: { offlineMode, offlineModeFree, productType } }: RootState) => ({
  offlineMode,
  offlineModeFree,
  productType
});

const mapDispatchToProps = {
  setGlobalSettings,
  setBestIcon
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineModeControl);
