import { Action } from "redux-actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { createRoutine, Routine } from "redux-saga-routines";
import { toast } from "react-toastify";

import { http } from "core";

import i18n from "../../../locales/i18n";

export const action: Routine = createRoutine("USER_UPDATE_YUBIKEY_ENABLE");

export type Payload = {
  action: boolean;
};

const updateYubikeyEnable = ({ action }: Payload) => {
  return http({
    route: `user/yubikeys/status/${action ? "enable" : "disable"}`,
    method: "PUT",
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(updateYubikeyEnable, payload);

    toast.success(i18n.t(`toast_${payload.action ? "enable" : "disable"}_yubikey_success`));

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure());
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
