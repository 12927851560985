import Color from "color";
import React, { FC, useMemo } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { FormSwitch } from "components/Form";
import { LoadingContainer } from "components/Generic";
import { customSelectStyles } from "components/Form/Select";

import { FormatOptionsResetPass, FormatOptionsRemind, REMIND_FORMAT, DAYS_FORMAT } from "pages/Settings/SettingsType";
import { cnSettings } from "pages/Settings";
import { defaultCustomValue } from "pages/Settings/Customize/Customize";

import { Payload as PayloadSetResetPasswordSettings } from "store/routines/settings/setResetPasswordSettings";
import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";
import { SettingsResetPasswordState } from "store/reducers/settings/resetPassword";
import { setResetPasswordSettings } from "store/actions";

type Props = {
  customize: SettingsCustomizeState;
  resetPassword: SettingsResetPasswordState;
  setResetPasswordSettings: (payload: PayloadSetResetPasswordSettings) => void;
};

const PasswordExpiration: FC<Props> = ({ customize, resetPassword, setResetPasswordSettings }) => {
  const { t } = useTranslation();

  const color: string = useMemo(() => customize.colorMain || defaultCustomValue.primaryColor, [customize.colorMain]);

  const handleUpdateResetPassword = (value: boolean) => {
    setResetPasswordSettings({
      resetPassValue: value,
    });
  };

  const setResetPassDays = (value: DAYS_FORMAT) => {
    if (value !== resetPassword.days) {
      setResetPasswordSettings({
        resetPassDays: value,
      });
    }
  };

  const setResetPassRemind = (value: REMIND_FORMAT) => {
    if (value !== resetPassword.remind) {
      setResetPasswordSettings({
        resetPassRemind: value,
      });
    }
  };

  return (
    <div className={cnSettings("Container")}>
      <LoadingContainer isLoading={resetPassword.isLoading}>
        <div className={cnSettings("SwitchItem")} id="container_pass_reset">
          <div className={cnSettings("Header")}>
            {t("reset_pass_settings_title")}
            <div className={cnSettings("Switch")}>
              <FormSwitch isOn={resetPassword.enabled} handleToggle={handleUpdateResetPassword} fieldName="resetPassword" />
              {resetPassword.enabled ? (
                <p>{t("settings_switch_on")}</p>
              ) : (
                <p className={cnSettings("Switch-Disable")}>{t("settings_switch_off")}</p>
              )}
            </div>
          </div>
          <div className={cnSettings("Hint")}>{t("reset_pass_settings_info")}</div>
          <div className={cnSettings("Switch Settings-Switch-Row")}>
            {resetPassword.enabled && (
              <div className={cnSettings("Select-Box")}>
                <div className={cnSettings("Select")}>
                  <label>{t("reset_pass_settings_month")}</label>
                  <Select
                    className={cnSettings("PassSelect")}
                    value={FormatOptionsResetPass.find(x => x.value === resetPassword.days)}
                    isClearable={false}
                    isSearchable={false}
                    id="select_pass_reset"
                    options={FormatOptionsResetPass}
                    onChange={selected => selected && setResetPassDays(selected.value)}
                    styles={customSelectStyles({ isMultiple: false }, color)}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary25: "#EAFBFD",
                        primary75: color,
                        primary50: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
                        primary: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
                      },
                    })}
                  />
                </div>
                <div className={cnSettings("Select")}>
                  <label>{t("reset_pass_settings_days")}</label>
                  <Select
                    className={cnSettings("PassSelect")}
                    value={FormatOptionsRemind.find(x => x.value === resetPassword.remind)}
                    isClearable={false}
                    isSearchable={false}
                    options={FormatOptionsRemind}
                    onChange={selected => selected && setResetPassRemind(selected.value)}
                    styles={customSelectStyles({ isMultiple: false }, color)}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary25: "#EAFBFD",
                        primary75: color,
                        primary50: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
                        primary: Color(color).alpha(0.5).lighten(0.3).hsl().string(),
                      },
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </LoadingContainer>
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { resetPassword, customize } }: RootState) => ({
  customize,
  resetPassword,
});

const mapDispatchToProps = {
  setResetPasswordSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiration);
