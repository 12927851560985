/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { has, isEmpty, get, find } from "lodash";
import { Icon } from "ss-ui";

import { typeKey, FIELD_TYPE, copyTypeToClipboard, typeOption, getFieldValue } from "core";

import { cnSettings } from "../Settings";

type Props = {
  item: typeKey;
  field: FIELD_TYPE;
  options: typeOption[];
};

const RowTextFromSelect: FC<Props> = ({ item, field, options }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>();

  const selectLabel: string | undefined = useMemo(() => {
    if (!has(item, field) || isEmpty(get(item, field))) {
      return undefined;
    }

    return find(options, ["value", value])?.label;
  }, [value]);

  useEffect(() => {
    getFieldValue(field, item).then(setValue);
  }, [item[field]]);

  if (!selectLabel || !value) {
    return null;
  }

  const switchValueField = (fieldName: string) => {
    if (value) {
      switch (fieldName) {
        case "id_card_type":
          return t(`options_id_type_${value}`);
        case "country":
          return t(`options_country_${value}`);
        case "place_issue":
          return t(`options_country_${value}`);
        default:
          return value;
      }
    } else {
      return "copy";
    }
  }

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("Label")}>{t(`manage_key_field_${field}_label`)}</div>
      <div className={cnSettings("Item")}>
        <div className={cnSettings("Value")} id={`info_box_key_${field}`}>
          {selectLabel}
        </div>
        <div className={cnSettings("Actions InfoBoxKeySettings-Actions-Right")}>
          <div className="tooltip tooltip-left" data-tooltip={t("tooltip-copy")}>
            <Icon onClick={() => copyTypeToClipboard(switchValueField(field), field)} name="icon-copy" id={`btn_copy_to_clipboard_key_${field}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowTextFromSelect;
