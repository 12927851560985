import React from "react";

export enum CONVEYANCE_ACTION {
  IMPORT = "import",
  EXPORT = "export",
}

// **************************************************
// *****************   FORMAT   *********************
// **************************************************

export enum CONVEYANCE_FORMAT {
  XLS = "xlsx",
  CSV = "csv",
}

export const formatLabel = {
  [CONVEYANCE_FORMAT.XLS]: "XLSx",
  [CONVEYANCE_FORMAT.CSV]: "CSV",
};

export type typeFormatOptions = {
  label: string;
  value: CONVEYANCE_FORMAT;
};

export const FormatOptions: typeFormatOptions[] = [
  {
    label: formatLabel[CONVEYANCE_FORMAT.XLS],
    value: CONVEYANCE_FORMAT.XLS,
  },
  {
    label: formatLabel[CONVEYANCE_FORMAT.CSV],
    value: CONVEYANCE_FORMAT.CSV,
  },
];

// **************************************************
// ***************   PRODUCTS   *********************
// **************************************************

export enum CONVEYANCE_PRODUCTS {
  PS = "ps",
  LP = "lastpass",
  KP = "keepass",
}

export const productLabel = {
  [CONVEYANCE_PRODUCTS.PS]: <span>PassSecurium&trade;</span>,
  [CONVEYANCE_PRODUCTS.LP]: <span>LastPass</span>,
  [CONVEYANCE_PRODUCTS.KP]: <span>KeePass</span>,
};

export type typeProductOption = {
  name: JSX.Element;
  code: CONVEYANCE_PRODUCTS;
};

export const ImportOptions: typeProductOption[] = [
  {
    name: productLabel[CONVEYANCE_PRODUCTS.PS],
    code: CONVEYANCE_PRODUCTS.PS,
  },
  {
    name: productLabel[CONVEYANCE_PRODUCTS.LP],
    code: CONVEYANCE_PRODUCTS.LP,
  },
  {
    name: productLabel[CONVEYANCE_PRODUCTS.KP],
    code: CONVEYANCE_PRODUCTS.KP,
  },
];

export const ExportOptions: typeProductOption[] = [
  {
    name: productLabel[CONVEYANCE_PRODUCTS.PS],
    code: CONVEYANCE_PRODUCTS.PS,
  },
  {
    name: productLabel[CONVEYANCE_PRODUCTS.LP],
    code: CONVEYANCE_PRODUCTS.LP,
  },
  {
    name: productLabel[CONVEYANCE_PRODUCTS.KP],
    code: CONVEYANCE_PRODUCTS.KP,
  },
];

// **************************************************
// ******************   DAYS   **********************
// **************************************************

export enum DAYS_FORMAT {
  THREE = 3,
  SIX = 6,
  TWELVE = 12,
  EIGHTEEN = 18,
  TWO = 24,
}

export const formatLabelResetPass = {
  [DAYS_FORMAT.THREE]: 3,
  [DAYS_FORMAT.SIX]: 6,
  [DAYS_FORMAT.TWELVE]: 12,
  [DAYS_FORMAT.EIGHTEEN]: 18,
  [DAYS_FORMAT.TWO]: 24,
};

export type typeFormatOptionsResetPassDays = {
  label: number;
  value: DAYS_FORMAT;
};

export const FormatOptionsResetPass: typeFormatOptionsResetPassDays[] = [
  {
    label: formatLabelResetPass[DAYS_FORMAT.THREE],
    value: DAYS_FORMAT.THREE,
  },
  {
    label: formatLabelResetPass[DAYS_FORMAT.SIX],
    value: DAYS_FORMAT.SIX,
  },
  {
    label: formatLabelResetPass[DAYS_FORMAT.TWELVE],
    value: DAYS_FORMAT.TWELVE,
  },
  {
    label: formatLabelResetPass[DAYS_FORMAT.EIGHTEEN],
    value: DAYS_FORMAT.EIGHTEEN,
  },
  {
    label: formatLabelResetPass[DAYS_FORMAT.TWO],
    value: DAYS_FORMAT.TWO,
  },
];

// **************************************************
// *****************   REMIND   *********************
// **************************************************

export enum REMIND_FORMAT {
  THREE = 3,
  FIVE = 5,
  SEVEN = 7,
}

export const formatLabelRemind = {
  [REMIND_FORMAT.THREE]: 3,
  [REMIND_FORMAT.FIVE]: 5,
  [REMIND_FORMAT.SEVEN]: 7,
};

export type typeFormatOptionsRemind = {
  label: number;
  value: REMIND_FORMAT;
};

export const FormatOptionsRemind: typeFormatOptionsRemind[] = [
  {
    label: formatLabelRemind[REMIND_FORMAT.THREE],
    value: REMIND_FORMAT.THREE,
  },
  {
    label: formatLabelRemind[REMIND_FORMAT.FIVE],
    value: REMIND_FORMAT.FIVE,
  },
  {
    label: formatLabelRemind[REMIND_FORMAT.SEVEN],
    value: REMIND_FORMAT.SEVEN,
  },
];
