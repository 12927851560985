import { History } from "history";
import { combineReducers, Reducer } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { FormStateMap, reducer as formReducer } from "redux-form";

import activityLog, { State as ActivityLog } from "./activity-log";
import favorites, { State as FavoritesState } from "./favorites";
import folders, { State as FoldersState } from "./folders";
import groups, { State as GroupsState } from "./groups";
import histories, { State as HistoriesState } from "./histories";
import keys, { State as KeysState } from "./keys";
import manager, { State as ManagerState } from "./manager";
import search, { State as SearchState } from "./search";
import settings, { State as SettingsState } from "./settings";
import templates, { State as Templates } from "./templates";
import user, { State as UserState } from "./user";
import users, { State as UsersState } from "./users";

import settingsTest, { SettingsState as SettingsStateTest  } from "./settings/index";

export interface RootState {
  activityLog: ActivityLog;
  favorites: FavoritesState;
  folders: FoldersState;
  form: FormStateMap;
  groups: GroupsState;
  histories: HistoriesState;
  keys: KeysState;
  manager: ManagerState;
  router: RouterState;
  search: SearchState;
  settings: SettingsState;
  settingsTest: SettingsStateTest;
  templates: Templates,
  user: UserState;
  users: UsersState;
}

export default function rootReducer(history: History): Reducer<RootState> {
  return combineReducers<RootState>({
    form: formReducer,
    router: connectRouter(history),
    settingsTest: settingsTest(),
    activityLog,
    favorites,
    folders,
    groups,
    histories,
    keys,
    manager,
    search,
    settings,
    templates,
    user,
    users,
  });
}
