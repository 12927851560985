/* eslint-disable prefer-destructuring, no-lonely-if */
export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * @param {File} image
 * @param {Object} pixelCrop
 * @param {number} rotation
 */

interface IGetCroppedImg {
  imageSrc: any | null;
  pixelCrop: any;
  maxWidth?: number;
  maxHeight?: number;
  rotation?: number;
  typeImg?: string;
}

export async function getCroppedImg({
  imageSrc,
  pixelCrop,
  maxWidth = 150,
  maxHeight = 150,
  rotation = 0,
  typeImg = "image/png",
}: IGetCroppedImg) {
  const image: any | null = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  ctx.fillStyle = "#fff";

  canvas.width = safeArea;
  canvas.height = safeArea;
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  return resizeImage(canvas.toDataURL(typeImg), maxWidth, maxHeight, typeImg);
}

export async function base64ToBlob(b64Data: string): Promise<Blob> {
  const res = await fetch(b64Data);
  return res.blob();
}

function getExtensionByMimeType(mime: string): string {
  return mime.split("/").pop() || "jpeg";
}

export async function base64ToFile(b64Data, name = "file"): Promise<File> {
  const blob = await base64ToBlob(b64Data);
  return new File([blob], `${name}.${getExtensionByMimeType(blob.type)}`, { type: blob.type });
}

/**
 * @param {File} imageSrc
 * @param {number} maxWidth
 * @param {number} maxHeight
 */
export async function resizeImage(imageSrc: any | null, maxWidth = 150, maxHeight = 150, type = "image/png") {
  const image: any | null = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");

  const MAX_WIDTH = maxWidth;
  const MAX_HEIGHT = maxHeight;
  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL(type);
}

/**
 * @param {string} imageUrl
 */
export function getSizeImage(imageUrl: string) {
  const stringLength = imageUrl.length - "data:image/png;base64,".length;
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.75;

  return sizeInBytes / 1000;
}
