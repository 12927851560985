/* eslint-disable jsx-a11y/click-events-have-key-events, react/no-danger, no-nested-ternary, jsx-a11y/no-static-element-interactions */
import React, { FC, useMemo } from "react";
import { connect } from "react-redux";
import { FolderIcon, Icon } from "ss-ui";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { PropsWithChosen, WithChosen } from "hoc";
import { compose } from "recompose";
import { cnTree } from "../../components/Generic/ModalFolderSelect/fragments/TreeList/TreeList";
import { RootState } from "../../store/reducers";
import { typeFolderFilter } from "../../components/InfoBox/FolderFilter/FolderFilterTypes";
import { Payload as PayloadFavoritesSetFolder } from "../../store/routines/favorites/setFolder";
import {
  DATE_FORMAT,
  filteringFolderByParam,
  FOLDER_ACCESS,
  FOLDER_TYPE,
  getKey,
  parseDate,
  PayloadChoseFunction,
  replaceUrlParam,
  Routes,
  typeFolder,
  typeGroup,
  ROLE_TYPE,
} from "../../core";
import { folderChose, favoritesSetFolder } from "../../store/actions";

type Props = {
  folders: typeFolder[];
  groupList: typeGroup[];
  filters: typeFolderFilter;
  folderChose: (payload: PayloadChoseFunction) => void;
  favoritesSetFolder: (payload: PayloadFavoritesSetFolder) => void;
  chosen: string[];
  role: ROLE_TYPE;
} & PropsWithChosen;

const FolderFilterList: FC<Props> = ({
  folders,
  groupList,
  filters,
  chosen,
  role,
  folderChose,
  handleUpdateChosenItems,
  handleChosenAllItems,
  favoritesSetFolder,
}) => {
  const { t } = useTranslation();

  const isAdmin: boolean = useMemo(() => [ROLE_TYPE.ADMIN].includes(role), [role]);

  const handleFavorite = (folderID: string) => {
    favoritesSetFolder({
      folderID,
    });
  };

  return (
    <>
      {folders.map((folder: typeFolder) => {
        return (
          <React.Fragment key={folder.id}>
            {filteringFolderByParam(folder, groupList, filters) && (
              <div className={cnTree({ level: 0 })}>
                <div className={cnTree("Folder", { chosen: chosen.indexOf(folder.id) >= 0 })}>
                  <div className={cnTree("Status")}>
                    <div className="EmptyFolderStatus">&nbsp;</div>
                  </div>
                  <div
                    onClick={() => handleUpdateChosenItems(folderChose, !(chosen.indexOf(folder.id) >= 0), folder.id, true)}
                    className={cnTree("Title")}
                  >
                    <FolderIcon
                      level={folder.level_security}
                      size={30}
                      isRoot={folder.root === null && folder.type !== FOLDER_TYPE.GROUP}
                      isGroup={folder.type === FOLDER_TYPE.GROUP}
                      access={folder.type === FOLDER_TYPE.GROUP ? folder.access : "" || folder.root === null ? FOLDER_ACCESS.WRITE : ""}
                    />
                    <span className={cnTree("Title-Truncate")}>{folder.title}</span>
                    {(isAdmin && folder.type === "group") || ( folder.access === FOLDER_ACCESS.NO_ACCESS) ? null : (
                      <button
                        className={cnTree("Favorites")}
                        type="button"
                        id={getKey(`btn_folder_favorite_${folder.title}`)}
                        onClick={e => {
                          e.stopPropagation();
                          handleFavorite(folder.id);
                        }}
                        data-tooltip={folder.favorite ? t("tooltip_remove-favorite") : t("tooltip_add-favorite")}
                      >
                        <Icon width={16} height={16} name={folder.favorite ? "icon-star-fill" : "icon-star"} />
                      </button>
                    ) }
                  </div>
                  <div className={cnTree("Description")}>
                    <span className={cnTree("Description-Truncate")}>
                      {folder.description?.replace(/(<([^>]+)>)/ig, "").replace(/&(nbsp|amp|quot|lt|gt);/g, "")}
                    </span>
                  </div>
                  <div className={cnTree("Date")}>{moment(parseDate(folder.createdAt)).format(DATE_FORMAT)}</div>
                  <div className={cnTree("Keys")}>
                    <Link
                      className={cnTree("Keys-Item tooltip tooltip-left")}
                      to={replaceUrlParam(Routes.Keys, "folderID", folder.id)}
                      id={getKey(`link_to_folder_${folder.title}`)}
                      data-tooltip={t("tooltip-show")}
                    >
                      <Icon name="colored/icon-key" width={20} height={9}/>
                      <span>{folder.countKeys}</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {folder.children.length > 0 && (
              <FolderFilterList
                handleUpdateChosenItems={handleUpdateChosenItems}
                handleChosenAllItems={handleChosenAllItems}
                favoritesSetFolder={favoritesSetFolder}
                folderChose={folderChose}
                chosen={chosen}
                filters={filters}
                folders={folder.children}
                groupList={groupList}
                role={role}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const mapStateToProps = ({
  groups: { list: groupList }, folders: { chosen },
  user: { role },
}: RootState) => ({ chosen, groupList, role });

const mapDispatchToProps = { folderChose, favoritesSetFolder };

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(FolderFilterList);
