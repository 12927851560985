import createSagaMiddleware from "redux-saga";
import { History } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { routinePromiseWatcherSaga } from "redux-saga-routines";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// import logger from "redux-logger";
import { isEnvDevelop } from "core";

import createRootReducer from "store/reducers";
import rootSaga from "store/sagas";

type Props = {
  history: History;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preloadedState?: any;
};

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export default function configureStore({ preloadedState, history }: Props) {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const middlewares: any[] = [];

  if (isEnvDevelop()) {
    const { createLogger } = require("redux-logger");
    const logger = createLogger({
      collapsed: true,
      diff: true,
    });
    middlewares.push(logger);
  }

  const sagaMiddleware = createSagaMiddleware();

  middlewares.push(routerMiddleware(history));
  middlewares.push(sagaMiddleware);

  const store = createStore(createRootReducer(history), preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);
  sagaMiddleware.run(routinePromiseWatcherSaga);

  return store;
}
