import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";

import { http, SecurityServices, typeProtectedConfigs } from "core";

import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("GET_PROTECTED_CONFIG");

const getProtectedConfig = () => {
  return http({ route: "parameters/protected" });
};

function* handler() {
  try {
    const response: typeProtectedConfigs = yield call(getProtectedConfig);

    yield SecurityServices.getInstance(response.encrypt.encryptor_loop);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
