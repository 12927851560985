import React, { FC, useState, useContext, useEffect } from "react";
import { Icon, Button } from "ss-ui";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";

import { FormFile } from "components/Form";

import { Validation, FIELD_TYPE } from "core";

import { cnManageKey } from "pages/ManageKey/ManageKey";

import { FieldContext } from "./Types";

type Props = {
  type?: FIELD_TYPE;
};

const fieldTypeAttachment = FIELD_TYPE.FILE;
const fieldTypeCertificate = FIELD_TYPE.CERTIFICATE;

const FieldFileName: FC<Props> = ({ type = FIELD_TYPE.FILE }) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.key && (!context.formData[fieldTypeAttachment] && !context.formData[fieldTypeCertificate])) {
      handleChangeFileValue(context.key?.[type], context.key?.[type]);
    } else if (context.formData[fieldTypeAttachment] && type === FIELD_TYPE.FILE) {
      handleChangeFileValue(context.formData[fieldTypeAttachment],
        context.formData[fieldTypeAttachment]?.name ? context.formData[fieldTypeAttachment].name : context.formData[fieldTypeAttachment]
      );
    } else if (context.formData[fieldTypeCertificate] && type === FIELD_TYPE.CERTIFICATE) {
      handleChangeFileValue(context.formData[fieldTypeCertificate],
        context.formData[fieldTypeCertificate]?.name ? context.formData[fieldTypeCertificate].name : context.formData[fieldTypeCertificate]
      );
    }
  }, [context.key]);

  const handleChangeFileValue = (value: any, valueName: string) => {
    context.handleChange(type, value);
    setName(valueName);
  }

  const handleRemove = () => {
    setName("");
    context.handleChange(type, "0");
  };

  return (
    <div className={cnManageKey("Attached")}>
      <div className={cnManageKey("AttachedLabel")}>{t(type === FIELD_TYPE.CERTIFICATE ? "manage_key_field_certificate_label" : "form_input-Attached")}</div>
      {name ? (
        <div className={cnManageKey("AttachedFile")}>
          <div className={cnManageKey("AttachedFileInfo")}>
            <Icon name="icon-file" width={16} height={16} /> <span title={name}>{name}</span>
          </div>
          <Button
            className={`${cnManageKey("AttachedFileRemove")} tooltip tooltip-left`}
            data-tooltip={t("tooltip-delete")}
            icon="icon-trash"
            id="btn_remove_file"
            onClick={() => handleRemove()}
            theme="danger-link"
            type="button"
          />
        </div>
      ) : (
        <div className={cnManageKey("AddFile")}>
          <Icon name="icon-upload" width={16} height={16} />
          <Field
            change={context.handleChange}
            className={cnManageKey("AttachedInput")}
            component={FormFile}
            label={t("form_input-SelectFile")}
            name={type}
            validate={[Validation.maxFileSize]}
          />
        </div>
      )}
    </div>
  );
};

export default FieldFileName;
