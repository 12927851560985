import React, { FC, useEffect, useMemo, useState } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from "redux-form";
import { useTranslation } from "react-i18next";

import { FormInput, FormLazySelect } from "components/Form";

import {
  ACTION,
  FORM_GROUP_MANAGEMENT,
  getGroupFromListByID, GROUP_STORAGE,
  http,
  ROLE_TYPE,
  typeGroup,
  typeGroupFolder,
  typeUser,
  Validation,
} from "core";

import { FormValues, Payload as PayloadGroupsManagement } from "store/routines/groups/manage";
import { RootState } from "store/reducers";
import { groupsManagement, usersGetAll } from "store/actions";

const cnSettings = cn("InfoBoxGroupSettings");

type Props = {
  chosen: string[];
  groupsManagement: (payload: PayloadGroupsManagement) => void;
  usersGetAll: () => void;
  list: typeGroup[];
  username: string;
  userId: string;
  role: ROLE_TYPE;
  groupStorage: GROUP_STORAGE;
  usersList: typeUser[];
} & InjectedFormProps &
  WrappedFieldArrayProps<any>;

const Settings: FC<Props> = ({
  usersGetAll,
  groupsManagement,
  list,
  chosen,
  change,
  handleSubmit,
  username,
  userId,
  role,
  groupStorage,
  usersList,
}) => {
  const isNew = chosen[0] === "new";
  const group: typeGroup = getGroupFromListByID(list, chosen[0]);
  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role, group]);
  const isGroupAccess = useMemo(() => group && group.users.some(x => x.username === username), [group, username]);
  const { t } = useTranslation();

  const [skip, setSkip] = useState<string>();
  const [limit, setLimit] = useState<number>();
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    if (groupStorage === GROUP_STORAGE.LOCAL) {
      change("name", isNew ? "" : group.name);
    } else {
      change("selectGroup", isNew ? "" : { label: group.name, value: `${group.name},${group.azureId}` });
      change("name", isNew ? "" : group.name);
      change("azureId", isNew ? "" : group.azureId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosen]);

  useEffect(() => {
    if (usersList.length === 0) {
      usersGetAll();
    }
  }, [usersList, usersGetAll]);

  const handleForm = (data: any) => {
    groupsManagement({
      id: isNew ? undefined : group.id,
      action: isNew ? ACTION.CREATE : ACTION.UPDATE,
      payload: {
        folders: isNew
          ? []
          : group.folders.map(({ access, folder, recursion }: typeGroupFolder) => ({
              access,
              folder,
              recursion,
            })),
        users: isNew ? [userId] : group.users.map(({ id }: typeUser) => id),
        name: data.name,
        azureId: data.azureId ? data.azureId : undefined,
      },
    });
  };

  const changeSelectValue = (selectValue) => {
    const valueArray = selectValue.value.split(",");
    change("name", valueArray[0]);
    change("azureId", valueArray[1]);
  };


  return (
    <form id="submitGroupForm" onSubmit={handleSubmit(handleForm)} className={cnSettings()}>
      {groupStorage === GROUP_STORAGE.LOCAL ? (
        <Field
          component={FormInput}
          label={t("groups-Name")}
          name="name"
          placeholder={t("form_input_enter-GroupName")}
          validate={[Validation.maxLengthGroup, Validation.required]}
          className="Required"
          disabled={!isNew && !isGroupAccess && !isAdmin}
        />
      ) : (
        <Field
          component={FormLazySelect}
          label={t("groups-Name")}
          name="selectGroup"
          className="Required"
          disabled={!isNew}
          onChange={changeSelectValue}
          placeholder={t("form_input_enter-GroupName")}
          loadOptions={async query => {
            let params: { q: string; skip_token?: string; limit?: number; offset?: number } = { q: query, offset };

            if (skip) params = { ...params, skip_token: skip };
            if (limit) params = { ...params, limit };
            if (query !== "") params = { ...params, offset: 0 };

            const response: any = await http({ route: "manager/groups-ldap", method: "GET", payload: { params } });

            setSkip(response.skip_token);
            setLimit(response.limit);
            setOffset(response.offset + response.limit);

            return {
              hasMore: !!response.skip_token,
              options: response.groups.map(group => ({
                label: group.name,
                value: `${group.name},${group.azureId}`,
              })),
            };
          }}
          template={(label: string): JSX.Element => (
            <div className="Users-Select">
              <Icon name="icon-group-circle" width={20} height={20} />
              <span title={label}>{label}</span>
            </div>
          )}
          validate={[Validation.maxLengthGroupAzure, Validation.required]}
        />
      )}
    </form>
  );
};

const mapStateToProps = ({
  groups: { list, chosen },
  user: { username, role, userId },
  settings: { groupStorage },
  users: { list: usersList },
}: RootState) => ({
  list,
  chosen,
  username,
  userId,
  role,
  groupStorage,
  usersList,
});

const mapDispatchToProps = { groupsManagement, usersGetAll };

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormValues>({
    form: FORM_GROUP_MANAGEMENT,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(Settings);
