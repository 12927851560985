import i18n from "locales/i18n";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, has, snakeCase } from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";

import { http } from "core";

export interface FormValues {
  password: string;
}

export type Payload = FormValues | { values: FormValues };

export const action: Routine = createRoutine("RESET_PASSWORD");

const resetPassword = (rest: FormValues) => {
  const windowUrl = window.location.hash.slice(1).split(/[\s#]+/);
  const data = {
    new_password: rest.password,
  };
  return http({
    method: "PUT",
    payload: data,
    route: `user/reset-password/${windowUrl[0]}`,
    isProtected: false,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const rest = "values" in payload ? payload.values : payload;
    const response = yield call(resetPassword, rest);
    yield put(action.success(response));
  } catch (error) {
    if (has(error, "response.data.error_description") && i18n.exists(`error_${snakeCase(get(error, "response.data.error_description"))}`)) {
      yield put(action.failure(`error_${snakeCase(get(error, "response.data.error_description"))}`));
    } else {
      yield put(action.failure("error_connection"));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
