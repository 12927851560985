import React, { useState, useEffect } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { WrappedFieldProps } from "redux-form";
import { connect } from "react-redux";

import { cnField } from "components/Form";
import { cnMessages } from "components/Messages";

import { typeOption } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

import { customSelectStyles, SelectTheme } from "./Select";

// const getInitialValue = (value: string | number, options: typeOption[]): typeOption | undefined => {
//   if (typeof value === "object") {
//     return value;
//   }

//   if (value && options.length > 0) {
//     return options.find((obj: typeOption) => obj.value === value);
//   }

//   return undefined;
// };

type Props = {
  className?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  isMultiple?: boolean;
  isLoading?: boolean;
  renderOutside?: boolean;
  menuPlacement?: "auto" | "bottom" | "top";
  label: string;
  templateList?: (label: string, value: string) => JSX.Element;
  name: string;
  options: [];
  placeholder?: string;
  selectType: string;
  template?: (label: string, value: string) => JSX.Element;
  customize: SettingsCustomizeState;
  hideSelectedOptions?: boolean
} & WrappedFieldProps;

const MultiSelectOutsideRender: React.FC<Props> = ({
  className = "",
  disabled,
  isSearchable = false,
  input,
  isMultiple = false,
  renderOutside = false,
  isLoading = false,
  menuPlacement = "auto",
  templateList,
  label,
  meta: { touched, error },
  name,
  options,
  placeholder = "",
  selectType = "",
  template,
  customize,
  hideSelectedOptions = false,
}) => {
  const isError = touched && Boolean(error);

  const [mainColor, setMainColor] = useState<string>("#1a90bb");

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  const getOptionLabel = (option: any) => {
    switch (selectType) {
      case "group":
        return option.title;
      default:
        return option.label;
    }
  };

  const getOptionValue = (option: any) => {
    switch (selectType) {
      case "group":
        return option.dn;
      default:
        return option.value;
    }
  };

  const formatOptionLabel = ({ value, label }: any) => (template ? template(label, value) : label);

  return (
    <div
      className={`${cnField()} ${cnField("Select", { hasError: isError })} ${className}`}
      id={`input_multi_select_outside_${input.name}_container`}
    >
      {label && (
        <span className="Label" id={`input_multi_select_outside_${input.name}_label`}>
          {label}
        </span>
      )}
      <Select
        className={className}
        classNamePrefix={className}
        closeMenuOnSelect={!isMultiple}
        controlShouldRenderValue={!(renderOutside && isMultiple)}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={hideSelectedOptions}
        id={`input_multi_select_outside_${input.name}`}
        isClearable={isMultiple}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMultiple}
        isOptionDisabled={option => (option.isDisabled !== undefined ? option.isDisabled : false)}
        isSearchable={isMultiple || isSearchable}
        menuPlacement={menuPlacement}
        name={name}
        onChange={(option: SingleValue<typeOption> | MultiValue<typeOption>) => input.onChange(option)}
        options={options}
        placeholder={placeholder}
        styles={customSelectStyles({ isMultiple }, mainColor)}
        value={input.value}
        theme={SelectTheme(mainColor)}
      />
      {renderOutside && isMultiple && templateList && (
        <div className="selected-options" id={`input_multi_select_outside_${input.name}_select_options`}>
          {input.value.length > 0 && input.value.map(item => templateList(item.label, item.value))}
        </div>
      )}

      {isError && (
        <span id={`input_multi_select_outside_${input.name}_error`} className={`${cnMessages()} ${cnMessages("Error")}`}>
          {error}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectOutsideRender);
