import { Action } from "redux-actions";
import { PayloadChoseFunction, FORM_LOCAL_USER_MANAGEMENT } from "core";
import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";
import { reset } from "redux-form";

export const action: Routine = createRoutine("USERS_CHOSE");

function* handler({ payload }: Action<PayloadChoseFunction>) {
  yield put(reset(FORM_LOCAL_USER_MANAGEMENT));
  yield put(action.success(payload));
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
