/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect } from "react";
import { Checkbox, Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  cnTable,
  PropsTableWithSearch,
  PropsTableWithSort,
  TableHeaderElement,
  TableWithSearch,
  TableWithSort,
  typeTableHeaderElement,
} from "components/Table";

import { getKey, PayloadChoseFunction, typeGroup } from "core";

import { PropsWithChosen, PropsWithLoading, WithChosen, withLoading } from "hoc";

import { RootState } from "store/reducers";
import { Payload as PayloadSetClickedLink } from "store/routines/settings/setClickedLink";
import { groupsChose, setClickedLink } from "store/actions";

import { tableHeader } from "./TableConfig";

const cnTableGroups = cn("TableGroups");

type Props = PropsWithLoading &
  PropsTableWithSearch &
  PropsWithChosen &
  PropsTableWithSort & {
    chosen: string[];
    groupsChose: (payload: PayloadChoseFunction) => void;
    isLoading: boolean;
    list: typeGroup[];
    setClickedLink: (payload: PayloadSetClickedLink) => void;
    searchValue: string;
  };

const TableGroups: FC<Props> = ({
  chosen,
  groupsChose,
  handleSort,
  handleUpdateChosenItems,
  isLoading,
  list,
  search,
  searchCallback,
  setClickedLink,
  setIsLoading,
  setSearch,
  sortBy,
  sortCallback,
  sortDirection,
  searchValue,
}) => {
  useEffect(() => {
    setIsLoading(isLoading && list.length !== 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    setSearch(searchValue);
  }, [searchValue]);

  // const themeCheckAll = chosen.length < (list ? list.length : 0) && chosen.length > 0 ? "squre" : "";

  const handleChosen = (id: string, tab: number) => {
    handleUpdateChosenItems(groupsChose, !(chosen.indexOf(id) >= 0), id, true);
    setClickedLink({ clickedLink: tab });
  };

  // const handleClearFilters = () => {
  //   setShown(!shown);
  //   setSearch("");
  // };

  const { t } = useTranslation();

  return (
    <div className={cnTableGroups()}>
      <div className={cnTable("Head Table-Head-Small")}>
        <div className={cnTable("Head-Th")}>&nbsp;</div>
        {tableHeader.map(({ title, ...data }: typeTableHeaderElement, index: number) => (
          <TableHeaderElement
            handleSort={handleSort}
            id={getKey(title, index)}
            key={title}
            sortBy={sortBy}
            sortDirection={sortDirection}
            {...data}
          >
            {t(`table_title-${title}`)}
          </TableHeaderElement>
        ))}
      </div>
      <div className={cnTable()}>
        <div className={cnTable("Body")}>
          {list.length > 0 &&
            list
              .filter(searchCallback)
              .sort(sortCallback)
              .map(({ id, name, folders, users }: typeGroup, index) => {
                const FoldersWriteCount = folders.filter(x => x.access === "write").length;
                const FoldersReadCount = folders.filter(x => x.access === "read").length;
                const FoldersNoaccessCount = folders.filter(x => x.access === "no_access").length;
                return (
                  <div key={id} className={cnTable("Row", { chosen: chosen.indexOf(id) >= 0 })} id={getKey("row_table_group", index)}>
                    <div className={cnTableGroups("Checkbox Table-Th")}>
                      <Checkbox
                        checked={chosen.indexOf(id) >= 0}
                        onChange={(status: boolean) => handleUpdateChosenItems(groupsChose, status, id, true)}
                      />
                    </div>

                    <div className={cnTableGroups("Icon Table-Th")}>
                      <Icon name="icon-group-circle" width={28} height={28} />
                    </div>

                    <div
                      onClick={() => handleChosen(id, 1)}
                      id={getKey("row_table_group_chosen_1", index)}
                      className={cnTableGroups("Name Table-Th")}
                    >
                      <p title={name}>{name}</p>
                    </div>

                    <div
                      onClick={() => handleChosen(id, 2)}
                      className={cnTableGroups("Folders Table-Th")}
                      id={getKey("row_table_group_2", index)}
                    >
                      {folders.length > 0 ? (
                        <>
                          {FoldersWriteCount > 0 && (
                            <div className={cnTableGroups("Folders-Item", { write: FoldersWriteCount > 0 })}>
                              <Icon name="colored/icon-folder-access-write" />
                              <span>{FoldersWriteCount}</span>
                            </div>
                          )}
                          {FoldersReadCount > 0 && (
                            <div className={cnTableGroups("Folders-Item", { read: FoldersReadCount > 0 })}>
                              <Icon name="colored/icon-folder-access-read" />
                              <span>{FoldersReadCount}</span>
                            </div>
                          )}
                          {FoldersNoaccessCount > 0 && (
                            <div className={cnTableGroups("Folders-Item", { no_access: FoldersWriteCount > 0 })}>
                              <Icon name="colored/icon-folder-access-no_access" />
                              <span>{FoldersNoaccessCount}</span>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={cnTableGroups("Folders-Item")}>
                          <Icon name="icon-folder" fill="#A7A7A8" />
                          <span>0</span>
                        </div>
                      )}
                    </div>
                    <div
                      onClick={() => handleChosen(id, 2)}
                      className={cnTableGroups("FoldersCount Table-Th")}
                      id={getKey("row_table_group_2", index)}
                    >
                      <div className={cnTableGroups("Folders-Item", { folders: folders.length > 0 })}>
                        <Icon name="icon-folder" fill={folders.length > 0 ? "" : "#A7A7A8"} />
                        <span>{folders.length} </span>
                      </div>
                    </div>
                    <div
                      onClick={() => handleChosen(id, 3)}
                      className={cnTableGroups("Users Table-Th")}
                      id={getKey("row_table_group_3", index)}
                    >
                      {users.length > 0 ? (
                        <div className={cnTableGroups("Folders-Item", { users: users.length > 0 })}>
                          <Icon name="icon-user" />
                          <span>{users.length}</span>
                        </div>
                      ) : (
                        <div className={cnTableGroups("Folders-Item")}>
                          <Icon name="icon-user" fill="#A7A7A8" />
                          <span>0</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ groups: { list, chosen, isLoading } }: RootState) => ({
  chosen,
  isLoading,
  list,
});

const mapDispatchToProps = { groupsChose, setClickedLink };

export default compose<Props, Partial<Props>>(
  TableWithSort,
  WithChosen,
  TableWithSearch,
  withLoading,
  connect(mapStateToProps, mapDispatchToProps),
)(TableGroups);
