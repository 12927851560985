import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, has } from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";

import { http } from "core";

export interface FormValues {
  oldPassword: string;
  password: string;
}

export type Payload = FormValues | { values: FormValues };

export const action: Routine = createRoutine("RESET_ADMIN_PASSWORD");

const resetAdminPassword = (rest: FormValues) => {
  const data = {
    oldPassword: rest.oldPassword,
    password: rest.password,
  };
  return http({
    method: "PUT",
    payload: data,
    route: "user/change-password",
    isProtected: true,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const rest = "values" in payload ? payload.values : payload;
    const response = yield call(resetAdminPassword, rest);
    yield put(action.success(response));
  } catch (error) {
    if (has(error, "response.data.detail.password.first[0]") || has(error, "response.data.detail.oldPassword[0]")) {
      yield put(action.failure(get(error, "response.data.detail")));
    } else {
      yield put(action.failure("error_connection"));
    }
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
