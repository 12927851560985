/* eslint-disable jsx-a11y/click-events-have-key-events, no-unneeded-ternary,  jsx-a11y/no-static-element-interactions, react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ITabs } from "components/Generic/Tabs/Tabs";
import { ROLE_TYPE } from "core";
import { InfoBoxKey } from "components/InfoBox";
import { InfoBoxFolder } from "components/InfoBox/Folder";
import { PropsWithChosen, WithChosen } from "hoc";
import { LoadingContainer, Tabs } from "components/Generic";
import { Aside, cnAsideContent, Content, ContentWrapper, ContentHeader } from "components/Layout";
import { RootState } from "store/reducers";
import { Payload as payloadGetFavoritesFolders } from "store/routines/favorites/getFolders";
import { Payload as payloadGetKeysFavorites } from "store/routines/favorites/getKeys";
import { setHeaderIcon, setHeaderTitle, favoritesGetFolders, favoritesGetKeys, favoritesGet } from "store/actions";
import FavoritesKey from "./Fragments/Keys/FavoritesKey";
import FavoritesFolder from "./Fragments/Folders/FavoritesFolder";


type Props = {
  keysTotalItems: number;
  foldersTotalItems: number;
  isLoadingKeys: boolean,
  isLoadingFolders: boolean,
  chosenKeys: string[];
  chosenFolders: string[];
  role: ROLE_TYPE;
  isLoading: boolean;
  setHeaderIcon: (payload: string | undefined) => void;
  setHeaderTitle: (payload: string | undefined) => void;
  favoritesGetFolders: (payload: payloadGetFavoritesFolders) => void;
  favoritesGetKeys: (payload: payloadGetKeysFavorites) => void;
  favoritesGet: () => void;
} & PropsWithChosen;

const Favorites: FC<Props> = ({
  keysTotalItems,
  isLoadingKeys,
  isLoadingFolders,
  foldersTotalItems,
  chosenKeys,
  chosenFolders,
  role,
  isLoading,
  setHeaderIcon,
  setHeaderTitle,
  favoritesGetFolders,
  favoritesGetKeys,
  favoritesGet,
}) => {
  const { t } = useTranslation();

  const isFreeUser: boolean = useMemo(() => role === ROLE_TYPE.FREE_USER, [role]);
  const isUser: boolean = useMemo(() => role === ROLE_TYPE.USER, [role]);

  const [tabs, setTabs] = useState<ITabs[]>(
    isFreeUser ?
      [{
        id: 1,
        isActive: true,
        label: t("table_title-Keys"),
        icon: "key",
        count: keysTotalItems,
      }] :
      [{
          id: 1,
          isActive: true,
          label: t("table_title-Keys"),
          icon: "key",
          count: keysTotalItems,
        },
        {
          id: 2,
          isActive: false,
          label: t("table_title-Folders"),
          icon: "folder",
          count: foldersTotalItems,
        }
      ]
  );

  useEffect(() => {
    setTabs(isFreeUser ?
      [{
        id: 1,
        isActive: true,
        label: t("table_title-Keys"),
        icon: "key",
        count: keysTotalItems,
      }] :
      [{
          id: 1,
          isActive: tabs && tabs[1].isActive ? false : true,
          label: t("table_title-Keys"),
          icon: "key",
          count: keysTotalItems,
        },
        {
          id: 2,
          isActive: tabs && tabs[0].isActive ? false : true,
          label: t("table_title-Folders"),
          icon: "folder",
          count: foldersTotalItems,
        }
      ])
  }, [foldersTotalItems, keysTotalItems]);

  useEffect(() => {
    setHeaderTitle(t("favorites_list"));
    setHeaderIcon("star-fill");
    favoritesGet();
    favoritesGetKeys({ currentPage: 1 });
    if (!isFreeUser) {
      favoritesGetFolders({ currentPage: 1 });
    }
  }, []);

  const handleChangeTab = (id: number) => {
    setTabs(tabs.map((tab: ITabs) => ({ ...tab, isActive: tab.id === id })));
  };

  return (
    <ContentWrapper id="favorites_content_wrapper">
      <Content id="favorites_content">
        <ContentHeader id="keys_content_header">
          <Tabs moreClass="Search" tabs={tabs} onClick={handleChangeTab} />
        </ContentHeader>
        <LoadingContainer isLoading={isLoadingKeys || isLoadingFolders || isLoading}>
          {tabs && tabs[0].isActive && (
            <FavoritesKey />
          )}
          {!isFreeUser && tabs[1].isActive && (
            <FavoritesFolder isUser={ isUser } />
          )}
        </LoadingContainer>
      </Content>
      <Aside side="right" id="favorites_aside_right">
        {chosenKeys.length > 0 && <InfoBoxKey />}
        {chosenFolders.length > 0 && <InfoBoxFolder />}
        {chosenKeys.length > 0 || chosenFolders.length > 0 ? null : (
          <div className={cnAsideContent()}>
            <div className={cnAsideContent("Header")}>
              <h2>{t("aside_right-Information")}</h2>
            </div>
            <div className={cnAsideContent("Description")}>
              <p>{t("aside_right_text-Favorite")}</p>
            </div>
            <div className={cnAsideContent("Total")}>
              <div className={cnAsideContent("Total-Count")}>
                <span>{keysTotalItems}</span>
                <span>{t("table_title-Keys")}</span>
              </div>
              {!isFreeUser && (
                <div className={cnAsideContent("Total-Count")}>
                  <span>{foldersTotalItems}</span>
                  <span>{t("table_title-Folders")}</span>
                </div>
              )}
            </div>
          </div>
        )}
      </Aside>
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  favorites: {
    isLoadingKeys,
    keysTotalItems,
    isLoadingFolders,
    foldersTotalItems,
  },
  keys: { chosen: chosenKeys },
  folders: { chosen: chosenFolders, isLoading },
  user: { role },
}: RootState) => ({
  isLoading,
  isLoadingKeys,
  isLoadingFolders,
  keysTotalItems,
  foldersTotalItems,
  chosenKeys,
  chosenFolders,
  role,
});

const mapDispatchToProps = {
  setHeaderIcon,
  setHeaderTitle,
  favoritesGetFolders,
  favoritesGetKeys,
  favoritesGet,
};

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(Favorites);
