import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";

import { http, typeOAuthLifeTime } from "core";

import { get as lodashGet } from "lodash";

export const action: Routine = createRoutine("SETTINGS_GET_OAUTH_LIFE_TIME");

const getOAuthLifeTime = () => {
  return http({ route: "oauth/life-time" });
};

function* handler() {
  try {
    const response: typeOAuthLifeTime = yield call(getOAuthLifeTime);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
