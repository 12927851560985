import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { get, snakeCase, has } from "lodash";
import { ACTION, FORM_LOCAL_USER_MANAGEMENT, getDataFromLS, http, LOCAL_STORAGE, ROLE_TYPE } from "core";
import { stopSubmit } from "redux-form";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

import { folderGetAll, getProfile, getConfig } from "store/actions";

export type FormValues = {
  appeal?: string;
  avatar?: File;
  firstName?: string;
  groups?: string[];
  himself_password?: boolean;
  himselfPassword?: number;
  lastName?: string;
  locale?: string;
  otp?: boolean | number;
  blocked?: boolean;
  password?: string | { first: string; second: string };
  password_expiration?: string;
  repeatPassword?: string;
  roles?: ROLE_TYPE;
  username?: string;
  email?: string;
};

export type Payload = {
  id?: string;
  action: ACTION;
  payload: FormValues;
};

export const action: Routine = createRoutine("USER_MANAGEMENT_LOCAL");

const manageLocal = ({ id, action, payload }: Payload) => {
  return http({
    route: action === ACTION.UPDATE ? `manager/user/${id}` : "manager/user",
    method: action === ACTION.UPDATE ? "PUT" : "POST",
    payload,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(manageLocal, payload);
    const authUser = getDataFromLS(LOCAL_STORAGE.USER);

    // if the user changes own profile language for non-application language, then update the application language
    if (payload.id && authUser.username === payload.payload.username && payload.payload.locale !== i18n.language) {
      i18n.changeLanguage(payload.payload.locale);
    }

    toast.success(i18n.t(`toast_user_success_${payload.action}`));

    yield put(action.success({ response, action: payload.action }));

    yield put(folderGetAll.trigger());
    yield put(getProfile.trigger());
    yield put(getConfig.trigger());
  } catch (error) {
    if (has(error, "response.data.detail.messages")) {
      yield put(stopSubmit(FORM_LOCAL_USER_MANAGEMENT, { _error: get(error, "response.data.detail.messages[0]") }));
    }

    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }

    if (has(error, "response.data.detail")) {
      const errors = get(error, "response.data.detail", {});
      const parsedErrors: string[] = Object.values(errors).reduce((acc: any, err: any) => [...acc, ...err], [] as string[]) as string[];

      parsedErrors.forEach(err => {
        toast.error(i18n.exists(err) ? i18n.t(`toast_${snakeCase(err)}`) : i18n.t(`toast_${snakeCase(err)}`));
      });
    }

    yield put(action.failure(get(error, "response.data.detail.messages", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
