/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { useTranslation } from "react-i18next";

const cnLoadingContainer = cn("LoadingContainer");

type Props = {
  isLoading: boolean;
  loadingImport?: boolean;
};

const LoadingContainer: FC<Props> = ({ children, isLoading, loadingImport = false }) => {
  const { t } = useTranslation();
  return (
    <>
      {isLoading && (
        <div className={cnLoadingContainer("Background")}>
          <div className={cnLoadingContainer("Animate")}>
            <Icon name="icon-lock-loading" />
          </div>
          {loadingImport && (
            <div className={cnLoadingContainer("Info")}>
              {t("loading_container_info")}
            </div>
          )}
        </div>
      )}
      {children}
    </>
  );
};

export default LoadingContainer;
