import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet, has } from "lodash";
import { toast } from "react-toastify";

import i18n from "locales/i18n";

export type Payload = {
  id: string;
};

export const action: Routine = createRoutine("GROUPS_GET");

const get = ({ id }: Payload) => {
  return http({ route: `manager/group/${id}`, method: "GET" });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(get, payload);

    yield put(action.success(response));
  } catch (error) {
    if (has(error, "response.data.status")) {
      toast.error(i18n.t("settings_access_control_denied"));
    }
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
