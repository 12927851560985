import { all, fork } from "redux-saga/effects";

import activityLog from "./activity-log";
import favorites from "./favorites";
import folders from "./folders";
import groups from "./groups";
import histories from "./histories";
import keys from "./keys";
import manager from "./manager";
import search from "./search";
import settings from "./settings";
import templates from "./templates";
import user from "./user";
import users from "./users";

export default function* root() {
  return yield all([
    fork(activityLog),
    fork(favorites),
    fork(folders),
    fork(groups),
    fork(histories),
    fork(keys),
    fork(manager),
    fork(search),
    fork(settings),
    fork(templates),
    fork(user),
    fork(users),
  ]);
}
