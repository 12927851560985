import React, { FC } from "react";
import { Icon } from "ss-ui";

import { cnManageKey } from "pages/ManageKey/ManageKey";

type Props = {
  isShow: boolean;
  isSuccess: boolean;
};

const ValidationTest: FC<Props> = ({ isShow, isSuccess, children }) => {

  if (!isShow) {
    return null;
  }

  return (
    <div className={cnManageKey("PassError-Item", { success: isSuccess })}>
      <Icon name={isSuccess ? "icon-checked" : "icon-close"} fill={isSuccess ? "#49B109" : "#E3000B"} width={12} height={12} />
      <span>{children}</span>
    </div>
  );
}

export default ValidationTest;
