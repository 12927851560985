import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http } from "core";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "locales/i18n";
import { get as lodashGet } from "lodash";

export type Payload = {
  resetPassValue?: boolean;
  resetPassDays?: number;
  resetPassRemind?: number;
};

export const action: Routine = createRoutine("SET_RESET_PASSWORD_SETTINGS");

const setResetPasswordSettings = ({ resetPassValue = undefined, resetPassDays = undefined, resetPassRemind = undefined }: Payload) => {
  return http({
    route: "user/settings",
    method: "POST",
    payload: {
      passwordRenewalEnabled: resetPassValue,
      passwordRenewalInterval: resetPassDays,
      passwordRemindInAdvance: resetPassRemind,
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(setResetPasswordSettings, payload);
    if (payload.resetPassValue || payload.resetPassDays || payload.resetPassRemind) {
      toast.success(i18n.t("toast_bestIcon_success"));
    } else {
      toast.success(i18n.t("toast_bestIcon_error"));
    }
    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
