import React, { FC } from "react";
import { Icon } from "ss-ui";
import { Link } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { RootState } from "store/reducers";
import { 
  BreadcrumbsItem, 
  getKey, 
  FOLDER_TYPE, 
  Routes, 
} from "core";

const cnBreadcrumbs = cn("Breadcrumbs");

type Props = {
  username?: string;
  items?: BreadcrumbsItem[];
  folderType?: string;
};

const Breadcrumbs: FC<Props> = ({ username, items, folderType }) => {

  return (
    <div className={cnBreadcrumbs()}>
      <Link to={Routes.FolderManagement} className="Breadcrumbs-Root" id="link_to_folder_manager">
        <Icon
          name={folderType === FOLDER_TYPE.GROUP ? "icon-group" : "icon-user"}
          width={16}
          height={16}
        />
      </Link>
      {items &&
        items.map(({ title, link }: BreadcrumbsItem, index: number) => {
          return (
            <React.Fragment key={getKey(title, index)}>
              {index === items.length - 1 ? (
                <div className={cnBreadcrumbs("Flex")} id="layout_content_breadcrumbs_container">
                  <div className={cnBreadcrumbs("Delimiter")}>
                    <Icon name="icon-arrow-right" fill="#A7A7A8" width={14} height={14} />
                  </div>
                  <span className={cnBreadcrumbs("Last")} title={title}>
                    {title}
                  </span>
                </div>
              ) : (
                <div className={cnBreadcrumbs("Flex")} id="layout_content_breadcrumbs_container">
                  <div className={cnBreadcrumbs("Delimiter")}>
                    <Icon name="icon-arrow-right" fill="#A7A7A8" width={14} height={14} />
                  </div>
                  <Link id={getKey(title, index)} to={link} title={title} className={cnBreadcrumbs("Item")}>
                    {title}
                  </Link>
                </div>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

const mapStateToProps = ({ user: { username } }: RootState) => ({ username });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
