/* eslint-disable @typescript-eslint/no-unused-vars */
import DatePicker from "react-datepicker";
import { de, enUS } from "date-fns/locale";
import React, { FC, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Icon } from "ss-ui";
import { cnField } from "components/Form";
import { useTranslation } from "react-i18next";
import { find } from "lodash";
import Color from "color";

import { Filter, cnFilter } from "components/Layout";
import { customSelectStyles, SelectTheme } from "components/Form/Select";

import { IActivityLogFilter, typeOption, IActivityLogFiltersData, IActivityLogFiltersUsersData } from "core";

import { RootState } from "store/reducers";
import { ActivityLogGetFilters } from "store/actions";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

interface Props {
  ActivityLogGetFilters: () => void;
  dataFilters: IActivityLogFiltersData[];
  dataUsers: IActivityLogFiltersUsersData[];
  filters: IActivityLogFilter;
  handleCancel: () => void;
  handleClose: (status: boolean) => void;
  handleSubmit: () => void;
  setFilters: (payload: IActivityLogFilter) => void;
  customize: SettingsCustomizeState;
}

const ActivityLogFilters: FC<Props> = ({
  ActivityLogGetFilters,
  dataFilters,
  dataUsers,
  filters,
  handleCancel,
  handleClose,
  handleSubmit,
  setFilters,
  customize,
}) => {
  const { t } = useTranslation();

  const [mainColor, setMainColor] = useState<string>("#1a90bb");
  const [valueUsersFilter, setValueUsersFilter] = useState<any>(filters.users);

  const currentLang = localStorage.getItem("i18nextLng");

  const templateType = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-group-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  useEffect(() => {
    setFilters({ ...filters, users: valueUsersFilter });
  }, [valueUsersFilter]);

  const handleRemoveOperation = (name: string) => {
    setFilters({ ...filters, operations: filters.operations.filter((item: any) => item !== name) });
  }

  const handleRemoveUser = (id: string) => {
    setValueUsersFilter(valueUsersFilter.filter((item: any) => item.value !== id));
  };

  const entityOptions: typeOption[] = useMemo(
    () => [
      { value: "all", label: t("label_option_entity_all") },
      ...dataFilters.map(({ entity }) => ({
        value: entity,
        label: t(`label_option_entity_${entity}`),
      })),
    ],
    [dataFilters],
  );

  const operationsOptions: typeOption[] = useMemo(() => {
    if (dataFilters.length === 0 || !filters.object) {
      return [];
    }
    const operations = find(dataFilters, { entity: filters.object })?.operations;
    return operations
      ? [
        // { value: "all", label: t("label_option_operation_all") },
        ...operations.map(operation => ({
          value: operation,
          label: t(`label_option_operation_${operation}`),
        })),
      ]
      : [];
  }, [filters.object, dataFilters]);

  const usersOptions: typeOption[] = useMemo(
    () => [
      // { value: "all", label: t("label_option_entity_all") },
      ...dataUsers.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ],
    [dataUsers],
  );

  useEffect(() => {
    if (dataFilters.length === 0 || dataUsers.length === 0) {
      ActivityLogGetFilters();
    }
  }, []);

  return (
    <>
      <Filter handleFilterCancel={handleCancel} filterClose={(value: boolean) => handleClose(value)} handleFilter={handleSubmit}>
        {/* <div className={cnFilter("Field")}> */}
        {/*   <label>Search</label> */}
        {/*   <input type="text" value={filters.search} onChange={({ target: { value } }) => setFilters({ ...filters, search: value })} /> */}
        {/* </div> */}
        <div className={cnFilter("Field")}>
          <label>{t("activity_log_label_filter_period")}</label>
          <div className={cnFilter("Field-Date")}>
            <DatePicker
              onChange={date => setFilters({ ...filters, period: { ...filters.period, start: date } })}
              startDate={filters.period.start}
              selected={filters.period.start}
              endDate={filters.period.end}
              maxDate={filters.period.end}
              dateFormat="dd.MM.yyyy"
              id="history_filter_date_picker_period_start"
              selectsStart
              locale={currentLang === "de" ? de : enUS}
            />
            &nbsp;&nbsp;—&nbsp;&nbsp;
            <DatePicker
              onChange={date => setFilters({ ...filters, period: { ...filters.period, end: date } })}
              startDate={filters.period.start}
              minDate={filters.period.start}
              selected={filters.period.end}
              endDate={filters.period.end}
              dateFormat="dd.MM.yyyy"
              id="history_filter_date_picker_period_end"
              selectsEnd
              locale={currentLang === "de" ? de : enUS}
            />
          </div>
        </div>
        {dataFilters.length > 0 && (
          <div className={cnFilter("Field")}>
            <label>{t("activity_log_label_filter_object_types")}</label>
            <Select
              menuPortalTarget={document.getElementById("menu-root")}
              isSearchable={false}
              id="history_filter_select_filter_operation"
              styles={{ ...customSelectStyles({ isMultiple: false }, mainColor) }}
              options={entityOptions}
              value={entityOptions.filter(option => filters.object === (option.value as string))}
              menuPlacement="top"
              placeholder={t("history_filter_operations_option_all")}
              onChange={opt =>
                setFilters({
                  ...filters,
                  object: opt?.value as string,
                  operations: filters.object !== opt?.value ? [] : filters.operations,
                })
              }
              theme={SelectTheme(mainColor)}
            />
          </div>
        )}
        {filters.object && operationsOptions.length > 0 && (
          <div className={cnFilter("Field")}>
            <label>{t("activity_log_label_filter_operations")}</label>
            <Select
              className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
              classNamePrefix={cnFilter("Select")}
              menuPortalTarget={document.getElementById("menu-root")}
              isSearchable={false}
              isMulti
              id="history_filter_select_filter_operation"
              styles={customSelectStyles({ isMultiple: false }, mainColor)}
              options={operationsOptions}
              controlShouldRenderValue={false}
              value={operationsOptions.filter(option => filters.operations.indexOf(option.value as string) >= 0)}
              menuPlacement="top"
              placeholder={`${t("form_input-Select")}...`}
              onChange={options => setFilters({ ...filters, operations: options.map(({ value }) => value as string) || [] })}
              theme={SelectTheme(mainColor)}
            />
            {filters.operations.map((item) => (
              <div key={item} className={cnFilter("SelectItem")}>
                <div className={cnFilter("SelectItem-Name")}>
                  <span>{t(`label_option_operation_${item}`)}</span>
                </div>
                <button type="button" onClick={() => handleRemoveOperation(item)} id="btn_handle_remove_role">
                  <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
                </button>
              </div>

            ))}
          </div>
        )}
        {dataUsers.length > 0 && (
          <div className={cnFilter("Field")}>
            <label>{t("activity_log_label_filter_users")}</label>
            <Select
              className={`${cnField()} ${cnField("Select")} ${cnFilter("Select")}`}
              classNamePrefix={cnFilter("Select")}
              menuPortalTarget={document.getElementById("menu-root")}
              isMulti
              id="history_filter_select_filter_operation"
              styles={customSelectStyles({ isMultiple: false }, mainColor)}
              options={usersOptions}
              value={valueUsersFilter}
              menuPlacement="top"
              controlShouldRenderValue={false}
              formatOptionLabel={templateType}
              placeholder={t("folder_filters_box_placeholder_user")}
              onChange={el => setValueUsersFilter(el)}
              closeMenuOnSelect={false}
              theme={theme => ({
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#EAFBFD",
                  primary75: mainColor,
                  primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                  primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
                },
              })}
            />
            {filters.users.map((item) => (
              <div key={item.value} className={cnFilter("SelectItem")}>
                <div className={cnFilter("SelectItem-Name")}>
                  <Icon name="icon-user-circle" width={20} height={20} /> <span>{item.label}</span>
                </div>
                <button type="button" onClick={() => handleRemoveUser(item.value)} id="btn_handle_remove_user">
                  <Icon name="icon-close" fill="#A7A7A8" width={12} height={12} />
                </button>
              </div>
            ))}
          </div>
        )}
      </Filter>
    </>
  );
};

const mapStateToProps = ({ activityLog: { filters, users }, settingsTest: { customize } }: RootState) => ({
  dataFilters: filters,
  dataUsers: users,
  customize,
});

const mapDispatchToProps = {
  ActivityLogGetFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogFilters);
