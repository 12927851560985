/**
 * Remove all param from url
 * @param {string} url
 */
export const clearUrlParam = (url: string) => url.replace(/\/:\w*\?/g, "");

/**
 * Replace string in url to needed param
 * @param {string} url
 * @param {string} param
 * @param {string} value
 */
export const replaceUrlParam = (url: string, param: string, value: string) => url.replace(`:${param}?`, value);

/**
 * functions match two url by path item, and return boolean
 * @param pathname - variable from useLocation hook
 * @param route - variable from Route config
 * @param pathEl - element number in after split url into array
 */
export function matchRoute(pathname: string, route: string, pathEl: number = 0): boolean {
  return pathname.split("/")[pathEl] === route.split("/")[pathEl];
}

/**
 * Return url to API
 */
export function getApiUrl(): string {
  return process.env.REACT_APP_API_URL || "/backpass/api/v2/";
}

/**
 * Return url to public folder API
 */
export function getPublicApiUrl(): string {
  const URL = getApiUrl();
  return `${URL.includes("api/v2") ? URL.replace("api/v2", "") : URL}public/`;
}

/**
 * functions match two url by path item, and return boolean
 * @param url - variable from useLocation hook
 */
export function getPortalLink(url: string) {
  switch (url) {
    case "localhost:3000":
      return "https://fdev-portal.pass-securium.ch";
    case "fdev-app.pass-securium.ch":
      return "https://fdev-portal.pass-securium.ch";
    case "fstg-app.pass-securium.ch":
      return "https://fstg-portal.pass-securium.ch";
    case "app.pass-securium.ch":
      return "https://portal.pass-securium.ch";
    default:
      return "https://portal.pass-securium.ch";
  }
}
