import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Checkbox } from "ss-ui";

import { cnSettings } from "pages/Settings";

import { Payload as PayloadSetFolderNotify } from "store/routines/settings/setFolderNotify";
import { RootState } from "store/reducers";
import { setGlobalSettings, setFolderNotify } from "store/actions";

type Props = {
  folderSharing?: boolean;
  keyCreation?: boolean;
  keyDeleting?: boolean;
  keySharing?: boolean;
  keySharingRemoval?: boolean;
  setFolderNotify: (payload: PayloadSetFolderNotify) => void;
};

const EmailNotifications: FC<Props> = ({
  setFolderNotify,
  folderSharing,
  keyCreation,
  keyDeleting,
  keySharing,
  keySharingRemoval,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cnSettings("Container")}>
      <div className={cnSettings("SwitchItem")}>
        <div className={cnSettings("Header")}>
          {t("settings_header_notify")}
        </div>
        <div className={cnSettings("Hint")}>{t("settings_hunt_notify")}</div>

          <div className={cnSettings("Switch-Right")}>
            <Checkbox
              checked={folderSharing}
              id="checkbox_folder_sharing"
              onChange={() => setFolderNotify({
                valueFolderSharing: !folderSharing,
                valueKeyCreation: keyCreation,
                valueKeyDeleting: keyDeleting,
                valueKeySharing: keySharing,
                valueKeySharingRemoval: keySharingRemoval,
              })}
            />
            <label htmlFor="checkbox_folder_sharing">{t("settings_notify_shared_folder")}</label>
          </div>

          <div className={cnSettings("Switch-Right")}>
            <Checkbox
              checked={keyCreation}
              id="checkbox_key_creation"
              onChange={() => setFolderNotify({
                valueFolderSharing: folderSharing,
                valueKeyCreation: !keyCreation,
                valueKeyDeleting: keyDeleting,
                valueKeySharing: keySharing,
                valueKeySharingRemoval: keySharingRemoval,
              })}
            />
            <label htmlFor="checkbox_key_creation">{t("settings_notify_shared_key")}</label>
          </div>

          <div className={cnSettings("Switch-Right")}>
            <Checkbox
              checked={keyDeleting}
              id="checkbox_key_deleting"
              onChange={() => setFolderNotify({
                valueFolderSharing: folderSharing,
                valueKeyCreation: keyCreation,
                valueKeyDeleting: !keyDeleting,
                valueKeySharing: keySharing,
                valueKeySharingRemoval: keySharingRemoval,
              })}
            />
            <label htmlFor="checkbox_key_deleting">{t("settings_notify_key_added")}</label>
          </div>

          <div className={cnSettings("Switch-Right")}>
            <Checkbox
              checked={keySharing}
              id="checkbox_key_sharing"
              onChange={() => setFolderNotify({
                valueFolderSharing: folderSharing,
                valueKeyCreation: keyCreation,
                valueKeyDeleting: keyDeleting,
                valueKeySharing: !keySharing,
                valueKeySharingRemoval: keySharingRemoval,
              })}
            />
            <label htmlFor="checkbox_key_sharing">{t("settings_notify_key_deleted")}</label>
          </div>

          <div className={cnSettings("Switch-Right")}>
            <Checkbox
              checked={keySharingRemoval}
              id="checkbox_key_sharing_removal"
              onChange={() => setFolderNotify({
                valueFolderSharing: folderSharing,
                valueKeyCreation: keyCreation,
                valueKeyDeleting: keyDeleting,
                valueKeySharing: keySharing,
                valueKeySharingRemoval: !keySharingRemoval,
              })}
            />
            <label htmlFor="checkbox_key_sharing_removal">{t("settings_notify_key_sharing_removed")}</label>
          </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  settings: {
    folderSharing,
    keyCreation,
    keyDeleting,
    keySharing,
    keySharingRemoval,
  }
}: RootState) => ({
  folderSharing,
  keyCreation,
  keyDeleting,
  keySharing,
  keySharingRemoval,
});

const mapDispatchToProps = {
  setGlobalSettings,
  setFolderNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotifications);
