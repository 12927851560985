import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";
import { Action } from "redux-actions";

import { http } from "core";

export const action: Routine = createRoutine("GET_FOLDERS_SEARCH");

export interface Payload {
  currentPage?: number;
  search?: string;
}

const getFoldersSearch = ({ currentPage = 1, search = "" }: Payload) => {
  return http({
    route: "folder/search",
    method: "POST",
    payload: {
      page: currentPage,
      term: search
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(getFoldersSearch, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}