import React, { FC, ReactNode, useMemo, useEffect } from "react";
import { ContentWrapper, Content, ContentHeader } from "components/Layout";
import { cnSettings } from "pages/Settings/index";
import { Header } from "components/Navigation";
import { SettingsNavigation, PRODUCT_TYPE, ROLE_TYPE, typeAccessControl, getAccessControl } from "core";
import { LoadingContainer } from "components/Generic";
import { RootState } from "store/reducers";
import { getGlobalSettings, getSettings } from "store/actions";
import { connect } from "react-redux";

type Props = {
  aside?: ReactNode;
  children: ReactNode;
  getGlobalSettings: () => void;
  getSettings: () => void;
  id?: string;
  initialize: boolean;
  isLoading: boolean;
  isLoadingExport: boolean;
  isLoadingSettings: boolean;
  productType: PRODUCT_TYPE;
  role: ROLE_TYPE;
  accessSettings: typeAccessControl;
};

const Settings: FC<Props> = ({
  aside = null,
  children,
  getGlobalSettings,
  getSettings,
  id = "settings",
  initialize,
  isLoading,
  isLoadingExport,
  isLoadingSettings,
  productType,
  role,
  accessSettings,
}) => {
  const isBusiness: boolean = useMemo(() => [PRODUCT_TYPE.ENTERPRISE, PRODUCT_TYPE.CORPORATE].includes(productType), [productType]);
  const isAdmin: boolean = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);
  const disableImport = SettingsNavigation.filter(e => e.icon !== "icon-conveyance");

  const isAccessImport: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessImport, role), [role, accessSettings]);
  const isAccessExport: boolean = useMemo(() => accessSettings && getAccessControl(accessSettings.accessExport, role), [role, accessSettings]);
  /**
   * UseEffect one time when page loaded
   */
  useEffect(() => {
    if (!initialize) {
      getSettings();

      if (isBusiness && isAdmin) {
        getGlobalSettings();
      }
    }
  }, []);

  return (
    <ContentWrapper id={`${id}_content_wrapper`}>
      <Content id={`${id}_content`} className={cnSettings()}>
        <ContentHeader id={`${id}_content_header`}>
          <Header linkList={(isAccessImport || isAccessExport) || isAdmin ? SettingsNavigation : disableImport } />
        </ContentHeader>
        <LoadingContainer isLoading={isLoadingSettings || isLoading || isLoadingExport} loadingImport={isLoadingExport}>{children}</LoadingContainer>
      </Content>
      {aside}
    </ContentWrapper>
  );
};

const mapStateToProps = ({
  user: { role },
  settings: { isLoading, isLoadingSettings, productType, initialize },
  keys: { isLoadingExport },
  settingsTest: { accessControl: { settings: accessSettings } }
}: RootState) => ({
  initialize,
  isLoading,
  isLoadingExport,
  isLoadingSettings,
  productType,
  role,
  accessSettings
});

const mapDispatchToProps = {
  getGlobalSettings,
  getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
