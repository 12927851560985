/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import { Icon } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { mapRolesName, PRODUCT_TYPE, ROLE_TYPE, Routes } from "core";
import { RootState } from "store/reducers";
import { getAvatar, logout } from "store/actions";
import { NavLink } from "react-router-dom";

export const cnHeaderProfile = cn("Header-Profile");

type Props = {
  productType: PRODUCT_TYPE;
  avatar?: string;
  getAvatar: () => void;
  isMicrosoft: boolean;
  logout: () => void;
  title?: string;
  userRole?: ROLE_TYPE;
};

const Profile: FC<Props> = ({ productType, avatar, getAvatar, isMicrosoft, logout, title = "User", userRole }) => {
  useEffect(() => {
    getAvatar();
  }, []);

  return (
    <div className={cnHeaderProfile()} id="page_header_right_profile">
      <div className={cnHeaderProfile("Content")}>

        <NavLink 
          className={cnHeaderProfile("Avatar")} 
          to={Routes.Profile} 
          id="page_header_right_profile_link"
          onClick={() => {
            const layoutElement = document.querySelector(".Layout-Container");
              if (layoutElement) {
                layoutElement.scrollTo({ top: 0, left: 0 });
            }
          }}
        >
          {avatar ? (
            <img src={avatar} alt="User avatar" id="page_header_right_profile_link_avatar" />
          ) : (
            <Icon name="icon-user-circle" width={30} height={30} />
          )}
        </NavLink>

        <NavLink 
          className={cnHeaderProfile("Title")} 
          to={Routes.Profile} 
          id="page_header_right_profile_title_link"
          onClick={() => {
            const layoutElement = document.querySelector(".Layout-Container");
              if (layoutElement) {
                layoutElement.scrollTo({ top: 0, left: 0 });
            }
          }}
        >
          <span title={title} className={cnHeaderProfile("Name")} id="page_header_right_profile_title_username">
            {title}
          </span>
          {userRole && productType !== PRODUCT_TYPE.STANDARD && (
            <span className={cnHeaderProfile("Role")} id="page_header_right_profile_title_role">
              {mapRolesName[userRole]}
            </span>
          )}
        </NavLink>

        {!isMicrosoft && (
          <button type="button" className={cnHeaderProfile("Logout")} onClick={logout} id="page_header_right_profile_btn_logout">
            <Icon name="icon-power" width={16} height={16} />
          </button>
        )}

      </div>
    </div>
  );
};

const mapStateToProps = ({ settings: { productType }, user: { role, avatar, isMicrosoft } }: RootState) => ({
  productType,
  avatar,
  isMicrosoft,
  userRole: role,
});

const mapDispatchToProps = {
  getAvatar,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
