import React, { FC, useState, useEffect, useRef } from "react";
import { Button } from "ss-ui";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { Trans, useTranslation } from "react-i18next";

import { Modal, DisplayQRCode } from "components/Generic";
import { FormInput, FormSwitch } from "components/Form";

import { cnSettings } from "pages/Settings";
import { RootState } from "store/reducers";
import { updateOTP } from "store/actions";
import { GetProfileOTPResponse } from "store/routines/user/getProfile";
import { Payload as PayloadUpdateOTP } from "store/routines/user/updateOTP";
import { toast } from "react-toastify";
import { compose } from "recompose";
import { FORM_OTP_ADMIN, removeSpaces, Routes, updateOnInputString, Validation } from "core";

export const cnOTPAdmin = cn("OTPAdmin");

type OTPForm = {
  otp: string;
};

type Props = InjectedFormProps<OTPForm> & {
  globalOtp?: boolean;
  otp?: GetProfileOTPResponse;
  updateOTP: (payload: PayloadUpdateOTP) => void;
};

const OTPAdmin: FC<Props> = ({
  globalOtp,
  otp,
  updateOTP,
  handleSubmit,
  reset,
}) => {
  const { t } = useTranslation();

  const [modalVerifyOTP, setModalVerifyOTP] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (otp && otp.enabled) {
      setModalVerifyOTP(false);
    }
  }, [otp?.enabled]);

  useEffect(() => {
    if (modalVerifyOTP) {
      reset();
      setFocus();
    }
  }, [modalVerifyOTP]);

  const setFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleOTPDisable = () => {
    toast.info(
      <Trans i18nKey="toast_global_otp_info">
        <a href={ Routes.settings.Security }> </a>
      </Trans>,
      { toastId: "global_otp_error" },
    );
  };

  const handleOTPUpdate = () => {
    if (otp && otp.enabled) {
      updateOTP({
        action: false,
        otp: "",
      });
    } else {
      setModalVerifyOTP(true);
    }
  };
  const handleSwitch = () => (globalOtp ? handleOTPDisable() : handleOTPUpdate());

  const handleForm = ({ otp }: OTPForm) =>
    updateOTP({
      action: true,
      otp,
    });

  return otp ? (
    <div className={ `${cnSettings("Container")} ${cnOTPAdmin()}` }>
      <div
        className={ cnSettings("SwitchItem") }
        id="container_hunt_password_services"
      >
        <div className={ cnSettings("Header") }>
          {t("profile_otp_header")}

          <div className={ cnSettings("Switch") }>
            <FormSwitch
              disabled={ globalOtp }
              fieldName="iconSwitchOTP"
              handleToggle={ handleSwitch }
              isOn={ otp.enabled }
            />
            {otp.enabled ? <p>{t("settings_switch_on")}</p> : <p className={ cnSettings("Switch-Disable") }>{t("settings_switch_off")}</p>}
          </div>
        </div>
        <div className={ cnSettings("Hint") }>{t("profile_otp_hint")}</div>
        {modalVerifyOTP && (
          <Modal
            className={ cnOTPAdmin("QRCodeModal") }
            handleClose={ () => setModalVerifyOTP(false) }
          >
            <header>{t("profile_modal_qr_code_header")}</header>
            <p>{t("profile_modal_qr_code_text")}</p>
            <DisplayQRCode value={ otp.url }/>
            <div className="otp-key">{otp.key}</div>

            <Form onSubmit={ handleSubmit(handleForm) }>
              <label>{t("profile_modal_qr_code_label")}</label>
              <Field
                autoFocus
                isShown
                component={ FormInput }
                inputRef={ inputRef }
                name="otp"
                placeholder={ t("form_input-OTP") }
                type="password"
                validate={ [Validation.required] }
                onInput={ (e: any) => updateOnInputString(e, removeSpaces) }
              />
              <Button
                id="button_profile_modal_qr_code_button"
                type="submit"
                onClick={ setFocus }
              >
                {t("profile_modal_qr_code_button")}
              </Button>
            </Form>
          </Modal>
        )}
      </div>
    </div>
  ) : null;
};

const mapStateToProps = ({ settings: { globalOtp }, user: { otp } }: RootState) => ({
  globalOtp,
  otp,
});

export default compose<Props, Partial<Props>>(
  connect(mapStateToProps, { updateOTP }),
  reduxForm<OTPForm>({
    form: FORM_OTP_ADMIN,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(OTPAdmin);
