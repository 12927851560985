import React, { FC, useContext, useEffect } from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";

import { FormInput } from "components/Form";

import { FIELD_TYPE, Validation, getFieldValue } from "core";

import { FieldContext } from "./Types";

type Props = {
  fieldType: FIELD_TYPE.OUTGOING_MAIL_PORT | FIELD_TYPE.INCOMING_MAIL_PORT;
};

const FieldMailPort: FC<Props> = ({ fieldType }) => {
  const { t } = useTranslation();
  const context = useContext(FieldContext);

  useEffect(() => {
    if (context.formData[fieldType]) {
      context.handleChange(fieldType, context.formData[fieldType]);
    } else {
      getFieldValue(fieldType, context.key).then(value => value && context.handleChange(fieldType, value));
    }
  }, [context.key]);

  return (
    <Field
      component={FormInput}
      label={t(`manage_key_field_${fieldType}_label`)}
      name={fieldType}
      placeholder={t(`manage_key_field_${fieldType}_placeholder`)}
      validate={[Validation.port, Validation.onlyDigit]}
    />
  );
};

export default FieldMailPort;
