/* eslint-disable @typescript-eslint/quotes */
import ClickAwayListener from "react-click-away-listener";
import React, { FC, useEffect, useState } from "react";
import { Button, Icon } from "ss-ui";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as msal from "@azure/msal-browser";
import { FormInput } from "components/Form";

import {
  FORM_LOGIN,
  LOGIN_TYPE,
  USER_STORAGE,
  Routes,
  typeAuthorizationSupports,
  IMicrosoftAuth,
  excludeCapitalLetters,
  removeSpaces,
  updateOnInputString,
  typeAuthenticateOptions,
  getPortalLink
} from "core";

import { PropsWithLoading, withLoading } from "hoc";

import { FormValues, Payload as PayloadLogin } from "store/routines/user/login";
import { RootState } from "store/reducers";
import { login, loginAzure, loginYubikey } from "store/actions";
import { Payload as PayloadLoginAzure } from "store/routines/user/loginAzure";
import { Payload as PayloadLoginYubikey } from "store/routines/user/loginYubikey";

import { useLogin } from "core/hook/yubikey";
import { cnLogin } from "../Login";

type Props = PropsWithLoading &
  InjectedFormProps<FormValues> &
  RouteComponentProps<undefined> & {
    authenticateOptions?: typeAuthenticateOptions;
    authorizationSupports: typeAuthorizationSupports;
    isLoading: boolean;
    login: (payload: PayloadLogin) => void;
    loginAzure: (payload: PayloadLoginAzure) => void;
    loginYubikey: (payload: PayloadLoginYubikey) => void;
    microsoftAuth: IMicrosoftAuth;
    setLoginType: (type: LOGIN_TYPE) => void;
    stateError: string;
    userStorage: USER_STORAGE;
  };

const LoginForm: FC<Props> = ({
  authenticateOptions,
  authorizationSupports,
  change,
  handleSubmit,
  isLoading,
  login,
  loginAzure,
  loginYubikey,
  microsoftAuth,
  setIsLoading,
  setLoginType,
  stateError,
  userStorage,
}) => {
  const { t } = useTranslation();
  const handlerLoginYubikey = useLogin({});

  const [showOTPHelp, setShowOTPHelp] = useState<boolean>(false);

  useEffect(() => {
    if (stateError) {
      change("password", null);
    }
  }, [stateError]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (authenticateOptions) {
      handlerLoginYubikey(authenticateOptions)
        .then(response => {
          loginYubikey(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [authenticateOptions]);

  const handleAzureLogin = () => {
    const msalConfig = {
      auth: {
        clientId: microsoftAuth.client_id || "",
        authority: `https://login.microsoftonline.com/${microsoftAuth.tenant_id || ""}`,
        redirectUri: `${window.location.origin}${Routes.Login}`,
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    const msalInstance = new msal.PublicClientApplication(msalConfig);

    msalInstance.loginPopup({ scopes: [] }).then((tokenResponse: any) => loginAzure(tokenResponse));
  };

  const handleLogin = (data: any) => {
    login({
      values: {
        otp: data.otp,
        password: data.password,
        save_account: false,
        username: data.username,
      },
    });
  };

  return (
    <Form className={cnLogin("Form")} onSubmit={handleSubmit(handleLogin)} id="page_login_form">
      <div className={cnLogin("FormHeader")}>
        <Icon name="icon-user" />
        <p>{t("login_login")}</p>
      </div>
      {stateError && (
        <div className={cnLogin("Error")}>
          <Icon fill="#E3000B" name="icon-warning" />
          {stateError === "error_account_is_blocked" ? (
            <div>
              <Trans i18nKey="error_account_is_blocked">
                <a href={`${getPortalLink(window.location.host)}/login`} target="_blank">Customer Portal</a>
              </Trans>
            </div>
          ) : (
            t(stateError)
          )}
        </div>
      )}
      <div className={cnLogin("FieldsContainer")}>
        <div className={cnLogin("FieldGroup")} id="field_group_login_username_password">
          <Field
            className={cnLogin("FieldGroupInput", { "has-error": !!stateError })}
            component={FormInput}
            name="username"
            placeholder={t(`${userStorage === USER_STORAGE.LOCAL ? "login_user_name_email" : "login_user_name"}`)}
            type="input"
            onInput={(e: any) => updateOnInputString(e, excludeCapitalLetters)}
          />

          <Field
            className={cnLogin("FieldGroupInput", { "has-error": !!stateError })}
            component={FormInput}
            isShown
            name="password"
            placeholder={t("login_user_pass")}
            type="password"
            onInput={(e: any) => updateOnInputString(e, removeSpaces)}
          />
        </div>
        <div className={cnLogin("Otp")}>
          <Field
            className={cnLogin("FieldGroupInputFlex")}
            component={FormInput}
            placeholder={t("login_otp_placeholder")}
            label={t("login_one_pass")}
            linkHandle={() => setShowOTPHelp(!showOTPHelp)}
            linkText={t("login_faq")}
            name="otp"
            type="input"
          />
          {showOTPHelp && (
            <ClickAwayListener onClickAway={() => setShowOTPHelp(false)}>
              <div className={cnLogin("Otp-Open")}>
                <p>
                  {t("login_otp")}
                  (&nbsp;
                  <a
                    href="https://apps.apple.com/us/app/accesssecurium/id1072367945"
                    rel="noopener noreferrer"
                    target="_blank"
                    id="link_download_ios_app"
                  >
                    iOS
                  </a>
                  &nbsp;|&nbsp;
                  <a
                    href="https://play.google.com/store/apps/details?id=ch.alpeinsoft.accesssecurium"
                    id="link_download_android_app"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    GooglePlay
                  </a>
                  &nbsp;).
                </p>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>

      <div className={cnLogin("ButtonContainer")} id="page_login_button_container">
        <Button id="button_login_ps" type="submit" width="100%">
          {t("login_login")}
        </Button>
        {authorizationSupports.microsoft && (
          <>
            <div className={cnLogin("ActionDelimiter")}>
              <div className="line">&nbsp;</div>
              <div className="or">{t("log_in_another")}</div>
              <div className="line">&nbsp;</div>
            </div>
            <Button
              type="button"
              onClick={() => handleAzureLogin()}
              id="btn_log_in_with_azure"
              theme="secondary-outline"
              width="100%"
              icon="colored/icon-azure"
            >
              {t("log_in_with_azure")}
            </Button>
          </>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = ({
  settings: { authorizationSupports, microsoftAuth, userStorage },
  user: { isLoading, error: stateError, authenticateOptions },
}: RootState) => ({
  authenticateOptions,
  authorizationSupports,
  isLoading,
  microsoftAuth,
  stateError,
  userStorage,
});

export default compose<Props, Partial<Props>>(
  withLoading,
  withRouter,
  connect(mapStateToProps, { loginAzure, login, loginYubikey }),
  reduxForm<FormValues>({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_LOGIN,
  }),
)(LoginForm);
