import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { put, takeLatest } from "redux-saga/effects";
import { get as lodashGet } from "lodash";

type Payload = {
  id?: string;
};

export const action: Routine = createRoutine("FOLDER_SET_ITEM_BY_ID");

function* handler({ payload }: Action<Payload>) {
  try {
    yield put(action.success(payload));
  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
