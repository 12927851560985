/* eslint-disable jsx-a11y/click-events-have-key-events, @typescript-eslint/no-unused-vars, prefer-const, array-callback-return, jsx-a11y/no-static-element-interactions */
import React, { FC, useState, useMemo } from "react";
import moment from "moment";
import { Icon, Checkbox } from "ss-ui";
import { Link } from "react-router-dom";
import { cn } from "@bem-react/classname";
import { compose } from "recompose";
import { connect } from "react-redux";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ButtonDiv, KeysActions } from "components/Generic";
import { cnTable } from "components/Table";
import { isUndefined } from "lodash";
import { PropsWithChosen, WithChosen } from "hoc";
import { RootState } from "store/reducers";
import { keyChose, folderChose, keyGet, favoritesSetKey } from "store/actions";

import {
  DATETIME_FORMAT_LITE,
  FOLDER_ACCESS,
  PayloadChoseFunction,
  Routes,
  getFolderFromTreeById,
  getKey,
  getTemplateIcon,
  parseDate,
  replaceUrlParam,
  typeFolder,
  typeSearchKey,
  validateURL,
  ROLE_TYPE,
  getKeyAnnotation,
} from "core";

import { Payload as PayloadFavoritesSetKey } from "store/routines/favorites/setKey";
import { Payload as PayloadKeyGet } from "store/routines/keys/get";

const cnKeysList = cn("KeysList");

type Props = {
  keyItem: typeSearchKey;
  chosenKeys: string[];
  foldersList: typeFolder[];
  isLoadingFavorites: boolean;
  isManager: boolean;
  isFree: boolean;
  keyChose: (payload: PayloadChoseFunction) => void;
  keyGet: (payload: PayloadKeyGet) => void;
  favoritesSetKey: (payload: PayloadFavoritesSetKey) => void;
  folderChose: (payload: PayloadChoseFunction) => void;
} & PropsWithChosen;

const FavoritesKeyItem: FC<Props> = ({
  chosenKeys,
  favoritesSetKey,
  folderChose,
  foldersList,
  handleChosenAllItems,
  isLoadingFavorites,
  isManager,
  isFree,
  keyChose,
  keyGet,
  keyItem,
}) => {
  const { t } = useTranslation();

  const [itemFavorites, setItemFavorites] = useState<boolean>(keyItem.favorite);
  const mainFolderID: typeFolder = useMemo(() => getFolderFromTreeById(foldersList, keyItem.mainFolderId), [foldersList, keyItem.mainFolderId]);
  const isHasAccess: boolean = useMemo(() => mainFolderID && [FOLDER_ACCESS.NO_ACCESS].includes(mainFolderID.access), [mainFolderID]);
  const { card_pin, login, password, card_number, client_number, doc_number, serial_number, hostname_ip } = JSON.parse(keyItem.data);

  let correctUrl: string = "";
  if (keyItem.uri) {
    correctUrl = keyItem.uri.includes("//") ? keyItem.uri : `http://${keyItem.uri}`;
  }

  const handleChosenKey = (id: string, folderID: string) => {
    handleChosenAllItems(folderChose, false, []);
    handleChosenAllItems(keyChose, !(chosenKeys.indexOf(id) >= 0), [id]);
    keyGet({ id, folderID });
  };

  const getSharedKeyIdFolder = () => {
    let childrenArray: any[] = [];
    if (keyItem.folders) {
      keyItem.folders.map(folderItem => {
        const folderInTree = getFolderFromTreeById(foldersList, folderItem);
        if (!isUndefined(folderInTree) && ![FOLDER_ACCESS.NO_ACCESS].includes(folderInTree?.access)) {
          childrenArray.push(folderInTree?.id);
        }
      });
    }
    return childrenArray.length > 0 ? childrenArray[0] : "";
  };

  const createKeySharingPath = () => {
    let sharedKeyPath: any = [];
    const sharedKeyPathId = getSharedKeyIdFolder();
    const folderInTree = getFolderFromTreeById(foldersList, sharedKeyPathId);
    const keyPath = (folderRoot: typeFolder) => {
      sharedKeyPath.push(folderRoot?.title);
      if (folderRoot?.root != null) {
        keyPath(getFolderFromTreeById(foldersList, folderRoot?.root));
      }
    };
    keyPath(folderInTree);

    return sharedKeyPath.reverse().join("/");
  };

  const getFolderID = () => {
    if (!mainFolderID || isHasAccess) {
      return getSharedKeyIdFolder();
    }
    return keyItem.mainFolderId;
  };

  const getKeySharingPath = () => {
    if (mainFolderID && !isHasAccess) {
      return keyItem.path.join("/");
    }
    return createKeySharingPath();
  }

  return (
    <ButtonDiv
      onClick={() => handleChosenKey(keyItem.id, isFree ? keyItem.mainFolderId : getFolderID())}
      key={keyItem.id}
      id={getKey("btn_key_chosen", Number(keyItem.id))}
      className={cnTable("Row", { chosen: chosenKeys.includes(keyItem.id) })}
    >
      <ButtonDiv id={getKey("btn_key_chosen_checkbox", 1)} onClick={e => e.stopPropagation()} className={cnKeysList("Checkbox Table-Th")}>
        <Checkbox
          checked={chosenKeys.includes(keyItem.id)}
          onChange={() => handleChosenKey(keyItem.id, isFree ? keyItem.mainFolderId : getFolderID())}
        />
      </ButtonDiv>

      <div className={cnKeysList("Type Table-Th")}>
        {keyItem.logo ? (
          <div className={cnKeysList("Logo")}>
            <img alt="" className={cnKeysList("Logo-Image")} src={keyItem.logo} />
            <div className={cnKeysList("Logo-Icon")}>
              <Icon name={getTemplateIcon(keyItem.template)} width={9} />
            </div>
          </div>
        ) : (
          <div className={cnKeysList("Logo Logo-Mask")}>
            <p className={cnKeysList("Logo-Text")}>{getKeyAnnotation(keyItem.title)}</p>
            <div className={cnKeysList("Logo-Icon")}>
              <Icon name={getTemplateIcon(keyItem.template)} width={9} />
            </div>
          </div>
        )}
      </div>

      <div className={cnKeysList("Title Table-Th")}>
        <div className={cnKeysList("Title-Column")}>
          <p title={keyItem.title}>{keyItem.title}</p>
          <span title={getKeySharingPath()}>
            {getKeySharingPath()}
          </span>
        </div>
        <div className={cnKeysList("Title-Icon")}>
          {keyItem.folders && keyItem.folders.length > 0 && (
            <Icon className={mainFolderID && !isHasAccess ? "SearchSharing" : ""} width={16} height={16} name="icon-shared" />
          )}
          {keyItem.sealing && isManager && <Icon className="SearchSharing" name="icon-sealing" width={16} height={16} />}
          {mainFolderID && (
            <button
              type="button"
              id={getKey("btn_favorite_key", Number(keyItem.id))}
              className={isLoadingFavorites ? "KeysList-Action-Favorite" : "KeysList-Action-Favorite tooltip tooltip-left"}
              onClick={e => {
                e.stopPropagation();
                favoritesSetKey({ folderID: keyItem.mainFolderId, keyID: keyItem.id });
              }}
              data-tooltip={t("tooltip_remove-favorite")}
            >
              <Icon width={16} height={16} name="icon-star-fill" />
            </button>
          )}
        </div>
      </div>
      <div className={cnKeysList("Date Table-Th")}>{moment(parseDate(keyItem.updatedAt)).format(DATETIME_FORMAT_LITE)}</div>
      <div className={cnKeysList("Action Table-Th")}>
        <ButtonDiv
          onClick={e => e.stopPropagation()}
          className={cnKeysList("Action-Button", { chosen: chosenKeys.includes(keyItem.id) })}
          id={getKey("btn_stop_propagation", 1)}
        >
          <Icon name="icon-actions" width={16} height={16} />
        </ButtonDiv>
        <ButtonDiv onClick={e => e.stopPropagation()} className={cnKeysList("Action-Row", { chosen: chosenKeys.includes(keyItem.id) })}>
          {mainFolderID && (
            <>
              {![FOLDER_ACCESS.READ, FOLDER_ACCESS.NO_ACCESS].includes(mainFolderID.access) && (
                <Link
                  to={replaceUrlParam(replaceUrlParam(Routes.ManageKey, "id", keyItem.id), "folderID", keyItem.mainFolderId)}
                  id={getKey(`link_to_manage_key_${keyItem.title}`)}
                  data-tooltip={t("tooltip-edit")}
                  className="KeysList-Action-Item tooltip tooltip-left"
                >
                  <Icon name="icon-pencil" width={16} height={16} />
                </Link>
              )}
            </>
          )}
          {correctUrl ? (
            <a
              href={validateURL(correctUrl)}
              target="_blank"
              className="KeysList-Action-Item tooltip tooltip-left"
              data-tooltip={t("tooltip-show")}
            >
              <Icon name="icon-open" width={16} height={16} />
            </a>
          ) : null}

          {!isSafari && card_pin && (
            <KeysActions
              value={card_pin}
              encryptionEnabled={keyItem.encryptionEnabled}
              iconName="icon-copy"
              tooltipName="keys_copy_card_pin"
              toastName="toast_card_pin_success"
            />
          )}

          {!isSafari && hostname_ip && (
            <KeysActions
              value={hostname_ip}
              encryptionEnabled={keyItem.encryptionEnabled}
              iconName="icon-copy"
              tooltipName="keys_copy_hostname_ip"
              toastName="toast_hostname_ip_success"
            />
          )}

          {!isSafari && login && (
            <KeysActions
              value={login}
              encryptionEnabled={keyItem.encryptionEnabled}
              iconName="icon-copy-login"
              tooltipName="tooltip-login"
              toastName="toast_login_copy"
            />
          )}

          {!isSafari && password && (
            <KeysActions
              value={password}
              encryptionEnabled={keyItem.encryptionEnabled}
              iconName="icon-copy-pass"
              tooltipName="tooltip-pass"
              toastName="toast_password_copy"
            />
          )}

          {!isSafari && card_number && (
            <KeysActions
              value={card_number}
              encryptionEnabled={keyItem.encryptionEnabled}
              tooltipName="keys_copy_card_number"
              toastName="toast_card_number_success"
            />
          )}

          {!isSafari && client_number && (
            <KeysActions
              value={client_number}
              encryptionEnabled={keyItem.encryptionEnabled}
              tooltipName="keys_copy_client_number"
              toastName="toast_client_number_success"
            />
          )}

          {!isSafari && doc_number && (
            <KeysActions
              value={doc_number}
              encryptionEnabled={keyItem.encryptionEnabled}
              tooltipName="keys_copy_doc_number"
              toastName="toast_doc_number_success"
            />
          )}

          {!isSafari && serial_number && (
            <KeysActions
              value={serial_number}
              encryptionEnabled={keyItem.encryptionEnabled}
              tooltipName="keys_copy_serial_number"
              toastName="toast_serial_number_success"
            />
          )}
          <Link
            className="KeysList-Action-Item tooltip tooltip-left"
            data-tooltip={t("search-to-folder-parent-action")}
            id={getKey(`link_to_folder_${keyItem.title}`)}
            to={replaceUrlParam(Routes.Keys, "folderID", isFree ? keyItem.mainFolderId : getFolderID())}
          >
            <Icon name="icon-to-folder" width={16} height={16} />
          </Link>
        </ButtonDiv>
      </div>
    </ButtonDiv>
  );
};

const mapStateToProps = ({
  favorites: { isLoading: isLoadingFavorites },
  folders: { list: foldersList },
  keys: { chosen: chosenKeys },
  user: { role },
}: RootState) => ({
  isManager: role === ROLE_TYPE.MANAGER,
  isFree: [ROLE_TYPE.FREE_USER, ROLE_TYPE.STANDARD_USER].includes(role),
  chosenKeys,
  foldersList,
  isLoadingFavorites,
});

const mapDispatchToProps = {
  favoritesSetKey,
  folderChose,
  keyChose,
  keyGet,
};

export default compose<Props, Partial<Props>>(WithChosen, connect(mapStateToProps, mapDispatchToProps))(FavoritesKeyItem);
