/* eslint-disable react/jsx-no-target-blank, react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from "react";
import { SingleValue } from "react-select";
import { Button } from "ss-ui";
import { RootState } from "store/reducers";
import { cn } from "@bem-react/classname";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import { AsyncPaginate } from "react-select-async-paginate";

import { ITabs } from "components/Generic/Tabs/Tabs";
import { LoadingContainer, Tabs, Pagination } from "components/Generic";
import { customSelectStyles } from "components/Form/Select";

import { IActiveSession, IManageSessionsRequest, optionAllUser, ROLE_TYPE, typeOption, http } from "core";

import { cnSettings } from "pages/Settings";

import { Payload as payloadCloseSession } from "store/routines/user/closeSession";
import { Payload as payloadCloseUsersSession } from "store/routines/manager/closeUsersSession";
import { Payload as payloadGetActiveSessions } from "store/routines/user/getSessions";
import { Payload as payloadGetUsersSessions } from "store/routines/manager/getUsersSessions";
import {
  closeSession,
  closeSessions,
  closeUsersSession,
  closeUsersSessions,
  getSessions,
  getUsersSessions,
  getProfile,
} from "store/actions";

import Session from "./Session";

export const cnSessionsControl = cn("SessionsControl");

type Props = {
  activeSessions: IManageSessionsRequest;
  activeSessionsForAdmin: IManageSessionsRequest;
  closeSession: (payload: payloadCloseSession) => void;
  closeSessions: () => void;
  closeUsersSession: (payload: payloadCloseUsersSession) => void;
  closeUsersSessions: () => void;
  getProfile: () => void;
  fullName?: string;
  email?: string;
  getSessions: (payload: payloadGetActiveSessions) => void;
  getUsersSessions: (payload: payloadGetUsersSessions) => void;
  isLoadingManager: boolean;
  isLoadingUser: boolean;
  isLoadingUsers: boolean;
  role: ROLE_TYPE;
  username?: string;
};

const SessionsControl: FC<Props> = ({
  activeSessions,
  activeSessionsForAdmin,
  closeSession,
  closeSessions,
  closeUsersSession,
  closeUsersSessions,
  getProfile,
  fullName,
  email,
  getSessions,
  getUsersSessions,
  isLoadingManager,
  isLoadingUser,
  isLoadingUsers,
  role,
  username,
}) => {
  const { t } = useTranslation();

  const isAdmin = useMemo(() => role === ROLE_TYPE.ADMIN, [role]);

  const [selectedUser, setSelectedUser] = useState<typeOption>(optionAllUser);
  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
  const [tabs, setTabs] = useState<ITabs[]>([
    {
      id: 1,
      isActive: isAdmin,
      label: t("settings_active_sessions_subheader_users_sessions"),
    },
    {
      id: 2,
      isActive: !isAdmin,
      label: t("settings_active_sessions_subheader_user_session"),
    },
  ]);

  /**
   * UseEffect one time when component loaded
   */
  useEffect(() => {
    if (email) {
      setTabs([
        {
          id: 1,
          isActive: isAdmin,
          label: t("settings_active_sessions_subheader_users_sessions"),
        },
        {
          id: 2,
          isActive: !isAdmin,
          label: t("settings_active_sessions_subheader_admin_sessions"),
        },
      ]);
    }
  }, [email]);

  /**
   * UseEffect one time when component loaded
   */
  useEffect(() => {
    getSessions({});
    getProfile();

    if (isAdmin) {
      getUsersSessions({});
    }
  }, []);

  const handleChangeTab = (id: number) => {
    setTabs(tabs.map((tab: ITabs) => ({ ...tab, isActive: tab.id === id })));
  };

  const handleChangeUser = (value: SingleValue<typeOption>) => {
    setSelectedUser(value as typeOption);
    getUsersSessions({ user: value?.value as string });
  };

  const handleChangePageAllSessions = (page: number) => {
    getUsersSessions({ page, user: selectedUser.value as string });
  };

  const handleChangePageUserSession = (page: number) => {
    getSessions({ page });
  };

  return (
    <LoadingContainer isLoading={isLoadingManager || isLoadingUser || isLoadingUsers}>
      <div className={cnSettings("Container")}>
        <div className={cnSessionsControl("ActiveSession")} id="container_active_session">
          <div className={cnSessionsControl("Header")}>{t("settings_active_sessions_header")}</div>
          <p className={cnSessionsControl("Hint")}>{t("settings_active_sessions_help_text")}</p>

          <Tabs tabs={tabs} onClick={handleChangeTab} />

          <div className="tabs">
            {tabs[0].isActive && (
              <>
                <div className={cnSessionsControl("SelectUser")}>
                  <label>{t("settings_active_sessions_subheader_users_filter")}</label>
                  <AsyncPaginate
                    debounceTimeout={1000}
                    styles={customSelectStyles()}
                    value={selectedUser}
                    hideSelectedOptions={false}
                    onChange={handleChangeUser}
                    id="select_filter_by_user"
                    isSearchable={true}
                    options={[optionAllUser]}
                    loadOptions={async selectSearchUsers => {
                      let params: { currentPage: number; pageSize: number; search?: string } = {
                        currentPage: currentPageUsers,
                        pageSize: 20,
                        search: selectSearchUsers,
                      };
                      if (currentPageUsers) params = { ...params, currentPage: currentPageUsers };
                      if (selectSearchUsers !== "") params = { ...params, search: selectSearchUsers, currentPage: 1 };
                      const response: any = await http({ route: "manager/users", method: "GET", payload: { params } });
                      setCurrentPageUsers(response.currentPage + 1);

                      return {
                        hasMore: currentPageUsers < response.pagesCount,
                        options: response.results.map(user => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.username,
                        })),
                      };
                    }}
                  />
                  <Button className="mt-20" theme="danger-outline" onClick={() => closeUsersSessions()} id="btn_close_all_users_session">
                    {t("settings_active_sessions_btn_terminate_users_sessions")}
                  </Button>
                </div>
                <div className={cnSessionsControl("UserSessions")}>
                  <label>{t("settings_active_sessions_subheader_users_sessions")}</label>
                  <ul className="session-list" id="sessions_list">
                    {!isEmpty(activeSessionsForAdmin.tokens) ? (
                      activeSessionsForAdmin.tokens.map((session: IActiveSession) => (
                        <Session session={session} key={session.id} closeSession={closeUsersSession} />
                      ))
                    ) : (
                      <div className={cnSessionsControl("EmptyList")}>{t("settings_active_sessions_list_empty")}</div>
                    )}
                  </ul>
                  {!isEmpty(activeSessionsForAdmin.tokens) &&
                    Math.ceil(activeSessionsForAdmin.count / activeSessionsForAdmin.limit) > 1 && (
                      <Pagination
                        currentPage={Math.ceil(activeSessionsForAdmin.offset / activeSessionsForAdmin.limit + 1)}
                        id="pagination_for_active_sessions_for_admin"
                        itemPagesCount={Math.ceil(activeSessionsForAdmin.count / activeSessionsForAdmin.limit)}
                        onChange={selected => handleChangePageAllSessions(selected - 1)}
                      />
                    )}
                </div>
              </>
            )}

            {tabs[1].isActive && (
              <div className={cnSessionsControl("UserSessions")}>
                <Button theme="danger-outline" onClick={() => closeSessions()} id="btn_close_users_session">
                  {t("settings_active_sessions_btn_user_sessions")}
                </Button>
                <label>{t("settings_active_sessions_subheader_user_session")}</label>
                <ul className="session-list" id="sessions_list">
                  {!isEmpty(activeSessions.tokens) ? (
                    activeSessions.tokens.map((session: IActiveSession) => (
                      <Session session={session} key={session.id} closeSession={closeSession} />
                    ))
                  ) : (
                    <div className={cnSessionsControl("EmptyList")}>{t("settings_active_sessions_list_empty")}</div>
                  )}
                </ul>
                {!isEmpty(activeSessions.tokens) && Math.ceil(activeSessions.count / activeSessions.limit) > 1 && (
                  <Pagination
                    currentPage={Math.ceil(activeSessions.offset / activeSessions.limit + 1)}
                    id="pagination_for_active_sessions"
                    itemPagesCount={Math.ceil(activeSessions.count / activeSessions.limit)}
                    onChange={selected => handleChangePageUserSession(selected - 1)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};

const mapStateToProps = ({
  manager: { activeSessions: activeSessionsForAdmin, isLoading: isLoadingManager },
  user: { role, activeSessions, isLoading: isLoadingUser, username, fullName, email },
  users: { isLoading: isLoadingUsers },
}: RootState) => ({
  activeSessions,
  activeSessionsForAdmin,
  fullName,
  email,
  isLoadingManager,
  isLoadingUser,
  isLoadingUsers,
  role,
  username,
});

const mapDispatchToProps = {
  closeSession,
  closeSessions,
  closeUsersSession,
  closeUsersSessions,
  getSessions,
  getUsersSessions,
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsControl);
