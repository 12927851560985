import { SORT_BY } from "core";
import { typeTableHeaderElement } from "components/Table";

export const tableHeader: typeTableHeaderElement[] = [
  {
    id: "group_title",
    title: "Title",
    sortInit: SORT_BY.NAME,
  },
  {
    id: "group_folders",
    title: "Folders",
    sortInit: SORT_BY.FOLDERS,
  },
  {
    id: "group_members",
    title: "Members",
    sortInit: SORT_BY.USERS,
  },
];
