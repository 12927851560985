import { Action } from "redux-actions";
import { typeSearchKey, unpackSearchKeys, typeSearchFolder } from "core";
import {
  favoritesClear,
  favoritesGet,
  favoritesGetFolders,
  favoritesGetKeys,
  favoritesSetFolder,
  favoritesSetKey
} from "store/actions";

export interface State {
  count: number;
  error?: string;
  isLoading: boolean;
  isLoadingKeys: boolean;
  isLoadingFolders: boolean;
  // keys
  keysList: typeSearchKey[];
  keysCurrentPage: number;
  keysPagesCount: number;
  keysTotalItems: number;
  // folders
  foldersList: typeSearchFolder[];
  foldersCurrentPage: number;
  foldersPagesCount: number;
  foldersTotalItems: number;
}

const initialState: State = {
  count: 0,
  error: undefined,
  isLoading: false,
  isLoadingKeys: false,
  isLoadingFolders: false,
  keysList: [],
  keysCurrentPage: 1,
  keysPagesCount: 1,
  keysTotalItems: 0,
  foldersList: [],
  foldersCurrentPage: 1,
  foldersPagesCount: 1,
  foldersTotalItems: 0,
};

export default function favorites(
  state: State = initialState,
  { payload, type }: Action<any>,
): State {
  switch (type) {
    case favoritesGet.TRIGGER:
      return {
        ...state,
        isLoading: true,
      }
    
    case favoritesSetKey.TRIGGER:
      return {
        ...state,
        isLoading: true,
        isLoadingKeys: true,
      };
    
    case favoritesGetKeys.TRIGGER:
      return {
        ...state,
        isLoadingKeys: true,
      }
    
    case favoritesSetFolder.TRIGGER:
      return {
        ...state,
        isLoading: true,
        isLoadingFolders: true,
      }

    case favoritesGetFolders.TRIGGER:
      return {
        ...state,
        isLoadingFolders: true,
      };

    case favoritesClear.SUCCESS:
      return {
        ...initialState,
      };

    case favoritesGet.SUCCESS:
      return {
        ...state,
        count: payload.countFavorites,
        isLoading: false,
      };

    case favoritesGetFolders.SUCCESS:
      return {
        ...state,
        foldersList: payload.results,
        foldersCurrentPage: payload.currentPage,
        foldersPagesCount: payload.pagesCount,
        foldersTotalItems: payload.totalItems,
        isLoadingFolders: false,
      };

    case favoritesGetKeys.SUCCESS:
      return {
        ...state,
        keysList: unpackSearchKeys(payload.results),
        keysCurrentPage: payload.currentPage,
        keysPagesCount: payload.pagesCount,
        keysTotalItems: payload.totalItems,
        isLoadingKeys: false,
      };

    case favoritesSetFolder.SUCCESS:
      return {
        ...state,
        foldersList: payload.status
          ? state.foldersList
          : state.foldersList.filter(
            (item: typeSearchFolder) => item.id !== payload.folderID
          ),
        count: payload.status ? state.count + 1 : state.count - 1,
        foldersTotalItems: payload.status ? state.foldersTotalItems + 1 : state.foldersTotalItems - 1,
        isLoading: false,
        isLoadingFolders: false,
      };

    case favoritesSetKey.SUCCESS:
      return {
        ...state,
        keysList: payload.status
          ? state.keysList
          : state.keysList.filter((item: typeSearchKey ) => item.id !== payload.keyID),
        count: payload.status ? state.count + 1 : state.count - 1,
        keysTotalItems: payload.status ? state.keysTotalItems + 1 : state.keysTotalItems - 1,
        isLoading: false,
        isLoadingKeys: false,
      };

    case favoritesGet.FAILURE:
    case favoritesSetFolder.FAILURE:
    case favoritesSetKey.FAILURE:
    case favoritesGetKeys.FAILURE:
    case favoritesGetFolders.FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        isLoadingKeys: false,
        isLoadingFolders: false,
      };

    default:
      return state;
  }
}
