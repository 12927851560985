import { typeTableHeaderElement } from "components/Table";

import { SORT_BY } from "core";

export const tableHeader: typeTableHeaderElement[] = [
  {
    id: "activity_log_table_header_date",
    title: "activity_log_table_header_date",
    sortInit: SORT_BY.DATE,
  },
  {
    id: "activity_log_table_header_object",
    title: "activity_log_table_header_object",
    sortInit: SORT_BY.OBJECT,
  },
  {
    id: "activity_log_table_header_title",
    title: "activity_log_table_header_title",
    // sortInit: SORT_BY.TITLE,
  },
  {
    id: "activity_log_table_header_operation",
    title: "activity_log_table_header_operation",
    sortInit: SORT_BY.OPERATION,
  },
  {
    id: "activity_log_table_header_user",
    title: "activity_log_table_header_user",
    sortInit: SORT_BY.USER,
  },
];
