import moment from "moment";
import { CONVEYANCE_FORMAT, CONVEYANCE_PRODUCTS } from "pages/Settings/SettingsType";
import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

import { http, sendFileForDownloading } from "core";
import { get as lodashGet, isUndefined } from "lodash";

export const action: Routine = createRoutine("KEYS_EXPORT");

export interface Payload {
  format: CONVEYANCE_FORMAT;
  product: CONVEYANCE_PRODUCTS;
  listFolders: string[];
  encryptedPassword: string;
  includeSubFolders: string;
}

const keysExport = ({ format, product, listFolders, encryptedPassword, includeSubFolders }: Payload) => {
  if (listFolders.length > 0) {
    const stringFolders = listFolders.join("&folders[]=");
    return http({
      route: `key/export/${product}?format=${format}&folders[]=${stringFolders}&provideEncryptedPassword=${encryptedPassword}&includeSubfolders=${includeSubFolders}`,
      responseType: "blob",
    });
  }
  return http({
    route: `key/export/${product}?format=${format}&provideEncryptedPassword=${encryptedPassword}&includeSubfolders=${includeSubFolders}`,
    responseType: "blob",
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response: Blob = yield call(keysExport, payload);

    sendFileForDownloading(response, `${payload.product}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.${payload.format}`);

    yield put(action.success());
    toast.success(i18n.t("toast_success_export_key"));
  } catch (error) {
    const status = lodashGet(error, "response.status", undefined);

    if (isUndefined(status)) {
      toast.error(i18n.t("settings_access_control_denied"));
    } else {
      toast.error(i18n.t(status === 500 ? "toast_error_500" : "toast_error_export_key"));
    }

    yield put(action.failure(lodashGet(error, "response.message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
