import { Action } from "redux-actions";
import { createRoutine, Routine } from "redux-saga-routines";
import { http, IManageSessionsRequest } from "core";
import { call, put, takeLatest } from "redux-saga/effects";

export const action: Routine = createRoutine("MANAGER_GET_USERS_SESSIONS");

export interface Payload {
  page?: number;
  user?: string;
}

const getUsersSessions = ({ page = 0, user }: Payload) => {
  const PER_PAGE: number = 6;

  return http({
    route: "manager/active-session",
    payload: {
      params: {
        offset: page * PER_PAGE,
        limit: PER_PAGE,
        user: user !== "all" ? user : null,
      },
    },
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response: IManageSessionsRequest = yield call(getUsersSessions, payload);

    yield put(action.success(response));
  } catch (error) {
    yield put(action.failure(null));
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
