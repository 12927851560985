import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest } from "redux-saga/effects";
import { Action } from "redux-actions";
import { http } from "core";
import { get as lodashGet } from "lodash";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

export const action: Routine = createRoutine("SETTINGS_SET_CUSTOMIZE_MAILER_CHECK");

export type Payload = {
  dataValue: {
    mailerEncryption: string, 
    mailerHost: string,
    mailerPassword: string,
    mailerPort: string,
    mailerUser: string,
    mailerSendMail: string,
    mailerSendName: string
  },
  emailTo?: string;
}

const checkCustomizeMailerStatus = ({
  dataValue,
  emailTo
}: Payload) => {
  const formData = new FormData();

  formData.append("mailerEncryption", dataValue.mailerEncryption);
  formData.append("mailerHost", dataValue.mailerHost);
  formData.append("mailerPassword", dataValue.mailerPassword);
  formData.append("mailerPort", dataValue.mailerPort);
  formData.append("mailerUser", dataValue.mailerUser);
  formData.append("mailerSendMail", dataValue.mailerSendMail);
  formData.append("mailerSendName", dataValue.mailerSendName);
  if (emailTo) {
    formData.append("mailerSendTo", emailTo);
  }

  return http({
    route: "global/mailer/check",
    method: "POST",
    payload: formData,
  });
};

function* handler({ payload }: Action<Payload>) {
  try {
    const response = yield call(checkCustomizeMailerStatus, payload);

    if (payload.emailTo) {
      if (response.status) {
        toast.success(i18n.t("customize_mail_toast_send_success"));
      } else {
        toast.error(i18n.t("customize_mail_toast_send_error"));
      }
    } else if (response.status) {
      toast.success(i18n.t("customize_mail_toast_check_success"));
    } else {
      toast.error(i18n.t("customize_mail_toast_check_error"));
    }

    yield put(action.success(response));

  } catch (error) {
    yield put(action.failure(lodashGet(error, "message", undefined)));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
