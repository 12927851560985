/* eslint-disable no-nested-ternary */
import Color from "color";
import React, { FC, useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { Button, Icon } from "ss-ui";
import { WrappedFieldArrayProps, FieldArrayFieldsProps, Field } from "redux-form";
import { connect } from "react-redux";
import { cn } from "@bem-react/classname";
import { useTranslation } from "react-i18next";

import { FormCheckbox, FormSelect } from "components/Form";

import { typeGroup, getKey, typeOption, getGroupFromListByID } from "core";

import { RootState } from "store/reducers";
import { SettingsCustomizeState } from "store/reducers/settings/customize";

const cnRenderGroup = cn("RenderGroup");

type Props = WrappedFieldArrayProps & {
  groupList: typeGroup[];
  parentGroupList?: any[];
  fields: FieldArrayFieldsProps<any>;
  userName: string | undefined;
  isAdmin: boolean;
  customize: SettingsCustomizeState;
  folderGroupsArray?: any;
  isNew?: boolean
  countFields: (count: number) => void;
};

const RenderGroups: FC<Props> = ({ 
  fields, 
  groupList, 
  userName, 
  isAdmin, 
  customize, 
  folderGroupsArray, 
  parentGroupList,
  isNew,
  countFields, 
 }) => {

  const { t } = useTranslation();

  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [mainColor, setMainColor] = useState<string>("#1a90bb");
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (customize && customize.colorMain) {
      setMainColor(customize.colorMain);
    }
  }, [customize]);

  useEffect(() => {
    countFields(fields.length);
  }, [fields]);

  useEffect(() => {
    if (isNew) {
      parentGroupList && parentGroupList.filter(
        (items) => !fields.map((group, index) => fields.get(index).group).includes(items.group) 
        ).map((item) => 
        fields.push({
          group: item.group,
          access: item.access,
          recursion: null,
        })
      )
    }
  }, []);
  
  const templateType = ({ value, label }) => (
    <div className="ManageKey-Option">
      <Icon name="icon-group-circle" width={20} height={20} />
      &nbsp;
      <p title={label}>{label}</p>
    </div>
  );

  const handleAddGroup = (value: string) => {
    fields.push({
      group: value,
      access: "write",
      recursion: false,
    });
    setShowSelect(!showSelect);
  };

  const handleRemoveGroup = (value: number, groupID: string) => {
    fields.remove(value);
  }

  const optionsGroupsList: typeOption[] = useMemo(
    () =>
      groupList
        .filter(item => (!isAdmin ? item.users.some(x => x.username === userName) : item))
        .map(({ id, name }: typeGroup) => ({
          value: id,
          label: name,
          isDisabled: (fields.map((group, index) => fields.get(index).group).includes(id)) || (String(parentGroupList?.map(e => e.group)).includes(id)),
        })),
    [groupList, fields, parentGroupList],
  );

  const optionAccess = [
    {
      label: t("folder-asses-write"),
      value: "write",
    },
    {
      label: t("folder-asses-read"),
      value: "read",
    },
    {
      label: t("folder-asses-no_access"),
      value: "no_access",
    },
  ];

  return (
    <div className={cnRenderGroup()}>
      {fields.length > 0 && <span className={cnRenderGroup("List-Info")}>{t("groups-List")}</span>}
      {fields.length > 0 && (<div className={cnRenderGroup("Header")}>
        <div className={cnRenderGroup("Search")}>
          <div className={cnRenderGroup("Search-Icon")}>
            <Icon name="icon-search" fill="#727272" width={16} height={16} />
          </div>
          <input type="text" id="search" value={search} onChange={e => setSearch(e.target.value)} />
        </div>
        <span className={cnRenderGroup("Header-Info")}>{t("folder-checkbox-include")}</span>
      </div>)}
      {fields.length > 0 && (
        <div className={cnRenderGroup("List")}>
          {fields.map((group, index) => {
            const itemGroup: typeGroup = getGroupFromListByID(groupList, fields.get(index).group);
            const noAccessGroup = itemGroup.users.some(user => user.username.includes(String(userName)));
            const recursionGroup = parentGroupList?.map(e => e.group).includes(fields.get(index).group);
            const noAccess = ["no_access", "read"].includes(folderGroupsArray?.filter((item) => item?.group === fields.get(index).group).map((e) => e?.access)[0]);
            return (
              <div
                className={cnRenderGroup("FieldGroup", {
                  hide: itemGroup.name.toLowerCase().indexOf(search.toLowerCase()) === -1,
                  // noAccess: recursionGroup ? true : isAdmin ? false : !noAccessGroup,
                })}
                key={getKey(`${group}.group`, index)}
              >
                <div className={cnRenderGroup("Name", {
                  noAccess: recursionGroup ? true : isAdmin ? false : !noAccessGroup,
                })}>
                  <Icon name="icon-group-circle" width={25} height={25} />
                  <p title={itemGroup.name}>{itemGroup.name}</p>
                </div>
                <div className={cnRenderGroup("Row")}>
                  <Field
                    name={`${group}.access`}
                    className={cnRenderGroup("Select")}
                    isSearchable={false}
                    component={FormSelect}
                    disabled={isAdmin ? false : !noAccessGroup || noAccess}
                    options={optionAccess}
                    template={(label: string, value: string): JSX.Element => (
                      <div className={cnRenderGroup("Option")}>
                        <span className={cnRenderGroup("Option-Icon", { value })} />
                        <span className={cnRenderGroup("Option-Hide")}>{label}</span>
                      </div>
                    )}
                  />
                  <Field
                    name={`${group}.recursion`}
                    component={FormCheckbox}
                    disabled={recursionGroup ? true : isAdmin ? false : !noAccessGroup || noAccess}
                    className={cnRenderGroup("Recursion")}
                  />
                </div>
                <div className={cnRenderGroup("Row-Member")}>
                  <div className={cnRenderGroup("Member")}>
                    <Icon fill={mainColor} name="icon-user" />
                    <span>{itemGroup.users.length}</span>
                  </div>
                </div>
                {(isAdmin || (noAccessGroup && !noAccess)) && !recursionGroup && (
                  <button
                    type="button"
                    onClick={() => handleRemoveGroup(index, itemGroup.id)}
                    id="btn_trash_folder_from_export_2"
                    className={cnRenderGroup("Remove")}
                  >
                    <Icon name="icon-trash" width={16} height={16} fill="#A7A7A8" />
                  </button>
                )}
              </div>
            );
          })}
        </div>)}
      {showSelect ? (
        <Select
          className={cnRenderGroup("Select-Name")}
          classNamePrefix={cnRenderGroup("Select-Name")}
          isSearchable={true}
          placeholder={t("form_input_select-Groups")}
          options={optionsGroupsList}
          formatOptionLabel={templateType}
          onChange={option => handleAddGroup(String(option?.value))}
          menuPlacement={ fields.length >= 3 ? "top" : "bottom" }
          styles={{
            indicatorSeparator: (provided: any) => ({
              ...provided,
              backgroundColor: "transparent",
            }),
          }}
          theme={theme => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary25: "#EAFBFD",
              primary75: mainColor,
              primary50: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
              primary: Color(mainColor).alpha(0.5).lighten(0.3).hsl().string(),
            },
          })}
        />
      ) : (
        <Button
          id="button_add_group"
          className={cnRenderGroup("ButtonAdd")}
          icon="icon-plus"
          onClick={() => setShowSelect(!showSelect)}
          theme="primary-link"
          type="button"
        >
          {t("button_add-Group")}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = ({ settingsTest: { customize } }: RootState) => ({
  customize,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RenderGroups);
