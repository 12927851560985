import moment from "moment";
import { createRoutine, Routine } from "redux-saga-routines";
import { call, put, takeLatest, } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "locales/i18n";

import { http, sendFileForDownloading } from "core";

export const action: Routine = createRoutine("KEYS_FILE_EXPORT");

const keyFilesExport = () => {
  return http({ route: "key/attachment", responseType: "blob" });
};

function* handler() {
  try {
    const response: Blob = yield call(keyFilesExport);

    if (response === null) {
      toast.error(i18n.t("toast_key_error_archive"));
    } else {
      toast.success(i18n.t("toast_key_success_archive"));
      sendFileForDownloading(response, `attachment-${moment().format("YYYY-MM-DD-HH-mm-ss")}.zip`);
    }

    yield put(action.success());
  } catch (error) {
      toast.error(i18n.t("settings_access_control_denied"));
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export function* saga() {
  yield takeLatest(action.TRIGGER, handler);
}
