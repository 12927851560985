/**
 * Option display key
 */
export enum DISPLAY_TYPE {
  LIST = "list",
  TILE = "tile",
}

/**
 * Option display folder in menu
 */
export enum FOLDER_STRUCTURE {
  TREE = "tree",
  SINGLE = "single",
}

/**
 * User Role
 */
export enum ROLE_TYPE {
  ADMIN = "ROLE_ADMINISTRATOR",
  MANAGER = "ROLE_MANAGER",
  USER = "ROLE_USER",
  FREE_USER = "ROLE_FREE_USER",
  STANDARD_USER = "ROLE_STANDARD_USER",
}

/**
 * Option login type
 */
export enum LOGIN_TYPE {
  SIMPLE = "simple",
  YUBIKEY = "yubikey",
}

/**
 * Option folder type
 */
export enum FOLDER_TYPE {
  PERSONAL = "personal",
  GROUP = "group",
  UNDEFINED = "all",
}

/**
 * Option folder Access
 */
export enum FOLDER_ACCESS {
  READ = "read",
  WRITE = "write",
  NO_ACCESS = "no_access",
}

/**
 * Option product type
 */
export enum PRODUCT_TYPE {
  STANDARD = "standard",
  CORPORATE = "corporate",
  ENTERPRISE = "enterprise",
}

/**
 * Option how get groups
 */
export enum GROUP_STORAGE {
  LDAP = "ldap",
  LOCAL = "local",
  AZURE = "azure",
}

/**
 * Option how get users, local - management in PS, ldap/azure - management on controlium
 */
export enum USER_STORAGE {
  LDAP = "ldap",
  LOCAL = "local",
  AZURE = "azure",
}

/**
 * Action Create for something model
 */
export enum ACTION {
  CREATE = "create",
  UPDATE = "update",
}

export enum FIELD_TYPE {
  ACCESS_TYPE = "access_type",
  ACCOUNT_NUMBER = "account_number",
  BIC_SWIFT = "bic_swift",
  CARD_EXPIRATION_DATE = "card_expiration_date",
  CARD_NUMBER = "card_number",
  CARD_PIN = "card_pin",
  CARD_TYPE = "card_type",
  CERTIFICATE = "certificate",
  CLIENT_NUMBER = "client_number",
  COUNTRY = "country",
  CVV = "cvv",
  DESCRIPTION = "description",
  DOC_NUMBER = "doc_number",
  EMAIL = "email",
  EMAIL_TYPE = "email_type",
  EXPIRATION_DATE = "expiration_date",
  FILE = "file",
  FILE_NAME = "fileName",
  HOLDER = "holder",
  HOSTNAME_IP = "hostname_ip",
  IAM_ID = "iam_id",
  IBAN = "iban",
  ID_CARD_TYPE = "id_card_type",
  INCOMING_MAIL_PORT = "incoming_mail_port",
  INCOMING_MAIL_SECURITY_TYPE = "incoming_mail_security_type",
  INCOMING_MAIL_SERVER = "incoming_mail_server",
  ISSUE_DATE = "issue_date",
  KEY = "key",
  LOGIN = "login",
  LOGO = "logo",
  NAME = "name",
  OTP = "otp",
  OUTGOING_MAIL_PORT = "outgoing_mail_port",
  OUTGOING_MAIL_SECURITY_TYPE = "outgoing_mail_security_type",
  OUTGOING_MAIL_SERVER = "outgoing_mail_server",
  PASSWORD = "password",
  PASSWORD_CHECK = "password_check",
  PHOTO = "photo",
  PLACE_ISSUE = "place_issue",
  PORT = "port",
  PROTOCOL = "protocol",
  QR_BARCODE = "qr_barcode",
  SEALING = "sealing",
  SERIAL_NUMBER = "serial_number",
  TEMPLATE = "template",
  TITLE = "title",
  URI = "uri",
  // Sap Field
  USER_NAME = "username",
  RFC_CONNECTION_ID = "rfc_connection_id",
  RFC_CONNECTION_NAME = "rfc_connection_name",
  RFC_CONNECTION_USER = "rfc_connection_user",
  RFC_CONNECTION_TYPE= "rfc_connection_type"
}

export const SECURE_FIELDS_LIST: FIELD_TYPE[] = [
  FIELD_TYPE.ACCESS_TYPE,
  FIELD_TYPE.ACCOUNT_NUMBER,
  FIELD_TYPE.BIC_SWIFT,
  FIELD_TYPE.CARD_EXPIRATION_DATE,
  FIELD_TYPE.CARD_NUMBER,
  FIELD_TYPE.CARD_PIN,
  FIELD_TYPE.CARD_TYPE,
  FIELD_TYPE.CLIENT_NUMBER,
  FIELD_TYPE.COUNTRY,
  FIELD_TYPE.CVV,
  FIELD_TYPE.DESCRIPTION,
  FIELD_TYPE.DOC_NUMBER,
  FIELD_TYPE.EMAIL,
  FIELD_TYPE.EMAIL_TYPE,
  FIELD_TYPE.EXPIRATION_DATE,
  FIELD_TYPE.HOLDER,
  FIELD_TYPE.HOSTNAME_IP,
  FIELD_TYPE.IAM_ID,
  FIELD_TYPE.IBAN,
  FIELD_TYPE.ID_CARD_TYPE,
  FIELD_TYPE.INCOMING_MAIL_PORT,
  FIELD_TYPE.INCOMING_MAIL_SECURITY_TYPE,
  FIELD_TYPE.INCOMING_MAIL_SERVER,
  FIELD_TYPE.ISSUE_DATE,
  FIELD_TYPE.KEY,
  FIELD_TYPE.LOGIN,
  FIELD_TYPE.NAME,
  FIELD_TYPE.OTP,
  FIELD_TYPE.OUTGOING_MAIL_PORT,
  FIELD_TYPE.OUTGOING_MAIL_SECURITY_TYPE,
  FIELD_TYPE.OUTGOING_MAIL_SERVER,
  FIELD_TYPE.PASSWORD,
  FIELD_TYPE.PLACE_ISSUE,
  FIELD_TYPE.PORT,
  FIELD_TYPE.PROTOCOL,
  FIELD_TYPE.SERIAL_NUMBER,
  FIELD_TYPE.USER_NAME,
  FIELD_TYPE.RFC_CONNECTION_ID,
  FIELD_TYPE.RFC_CONNECTION_NAME,
  FIELD_TYPE.RFC_CONNECTION_USER,
  FIELD_TYPE.RFC_CONNECTION_TYPE
];
